const urlValidation = [
    {
        field_name: 'url',
        label: 'URL',
        type: "url",
        isRequired: true
    }
]

export function Constants() {
    return { urlValidation }
}