import React, { useState, useEffect } from 'react'
import { MButton, Error, Select, Loader, TooltipPro } from '../../CommonComponents/index'
import { Constants } from '../Constants'
import { monitorServersSettingServices } from '../../../_services/servicesWithSecondOrigin/index'
import toastr from 'toastr'
import { Input } from 'reactstrap';
import { Validation } from '../../../_helpers/validation/Validation'
import { AiOutlineInfoCircle } from 'react-icons/ai'

const fileObj = {
    "path_type": 's3',
    "bucket_name": "",
    "access_key": "",
    "secret_code": "",
    "step_to_create_s3_bucket": "",
    "log_level": 0,
    "cpu_time": "",
    "region_name": "",

    'dropbox_token': "",
    'dropbox_path': "",
    // 'google_access_token': "",
    // 'google_client_id': "",
    // 'google_client_secret': "",
    // 'google_refresh_token': "",

    'access_key_update': false,
    'bucket_name_update': false,
    'region_name_update': false,
    'secret_code_update': false,
    'dropbox_token_update': false,
    'dropbox_path_update': false,


    // 'google_access_token_update': false,
    // 'google_client_id_update': false,
    // 'google_client_secret_update': false,
    // 'google_refresh_token_update': false,

    "google_folder_name": "",

    "google_folder_name_update": false,
    "google_json_update": false
}


const UploadPath = ({ ...props }) => {
    const [inputFields, setInputFields] = useState(fileObj)

    const [googleJson, setGoogleJSON] = useState("")
    const [checkJsonUpload, setCheckJsonUpload] = useState(false)


    const [error, setError] = useState({})
    const [loader, setLoader] = useState(false)
    useEffect(() => {
        if (props.active === 5)
            getDataFromServer()
    }, [props.MSId, props.active]);


    const onChangeFileUpload = (e) => {
        if (e.target.files && e.target.files[0]) {
            var file = e.target.files[0]
            let reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => {
                var strImage = reader.result.split('base64,')[1];
                let obj = { ...inputFields }
                obj["google_json_update"] = true;
                setInputFields(obj)
                setGoogleJSON(strImage)
            };
        }
    }


    const getDataFromServer = async () => {
        setLoader(true)
        setInputFields(fileObj)
        if (props.MSId) {
            let response = await monitorServersSettingServices.getMonitorUploadPath(props.MSId)
            if (response && response.data && Array.isArray(response.data) && response.data.length !== 0) {
                setCheckJsonUpload(response.data[0].google_json ? true : false)
                setInputFields({
                    "path_type": response.data[0].path_type,
                    "bucket_name": response.data[0].bucket_name_to_show ? response.data[0].bucket_name_to_show : response.data[0].bucket_name,
                    "access_key": response.data[0].access_key_to_show ? response.data[0].access_key_to_show : response.data[0].access_key,
                    "secret_code": response.data[0].secret_code_to_show ? response.data[0].secret_code_to_show : response.data[0].secret_code,
                    "step_to_create_s3_bucket": response.data[0].step_to_create_s3_bucket,
                    "log_level": parseInt(response.data[0].log_level),
                    "cpu_time": response.data[0].cpu_time,
                    "region_name": response.data[0].region_name_to_show ? response.data[0].region_name_to_show : response.data[0].region_name,
                    'dropbox_token': response.data[0].dropbox_token_to_show ? response.data[0].dropbox_token_to_show : response.data[0].dropbox_token,
                    'dropbox_path': response.data[0].dropbox_path_to_show ? response.data[0].dropbox_path_to_show : response.data[0].dropbox_path,
                    // 'google_access_token': response.data[0].google_access_token_to_show ? response.data[0].google_access_token_to_show : response.data[0].google_access_token,
                    // 'google_client_id': response.data[0].google_client_id_to_show ? response.data[0].google_client_id_to_show : response.data[0].google_client_id,
                    // 'google_client_secret': response.data[0].google_client_secret_to_show ? response.data[0].google_client_secret_to_show : response.data[0].google_client_secret,
                    // 'google_refresh_token': response.data[0].google_refresh_token_to_show ? response.data[0].google_refresh_token_to_show : response.data[0].google_refresh_token,

                    'google_folder_name': response.data[0].google_folder_name_to_show ? response.data[0].google_folder_name_to_show : response.data[0].google_folder_name,

                    'access_key_update': false,
                    'bucket_name_update': false,
                    'region_name_update': false,
                    'secret_code_update': false,
                    'dropbox_token_update': false,
                    'dropbox_path_update': false,
                    'google_folder_name_update': false
                    // 'google_access_token_update': false,
                    // 'google_client_id_update': false,
                    // 'google_client_secret_update': false,
                    // 'google_refresh_token_update': false,
                })
            }
        }
        setLoader(false)
    }

    const onSubmit = async (e) => {
        let flag = 0
        const field = inputFields.path_type === 's3' ? Constants().uploadPath : inputFields.path_type === 'dropbox' ? Constants().uploadPath_dropbox : Constants().uploadPath_google;
        let errobj = Validation(inputFields, field);
        Object.keys(errobj).map(index => {
            if (errobj[index] !== "") {
                flag = 1;
            }
        });
        setError(errobj)
        let inputFileData = {
            "log_level": inputFields.log_level,
            "cpu_time": inputFields.cpu_time,
            "path_type": inputFields.path_type
        }

        if (inputFields.path_type === "s3") {
            inputFileData.step_to_create_s3_bucket = inputFields.step_to_create_s3_bucket
        }
        if (inputFields.bucket_name_update) {
            inputFileData.bucket_name = inputFields.bucket_name
        }
        if (inputFields.access_key_update) {
            inputFileData.access_key = inputFields.access_key
        }
        if (inputFields.secret_code_update) {
            inputFileData.secret_code = inputFields.secret_code
        }
        if (inputFields.region_name_update) {
            inputFileData.region_name = inputFields.region_name
        }
        if (inputFields.dropbox_token_update) {
            inputFileData.dropbox_token = inputFields.dropbox_token
        }
        if (inputFields.dropbox_path_update) {
            inputFileData.dropbox_path = inputFields.dropbox_path
        }
        // if (inputFields.google_access_token_update) {
        //     inputFileData.google_access_token = inputFields.google_access_token
        // }
        // if (inputFields.google_client_id_update) {
        //     inputFileData.google_client_id = inputFields.google_client_id
        // }
        // if (inputFields.google_client_secret_update) {
        //     inputFileData.google_client_secret = inputFields.google_client_secret
        // }
        // if (inputFields.google_refresh_token_update) {
        //     inputFileData.google_refresh_token = inputFields.google_refresh_token
        // }

        if (inputFields.google_folder_name_update) {
            inputFileData.google_folder_name = inputFields.google_folder_name
        }
        if (inputFields.google_json_update) {
            inputFileData.google_json = googleJson
        }

        if (flag === 0) {
            let data = {
                ...inputFileData
            }
            let response = await monitorServersSettingServices.updateMonitorUploadPath(data, props.MSId)
            if (response) {
                if (response && response.message) {
                    toastr.success(response.message)
                }
            }
        }
    }

    const onChange = (e) => {
        let obj = { ...inputFields }
        obj[e.target.name] = e.target.value;

        if (obj[e.target.name + '_update'] === false) { obj[e.target.name + '_update'] = true; }
        setInputFields(obj)
    }

    const setSelectedOption = (e) => {
        let obj = { ...inputFields }
        obj.path_type = e.value;
        setInputFields(obj)
    }

    return <div className="serverSettingData">
        <div className="setLoaderPosition">
            <Loader loader={loader}></Loader>
        </div>
        <div className="serverInfoTabsTitle">
            {/* <Button className="backBtn mr-4" onClick={(e) => props.history.push('/monitor-servers')}><IoIosArrowRoundBack></IoIosArrowRoundBack></Button> */}
            <h3>Backup Upload Path
                <span className="ml-2">
                    <TooltipPro
                        className="bottom tsize-600"
                        tooltip={
                            <div className="row">
                                <div className="col-md-12">
                                    <p>Your backup is stored in your own cloud space. Select an appropriate cloud space as per your convenience and provide the details so your backups can be securely stored there.</p>
                                </div>
                            </div>
                        }
                    >
                        <AiOutlineInfoCircle className="infoIcon" />
                    </TooltipPro>
                </span>
            </h3>

        </div>
        <div>

            <div className="row mt-4">
                <div className="col-md-6">
                    <label>Path Type</label><span className="ml-2">
                        <TooltipPro
                            className="bottom tsize-400"
                            tooltip={
                                <div className="row">
                                    <div className="col-md-12">
                                        <p>We can save your backup in {inputFields.path_type === "google drive" ? "Google Drive" : inputFields.path_type === "dropbox" ? "Drop Box" : "S3"}. You need to setup {inputFields.path_type === "google drive" ? "Google Drive" : inputFields.path_type === "dropbox" ? "Drop Box" : "S3"} as per the steps given {inputFields.path_type === "google drive" ? <a href="https://g8keeperapp.com/how-it-works/configuring-google-drive-with-g8keeper-to-store-backups/" target="_blank">here</a> : inputFields.path_type === "dropbox" ? <a href="https://g8keeperapp.com/how-it-works/configuring-dropbox-with-g8keeper-to-store-backups/" target="_blank">here</a> : <a href="https://g8keeperapp.com/how-it-works/configuring-aws-s3-with-g8keeper-to-store-backups/" target="_blank">here</a>} and provide the details.</p>
                                    </div>
                                </div>
                            }
                        >
                            <AiOutlineInfoCircle className="infoIcon" />
                        </TooltipPro>
                    </span>
                    <Select setSelectedOption={setSelectedOption} selectedOption={inputFields.path_type} options={[{ label: "s3", value: 's3' }, { label: "dropbox", value: 'dropbox' }, { label: "google drive", value: 'google drive' }]}></Select>
                </div>
                <div className="col-md-6">
                </div>
            </div>

            {inputFields.path_type === "dropbox" && <div className="row mt-4">
                <div className="col-md-6">
                    <label>Dropbox Token</label><span className="ml-2">
                        <TooltipPro
                            className="bottom tsize-400"
                            tooltip={
                                <div className="row">
                                    <div className="col-md-12">
                                        <p>Provide the dropbox token. Please use a long term token so it does not expire</p>
                                    </div>
                                </div>
                            }
                        >
                            <AiOutlineInfoCircle className="infoIcon" />
                        </TooltipPro>
                    </span>
                    <Input value={inputFields.dropbox_token} name="dropbox_token" placeholder="Dropbox Token" onChange={onChange}></Input>
                    {error && error.dropbox_token && <Error error={error.dropbox_token} />}
                </div>
                <div className="col-md-6">
                    <label>Dropbox Path</label><span className="ml-2">
                        <TooltipPro
                            className="bottom tsize-400"
                            tooltip={
                                <div className="row">
                                    <div className="col-md-12">
                                        <p>Please provide dropbox folder path. All you need to do is enter sub-folder  name here for example just add "backup" here and then the backups will be created in  "/Apps/g8eeper-backup/backup "  folder</p>
                                    </div>
                                </div>
                            }
                        >
                            <AiOutlineInfoCircle className="infoIcon" />
                        </TooltipPro>
                    </span>
                    <Input value={inputFields.dropbox_path} name="dropbox_path" placeholder="Dropbox Path" onChange={onChange}></Input>
                    {error && error.dropbox_path && <Error error={error.dropbox_path} />}
                </div>
            </div>}









            {inputFields.path_type === "google drive" && <div className="row mt-4">
                <div className="col-md-6">
                    <label>Google Folder Name</label><span className="ml-2">
                        <TooltipPro
                            className="bottom tsize-400"
                            tooltip={
                                <div className="row">
                                    <div className="col-md-12">
                                        <p>We can save your backup in google drive. You need to setup google drive as per the steps given <a href="https://g8keeperapp.com/how-it-works/configuring-aws-s3-with-g8keeper-to-store-backups/" target="_blank">here </a> and provide the details.</p>
                                    </div>
                                </div>
                            }
                        >
                            <AiOutlineInfoCircle className="infoIcon" />
                        </TooltipPro>
                    </span>
                    <Input value={inputFields.google_folder_name} name="google_folder_name" placeholder="Google folder name" onChange={onChange}></Input>
                    {error && error.google_folder_name && <Error error={error.google_folder_name} />}
                </div>
                <div className="col-md-6">
                    <label>Google JSON</label><span className="ml-2">
                        <TooltipPro
                            className="bottom tsize-400"
                            tooltip={
                                <div className="row">
                                    <div className="col-md-12">
                                        <p>We can save your backup in google drive. You need to setup google drive as per the steps given <a href="https://g8keeperapp.com/how-it-works/configuring-aws-s3-with-g8keeper-to-store-backups/" target="_blank">here </a>and provide the details.</p>
                                    </div>
                                </div>
                            }
                        >
                            <AiOutlineInfoCircle className="infoIcon" />
                        </TooltipPro>
                    </span>
                    <Input type="file" value={inputFields.google_json} name="google_json" placeholder="Google Client Secret" onChange={onChangeFileUpload}></Input>
                    {checkJsonUpload && <p>JSON Already Uploaded</p>}
                    {error && error.google_json && <Error error={error.google_json} />}
                </div>
            </div>}
            {/* {inputFields.path_type === "google drive" && <div className="row mt-4">
                <div className="col-md-6">
                    <label>Google Client Id</label><span className="ml-2">
                            <TooltipPro
                                className="bottom tsize-400"
                                tooltip={
                                    <div className ="row">
                                        <div className ="col-md-12">
                                            <p>We can save your backup in S3. You need to setup S3 as per the steps given here and provide the details.</p>
                                        </div>
                                    </div>
                                }
                            >
                               <AiOutlineInfoCircle className="infoIcon" />
                            </TooltipPro>
                        </span>
                    <Input value={inputFields.google_client_id} name="google_client_id" placeholder="Google Client Id" onChange={onChange}></Input>
                    {error && error.google_client_id && <Error error={error.google_client_id} />}
                </div>
                <div className="col-md-6">
                    <label>Google Client Secret</label><span className="ml-2">
                            <TooltipPro
                                className="bottom tsize-400"
                                tooltip={
                                    <div className ="row">
                                        <div className ="col-md-12">
                                            <p>We can save your backup in S3. You need to setup S3 as per the steps given here and provide the details.</p>
                                        </div>
                                    </div>
                                }
                            >
                               <AiOutlineInfoCircle className="infoIcon" />
                            </TooltipPro>
                        </span>
                    <Input value={inputFields.google_client_secret} name="google_client_secret" placeholder="Google Client Secret" onChange={onChange}></Input>
                    {error && error.google_client_secret && <Error error={error.google_client_secret} />}
                </div>
            </div>}
            {inputFields.path_type === "google drive" && <div className="row mt-4">
                <div className="col-md-6">
                    <label>Google Refresh Token</label><span className="ml-2">
                            <TooltipPro
                                className="bottom tsize-400"
                                tooltip={
                                    <div className ="row">
                                        <div className ="col-md-12">
                                            <p>We can save your backup in S3. You need to setup S3 as per the steps given here and provide the details.</p>
                                        </div>
                                    </div>
                                }
                            >
                               <AiOutlineInfoCircle className="infoIcon" />
                            </TooltipPro>
                        </span>
                    <Input value={inputFields.google_refresh_token} name="google_refresh_token" placeholder="Google Refresh Token" onChange={onChange}></Input>
                    {error && error.google_refresh_token && <Error error={error.google_refresh_token} />}
                </div>
                <div className="col-md-6">
                    <label>Google Access Token</label><span className="ml-2">
                            <TooltipPro
                                className="bottom tsize-400"
                                tooltip={
                                    <div className ="row">
                                        <div className ="col-md-12">
                                            <p>We can save your backup in S3. You need to setup S3 as per the steps given here and provide the details.</p>
                                        </div>
                                    </div>
                                }
                            >
                               <AiOutlineInfoCircle className="infoIcon" />
                            </TooltipPro>
                        </span>
                    <Input value={inputFields.google_access_token} name="google_access_token" placeholder="Google Access Token" onChange={onChange}></Input>
                    {error && error.google_access_token && <Error error={error.google_access_token} />}
                </div>
            </div>} */}
























            {inputFields.path_type === "s3" && <div className="row mt-4">
                <div className="col-md-6">
                    <label>Bucket Name</label><span className="ml-2">
                        <TooltipPro
                            className="bottom tsize-400"
                            tooltip={
                                <div className="row">
                                    <div className="col-md-12">
                                        <p>Provide the s3 bucket name</p>
                                    </div>
                                </div>
                            }
                        >
                            <AiOutlineInfoCircle className="infoIcon" />
                        </TooltipPro>
                    </span>
                    <Input value={inputFields.bucket_name} name="bucket_name" placeholder="Bucket Name" onChange={onChange}></Input>
                    {error && error.bucket_name && <Error error={error.bucket_name} />}
                </div>
                <div className="col-md-6">
                    <label>Access Key</label><span className="ml-2">
                        <TooltipPro
                            className="bottom tsize-400"
                            tooltip={
                                <div className="row">
                                    <div className="col-md-12">
                                        <p>Provide the s3 access key.</p>
                                    </div>
                                </div>
                            }
                        >
                            <AiOutlineInfoCircle className="infoIcon" />
                        </TooltipPro>
                    </span>
                    <Input value={inputFields.access_key} name="access_key" placeholder="Access Key" onChange={onChange}></Input>
                    {error && error.access_key && <Error error={error.access_key} />}
                </div>
            </div>}
            {inputFields.path_type === "s3" && <div className="row mt-4">
                <div className="col-md-6">
                    <label>Secret Code</label><span className="ml-2">
                        <TooltipPro
                            className="bottom tsize-400"
                            tooltip={
                                <div className="row">
                                    <div className="col-md-12">
                                        <p>Provide the s3 secret code.</p>
                                    </div>
                                </div>
                            }
                        >
                            <AiOutlineInfoCircle className="infoIcon" />
                        </TooltipPro>
                    </span>
                    <Input value={inputFields.secret_code} name="secret_code" placeholder="Secret Code" onChange={onChange}></Input>
                    {error && error.secret_code && <Error error={error.secret_code} />}
                </div>
                <div className="col-md-6">
                    <label>Region Name</label><span className="ml-2">
                        <TooltipPro
                            className="bottom tsize-400"
                            tooltip={
                                <div className="row">
                                    <div className="col-md-12">
                                        <p>Provide the s3 bucket region name, for example ap-south-1</p>
                                    </div>
                                </div>
                            }
                        >
                            <AiOutlineInfoCircle className="infoIcon" />
                        </TooltipPro>
                    </span>
                    <Input value={inputFields.region_name} name="region_name" placeholder="Region Name" onChange={onChange}></Input>
                    {error && error.region_name && <Error error={error.region_name} />}
                </div>
                {/* <div className="col-md-6">
                    <label>Steps To Create S3 bucket</label>
                    <Input value={inputFields.step_to_create_s3_bucket} name="step_to_create_s3_bucket" placeholder="Steps To Create S3 bucket" onChange={onChange}></Input>
                    {error && error.step_to_create_s3_bucket && <Error error={error.step_to_create_s3_bucket} />}
                </div> */}
            </div>}
            {/* <div className="row mt-4">
                <div className="col-md-6">
                    <label>Log Level</label>
                    <Select options={[{ label: 'Error', value: 0 }, { label: 'Debug', value: 1 }, { label: 'Info', value: 2 },]} setSelectedOption={(e => {
                        let obj = { ...inputFields }
                        obj["log_level"] = e.value;
                        setInputFields(obj)
                    })} selectedOption={inputFields.log_level}></Select>
                </div>
                <div className="col-md-6">
                    <label> CPU Time(In Seconds)</label>
                    <Input type="number" value={inputFields.cpu_time} name="cpu_time" placeholder="CPU Time" onChange={onChange}></Input>
                    {error && error.cpu_time && <Error error={error.cpu_time} />}
                </div>
            </div> */}
            {/* {inputFields.path_type === "s3" && <div className="row mt-4">
                <div className="col-md-6">
                    <label>Region Name</label>
                    <Input value={inputFields.region_name} name="region_name" placeholder="Region Name" onChange={onChange}></Input>
                    {error && error.region_name && <Error error={error.region_name} />}
                </div>
            </div>} */}
            <div className="mt-4">
                <MButton label="Save" onClick={(e) => onSubmit(e)} className="bluebutton"></MButton>
            </div>
        </div>
    </div>
}
export default UploadPath
