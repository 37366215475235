import React from 'react'
import { domainService } from '../../_services/DomainServices/domainServices'
import { Button, Form, Input, Row, Col } from 'reactstrap';
import CloudFireInformation from '../../_components/DomainInformation/CloudFlareInformation'
import Header from '../../_components/CommonComponents/Header/Header'
import { Validation } from '../../_helpers/validation/Validation'
import { Constants } from './Constants'
import SpinnerForTable from '../../_components/CommonComponents/Loader/SpinnerForTable'
class ConnectToCloudFlare extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            my_token: "",
            my_token_copy: "",
            responseData: [],
            error: {},
            toggle: "",
            loader: false
        }
    }

    setToggelValue = (value) => {
        this.setState({
            toggle: value
        })
    }

    onSubmit = async (e) => {
        e.preventDefault()
        let flag = 0
        const field = Constants().tokenValidation;
        let errobj = Validation(this.state, field);
        Object.keys(errobj).map(index => {
            if (errobj[index] !== "") {
                flag = 1;
            }
        });
        this.setState({
            error: errobj
        })
        if (flag === 0) {
            this.setState({
                loader: true
            })
            let data = {
                "token": this.state.my_token
            }
            let response = await domainService.getDomainDNSInformationByToken(data)
            if (response) {
                this.setState({
                    responseData: response,
                    loader: false,
                    my_token_copy: this.state.my_token
                })
            } else {
                this.setState({
                    responseData: [],
                    loader: false
                })
            }
        }
    }

    setToken = (e) => {
        this.setState({
            my_token: e.target.value
        })
    }


    gotoAddDomain = () => {
        this.props.history.push('/')
    }

    render() {
        const { loader } = this.state
        return (<div>
            <Header history={this.props.history} {...this.props}></Header>
            <div className="information-input">
                <label>Enter Token</label>
                <Form onSubmit={this.onSubmit}>
                    <Row>
                        <Col><Input name="my_token" value={this.state.my_token} onChange={this.setToken}></Input>
                            {this.state.error && this.state.error.my_token && this.state.error.my_token !== "" && <div>{this.state.error.my_token}</div>}
                        </Col>
                        <Col> <Button placeholder="Enter Domain Name" color="primary" onClick={(e) => this.onSubmit(e)}>Submit</Button></Col>
                    </Row>
                </Form>
            </div>
            <Row>
                <Col className="col-lg-1 col-md-1">
                    {loader ? <div className="cloudflare_loader loader_div_width">
                        <SpinnerForTable loader={loader} />
                    </div> : <div className="loader_div_width"></div>}
                </Col>
                <Col>
                <div className="card p-4">
                <div className="domain_information_table">
                        <label>Domain Information</label>
                        <div>
                            <Row className="table">
                                <Col>
                                    <div scope="col">#</div>
                                </Col>
                                <Col>
                                    <div scope="col">Domain Name</div>
                                </Col>
                                <Col>
                                    <div scope="col">Registrar URL</div>
                                </Col>
                                <Col>
                                    <div scope="col">Expiration Date</div>
                                </Col>
                            </Row>
                        </div>
                        {Array.isArray(this.state.responseData) && this.state.responseData.map((data, index) => {
                            return <CloudFireInformation
                                data={data}
                                setToggelValue={this.setToggelValue}
                                toggle={this.state.toggle}
                                index={index}
                                my_token_copy={this.state.my_token_copy}
                            >
                            </CloudFireInformation>
                        })}
                    </div>
                
                </div>
                   </Col>
            </Row>
            <div><Button className="NextButton" onClick={() => this.props.history.push('/seo-monitoring')}>Next</Button></div>
        </div>)
    }
}

export default ConnectToCloudFlare