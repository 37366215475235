import { useCallback, useEffect, useMemo, useState, useRef } from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { TooltipPro } from "../../_components/CommonComponents/index";
import { monitorServersSettingServices } from "../../_services/servicesWithSecondOrigin/MonitorServers/MonitorServerSetting/MonitorServerSetting";
import DiskIOWaitActualTable from "../../_components/DiskIOWaitActualTabs/DiskIOWaitActualTabs";

export default function NetworkIOProcess({ networkIOKeyList, networkIOList, network_io_stats_date, tableName = "Network IO Process", serverId }) {
  const [checked, setChecked] = useState(false)

  const dataRef = useRef(null)

  const toggleCheckbox = useCallback(({ target: { checked }}) => {
    setChecked(checked)

    const data = dataRef.current;

    if (!data) return;

    monitorServersSettingServices.generalSettingSave({...data, network_performance: checked ? 1 : 0}, serverId)
  }, [serverId])

  useEffect(() => {
    monitorServersSettingServices.getGeneralSettingSave(serverId).then(response => {
      dataRef.current =
      response.data

      setChecked(+(response.data?.network_performance) === 1)
    })
  }, [serverId])

  const checkboxId = useMemo(() => Math.random().toString().slice(2), [])
  
  return (
    <div className="col-md-6">
      <div className="card overflow-visible fixedChartTable">
        <div className="card-header">
          <h3 className="mr-3">
            {tableName}
            <TooltipPro
              className="bottom tsize-400 ml-3 mt-1 d-inline-block"
              style={{ zIndex: 10 }}
              tooltip={
                <div className="row">
                  <div className="col-md-12">
                    <p>This shows the processes that are causing the Network IO. This also shows how much Network IO is caused by individual process (KB/s).</p>
                  </div>
                </div>
              }
            >
              <AiOutlineInfoCircle className="infoIcon" />
            </TooltipPro>
          </h3>
          <div className="mb-0 pull-right">
            <strong> {network_io_stats_date}</strong>
          </div>
          <div className="d-flex justify-content-end">
            <label htmlFor={checkboxId} className="mx-2 my-0">
              Enable Network Monitoring
            </label>
            <input type="checkbox" name="enable-network-monitoring" checked={checked} id={checkboxId} onChange={toggleCheckbox} />
          </div>
        </div>
        <DiskIOWaitActualTable keyList={networkIOKeyList} tableList={networkIOList} />
      </div>
    </div>
  );
}
