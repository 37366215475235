import React from "react";
import { Button } from "reactstrap";
import { IoIosArrowRoundBack } from "react-icons/io";
import Header from "../../_components/CommonComponents/Header/Header";
import DisallowedFilesForm from "../../_components/MonitorServersComponents/DisallowedFilesForm/DisallowedFilesForm";
import { ServerCurrentLoggedIn } from "../../_components/CommonComponents/index";
import { objectToQuery } from "../../_helpers/Common/Common";

class BackupFolderChange extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      uuid: "",
      myTimezone: "",
      server_name: "",
      unique_id: null,
    };
  }

  componentDidMount() {
    let Timezone = localStorage.getItem("Timezone");
    this.setState(
      {
        myTimezone: Timezone,
      },
      () => {
        if (this.props.location.state.uuid) {
          this.setState({
            uuid: this.props.location.state.uuid,
          });
        }
        if (
          this.props.location &&
          this.props.location.state &&
          this.props.location.state.id
        ) {
          this.setState(
            {
              id: this.props.location.state.id,
              uuid: this.props.location.state.uuid,
              server_name: this.props.location.state.server_name,
            },
            async () => {}
          );
        } else {
          this.props.history.push("/monitor-servers");
        }
      }
    );
  }

  onSelectServer = (e) => {
    this.setState({
      id: e.id,
      uuid: e.value,
      server_name: e.label,
    });
  };

  onSelectUniqueId = (e) => {
    this.setState({
      unique_id: e.value,
    });
  };

  render() {
    let object = {
      id: this.state.id,
      uuid: this.state.uuid,
      update: true,
      server_name: this.state.server_name,
    };
    return (
      <div>
        <Header history={this.props.history} {...this.props}></Header>
        <div className="container">
          <div className="serverDetailsSec p-4">
            <div className="">
              <Button
                className="backBtn mr-3 mr-lg-3"
                onClick={() =>
                  this.props.history.push(
                    "/monitor-server/charts" + objectToQuery(object),
                    object
                  )
                }>
                <IoIosArrowRoundBack></IoIosArrowRoundBack>
              </Button>
            </div>
            <ServerCurrentLoggedIn
              server_name={this.state.server_name}
              uuid={this.state.uuid}
              onSelectServer={this.onSelectServer}
              mytimezone={this.state.mytimezone}
              unique_id={this.state.unique_id}
              onSelectUniqueId={this.onSelectUniqueId}
            />
          </div>
          <div className="card">
            <div className="row">
              <div className="col-md-12">
                <DisallowedFilesForm {...this.props} uuid={this.state.uuid} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BackupFolderChange;
