import React from 'react'
import { Button, Row, Col } from 'reactstrap';
import Header from '../../_components/CommonComponents/Header/Header'
import { monitorServersServices } from '../../_services/servicesWithSecondOrigin/index'
import { confirmAlert } from 'react-confirm-alert'
import toastr from 'toastr'

class AddServer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: "",
            data: ""
        }
    }

    componentDidMount() {
        if (this.props.location.state && this.props.location.state.id) {
            this.getDataFromServer(this.props.location.state.id)
        } else {
            this.props.history.push("/monitor-servers")
        }
    }

    getDataFromServer = async (id) => {
        let response = await monitorServersServices.getMonitorServer(id)
        if (response && response.data) {
            this.setState({
                data: response.data
            })
        }
    }

     deleteServer = (id) => {
        confirmAlert({
            title: 'Confirm to Delete',
            message: <p>Are you sure to do this.</p>,
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        let response = await monitorServersServices.deleteMonitorServers(id)
                        if (response && response.message) {
                            toastr.info(response.message)
                        }
                        if (response && response.status === 200) {
                            this.props.history.push("/monitor-servers")
                        }
                    }
                },
                {
                    label: 'No',
                    onClick: () => console.log("No")
                }
            ]
        });
    }

    render() {
        const { data } = this.state
        return (<div>
            <Header history={this.props.history} {...this.props}></Header>
           <div className="container">
           <div className="card">
            <Row className="">
                
                <Col className="col-md-12">
                    <div className="card-header">
                        <h3>{data && data.server_name}</h3>
                        <span> 
                            <Button className="addEditBtn primary-btn btn btn-secondary mr-2" onClick={() => this.props.history.push('/monitor-servers/update-server', { id: data.id, update: true, header: true }) }>Update</Button>
                            <Button className="addEditBtn primary-btn btn btn-secondary mr-2" onClick={() => this.deleteServer(data.id)}>Delete</Button>
                            <Button className="addEditBtn btn btn-secondary" onClick={() => this.props.history.push('/monitor-servers')}>Back</Button></span>
                    </div>
                    <div>
                         <table className="table">
                            <tbody>
                                <tr>
                                    <td>Uuid</td>
                                    <td>{data && data.uuid}</td>
                                </tr>
                                <tr>
                                    <td>Server Name</td>
                                    <td>{data &&data.server_name}</td>
                                </tr>
                                <tr>
                                    <td>Server Description</td>
                                    <td>{ data && data.server_description}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </Col>
            </Row>
       
            </div>
           
           </div>
            </div>)
    }
}

export default AddServer