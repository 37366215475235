import React from "react";

export const Table = React.memo(
  ({ Fields, data, noRecord, headerWithoutData = true }) => {
    return (
      <div className="react-bootstrap-table">
        <table class="table table-border">
          {((Array.isArray(data) && data.length !== 0) ||
            headerWithoutData) && (
            <thead>
              <tr>
                {Array.isArray(Fields) &&
                  Fields.map((obj) => {
                    return <th scope="col">{obj.field_name}</th>;
                  })}
              </tr>
            </thead>
          )}
          {Array.isArray(data) && data.length !== 0 ? (
            <tbody>
              {data.map((obj, index) => {
                return (
                  <tr>
                    {Array.isArray(Fields) &&
                      Fields.map((field) => {
                        return (
                          <td>
                            {field.callBack
                              ? field.callBack(obj, index)
                              : obj[field.field_key]}
                          </td>
                        );
                      })}
                  </tr>
                );
              })}
            </tbody>
          ) : noRecord ? (
            noRecord
          ) : (
            <div>No data found</div>
          )}
        </table>
      </div>
    );
  }
);
