import React, { Fragment, useState, useEffect, useCallback } from "react";
import { monitorServersServices } from "../../_services/servicesWithSecondOrigin/index";
import {
  Pagination,
  Select,
  TooltipPro,
} from "../../_components/CommonComponents/index";
import PageLoader from "../../_components/CommonComponents/PageLoader/Loader";
import { AiOutlineInfoCircle } from "react-icons/ai";

function randomIntFromInterval(min, max) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
}

const getColor = () => {
  const colors = {};

  return function getRandomColor(id) {
    if (colors[id]) return colors[id];

    const max = 255;
    const min = 80;

    const color = `rgb(${randomIntFromInterval(
      min,
      max
    )}, ${randomIntFromInterval(min, max)}, ${randomIntFromInterval(
      min,
      max
    )})`;
    colors[id] = color;
    return color;
  };
};

const colorById = getColor();

const BackupFolderLsting = ({ ...props }) => {
  const [data, setData] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [isLoading, setLoading] = useState(true);
  const [selectedOption, setselectedOption] = useState(props.pageSize || 10);

  const getData = useCallback(
    async (id, selectPageItem) => {
      setLoading(true);
      let d = new Date();
      let response =
        await monitorServersServices.MonitorServerBackUpFolderChange(
          props.uuid,
          id,
          selectedOption,
          selectPageItem,
          props.onSubmitSearch,
          d.getTimezoneOffset()
        );
      if (response && response.data && Array.isArray(response.data)) {
        response.data.forEach((x, i) => {
          response.data[i].matched = "";
          response.data.forEach((y, i1) => {
            if (i !== i1 && x.uniqe_id === y.uniqe_id) {
              response.data[i].matched = 1;
            }
          });
        });
        setData(response.data);
        if (response && response._meta) {
          setPageCount(response._meta.pageCount);
          setcurrentPage(response._meta.currentPage);
        }
      }
      setLoading(false);
    },
    [props.onSubmitSearch, props.uuid, selectedOption]
  );

  // useEffect(() => {
  //   if (props.uuid) {
  //     getData();
  //   }
  // }, [props.uuid]);

  useEffect(() => {
    if (props.uuid) getData(null, selectedOption);
  }, [props.onSubmitSearch, selectedOption, props.uuid, getData]);

  const onClick = () => {
    if (props.onClick) {
      props.onClick();
    }
  };

  const setSelectedOption = (obj) => {
    setselectedOption(obj.value);
  };

  return (
    <Fragment>
      <div>
        {props.tooltip ? (
          <div className="card-header justify-content-start ">
            <h3 className="mr-3">File Integrity Monitoring</h3>
            <TooltipPro
              className="bottom tsize-400"
              style={{ zIndex: 10 }}
              tooltip={
                <div className="row">
                  <div className="col-md-12">
                    <p>
                      If you have enabled folder change monitoring, then this
                      widget lists the files that have been modified in that
                      folder or any sub folder within that folder. This does not
                      list the modification in files in subfolders that have
                      specifically been excluded in settings or any file type
                      that has explicitly been disabled for tracking. It
                      explicitly states if the file has been modified, or new
                      file has been added or file has been deleted.
                    </p>
                  </div>
                </div>
              }
            >
              <AiOutlineInfoCircle className="infoIcon" />
            </TooltipPro>
          </div>
        ) : (
          <div className="card-header">
            <h3>File Integrity Monitoring</h3>
          </div>
        )}
        {!props.serverChartsShow && (
          <PageLoader isLoading={isLoading}></PageLoader>
        )}
        <div className="table-responsive" onClick={() => onClick()}>
          <table className="table table-bordered">
            <thead>
              <tr>
                {!props.serverChartsShow && <th scope="col">ID</th>}
                <th scope="col" style={{ minWidth: "142px" }}>
                  {" "}
                  Reported Timestamp
                </th>
                <th scope="col">File Full Path</th>
                <th scope="col">Type</th>
                {!props.serverChartsShow && <th scope="col">Size (Bytes)</th>}
                {!props.serverChartsShow && <th scope="col">Mime Type</th>}
                {!props.serverChartsShow && <th scope="col">User</th>}
                {!props.serverChartsShow && <th scope="col">Group Name</th>}
                {!props.serverChartsShow && (
                  <th scope="col">File Permisssion</th>
                )}
                {!props.serverChartsShow && (
                  <th scope="col">File Modified Date</th>
                )}
                {!props.serverChartsShow && <th scope="col">Malware Status</th>}
              </tr>
            </thead>
            <tbody>
              {Array.isArray(data) &&
                data.map((obj, index) => {
                  return (
                    <tr key={index}>
                      {!props.serverChartsShow && <td>{obj.id}</td>}
                      <td>{obj.timestamp}</td>
                      <td
                        style={
                          obj.matched === 1
                            ? { color: colorById(obj.uniqe_id) }
                            : {}
                        }
                      >
                        {obj.file_full_path}
                      </td>
                      <td>{obj.type_to_show}</td>
                      {!props.serverChartsShow && <td>{obj.size_kb}</td>}
                      {!props.serverChartsShow && <td>{obj.mime_type}</td>}
                      {!props.serverChartsShow && <td>{obj.user}</td>}
                      {!props.serverChartsShow && <td>{obj.group_name}</td>}
                      {!props.serverChartsShow && (
                        <td>{obj.file_permisssion}</td>
                      )}
                      {!props.serverChartsShow && <td>{obj.date_modified}</td>}
                      {!props.serverChartsShow && (
                        <td>
                          {parseInt(obj.malware_status) === 1 ? (
                            <div style={{ zIndex: 22 }}>
                              <TooltipPro
                                className="right tsize-400"
                                style={{ zIndex: 10 }}
                                tooltip={
                                  <div>
                                    <h6>Malware Type: {obj.malware_type}</h6>
                                    <h6>
                                      Malware Pattern: {obj.malware_pattern}
                                    </h6>
                                  </div>
                                }
                              >
                                Malware Detected
                              </TooltipPro>
                            </div>
                          ) : (
                            <>Malware not Detected</>
                          )}
                        </td>
                      )}
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
        <div className="paginationSec">
          {!props.serverChartsShow && (
            <div className="totalPage">
              <Select
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
                options={[
                  { label: 10, value: 10 },
                  { label: 30, value: 30 },
                  { label: 50, value: 50 },
                  { label: 70, value: 70 },
                  { label: 100, value: 100 },
                ]}
              ></Select>
            </div>
          )}
          <Pagination
            onClickPage={getData}
            pageCount={pageCount}
            currentPage={currentPage}
          ></Pagination>
        </div>
      </div>
    </Fragment>
  );
};

export default BackupFolderLsting;
