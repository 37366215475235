import React, { useEffect, useMemo, useState } from "react";
import BarStackChart from "../Charts/BarStackChart";
import { graphService } from "../../_services/servicesWithSecondOrigin/index";
import { Loader } from "../../_components/CommonComponents/index";
import { throttle } from "../../_helpers/Common/Common";

const StatisticsLatencyOperation = (props) => {
  const [xAxis, setXAxis] = useState(false);
  const [yAxis, setyAxis] = useState(false);
  const [series, setSeries] = useState(false);
  const [seriesFinal, setSeriesFinal] = useState(false);
  const [setUnSet, setUnSetUI] = useState(true);
  const [dataSizeType, setDataSizeType] = useState(" s");
  const [loader, setLoader] = useState(true);
  const [legend, setLegend] = useState([]);
  const [flagg, setFlagg] = useState(0);

  useEffect(() => {
    if (props.uuid) {
      setFlagg(1);
      getGraphDataFromServer();
    }
  }, [props.uuid, props.mytimezone, props.unique_id]);

  useEffect(() => {
    if (
      flagg === 1 &&
      props.uuid &&
      Array.isArray(props.dbName) &&
      props.dbName?.length !== 0
    ) {
      getGraphDataFromServer();
    }
  }, [props.dbName]);

  const parseArr = (val) => {
    if (!val) return 0;
    return parseFloat(parseFloat(val) / 1000).toFixed(2);
  };

  const onEvents = useMemo(
    () => ({
      click: throttle(({ componentType, targetType, value }) => {
        if (componentType !== "yAxis" || targetType !== "axisLabel") return;
        const index = yAxis.data.findIndex((label) => label === value);
        if (index === -1) return;

        setSeriesFinal((prev) =>
          prev.map((s, idx) => ({
            ...s,
            data: s.data.map((n, i) =>
              i !== index ? n : n === 0 ? series[idx].data[i] : 0
            ),
          }))
        );
      }, 500),
    }),
    [series, yAxis.data]
  );

  const getGraphDataFromServer = async () => {
    const emptySeries = [
      {
        name: "",
        data: [""],
        type: "bar",
        stack: "total",
        // label: {
        //     show: true
        // },
        emphasis: {
          focus: "series",
        },
      },
    ];
    setSeries(emptySeries);
    setSeriesFinal(emptySeries);
    setLoader(true);
    let data = {};

    // if (props.limit) data.limit = props.limit;
    if (props.top) {
      data = {
        uuid: props.uuid,
        timezone: props.mytimezone,
        top: props.top ? props.top : "",
        timediff: new Date().getTimezoneOffset(),
      };
    } else {
      data = {
        uuid: props.uuid,
        timezone: props.mytimezone,
        timediff: new Date().getTimezoneOffset(),
      };
    }

    if (props.unique_id) {
      data.unique_id = props.unique_id;
    }

    let dataobj = {
      update_latency: [],
      fetch_latency: [],
      insert_latency: [],
      delete_latency: [],
      table_name: [],
    };
    let resData = [];
    let response = await graphService.getMysqlStatisticsLatencyDetails(
      data,
      props.top
    );
    let SeriesType = [
      "update_latency",
      "fetch_latency",
      "insert_latency",
      "delete_latency",
    ];

    if (Array.isArray(props.dbName) && response && response.data) {
      if (props.callBackSetStatisticsLatencyDate) {
        props.callBackSetStatisticsLatencyDate(response.data.date);
      }

      Array.isArray(response.data.fetch_latency) &&
        response.data.fetch_latency.map((data, index) => {
          let dataobjj = {
            update_latency: response.data.update_latency[index],
            fetch_latency: response.data.fetch_latency[index],
            insert_latency: response.data.insert_latency[index],
            delete_latency: response.data.delete_latency[index],
            table_name: response.data.table_name[index],
          };
          resData.push(dataobjj);
        });

      resData.sort((a, b) =>
        a.fetch_latency < b.fetch_latency
          ? 1
          : a.fetch_latency > b.fetch_latency
          ? -1
          : 0
      );

      resData.map((mObject) => {
        if (
          (props.dbName.length > 0 &&
            props.dbName.indexOf(mObject.table_name) > -1 &&
            dataobj.fetch_latency.length < 10) ||
          (props.dbName.length === 0 && dataobj.fetch_latency.length < 10)
        ) {
          dataobj.update_latency.push(parseArr(mObject.update_latency));
          dataobj.fetch_latency.push(parseArr(mObject.fetch_latency));
          dataobj.insert_latency.push(parseArr(mObject.insert_latency));
          dataobj.delete_latency.push(parseArr(mObject.delete_latency));
          dataobj.table_name.push(mObject.table_name);
        }
      });

      response.data = dataobj;
    }

    if (props.isLegend) {
      setLegend(SeriesType);
    }
    SeriesType.map((dataob) => {
      Array.isArray(response?.[dataob]) &&
        response[dataob].map((vv, i) => {
          response[dataob][i] = parseInt(vv) ? parseInt(vv) : "";
        });
    });
    if (response && response.data) {
      if (Array.isArray(response.data.table_name)) {
        setyAxis({
          type: "category",
          data: response.data.table_name,
          triggerEvent: true,
        });
      } else {
        setyAxis({
          type: "category",
          data: ["y"],
        });
      }

      const Series = [];
      Array.isArray(SeriesType) &&
        SeriesType.map((object) => {
          Array.isArray(response.data[object]) &&
            Series.push({
              name: object,
              data: response.data[object],
              type: "bar",
              stack: "total",
              // label: {
              //     show: true
              // },
              emphasis: {
                focus: "series",
              },
            });
        });
      setUnSetUI(true);
      if (Series.length === 0) {
        const emptySeries = [
          {
            name: "",
            data: [""],
            type: "bar",
            stack: "total",
            // label: {
            //     show: true
            // },
            emphasis: {
              focus: "series",
            },
          },
        ];
        setSeries(emptySeries);
        setSeriesFinal(emptySeries);
      } else {
        setSeries(Series);
        setSeriesFinal(Series);
      }
      setLoader(false);
    } else {
      setLoader(false);
    }
  };

  return (
    <>
      <div className="chart-loader">
        {" "}
        <Loader loader={loader}></Loader>
      </div>
      {setUnSet && (
        <BarStackChart
          xAxis={xAxis}
          yAxis={yAxis}
          series={seriesFinal}
          dataSizeType={dataSizeType}
          legend={legend}
          onEvents={onEvents}
          {...props}
        ></BarStackChart>
      )}
    </>
  );
};
export default StatisticsLatencyOperation;
