import React, {
    useEffect,
    useState
} from 'react'
import LineStackChart from '../Charts/LineStackChart'
import { Loader } from '../../_components/CommonComponents/index'
const ChartDatabaseSchemaOperation = ({ ...props }) => {
    const [xAxis, setXAxis] = useState(false)
    const [yAxis, setyAxis] = useState(false)
    const [series, setSeries] = useState(false)
    const [setUnSet, setUnSetUI] = useState(true)
    const [dataSizeType, setDataSizeType] = useState("")
    const [loader, setLoader] = useState(true)
    const [legend, setLegend] = useState([])

    useEffect(() => {
        if (props.chartData)
            getGraphDataFromServer()
    }, [props.chartData])

    const getGraphDataFromServer = async () => {
        setLoader(true)
        let response = props.chartData

        if (response && response) {
            setUnSetUI(false)
            if (Array.isArray(response.timestamp)) {
                setXAxis({
                    type: 'category',
                    data: response.timestamp
                })
            } else {
                setXAxis({
                    type: 'category',
                    triggerEvent: true,
                    data: []
                })
            }
            const Series = []
            let SeriesType = Object.keys(response)
            Array.isArray(SeriesType) && SeriesType.map((object) => {
                if (Array.isArray(response[object]) && object !== "timestamp") {
                    setDataSizeType("")
                    response[object].map((check, index) => {
                        response[object][index] = (parseFloat(check)).toFixed(2)
                    })
                }
              
                if (props.isLegend  && object !== "timestamp") {
                    setLegend(SeriesType)
                }

               if(object !== "timestamp"){ Array.isArray(response[object]) && Series.push({
                    name: object,
                    data: response[object],
                    type: 'line',
                    smooth: true,
                })}
            })
            setUnSetUI(true)
            if (Series.length === 0) {
                setSeries([{
                    name: "",
                    data: [0],
                    type: 'line',
                    smooth: true,
                }])
            } else {
                setSeries(Series)
            }
            setLoader(false)
        } else {
            setLoader(false)
        }
    }

    return <>
        <div className="chart-loader"> <Loader loader={loader}></Loader></div>
        {setUnSet && <LineStackChart
            xAxis={xAxis}
            yAxis={yAxis}
            series={series}
            dataSizeType={dataSizeType}
            legend={legend}
            {...props}
        ></LineStackChart>}</>
}
export default ChartDatabaseSchemaOperation