import React, {
    useEffect,
    useState
} from 'react'
import moment from 'moment'
import OpenPortChart from '../Charts/OpenPortChart'
import { graphService } from '../../_services/servicesWithSecondOrigin/index'
import { allData } from './Constants'
import { Loader } from '../../_components/CommonComponents/index'
const OpenPortChartOperationPublic = ({ ...props }) => {
    const [xAxis, setXAxis] = useState(false)
    const [yAxis, setyAxis] = useState(false)
    const [series, setSeries] = useState(false)
    const [setUnSet, setUnSetUI] = useState(true)
    const [dataSizeType, setDataSizeType] = useState("")
    const [loader, setLoader] = useState(true)
    const [allobj, setAllObj] = useState(true)
    useEffect(() => {
        if (props.uuid)
            getGraphDataFromServer()
    }, [props.uuid, props.active, props.start_datetime, props.end_datetime, props.isRefresh, props.isRefresh, props.mytimezone,props.unique_id])

    const getGraphDataFromServer = async () => {
        setUnSetUI(false)
        setSeries([{
            name: "",
            data: [],
            type: 'line',
            smooth: true,
        }])
        setyAxis({
            type: 'category',
            data: ["3:00"]
        })
        setUnSetUI(true)
        setLoader(true)
        var d = new Date();
        var n = d.getTimezoneOffset();
        let data = {
            "uuid": props.uuid,
            "start_datetime": props.start_datetime ? props.start_datetime : moment(new Date()).add(-props.active || -4, 'hours').format("YYYY-MM-DD HH:mm:ss"),
            "end_datetime": props.end_datetime ? props.end_datetime : moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
            "timezone": props.mytimezone,
            timediff: n
        }

        if(props.unique_id){
            data.unique_id = props.unique_id
        }

        let response = await graphService.getOpenPortGraphDataPublic(data)

        if (response && response.data) {
            setXAxis({
                type: 'category',
                data: response.data.timestamp
            })

            if (response.data && response.data.data && typeof response.data.data === 'object') {
                setAllObj(response.data.data)
                let objArray = Object.keys(response.data.data)
                setyAxis({
                    type: 'category',
                    data: objArray
                })
                let Series = []
                objArray.map((port, index) => {

                    Series.push({
                        name: port.toString(),
                        data: response.data.data[port],
                        type: 'line',
                        smooth: true,
                    })
                })

                setSeries(Series)
                setLoader(false)
            }
            setLoader(false)
        } else {
            setSeries([])
            setLoader(false)
            setyAxis({
                type: 'category',
                data: []
            })
            setXAxis({
                type: 'category',
                data: []
            })
        }
    }

    return <>
        <div className="chart-loader"> <Loader loader={loader}></Loader></div>
        {setUnSet && <OpenPortChart
            xAxis={xAxis}
            yAxis={yAxis}
            series={series}
            dataSizeType={dataSizeType}
            allData={allData.data}
            allobj={allobj}
            publicPort={true}
            {...props}
        ></OpenPortChart>}</>
}
export default OpenPortChartOperationPublic