import React, { useEffect, useState } from "react";
import { Pagination } from "../../_components/CommonComponents";
import { Table } from "../../_components/MTable/MTable";
import { creditsServices } from "../../_services/servicesWithSecondOrigin";
import { useCreditDetailsLog } from "./_hook";
import Loader from "../../_components/CommonComponents/PageLoader/Loader";
const PAGE_SIZE = 10;

function CreditsList({ type, Fields }) {
  const [data, getData, page, totalPage] = useCreditDetailsLog();
  useEffect(() => {
    getData(type, 1, PAGE_SIZE);
  }, []);
  return (
    <div>
      <Table
        Fields={Fields}
        data={data}
        noRecord={"No data available"}
        headerWithoutData={false}
      />
      {+totalPage > +PAGE_SIZE && (
        <div className="d-flex justify-content-center">
          <Pagination
            onClickPage={(pageno) => getData(type, pageno, PAGE_SIZE)}
            pageCount={Math.ceil(totalPage / PAGE_SIZE) || 1}
            currentPage={page}
          ></Pagination>
        </div>
      )}
    </div>
  );
}

export default React.memo(CreditsList);

export function UsedCreditsList({ type, Fields }) {
  const [data, getData, page, totalPage] = useCreditDetailsLog();
  useEffect(() => {
    getData(type, 1, PAGE_SIZE);
  }, []);

  const [current_open, setCurrent] = useState("");
  return (
    <div>
      <div className="">
        <div className="row">
          <div className="col-md-6">
            <h4>Used Credits</h4>
          </div>
          <div className="col-md-6">
            <h4>Date</h4>
          </div>
        </div>
        {Array.isArray(data) && data.length !== 0 ? (
          data.map((data, index) => {
            return (
              <CustomAccordion
                title={
                  <div className="row">
                    <div className="col-md-6">{data.credits}</div>
                    <div className="col-md-6">{data.spent_date}</div>
                  </div>
                }
                open={current_open}
                current={index + 1}
                setOpen={(e) => setCurrent(e !== current_open ? e : "")}
              >
                {" "}
                {current_open === index + 1 && (
                  <CreditsListForUser date={data.spent_date} Fields={Fields} />
                )}
              </CustomAccordion>
            );
          })
        ) : (
          <div>No data available</div>
        )}
      </div>
      {/* <Table
        Fields={Fields}
        data={data}
        noRecord={"No data available"}
        headerWithoutData={false}
      />
      {+totalPage > +PAGE_SIZE && (
        <div className="d-flex justify-content-center">
          <Pagination
            onClickPage={(pageno) => getData(type, pageno, PAGE_SIZE)}
            pageCount={Math.ceil(totalPage / PAGE_SIZE) || 1}
            currentPage={page}
          ></Pagination>
        </div>
      )} */}
    </div>
  );
}

const CustomAccordion = ({ open, current, setOpen, children, title }) => {
  return (
    <div className="card mb-1">
      <div
        className="card-header dash-header accordion-headings"
        onClick={() => setOpen(current)}
      >
        <h3 className="w-100">{title}</h3>
      </div>
      <div
        className="card-body"
        style={{ display: open !== current ? "none" : "block" }}
      >
        {children}
      </div>
    </div>
  );
};

function CreditsListForUser({ date, type, Fields }) {
  const [data, getData] = useState([]);

  const [loader, setLoader] = useState(false);
  useEffect(() => {
    setLoader(true);
    creditsServices
      .getUserUserCreditsList({
        date,
      })
      .then((res) => {
        setLoader(false);
        getData(res.data);
      });
  }, []);
  return (
    <div>
      <Loader isLoading={loader}></Loader>
      <Table
        Fields={Fields}
        data={data}
        noRecord={"No data available"}
        headerWithoutData={false}
      />
      {/* {+data?.length > +PAGE_SIZE && (
        <div className="d-flex justify-content-center">
          <Pagination
            onClickPage={(pageno) => getData(type, pageno, PAGE_SIZE)}
            pageCount={Math.ceil(data?.length / PAGE_SIZE) || 1}
            currentPage={page}
          ></Pagination>
        </div>
      )} */}
    </div>
  );
}
