import React from "react";

const TooltipPro = (props) => {
    return (
        <span className={props.className ? props.className + " tooltip" : "tooltip"} style={props.style}>
            {props.children}
            {props.tooltip && <span className="tooltiptext tooltip-top">{props.tooltip}</span>}
        </span>
    );
};

export default TooltipPro;
