import React from "react";
import toastr from "toastr";
import { confirmAlert } from "react-confirm-alert";
import { MdRefresh } from "react-icons/md";
import Header from "../../_components/CommonComponents/Header/Header";
import DashboardCard from "../../_components/DashboardCard/DashboardCard";
import PageLoader from "../../_components/CommonComponents/PageLoader/Loader";
import AddNewDomain from "../../_components/DashboardCard/AddNewDomain";
import { dashBoardServices, helperService } from "../../_services/servicesWithSecondOrigin/index";
// import g8keeper_dash from "../../assets/img/g8keeper_dash.png"
import defaultDashboard from "../../assets/img/blank-dashboard.png";
import topArrow from "../../assets/img/top-arrow.svg"
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            allDomain: [],
            isLoading: true,
            myTimezone: "",
            allSharedDomainData: [],
            //isRefresh:true
        };
    }

    async componentDidMount() {
        this.getAllDomain();

        var timezone_offset_minutes = new Date().getTimezoneOffset();
        timezone_offset_minutes = timezone_offset_minutes == 0 ? 0 : -timezone_offset_minutes;
        let data = {
            timezone: timezone_offset_minutes,
        };
        let response = await helperService.getTimeZoon(data);
        if (response && response.data) {
            this.setState({ myTimezone: response.data });
            localStorage.setItem("Timezone", response.data);
        }
    }

    refresh = () => {
        this.getAllDomain();
    }

    getAllDomain = async () => {
        this.setState({
            isLoading: true,
            allDomain: [],
            allSharedDomainData: []
        });
        let response = await dashBoardServices.getAllDomainWithViewData();

        if (response && response.data) {
            this.setState({
                allDomain: Array.isArray(response.data)
                    ? response.data.sort((a, b) => (a.domain_name.toLowerCase() > b.domain_name.toLowerCase() ? 1 : a.domain_name.toLowerCase() < b.domain_name.toLowerCase() ? -1 : 0))
                    : [],
                allSharedDomainData: Array.isArray(response.Shareddata)
                    ? response.Shareddata.sort((a, b) => (a.domain_name.toLowerCase() > b.domain_name.toLowerCase() ? 1 : a.domain_name.toLowerCase() < b.domain_name.toLowerCase() ? -1 : 0))
                    : [],
            });
        }
        this.setState({
            isLoading: false,
        });
    };

    deleteDomain = (id) => {
        confirmAlert({
            title: "Confirm to Delete",
            message: <p>Are you sure to do this.</p>,
            buttons: [
                {
                    label: "Yes",
                    onClick: async () => {
                        let response = await dashBoardServices.deleteDomain(id);
                        if (response && response.message) {
                            toastr.info(response.message);
                        }
                        if (response && response.status === 200) {
                            this.getAllDomain();
                        }
                    },
                },
                {
                    label: "No",
                    onClick: () => console.log("No"),
                },
            ],
        });
    };

    removeDomain = (domain) => {
        confirmAlert({
            title: "Confirm to Delete",
            message: <p>Are you sure to do this.</p>,
            buttons: [
                {
                    label: "Yes",
                    onClick: async () => {
                        let data = {
                            domain_name: domain,
                        };
                        let response = await dashBoardServices.removeDomainFromDashboard(data);
                        if (response && response.message) {
                            toastr.info(response.message);
                        }
                        if (response && response.status === 200) {
                            this.getAllDomain();
                        }
                    },
                },
                {
                    label: "No",
                    onClick: () => console.log("No"),
                },
            ],
        });
    };

    render() {
        const { allDomain, allSharedDomainData } = this.state;
        return (
            <div>
                <Header history={this.props.history} {...this.props}></Header>
                <PageLoader isLoading={this.state.isLoading}></PageLoader>
                <div className="seo-monitoring">

                    <div className="container">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="card">
                                <div className="card-header dash-header">
                                    <h3>List of domains and configurations</h3>
                                    <div className="d-flex">
                                        <AddNewDomain callBack={() => this.getAllDomain()}></AddNewDomain>
                                        <OverlayTrigger overlay={<Tooltip>Refresh</Tooltip>}>
                                            <MdRefresh size="20" className="iconBtn" onClick={() => this.refresh()}></MdRefresh>
                                        </OverlayTrigger>
                                    </div>
                                </div>
                                {Array.isArray(allDomain) && allDomain.length > 0 ? <div className="card-body mp-0">
                                <div className="table-responsive">
                                    <table className="table table-bordered mb-0">
                                        {allDomain.length > 0 && <thead>
                                            <tr>
                                                <th scope="col">S.No.</th>
                                                <th scope="col">Domain </th>
                                                <th scope="col">Status</th>
                                                <th scope="col">Notifications</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>}
                                        <tbody>
                                            {Array.isArray(allDomain) && allDomain.length > 0 &&
                                                allDomain.map((obj, index) => {
                                                    return (
                                                        <DashboardCard
                                                            myTimezone={this.state.myTimezone}
                                                            removeDomain={this.removeDomain}
                                                            object={obj}
                                                            history={this.props.history}
                                                            deleteDomain={this.deleteDomain}
                                                            key1={index}
                                                            key={index}
                                                        ></DashboardCard>
                                                    );
                                                })}
                                        </tbody>
                                    </table>
                                    </div>
                                    </div> :
                                    Array.isArray(allSharedDomainData) && allSharedDomainData.length < 1 &&
                                    <div className="blankPageUI">
                                        <div className="row">
                                            <div className="notActiveTxt col-md-6">
                                                <p className="notactivedata">
                                                    {/* <strong>Oops!</strong> */}
                                                    <strong>  Welcome to G8keeper!</strong>
                                                    It seems you have not added any domains yet.
                                                    {/* We do not see any domain configured yet. */}
                                                </p>
                                                <p>Let me help you get started, its as simple as clicking here and add the domain on pop-up.</p>
                                                <p>We shall configure most of the things automatically and you will be able to monitor all details.</p>
                                                <div>
                                                    <AddNewDomain callBack={() => this.getAllDomain()} getStarter={true}></AddNewDomain>
                                                </div>
                                            </div>
                                            <div className="text-center col-md-6">
                                                <div className="defaultImg">
                                                    <img src={topArrow} className="topArrow" />
                                                    <img src={defaultDashboard} alt="default-dashboard" className="img-fluid" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                            </div>
                            {Array.isArray(allSharedDomainData) && allSharedDomainData.length > 0 && (
                                <div className="col-md-12">
                                    <div className="card">
                                    <div className="card-header"><h3>Shared Domain</h3></div>
                                    <div className="card-body mp-0">
                                      <div className="table-responsive">
                                        <table className="table table-bordered mb-0">
                                            <thead>
                                                <tr>
                                                    <th scope="col">S.No.</th>
                                                    <th scope="col">Domain </th>
                                                    <th scope="col">Status</th>
                                                    <th scope="col">Shared By</th>
                                                    <th scope="col">Notifications</th>
                                                    <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {allSharedDomainData.map((obj, index) => {
                                                    return (
                                                        <DashboardCard
                                                            sharedby={true}
                                                            myTimezone={this.state.myTimezone}
                                                            removeDomain={this.removeDomain}
                                                            object={obj}
                                                            history={this.props.history}
                                                            deleteDomain={this.deleteDomain}
                                                            key1={index}
                                                            key={index}
                                                        ></DashboardCard>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}
export default Dashboard;
