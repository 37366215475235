import { RestMethodSecondOrigin } from '../../../_helpers/ApiConfigTwo/RestMethod'
import FilterResponse from '../../../_helpers/FilterResponse'
import {isAuthorized} from '../../../_helpers/Common/Common'

export const dashBoardServices = {
    getAllDomain,
    deleteDomain,
    removeDomainFromDashboard,
    getAllDomainWithViewData
}

async function getAllDomain(data) {
    try {
        const response = await RestMethodSecondOrigin.GET('/domain/all-domain');
        const api_response = FilterResponse(response);
        return api_response;
    }
    catch (error) {
        isAuthorized(error)
        console.log("error detected while fetching data from api");
        return null;
    }
}

async function getAllDomainWithViewData(data) {
    try {
        const response = await RestMethodSecondOrigin.GET('/domain/domain-dashboard');
        const api_response = FilterResponse(response);
        return api_response;
    }
    catch (error) {
        isAuthorized(error)
        console.log("error detected while fetching data from api");
        return null;
    }
}

async function deleteDomain(id) {
    try {
        const response = await RestMethodSecondOrigin.GET('/domain/delete-domain?id='+id);
        const api_response = FilterResponse(response);
        return api_response;
    }
    catch (error) {
        isAuthorized(error)
        console.log("error detected while fetching data from api");
        return null;
    }
}

async function removeDomainFromDashboard(data) {
    try {
        const response = await RestMethodSecondOrigin.POST('/domain-share/remove-shared',data);
        const api_response = FilterResponse(response);
        return api_response;
    }
    catch (error) {
        isAuthorized(error)
        console.log("error detected while fetching data from api");
        return null;
    }
}