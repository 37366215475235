import { useMemo, useState } from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { TooltipPro } from "../../_components/CommonComponents/index";
import DiskIOWaitActualTable from "../../_components/DiskIOWaitActualTabs/DiskIOWaitActualTabs";

export default function DiskIOProcess({
  diskIOKeyList,
  diskIOList,
  disk_io_stats_date,
  tableName,
  hideSearch,
}) {
  const [search, setSearch] = useState("");

  const tableList = useMemo(
    () =>
      !search
        ? diskIOList
        : diskIOList.filter(
            (row) =>
              row.PID?.toString().includes(search) ||
              row.Process?.toLowerCase().includes(search.toLowerCase())
          ),
    [diskIOList, search]
  );

  return (
    <div className="col-md-6">
      <div className="card overflow-visible fixedChartTable">
        <div className="card-header">
          <h3 className="mr-3">
            {tableName ? tableName : "Disk IO Process"}
            <TooltipPro
              className="bottom tsize-400 ml-3 mt-1 d-inline-block"
              style={{ zIndex: 10 }}
              tooltip={
                <div className="row">
                  <div className="col-md-12">
                    <p>
                      This shows the processes that are causing the actual Disk
                      Input and Output operations. This also shows how much Disk
                      IO is being done by each process (KB/s).
                    </p>
                  </div>
                </div>
              }
            >
              <AiOutlineInfoCircle className="infoIcon" />
            </TooltipPro>
          </h3>
          <div className="mb-0 pull-right">
            <strong> {disk_io_stats_date}</strong>
          </div>
          {!hideSearch && (
            <div className="text-right">
              <input
                type="search"
                className="form-control"
                placeholder="Search"
                onChange={(e) => setSearch(e.target.value)}
                style={{
                  fontSize: 12,
                  height: 32,
                }}
              />
            </div>
          )}
        </div>
        {/* <div className="p-3">
                <div className="row align-items-center">
                    <div className="col-5">
                        <Button className="float-left" onClick={() => this.getActualDiskIo()} color={diskIOType === "disk_io_process" ? "primary" : "secondary"}>
                            Disk IO Process
                        </Button>
                        <TooltipPro
                            className="bottom tsize-400 ml-3 mt-1 d-inline-block"
                            style={{ zIndex: 10 }}
                            tooltip={
                                <div className="row">
                                    <div className="col-md-12">
                                        <p>
                                            This shows the processes that are causing the actual Disk Input and Output operations. This also shows how much
                                            Disk IO is being done by each process (KB/s).
                                        </p>
                                    </div>
                                </div>
                            }
                        >
                            <AiOutlineInfoCircle className="infoIcon" />
                        </TooltipPro>
                    </div>
                    <div className="col-4" style={{ textAlign: "end" }}>
                        {disk_io_stats_date && disk_io_stats_date}
                    </div>
                    {/* <div className="col-5">
                        <Button
                            className="float-right"
                            onClick={() => this.getActualDiskIoWait()}
                            color={diskIOType === "disk_io_wait_process" ? "primary" : "secondary"}
                        >
                            Disk IO Wait Process
                        </Button>
                    </div> 
                </div>
            </div> */}
        <DiskIOWaitActualTable keyList={diskIOKeyList} tableList={tableList} />

        {/* {activeTab == 1 &&
            <DiskIOWaitActualTabs
                uuid={uuid}
                active={active}
                mytimezone={mytimezone}
                dateTime={disk_io_stats_date ? disk_io_stats_date : null}
                activeTab={activeTab}
                isRefresh={isRefresh}
            />
        } */}
      </div>
    </div>
  );
}
