import React, { useState, useEffect } from 'react'
import { Table } from 'reactstrap'
import { Pagination, Loader, Select } from '../../CommonComponents/index'
import { domainDetailsServices } from '../../../_services/servicesWithSecondOrigin/index'
import { BsCheckCircle } from "react-icons/bs";
import { ImCross } from "react-icons/im";

const CustomMalware = (props) => {
    const [pageCount, setPageCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [blackListArr, setBlackListArr] = useState([])
    const [loader, setLoader] = useState(false)
    const [selectedOption, setSelectedOptionData] = useState(10)
    useEffect(() => {
        if (blackListArr && Array.isArray(blackListArr)) {
            let length = blackListArr.length
            let pageCount = parseInt(length / selectedOption)
            if (length % selectedOption !== 0) {
                pageCount = pageCount + 1
            }
            setPageCount(pageCount)
        }
    }, [blackListArr, selectedOption])

    useEffect(() => {
        if (props.domain) {
            getCustomMalware()
        }
    }, [props.domain])

    const setPageNumber = (page) => {
        setCurrentPage(page)
    }

    const getCustomMalware = async () => {
        setLoader(true)
        let objData = {
            "domain_name": props.domain
        }
        setBlackListArr([])
        let request =""
        
            request = await domainDetailsServices.getCustomMalwarelistCall(objData)
         
     
        if (request && request.data && request.data.urls) {
            setBlackListArr(request.data.urls.sort((a, b) => a.status > b.status ? true : a.status < b.status ? -1 : 0))
        }
        setLoader(false)
    }

    const setSelectedOption = (obj) => {
        setSelectedOptionData(obj.value)
        setCurrentPage(1)
    }

    return <div>
        <div className="chart-loader"> <Loader loader={loader}></Loader></div>
        <div className="tableOverflow">
            <Table className="table table-bordered">
                <thead>
                    <tr>
                        <th>S.No.</th>
                        <th>Status</th>
                        <th> URL</th>
                        <th>Type</th>
                    </tr>
                </thead>
                <tbody>
                    {Array.isArray(blackListArr) && blackListArr.map((data, index) => {
                        if (currentPage * selectedOption > index && currentPage * selectedOption < index + 1 + selectedOption)
                            return <tr>
                                <th scope="row">{index + 1}</th>
                                <td>{data.malware_status === "Ok" ? <BsCheckCircle size="20" className="b-ok"></BsCheckCircle> : <ImCross size="20" className="b-list-red"></ImCross>}{data.status}</td>
                                <td>{data.url}</td>
                                <td>{data.malware_type}</td>
                            </tr>
                    })}
                </tbody>
            </Table>
        </div>
        {Array.isArray(blackListArr) && blackListArr.length !== 0 && <div className="paginationSec" >
            <div className="totalPage">
                <Select selectedOption={selectedOption} setSelectedOption={setSelectedOption} options={[{ label: 10, value: 10 }, { label: 30, value: 30 }, { label: 50, value: 50 }, { label: 70, value: 70 }, { label: 100, value: 100 }]}></Select>
            </div>
            <Pagination
                currentPage={currentPage}
                pageCount={pageCount}
                onClickPage={(page) => setPageNumber(page)}
            ></Pagination>
        </div>}
    </div>
}

export default CustomMalware