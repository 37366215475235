import React from "react";
import { Button } from "reactstrap";
import { MdAdd } from "react-icons/md";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SocialMedia from "./SocialMedia";

const SocialMediapupup = ({ show, setShow, ...props }) => {
    const toggle = () => setShow(!show);

    return (
        <div>
            <OverlayTrigger placement="bottom" overlay={<Tooltip>Add / Manage Social Media pages</Tooltip>}>
                <Button className="iconBtn" style={{ fontSize: "18px" }} onClick={toggle}>
                    <MdAdd />
                </Button>
            </OverlayTrigger>

            <div style={{ display: show ? "block" : "none" }}>
                <div className="viewPopUP card">                {/* <div>Social Media</div> */}
                    <div className="card-header ">
                        <h3>Add/Manage Social Media</h3>
                        <button type="button" className ="close" onClick={()=>toggle()}><span aria-hidden="true" onClick={()=>toggle()}>×</span><span className ="sr-only">Close</span></button>
                    </div>
                    <div className="customModalBody pt-3">
                        <SocialMedia popup={true} {...props} callback={toggle}></SocialMedia>
                    </div>
                </div>
                <div className ="modal-backdrop fade show"></div>
            </div>

            {/* 
            <div>
                <div className="viewPopUP" style={{ display: show ? "block" : 'none' }}>
                    <SocialMedia popup={true} {...props} callback={toggle}></SocialMedia>
                </div>
            </div> */}

            {/* <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered isOpen={show} toggle={toggle} className={className}>
                <ModalHeader toggle={toggle}>Social Media</ModalHeader>
                <ModalBody>
                <SocialMedia popup={true} {...props} callback={toggle}></SocialMedia>
                </ModalBody>
            </Modal> */}
        </div>
    );
};

export default SocialMediapupup;
