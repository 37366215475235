import React from "react";
import Header from "../../_components/CommonComponents/Header/Header";
import "./credits.styles.css";
import CreditsList, { UsedCreditsList } from "./CreditsList";
import { useCreditDetails } from "./_hook";

const CreditTypes = {
  PAID_CREDITS: "paid_credits",
  FREE_CREDITS: "free_credits",
  TOTAL_CREDITS: "total_credits",
  USED_CREDITS: "used_credits",
};
function CreditsDetails(props) {
  const [data, current_open, toggle] = useCreditDetails();
  return (
    <div>
      <Header history={props.history} {...props}></Header>
      <div>
        <div className="card m-5">
          <div className="card-header dash-header">
            <h3>Credit Details</h3>
            <div className="d-flex"></div>
          </div>

          <div className="row m-5">
            <div className="col-md-3">
              <div className="credits-counting-box">
                <p>Total Credits</p>
                <h3>{data?.total_credits_count}</h3>
              </div>
            </div>
            <div className="col-md-3">
              <div className="credits-counting-box">
                <p>Paid Credits</p>
                <h3>{data?.paid_credits_count}</h3>
              </div>
            </div>
            <div className="col-md-3">
              <div className="credits-counting-box">
                {" "}
                <p>Free Credits</p>
                <h3>{data?.free_credits_count}</h3>
              </div>
            </div>
            <div className="col-md-3">
              <div className="credits-counting-box">
                <p>Used Credits</p>
                <h3>{data?.used_credits_count}</h3>
              </div>
            </div>
          </div>

          <div className="m-3">
            <CustomAccordion
              title="Total Credits"
              open={current_open}
              current={1}
              setOpen={(e) => toggle(e)}
            >
              {" "}
              <CreditsList
                type={CreditTypes.TOTAL_CREDITS}
                Fields={FieldsTotalCredits}
              />
            </CustomAccordion>
            <CustomAccordion
              title="Paid Credits"
              open={current_open}
              current={2}
              setOpen={(e) => toggle(e)}
            >
              {" "}
              <CreditsList
                type={CreditTypes.PAID_CREDITS}
                Fields={FieldsTotalCredits}
              />
            </CustomAccordion>
            <CustomAccordion
              title="Free Credits"
              open={current_open}
              current={3}
              setOpen={(e) => toggle(e)}
            >
              {" "}
              <CreditsList
                type={CreditTypes.FREE_CREDITS}
                Fields={FieldsTotalCredits}
              />
            </CustomAccordion>

            <CustomAccordion
              title="Used Credits"
              open={current_open}
              current={4}
              setOpen={(e) => toggle(e)}
            >
              <UsedCreditsList
                type={CreditTypes.USED_CREDITS}
                Fields={FieldsUsedCredits}
              />
            </CustomAccordion>
          </div>
        </div>
      </div>
    </div>
  );
}

const FieldsTotalCredits = [
  {
    field_name: "Credits",
    field_key: "credits",
  },
  {
    field_name: "Expiry Date",
    field_key: "expiry_date",
    style: {},
  },
  {
    field_name: "Credit Type",
    field_key: "credits",
    callBack: (row, index) => {
      return row.is_free === 1 ? "Free" : "Paid";
    },
  },
];

const FieldsUsedCredits = [
  {
    field_name: "Credits",
    field_key: "credits",
  },
  {
    field_name: "Resource Id",
    field_key: "resource_id",
    style: {},
  },
  {
    field_name: "Service Type",
    field_key: "service_type",
    style: {},
  },
  {
    field_name: "Credit Type",
    field_key: "credits",
    callBack: (row, index) => {
      return row.is_free === 1 ? "Free" : "Paid";
    },
  },
  {
    field_name: "Type",
    field_key: "type",
    callBack: (row, index) => {
      return row.type === 1 ? "Buy" : row.type === 2 ? "Spent" : "";
    },
  },
];

export default CreditsDetails;

export const CustomAccordion = ({
  open,
  current,
  setOpen,
  children,
  title,
}) => {
  return (
    <div className="card mb-1">
      <div
        className="card-header dash-header accordion-headings"
        onClick={() => setOpen(current)}
      >
        <h3>{title}</h3>
        <div className="d-flex"></div>
      </div>
      <div
        className="card-body"
        style={{ display: open !== current ? "none" : "block" }}
      >
        {children}
      </div>
    </div>
  );
};

// const AccordionV1 = (props) => {
//   return (
//     <div className="accordion" id="accordionExample">
//       {props.children}
//     </div>
//   );
// };

// const AccordionItemV1 = (props) => {
//   return (
//     <div className="card">
//       <button
//         className="btn btn-link"
//         type="button"
//         data-toggle="collapse"
//         data-target={"#collapse" + props.title}
//         aria-expanded="true"
//         aria-controls={"collapse" + props.title}
//         onClick={() => props.toggle(props.current)}
//       >
//         <div className="card-header" id={"heading" + props.title}>
//           {props.title}
//         </div>
//       </button>
//       {/* + (props.open === props.current ? "show" : "") */}
//       <div
//         id={"collapse" + props.title}
//         className={"collapse "}
//         aria-labelledby={"heading" + props.title}
//         data-parent="#accordionExample"
//       >
//         <div className="card-body">{props.children}</div>
//       </div>
//     </div>
//   );
// };

{
  /* <div className="accordion" id="accordionExample">
              <div className="card">
                <button
                  className="btn btn-link"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                  onClick={() => toggle()}
                >
                  <div className="card-header" id="headingOne">
                    Credits Details
                  </div>
                </button>

                <div
                  id="collapseOne"
                  className={"collapse " + (open === 1 ? "show" : "")}
                  aria-labelledby="headingOne"
                  data-parent="#accordionExample"
                    >
                   <div className="card-body">
                    <table>
                      <tr>
                        <th>Total Credits</th>
                        <th> Used Credits</th>
                      </tr>
                      <tr>
                        <td>Alfreds Futterkiste</td>
                        <td>Maria Anders</td>
                      </tr>
                      <tr>
                        <td>Centro comercial Moctezuma</td>
                        <td>Francisco Chang</td>
                      </tr>
                      <tr>
                        <td>Ernst Handel</td>
                        <td>Roland Mendel</td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header" id="headingTwo">
                  <h2 className="mb-0">
                    <button
                      className="btn btn-link collapsed"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      Paid Credit
                    </button>
                  </h2>
                </div>
                <div
                  id="collapseTwo"
                  className="collapse"
                  aria-labelledby="headingTwo"
                  data-parent="#accordionExample"
                >
                  <div className="card-body">
                    Anim pariatur cliche reprehenderit, enim eiusmod high life
                    accusamus terry richardson ad squid. 3 wolf moon officia
                    aute, non cupidatat skateboard dolor brunch. Food truck
                    quinoa nesciunt
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header" id="headingThree">
                  <h2 className="mb-0">
                    <button
                      className="btn btn-link collapsed"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      Free Credits
                    </button>
                  </h2>
                </div>
                <div
                  id="collapseThree"
                  className="collapse"
                  aria-labelledby="headingThree"
                  data-parent="#accordionExample"
                >
                  <div className="card-body">
                    Anim pariatur cliche reprehenderit, enim eiusmod high life
                    accusamus terry richardson ad squid. 3 wolf moon officia
                    aute, non cupidatat skateboard dolor brunch. Food truck
                    quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor,
                    sunt aliqua put a bird
                  </div>
                </div>
              </div>

              <div className="card">
                <div className="card-header" id="headingThree">
                  <h2 className="mb-0">
                    <button
                      className="btn btn-link collapsed"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      Used Credits
                    </button>
                  </h2>
                </div>
                <div
                  id="collapseThree"
                  className="collapse"
                  aria-labelledby="headingThree"
                  data-parent="#accordionExample"
                >
                  <div className="card-body">
                    Anim pariatur cliche reprehenderit, enim eiusmod high life
                    accusamus terry richardson ad squid. 3 wolf moon officia
                    aute, non cupidatat skateboard dolor brunch. Food truck
                    quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor,
                    sunt aliqua put a bird
                  </div>
                </div>
              </div>
            </div> */
}
{
  /* <AccordionV1>
              <AccordionItemV1
                title="Credits Details"
                current={1}
                toggle={(tab) => toggle(tab)}
                open={open}
              >
                sdfjadsfgjsfdgsdjf
              </AccordionItemV1>
            </AccordionV1>
            <AccordionV1>
              <AccordionItemV1
                title="Paid Credits"
                current={2}
                toggle={(tab) => toggle(tab)}
                open={open}
              >
                sdfjadsfgjsfdgsdjf{" "}
              </AccordionItemV1>
            </AccordionV1>
            <AccordionV1>
              <AccordionItemV1
                title="Free Credits"
                current={3}
                toggle={(tab) => toggle(tab)}
                open={open}
              >
                sdfjadsfgjsfdgsdjf
              </AccordionItemV1>
            </AccordionV1> */
}
