// export const URL_CONFIG = {
//   TERMS_CONDITIONS_URL: "https://g8keeperapp.com/terms-and-conditions/",
//   LOCAL_URL: "https://a1websitetools.com/pyapi",
//   LOCAL_URL_FOR_SECOND_ORIGIN: "https://dev.a1websitetools.com/api/web/v1",
//   BASE_URL: "",
// };

// export const URL_CONFIG = {
// TERMS_CONDITIONS_URL:'https://g8keeperapp.com/terms-and-conditions/',
//     LOCAL_URL: 'https://a1websitetools.com/pyapi',
//     LOCAL_URL_FOR_SECOND_ORIGIN: 'https://a1websitetools.com/api/web/v1',
//     BASE_URL: "/g8keeper"
// }

export const URL_CONFIG = {
  TERMS_CONDITIONS_URL: "https://g8keeperapp.com/terms-and-conditions/",
  LOCAL_URL: "https://api.g8keeper.app/pyapi/",
  LOCAL_URL_FOR_SECOND_ORIGIN: "https://api.g8keeper.app/api/web/v1",
  BASE_URL: "",
};

// export const URL_CONFIG = {
//   TERMS_CONDITIONS_URL: "https://g8keeperapp.com/terms-and-conditions/",
//   LOCAL_URL: "https://api.g8keeper.app/pyapi/",
//   LOCAL_URL_FOR_SECOND_ORIGIN:
//     "http://192.168.1.3/saas-app-base-code/api/web/v1",
//   BASE_URL: "",
// };
