const tokenValidation = [
    {
        field_name: 'my_token',
        label: 'Token',
        type: "string",
        isRequired: true
    }
]

export function Constants() {
    return {tokenValidation}
}