import React from "react";
import { Link } from "react-router-dom";
import Header from "../../_components/CommonComponents/Header/Header";
import {
  UsageChart,
  BackupChartOperation,
  ChartMySqlOperation,
  ChartOperationsApachhe,
  ChartOperationsApachheOuter,
} from "../../_components/MonitorServerChart/index";
import OpenPortChartOperation from "../../_components/OpenPortChartOperation/OpenPortChartOperation";
import {
  DateTime,
  ServerCurrentLoggedIn,
} from "../../_components/CommonComponents/index";
import { Button } from "reactstrap";
import { IoIosArrowRoundBack } from "react-icons/io";
import { MdRefresh, MdRemoveRedEye } from "react-icons/md";
import moment from "moment";
import { graphsKeys } from "./Constants";
import * as echarts from "echarts";
import { graphService } from "../../_services/servicesWithSecondOrigin/index";
import { Fragment } from "react";
import {
  DiskIOAndWaitProcress,
  TopMemoryProcress,
  MySqlCurrentQuery,
} from "../../_components/DiskIOWaitActualTabs/DiskIOWaitActualTabs";
import MoniterUrlDetailsChart from "../../_components/MonitorUrlsListing/MoniterUrlDetailsChart";
import OpenPortChartOperationPublic from "../../_components/OpenPortChartOperation/OpenPortChartOperationPublic";
import { objectToQuery } from "../../_helpers/Common/Common";

class MonitorSettingIndividualChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      uuid: "",
      title: "",
      chart: "",
      user_element: "",
      server_name: "",
      mytimezone: "",
      start_datetime: moment(new Date())
        .add(-4, "hours")
        .format("YYYY-MM-DD HH:mm:ss"),
      end_datetime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      start_datetimeForChart: moment(new Date())
        .add(-4, "hours")
        .format("YYYY-MM-DD HH:mm:ss"),
      end_datetimeForChart: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      type: "",
      unique_id: null,
      echartRefObj: {},
      activeTab: 1,
      activeTabList: 0,
      graphArray: [], // dynamic add or remove chart
      graphShowObj: {
        // dynamic show chart condition
        cpu_usage: false,
        top_process: false,
        disk_io_process: false,
        ram_usage: false,
        swap_usage: false,
        memory_usage: false,
        disk_io_stats: false,
        "network-stats": false,
        "open-port": false,
        "public-open-port": false,
        apache_log_data: false,
        mysql_metadata1_mysql: false,
        mysql_current_query: false,
        "mysql_metadata1_mysql-read-write-count": false,
        "mysql_metadata1_mysql-statement": false,
        "mysql_metadata1_mysql-read-write-data": false,
        "mysql_metadata1_mysql-latency": false,
      },
      graphList: [
        // list fo tab wih chart obj
        {
          id: "cpu_usage",
          user_element: "cpu_usage",
          title: "CPU Usage",
          chart: 1,
          dateTimeName: "md_cpu_usage_date",
          apiCall: true,
          activeTab: 1,
        },
        { id: "top_process", table: true, title: "TOP PROCESS", activeTab: 1 },
        {
          id: "ram_usage",
          user_element: "ram_usage",
          title: "RAM Usage",
          chart: 1,
          apiCall: true,
          activeTab: 1,
        },
        {
          id: "swap_usage",
          user_element: "swap_usage",
          title: "Swap Usage",
          chart: 1,
          apiCall: true,
          activeTab: 1,
        },
        {
          id: "memory_usage",
          user_element: "memory_usage",
          title: "Disk Usage",
          chart: 1,
          apiCall: true,
          activeTab: 1,
        },
        {
          id: "disk_io_stats",
          user_element: "disk_io_stats",
          title: "Disk IO Stats",
          chart: 1,
          apiCall: true,
          dateTimeName: "md_disk_io_process_date",
          activeTab: 1,
        },
        {
          id: "disk_io_process",
          table: true,
          title: "Disk IO PROCESS",
          activeTab: 1,
        },
        {
          id: "network-stats",
          user_element: "network-stats",
          title: "Network Stats",
          chart: 1,
          apiCall: true,
          activeTab: 1,
        },
        {
          id: "open-port",
          user_element: "open-port",
          title: "Ports used by processes",
          chart: 3,
          apiCall: true,
          activeTab: 1,
        },
        {
          id: "public-open-port",
          user_element: "public-open-port",
          title: "Ports open to world",
          chart: 7,
          apiCall: true,
          activeTab: 1,
        },
        {
          id: "apache_log_data",
          user_element: "apache_log_data",
          title: "Apache Traffic",
          chart: 5,
          type: ["InnerData", "InnerData2"],
          activeTab: 2,
        },

        {
          id: "mysql_metadata1_mysql",
          user_element: "mysql_metadata1",
          type: "mysql",
          title: "MySql Connections / Threads",
          dateTimeName: "md_mysql_current_query",
          chart: 4,
          apiCall: true,
          activeTab: 3,
        },
        {
          id: "mysql_current_query",
          table: true,
          title: "Mysql Current Query",
          activeTab: 3,
        },

        {
          id: "mysql_metadata1_mysql-read-write-count",
          user_element: "mysql_metadata1",
          type: "mysql-read-write-count",
          title: "MySql Read Write Count",
          chart: 4,
          apiCall: true,
          activeTab: 3,
        },

        {
          id: "mysql_metadata1_mysql-statement",
          user_element: "mysql_metadata1",
          type: "mysql-statement",
          title: "MySql Statement",
          chart: 4,
          apiCall: true,
          activeTab: 3,
        },
        {
          id: "mysql_metadata1_mysql-read-write-data",
          user_element: "mysql_metadata1",
          type: "mysql-read-write-data",
          title: "MySql Read Write Data",
          chart: 4,
          apiCall: true,
          activeTab: 3,
        },
        {
          id: "mysql_metadata1_mysql-latency",
          user_element: "mysql_metadata1",
          type: "mysql-latency",
          title: "MySql Latency",
          chart: 4,
          apiCall: true,
          activeTab: 3,
        },
      ],

      apachheReq: false,
      apachheFirstReq: true,

      // date
      md_cpu_usage_date: null,
      md_disk_io_process_date: null,
      md_mysql_current_query: null,
    };
  }

  setActiveTab = (tab) => {
    this.setState({ activeTab: tab }, () => {
      if (this.state.activeTab === 2) {
        this.getGraphDataFromServer();
      }
    });
  };

  async componentDidMount() {
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.id
    ) {
      let graphShowObj = this.state.graphShowObj;

      let graphArray = [];
      let apachheReq = false;
      if (this.props.location.state.user_element) {
        graphArray = this.state.graphList.filter(
          (d) => d.user_element == this.props.location.state.user_element
        );

        if (Array.isArray(graphArray) && graphArray.length > 1) {
          graphArray = graphArray.filter(
            (d) => d.type == this.props.location.state.type
          );
        }

        if (graphArray.length === 1) {
          graphShowObj[graphArray[0].id] = true;
        }

        if (this.props.location.state.user_element == "apache_log_data") {
          apachheReq = true;
        }
      }

      this.setState(
        {
          id: this.props.location.state.id,
          uuid: this.props.location.state.uuid,
          title: this.props.location.state.title,
          user_element: this.props.location.state.user_element,
          chart: this.props.location.state.chart,
          server_name: this.props.location.state.server_name,
          mytimezone: this.props.location.state.mytimezone,
          type: this.props.location.state.type,
          graphArray,
          graphShowObj: graphShowObj,
          apachheReq: apachheReq,
          unique_id: this.props.location.unique_id,
        },
        () => {
          //  this.getGraphDataFromServer();
          this.onChnageGetDomainRelatedCharts();
        }
      );
    } else {
      this.props.history.push("/monitor-servers");
    }

    this.timestamp = setInterval(() => {
      // if (localStorage.getItem("topProcess") !== this.state.topProcessDataDate)
      //     this.setState({
      //         topProcessDataDate: localStorage.getItem("topProcess")
      //     })

      if (
        localStorage.getItem("md_cpu_usage_date") !==
        this.state.md_cpu_usage_date
      )
        this.setState({
          md_cpu_usage_date: localStorage.getItem("md_cpu_usage_date"),
        });

      if (
        localStorage.getItem("md_disk_io_process_date") !==
        this.state.md_disk_io_process_date
      )
        this.setState({
          md_disk_io_process_date: localStorage.getItem(
            "md_disk_io_process_date"
          ),
        });
      if (
        localStorage.getItem("md_mysql_current_query") !==
        this.state.md_mysql_current_query
      )
        this.setState({
          md_mysql_current_query: localStorage.getItem(
            "md_mysql_current_query"
          ),
        });
    }, 1);

    // setInterval(() => {
    //     this.setState({
    //         "end_datetime": moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
    //     })
    // }, 2000)

    // window.addEventListener("resize", function () {
    //     "use strict"; window.location.reload();
    // });

    // const toggleTabView = function (element) {

    //     if (!element.nextElementSibling.classList.contains('submenu')) return;

    //     element.nextElementSibling.style.display = element.nextElementSibling.style.display === 'block' ? 'none' : "block";

    // }
    // document.addEventListener("DOMContentLoaded", function () {
    //     // make it as accordion for smaller screens
    //     if (window.innerWidth < 992) {

    //         document.querySelectorAll('.sidebar .nav-link').forEach(function (element) {

    //             // element.addEventListener('click', toggleTabView);
    //             element.addEventListener('mouseenter', () => toggleTabView(element));
    //             element.addEventListener('mouseleave', () => toggleTabView(element));
    //         })
    //     }
    //     // end if innerWidth

    // });
    // DOMContentLoaded  end
  }

  componentWillUnmount() {
    localStorage.setItem("md_cpu_usage_date", "");
    localStorage.setItem("md_disk_io_process_date", "");
    localStorage.setItem("md_mysql_current_query", "");
  }

  /*****************  Api Call Method ***********/
  getGraphDataFromServer = async () => {
    var d = new Date();
    var n = d.getTimezoneOffset();
    let data = {
      uuid: this.state.uuid,
      user_element: "apache_log_data",
      start_datetime: this.state.start_datetime,
      end_datetime: this.state.end_datetime,
      timezone: this.state.mytimezone,
      timediff: n,
      //"unique_id": "00000000-0000-0000-0000-0641a6ca2d4c"
    };

    let response = await graphService.getApachheGraphData(data);
    if (response && response.data && response.data.OuterData) {
      let timestamp = [];

      // set timestamp
      if (response.data && response.data.timestamp)
        timestamp = response.data.timestamp;

      // get all key
      let key = Object.keys(response.data.OuterData);

      // key based data
      let data = response.data.OuterData;

      let graphList = this.state.graphList; // for listing
      let graphArray = this.state.graphArray; // graph obj array
      let graphShowObj = this.state.graphShowObj; // show obj

      if (Array.isArray(key)) {
        for (const element of key) {
          let obj = {
            checkRefresh: true,
            id: element,
            user_element: element,
            title: element,
            chart: 5,
            apiCall: true,
            chartData: data[element],
            timestamp: timestamp,
            dynamic: true,
            isRefesh: Math.random(),
          };

          let index = null;
          if (Array.isArray(graphArray)) {
            graphArray.forEach((data, i) => {
              if (data.user_element == element) index = i;
            });
          }

          // remove obj if exist
          if (index != null) graphArray.splice(index, 1);

          let listIndex = null;
          if (Array.isArray(graphList)) {
            graphList.forEach((data, i) => {
              if (data.user_element == element) listIndex = i;
            });
          }

          //  update graph obj if exist otherwise push
          if (listIndex != null) {
            graphList[listIndex] = obj;
          } else {
            graphList.push(obj);
          }

          if (Array.isArray(graphArray) && graphArray.length > 0) {
            // check req if come from apche page
            if (this.state.apachheReq && this.state.apachheFirstReq) {
              graphArray.push(obj);
              graphShowObj[element] = true;
            } else {
              let index = null;
              graphArray.forEach((data, i) => {
                if (data.user_element == element) index = i;
              });

              // check if chart is true and not exist push
              if (graphShowObj[element] && index == null) {
                graphArray.push(obj);
              } else {
                // set false
                graphShowObj[element] = false;
              }
            }
          }
        }
      }

      let apachheFirstReq = this.state.apachheFirstReq;
      if (this.state.apachheReq) {
        apachheFirstReq = false;
      }

      this.setState({
        graphList: graphList,
        graphShowObj: graphShowObj,
        apachheFirstReq: apachheFirstReq,
      });
    }
  };

  isAcitiveSubLink = (title) => {
    let className = "";
    this.state.graphArray.map((key) => {
      if (title === key.title) {
        className = "active-links";
      }
    });
    return className;
  };

  /*****************  Input Method ***********/
  setStartDateTime = (date) => {
    this.setState(
      {
        start_datetime: moment(date).format("YYYY-MM-DD HH:mm:ss"),
      },
      () => {
        var a = moment(this.state.end_datetime, "YYYY-MM-DD HH:mm:ss");
        var b = moment(this.state.start_datetime, "YYYY-MM-DD HH:mm:ss");
        let c = a.diff(b, "days");
        if (c > 31) {
          this.setState({
            end_datetime: b
              .add(1, "M")
              .add(-1, "D")
              .format("YYYY-MM-DD HH:mm:ss"),
          });
        }
      }
    );
  };

  setEndDateTime = (date) => {
    this.setState(
      {
        end_datetime: moment(date).format("YYYY-MM-DD HH:mm:ss"),
      },
      () => {
        var a = moment(this.state.end_datetime, "YYYY-MM-DD HH:mm:ss");
        var b = moment(this.state.start_datetime, "YYYY-MM-DD HH:mm:ss");
        let c = a.diff(b, "days");
        if (c > 31) {
          this.setState({
            start_datetime: a.add(-1, "M").format("YYYY-MM-DD HH:mm:ss"),
          });
        }
      }
    );
  };

  onChnageGetDomainRelatedCharts = async () => {
    let Timezone = localStorage.getItem("Timezone");
    var d = new Date();
    var n = d.getTimezoneOffset();
    let data = {
      uuid: this.state.uuid,
      start_datetime: this.state.start_datetime,
      end_datetime: this.state.end_datetime,
      timezone: Timezone,
      timediff: n,
    };
    let response = await graphService.getDomainDataChart(data);

    if (response && response.data) {
      let graphList = this.state.graphList.slice();
      let domainArray = [];
      response.data.map((obj) => {
        let check = graphList.filter((obj2) => {
          return obj.url === obj2.id;
        });
        if (check.length === 0) {
          let objData = {
            urlId: obj.id,
            id: obj.url,
            user_element: obj.url,
            title: obj.url,
            chart: 6,
            apiCall: true,
            isRefesh: Math.random(),
            activeTab: 4,
          };
          domainArray.push(objData);
        }
      });
      this.setState({
        graphList: graphList.concat(domainArray),
      });
    }
  };

  onSelectServer = (e) => {
    this.refreshChartList();
    this.setState(
      {
        id: e.id,
        uuid: e.value,
        server_name: e.label,
      },
      () => {
        if (this.state.activeTab === 2) {
          this.getGraphDataFromServer();
        }
        this.onChnageGetDomainRelatedCharts();
      }
    );
  };

  refreshChartList = () => {
    this.setState({
      graphList: this.state.graphList.filter((obj) => {
        return obj.chart !== 6 && !obj.checkRefresh;
      }),
      graphArray: this.state.graphArray.filter((obj) => {
        return obj.chart !== 6 && !obj.checkRefresh;
      }),
    });
  };

  onSelectUniqueId = (e) => {
    this.setState({
      unique_id: e.value,
    });
  };

  /*****************  Normal Method ***********/

  onClickShowGraphData = () => {
    this.setState(
      {
        start_datetimeForChart: this.state.start_datetime,
        end_datetimeForChart: this.state.end_datetime,
      },
      () => {
        if (this.state.activeTab === 2) {
          this.getGraphDataFromServer();
        }
        this.onChnageGetDomainRelatedCharts();
      }
    );
  };

  filterPassedStartDate = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);
    const end_datetime = new Date(moment(this.state.end_datetime));
    return currentDate > selectedDate && end_datetime >= selectedDate;
  };

  filterPassedEndDate = (time) => {
    const currentDate = new Date();
    const start_datetime = new Date(moment(this.state.start_datetime));
    const selectedDate = new Date(time);
    return currentDate > selectedDate && start_datetime <= selectedDate;
  };

  resetTime = () => {
    this.setState(
      {
        start_datetimeForChart: moment(new Date())
          .add(-4, "hours")
          .format("YYYY-MM-DD HH:mm:ss"),
        end_datetimeForChart: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
        start_datetime: moment(new Date())
          .add(-4, "hours")
          .format("YYYY-MM-DD HH:mm:ss"),
        end_datetime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      },
      () => {
        if (this.state.activeTab === 2) {
          this.getGraphDataFromServer();
        }
        this.onChnageGetDomainRelatedCharts();
      }
    );
  };

  addOrRemoveChartsInList = (object) => {
    let graphArray = this.state.graphArray;
    let checkExistance = graphArray.filter((data) => {
      return data.id === object.id;
    });

    if (checkExistance.length === 0) {
      graphArray.push(object);
      this.setState({ graphArray: graphArray });
    } else {
      let index = null;
      graphArray.forEach((data, i) => {
        if (data.id == object.id) index = i;
      });

      if (object.id === "top_process") {
        localStorage.setItem("md_cpu_usage_date", "");
      }

      if (object.id === "mysql_current_query") {
        localStorage.setItem("md_mysql_current_query", "");
      }

      if (object.id === "disk_io_process") {
        localStorage.setItem("md_disk_io_process_date", "");
      }

      if (index != null) graphArray.splice(index, 1);

      this.setState({ graphArray: graphArray });
      // this.setState({ graphArray: graphArray.filter((data) => { return data.user_element !== object.user_element }) })
    }
    // })

    let graphShowObj = this.state.graphShowObj;

    if (object && object.id) graphShowObj[object.id] = !graphShowObj[object.id];

    this.setState({ graphShowObj });
  };

  apiCallUpdate = (index) => {};

  /*****************  Chart Method ***********/

  getRestoreChart = () => {
    let echartRefObj = this.state.echartRefObj;
    for (const key in echartRefObj) {
      if (Object.hasOwnProperty.call(echartRefObj, key)) {
        const myref = echartRefObj[key];
        if (myref && myref.current && myref.current.getEchartsInstance())
          myref.current
            .getEchartsInstance()
            .setOption(myref.current.props.option, true);
      }
    }
  };

  setEchartRef = (ref, id) => {
    let echartRefObj = this.state.echartRefObj;
    if (id) echartRefObj[id] = ref;
    this.setState({ echartRefObj }, () => {
      this.connectCharts(echartRefObj);
    });
  };

  connectCharts = (echartRefObj) => {
    let data = [];
    for (const key in echartRefObj) {
      if (Object.hasOwnProperty.call(echartRefObj, key)) {
        const myref = echartRefObj[key];
        if (myref && myref.current && myref.current.getEchartsInstance())
          data.push(myref.current.getEchartsInstance());
      }
    }

    if (data.length > 1) echarts.connect(data);
  };

  render() {
    let object = {
      id: this.state.id,
      uuid: this.state.uuid,
      update: true,
      server_name: this.state.server_name,
    };
    return (
      <div className="container">
        <Header history={this.props.history} {...this.props}></Header>

        {/* Filter section */}
        <div className="serverDetailsSec justify-content-between">
          <div className="d-flex">
            <Button
              className="backBtn mr-4"
              onClick={() =>
                this.props.history.push(
                  "/monitor-server/charts" + objectToQuery(object),
                  object
                )
              }>
              <IoIosArrowRoundBack></IoIosArrowRoundBack>
            </Button>
            <ServerCurrentLoggedIn
              server_name={this.state.server_name}
              uuid={this.state.uuid}
              onSelectServer={this.onSelectServer}
              mytimezone={this.state.mytimezone}
              unique_id={this.state.unique_id}
              onSelectUniqueId={this.onSelectUniqueId}
            />
          </div>
          <div className="select_startdatetime">
            <div className="row align-items-end">
              <div className="col-md-4">
                <label>Start Date Time</label>
                <DateTime
                  className="form-control"
                  dateTime={this.state.start_datetime}
                  setDateTime={this.setStartDateTime}
                  filterPassedDate={this.filterPassedStartDate}></DateTime>
              </div>
              <div className="col-md-4">
                <label>End Date Time</label>
                <DateTime
                  className="form-control"
                  dateTime={this.state.end_datetime}
                  setDateTime={this.setEndDateTime}
                  filterPassedDate={this.filterPassedEndDate}></DateTime>
              </div>
              <div className="col-md-3 d-flex" style={{ paddingBottom: "1px" }}>
                <Button
                  className="iconBtn"
                  onClick={() => this.onClickShowGraphData()}>
                  <MdRemoveRedEye></MdRemoveRedEye>
                </Button>
                <Button
                  className="iconBtn ml-3"
                  onClick={() => this.resetTime()}>
                  <MdRefresh></MdRefresh>
                </Button>
              </div>
            </div>
          </div>
        </div>

        {/* End */}
        <div className="sidenav">
          <ul class="nav flex-column">
            <li
              class="nav-item"
              onMouseEnter={() =>
                this.setState({ activeTabList: 1 }, () => {
                  this.setActiveTab(1);
                })
              }
              onMouseLeave={() => this.setState({ activeTabList: 0 })}>
              <Button
                onClick={() => this.setActiveTab(1)}
                color={this.state.activeTab === 1 ? "primary" : ""}>
                General
              </Button>
              {this.state.activeTab === 1 && (
                <ul
                  className="listed submenu dropdown-menu"
                  style={{
                    display: this.state.activeTabList === 1 ? "block" : "none",
                  }}>
                  {this.state.graphList.map((d, i) => {
                    return d.activeTab !== this.state.activeTab ? (
                      d.activeTab ? (
                        this.state.activeTab === this.state.activeTabList &&
                        d.activeTab === this.state.activeTab && (
                          <li
                            onClick={() => this.addOrRemoveChartsInList(d)}
                            key={i}
                            className={this.isAcitiveSubLink(d.title)}>
                            <a href="#">{d.title}</a>
                          </li>
                        )
                      ) : (
                        this.state.activeTab === this.state.activeTabList && (
                          <li
                            key={i}
                            className={this.isAcitiveSubLink(d.title)}
                            onClick={() => this.addOrRemoveChartsInList(d)}>
                            <a href="#">{d.title}</a>
                          </li>
                        )
                      )
                    ) : (
                      <li
                        key={i}
                        className={this.isAcitiveSubLink(d.title)}
                        onClick={() => this.addOrRemoveChartsInList(d)}>
                        <a href="#">{d.title}</a>
                      </li>
                    );
                  })}
                </ul>
              )}
            </li>
            <li
              className="nav-item"
              onMouseEnter={() =>
                this.setState({ activeTabList: 1 }, () => {
                  this.setActiveTab(2);
                })
              }
              onMouseLeave={() => this.setState({ activeTabList: 0 })}>
              <Button
                onClick={() => this.setActiveTab(2)}
                color={this.state.activeTab === 2 ? "primary" : ""}>
                Apache
              </Button>
              {this.state.activeTab === 2 && (
                <ul
                  className="listed submenu dropdown-menu"
                  style={{
                    display: this.state.activeTabList === 1 ? "block" : "none",
                  }}>
                  {this.state.graphList.map((d, i) => {
                    return d.activeTab !== this.state.activeTab ? (
                      d.activeTab ? (
                        this.state.activeTab === this.state.activeTabList &&
                        d.activeTab === this.state.activeTab && (
                          <li
                            onClick={() => this.addOrRemoveChartsInList(d)}
                            key={i}
                            className={this.isAcitiveSubLink(d.title)}>
                            <a href="#">{d.title}</a>
                          </li>
                        )
                      ) : (
                        this.state.activeTab === this.state.activeTabList && (
                          <li
                            key={i}
                            className={this.isAcitiveSubLink(d.title)}
                            onClick={() => this.addOrRemoveChartsInList(d)}>
                            <a href="#">{d.title}</a>
                          </li>
                        )
                      )
                    ) : (
                      <li
                        key={i}
                        className={this.isAcitiveSubLink(d.title)}
                        onClick={() => this.addOrRemoveChartsInList(d)}>
                        <a href="#">{d.title}</a>
                      </li>
                    );
                  })}
                </ul>
              )}
            </li>

            <li
              className="nav-item"
              onMouseEnter={() =>
                this.setState({ activeTabList: 1 }, () => {
                  this.setActiveTab(3);
                })
              }
              onMouseLeave={() => this.setState({ activeTabList: 0 })}>
              <Button
                onClick={() => this.setActiveTab(3)}
                color={this.state.activeTab === 3 ? "primary" : ""}>
                MySQL
              </Button>
              {this.state.activeTab === 3 && (
                <ul
                  className="listed submenu dropdown-menu"
                  style={{
                    display: this.state.activeTabList === 1 ? "block" : "none",
                  }}>
                  {this.state.graphList.map((d, i) => {
                    return d.activeTab !== this.state.activeTab ? (
                      d.activeTab ? (
                        this.state.activeTab === this.state.activeTabList &&
                        d.activeTab === this.state.activeTab && (
                          <li
                            onClick={() => this.addOrRemoveChartsInList(d)}
                            key={i}
                            className={this.isAcitiveSubLink(d.title)}>
                            <a href="#">{d.title}</a>
                          </li>
                        )
                      ) : (
                        this.state.activeTab === this.state.activeTabList && (
                          <li
                            key={i}
                            className={this.isAcitiveSubLink(d.title)}
                            onClick={() => this.addOrRemoveChartsInList(d)}>
                            <a href="#">{d.title}</a>
                          </li>
                        )
                      )
                    ) : (
                      <li
                        key={i}
                        className={this.isAcitiveSubLink(d.title)}
                        onClick={() => this.addOrRemoveChartsInList(d)}>
                        <a href="#">{d.title}</a>
                      </li>
                    );
                  })}
                </ul>
              )}
            </li>

            <li
              className="nav-item"
              onMouseEnter={() =>
                this.setState({ activeTabList: 1 }, () => {
                  this.setActiveTab(4);
                })
              }
              onMouseLeave={() => this.setState({ activeTabList: 0 })}>
              <Button
                onClick={() => this.setActiveTab(4)}
                color={this.state.activeTab === 4 ? "primary" : ""}>
                Domain
              </Button>
              {this.state.activeTab === 4 && (
                <ul
                  className="listed submenu dropdown-menu"
                  style={{
                    display: this.state.activeTabList === 1 ? "block" : "none",
                  }}>
                  {this.state.graphList.map((d, i) => {
                    return d.activeTab !== this.state.activeTab ? (
                      d.activeTab ? (
                        this.state.activeTab === this.state.activeTabList &&
                        d.activeTab === this.state.activeTab && (
                          <li
                            onClick={() => this.addOrRemoveChartsInList(d)}
                            key={i}
                            className={this.isAcitiveSubLink(d.title)}>
                            <a href="#">{d.title}</a>
                          </li>
                        )
                      ) : (
                        this.state.activeTab === this.state.activeTabList && (
                          <li
                            key={i}
                            className={this.isAcitiveSubLink(d.title)}
                            onClick={() => this.addOrRemoveChartsInList(d)}>
                            <a href="#">{d.title}</a>
                          </li>
                        )
                      )
                    ) : (
                      <li
                        key={i}
                        className={this.isAcitiveSubLink(d.title)}
                        onClick={() => this.addOrRemoveChartsInList(d)}>
                        <a href="#">{d.title}</a>
                      </li>
                    );
                  })}
                </ul>
              )}
            </li>
          </ul>
        </div>
        {/* <div className="sidechartnavbar">
                <ul className="charts-testscreen-tabs">
                    <li className="nav-item">
                        <Button onClick={() => this.setActiveTab(1)} color={this.state.activeTab === 1 ? "primary" : ""}>General</Button>
                    </li>
                    
                    <li className="nav-item">
                        <Button onClick={() => this.setActiveTab(2)} color={this.state.activeTab === 2 ? "primary" : ""}>Apache</Button>
                    </li>
                    
                    <li className="nav-item">
                        <Button onClick={() => this.setActiveTab(3)} color={this.state.activeTab === 3 ? "primary" : ""}>MySQL</Button>
                    </li>
                   
                    <li className="nav-item">
                        <Button onClick={() => this.setActiveTab(4)} color={this.state.activeTab === 4 ? "primary" : ""}>Domain</Button>
                    </li>  
                </ul>
                <ul className="listed">
                        {this.state.graphList.map((d, i) => {
                            return d.activeTab !== this.state.activeTab || this.state.activeTab === 2 ?
                                d.activeTab ? this.state.activeTab === 2 && d.activeTab === this.state.activeTab && <li key={i}>
                                    <a href="#" onClick={() => this.addOrRemoveChartsInList(d)}>
                                        {d.title}
                                    </a>
                                </li> :
                                    this.state.activeTab === 2 && <li key={i}>
                                        <a href="#" onClick={() => this.addOrRemoveChartsInList(d)}>
                                            {d.title}
                                        </a>
                                    </li>
                                : (
                                    <li key={i}>
                                        <a href="#" onClick={() => this.addOrRemoveChartsInList(d)}>
                                            {d.title}
                                        </a>
                                    </li>
                                );
                        })}
                    </ul>
              </div> */}

        {Array.isArray(this.state.graphArray) &&
          this.state.graphArray.map((data, index) => {
            if (
              this.state.graphShowObj &&
              this.state.graphShowObj.hasOwnProperty(data.id) &&
              this.state.graphShowObj[data.id]
            )
              return (
                <div key={data.id} className={"row"}>
                  <div className="col-md-12">
                    <div className="card overflow-visible sqlTable">
                      <a
                        href="#"
                        className="cross-charts"
                        onClick={() => this.addOrRemoveChartsInList(data)}>
                        X
                      </a>

                      <div className="card-header"></div>

                      <div className="largeChartInnerDiv">
                        {data.table && (
                          <Fragment>
                            {data.id == "top_process" && (
                              <TopMemoryProcress
                                uuid={this.state.uuid}
                                start_datetime={
                                  this.state.start_datetimeForChart
                                }
                                end_datetime={this.state.end_datetimeForChart}
                                mytimezone={this.state.mytimezone}
                                dateTime={this.state.md_cpu_usage_date}
                                unique_id={this.state.unique_id}
                              />
                            )}

                            {data.id == "disk_io_process" && (
                              <DiskIOAndWaitProcress
                                uuid={this.state.uuid}
                                start_datetime={
                                  this.state.start_datetimeForChart
                                }
                                end_datetime={this.state.end_datetimeForChart}
                                mytimezone={this.state.mytimezone}
                                dateTime={this.state.md_disk_io_process_date}
                                unique_id={this.state.unique_id}
                              />
                            )}

                            {data.id == "mysql_current_query" && (
                              <MySqlCurrentQuery
                                uuid={this.state.uuid}
                                start_datetime={
                                  this.state.start_datetimeForChart
                                }
                                end_datetime={this.state.end_datetimeForChart}
                                mytimezone={this.state.mytimezone}
                                dateTime={this.state.md_mysql_current_query}
                                unique_id={this.state.unique_id}
                              />
                            )}
                          </Fragment>
                        )}

                        {data.chart === 1 && (
                          <UsageChart
                            title={data.title}
                            tooltip={true}
                            toolbox={true}
                            restore={true}
                            uuid={this.state.uuid}
                            user_element={data.user_element}
                            SeriesType={graphsKeys}
                            start_datetime={this.state.start_datetimeForChart}
                            end_datetime={this.state.end_datetimeForChart}
                            mytimezone={this.state.mytimezone}
                            isLegend={true}
                            dataZoom={true}
                            apiCallUpdate={() => this.apiCallUpdate(index)}
                            apiCall={data.apiCall}
                            getEchartRef={(ref) =>
                              this.setEchartRef(ref, data.id)
                            }
                            getRestoreChart={this.getRestoreChart}
                            tooltipFormatterValueName={data.dateTimeName}
                            unique_id={this.state.unique_id}
                          />
                        )}

                        {data.chart === 2 && (
                          <BackupChartOperation
                            title={data.title}
                            tooltip={true}
                            toolbox={true}
                            restore={true}
                            uuid={this.state.uuid}
                            user_element={data.user_element}
                            SeriesType={graphsKeys}
                            start_datetime={this.state.start_datetimeForChart}
                            end_datetime={this.state.end_datetimeForChart}
                            mytimezone={this.state.mytimezone}
                            dataZoom={true}
                            echartsRef={index + 1}
                            getEchartRef={(ref) =>
                              this.setEchartRef(ref, data.id)
                            }
                            getRestoreChart={this.getRestoreChart}
                            unique_id={this.state.unique_id}
                          />
                        )}

                        {data.chart === 3 && (
                          <OpenPortChartOperation
                            title={"Ports used by processes"}
                            tooltip={true}
                            toolbox={true}
                            restore={true}
                            uuid={this.state.uuid}
                            user_element={data.user_element}
                            SeriesType={graphsKeys}
                            start_datetime={this.state.start_datetimeForChart}
                            end_datetime={this.state.end_datetimeForChart}
                            mytimezone={this.state.mytimezone}
                            dataZoom={true}
                            echartsRef={index + 1}
                            getEchartRef={(ref) =>
                              this.setEchartRef(ref, data.id)
                            }
                            getRestoreChart={this.getRestoreChart}
                            unique_id={this.state.unique_id}
                          />
                        )}

                        {data.chart === 7 && (
                          <OpenPortChartOperationPublic
                            title={"Ports open to world"}
                            tooltip={true}
                            toolbox={true}
                            restore={true}
                            uuid={this.state.uuid}
                            user_element={data.user_element}
                            SeriesType={graphsKeys}
                            start_datetime={this.state.start_datetimeForChart}
                            end_datetime={this.state.end_datetimeForChart}
                            mytimezone={this.state.mytimezone}
                            dataZoom={true}
                            echartsRef={index + 1}
                            getEchartRef={(ref) =>
                              this.setEchartRef(ref, data.id)
                            }
                            getRestoreChart={this.getRestoreChart}
                            unique_id={this.state.unique_id}
                          />
                        )}

                        {data.chart === 4 && (
                          <ChartMySqlOperation
                            title={data.title}
                            tooltip={true}
                            toolbox={true}
                            restore={true}
                            uuid={this.state.uuid}
                            user_element={data.user_element}
                            SeriesType={graphsKeys}
                            start_datetime={this.state.start_datetimeForChart}
                            end_datetime={this.state.end_datetimeForChart}
                            mytimezone={this.state.mytimezone}
                            isLegend={true}
                            dataZoom={true}
                            type={data.type}
                            getEchartRef={(ref) =>
                              this.setEchartRef(ref, data.id)
                            }
                            getRestoreChart={this.getRestoreChart}
                            tooltipFormatterValueName={data.dateTimeName}
                            unique_id={this.state.unique_id}
                          />
                        )}

                        {data.chart === 6 && (
                          <MoniterUrlDetailsChart
                            urlId={data.urlId}
                            title={data.title}
                            grid={{
                              top: "25%",
                              left: "3%",
                              right: "4%",
                              bottom: "3%",
                              containLabel: true,
                            }}
                            style={{}}
                            getEchartRef={(ref) =>
                              this.setEchartRef(ref, data.id)
                            }
                            getRestoreChart={this.getRestoreChart}
                            start_datetime={this.state.start_datetimeForChart}
                            end_datetime={this.state.end_datetimeForChart}
                            dataZoom={true}
                            unique_id={
                              this.state.unique_id
                            }></MoniterUrlDetailsChart>
                        )}

                        {data.chart === 5 ? (
                          data.dynamic === true ? (
                            // dynamic domain send chart data and timestemp
                            <ChartOperationsApachhe
                              title={
                                data.title === "unknown"
                                  ? "Default"
                                  : data.title
                              }
                              tooltip={true}
                              toolbox={true}
                              restore={true}
                              uuid={this.state.uuid}
                              isLegend={true}
                              dataZoom={true}
                              chartData={data.chartData}
                              timestemp={data.timestamp}
                              outer={true}
                              isRefesh={data.isRefesh}
                              getEchartRef={(ref) =>
                                this.setEchartRef(ref, data.id)
                              }
                              getRestoreChart={this.getRestoreChart}
                              unique_id={this.state.unique_id}
                            />
                          ) : (
                            <ChartOperationsApachhe
                              title={data.title}
                              tooltip={true}
                              toolbox={true}
                              restore={true}
                              uuid={this.state.uuid}
                              user_element={data.user_element}
                              SeriesType={graphsKeys}
                              start_datetime={this.state.start_datetimeForChart}
                              end_datetime={this.state.end_datetimeForChart}
                              mytimezone={this.state.mytimezone}
                              isLegend={true}
                              dataZoom={true}
                              type={data.type}
                              InnerDataType={data.type}
                              getEchartRef={(ref) =>
                                this.setEchartRef(ref, data.id)
                              }
                              getRestoreChart={this.getRestoreChart}
                              unique_id={this.state.unique_id}
                            />
                          )
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              );
          })}
      </div>
    );
  }
}

export default MonitorSettingIndividualChart;
