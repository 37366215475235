import React, { useState } from 'react'
import { Input } from 'reactstrap';
import { Modal, ModalTitle } from "react-bootstrap";
import { domainDetailsServices } from '../../_services/servicesWithSecondOrigin/index'
import toastr from 'toastr'
import { MdAdd } from "react-icons/md";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
const DomainShare = ({ ...props }) => {
    const [domain, setdomain] = useState("")
    const [show, setShow] = useState(false)

    const setShowPopup = () => {
        setShow(true)
    }

    const onsubmit = async () => {
        let data = {
            "domain_name": domain
        }
        let response = await domainDetailsServices.addNewDomain(data)
        if (response && response.status === 200) {
            callBack()
            toastr.success(response.message)
        }
        if (response && response.message && response.status === 208) {
            toastr.error(response.message)
        }

    }

    const callBack = () => {
        setdomain("")
        setShow(false)
        if (props.callBack) {
            props.callBack()
        }
    }

    return <>
        <OverlayTrigger placement="bottom" overlay={<Tooltip>Add a new domain</Tooltip>}>
            {props.getStarter?<a href="#" onClick={() => setShowPopup(true)} className="btn btn-primary text-uppercase">Get started now</a>:<MdAdd className="headerIcon addIconBtn" onClick={() => setShowPopup(true)}></MdAdd>}
        </OverlayTrigger>
        {/* <OverlayTrigger placement="top" overlay={<Tooltip>Add a new domain</Tooltip>}><MdAdd className="headerIcon addIconBtn" onClick={() => setShowPopup(true)}></MdAdd></OverlayTrigger> */}
        <Modal show={show} onHide={() => setShow(false)} {...props}>
            <Modal.Header closeButton>
                <ModalTitle>Add Domain</ModalTitle>
            </Modal.Header>
            <Modal.Body>
                <Input value={domain} onChange={e => setdomain(e.target.value)} placeholder="Enter Domain Name" ></Input>
            </Modal.Body>
            
            <div className="modal-footer">
                    <button type="button" className="btn btn-primary" onClick={() => onsubmit()}>Add</button>
                </div>
        </Modal>
    </>
}

export default DomainShare
