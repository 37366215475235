import React, { useState, useEffect } from "react";
import { Form, Input, Button } from "reactstrap";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  MButton,
  Error,
  Loader,
  TooltipPro,
} from "../../CommonComponents/index";
import { monitorServersSettingServices } from "../../../_services/servicesWithSecondOrigin/index";
import toastr from "toastr";
import { Validation } from "../../../_helpers/validation/Validation";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { GrAddCircle } from "react-icons/gr";
import { IoIosRemoveCircleOutline } from "react-icons/io";
import { Rules } from "../../../_helpers/validation/Rules";

const obData = {
  db_username: "",
  db_password: "",
  host: "",
  port: "3306",

  db_username_update: false,
  db_password_update: false,
  host_update: false,
};

const initialOtherService = {
  service_display_name: "",
  service_command: "",
};

const ModifiedFiles = ({ ...props }) => {
  const [mysql_performance, setDatamysql_performance] = useState(0);

  const [mongodb_performance, setDataMongo_performance] = useState(0);

  const [apache_performance, setDataApache_performance] = useState(0);
  const [nginx_performance, setDataNginx_performance] = useState(0);
  const [other_services, setDataOther_services] = useState(0);
  const [autoscaling_db, setAutoscaling_db] = useState(0);
  const [apachheArr, setApachheArray] = useState([]);
  const [errorArray, setErrorArray] = useState([]);
  const [errorArrayM, setErrorMArray] = useState([]);

  const [apachhePath, setApachhePath] = useState([]);
  const [mysqlHostingArr, setMysqlHostingArr] = useState([obData]);

  const [mongoDbArr, setMongoDbHostingArr] = useState([obData]);

  const [message, setMessage] = useState("");
  const [loader, setLoader] = useState(false);
  const [otherServices, setOtherServices] = useState([]);
  const [otherServicesErrors, setOtherServicesErrors] = useState([]);

  useEffect(() => {
    if (props.MSId && props.active === 7) {
      getData();
      mysqlStatus();
    }
  }, [props.MSId, props.active]);

  const handleAddOtherService = () =>
    setOtherServices((prev) => [...prev, { ...initialOtherService }]);
  const handleRemoveOtherService = (index) =>
    setOtherServices((prev) => prev.filter((_s, idx) => idx !== index));
  const handleChangeOtherService = ({ target: { name, value } }, index) =>
    setOtherServices((prev) =>
      prev.map((s, idx) => (idx !== index ? s : { ...s, [name]: value }))
    );

  const getData = async () => {
    setLoader(true);
    setDatamysql_performance(0);
    setDataMongo_performance(0);
    setDataApache_performance(0);
    setDataNginx_performance(0);
    setDataOther_services(0);
    setAutoscaling_db(0);
    setOtherServices([]);
    setOtherServicesErrors([]);
    setMysqlHostingArr([obData]);

    let response = await monitorServersSettingServices.getGeneralSettingSave(
      props.MSId
    );
    if (response && response.data) {
      let data = response.data;
      if (data) {
        Array.isArray(data.mysql_data) &&
          data.mysql_data.map((allData, i) => {
            allData.db_username = allData.db_username_to_show
              ? allData.db_username_to_show
              : allData.db_username;
            allData.db_password = allData.db_password_to_show
              ? allData.db_password_to_show
              : allData.db_password;
            allData.host = allData.host_to_show
              ? allData.host_to_show
              : allData.host;
          });

        Array.isArray(data.mongodb_data) &&
          data.mongodb_data.map((allData, i) => {
            allData.db_username = allData.db_username_to_show
              ? allData.db_username_to_show
              : allData.db_username;
            allData.db_password = allData.db_password_to_show
              ? allData.db_password_to_show
              : allData.db_password;
            allData.host = allData.host_to_show
              ? allData.host_to_show
              : allData.host;
          });

        setDataMongo_performance(
          data.mongodb_performance ? data.mongodb_performance : 0
        );

        setDatamysql_performance(
          data.mysql_performance ? data.mysql_performance : 0
        );
        setDataApache_performance(
          data.apache_performance ? data.apache_performance : 0
        );
        setDataNginx_performance(
          data.nginx_performance ? data.nginx_performance : 0
        );
        setDataOther_services(data.other_services ? data.other_services : 0);
        setAutoscaling_db(data.autoscaling_db ? data.autoscaling_db : 0);

        setMysqlHostingArr(
          Array.isArray(data.mysql_data) ? data.mysql_data : [obData]
        );

        setMongoDbHostingArr(
          Array.isArray(data.mongodb_data) ? data.mongodb_data : [obData]
        );

        setOtherServices(
          Array.isArray(data.other_services_data)
            ? data.other_services_data
            : []
        );
        // let Timezone = localStorage.getItem("Timezone")
        // setApachheArray([])
        // let dataObj = {
        //     "uuid": props.uuid,
        //     "timezone": Timezone
        // }
        // if (parseInt(data.apache_performance) === 1) {
        //     let apachhePath_temp = []
        //     let res = await monitorServersSettingServices.getGeneralSettingApachhe(dataObj)
        //     if (res && res.data && Array.isArray(res.data)) {
        //         setApachheArray(res.data)
        //         res.data.map((val, index) => {
        //             Array.isArray(data.apache_data) && data.apache_data.map((obj) => {
        //                 if (val.value === obj.CustomLog && !apachhePath_temp.includes(index)) {
        //                     apachhePath_temp.push(index)
        //                 }
        //             })
        //         })
        //         setApachhePath(apachhePath_temp)
        //     }
        // }
      }
    }
    setLoader(false);
  };

  const mysqlStatus = async () => {
    setMessage("");
    let data = {
      uuid: props.uuid,
    };
    let response =
      await monitorServersSettingServices.getGeneralSettingMySqlStatus(data);
    if (response && response.data && response.data.message) {
      setMessage(response.data.message);
    }
  };

  const onSubmit = async (event) => {
    let flag = 0;
    let hostingArr = [];
    if (parseInt(mysql_performance) === 1) {
      let cloneArray = mysqlHostingArr.slice();
      let errorArray = [];
      const field = hostRules;
      Array.isArray(cloneArray) &&
        cloneArray.forEach((obj) => {
          let errobj = Validation(obj, field);
          Object.keys(errobj).forEach((index) => {
            if (errobj[index] !== "") {
              flag = 1;
            }
          });
          errorArray = errorArray.concat(errobj);
          let ObjDataVal = {};
          if (obj.db_username_update) {
            ObjDataVal.db_username = obj.db_username;
          }
          if (obj.db_password_update) {
            ObjDataVal.db_password = obj.db_password;
          }
          if (obj.host_update) {
            ObjDataVal.host = obj.host;
          }
          if (obj.port_update) {
            ObjDataVal.port = obj.port;
          }
          hostingArr.push(ObjDataVal);
        });

      setErrorArray(errorArray);
    } else {
      setMysqlHostingArr([]);
    }

    let mongohostingArr = [];
    if (parseInt(mongodb_performance) === 1) {
      let cloneArray = mongoDbArr.slice();
      let errorMArray = [];
      const field = hostRules;
      Array.isArray(cloneArray) &&
        cloneArray.forEach((obj) => {
          let errobj = Validation(obj, field);
          Object.keys(errobj).forEach((index) => {
            if (errobj[index] !== "") {
              flag = 1;
            }
          });
          errorMArray = errorMArray.concat(errobj);
          let ObjDataVal = {};
          if (obj.db_username_update) {
            ObjDataVal.db_username = obj.db_username;
          }
          if (obj.db_password_update) {
            ObjDataVal.db_password = obj.db_password;
          }
          if (obj.host_update) {
            ObjDataVal.host = obj.host;
          }
          if (obj.port_update) {
            ObjDataVal.port = obj.port;
          }
          mongohostingArr.push(ObjDataVal);
        });

      setErrorMArray(errorMArray);
    } else {
      setMongoDbHostingArr([]);
    }

    const serviceErrors = [];
    for (const service of otherServices) {
      const errorObj = {};
      if (!service.service_display_name) {
        errorObj.service_display_name = "Required Field.";
        flag = 1;
      } else {
        const message = Rules(service.service_display_name, { type: "name" });
        errorObj.service_display_name = message;
        if (message) flag = 1;
      }
      if (!service.service_command) {
        errorObj.service_command = "Required Field.";
        flag = 1;
      }
      serviceErrors.push(errorObj);
    }
    setOtherServicesErrors(serviceErrors);

    let apache_data = [];
    // Array.isArray(apachhePath) && apachhePath.map((index) => {
    //     apache_data.push({
    //         CustomLog: apachheArr[index].value
    //     })
    // })

    if (flag === 0) {
      let objdata = {
        mysql_performance: mysql_performance,
        apache_performance: apache_performance,
        mysql_data: parseInt(mysql_performance) === 1 ? hostingArr : [],
        apache_data: apache_data,
        other_services_data: otherServices,
        nginx_performance,
        other_services,
        autoscaling_db,
        mongodb_performance: mongodb_performance,
        mongodb_data:
          parseInt(mongodb_performance) === 1 ? mongohostingArr : [],
      };

      let response = await monitorServersSettingServices.generalSettingSave(
        objdata,
        props.MSId
      );
      if (response && response.message) {
        toastr.info(response.message);
        getData();
        mysqlStatus();
      }
    }
  };

  const onCheckedApachhe = async () => {
    setDataApache_performance(parseInt(apache_performance) === 1 ? 0 : 1);
    // setLoading(true)
    // let Timezone = localStorage.getItem("Timezone")
    // setApachheArray([])
    // let data = {
    //     "uuid": props.uuid,
    //     "timezone": Timezone
    // }
    // if (parseInt(apache_performance) !== 1) {
    //     let response = await monitorServersSettingServices.getGeneralSettingApachhe(data)
    //     if (response && response.data && Array.isArray(response.data)) {
    //         setApachheArray(response.data)
    //         setDataApache_performance(parseInt(apache_performance) === 1 ? 0 : 1)
    //     }
    // } else {
    //     setDataApache_performance(0)
    //     setApachheArray([])
    //     setApachhePath([])
    // }
    // setLoading(false)
  };

  // const onClickApachhePath = (value) => {
  //     let apachhePathArr = apachhePath.slice()
  //     if (apachhePathArr.includes(value)) {
  //         apachhePathArr = apachhePathArr.filter((val, index) => {
  //             return val !== value
  //         })
  //     } else {
  //         apachhePathArr.push(value)
  //     }
  //     setApachhePath(apachhePathArr)
  // }

  // const addHostingObj = () => {
  //   let mysqlHostingArr_clone = mysqlHostingArr.slice();
  //   mysqlHostingArr_clone.push(obData);
  //   setMysqlHostingArr(mysqlHostingArr_clone);
  // };

  const onClickRemove = (index) => {
    let mysqlHostingArr_clone = mysqlHostingArr.slice();
    mysqlHostingArr_clone = mysqlHostingArr_clone.filter((val, i) => {
      return i !== index;
    });
    setMysqlHostingArr(mysqlHostingArr_clone);
  };

  const onChangePerForCride = (e, index) => {
    const { name, value } = e.target;
    let mysqlHostingArr_clone = mysqlHostingArr.slice();
    mysqlHostingArr_clone[index][name] = value;

    switch (e.target.name) {
      case "db_username":
        mysqlHostingArr_clone[index].db_username_update = true;
        break;
      case "db_password":
        mysqlHostingArr_clone[index].db_password_update = true;
        break;
      case "host":
        mysqlHostingArr_clone[index].host_update = true;
        break;
      case "port":
        mysqlHostingArr_clone[index].port_update = true;
        break;
      default:
        console.log("default");
    }
    setMysqlHostingArr(mysqlHostingArr_clone);
  };

  const onChangeMongodbForCride = (e, index) => {
    const { name, value } = e.target;
    let mongoDbHostingArr_clone = mongoDbArr.slice();
    mongoDbHostingArr_clone[index][name] = value;

    switch (e.target.name) {
      case "db_username":
        mongoDbHostingArr_clone[index].db_username_update = true;
        break;
      case "db_password":
        mongoDbHostingArr_clone[index].db_password_update = true;
        break;
      case "host":
        mongoDbHostingArr_clone[index].host_update = true;
        break;
      case "port":
        mongoDbHostingArr_clone[index].port_update = true;
        break;
      default:
        console.log("default");
    }
    setMongoDbHostingArr(mongoDbHostingArr_clone);
  };

  return (
    <div className="serverSettingData">
      <div className="setLoaderPosition">
        <Loader loader={loader}></Loader>
      </div>
      <div className="serverInfoTabsTitle flex-column align-items-start">
        <h3>Performance Setting</h3>
        <p className="serverInfoDetails">
          G8Keeper can track Apache and MySQL and provide you deeper insights on
          the same which help you track the performance of these applications.
        </p>
      </div>

      <div className="performanceSetting">
        <Form onSubmit={onSubmit}>
          <div style={{ marginBottom: "30px" }}>
            <div style={{ display: "flex" }}>
              <div class="performanceCheck">
                <div className="customCheckBox">
                  <input
                    type="checkbox"
                    checked={parseInt(mysql_performance) === 1}
                    onClick={() =>
                      setDatamysql_performance(
                        parseInt(mysql_performance) === 1 ? 0 : 1
                      )
                    }
                    id="mysql_performance"
                  />
                  <label
                    className="checkbox-height"
                    htmlFor="mysql_performance"
                    for="mysql_performance"
                    style={{ paddingRight: 30 }}
                  >
                    MySql Performance
                  </label>
                  <span className="performanceInfo">
                    <TooltipPro
                      className="bottom tsize-600"
                      tooltip={
                        <div className="row">
                          <div className="col-md-12">
                            <p>
                              G8Keeper tracks and reports important statictics
                              of MySQL. This feature is supported on MySQL 5.7
                              or above.{" "}
                            </p>
                            <p>
                              You need to provide details to access the MySQL
                              server. The details you provide needs to have
                              access to collect performance statictics. On how
                              to setup user please{" "}
                              <a
                                target="_blank"
                                href="https://g8keeperapp.com/how-it-works/create-mysql-db-user-for-performance-monitoring/"
                              >
                                click here
                              </a>{" "}
                              and follow the steps.
                            </p>
                            <p>
                              <strong>Note: </strong>Some statictics are
                              collected every minute and reported. More detailed
                              ones are collected once per day.
                            </p>
                          </div>
                        </div>
                      }
                    >
                      <AiOutlineInfoCircle className="infoIcon" />
                    </TooltipPro>
                  </span>
                </div>
                <div class="form-check" style={{ paddingLeft: 65 }}>
                  <input
                    class="form-check-input"
                    type="radio"
                    name="autoscaling_db"
                    id="autoscaling_db"
                    checked={autoscaling_db === 1}
                    onClick={() =>
                      setAutoscaling_db((prev) => (prev === 1 ? 0 : 1))
                    }
                  />
                  <label class="form-check-label" htmlFor="autoscaling_db">
                    Autoscaling DB
                  </label>
                </div>
              </div>
            </div>
            {parseInt(mysql_performance) === 1 && (
              <div className="">
                {Array.isArray(mysqlHostingArr) &&
                  mysqlHostingArr.map((obj, index) => {
                    return (
                      <div className="">
                        <div className="d-flex">
                          <h5 className="pt-3">Host Info </h5>
                          <div
                            style={{ color: "#ea504f" }}
                            className="pt-3 ml-5"
                          >
                            {message}
                          </div>
                        </div>
                        <div className="row">
                          <div className="mt-2 col-md-3">
                            <label>Host</label>
                            <span className="ml-2">
                              <TooltipPro
                                className="bottom tsize-400"
                                tooltip={
                                  <div className="row">
                                    <div className="col-md-12">
                                      <p>
                                        Provide the path of the host. If DB is
                                        on the same server where G8Keeper is
                                        running then simply specify "localhost"
                                        else provide fully qualified host url
                                      </p>
                                    </div>
                                  </div>
                                }
                              >
                                <AiOutlineInfoCircle className="infoIcon" />
                              </TooltipPro>
                            </span>
                            <Input
                              className="w-100"
                              placeholder="Host"
                              value={obj.host}
                              name="host"
                              onChange={(e) => onChangePerForCride(e, index)}
                            ></Input>
                            {errorArray &&
                              errorArray[index] &&
                              errorArray[index].host && (
                                <Error error={errorArray[index].host}></Error>
                              )}
                          </div>
                          <div className="mt-2 col-md-3">
                            <label>DB Username</label>
                            <span className="ml-2">
                              <TooltipPro
                                className="bottom tsize-400"
                                tooltip={
                                  <div className="row">
                                    <div className="col-md-12">
                                      <p>
                                        You need to provide the username that
                                        has access to performance statictics on
                                        the DB. On setting the user with
                                        required access{" "}
                                        <a
                                          href="https://g8keeperapp.com/blog/setup-apache-monitoring-with-g8keeper/"
                                          target="_blank"
                                        >
                                          click here
                                        </a>
                                        .
                                      </p>
                                    </div>
                                  </div>
                                }
                              >
                                <AiOutlineInfoCircle className="infoIcon" />
                              </TooltipPro>
                            </span>
                            <Input
                              className="w-100"
                              placeholder="Name"
                              value={obj.db_username}
                              name="db_username"
                              onChange={(e) => onChangePerForCride(e, index)}
                            ></Input>
                            {errorArray &&
                              errorArray[index] &&
                              errorArray[index].db_username && (
                                <Error
                                  error={errorArray[index].db_username}
                                ></Error>
                              )}
                          </div>
                          <div className="mt-2 col-md-3">
                            <label>DB Password</label>
                            <span className="ml-2">
                              <TooltipPro
                                className="bottom tsize-400"
                                tooltip={
                                  <div className="row">
                                    <div className="col-md-12">
                                      <p>
                                        Provide the password for the database
                                        user.
                                      </p>
                                    </div>
                                  </div>
                                }
                              >
                                <AiOutlineInfoCircle className="infoIcon" />
                              </TooltipPro>
                            </span>
                            <Input
                              className="w-100"
                              type="password"
                              placeholder="Password"
                              value={obj.db_password}
                              name="db_password"
                              onChange={(e) => onChangePerForCride(e, index)}
                            ></Input>
                            {errorArray &&
                              errorArray[index] &&
                              errorArray[index].db_password && (
                                <Error
                                  error={errorArray[index].db_password}
                                ></Error>
                              )}
                          </div>

                          <div className="mt-2 col-md-3">
                            <label>Port</label>
                            <span className="ml-2">
                              <TooltipPro
                                className="bottom tsize-400"
                                tooltip={
                                  <div className="row">
                                    <div className="col-md-12">
                                      <p>
                                        Provide the port for the database user.
                                      </p>
                                    </div>
                                  </div>
                                }
                              >
                                <AiOutlineInfoCircle className="infoIcon" />
                              </TooltipPro>
                            </span>
                            <Input
                              className="w-100"
                              type="number"
                              placeholder="Port"
                              value={obj.port}
                              name="port"
                              onChange={(e) => onChangePerForCride(e, index)}
                            ></Input>
                            {errorArray &&
                              errorArray[index] &&
                              errorArray[index].port && (
                                <Error error={errorArray[index].port}></Error>
                              )}
                          </div>

                          <div className="mt-4 col-md-3">
                            {(index !== 0 || mysqlHostingArr.length > 1) && (
                              <Button
                                style={{ margin: "10px", marginRight: "107px" }}
                                onClick={() => onClickRemove(index)}
                              >
                                Remove
                              </Button>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                {/* <div className="" style={{ float: 'right' }}>
                            <Button onClick={() => addHostingObj()}>Add</Button>
                        </div> */}
              </div>
            )}
          </div>

          <div style={{ marginBottom: "30px" }}>
            <div style={{ display: "flex" }}>
              <div class="performanceCheck">
                <div className="customCheckBox">
                  <input
                    type="checkbox"
                    checked={parseInt(mongodb_performance) === 1}
                    onClick={() =>
                      setDataMongo_performance(
                        parseInt(mongodb_performance) === 1 ? 0 : 1
                      )
                    }
                    id="mongodb_performance"
                  />
                  <label
                    className="checkbox-height"
                    htmlFor="mongodb_performance"
                    for="mongodb_performance"
                    style={{ paddingRight: 30 }}
                  >
                    Mongodb Performance
                  </label>
                  <span className="performanceInfo">
                    <TooltipPro
                      className="bottom tsize-600"
                      tooltip={
                        <div className="row">
                          <div className="col-md-12">
                            <p>
                              G8Keeper tracks and reports important statictics
                              of MongoDB.{" "}
                            </p>
                            <p>
                              You need to provide details to access the MongoDB
                              server. The details you provide needs to have
                              access to collect performance statictics. On how
                              to setup user please click here and follow the
                              steps.{" "}
                              <a
                                target="_blank"
                                href="https://g8keeperapp.com/how-it-works/create-mysql-db-user-for-performance-monitoring/"
                              >
                                click here
                              </a>{" "}
                              and follow the steps.
                            </p>
                            <p>
                              <strong>Note: </strong>Some statictics are
                              collected every minute and reported. More detailed
                              ones are collected once per day.
                            </p>
                          </div>
                        </div>
                      }
                    >
                      <AiOutlineInfoCircle className="infoIcon" />
                    </TooltipPro>
                  </span>
                </div>
              </div>
            </div>
            {parseInt(mongodb_performance) === 1 && (
              <div className="">
                {Array.isArray(mongoDbArr) &&
                  mongoDbArr.map((obj, index) => {
                    return (
                      <div className="">
                        <div className="d-flex">
                          <h5 className="pt-3">Profiling Setting </h5>
                          <div
                            style={{ color: "#ea504f" }}
                            className="pt-3 ml-5"
                          >
                            {message}
                          </div>
                        </div>
                        <div className="row">
                          <div className="mt-2 col-md-3">
                            <label>Host</label>
                            <span className="ml-2">
                              <TooltipPro
                                className="bottom tsize-400"
                                tooltip={
                                  <div className="row">
                                    <div className="col-md-12">
                                      <p>
                                        Provide the path of the host. If DB is
                                        on the same server where G8Keeper is
                                        running then simply specify "localhost"
                                        else provide fully qualified host url
                                      </p>
                                    </div>
                                  </div>
                                }
                              >
                                <AiOutlineInfoCircle className="infoIcon" />
                              </TooltipPro>
                            </span>
                            <Input
                              className="w-100"
                              placeholder="Host"
                              value={obj.host}
                              name="host"
                              onChange={(e) =>
                                onChangeMongodbForCride(e, index)
                              }
                            ></Input>
                            {errorArrayM &&
                              errorArrayM[index] &&
                              errorArrayM[index].host && (
                                <Error error={errorArrayM[index].host}></Error>
                              )}
                          </div>
                          <div className="mt-2 col-md-3">
                            <label>DB Username</label>
                            <span className="ml-2">
                              <TooltipPro
                                className="bottom tsize-400"
                                tooltip={
                                  <div className="row">
                                    <div className="col-md-12">
                                      <p>
                                        You need to provide the username that
                                        has access to performance statictics on
                                        the DB. On setting the user with
                                        required access{" "}
                                        <a
                                          href="https://g8keeperapp.com/blog/setup-apache-monitoring-with-g8keeper/"
                                          target="_blank"
                                        >
                                          click here
                                        </a>
                                        .
                                      </p>
                                    </div>
                                  </div>
                                }
                              >
                                <AiOutlineInfoCircle className="infoIcon" />
                              </TooltipPro>
                            </span>
                            <Input
                              className="w-100"
                              placeholder="Name"
                              value={obj.db_username}
                              name="db_username"
                              onChange={(e) =>
                                onChangeMongodbForCride(e, index)
                              }
                            ></Input>
                            {errorArrayM &&
                              errorArrayM[index] &&
                              errorArrayM[index].db_username && (
                                <Error
                                  error={errorArrayM[index].db_username}
                                ></Error>
                              )}
                          </div>
                          <div className="mt-2 col-md-3">
                            <label>DB Password</label>
                            <span className="ml-2">
                              <TooltipPro
                                className="bottom tsize-400"
                                tooltip={
                                  <div className="row">
                                    <div className="col-md-12">
                                      <p>
                                        Provide the password for the database
                                        user.
                                      </p>
                                    </div>
                                  </div>
                                }
                              >
                                <AiOutlineInfoCircle className="infoIcon" />
                              </TooltipPro>
                            </span>
                            <Input
                              className="w-100"
                              type="password"
                              placeholder="Password"
                              value={obj.db_password}
                              name="db_password"
                              onChange={(e) =>
                                onChangeMongodbForCride(e, index)
                              }
                            ></Input>
                            {errorArrayM &&
                              errorArrayM[index] &&
                              errorArrayM[index].db_password && (
                                <Error
                                  error={errorArrayM[index].db_password}
                                ></Error>
                              )}
                          </div>

                          <div className="mt-2 col-md-3">
                            <label>Port</label>
                            <span className="ml-2">
                              <TooltipPro
                                className="bottom tsize-400"
                                tooltip={
                                  <div className="row">
                                    <div className="col-md-12">
                                      <p>
                                        Provide the port for the database user.
                                      </p>
                                    </div>
                                  </div>
                                }
                              >
                                <AiOutlineInfoCircle className="infoIcon" />
                              </TooltipPro>
                            </span>
                            <Input
                              className="w-100"
                              type="number"
                              placeholder="Port"
                              value={obj.port}
                              name="port"
                              onChange={(e) =>
                                onChangeMongodbForCride(e, index)
                              }
                            ></Input>
                            {errorArrayM &&
                              errorArrayM[index] &&
                              errorArrayM[index].port && (
                                <Error error={errorArrayM[index].port}></Error>
                              )}
                          </div>

                          <div className="mt-4 col-md-3">
                            {(index !== 0 || mysqlHostingArr.length > 1) && (
                              <Button
                                style={{ margin: "10px", marginRight: "107px" }}
                                onClick={() => onClickRemove(index)}
                              >
                                Remove
                              </Button>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                {/* <div className="" style={{ float: 'right' }}>
                            <Button onClick={() => addHostingObj()}>Add</Button>
                        </div> */}
              </div>
            )}
          </div>

          <div style={{ display: "flex" }}>
            <div class="customCheckBox performanceCheck">
              <input
                type="checkbox"
                checked={parseInt(apache_performance) === 1 ? true : false}
                onClick={() => onCheckedApachhe()}
                id="apache_performance"
              />
              <label
                className="checkbox-height"
                for="apache_performance"
                htmlFor="apache_performance"
              >
                Apache Performance
              </label>
              <span className="performanceInfo">
                <TooltipPro
                  className="bottom tsize-600"
                  tooltip={
                    <div className="row">
                      <div className="col-md-12">
                        <p>
                          G8Keeper tracks apache logs and reports details from
                          the same. When you enable Apache reporting, G8keeper
                          automatically tries to determine the Apache
                          installation and logs path and starts reporting. If
                          there are multiple domains hosted within the same
                          server, G8keeper is able to track the individual log
                          files and reports for each of the domain. Ensure that
                          while setting up multiple domains on Apache, you use
                          separate log path for each. For further details{" "}
                          <a
                            target="_blank"
                            href="https://g8keeperapp.com/blog/setup-apache-monitoring-with-g8keeper/"
                          >
                            read here
                          </a>
                          .
                        </p>
                      </div>
                    </div>
                  }
                >
                  <AiOutlineInfoCircle className="infoIcon" />
                </TooltipPro>
              </span>
            </div>
          </div>

          <div style={{ display: "flex" }}>
            <div class="customCheckBox performanceCheck">
              <input
                type="checkbox"
                checked={parseInt(nginx_performance) === 1 ? true : false}
                onClick={() =>
                  setDataNginx_performance((prev) => (prev === 1 ? 0 : 1))
                }
                id="nginx_performance"
              />
              <label
                className="checkbox-height"
                for="nginx_performance"
                htmlFor="nginx_performance"
              >
                Nginx Performance
              </label>
              <span className="performanceInfo">
                <TooltipPro
                  className="bottom tsize-600"
                  tooltip={
                    <div className="row">
                      <div className="col-md-12">
                        <p>
                          G8Keeper tracks nginx logs and reports details from
                          the same. When you enable Apache reporting, G8keeper
                          automatically tries to determine the Nginx
                          installation and logs path and starts reporting. If
                          there are multiple domains hosted within the same
                          server, G8keeper is able to track the individual log
                          files and reports for each of the domain. Ensure that
                          while setting up multiple domains on Nginx, you use
                          separate log path for each.{" "}
                          <a
                            target="_blank"
                            href="https://g8keeperapp.com/blog/setup-apache-monitoring-with-g8keeper/"
                          >
                            read here
                          </a>
                          .
                        </p>
                      </div>
                    </div>
                  }
                >
                  <AiOutlineInfoCircle className="infoIcon" />
                </TooltipPro>
              </span>
            </div>
          </div>

          <div style={{ display: "flex" }}>
            <div class="customCheckBox performanceCheck">
              <input
                type="checkbox"
                checked={parseInt(other_services) === 1 ? true : false}
                onClick={() =>
                  setDataOther_services((prev) => {
                    setOtherServices(
                      prev === 0 ? [{ ...initialOtherService }] : []
                    );
                    setOtherServicesErrors([]);
                    return prev === 1 ? 0 : 1;
                  })
                }
                id="other_services"
              />
              <label
                className="checkbox-height"
                for="other_services"
                htmlFor="other_services"
              >
                Other Services
              </label>
              <span className="performanceInfo">
                <TooltipPro
                  className="bottom tsize-600"
                  tooltip={
                    <div className="row">
                      <div className="col-md-12">
                        <p>
                          G8Keeper can track statics for various services
                          running under linux. All you need to do is provide the
                          Service command name. The service command name needs
                          to be exactly the name used to start / stop the
                          service.{" "}
                          <a
                            target="_blank"
                            href="https://g8keeperapp.com/blog/setup-apache-monitoring-with-g8keeper/"
                          >
                            read here
                          </a>
                          .
                        </p>
                      </div>
                    </div>
                  }
                >
                  <AiOutlineInfoCircle className="infoIcon" />
                </TooltipPro>
              </span>
            </div>
          </div>
          {/* {Array.isArray(apachheArr) && apachheArr.map((path, index) => {
                    return <div className='pl-5 pt-2'>
                        <input type="checkbox" checked={apachhePath.includes(index)} onClick={() => onClickApachhePath(index)} id={"apache_performance" + index} />
                        <label className="checkbox-height" htmlFor={"apache_performance" + index}>
                            {path.key ? path.key : "Default"}
                        </label>
                    </div>
                })} */}
          <div className="row">
            <div className="col-lg-8 col-md-12 col-12 offset-lg-2">
              {otherServices.map((service, idx) => (
                <div className="row mb-2" key={idx}>
                  <div className="col-lg-5 col-md-5 col-sm-5 col-12">
                    <Input
                      className="w-100"
                      placeholder="Service Display Name"
                      name="service_display_name"
                      onChange={(event) => handleChangeOtherService(event, idx)}
                      value={service.service_display_name}
                    ></Input>
                    <div className="text-danger small font-weight-bold px-2 py-1">
                      {otherServicesErrors[idx]?.service_display_name}
                    </div>
                  </div>
                  <div className="col-lg-5 col-md-5 col-sm-5 col-12">
                    <Input
                      className="w-100"
                      placeholder="Service Command"
                      name="service_command"
                      onChange={(event) => handleChangeOtherService(event, idx)}
                      value={service.service_command}
                    ></Input>
                    <div className="text-danger small font-weight-bold px-2 py-1">
                      {otherServicesErrors[idx]?.service_command}
                    </div>
                  </div>
                  <div className="col-lg-2 col-12">
                    <div className="d-flex p-1">
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>Add Service</Tooltip>}
                      >
                        <Button
                          className="iconBtn ml-2"
                          onClick={handleAddOtherService}
                        >
                          <GrAddCircle color="skyblue" />
                        </Button>
                      </OverlayTrigger>
                      {otherServices.length > 1 && (
                        <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip>Remove Service</Tooltip>}
                        >
                          <Button
                            className="iconBtn ml-2"
                            onClick={() => handleRemoveOtherService(idx)}
                          >
                            <IoIosRemoveCircleOutline color="red" />
                          </Button>
                        </OverlayTrigger>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="mt-4">
            <MButton
              label="Save"
              onClick={onSubmit}
              className="bluebutton"
            ></MButton>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default ModifiedFiles;

const hostRules = [
  {
    field_name: "host",
    label: "Host",
    type: "string",
    isRequired: true,
  },
  {
    field_name: "db_username",
    label: "DB User Name",
    type: "string",
    isRequired: true,
  },
  {
    field_name: "db_password",
    label: "Password",
    type: "string",
    isRequired: true,
  },
];
