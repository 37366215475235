import React from 'react'

// 3rd party component
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

const SearchBootstrapTable = ({ data, columns, searchFlag }) => {

    return (
        <ToolkitProvider
            striped
            keyField="id"
            data={data}
            columns={columns}
            search={{
                searchFormatted: true
            }}
        >
            {props => (
                <>
                    <div
                        className="topProcessTbl"
                    >
                        <BootstrapTable
                            {...props.baseProps}
                            bootstrap4
                            noDataIndication={"Table is empty"}
                            className={'topProcessTbl h300'} 
                            
                        />
                    </div>
                </>
            )
            }
        </ToolkitProvider>
    )

}

export default SearchBootstrapTable
