import { RestMethodSecondOrigin } from '../../../_helpers/ApiConfigTwo/RestMethod'
import FilterResponse from '../../../_helpers/FilterResponse'
import { isAuthorized } from '../../../_helpers/Common/Common'

export const backUpServices = {
    getFolterBackUpData,
    getDBBackUpData
}

async function getFolterBackUpData(data, page) {
    let api = '/backup/folder-backup'
    if (page !== undefined && page !== null) {
        api = api + "?page=" + page
    }
    try {
        const response = await RestMethodSecondOrigin.POST(api, data);
        const api_response = FilterResponse(response);
        return api_response;
    }
    catch (error) {
        isAuthorized(error)
        console.log("error detected while fetching data from api");
        return null;
    }
}

async function getDBBackUpData(data, page) {
    let api = '/backup/db-backup'
    if (page !== undefined && page !== null) {
        api = api + "?page=" + page
    }
    try {
        const response = await RestMethodSecondOrigin.POST(api, data);
        const api_response = FilterResponse(response);
        return api_response;
    }
    catch (error) {
        isAuthorized(error)
        console.log("error detected while fetching data from api");
        return null;
    }
}