import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { MultiSelect } from '../../CommonComponents/index'
import { domainDetailsServices, monitorServersServices } from '../../../_services/servicesWithSecondOrigin/index'
import { MdAdd } from "react-icons/md";
import toastr from 'toastr';
const ConnectServer = (props) => {
    const [selectedServer, setSelectedServer] = useState([])




    const [data, setData] = useState([])
    const [modal, setModal] = useState(false);
    const toggle = () => {
        setModal(!modal);
        if (!modal) {
            getData()
        }
    }

    useEffect(() => {
        if (data.length !== 0) {
            getDataFromServer()
        }
    }, [data])

    const getDataFromServer = async () => {
        let datao = {
            "domain_name": props.domain
        }

        let response = await domainDetailsServices.getServerListByDomain(datao)
        if (response && Array.isArray(response.data)) {
            setSelectedServer(data.filter((obj) => {
                let flag = false
                response.data.map((iObj) => {
                    if (obj.value === iObj.server_uuid) {
                        flag = true
                    }
                })
                return flag
            }))
        }
    }

    const getData = async () => {
        let response = await monitorServersServices.getAllMonitorServerForChart()
        if (response && response.data && Array.isArray(response.data)) {
            let array_temp = []
            response.data.map((obj) => {
                array_temp.push({
                    label: obj.server_name,
                    value: obj.uuid,
                    id: obj.id,
                    unique_id: obj.unique_id
                })
            })
            Array.isArray(response.Shareddata) && response.Shareddata.map((obj) => {
                array_temp.push({
                    label: obj.server_name,
                    value: obj.uuid,
                    id: obj.id,
                    unique_id: obj.unique_id
                })
            })
            array_temp = array_temp.sort((a, b) => {
                if (a.label.toLowerCase() < b.label.toLowerCase()) { return -1; }
                if (a.label.toLowerCase() > b.label.toLowerCase()) { return 1; }
                return 0
            })
            setData(array_temp)

        }
    }

    const onSelectServer = (e) => {
        setSelectedServer(e)
    }


    const onAdd = async () => {
        let dataArr = []
        Array.isArray(selectedServer) && selectedServer.map((obj) => {
            dataArr.push(obj.value)
        })
        let datao = {
            "domain_name": props.domain,
            "servers": dataArr
        }
        let response = await domainDetailsServices.addServerListToDomain(datao)
        if (response && response && response.message) {
            toastr.success(response.message)
            props.onChangeResetData()
            setModal(!modal);
        }
    }

    return (
        <>
        <OverlayTrigger placement="bottom" overlay={<Tooltip>Add server Records</Tooltip>}>
            <Button className="iconBtn addIconBtn" onClick={toggle}><MdAdd /></Button>
        </OverlayTrigger>
            
            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle}>Connect Server</ModalHeader>
                <ModalBody>
                    <div className="row">
                        <div className="col-md-12 dropdownContent">
                            <MultiSelect onSelect={onSelectServer} selected={selectedServer} className="multi-Select" options={data.sort((a, b) => a.label > b.label ? 1 : a.label < b.label ? -1 : 0)} keys={{ value: 'value', label: 'label' }}></MultiSelect>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={() => onAdd()}>Update</Button>{' '}
                </ModalFooter>
            </Modal>
        </>
    );
}

export default ConnectServer;