import React from 'react'
import { domainServices } from '../../_services/servicesWithSecondOrigin/index'
import { Button } from 'reactstrap';
import DnsInformation from '../../_components/DomainInformation/DnsInformation'
import DomainInformation from '../../_components/DomainInformation/DomainInformation'
import Header from '../../_components/CommonComponents/Header/Header'
import { isLogin } from '../../_helpers/Common/Common'
import toastr from 'toastr'
import { CheckBox } from '../../_components/CommonComponents/index'
import { MdRemoveRedEye } from "react-icons/md";
class AddDomain extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: "",
            my_domain_name: "",
            showInformation: false,
            dnsInformation: "",
            extraDns: [],
            cdn_loader: false,
            autoAdd: false,
            domainInformation: "",
            domainAllData: "",
            loaderDomain: false,
            dnsAllChecked: false,
        }
    }

    componentDidMount() {
        if (this.props.location && this.props.location.state && this.props.location.state.id) {
            this.setState({
                id: this.props.location.state.id
            }, () => {
                this.getDomainDataFromSecondOrigin()
            })
        } else {
            this.props.history.push('/dashboard')
        }
    }

    getDomainDataFromSecondOrigin = async () => {
        let response = await domainServices.viewDomain(this.props.location.state.id)
        if (response && response.domain && response.domain.domain_name) {
            let data = response.domain
            let dnsInformation = [];
            let extraDns = [];
            let autoAdd = false
            let domainInformation = ""
            let serverUrl = []
            Array.isArray(data.dnsDetails) && data.dnsDetails.map((obj) => {
                let monitor = false
                Array.isArray(data.urlMonitor) && data.urlMonitor.map((monitorURLObj) => {
                    if (monitorURLObj.URL === obj.name && monitorURLObj.Status === "1") {
                        monitor = true
                    }
                })
                obj.monitor = monitor
                if (obj.type === "A" || obj.type === "CNAME") {
                    dnsInformation.push(obj)
                } else {
                    extraDns.push(obj)
                }
            })

            if (data && data.domainDetails && data.domainDetails.Allow_auto_add === "1") {
                autoAdd = true
            }

            if (data.nameServers) {
                for (let i = 1; i <= 5; i++) {
                    if (data.nameServers && data.nameServers["name_server" + i] !== "") {
                        serverUrl.push(data.nameServers["name_server" + i])
                    }
                }
            }

            if (data.domainDetails && data.domainAutoDetails) {
                domainInformation = {
                    country: data.domainAutoDetails.country,
                    creation_date: data.domainAutoDetails.domain_creation_date,
                    dnssec: "",
                    domain_name: data.domain_name,
                    emails: null,
                    expiration_date: data.domainAutoDetails.domain_expiration_date,
                    name_servers: serverUrl,
                    organization: "",
                    registrar: data.domainAutoDetails.registrar,
                    registrar_iana: "",
                    registrar_url: data.domainAutoDetails.registrar_url,
                    state: data.domainAutoDetails.state,
                    status: "",
                    updated_date: data.domainAutoDetails.domain_updated_date
                }
            }

            this.setState({
                my_domain_name: data.domain_name,
                showInformation: true,
                dnsInformation: dnsInformation,
                extraDns: extraDns,
                autoAdd: autoAdd,
                domainInformation: domainInformation,
                domainAllData: data
            }, () => {
                this.checkAllDnsChecked()
            })
        } else {
            this.setState({
                showInformation: false
            })
        }
    }

    onClickAllDnsChecked = (value) => {
        let dnsInformation = [...this.state.dnsInformation]
        Array.isArray(dnsInformation) && dnsInformation.map((obj) => {
            obj.monitor = value
        })
        this.setState({
            dnsAllChecked: value,
            dnsInformation: dnsInformation
        })
    }

    onClickSingleDnsChecked = (value, index) => {
        let dnsInformation = [...this.state.dnsInformation]
        Array.isArray(dnsInformation) && dnsInformation.map((obj, i) => {
            if (index === i)
                obj.monitor = value
        })
        this.setState({
            dnsInformation: dnsInformation,
            dnsAllChecked: false
        }, () => {
            this.checkAllDnsChecked()
        })
    }

    checkAllDnsChecked = () => {
        let length = 0
        Array.isArray(this.state.dnsInformation) && this.state.dnsInformation.map((obj, i) => {
            if (obj.monitor)
                length = length + 1
        })

        if (this.state.dnsInformation.length === length) {
            this.setState({
                dnsAllChecked: true
            })
        }
    }

    goToCloudFire = () => {
        this.props.history.push('/connect-to-cloudflare')
    }

    autoAddUpdate = (value) => {
        this.setState({
            autoAdd: value
        })
    }

    gotoSeoMonitoring = async () => {
        if (isLogin()) {
            if (this.state.domainAllData && this.state.dnsInformation) {
                let domainAllData = this.state.domainAllData
                domainAllData.domainDetails.Allow_auto_add = this.state.autoAdd ? 1 : 0
                let data = {
                    "id": this.state.id,
                    "domain_name": this.state.my_domain_name,
                    "domainDetails": domainAllData.domainDetails,
                    "domainAutoDetails": domainAllData.domainAutoDetails,
                    "nameServers": domainAllData.nameServers,
                    "dnsDetails": Array.isArray(this.state.dnsInformation) ? this.state.dnsInformation.concat(this.state.extraDns) : []
                }

                let response = await domainServices.updateDomain(this.state.id, data)
                if (response && response.status === 200) {
                    if (response.message) {
                        toastr.success(response.message)
                    }
                    this.props.history.push('/update-seo-monitoring', { domain: this.state.my_domain_name, id: this.state.id, update: true })
                }
            }
            else {
                toastr.warning("Wait ...")
            }
        } else {
            toastr.warning("Please Login First!")
            this.props.history.push('/login')
        }
    }

    render() {
        return (<div >
            <div>
                <Header history={this.props.history} {...this.props}></Header>
                <div className="container">
                    <div className="card">
                        <div className="card-header" id="updateDomain">
                            <div className="information-input">
                                <h4>Domain Name : <span style={{ fontSize: 14 }}>{this.state.my_domain_name}</span>
                                </h4>
                                <Button style={{ fontSize: '18px' }} className="iconBtn" onClick={() => {
                                    localStorage.setItem("domainID", this.state.id)
                                    this.props.history.push('/view-domain-details', { id: this.state.id })
                                }}><MdRemoveRedEye /></Button>
                            </div>
                            <div className="">
                                <div className="mr-1"></div>
                            </div>
                            <div className="connet-clo">
                                <a href="javascript:void(0)" onClick={(e) => this.goToCloudFire(e)} className="cloudflare_a">Connect To Cloudflare</a>
                            </div>
                        </div>
                        {/* <div className="row">
                        <div className="col-md-12">
                            <div className="info-in">
                                
                                <div className="card"></div>
                                
                            </div>
                        </div>
                    </div> */}

                        {this.state.showInformation && <DomainInformation loaderDomain={this.state.loaderDomain} domainInformation={this.state.domainInformation}></DomainInformation>}
                        {this.state.showInformation && <div className="domain_informationtable">
                            {<DnsInformation cdn_loader={this.state.cdn_loader}
                                dnsInformation={this.state.dnsInformation}
                                title={true}
                                onClickAllDnsChecked={this.onClickAllDnsChecked}
                                dnsAllChecked={this.state.dnsAllChecked}
                                onClickSingleDnsChecked={this.onClickSingleDnsChecked}
                            ></DnsInformation>}
                        </div>}
                    </div>
                    {this.state.showInformation && <div> <div className="autoUpdateCheckBox"> <CheckBox value={this.state.autoAdd} onChange={this.autoAddUpdate} /> Auto Add/Update New Sub Domain as They are Added</div><Button className="NextButton" onClick={() => this.gotoSeoMonitoring()}>Next</Button></div>}
                </div>
            </div>
        </div>)
    }
}

export default AddDomain