import { RestMethodSecondOrigin } from "../../../_helpers/ApiConfigTwo/RestMethod";
import FilterResponse from "../../../_helpers/FilterResponse";
import { isAuthorized } from "../../../_helpers/Common/Common";

export const ApiStatusService = {
  ApiDetails,
};

async function ApiDetails(id, filter = "") {
  try {
    let q = "?id=" + id;
    if (filter) q += "&" + filter;

    const response = await RestMethodSecondOrigin.GET(
      "/api-status/api-details" + q
    );
    const api_response = FilterResponse(response);
    return api_response;
  } catch (error) {
    isAuthorized(error);
    return null;
  }
}
