import React, { useEffect, useState } from "react";
import { UsageChart } from "../../../_components/MonitorServerChart/index";
import CurrentLoggedIn from "../../../_components/CommonComponents/ServerCurrentLoggedIn/CurrentLoggedInModel";
import apache_img from "../../../assets/img/ServerIcon/apache.jpg";
import aws_img from "../../../assets/img/ServerIcon/aws.jpg";
import backup_img from "../../../assets/img/ServerIcon/database.png";
import folder_img from "../../../assets/img/ServerIcon/folder.jpg";
import googleFiles_img from "../../../assets/img/ServerIcon/not-allowed-folder.png";
import greenLock_img from "../../../assets/img/ServerIcon/monitor.png";
import mysql_img from "../../../assets/img/ServerIcon/mysql.jpg";

import server_on from "../../../assets/img/serveron.png";
import server_off from "../../../assets/img/serveroff.png";
import { domainDetailsServices } from "../../../_services/servicesWithSecondOrigin/index";
import { BiLineChart } from "react-icons/bi";
import { Button } from "reactstrap";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { RiDeleteBinLine } from "react-icons/ri";
import toastr from "toastr";
import { confirmAlert } from "react-confirm-alert";
import { AiFillAccountBook } from "react-icons/ai";
import { objectToQuery } from "../../../_helpers/Common/Common";

const ServerRecord = (props) => {
  let [data, setData] = useState([]);
  useEffect(() => {
    if (props.domain) {
      getDataFromServer();
    }
  }, [props.domain, props.refresh, props.recordReset]);
  const getDataFromServer = async () => {
    let objData = {
      domain_name: props.domain,
    };
    setData([]);
    let response = await domainDetailsServices.getAllDomainServer(objData);
    if (response && response.data) {
      setData(response.data);
    }
  };

  const removeServer = async (val) => {
    confirmAlert({
      title: "Confirm to Delete",
      message: <p>Are you sure to do this.</p>,
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            let dataArr = [];
            Array.isArray(data) &&
              data.map((obj) => {
                if (val.uuid !== obj.uuid) dataArr.push(obj.uuid);
              });
            let datao = {
              domain_name: props.domain,
              servers: dataArr,
            };
            let response = await domainDetailsServices.addServerListToDomain(
              datao
            );
            if (response && response && response.message) {
              toastr.success(response.message);
              getDataFromServer();
            }
          },
        },
        {
          label: "No",
          onClick: () => console.log("No"),
        },
      ],
    });
  };

  const onClickGotoCharts = (obj, key, setting) => {
    localStorage.setItem("moniterServerScroll", key);
    let object = {
      id: obj.id,
      uuid: obj.uuid,
      update: true,
      server_name: obj.server_name,
    };
    props.history.push(
      "/monitor-server/charts" + objectToQuery(object),
      object
    );
  };

  const onClickGotoSetting = (obj, key, setting) => {
    localStorage.setItem("moniterServerScroll", key);
    props.history.push("/monitor-server/server-setting", {
      id: obj.id,
      uuid: obj.uuid,
      server_name: obj.server_name,
      update: true,
    });
  };

  return (
    <div className="table-responsive">
      <table className="table table-bordered mb-0">
        <thead>
          <tr>
            <th scope="col">S.No.</th>
            {/* <th scope="col">Uuid</th> */}
            <th scope="col">Server</th>
            <th scope="col">Status</th>
            <th scope="col">Cpu Usage Chart (Last 30 mins)</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          {Array.isArray(data) &&
            data.map((obj, index) => {
              return (
                <>
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}>
                        <div>
                          <div>
                            <p className="m-0">Server: {obj.server_name}</p>
                            <p className="m-0">
                              Agent:{" "}
                              {obj.server_details &&
                                obj.server_details.App_version}
                            </p>
                          </div>
                        </div>
                        <div style={{ display: "flex" }}>
                          <div>
                            {obj.server_details &&
                            obj.server_details.server_status &&
                            obj.server_details.server_status.status === "UP" ? (
                              <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip>Server is UP</Tooltip>}>
                                <img
                                  src={server_on}
                                  width="30px"
                                  className="mr-3"
                                  alt="server_on"
                                />
                              </OverlayTrigger>
                            ) : (
                              <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip>Server is DOWN</Tooltip>}>
                                <img
                                  src={server_off}
                                  width="30px"
                                  className="mr-3"
                                  alt="server_off"></img>
                              </OverlayTrigger>
                            )}
                          </div>
                          {/* {obj.currently_logged_in ? <img src={online} width="32px" className="ml-3 mt-1" alt="online"></img> : <img src={offline_user} width="32px" className="ml-3 mt-1" alt="offline_user"></img>} */}
                          <CurrentLoggedIn
                            uuid={obj.uuid}
                            mytimezone={props.mytimezone}></CurrentLoggedIn>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div style={{ display: "flex" }}>
                        {obj.server_type === "Aws" ? (
                          <>
                            {obj.aws_icon === 1 ? (
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip>
                                    Amazon Web Services click to view details.
                                  </Tooltip>
                                }>
                                <img
                                  src={aws_img}
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    onClickGotoCharts(obj, "awsView")
                                  }
                                  alt="aws_img"
                                  className="moniterImges"
                                />
                              </OverlayTrigger>
                            ) : (
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip>
                                    Amazon Web Services disabled click to
                                    configure.
                                  </Tooltip>
                                }>
                                <div></div>
                              </OverlayTrigger>
                            )}
                          </>
                        ) : obj.server_type === "DigitalOcean" ? (
                          <>
                            {obj.aws_icon === 1 ? (
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip>
                                    Digital Ocean click to view details.
                                  </Tooltip>
                                }>
                                <AiFillAccountBook
                                  size="25"
                                  src={aws_img}
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    onClickGotoCharts(obj, "awsView")
                                  }
                                  alt="aws_img"
                                  className="moniterImges"
                                />
                              </OverlayTrigger>
                            ) : (
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip>
                                    Amazon Web Services disabled click to
                                    configure.
                                  </Tooltip>
                                }>
                                <div></div>
                              </OverlayTrigger>
                            )}
                          </>
                        ) : (
                          ""
                        )}

                        {obj.apche_icon === 1 ? (
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip>
                                Apache details are configured for monitoring
                                click to view details.
                              </Tooltip>
                            }>
                            <img
                              src={apache_img}
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                onClickGotoCharts(obj, "performance")
                              }
                              alt="apache_img"
                              className="moniterImges"
                            />
                          </OverlayTrigger>
                        ) : (
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip>
                                Apache not being monitored on this server click
                                to configure.
                              </Tooltip>
                            }>
                            <img
                              src={apache_img}
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                onClickGotoSetting(
                                  obj,
                                  "performance",
                                  "setting"
                                )
                              }
                              alt="apache_img"
                              className="moniterImges moniterImgesDisabled"
                            />
                          </OverlayTrigger>
                        )}

                        {obj.mysql_icon === 1 ? (
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip>
                                MySQL details are configured for monitoring
                                click to view details.
                              </Tooltip>
                            }>
                            <img
                              src={mysql_img}
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                onClickGotoCharts(obj, "performance")
                              }
                              alt="mysql_img"
                              className="moniterImges"
                            />
                          </OverlayTrigger>
                        ) : (
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip>
                                MySQL not being monitored on this server click
                                to configure.
                              </Tooltip>
                            }>
                            <img
                              src={mysql_img}
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                onClickGotoSetting(
                                  obj,
                                  "performance",
                                  "setting"
                                )
                              }
                              alt="mysql_img"
                              className="moniterImges moniterImgesDisabled"
                            />
                          </OverlayTrigger>
                        )}

                        {obj.disallowed_icon === 1 ? (
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip>
                                {" "}
                                Not Allowed Files monitoring configured click to
                                view details.
                              </Tooltip>
                            }>
                            <img
                              src={googleFiles_img}
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                onClickGotoCharts(obj, "notAllowedFile")
                              }
                              alt="googleFiles_img"
                              className="moniterImges"
                            />
                          </OverlayTrigger>
                        ) : (
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip>
                                Not Allowed Files monitoring not configured
                                click to configure.
                              </Tooltip>
                            }>
                            <img
                              src={googleFiles_img}
                              style={{ cursor: "pointer" }}
                              alt="googleFiles_img"
                              onClick={() =>
                                onClickGotoSetting(
                                  obj,
                                  "notAllowedFile",
                                  "setting"
                                )
                              }
                              className="moniterImges moniterImgesDisabled"
                            />
                          </OverlayTrigger>
                        )}

                        {obj.folder_change_icon === 1 ? (
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip>
                                File change monitoring configured click to view
                                details.
                              </Tooltip>
                            }>
                            <img
                              src={greenLock_img}
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                onClickGotoCharts(obj, "folderChange")
                              }
                              alt="greenLock_img"
                              className="moniterImges"
                            />
                          </OverlayTrigger>
                        ) : (
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip>
                                File change monitoring not configured click to
                                configure.
                              </Tooltip>
                            }>
                            <img
                              src={greenLock_img}
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                onClickGotoSetting(
                                  obj,
                                  "folderChange",
                                  "setting"
                                )
                              }
                              alt="greenLock_img"
                              className="moniterImges moniterImgesDisabled"
                            />
                          </OverlayTrigger>
                        )}

                        {obj.db_backup_icon === 1 ? (
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip>
                                DB Backup monitoring configured click to view
                                details.
                              </Tooltip>
                            }>
                            <img
                              src={backup_img}
                              style={{ cursor: "pointer" }}
                              onClick={() => onClickGotoCharts(obj, "backup")}
                              alt="backup_img"
                              className="moniterImges"
                            />
                          </OverlayTrigger>
                        ) : (
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip>
                                DB Backup monitoring not configured click to
                                configure.
                              </Tooltip>
                            }>
                            <img
                              src={backup_img}
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                onClickGotoSetting(obj, "backup", "setting")
                              }
                              alt="backup_img"
                              className="moniterImges moniterImgesDisabled"
                            />
                          </OverlayTrigger>
                        )}

                        {obj.folder_backup_icon === 1 ? (
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip>
                                Folder Backup monitoring configured click to
                                view details.
                              </Tooltip>
                            }>
                            <img
                              src={folder_img}
                              style={{ cursor: "pointer" }}
                              onClick={() => onClickGotoCharts(obj, "backup")}
                              alt="folder_img"
                              className="moniterImges"
                            />
                          </OverlayTrigger>
                        ) : (
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip>
                                Folder Backup monitoring not configured click to
                                configure.
                              </Tooltip>
                            }>
                            <img
                              src={folder_img}
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                onClickGotoSetting(obj, "backup", "setting")
                              }
                              alt="folder_img"
                              className="moniterImges moniterImgesDisabled"
                            />
                          </OverlayTrigger>
                        )}
                      </div>
                    </td>
                    <td>
                      <div
                        className="chartArea"
                        style={{
                          width: "350px",
                          height: "100px",
                          overflow: "hidden",
                        }}>
                        <UsageChart
                          // title="CPU Usage"
                          tooltip={true}
                          // toolbox={true}
                          uuid={obj.uuid}
                          user_element="cpu_usage"
                          SeriesType={["total", "system", "user"]}
                          active={0.5}
                          // tooltipFormatterCallBack={this.getTopProcessDate}
                          // isRefresh={this.state.isRefresh}
                          mytimezone={props.mytimezone}
                          grid={{
                            top: "2%",
                            left: "10%",
                            right: "0%",
                            bottom: "70%",
                            containLabel: true,
                          }}
                          onRefresh={props.onRefresh}
                        />
                      </div>
                    </td>
                    <td>
                      <div className="d-flex">
                        <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip>View server details</Tooltip>}>
                          <Button className="iconBtn">
                            <BiLineChart
                              onClick={() => {
                                localStorage.setItem("chartsRedireactTo", 2);
                                let object = {
                                  id: obj.id,
                                  uuid: obj.uuid,
                                  update: true,
                                  server_name: obj.server_name,
                                };
                                props.history.push(
                                  "/monitor-server/charts" +
                                    objectToQuery(object),
                                  object
                                );
                              }}
                            />
                          </Button>
                        </OverlayTrigger>
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip>Disconnect server from domain</Tooltip>
                          }>
                          <Button className="iconBtn deleteIcon ml-2">
                            <RiDeleteBinLine
                              onClick={() =>
                                removeServer({
                                  uuid: obj.uuid,
                                  server_name: obj.server_name,
                                })
                              }
                            />
                          </Button>
                        </OverlayTrigger>
                      </div>
                    </td>
                  </tr>
                </>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default ServerRecord;
