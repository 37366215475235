import React from 'react'
import {  Row, Col } from 'reactstrap';
import SpinnerForTable from '../../_components/CommonComponents/Loader/SpinnerForTable'
const DomainInformation = ({ domainInformation,loaderDomain }) => {
    const response = domainInformation
    return <>
        <div className="">
            <div className="">
            <Row>
                 <Col className="col-lg-1 col-md-1">
                    {loaderDomain ? <div className="domain-loader loader_div_width">
                        <SpinnerForTable loader={loaderDomain}/>
                    </div>:<div className="loader_div_width"></div>}
                </Col>
                <div className="col-md-12 mt-2">
                    <h4 className="p-3">Domain Information </h4>
                    <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">Domain Name</th>
                                <th scope="col">Name Servers</th>
                                <th scope="col">Organization</th>
                                <th scope="col">Registrar</th>
                                <th scope="col">Registrar Iana</th>
                                <th scope="col">Registrar URL</th>
                                <th scope="col">Expiration Date</th>
                                <th scope="col">dnssec</th>
                                <th scope="col">Country</th>
                                <th scope="col">state</th>
                                <th scope="col">Creation Date</th>
                                <th scope="col">Updated Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {response &&<tr>
                                {/* <th scope="row">1</th> */}
                                <td>{response ? Array.isArray(response.domain_name) && response.domain_name[0]?response.domain_name[0]:response.domain_name: ""}</td>
                                <td>{response ? Array.isArray(response.name_servers) ?response.name_servers.join(', '):response.name_servers : ""}</td>
                                <td>{response ? response.organization : ""}</td>
                                <td>{response ? response.registrar : ""}</td>
                                <td>{response ? response.registrar_iana : ""}</td>
                                <td>{response ? response.registrar_url : ""}</td>
                                <td>{response ? response.expiration_date : ""}</td>
                                <td>{response ? response.dnssec : ""}</td>
                                <td>{response ? response.country : ""}</td>
                                <td>{response ? response.state : ""}</td>
                                <td>{response ? response.creation_date : ""}</td>
                                <td>{response ? response.updated_date : ""}</td>
                            </tr>}
                        </tbody>
                    </table>
                    </div>
                </div>
            </Row>
        </div></div></>
}

export default DomainInformation