import React, { useState, useEffect, useCallback } from "react";
import { Table } from "reactstrap";
import { Pagination, Select } from "../CommonComponents/index";
import moment from "moment";
import { monitorServersServices } from "../../_services/servicesWithSecondOrigin";
const MonitorServerCustomLog = (props) => {
  const { headings } = props;

  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [sort, setSort] = useState({
    field: "date",
    dir: "ASC",
  });

  const onChangeSort = ({ target }) => {
    const field = target.getAttribute("about");
    setSort((prev) => ({
      field: field,
      dir: prev.field !== field ? "ASC" : prev.dir === "ASC" ? "DESC" : "ASC",
    }));
  };

  useEffect(() => {
    if (Array.isArray(props?.data)) {
      let length = props.data.length;
      let pageCount = parseInt(length / 10);
      if (length % 10 !== 0) {
        pageCount = pageCount + 1;
      }
      setPageCount(pageCount);
    }
  }, [props.data]);

  const setPageNumber = (page) => {
    setCurrentPage(page);
  };

  return (
    <div>
      <Table className="table table-bordered table-sortable ">
        <thead>
          <tr>
            <th style={{ width: 100 }}>S.No.</th>
            {headings.map((header) => (
              <th
                style={{ minWidth: 200 }}
                className={`
                // sortable${sort.field === "status" ? ` ${sort.dir.toLowerCase()}` : ""}
                `}
                // onClick={onChangeSort}
                // about="status"
              >
                {header}
              </th>
            ))}
          </tr>
        </thead>

        <tbody>
          {Array.isArray(props?.data) &&
            props.data.map((data, index) => {
              return (
                currentPage * 10 > index &&
                currentPage * 10 < index + 1 + 10 && (
                  <tr key={index}>
                    <th scope="row">{index + 1}</th>

                    {headings.map((header) => (
                      <td style={{ width: 300 }} onClick={onChangeSort} about="status">
                        {data[header] || "NA"}
                      </td>
                    ))}
                  </tr>
                )
              );
            })}
        </tbody>
      </Table>
      {Array.isArray(props.data) && props.data.length !== 0 && (
        <div className="paginationSec">
          <Pagination currentPage={currentPage} pageCount={pageCount} onClickPage={(page) => setPageNumber(page)}></Pagination>
        </div>
      )}
    </div>
  );
};

const MonitorServerCustomLogs = (props) => {
  const [logsData, setDataPaths] = useState({});
  const [active, onActive] = useState(4);

  const getData = useCallback(async () => {
    let d = new Date();
    var n = d.getTimezoneOffset();
    let data = {
      uuid: props.uuid,
      start_datetime: moment(new Date()).add(-active, "hours").format("YYYY-MM-DD HH:mm:ss"),
      end_datetime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      timezone: props.mytimezone,
      timediff: n,
    };

    let response = await monitorServersServices.customLogTableDetails(data);

    if (!response?.data) return false;
    setDataPaths(response?.data);
  }, [active, props.mytimezone, props.uuid]);

  useEffect(() => {
    getData();
  }, [active, getData]);

  const dataPaths = Object.keys(logsData);
  console.log({ dataPaths });
  console.log({ logsData });
  return (
    <>
      <div className="serverStatisticsSec">
        <div className="subTitleOuter">
          <h2 className="sectionHeading">Custom Logs</h2>
          <div className="timeZoneSelect" style={{ minWidth: "150px" }}>
            <Select
              selectedOption={active}
              setSelectedOption={(e) => onActive(e.value)}
              options={[
                { label: "4 Hours", value: 4 },
                { label: "8 Hours", value: 8 },
                { label: "12 Hours", value: 12 },
                { label: "24 Hours", value: 24 },
              ]}
            ></Select>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            {" "}
            {Array.isArray(dataPaths) && dataPaths.length > 0 ? (
              dataPaths.map((path) => {
                const headings = Object.keys(logsData[path].field[0]);

                return (
                  <div className="card overflow-auto">
                    <div className="card-header">
                      <div className="d-flex w-50">
                        <h3>Path Name: {path} </h3>
                      </div>
                    </div>
                    <MonitorServerCustomLog data={logsData[path].field} headings={headings}></MonitorServerCustomLog>
                  </div>
                );
              })
            ) : (
              <div className="text-center p-4" style={{ fontSize: "19px", color: "#d33768", fontWeight: "500" }}>
                No logs available
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default MonitorServerCustomLogs;
