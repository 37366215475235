import React, { useState, useEffect } from 'react'
import { Pagination } from '../CommonComponents/index'
const MonitorUrlsLogTable = (props) => {
    const [pageCount, setPageCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    let indexValue = 0
    useEffect(() => {
        if (props.urlDetails && Array.isArray(props.urlDetails.url_status_log)) {
            let length = props.urlDetails.url_status_log.length
            let pageCount = parseInt(length / 10)
            if (length % 10 !== 0) {
                pageCount = pageCount + 1
            }
            setPageCount(pageCount)
        }
    }, [props.urlDetails])


    const setPageNumber = (page) => {
        setCurrentPage(page)
        indexValue = 0
    }

    return <div>
       <div className="card-header">
                <h3>Latest Events (Up Down)</h3>
        </div>    
            <table className="table table-bordered">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Date</th>
                        <th>Duration</th>
                        <th>Status</th>
                        <th>Status Code</th>
                    </tr>
                </thead>
                <tbody>
                    {props.urlDetails && Array.isArray(props.urlDetails.url_status_log) && props.urlDetails.url_status_log.map((data, index) => {
                        if (currentPage * 10 > index && currentPage * 10 < index + 1 + 10)
                            return <tr>
                                <th scope="row">{indexValue = indexValue + 1}</th>
                                <td>{data.date}</td>
                                <td>{data.duration}</td>
                                <td>{data.status}</td>
                                <td>{data.status_code}</td>
                            </tr>
                    })}
                </tbody>
            </table>
            {Array.isArray(props.urlDetails.url_status_log) && props.urlDetails.url_status_log.length !== 0 && <div className="paginationSec">
                <Pagination
                    currentPage={currentPage}
                    pageCount={pageCount}
                    onClickPage={(page) => setPageNumber(page)}
                ></Pagination>
            </div>}
    </div>
}

export default MonitorUrlsLogTable