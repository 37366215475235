import React, { useState, useEffect } from "react";
import { Button, Progress } from "reactstrap";
// import AddEditUrlMonitorPopup from "../MonitorUrlsForm/AddEditUrlMonitorPopup";
import { MdRemoveRedEye, MdLink, MdEdit, MdDelete } from "react-icons/md";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import moment from "moment";
import { useHistory } from "react-router-dom";

const min_sec = 1000;
const ApiMonitorListingTable = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [allKey, setAllkeys] = useState([]);
  const [allValues, seAllValues] = useState([]);
  const [progressArray, setProgressArray] = useState([]);
  const toggle = () => setIsOpen(!isOpen);
  const history = useHistory();
  const MethodColorCode = ["#0cbb52", "#ffb400"];

  useEffect(() => {
    setAllkeys(
      props["hour_status"]
        ? Object.keys(props["hour_status"]).sort((a, b) =>
            a > b ? 1 : a < b ? -1 : 0
          )
        : []
    );
    seAllValues(
      props["hour_status"] ? Object.values(props["hour_status"]) : []
    );
  }, [props["hour_status"]]);

  useEffect(() => {
    if (allKey) manageRecord();
  }, [allKey]);

  const manageRecord = () => {
    let progressArray = [];
    allKey.map((datetime, index) => {
      let last24Hours = moment(new Date())
        .add(-24, "hours")
        .format("YYYY-MM-DD HH:mm:ss");
      let currentTime = moment(new Date());
      let createDate = "";
      if (props.allData && props.allData.create_datetime) {
        createDate = moment(
          props.allData.create_datetime,
          "YYYY-MM-DD HH:mm:ss"
        ).format("YYYY-MM-DD HH:mm:ss");
      }
      var a = moment(datetime, "YYYY-MM-DD HH:mm:ss");
      var startTime = moment(last24Hours, "YYYY-MM-DD HH:mm:ss");
      if (last24Hours < datetime) {
        // date is less then 24 hours
        if (allKey[index - 1]) {
          //if value is before current time if not then this is first record
          if (last24Hours > allKey[index - 1]) {
            let count = a.diff(startTime, "seconds");
            progressArray[0] = {
              count: count > min_sec ? count : min_sec,
              //value: allValues[index] === "UP" ? "DOWN" : "UP",
              value: props["hour_status"][datetime] === "UP" ? "DOWN" : "UP",
              key: "2",
            };
          } else {
            let count = a.diff(
              moment(allKey[index - 1], "YYYY-MM-DD HH:mm:ss"),
              "seconds"
            );
            progressArray.push({
              count: count > min_sec ? count : min_sec,
              // value: allValues[index] === "UP" ? "DOWN" : "UP",
              value: props["hour_status"][datetime] === "UP" ? "DOWN" : "UP",
              key: "3",
            });
          }
        } else {
          let count = a.diff(startTime, "seconds");
          progressArray = [];

          if (createDate > last24Hours) {
            progressArray.push({
              count: count > min_sec ? count : min_sec,
              //value: allValues[index] === "UP" ? "DOWN" : "UP",
              value: "No data available",
              key: "4",
            });
          } else {
            progressArray.push({
              count: count > min_sec ? count : min_sec,
              //value: allValues[index] === "UP" ? "DOWN" : "UP",
              value: props["hour_status"][datetime] === "UP" ? "DOWN" : "UP",
              key: "4",
            });
          }
        }

        if (allKey.length - 1 === index) {
          let count1 = currentTime.diff(a, "seconds");
          progressArray.push({
            count: count1 > min_sec ? count1 : min_sec,
            // value: allValues[index],
            value: props["hour_status"][datetime],
            key: "5",
          });
        }
      } else {
        progressArray[0] = {
          count: 24 * 60 * 60,
          //value: allValues[index],
          value: props["hour_status"][datetime],
          key: "1",
        };
      }
    });
    setProgressArray(progressArray);
  };

  const onClickOpenUrl = () => {
    props.allData.id !== props.id && props.onClick();
  };

  return (
    <>
      {/* <div className="monitorListSec" style={props.allData.id === props.id ? { background: "#fafafa", border: "1px solid #516ee3" } : {}}> */}
      <div
        className={
          props.allData.id === props.id
            ? "monitorListSec listactive"
            : "monitorListSec"
        }>
        <div className="d-flex align-items-center">
          {props.hideInfo ? null : (
            <>
              <div
                className={props.viewDomain ? "col-md-3" : "col-md-2"}
                onClick={toggle}>
                {props.allData && props.allData.api_type ? (
                  <span
                    // className={
                    //   props.allData && props.allData.api_type === "GET"
                    //     ? "https"
                    //     : "htt"
                    // }
                    style={{
                      color: `${
                        props.allData.api_type === "GET"
                          ? MethodColorCode[0]
                          : MethodColorCode[1]
                      }`,
                    }}>
                    {props.allData && props.allData.api_type}
                  </span>
                ) : (
                  ""
                )}
              </div>
              <div
                className={
                  props.viewDomain ? "col-md-8 pl-0 d-flex" : "col-md-4 pl-0"
                }
                onClick={toggle}>
                {props.allData.api_name && (
                  <div className="webAdd">{props.allData.api_name}</div>
                )}{" "}
              </div>
            </>
          )}

          {props.hideChart ? null : (
            <>
              <div className={props.viewDomain ? "col-md-12" : "col-md-4"}>
                {Array.isArray(progressArray) && (
                  <Progress multi onClick={() => onClickOpenUrl()}>
                    {progressArray.map((data, index) => {
                      return (
                        <>
                          {["top", "right", "bottom", "left"].map(
                            (placement) => (
                              <OverlayTrigger
                                key={placement}
                                placement={placement}
                                overlay={
                                  <Tooltip id={`tooltip-${placement}`}>
                                    {data.value}
                                  </Tooltip>
                                }>
                                <Progress
                                  bar
                                  className={
                                    data.value === "No data available"
                                      ? "grayProgressbar"
                                      : ""
                                  }
                                  color={
                                    data.value === "No data available"
                                      ? ""
                                      : data.value === "UP"
                                      ? "success"
                                      : "danger"
                                  }
                                  value={(data.count * 100) / (60 * 60 * 24)}
                                />
                              </OverlayTrigger>
                            )
                          )}
                        </>
                      );
                    })}
                  </Progress>
                )}
              </div>
            </>
          )}
          {props.deleteDomain && (
            <div className="col-md-2 monitorActionIcons">
              <div style={{ display: "flex" }}>
                <div>
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>View</Tooltip>}>
                    <Button
                      className="iconBtn"
                      onClick={() => onClickOpenUrl()}>
                      <MdRemoveRedEye />
                    </Button>
                  </OverlayTrigger>
                </div>
                <div className="mr-2">
                  {/* <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>Delete Api</Tooltip>}>
                    <MdDelete
                      className="actionIcon"
                    />
                  </OverlayTrigger> */}

                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>Edit Api</Tooltip>}>
                    <MdEdit
                      className="actionIcon"
                      onClick={() => {
                        history.push("/api-monitor-form", {
                          updatedApiData: props.allData,
                        });
                      }}
                    />
                  </OverlayTrigger>
                </div>
                {/* <AddEditUrlMonitorPopup
                  edit={true}
                  allData={props.allData}
                  callback={props.callback}></AddEditUrlMonitorPopup> */}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ApiMonitorListingTable;
