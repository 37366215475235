import React, { useState } from "react";
import { Button, Input, Row, Col } from 'reactstrap';
import ListItem from './ListItem'
import { Validation } from '../../_helpers/validation/Validation'
import { Constants } from './Constants'
const SocialMediaFrom = ({ ...props }) => {
    const [url, setUrl] = useState("")
    const [error, setError] = useState({})
   
    const addUrl = () => {
        let flag = 0
        const field = Constants().urlValidation;
        let errobj = Validation({url:url}, field);
        setError(errobj)
        Object.keys(errobj).map(index => {
            if (errobj[index] !== "") {
                flag = 1;
            }
        });

        if(flag === 0){
            props.addUrl(url)
            setUrl("")
        }
    }

    return <div className="seo-monitoring-form">
        <div className="card cardhgt">
        <div className="card-header">
            <h3>Monitor URLs</h3>
            <h3>Domain: {props.domain}</h3>
        </div>
        <div className=" p-4 ">
            <div>
                <Row>
                    <Col> <Input value={url} placeholder="Enter URL" onChange={e => setUrl(e.target.value,setError({}))}></Input>
                    <div>{error && error.url}</div>
                    </Col>
                    <Col> <Button className="addEditBtn primary-btn btn btn-secondary" onClick={addUrl}>Add</Button></Col>
                </Row>
            </div>
            <div className="monitor-url-listing">
                <h3>List of Already Monitored Urls For the Domain</h3>
            </div>
            <ListItem
                monitorUrlsArray={props.monitorUrlsArray}
                {...props}
            ></ListItem>
        </div>
        </div>
        
    </div>
}

export default SocialMediaFrom