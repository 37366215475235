import { RestMethodSecondOrigin } from "../../../_helpers/ApiConfigTwo/RestMethod";
import FilterResponse from "../../../_helpers/FilterResponse";
import toastr from "toastr";

export const notificationService = {
  getOpenNotification,
  allNotification,
  allResourceSetting,
  updateSettingData,
  removeNotification,
};

async function getOpenNotification(data) {
  try {
    const response = await RestMethodSecondOrigin.POST(
      "/notification/open-notification",
      data
    );
    const api_response = FilterResponse(response);
    return api_response;
  } catch (error) {
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.Error
    ) {
      toastr.error(error.response.data.Error);
    }
    console.log("error detected while fetching data from api");
    return null;
  }
}

async function allNotification(data) {
  try {
    const response = await RestMethodSecondOrigin.POST(
      "/notification/all-notification",
      data
    );
    const api_response = FilterResponse(response);
    return api_response;
  } catch (error) {
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.Error
    ) {
      toastr.error(error.response.data.Error);
    }
    console.log("error detected while fetching data from api");
    return null;
  }
}

async function allResourceSetting(data) {
  try {
    const response = await RestMethodSecondOrigin.POST(
      "/resource-notification-setting/all-resource-setting",
      data
    );
    const api_response = FilterResponse(response);
    return api_response;
  } catch (error) {
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.Error
    ) {
      toastr.error(error.response.data.Error);
    }
    console.log("error detected while fetching data from api");
    return null;
  }
}

async function updateSettingData(data) {
  try {
    const response = await RestMethodSecondOrigin.POST(
      "/resource-notification-setting/update-resource-setting",
      data
    );
    const api_response = FilterResponse(response);
    return api_response;
  } catch (error) {
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.Error
    ) {
      toastr.error(error.response.data.Error);
    }
    console.log("error detected while fetching data from api");
    return null;
  }
}

async function removeNotification(id) {
  try {
    const response = await RestMethodSecondOrigin.GET(
      "/notification/update-seen-notification?id=" + id
    );
    const api_response = FilterResponse(response);
    return api_response;
  } catch (error) {
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.Error
    ) {
      toastr.error(error.response.data.Error);
    }
    console.log("error detected while fetching data from api");
    return null;
  }
}
