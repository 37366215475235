import { RestMethodSecondOrigin } from "../../../_helpers/ApiConfigTwo/RestMethod";
import FilterResponse from "../../../_helpers/FilterResponse";
import { isAuthorized } from "../../../_helpers/Common/Common";

export const creditsServices = {
  getUserCredits,
  getUserCreditsList,
  getUserUserCreditsList,
};

async function getUserCredits(data) {
  try {
    const response = await RestMethodSecondOrigin.GET(
      "/user-credits/get-user-credits"
    );
    const api_response = FilterResponse(response);
    return api_response;
  } catch (error) {
    isAuthorized(error);
    console.log("error detected while fetching data from api");
    return null;
  }
}

async function getUserCreditsList(type, page, pageSize) {
  try {
    const response = await RestMethodSecondOrigin.GET(
      `/user-credits/get-credits-list?type=${type}&page=${page}&pageSize=${pageSize}`
    );
    const api_response = FilterResponse(response);
    return api_response;
  } catch (error) {
    isAuthorized(error);
    console.log("error detected while fetching data from api");
    return null;
  }
}

async function getUserUserCreditsList(data, type, page, pageSize) {
  try {
    const response = await RestMethodSecondOrigin.POST(
      `/user-credits/used-credit`,
      data
    );
    const api_response = FilterResponse(response);
    return api_response;
  } catch (error) {
    isAuthorized(error);
    console.log("error detected while fetching data from api");
    return null;
  }
}
