import React, { useState } from 'react'
import { monitorServersServices } from '../../../_services/servicesWithSecondOrigin/index'
import {
  Button
} from 'reactstrap';
import toastr from 'toastr'

const DisallowedFilesForm = (props) => {
    const [checkedArray, setCheckedArray] = useState([])
    const [id, setId] = useState("")

    const onCheck = (value, sId) => {
        setId(sId)
        let checkedarray = checkedArray.slice()
        const index = checkedarray.indexOf(value);
        if (index > -1) {
            checkedarray.splice(index, 1);
        } else {
            checkedarray.push(value)
        }
        setCheckedArray(checkedarray)
    }

    const onSubmit = async () => {
        if (id) {
            let data = {
                "id": id,
                "files": checkedArray
            }
            let response = await monitorServersServices.updateDisallowedFiles(data)
            if (response && response.message) {
                toastr.info(response.message)
            }
        }
    }

    return <div>
        <h6><span className="w-600">Folder Name</span>{": " + props.data.folder_name} </h6>
        {Array.isArray(props.data['disallowed-files'][props.data.folder_name]) && props.data['disallowed-files'][props.data.folder_name].length > 0 && <h6>Fllowing disallowed files were found in the folder</h6>}
       <div className='ml-4'>
        {Array.isArray(props.data['disallowed-files'][props.data.folder_name]) && props.data['disallowed-files'][props.data.folder_name].map((n_data, n_index) => {
            if (n_data)
                return <label style={{ fontSize: '13px' }} className="checkboxLabel"><input checked={checkedArray.indexOf(n_data) > -1} type="checkbox" className="checkboxInput" onClick={() => onCheck(n_data, props.data['disallowed-files'].id)} />{n_data}</label>
        })}
        </div>
        <Button className="mb-4 ml-4 mt-2 addEditBtn primary-btn btn btn-secondary btn btn-secondary btn btn-secondary" onClick={() => onSubmit()}>Add files to exceptions list</Button>
    </div>
}

export default DisallowedFilesForm