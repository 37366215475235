import React from 'react'
import Header from '../../_components/CommonComponents/Header/Header'
import MonitorUrlListing from '../../_components/MonitorUrlsListing/MonitorUrlListing'
import MonitorUrlprogressBar from '../../_components/MonitorUrlsListing/MonitorUrlprogressBar'
import MonitorUrlsLogTable from '../../_components/MonitorUrlsLogTable/MonitorUrlsLogTable'
import { Progress, Table } from 'reactstrap'
import MonitorUrlChartOperation from '../../_components/MonitorUrlsChartOperation/MonitorUrlChartOperation'
import MonitorUrlResponseChartOperation from '../../_components/MonitorUrlsChartOperation/MonitorUrlResponseChartOperation'
import { UrlStatusServices } from '../../_services/servicesWithSecondOrigin/index'
import moment from 'moment'
import { DateTimeFilter } from '../../_components/CommonComponents/index'
import AddEditUrlMonitorPopup from '../../_components/MonitorUrlsForm/AddEditUrlMonitorPopup'
import { Notes } from '../../_components/CommonComponents/index'
import PageLader from '../../_components/CommonComponents/PageLoader/Loader'
import { RiBarChartFill } from "react-icons/ri";
import { AiFillClockCircle } from "react-icons/ai";
import { MdRefresh } from "react-icons/md";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import * as echarts from "echarts";
import topArrow from "../../assets/img/top-arrow.svg"
import defaultDashboard from "../../assets/img/blank-monitor-urls-list.png";

class MonitorUrlsList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            allUrls: [],
            disabledData: [],
            urlDetails: "",
            loader: false,
            myTimezone: "",
            start_datetime: moment(new Date()).add(-props.active || -24, 'hours').format("YYYY-MM-DD HH:mm:ss"),
            hours: 24,
            minute: 0,
            end_datetime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
            id: "",
            refresh: "",
            allurlLoader: true,
            urlId: "",

            urlResponse: "",
            urlResponseloader: false,
            echartRefObj: {},
            onRefresh:""
        }
    }

    componentDidMount() {
        let Timezone = localStorage.getItem("Timezone")
        this.setState({
            myTimezone: Timezone
        }, () => {
            this.getUrls()
            if (this.props.location && this.props.location.state && this.props.location.state.urlId) {
                this.getUrlDetails(this.props.location.state.urlId)
            }
        })
    }

    refresh = () => {
        this.getUrls()
    }

    getUrls = async () => {
        var d = new Date();
        var n = d.getTimezoneOffset();
        this.setState({
            allurlLoader: true
        })
        let response = await UrlStatusServices.allUrls({
            "timezone": this.state.myTimezone,
            timediff: n
        })
        if (response && response.data && Array.isArray(response.data)) {
            this.setState({
                disabledData: response.disbale,
                allUrls: response.data,
                allurlLoader: false
            })
        } else {
            this.setState({
                allurlLoader: false
            })
        }
    }

    getUrlDetails = async (id) => {
        var d = new Date();
        var n = d.getTimezoneOffset();
        this.setState({ loader: true, urlResponseloader: true, id: id, urlDetails: [], refresh: Math.random() })
        let data = {
            "start_datetime": this.state.start_datetime,
            "end_datetime": this.state.end_datetime,
            "timezone": this.state.myTimezone,
            timediff: n
        }
        let response = await UrlStatusServices.UrlDetails(id, data)
        if (response && response.data) {
            this.setState({
                urlDetails: response.data,
                loader: false,
            })
        } else {
            this.setState({
                urlDetails: "",
                loader: false,
            })
        }
        this.getUrlResponse(id)
    }

    getUrlResponse = async (id) => {
        var d = new Date();
        var n = d.getTimezoneOffset();
        this.setState({ urlResponse: [] })
        let data = {
            "start_datetime": this.state.start_datetime,
            "end_datetime": this.state.end_datetime,

            // "start_datetime": "2021-09-10 13:04:45",
            // "end_datetime": "2021-09-11 15:04:45",
            "timezone": this.state.myTimezone,
            timediff: n
        }
        let response = await UrlStatusServices.urlResponse(id, data)
        if (response && response.data) {
            this.setState({
                urlResponse: response.data,
                urlResponseloader: false,
            })
        } else {
            this.setState({
                urlResponse: "",
                urlResponseloader: false,
            })
        }
    }

    setStartDateTime = (date) => {
        this.setState({
            start_datetime: moment(date).format("YYYY-MM-DD HH:mm:ss"),
        }, () => {
            var a = moment(this.state.end_datetime, "YYYY-MM-DD HH:mm:ss");
            var b = moment(this.state.start_datetime, "YYYY-MM-DD HH:mm:ss");
            let c = a.diff(b, 'days')
            if (c > 5) {
                this.setState({
                    end_datetime: b.add(5, 'd').format("YYYY-MM-DD HH:mm:ss")
                })
            }
        })
    }
    // end_datetime: b.add(5, 'D').add(-1, 'D').format("YYYY-MM-DD HH:mm:ss")
    setEndDateTime = (date) => {
        this.setState({
            end_datetime: moment(date).format("YYYY-MM-DD HH:mm:ss")
        }, () => {
            var a = moment(this.state.end_datetime, "YYYY-MM-DD HH:mm:ss");
            var b = moment(this.state.start_datetime, "YYYY-MM-DD HH:mm:ss");
            let c = a.diff(b, 'days')
            if (c > 5) {
                this.setState({
                    start_datetime: a.add(-5, 'd').format("YYYY-MM-DD HH:mm:ss")
                })
            }
        })
    }

    onClickShowGraphData = () => {
        var now = moment(this.state.end_datetime); //todays date
        var end = moment(this.state.start_datetime); // another date
        var duration = moment.duration(now.diff(end));
        var hours = duration.asHours();
        var minute = duration.asMinutes();
        minute = minute % 60
        this.setState({
            hours: hours,
            minute: minute
        })
        this.getUrlDetails(this.state.id)
    }

    onClickGetFreshUserdetails = (id) => {
        this.setState({
            start_datetime: moment(new Date()).add(-24, 'hours').format("YYYY-MM-DD HH:mm:ss"),
            hours: 24,
            minute: 0,
            end_datetime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
        }, () => {
            this.getUrlDetails(id)
        })
    }


    getRestoreChart = () => {
        let echartRefObj = this.state.echartRefObj;
        for (const key in echartRefObj) {
            if (Object.hasOwnProperty.call(echartRefObj, key)) {
                const myref = echartRefObj[key];
                if (myref && myref.current && myref.current.getEchartsInstance()) myref.current.getEchartsInstance().setOption(myref.current.props.option, true);
            }
        }
    };

    setEchartRef = (ref, id) => {
        let echartRefObj = this.state.echartRefObj;
        if (id) echartRefObj[id] = ref;
        this.setState({ echartRefObj }, () => {
            this.connectCharts(echartRefObj);
        });
    };

    connectCharts = (echartRefObj) => {
        let data = [];
        for (const key in echartRefObj) {
            if (Object.hasOwnProperty.call(echartRefObj, key)) {
                const myref = echartRefObj[key];
                if (myref && myref.current && myref.current.getEchartsInstance()) data.push(myref.current.getEchartsInstance());
            }
        }

        if (data.length > 1) echarts.connect(data);
    };

    refreshData=()=>{
        this.setState({onRefresh:Math.random()})
  }

    render() {
        const { allUrls, urlDetails, hours, minute, disabledData, urlResponse } = this.state

        return (<div className="container">
            <PageLader isLoading={this.state.allurlLoader}></PageLader>
            <Header history={this.props.history} {...this.props} callRefresh={this.refreshData}></Header>
            {Array.isArray(allUrls) && allUrls.length > 0 ?
                <div className="row">
                    <div className="col-lg-5">
                        <div className="card">
                            <div className="card-header">
                                <div>
                                    <h3>Monitor URL</h3>
                                    <h6 className="headerDes">This page lists all the URLs that are being monitored. This displayes all the detailed statistics of URL and notifies you when the URL is up or down</h6>
                                </div>
                                <AddEditUrlMonitorPopup callback={this.getUrls}></AddEditUrlMonitorPopup>

                                <OverlayTrigger
                                    key={'bottom'}
                                    placement={'bottom'}
                                    overlay={<Tooltip>Refresh</Tooltip>}>
                                    <MdRefresh style={{ width: '50px' }} className="iconBtn pl-1 ml-3" onClick={() => this.refresh()}></MdRefresh>
                                </OverlayTrigger>
                                {/* <OverlayTrigger
                                key={'left'}
                                placement={'left'}
                                overlay={<Tooltip>Refresh</Tooltip>}>
                                <MdRefresh size="20" className="iconBtn" onClick={() => this.refresh()}></MdRefresh>
                            </OverlayTrigger> */}
                            </div>
                            {Array.isArray(allUrls) && allUrls.length > 0 && <div className="monitorUrlTitle">
                                <div className="row">
                                    <div className="col-md-6 col-5">
                                        <div className="lastTitle"><AiFillClockCircle className="clockIcon" /><strong>Last 24 hours</strong></div>
                                    </div>
                                    <div className="col-md-4 col-7">
                                        <div className="hoursValue">
                                            <span>{moment().format("HH:mm")}</span>
                                            <span>{moment().add(6, 'hours').format("HH:mm")}</span>
                                            <span>{moment().add(-6, 'hours').format("HH:mm")}</span>
                                            <span>{moment().format("HH:mm")}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                            {Array.isArray(allUrls) && allUrls.map((data) => {
                                return <MonitorUrlListing key={data.id} allurlLoader={this.state.allurlLoader} callback={this.getUrls} hour_status={data['24_hour_status']} monitorUrlname={data.URL} allData={data} onClick={() => this.onClickGetFreshUserdetails(data.id)} id={this.state.id} />
                            })}
                        </div>
                        {Array.isArray(disabledData) && disabledData.length !== 0 && <div className="card">
                            <div className="card-header">
                                <div>
                                    <h3>Disabled Monitor URL</h3>
                                </div>
                            </div> {disabledData.map((data) => {
                                return <MonitorUrlListing key={data.id} allurlLoader={this.state.allurlLoader} callback={this.getUrls} hour_status={data['24_hour_status']} monitorUrlname={data.URL} allData={data} onClick={() => this.onClickGetFreshUserdetails(data.id)} id={this.state.id} />
                            })}
                        </div>}
                    </div>







                    {this.state.id && <div className="col-lg-7">
                        <div className="card overflow-inherit">
                            <div className="card-header">
                                {urlDetails && urlDetails.urlDetails && urlDetails.urlDetails.URL && <h3> {urlDetails.urlDetails.URL} </h3>}
                                <Notes id={this.state.id} type="url_monitor"></Notes>
                            </div>



                            <div className="m-2">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="card">
                                            <div className="card-header"><h3>Current Status</h3></div>
                                            <div className="card-body componentCenter" style={{ minHeight: "138px" }}>
                                                <div className="serverstatusbg">
                                                    {urlDetails && urlDetails.current_status && <span className="statusText" style={urlDetails && urlDetails.current_status && urlDetails.current_status.status === "UP" ? { background: "#ebf7ee", color: "#27a644" } : { background: '#fef1f1', color: '#dc3545' }}>{urlDetails && urlDetails.current_status && urlDetails.current_status.status}</span>}
                                                    {urlDetails && urlDetails.current_status && <span className="font-13">{`Since ${urlDetails && urlDetails.current_status && urlDetails.current_status.time} (${urlDetails && urlDetails.current_status && urlDetails.current_status.date})`}</span>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="card">
                                            <div className="card-header"><h3>Uptime Status</h3></div>
                                            <div className="card-body pt-3 pb-3">
                                                {urlDetails && urlDetails.last_24_hours !== "" && urlDetails.last_24_hours !== null &&

                                                    <span className={(parseInt(urlDetails.last_24_hours) > 94 ? "greenOver95 statusText mb-1" : parseInt(urlDetails.last_24_hours) > 79 ? "onabove79 statusText mb-1" : urlDetails.last_24_hours === "No suficient data found!" ? "no-sufficient-data statusText mb-1" : "red-on-blow79 statusText mb-1")}>
                                                        {isNaN(urlDetails.last_24_hours) ? <span>{urlDetails.last_24_hours}</span> : `${parseFloat(urlDetails.last_24_hours).toFixed(6)}% Last 24 Hours`}
                                                    </span>}
                                                {urlDetails && urlDetails.last_7_days !== "" && urlDetails.last_7_days !== null &&


                                                    <span className={(parseInt(urlDetails.last_7_days) > 94 ? "greenOver95 statusText mb-1" : parseInt(urlDetails.last_7_days) > 79 ? "onabove79 statusText mb-1" : urlDetails.last_7_days === "No suficient data found!" ? "no-sufficient-data statusText mb-1" : "red-on-blow79 statusText mb-1")} >
                                                        {isNaN(urlDetails.last_7_days) ? <span>{urlDetails.last_7_days}</span> : `${parseFloat(urlDetails.last_7_days).toFixed(6)}% Last 7 Days`}
                                                    </span>}

                                                {urlDetails && urlDetails.last_30_days !== "" && urlDetails.last_30_days !== null &&


                                                    <span className={(parseInt(urlDetails.last_30_days) > 94 ? "greenOver95 statusText mb-1" : parseInt(urlDetails.last_30_days) > 79 ? "onabove79 statusText mb-1" : urlDetails.last_30_days === "No suficient data found!" ? "no-sufficient-data statusText mb-1" : "red-on-blow79 statusText mb-1")} >
                                                        {isNaN(urlDetails.last_30_days) ? <span>{urlDetails.last_30_days}</span> : `${parseFloat(urlDetails.last_30_days).toFixed(6)}% Last 30 Days`}
                                                    </span>}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="card m-2">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="card-header"><h3>Latest Downtime</h3></div>
                                        <div className="card-body componentCenter">
                                            <div className="serverstatusbg">
                                                {urlDetails && urlDetails.last_down_time && <span className="statusText" style={urlDetails.last_down_time.status === "UP" ? { background: "#ebf7ee", color: "#27a644" } : { background: '#fef1f1', color: '#dc3545' }}>{urlDetails.last_down_time.status}</span>}
                                                {urlDetails && urlDetails.last_down_time && urlDetails.last_down_time.date && urlDetails.last_down_time.time && <span className="font-13">{`it was recorded on ${urlDetails.last_down_time.date} and down time latest for ${urlDetails.last_down_time.time}`}</span>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>










                            <div className="dateTimeFilterSec">
                                <div className="uptimeIntro">
                                    <RiBarChartFill className="upBarChartIcon" /> <h3>Uptime <span>(Last 24 hours)</span></h3>
                                </div>
                                <div className="pb-3 mb-1">
                                    <MonitorUrlprogressBar hour_status={urlDetails && urlDetails['24_hour_status'] ? urlDetails['24_hour_status'] : []} create_datetime={urlDetails?.urlDetails?.create_datetime ? urlDetails.urlDetails.create_datetime : ''} />
                                </div>

                                <div className="select_startdatetime">
                                    <DateTimeFilter
                                        start_datetime={this.state.start_datetime}
                                        end_datetime={this.state.end_datetime}
                                        setStartDateTime={this.setStartDateTime}
                                        setEndDateTime={this.setEndDateTime}
                                        onClickShowGraphData={this.onClickShowGraphData}
                                        onClickGetFreshUserdetails={() => this.onClickGetFreshUserdetails(this.state.id)}
                                    />
                                </div>
                                {/* <div className='mt-5'>
                            <div>Uptime Last 24 Hours</div>
                            <Progress multi>
                                <Progress bar color="success" value="30" />
                                <Progress bar color="danger" value="70" />
                            </Progress>
                        </div>*/}
                                <div className="respondTimeSec">Response Time:  <span>{hours && parseInt(hours)} Hours {minute && parseInt(minute)} Minutes</span></div>
                            </div>
                        </div>

                        <div className="card">
                            <div className="card-header">
                                <h3>Server Response Time</h3>
                            </div>
                            <div className="row">
                                <div className="col-md-12 pb-2 monitorURLchart">
                                    <MonitorUrlChartOperation
                                        urlDetails={urlDetails.response_time ? urlDetails.response_time : []}
                                        title={''}
                                        tooltip={true}
                                        toolbox={true}
                                        loader={this.state.loader}
                                        refresh={this.state.refresh}
                                        dataZoom={true}
                                        onRefresh={this.state.onRefresh}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="card">
                            <div className="card-header">
                                <h3>Server Response Time - breakup</h3>
                            </div>
                            <div className="row">
                                <div className="col-md-12 pb-2 monitorURLchart">
                                    <MonitorUrlResponseChartOperation
                                        urlDetails={urlResponse ? urlResponse : []}
                                        title={''}
                                        tooltip={true}
                                        toolbox={true}
                                        loader={this.state.urlResponseloader}
                                        refresh={this.state.refresh}
                                        dataZoom={true}
                                        tofixed={true}
                                        onRefresh={this.state.onRefresh}
                                    />
                                </div>
                            </div>
                        </div>



                        <div className="card">
                            {urlDetails && <MonitorUrlsLogTable
                                urlDetails={urlDetails}
                            ></MonitorUrlsLogTable>}
                            {/* <div>
                            <div className="mt-2">
                                Latest Events (Up Down Start Pouse)
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Date</th>
                                            <th>Duration</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {urlDetails && Array.isArray(urlDetails.url_status_log) && urlDetails.url_status_log.map((data, index) => {
                                            return <tr>
                                                <th scope="row">{index + 1}</th>
                                                <td>{data.date}</td>
                                                <td>{data.duration}</td>
                                                <td>{data.status}</td>
                                            </tr>
                                        })}

                                    </tbody>
                                </Table>
                            </div>
                        </div> */}
                        </div>
                    </div>
                    }
                </div>
                :
                <div className="card">
                    <div className="card-header">
                        <div>
                            <h3>Monitor URL</h3>
                        </div>
                        <div className='d-flex'>
                            <AddEditUrlMonitorPopup callback={this.getUrls}></AddEditUrlMonitorPopup>
                            <OverlayTrigger
                                key={'bottom'}
                                placement={'bottom'}
                                overlay={<Tooltip>Refresh</Tooltip>}>
                                <MdRefresh className="iconBtn ml-2" onClick={() => this.refresh()}></MdRefresh>
                            </OverlayTrigger>
                        </div>
                    </div>
                    <div className="blankPageUI">
                        <div className="row">
                            <div className="notActiveTxt col-md-6">
                                <p className="notactivedata">
                                    <strong>Welcome to URL Monitoring</strong>
                                    It seems you have not configured any URLs to be monitored.
                                </p>
                                <p>You simply need to click <span className='dataAddIcon'><AddEditUrlMonitorPopup callback={this.getUrls}></AddEditUrlMonitorPopup></span> icon to add urls. We monitor that URL for you.</p>
                                <p>We report to you if that URL is down or there is degradation in performance.</p>
                            </div>
                            <div className="text-center col-md-6">
                                <div className="defaultImg">
                                    <img src={topArrow} className="topArrow" />
                                    <img src={defaultDashboard} alt="default-dashboard" className="img-fluid" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>)
    }
}

export default MonitorUrlsList