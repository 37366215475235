import React from "react";
import { Error, TooltipPro } from "../../../CommonComponents/index";
import { Input, Button } from "reactstrap";
import { MdAdd, MdRemove } from "react-icons/md";
import { AiOutlineInfoCircle } from "react-icons/ai";

const MonitorCustomLogsForm = ({ ...props }) => {
  return (
    <>
      {props.data && (
        <div className=" mt-4">
          <div className="row mt-4">
            <div className="col-md-5">
              <label>Report Fields</label>
              <span className="ml-2">
                <TooltipPro
                  className="bottom tsize-400"
                  tooltip={
                    <div className="row">
                      <div className="col-md-12">
                        <p>
                          In case there are lots of fields in the log file, but you are interested in seeing data from only a few fields on G8keeper dashboard, you can mention the
                          field number that you want to see.{" "}
                        </p>
                      </div>
                    </div>
                  }
                >
                  <AiOutlineInfoCircle className="infoIcon" />
                </TooltipPro>
              </span>
              <Input
                value={props.data.field}
                name="field"
                placeholder="Report Fields"
                onChange={(e) => {
                  const { value } = e.target;
                  if (value !== "" && isNaN(value)) return false;
                  props.onChangeFolders(e, props.index);
                }}
              ></Input>
              {props.errorArray[props.s_index]?.report_fields[props?.index]?.field && <Error error={props.errorArray[props.s_index]?.report_fields[props?.index]?.field}></Error>}
            </div>
            <div className="col-md-5">
              <label>Header</label>
              <span className="ml-2">
                <TooltipPro
                  className="bottom tsize-400"
                  tooltip={
                    <div className="row">
                      <div className="col-md-12">
                        <p>You need to specify the heading for the field number, so G8Keeper will show the data for that field under the given header.</p>
                      </div>
                    </div>
                  }
                >
                  <AiOutlineInfoCircle className="infoIcon" />
                </TooltipPro>
              </span>
              <Input placeholder="Header" value={props.data.header} name="header" onChange={(e) => props.onChangeFolders(e, props.index)}></Input>
              {props.errorArray[props.s_index]?.report_fields[props?.index]?.header && <Error error={props.errorArray[props.s_index]?.report_fields[props?.index]?.header}></Error>}
            </div>

            {
              <div className="mt-4 col-md-2 p-0">
                <div className="d-flex ">
                  {(props.hideRemove || props.data.id) && (
                    <div className="mr-2">
                      <Button className="remove-sev" onClick={props.remove}>
                        <MdRemove></MdRemove>
                      </Button>
                    </div>
                  )}
                  {props.showAdd && (
                    <div className="">
                      <Button className="remove-sev add-sev" onClick={props.addSubFieldFiles}>
                        <MdAdd></MdAdd>
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            }
          </div>
        </div>
      )}
    </>
  );
};
export default MonitorCustomLogsForm;
