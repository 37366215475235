import { OverlayTrigger, Tooltip } from 'react-bootstrap';
const MTooltip = (props) => {
    return <>
        {['top'].map((placement) => (
            <OverlayTrigger
                key={placement}
                placement={placement}
                overlay={
                    <Tooltip id={`tooltip-${placement}`}>
                        {props.tooltip}
                    </Tooltip>
                }
            >
                {props.UI}
            </OverlayTrigger>
        ))}
    </>
}

export default MTooltip