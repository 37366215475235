import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { RiFileDownloadLine } from 'react-icons/ri';
import { FiCopy } from "react-icons/fi";
import toastr from 'toastr'
const AppInstallSteps = (props) => {
    const [modal, setModal] = useState(false);
    const toggle = () => {
        setModal(!modal);
    }

    const copyText = (key) => {
        if (key) {
            navigator.clipboard.writeText(key)
            toastr.success("Copied!")
        }
    }

    return (
        <div>
            <OverlayTrigger placement="top" overlay={<Tooltip>Installs Instructions </Tooltip>}>
                <Button className="iconBtn ml-2" onClick={() => toggle()}><RiFileDownloadLine /></Button>
            </OverlayTrigger>
            <Modal size="lg" isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle} className="notesHeader"><div>Install Steps</div>
                </ModalHeader>
                <ModalBody>
                    <div>

                        {/*                         
                        Run this command on your server to setup g8keeper agent

                        <p> {`sudo bash -c "$(curl -sL http://static.g8keeperapp.com/install-g8keeper.sh)" -i -key=${props.uuid} -secret=${props.secretkey}`}</p> */}
                        <p>
                            <h6 className="command-title">Run this command on your server to setup g8keeper agent.
                            To view what all you can do with the agent on server <a href="https://g8keeperapp.com/how-it-works/manage-g8keeper-agent-on-server/" target="_blank">click here </a>

                            </h6>
                            <div className="p-relative">
                                <p className="command-box"> {`sudo bash -c "$(curl -sL http://static.g8keeperapp.com/install-g8keeper.sh)" -i -key=${props.uuid} -secret=${props.secretkey}`}
                                    <FiCopy style={{cursor:'pointer'}} className="copy-text-button" onClick={() => copyText(`sudo bash -c "$(curl -sL http://static.g8keeperapp.com/install-g8keeper.sh)" -i -key=${props.uuid} -secret=${props.secretkey}`)}></FiCopy>
                                </p>
                            </div>
                        </p>
                    </div>
                </ModalBody>
            </Modal>
        </div>
    );
}

export default AppInstallSteps;
