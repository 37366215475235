import React from "react";
import Header from "../../_components/CommonComponents/Header/Header";
import {
  StatisticsLatencyOperation,
  ChartMySqlOperation,
} from "../../_components/MonitorServerChart/index";
import { ServerCurrentLoggedIn } from "../../_components/CommonComponents/index";
import { Button } from "reactstrap";
import { IoIosArrowRoundBack } from "react-icons/io";
import moment from "moment";
import DatabaseSchema from "../../_components/DatabaseSchema/DatabaseSchema";
import { graphsKeys } from "./Constants";
import { objectToQuery } from "../../_helpers/Common/Common";

class MonitorServerIndividualChartWithNoTime extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      uuid: "",
      title: "",
      chart: "",
      user_element: "",
      server_name: "",
      mytimezone: "",
      start_datetime: moment(new Date())
        .add(-4, "hours")
        .format("YYYY-MM-DD HH:mm:ss"),
      end_datetime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      start_datetimeForChart: moment(new Date())
        .add(-4, "hours")
        .format("YYYY-MM-DD HH:mm:ss"),
      end_datetimeForChart: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      graphArray: [],
      type: "",
      refreh: true,
      unique_id: null,
      onRefresh: "",
      active: 4,
    };
  }

  refreshData = () => {
    this.setState({ onRefresh: Math.random() });
  };

  componentDidMount() {
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.id
    ) {
      this.setState({
        id: this.props.location.state.id,
        uuid: this.props.location.state.uuid,
        title: this.props.location.state.title,
        active: this.props.location.state.active,
        dbName: this.props.location.state.dbName,
        user_element: this.props.location.state.user_element,
        chart: this.props.location.state.chart,
        server_name: this.props.location.state.server_name,
        mytimezone: this.props.location.state.mytimezone,
        type: this.props.location.state.type,
        graphArray: [
          {
            title: this.props.location.state.title,
            user_element: this.props.location.state.user_element,
            chart: this.props.location.state.chart,
            type: this.props.location.state.type,
          },
        ],
      });
    } else {
      this.props.history.push("/monitor-servers");
    }
  }

  onSelectServer = (e) => {
    this.setState(
      {
        id: e.id,
        uuid: e.value,
        server_name: e.label,
        refreh: false,
      },
      () => {
        setTimeout(() => {
          this.setState({ refreh: true });
        }, 500);
      }
    );
  };

  onSelectUniqueId = (e) => {
    this.setState({
      unique_id: e.value,
    });
  };

  render() {
    let object = {
      id: this.state.id,
      uuid: this.state.uuid,
      update: true,
      server_name: this.state.server_name,
    };
    return (
      <div className="container">
        <Header
          history={this.props.history}
          {...this.props}
          callRefresh={this.refreshData}></Header>

        {/* Filter section */}
        <div className="serverDetailsSec justify-content-between">
          <div className="d-flex">
            <Button
              className="backBtn mr-4"
              onClick={() =>
                this.props.history.push(
                  "/monitor-server/charts" + objectToQuery(object),
                  object
                )
              }>
              <IoIosArrowRoundBack></IoIosArrowRoundBack>
            </Button>
            <ServerCurrentLoggedIn
              server_name={this.state.server_name}
              uuid={this.state.uuid}
              onSelectServer={this.onSelectServer}
              mytimezone={this.state.mytimezone}
              unique_id={this.state.unique_id}
              onSelectUniqueId={this.onSelectUniqueId}
            />
          </div>
        </div>
        {/* End */}

        {this.state.chart === 1 && this.state.refreh ? (
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header"></div>
                <div className="largeChartInnerDiv details-page">
                  <StatisticsLatencyOperation
                    title={this.state.title}
                    tooltip={true}
                    toolbox={true}
                    uuid={this.state.uuid}
                    mytimezone={this.state.mytimezone}
                    isLegend={true}
                    dataZoom={true}
                    onRefresh={this.state.onRefresh}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : null}

        {this.state.chart === 2 && (
          <DatabaseSchema
            id={this.state.id}
            details={true}
            dataZoom={true}
            limit={30}
            opts={{ height: 500 }}
            grid={{
              top: "15%",
              left: "3%",
              right: "4%",
              bottom: "3%",
              containLabel: true,
            }}
            isLegend></DatabaseSchema>
        )}

        {this.state.chart === 4 && (
          <div className={"card"} style={{ height: 550, overflow: "unset" }}>
            <div className="chartInnerDiv my-2">
              <ChartMySqlOperation
                tooltip={true}
                toolbox={true}
                uuid={this.state.uuid}
                user_element="mysql_metadata1"
                SeriesType={graphsKeys}
                active={this.state.active}
                isRefresh={this.state.isRefresh}
                mytimezone={this.state.mytimezone}
                type="total-latency"
                dbName={this.state.dbName}
                onTotalLatencyChart={true}
                unique_id={this.state.unique_id}
                onRefresh={this.state.onRefresh}
                limit={30}
                opts={{ height: 500 }}
                grid={{
                  top: "15%",
                  left: "3%",
                  right: "4%",
                  bottom: "3%",
                  containLabel: true,
                }}
                isLegend
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default MonitorServerIndividualChartWithNoTime;
