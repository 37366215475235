import React, { useState, useEffect } from "react";
import { Form, Input, Button } from "reactstrap";
import { MButton, Select, Loader, TooltipPro, Error } from "../../CommonComponents/index";
import { Constants } from "../Constants";
import { monitorServersSettingServices } from "../../../_services/servicesWithSecondOrigin/index";
import toastr from "toastr";
import { Validation } from "../../../_helpers/validation/Validation";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { confirmAlert } from "react-confirm-alert";
import MonitorCustomLogsForm from "./MonitorCustomLogsForm/MonitorCustomLogsForm";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { MdAdd, MdClear, MdRemove } from "react-icons/md";

const fileObj = {
  // monitor_server_id: "26",
  log_path: "",
  field_separator: "",
  review_file_every_mins: "1",
  report_fields: [
    {
      field: "",
      header: "",
    },
  ],
  enable_notification: "0",
  notification_type: "1",
  specific_text: [""],
};

const MonitorCustomLogs = ({ ...props }) => {
  const [monitorLogs, setLogs] = useState([{ ...fileObj }]);
  const [errorArray, setErrorArray] = useState([]);
  const [loader, setLoader] = useState(false);
  const [disable, setDisabled] = useState(false);

  useEffect(() => {
    setErrorArray([]);
  }, [monitorLogs]);
  useEffect(() => {
    if (props.active === 9) {
      getDataFromServer();
    }
  }, [props.MSId, props.active]);

  const getDataFromServer = async () => {
    setLoader(true);
    if (props.MSId) {
      let response = await monitorServersSettingServices.getCustomLogs(props.MSId);
      if (Array.isArray(response?.data) && response?.data.length !== 0) {
        setLogs(response?.data);
      } else if (Array.isArray(response?.data) && response?.data.length === 0) {
        setLogs([fileObj]);
      }
    }
    setLoader(false);
  };

  const addFiles = () => {
    setDisabled(false);
    let cloneArray = monitorLogs.concat({ ...fileObj, monitor_server_id: props.MSId });
    setLogs(cloneArray);
  };

  const addSubFieldFiles = (index) => {
    setDisabled(false);
    let cloneArray = JSON.parse(JSON.stringify(monitorLogs));
    cloneArray[index].report_fields = [...cloneArray[index].report_fields, { field: "", header: "" }];
    setLogs(cloneArray);
  };

  const remove = (index) => {
    confirmAlert({
      title: "Confirm to Delete",
      message: <p>Are you sure to do this.</p>,
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            let cloneArray = monitorLogs.slice();
            if (cloneArray[index].id) {
              let response = await monitorServersSettingServices.deleteCustomLogs(cloneArray[index].id);
              if (response?.status === 200) {
                cloneArray.splice(index, 1);
                setLogs(cloneArray.length < 1 ? [fileObj] : cloneArray);
              }
            } else {
              if (cloneArray.length === 1) {
                setLogs([fileObj]);
              } else {
                cloneArray.splice(index, 1);
                setLogs(cloneArray);
              }
            }
          },
        },
        {
          label: "No",
          onClick: () => console.log("No"),
        },
      ],
    });
  };

  const removeSubIndex = (index, subIndex) => {
    let cloneArray = JSON.parse(JSON.stringify(monitorLogs));

    if (cloneArray[index].report_fields[subIndex].field === "" && cloneArray[index].report_fields[subIndex].header === "") {
      if (cloneArray[index].report_fields.length === 1) {
        cloneArray[index].report_fields.push({
          field: "",
          header: "",
        });
      }
      cloneArray[index].report_fields.splice(subIndex, 1);
      setLogs(cloneArray);
    } else {
      confirmAlert({
        title: "Confirm to Delete",
        message: <p>Are you sure to do this.</p>,
        buttons: [
          {
            label: "Yes",
            onClick: async () => {
              if (cloneArray[index].report_fields.length === 1) {
                cloneArray[index].report_fields.push({
                  field: "",
                  header: "",
                });
              }
              cloneArray[index].report_fields.splice(subIndex, 1);
              setLogs(cloneArray);
            },
          },
          {
            label: "No",
            onClick: () => console.log("No"),
          },
        ],
      });
    }
  };

  const deletePerticularSettingData = async (cloneArray, index, subIndex) => {
    setDisabled(false);
    if (cloneArray[index].report_fields.length === 1) {
      cloneArray[index].report_fields.push({
        field: "",
        header: "",
      });
    }
    cloneArray[index].report_fields.splice(subIndex, 1);

    let data = cloneArray[index];

    let response = await monitorServersSettingServices.updateCustomLogs(data.id, data);
    if (response?.status === 200) {
      if (response?.message) toastr.success(response.message);

      setLogs(cloneArray);
    }
  };

  const onChangeLogs = (e, index) => {
    let cloneArray = JSON.parse(JSON.stringify(monitorLogs));
    cloneArray[index][e.target.name] = e.target.value.trim();
    setDisabled(false);
    setLogs(cloneArray);
  };

  const onChangeSubArrayLogs = (e, index, subIndex) => {
    const { value } = e.target;
    let cloneArray = JSON.parse(JSON.stringify(monitorLogs));
    cloneArray[index].report_fields[subIndex][e.target.name] = value.trimStart();
    setDisabled(false);
    setLogs(cloneArray);
  };

  const onSubmit = async (e) => {
    let cloneArray = JSON.parse(JSON.stringify(monitorLogs));
    cloneArray = Array.isArray(cloneArray)
      ? cloneArray?.map((obj) => {
          if (parseInt(obj.enable_notification) !== 1) {
            obj.notification_type = "";
            obj.specific_text = [];
          }
          if (obj.notification_type === 1) {
            obj.specific_text = [];
          }

          return obj;
        })
      : [];

    console.log(cloneArray);

    let flag = 0;
    let errorArray = [];
    const field = Constants().customFieldsRules;
    const subField = Constants().customFieldsSubRules;

    Array.isArray(cloneArray) &&
      cloneArray.map((obj) => {
        let errobj = Validation(obj, field);
        Object.keys(errobj).map((index) => {
          if (errobj[index] !== "") {
            flag = 1;
          }
        });

        let subArr = [];
        obj.report_fields.map((ob) => {
          let errob = Validation(ob, subField);
          Object.keys(errob).map((index) => {
            if (errob[index] !== "") {
              flag = 1;
            }
          });

          subArr = subArr.concat(errob);
        });
        let specifiArr = [];
        obj.specific_text.map((val) => {
          specifiArr.push(val.trimStart() ? "" : "Specific text is Required.");
          if (!val.trimStart() && parseInt(obj.notification_type) === 2) flag = 1;
        });
        errobj["specific_text"] = specifiArr;
        errobj["report_fields"] = subArr;
        errorArray = errorArray.concat(errobj);
      });

    setErrorArray(errorArray);

    if (flag === 0) {
      setLoader(true);
      setDisabled(true);
      let response = await monitorServersSettingServices.saveCustomLogs({ logs: cloneArray }, props.MSId);
      if (response.message) {
        toastr.success(response?.message);
        getDataFromServer();
      } else {
        setDisabled(false);
      }
      setLoader(false);
    }
  };

  const addSpecificText = (index) => {
    setDisabled(false);
    let cloneArr = JSON.parse(JSON.stringify(monitorLogs));
    cloneArr[index].specific_text.push("");
    setLogs(cloneArr);
    setDisabled(false);
  };

  const removeSpecificText = (index, subIndex) => {
    setDisabled(false);
    let cloneArr = JSON.parse(JSON.stringify(monitorLogs));

    if (cloneArr[index].specific_text[subIndex]) {
      confirmAlert({
        title: "Confirm to Delete",
        message: <p>Are you sure to do this.</p>,
        buttons: [
          {
            label: "Yes",
            onClick: async () => {
              cloneArr[index].specific_text.splice(subIndex, 1);
              setLogs(cloneArr);
            },
          },
          {
            label: "No",
            onClick: () => console.log("No"),
          },
        ],
      });
    } else {
      cloneArr[index].specific_text.splice(subIndex, 1);
      setLogs(cloneArr);
    }
  };

  const onChangeSpecificText = (e, index, subIndex) => {
    setDisabled(false);
    const { value } = e.target;
    let cloneArr = JSON.parse(JSON.stringify(monitorLogs));
    cloneArr[index].specific_text[subIndex] = value.trimStart();
    setLogs(cloneArr);
  };

  const onCheckNotification = (val, index) => {
    setDisabled(false);
    let cloneArr = JSON.parse(JSON.stringify(monitorLogs));
    cloneArr[index].enable_notification = val;
    setLogs(cloneArr);
  };

  const onChangeNewLineAndSpecificText = (val, index) => {
    setDisabled(false);
    let cloneArr = JSON.parse(JSON.stringify(monitorLogs));
    cloneArr[index].notification_type = val;
    if (val === 2 && cloneArr[index].specific_text.length === 0) {
      cloneArr[index].specific_text = [""];
    }
    setLogs(cloneArr);
  };

  console.log("errorArray", errorArray);
  return (
    <>
      <div className="serverSettingData">
        <div className="setLoaderPosition">
          <Loader loader={loader}></Loader>
        </div>

        <div className="serverInfoTabsTitle pt-4">
          <h3>
            Monitor Custom Logs
            <span className="ml-2">
              <TooltipPro
                className="bottom tsize-600"
                tooltip={
                  <div className="row">
                    <div className="col-md-12">
                      <p>G8keeper can keep track of your custom application logs and display the data on dashboard. You can track multiple logs as well by using "Add More"</p>
                      <strong>Some rules need to be followed while creating log:</strong>
                      <div>1. Log needs to be written in a single line at a time (with no line breaks)</div>
                      <div>2. Every line in the log file needs to have datetime in the exact format 16/Mar/2023:16:30:50 as the first field on log </div>
                      3. Log file needs to use field separators as comma, pipe or tabs
                    </div>
                  </div>
                }
              >
                <AiOutlineInfoCircle className="infoIcon" />
              </TooltipPro>
            </span>
          </h3>
        </div>

        {Array.isArray(monitorLogs) &&
          monitorLogs.map((obj, index) => {
            return (
              <div className="fileSection">
                <Form onSubmit={(e) => onSubmit(e)}>
                  <div className="row mt-4">
                    <div className="col-md-12">
                      <label>Path for the log</label>
                      <span className="ml-2">
                        <TooltipPro
                          className="bottom tsize-400"
                          tooltip={
                            <div className="row">
                              <div className="col-md-12">
                                <p>Enter the full path of the log from root directory like /var/log/apache2/access.log</p>
                              </div>
                            </div>
                          }
                        >
                          <AiOutlineInfoCircle className="infoIcon" />
                        </TooltipPro>
                      </span>
                      <Input value={obj.log_path} name="log_path" placeholder="Path for the log" onChange={(e) => onChangeLogs(e, index)}></Input>
                      {errorArray[index] && <Error error={errorArray[index].log_path}></Error>}
                    </div>
                    <div className="col-md-4 mt-3">
                      <label>Field separator</label>
                      <span className="ml-2">
                        <TooltipPro
                          className="bottom tsize-400"
                          tooltip={
                            <div className="row">
                              <div className="col-md-12">
                                <p>
                                  You can use following characters as field separator in your log files, pipes (|), comma (,), tabs (entered as '\t' ) If you data can have pipes,
                                  comma and tabs in data as well, then may be you can use a combination or multiple instances of these for example use three pipes as separator |||
                                  or 2 comma and 2 pipes together ,,|| You can use any combination of these, just ensure that that combination does not appear in the data
                                </p>
                              </div>
                            </div>
                          }
                        >
                          <AiOutlineInfoCircle className="infoIcon" />
                        </TooltipPro>
                      </span>
                      <Input
                        value={obj.field_separator}
                        name="field_separator"
                        placeholder="Field separator"
                        onChange={(e) => {
                          // const { value } = e.target;
                          // let re = /[|;,]+$/i;
                          // let result = re.test(value);
                          // if ((value !== "" && !result) || value.length > 1) return false;
                          onChangeLogs(e, index);
                        }}
                      ></Input>

                      {errorArray[index] && <Error error={errorArray[index].field_separator}></Error>}
                    </div>

                    <div className="col-md-11 mt-3">
                      <div style={{}}>
                        <div class="performanceCheck">
                          <div className="customCheckBox content-fit-css">
                            <input
                              type="checkbox"
                              checked={parseInt(obj.enable_notification) === 1}
                              onClick={() => onCheckNotification(parseInt(obj.enable_notification) === 1 ? 0 : 1, index)}
                              id={"onCheckNotification" + index}
                            />
                            <label className="checkbox-height" htmlFor={"onCheckNotification" + index} style={{ paddingRight: 30 }}>
                              Generate Notification
                            </label>
                            <span className="performanceInfo">
                              <TooltipPro
                                className="bottom tsize-600"
                                tooltip={
                                  <div className="row">
                                    <div className="col-md-12">
                                      <p>
                                        If you check this box, a notification will be generated if the condition is met. For example, if you enable notification for new lines, that
                                        will generate notification for every time a new line is added in the log file.{" "}
                                      </p>
                                    </div>
                                  </div>
                                }
                              >
                                <AiOutlineInfoCircle className="infoIcon" />
                              </TooltipPro>
                            </span>
                          </div>
                        </div>
                        <div class="performanceCheck">
                          {/* <div className="customCheckBox">
                            <input
                              type="checkbox"
                              checked={parseInt(obj.enable_notification) === 1}
                              onClick={() => onCheckNotification(parseInt(obj.enable_notification) === 1 ? 0 : 1, index)}
                              id={"onCheckNotification" + index}
                            />
                            <label className="checkbox-height" htmlFor={"onCheckNotification" + index} style={{ paddingRight: 30 }}>
                              Generate Notification
                            </label>
                            <span className="performanceInfo">
                              <TooltipPro
                                className="bottom tsize-600"
                                tooltip={
                                  <div className="row">
                                    <div className="col-md-12">
                                      <p>G8Keeper tracks and reports important statictics of MySQL. This feature is supported on MySQL 5.7 or above. </p>
                                      <p>
                                        You need to provide details to access the MySQL server. The details you provide needs to have access to collect performance statictics. On
                                        how to setup user please{" "}
                                        <a target="_blank" href="https://g8keeperapp.com/how-it-works/create-mysql-db-user-for-performance-monitoring/">
                                          click here
                                        </a>{" "}
                                        and follow the steps.
                                      </p>
                                      <p>
                                        <strong>Note: </strong>Some statictics are collected every minute and reported. More detailed ones are collected once per day.
                                      </p>
                                    </div>
                                  </div>
                                }
                              >
                                <AiOutlineInfoCircle className="infoIcon" />
                              </TooltipPro>
                            </span>
                          </div> */}
                          {parseInt(obj.enable_notification) === 1 && (
                            <div className="d-flex gap8">
                              <div class="form-check" style={{ paddingLeft: 65 }}>
                                <input
                                  class="form-check-input"
                                  type="radio"
                                  name="autoscaling_db"
                                  id="autoscaling_db"
                                  checked={parseInt(obj.notification_type) === 1}
                                  onClick={() => onChangeNewLineAndSpecificText(1, index)}
                                />
                                <label onClick={() => onChangeNewLineAndSpecificText(1, index)} class="form-check-label" htmlFor="autoscaling_db">
                                  New line
                                </label>
                              </div>
                              <div class="form-check" style={{ paddingLeft: 65 }}>
                                <input
                                  onClick={() => onChangeNewLineAndSpecificText(2, index)}
                                  class="form-check-input"
                                  type="radio"
                                  name="autoscaling_db"
                                  id="autoscaling_db"
                                  checked={parseInt(obj.notification_type) === 2}
                                />
                                <label onClick={() => onChangeNewLineAndSpecificText(2, index)} class="form-check-label" htmlFor="autoscaling_db">
                                  Specific text in the file
                                </label>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 mx-3 d-flex flex-wrap">
                      {parseInt(obj.enable_notification) === 1 && parseInt(obj.notification_type) === 2 && (
                        <>
                          {Array.isArray(obj?.specific_text) &&
                            obj.specific_text.map((val, i) => {
                              return (
                                <div className=" col-md-3 mt-3">
                                  <div className="d-flex ">
                                    <div className="w-100">
                                      <Input
                                        value={val}
                                        name="field_separator"
                                        placeholder="Specific text "
                                        onChange={(e) => {
                                          // const { value } = e.target;
                                          // let re = /[|;,]+$/i;
                                          // let result = re.test(value);
                                          // if ((value !== "" && !result) || value.length > 1) return false;
                                          onChangeSpecificText(e, index, i);
                                        }}
                                      ></Input>
                                      {errorArray[index] && <Error error={errorArray[index].specific_text[i]}></Error>}
                                    </div>
                                    {obj.specific_text.length > 1 && (
                                      <div className="ml-1">
                                        <Button className="remove-sev remove-button-md" onClick={() => removeSpecificText(index, i)}>
                                          <MdRemove></MdRemove>
                                        </Button>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              );
                            })}

                          <div className={"col-md-0.5 mt-3 " + (obj.specific_text.length === 0 ? "ml-4" : "ml-0")}>
                            <Button className="remove-sev add-sev remove-button-md" onClick={() => addSpecificText(index)}>
                              <MdAdd></MdAdd>
                            </Button>
                          </div>
                        </>
                      )}
                    </div>
                    {/* <div className="col-md-4">
                      <label>Review File Every Mins</label>
                      <span className="ml-2">
                        <TooltipPro
                          className="bottom tsize-400"
                          tooltip={
                            <div className="row">
                              <div className="col-md-12">
                                <p>Log in every minute </p>
                              </div>
                            </div>
                          }
                        >
                          <AiOutlineInfoCircle className="infoIcon" />
                        </TooltipPro>
                      </span>
                      <Input value={obj.review_file_every_mins} name="review_file_every_mins" placeholder="Review File Every Mins" onChange={(e) => onChangeLogs(e, index)}></Input>
                    </div> */}
                  </div>

                  <div className="fileSection mt-3">
                    {Array.isArray(obj.report_fields) &&
                      obj.report_fields.map((data, index1) => {
                        return (
                          <MonitorCustomLogsForm
                            hideRemove={obj.report_fields.length !== 1}
                            errorArray={errorArray}
                            remove={() => removeSubIndex(index, index1)}
                            data={data}
                            onChangeFolders={(e, i) => onChangeSubArrayLogs(e, index, i)}
                            index={index1}
                            addSubFieldFiles={() => addSubFieldFiles(index)}
                            showAdd={obj.report_fields.length - 1 === index1}
                            s_index={index}
                          ></MonitorCustomLogsForm>
                        );
                      })}
                  </div>

                  <div className="mt-4 disallowedbuttons justify-content-center">
                    {monitorLogs.length - 1 === index && <MButton label="Add More" className="bluebutton mx-1" onClick={() => addFiles()}></MButton>}
                    {monitorLogs.length - 1 === index && <MButton disabled={disable} label="Save" onClick={(e) => onSubmit(e)} className="bluebutton mx-1"></MButton>}
                  </div>
                </Form>
                {(monitorLogs.length > 1 || monitorLogs[0].id) && (
                  <div className="mt-3">
                    <OverlayTrigger placement="top" overlay={<Tooltip>Delete this setting </Tooltip>}>
                      <Button className="btn-danger removeSecIcon" onClick={() => remove(index)}>
                        <MdClear />
                      </Button>
                    </OverlayTrigger>
                  </div>
                )}
              </div>
            );
          })}
      </div>
    </>
  );
};

export default MonitorCustomLogs;
