import React from "react";
import {
  domainServices,
  UrlStatusServices,
} from "../../_services/servicesWithSecondOrigin/index";
import Header from "../../_components/CommonComponents/Header/Header";
import SeoMonitorChartOperation from "../../_components/SeoMonitorChartOperation/SeoMonitorChartOperation";
import SocialMediaChartOperation from "../../_components/SocialMediaChartOperation/SocialMediaChartOperation";
import { Loader, Notes } from "../../_components/CommonComponents/index";
import MonitorUrlListing from "../../_components/MonitorUrlsListing/MonitorUrlListing";
import moment from "moment";
import DomainListing from "../../_components/DomainDropDown/DomainDropDown";
import MoniterUrlDetailsChart from "../../_components/MonitorUrlsListing/MoniterUrlDetailsChart";
import {
  ServerRecords,
  EmailBlackList,
  ConnectServer,
  CustomMalware,
  ShareDomain,
} from "../../_components/DomainDetails/index";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { MdRemoveRedEye } from "react-icons/md";
import { Button } from "reactstrap";
import AddEditUrlMonitorPopup from "../../_components/MonitorUrlsForm/AddEditUrlMonitorPopup";
import SeoMonitoringPopup from "../SeoMonitoring/SeoMonitoringPupup";
import SocialMediaPopup from "../SocialMedia/SocialMediaPopup";
import Notifications from "../NotificationChannel/NotificationsPopup";
import { MdRefresh } from "react-icons/md";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { MdAdd } from "react-icons/md";
import { BsInfoCircleFill } from "react-icons/bs";
import LightHouseChartOperation from "../../_components/LightHouseChartOperation/LightHouseChartOperation";
class ViewDomainDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      my_domain_name: "",
      showInformation: false,
      domainInformation: "",
      domainAllData: "",
      loaderDomain: false,
      isLoading: true,
      refresh: Math.random(),
      loader: false,
      myTimezone: "",
      start_datetime: moment(new Date())
        .add(-props.active || -24, "hours")
        .format("YYYY-MM-DD HH:mm:ss"),
      end_datetime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      allUrls: [],
      allUrlsDisable: [],
      ssl_enabled: "",
      ssl_expiration_date: "",
      ssl_provider: "",
      dnsdetails: [],
      showAddSeoModal: false,
      showAddFollowerModal: false,
      showAddPostModal: false,
      DomainCompanyName: "",
      DomainBlacklistStatus: "",
      DomainMalwareStatus: "",
      DomainBlacklistUpdateTime: "",
      DomainMalwareUpdateTime: "",
      DomainCustomMalwareUpdateTime: "",
      DomainCustomMalwareStatus: "",
      onRefresh: "",
    };
  }

  refreshData = () => {
    this.setState({ onRefresh: Math.random() });
  };

  setShowAddSeoModal = (flag) => this.setState({ showAddSeoModal: flag });
  setShowAddFollowerModal = (flag) =>
    this.setState({ showAddFollowerModal: flag });
  setShowAddPostModal = (flag) => this.setState({ showAddPostModal: flag });

  componentDidMount() {
    this.getPosition();
    let Timezone = localStorage.getItem("Timezone");
    this.setState({
      myTimezone: Timezone,
    });
    let id = localStorage.getItem("domainID");
    if (id) {
      this.setState(
        {
          id: id,
        },
        () => {
          this.getDomainDataFromSecondOrigin();
        }
      );
    } else {
      this.props.history.push("/dashboard");
    }

    if (localStorage.getItem("cID") && localStorage.getItem("cID") !== "null") {
      let id = localStorage.getItem("cID");
      this.setState(
        {
          domainMalwareColleps: id === "malware" ? true : false,
          runtimeColleps: id === "email_back_track" ? true : false,
        },
        () => {
          // window.scrollBy(0, -100)
          // setTimeout(() => {
          //     document.getElementById(localStorage.getItem("cID")).scrollIntoView({
          //         behavior: 'smooth',
          //        // block: "start",
          //     });
          //     setTimeout(() => {window.scrollBy(0, -80)},500)
          //     localStorage.setItem("cID", null)
          // }, 1000)
        }
      );
    }
  }

  getDomainDataFromSecondOrigin = async (reset) => {
    this.setState({
      isLoading: true,
      my_domain_name: "",
      domainInformation: "",
      allUrls: [],
      allUrlsDisable: [],
      DomainCompanyName: "",
    });
    let response = await domainServices.viewDomain(this.state.id);
    if (response && response.domain && response.domain.domain_name) {
      let data = response.domain;
      let domainInformation = "";
      let serverUrl = [];
      if (data.nameServers) {
        for (let i = 1; i <= 5; i++) {
          if (data.nameServers && data.nameServers["name_server" + i] !== "") {
            serverUrl.push(data.nameServers["name_server" + i]);
          }
        }
      }

      if (data.domainDetails && data.domainAutoDetails) {
        domainInformation = {
          country: data.domainAutoDetails.country,
          creation_date: data.domainAutoDetails.domain_creation_date,
          dnssec: "",
          domain_name: data.domain_name,
          emails: null,
          expiration_date: data.domainAutoDetails.domain_expiration_date,
          name_servers: serverUrl,
          organization: "",
          registrar: data.domainAutoDetails.registrar,
          registrar_iana: "",
          registrar_url: data.domainAutoDetails.registrar_url,
          state: data.domainAutoDetails.state,
          status: "",
          updated_date: data.domainAutoDetails.domain_updated_date,

          ssl_enabled: data.domainAutoDetails.ssl_enabled,
          ssl_expiration_date: data.domainAutoDetails.ssl_expiration_date,
          ssl_provider: data.domainAutoDetails.ssl_provider,
          ip_address: data.DomainIpAndHost.ip_address,
          server_hosted_by: data.DomainIpAndHost.server_hosted_by,
        };
      }

      this.setState(
        {
          my_domain_name: data.domain_name,
          showInformation: true,
          domainInformation: domainInformation,
          domainAllData: data,
          isLoading: false,
          dnsdetails: data.dnsDetails,
          DomainCompanyName: data.DomainCompanyName,
          DomainBlacklistStatus: data.DomainBlacklistStatus,
          DomainMalwareStatus: data.DomainMalwareStatus,
          DomainCustomMalwareStatus: data.DomainCustomMalwareStatus,
          DomainBlacklistUpdateTime:
            data && data.DomainBlacklistUpdateTime
              ? data.DomainBlacklistUpdateTime
              : "",
          DomainMalwareUpdateTime:
            data && data.DomainMalwareUpdateTime
              ? data.DomainMalwareUpdateTime
              : "",
          DomainCustomMalwareUpdateTime:
            data && data.DomainCustomMalwareUpdateTime
              ? data.DomainCustomMalwareUpdateTime
              : "",
        },
        () => {
          this.getUrls();
          //  this.getDomainSSLRecord()
        }
      );
    } else {
      this.setState({
        showInformation: false,
        isLoading: false,
        // refresh: Math.random(),
      });
    }
  };

  onClickShowGraphData = () => {
    this.setState({
      refresh: Math.random(),
    });
  };

  onClickGotoUrlDetailsPage = (id) => {
    this.props.history.push("/monitor-urls-list", { urlId: id });
  };

  getUrls = async () => {
    let d = new Date();
    var n = d.getTimezoneOffset();
    let response = await UrlStatusServices.allUrls({
      timezone: this.state.myTimezone,
      domain_name: this.state.my_domain_name,
      timediff: n,
    });

    if (!Array.isArray(response?.data)) return;

    this.setState(
      {
        allUrls: response.data,
        allUrlsDisable: response.disbale,
      },
      () => {
        this.getPosition();
        localStorage.removeItem("cID");
      }
    );
  };

  getPosition = () => {
    const id = localStorage.getItem("cID");

    if (!id) return;

    this.setState(
      {
        domainMalwareColleps: id === "malware" ? true : false,
        runtimeColleps: id === "email_back_track" ? true : false,
      },
      () => {
        const top = document.getElementById(id)?.getBoundingClientRect().top;

        if (!top) return;

        window.scrollBy({
          behavior: "smooth",
          top: top - 80,
        });
      }
    );
  };

  onSelectDomain = (e) => {
    this.setState(
      {
        id: e.value,
      },
      () => {
        localStorage.setItem("domainID", this.state.id);
        this.props.history.push("/view-domain-details", { id: this.state.id });
        this.getDomainDataFromSecondOrigin(() => {
          this.setState({
            refresh: Math.random(),
          });
        });
      }
    );
  };

  onChangeResetData = () => {
    this.getDomainDataFromSecondOrigin();
    this.onClickShowGraphData();
  };

  checkExpiring = (date) => {
    let comingDate = moment(date, "YYYY-MM-DD");
    let currentDate = moment();
    if (date) {
      let d = comingDate.diff(currentDate, "days");
      return d < 8 ? "red" : d < 30 ? "orange" : "";
    }
    return "";
  };

  refresh = () => {
    this.getDomainDataFromSecondOrigin();
    this.onClickShowGraphData();
    this.setState({
      runtimeColleps: false,
      domainMalwareColleps: false,
      domaincustomMalwareColleps: false,
    });
  };

  authHandler = (err, data) => {
    if (data) {
      // props.twitterData(data)
    }
  };

  render() {
    const { allUrls } = this.state;
    return (
      <div className="container">
        <Header
          history={this.props.history}
          {...this.props}
          callRefresh={this.refreshData}
        ></Header>
        {/* <PageLoader isLoading={this.state.isLoading}></PageLoader> */}
        <div className="serverDetailsSec justify-content-between">
          <div className="select_startdatetime">
            <div className="row align-items-center domainPage">
              <DomainListing
                domain={this.state.my_domain_name}
                history={this.props.history}
                onSelectDomain={(e) => this.onSelectDomain(e)}
                id={this.state.id.toString()}
                onChangeResetData={() => this.onChangeResetData()}
              ></DomainListing>
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip>Refresh</Tooltip>}
              >
                <Button
                  className="iconBtn addIconBtn ml-0 mr-2"
                  onClick={() => this.refresh()}
                >
                  <MdRefresh />
                </Button>
              </OverlayTrigger>
              <Notes id={this.state.id} type="domain"></Notes>
              <ShareDomain
                className="mr-1 actionIcon"
                allData={{
                  shared: this.state.domainAllData.shared,
                  domain_name: this.state.my_domain_name,
                  id: this.state.id,
                }}
                placement="bottom"
              ></ShareDomain>
            </div>
          </div>
        </div>

        <div className="card  overflow-inherit">
          <div className="card-header">
            <div className="d-flex">
              <h3 className="mr-2">Domain Details</h3>
              <span className="bottom tsize-400 tooltip ">
                <AiOutlineInfoCircle className="infoIcon" />
                <span className="tooltiptext tooltip-top">
                  <div className="row">
                    <div className="col-md-12">
                      <p>
                        The section lists all the vital details about the domain
                        which includes where you purchased domain from, when
                        does it expire, where DNS server is hosted, server
                        hosting details and SSL details and its expiry time
                      </p>
                    </div>
                  </div>
                </span>
              </span>

              {/* <div className="sDetailsCol pl-5">
                            <label>UUId:</label>
                            <strong> </strong>
                        </div> */}
            </div>
            {/* <div className="d-flex">
                        <div className="sDetailsCol pl-5">
                            <label>OS:</label>
                            <strong> </strong>
                        </div>
                    </div> */}
          </div>
          <div className="serverDetails">
            <div className="domainDetailsInfo">
              <div className="chart-loader">
                {" "}
                <Loader loader={this.state.isLoading}></Loader>
              </div>
              <div className="row mt-1">
                <div className="col-md-4">
                  <div className="sDetailsCol">
                    <label>
                      Domain Name:
                      {/* <span className="bottom tsize-400 tooltip ">
                                        <AiOutlineInfoCircle className="infoIcon" />
                                        <span className="tooltiptext tooltip-top">
                                        <p>Domain Details info</p>
                                        </span>
                                    </span> */}
                    </label>{" "}
                    <strong>{this.state.my_domain_name}</strong>
                  </div>

                  <div className="sDetailsCol">
                    <label>Domain Registrar:</label>{" "}
                    <strong>
                      {this.state.domainInformation &&
                        this.state.domainInformation.registrar}
                    </strong>
                  </div>
                  <div className="sDetailsCol">
                    <label>Domain Expiry Date:</label>{" "}
                    <strong
                      style={{
                        background: this.checkExpiring(
                          this.state.domainInformation &&
                          this.state.domainInformation.expiration_date
                        ),
                      }}
                    >
                      {this.state.domainInformation &&
                        this.state.domainInformation.expiration_date}
                    </strong>
                  </div>
                  <div className="sDetailsCol">
                    <label>Name Servers:</label>{" "}
                    <strong>
                      {this.state.domainInformation
                        ? Array.isArray(
                          this.state.domainInformation.name_servers
                        )
                          ? this.state.domainInformation.name_servers.join(", ")
                          : this.state.domainInformation.name_servers
                        : ""}
                    </strong>
                  </div>
                  <div className="sDetailsCol">
                    <label>DNS Hosted With:</label>{" "}
                    <strong>
                      {this.state.domainInformation &&
                        this.state.domainInformation.server_hosted_by}
                    </strong>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="sDetailsCol">
                    <label>Server Ip:</label>{" "}
                    <strong>
                      {this.state.domainInformation &&
                        this.state.domainInformation.ip_address &&
                        this.state.domainInformation.ip_address.replace(
                          /,/g,
                          ", "
                        )}
                    </strong>
                  </div>
                  <div className="sDetailsCol">
                    <label>Server Hosted With:</label>{" "}
                    <strong>{this.state.DomainCompanyName}</strong>
                  </div>
                  <div className="sDetailsCol">
                    <label>SSL:</label>{" "}
                    <strong>
                      {this.state.domainInformation &&
                        parseInt(this.state.domainInformation.ssl_enabled) === 1
                        ? "Enabled"
                        : parseInt(this.state.domainInformation.ssl_enabled) ===
                          0
                          ? "Disabled"
                          : ""}
                    </strong>
                  </div>
                  <div className="sDetailsCol">
                    <label>SSL Provided By:</label>{" "}
                    <strong>
                      {this.state.domainInformation &&
                        this.state.domainInformation.ssl_provider}
                    </strong>
                  </div>
                  <div className="sDetailsCol">
                    <label>SSL Expiry Date:</label>{" "}
                    <strong
                      style={{
                        background: this.checkExpiring(
                          this.state.domainInformation &&
                          this.state.domainInformation.ssl_expiration_date
                        ),
                      }}
                    >
                      {this.state.domainInformation &&
                        this.state.domainInformation.ssl_expiration_date}
                    </strong>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="notificationCol">
                    <Notifications
                      id={this.state.id}
                      mytimezone={this.state.myTimezone}
                      isRefresh={this.state.isRefresh}
                      isDomainPage="domain"
                    ></Notifications>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card  overflow-inherit" id="monitor_url">
          <div className="card-header">
            <div className="d-flex ">
              <h3 className="mr-2">Monitor Urls </h3>
              <span className="bottom tsize-400 tooltip ">
                <AiOutlineInfoCircle className="infoIcon" />
                <span className="tooltiptext tooltip-top">
                  <div className="row">
                    <div className="col-md-12">
                      <p>
                        This section displays all the urls related to this
                        domain that are added to monitor list. It displays
                        latest data for last 30 mins as well has up time in last
                        24 hours
                      </p>
                    </div>
                  </div>
                </span>
              </span>

              <AddEditUrlMonitorPopup
                callback={() => this.getUrls()}
                domainDetails={true}
              ></AddEditUrlMonitorPopup>
            </div>
          </div>

          <div className="table-responsive">
            <table className="table table-bordered mb-0">
              <thead>
                <tr className="nowrap_th">
                  <th scope="col" style={{ width: "5%" }}>
                    S.No.
                  </th>
                  {/* <th scope="col" style={{ minWidth: "500px", maxWidth: "500px" }}>
                                    <span className="d-flex justify-content-between">
                                        <span>Monitor URL List</span>
                                        <span>URL UP / Down in Last 24 hours</span>
                                    </span>
                                </th> */}
                  <th scope="col" style={{ width: "22%" }}>
                    Monitor URL List
                  </th>
                  <th scope="col" style={{ width: "30%" }}>
                    URL UP / Down in Last 24 hours
                  </th>
                  <th scope="col" style={{ width: "34%" }}>
                    URL response rate (Last 30 mins)
                  </th>
                  <th scope="col" style={{ width: "8%" }}>
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {Array.isArray(allUrls) &&
                  allUrls.map((data, index) => {
                    return (
                      <>
                        <tr key={data.id}>
                          <td>{index + 1}</td>
                          <td>
                            <div className="domainDetailsList dashboardTd">
                              <MonitorUrlListing
                                hideChart={true}
                                allurlLoader={this.state.allurlLoader}
                                hour_status={data["24_hour_status"]}
                                monitorUrlname={data.URL}
                                allData={data}
                                onClick={() =>
                                  this.onClickGotoUrlDetailsPage(data.id)
                                }
                                viewDomain={true}
                              />
                            </div>
                          </td>
                          <td>
                            <div className="chart-loader">
                              {" "}
                              <Loader loader={this.state.isLoading}></Loader>
                            </div>
                            <div className="domainDetailsList">
                              <MonitorUrlListing
                                hideInfo={true}
                                allurlLoader={this.state.allurlLoader}
                                hour_status={data["24_hour_status"]}
                                monitorUrlname={data.URL}
                                allData={data}
                                onClick={() =>
                                  this.onClickGotoUrlDetailsPage(data.id)
                                }
                                viewDomain={true}
                              />
                            </div>
                          </td>
                          <td>
                            <MoniterUrlDetailsChart
                              onRefresh={this.state.onRefresh}
                              urlId={data.id}
                            ></MoniterUrlDetailsChart>
                          </td>
                          <td>
                            {" "}
                            <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Tooltip className="tooltip.tsize-900">
                                  View URL response details
                                </Tooltip>
                              }
                            >
                              <Button
                                style={{ fontSize: "18px" }}
                                className="iconBtn"
                                onClick={() =>
                                  this.onClickGotoUrlDetailsPage(data.id)
                                }
                              >
                                <MdRemoveRedEye />
                              </Button>
                            </OverlayTrigger>
                          </td>
                        </tr>
                      </>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>

        <div className="row" id="monitor_server">
          <div className="col-md-12">
            <div className="card  overflow-inherit">
              <div className="card-header">
                <div className="d-flex ">
                  <h3 className="mr-2">Server Records</h3>
                  <span className="bottom tsize-400 tooltip ">
                    <AiOutlineInfoCircle className="infoIcon" />
                    <span className="tooltiptext tooltip-top">
                      <div className="row">
                        <div className="col-md-12">
                          <p>
                            This section displays the server associated with
                            this domain / sub domains. It shows server CPU
                            response in last 30 mins.
                          </p>
                        </div>
                      </div>
                    </span>
                  </span>
                  <ConnectServer
                    domain={this.state.my_domain_name}
                    onChangeResetData={() =>
                      this.setState({ recordReset: Math.random() })
                    }
                  ></ConnectServer>
                </div>
              </div>
              <div className="chart-loader">
                {" "}
                <Loader loader={this.state.isLoading}></Loader>
              </div>
              <ServerRecords
                onRefresh={this.state.onRefresh}
                mytimezone={this.state.myTimezone}
                domain={this.state.my_domain_name}
                refresh={this.state.refresh}
                history={this.props.history}
                recordReset={this.state.recordReset}
              />
            </div>
          </div>
        </div>

        <div className="card  overflow-inherit" id="monitor_seo">
          <div className="socialChartBlock">
            <div className="row">
              <div className="col-md-12 domainmodal">
                <div className="card-header justify-content-start">
                  <h3 className="mr-2">SEO Monitoring</h3>
                  <span className="bottom tsize-400 tooltip ">
                    <AiOutlineInfoCircle className="infoIcon" />
                    <span className="tooltiptext tooltip-top">
                      <div className="row">
                        <div className="col-md-12">
                          <p>
                            This section shows the rank of your domain vis-a-vis
                            keyword that you have entered. We show rank only if
                            the domain appears in top 100 search results for
                            that keyword. Since the rank on Google does not vary
                            quickly, we tract the data once every week.
                          </p>
                        </div>
                      </div>
                    </span>
                  </span>
                  <SeoMonitoringPopup
                    domain={this.state.my_domain_name}
                    data={{
                      domain: this.state.my_domain_name,
                      id: this.state.id,
                    }}
                    history={this.props.history}
                    show={this.state.showAddSeoModal}
                    setShow={this.setShowAddSeoModal}
                  />
                </div>
                <div className="chartInnerDiv">
                  <SeoMonitorChartOperation
                    title={""}
                    tooltip={true}
                    toolbox={true}
                    loader={this.state.loader}
                    refresh={this.state.refresh}
                    myTimezone={this.state.myTimezone}
                    start_datetime={this.state.start_datetime}
                    end_datetime={this.state.end_datetime}
                    domain_name={this.state.my_domain_name}
                    ShowOnBlank=" Not Listed in Top 100 Results"
                    setShow={this.setShowAddSeoModal}
                    onRefresh={this.state.onRefresh}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card  overflow-inherit" id="monitor_social_media">
          <div className="socialChartBlock">
            <div className="row">
              <div className="col-md-12 domainmodal">
                <div className="card-header justify-content-start">
                  <h3 className="mr-2">Lighthouse Performance</h3>
                  <span className="bottom tsize-400 tooltip ">
                    <BsInfoCircleFill className="infoIcon" size={15} />
                    <span className="tooltiptext tooltip-top">
                      <div className="row">
                        <div className="col-md-12">
                          <p>
                            This section displays the domain performance based
                            on Lighthouse API. This data is updated once every
                            week
                          </p>
                        </div>
                      </div>
                    </span>
                  </span>
                </div>
                <div className="chartInnerDiv">
                  <LightHouseChartOperation
                    title={""}
                    tooltip={true}
                    toolbox={true}
                    loader={this.state.loader}
                    refresh={this.state.refresh}
                    myTimezone={this.state.myTimezone}
                    start_datetime={this.state.start_datetime}
                    end_datetime={this.state.end_datetime}
                    domain_name={this.state.my_domain_name}
                    // setShow={this.setShowAddFollowerModal}
                    onRefresh={this.state.onRefresh}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card  overflow-inherit">
          <div className="socialChartBlock">
            <div className="row">
              <div className="col-md-12 domainmodal">
                <div className="card-header justify-content-start">
                  <h3 className="mr-2">Social Media Follower</h3>
                  <span className="bottom tsize-400 tooltip ">
                    <AiOutlineInfoCircle className="infoIcon" />
                    <span className="tooltiptext tooltip-top">
                      <div className="row">
                        <div className="col-md-12">
                          <p>
                            This section displays the total number of followers
                            on your social media pages. We track this data on
                            daily basis.
                          </p>
                        </div>
                      </div>
                    </span>
                  </span>
                  <SocialMediaPopup
                    data={{
                      domain: this.state.my_domain_name,
                      id: this.state.id,
                    }}
                    history={this.props.history}
                    show={this.state.showAddFollowerModal}
                    setShow={this.setShowAddFollowerModal}
                  ></SocialMediaPopup>
                </div>
                <div className="chartInnerDiv">
                  <SocialMediaChartOperation
                    metakey="social-follower-chart"
                    title={""}
                    tooltip={true}
                    toolbox={true}
                    loader={this.state.loader}
                    refresh={this.state.refresh}
                    myTimezone={this.state.myTimezone}
                    start_datetime={this.state.start_datetime}
                    end_datetime={this.state.end_datetime}
                    domain_name={this.state.my_domain_name}
                    setShow={this.setShowAddFollowerModal}
                    onRefresh={this.state.onRefresh}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card  overflow-inherit">
          <div className="socialChartBlock">
            <div className="row">
              <div className="col-md-12 domainmodal">
                <div className="card-header justify-content-start">
                  <h3 className="mr-2">Social Media Post</h3>
                  <span className="bottom tsize-400 tooltip ">
                    <AiOutlineInfoCircle className="infoIcon" />
                    <span className="tooltiptext tooltip-top">
                      <div className="row">
                        <div className="col-md-12">
                          <p>
                            This section displays the total number of posts on
                            your social media pages. We track this data on daily
                            basis.
                          </p>
                        </div>
                      </div>
                    </span>
                  </span>
                  {/* <SocialMediaPopup data={{ domain: this.state.my_domain_name, id: this.state.id }} history={this.props.history} show={this.state.showAddPostModal} setShow={this.setShowAddPostModal} >
                                </SocialMediaPopup> */}
                  <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip>Add / Manage Social Media pages</Tooltip>}
                  >
                    <Button
                      className="iconBtn"
                      style={{ fontSize: "18px" }}
                      onClick={this.setShowAddFollowerModal}
                    >
                      <MdAdd />
                    </Button>
                  </OverlayTrigger>
                </div>
                <div className="chartInnerDiv">
                  <SocialMediaChartOperation
                    metakey="social-post-chart"
                    title={""}
                    tooltip={true}
                    toolbox={true}
                    loader={this.state.loader}
                    refresh={this.state.refresh}
                    myTimezone={this.state.myTimezone}
                    start_datetime={this.state.start_datetime}
                    end_datetime={this.state.end_datetime}
                    domain_name={this.state.my_domain_name}
                    // setShow={this.setShowAddPostModal}
                    setShow={this.setShowAddFollowerModal}
                    onRefresh={this.state.onRefresh}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row" id="email_back_track">
          <div className="col-md-12">
            <div className="card overflow-inherit">
              <div
                className="card-header domainEmail flexDisableMob"
                style={{ justifyContent: "flex-start" }}
              >
                <div className="d-flex">
                  <h3 className="mr-2">Email Blacklist</h3>
                  <span className="bottom tsize-400 tooltip">
                    <AiOutlineInfoCircle className="infoIcon" />
                    <span className="tooltiptext tooltip-top">
                      <div className="row">
                        <div className="col-md-12">
                          <p>
                            This section displays if your domain or related IP
                            is added in any blacklist. In case it is listed, you
                            can view details for the reason.
                          </p>
                        </div>
                      </div>
                    </span>
                  </span>
                </div>
                {this.state.DomainBlacklistStatus !== "" &&
                  this.state.DomainBlacklistStatus !== null &&
                  this.state.DomainBlacklistStatus !== 2 && (
                    <div className="ml-3 headerDetailSml">
                      Domain is
                      {this.state.DomainBlacklistStatus === 0 ? (
                        <span className="listedBg listedBgBack ">
                          Not Listed
                        </span>
                      ) : this.state.DomainBlacklistStatus === 1 ? (
                        <span className="notlistedBgBack listedBg">Listed</span>
                      ) : (
                        ""
                      )}
                      in blacklist.&nbsp;&nbsp;&nbsp;Last test was run at{" "}
                      {this.state.DomainBlacklistUpdateTime}
                    </div>
                  )}
                <div className="selectMtTimezone b-drop-down">
                  <span className="myTimezone">
                    {this.state.runtimeColleps ? (
                      <span
                        onClick={() =>
                          this.setState({
                            runtimeColleps: !this.state.runtimeColleps,
                          })
                        }
                      >
                        {" "}
                        <span className="hiddenMob">Click To</span> Close Details{" "}
                        <IoIosArrowUp size={20}></IoIosArrowUp>
                      </span>
                    ) : (
                      <span
                        onClick={() =>
                          this.setState({
                            runtimeColleps: !this.state.runtimeColleps,
                          })
                        }
                      >
                        {" "}
                        <span className="hiddenMob">Click To</span> View Details{" "}
                        <IoIosArrowDown size={20}></IoIosArrowDown>{" "}
                      </span>
                    )}
                  </span>
                </div>
              </div>

              {this.state.runtimeColleps ? (
                <EmailBlackList domain={this.state.my_domain_name} />
              ) : (
                <span
                  className="clickToviewDetails"
                  onClick={() =>
                    this.setState({
                      runtimeColleps: !this.state.runtimeColleps,
                    })
                  }
                >
                  {" "}
                  Click To View Details{" "}
                  <IoIosArrowDown size={20}></IoIosArrowDown>{" "}
                </span>
              )}
            </div>
          </div>
        </div>

        <div className="row" id="malware">
          <div className="col-md-12">
            <div className="card overflow-inherit">
              <div
                className="card-header domainEmail flexDisableMob"
                style={{ justifyContent: "flex-start" }}
              >
                <div className="d-flex">
                  <h3 className="mr-2">Domain Malware</h3>
                  <span className="bottom tsize-400 tooltip ">
                    <AiOutlineInfoCircle className="infoIcon" />
                    <span className="tooltiptext tooltip-top">
                      <div className="row">
                        <div className="col-md-12">
                          <p>
                            We crawl the website to get the sitemap (txt or xml)
                            to get all the important URLs for the domain and
                            then pass it on to google to check if the url has
                            malware. This check run done once every day.
                          </p>
                        </div>
                      </div>
                    </span>
                  </span>
                </div>
                {this.state.DomainMalwareStatus !== "" &&
                  this.state.DomainMalwareStatus !== null &&
                  this.state.DomainMalwareStatus !== 2 && (
                    <div className="ml-3 headerDetailSml">
                      URL is
                      {this.state.DomainMalwareStatus === 0 ? (
                        <span className="listedBg listedBgBack">
                          Not Listed
                        </span>
                      ) : this.state.DomainMalwareStatus === 1 ? (
                        <span className="notlistedBgBack listedBg">Listed</span>
                      ) : (
                        ""
                      )}{" "}
                      in google's Malware database.&nbsp;&nbsp;&nbsp;Last test
                      was run at {this.state.DomainMalwareUpdateTime}
                    </div>
                  )}
                <div className="selectMtTimezone b-drop-down">
                  <span className="myTimezone">
                    {this.state.domainMalwareColleps ? (
                      <span
                        onClick={() =>
                          this.setState({
                            domainMalwareColleps:
                              !this.state.domainMalwareColleps,
                          })
                        }
                      >
                        {" "}
                        <span className="hiddenMob">Click To</span> Close Details{" "}
                        <IoIosArrowUp size={20}></IoIosArrowUp>
                      </span>
                    ) : (
                      <span
                        onClick={() =>
                          this.setState({
                            domainMalwareColleps:
                              !this.state.domainMalwareColleps,
                          })
                        }
                      >
                        {" "}
                        <span className="hiddenMob">Click To</span> View Details{" "}
                        <IoIosArrowDown size={20}></IoIosArrowDown>{" "}
                      </span>
                    )}
                  </span>
                </div>
              </div>
              {this.state.domainMalwareColleps ? (
                <EmailBlackList
                  isdomainMalware={true}
                  domain={this.state.my_domain_name}
                />
              ) : (
                <span
                  className="clickToviewDetails"
                  onClick={() =>
                    this.setState({
                      domainMalwareColleps: !this.state.domainMalwareColleps,
                    })
                  }
                >
                  {" "}
                  Click To View Details{" "}
                  <IoIosArrowDown size={20}></IoIosArrowDown>{" "}
                </span>
              )}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="card overflow-inherit">
              <div
                className="card-header domainEmail flexDisableMob"
                style={{ justifyContent: "flex-start" }}
              >
                <div className="d-flex">
                  <h3 className="mr-2"> Domain Custom Malware</h3>
                  <span className="bottom tsize-400 tooltip ">
                    <AiOutlineInfoCircle className="infoIcon" />
                    <span className="tooltiptext tooltip-top">
                      <div className="row">
                        <div className="col-md-12">
                          <p>
                            We crawl the website to get the sitemap (txt or xml)
                            to get all the important URLs for the domain and
                            then pass it on to google to check if the url has
                            malware. This check run done once every day.
                          </p>
                        </div>
                      </div>
                    </span>
                  </span>
                </div>
                {this.state.DomainMalwareStatus !== "" &&
                  this.state.DomainMalwareStatus !== null &&
                  this.state.DomainMalwareStatus !== 2 && (
                    <div className="ml-3 headerDetailSml">
                      URL is
                      {this.state.DomainMalwareStatus === 0 ? (
                        <span className="listedBg listedBgBack">
                          Not Listed
                        </span>
                      ) : this.state.DomainMalwareStatus === 1 ? (
                        <span className="notlistedBgBack listedBg">Listed</span>
                      ) : (
                        ""
                      )}{" "}
                      in google's Malware database.&nbsp;&nbsp;&nbsp;Last test
                      was run at {this.state.DomainCustomMalwareUpdateTime}
                    </div>
                  )}
                <div className="selectMtTimezone b-drop-down">
                  <span className="myTimezone">
                    {this.state.domaincustomMalwareColleps ? (
                      <span
                        onClick={() =>
                          this.setState({
                            domaincustomMalwareColleps:
                              !this.state.domaincustomMalwareColleps,
                          })
                        }
                      >
                        {" "}
                        <span className="hiddenMob">Click To</span> Close Details{" "}
                        <IoIosArrowUp size={20}></IoIosArrowUp>
                      </span>
                    ) : (
                      <span
                        onClick={() =>
                          this.setState({
                            domaincustomMalwareColleps:
                              !this.state.domaincustomMalwareColleps,
                          })
                        }
                      >
                        {" "}
                        <span className="hiddenMob">Click To</span> View Details{" "}
                        <IoIosArrowDown size={20}></IoIosArrowDown>{" "}
                      </span>
                    )}
                  </span>
                </div>
              </div>
              {this.state.domaincustomMalwareColleps ? (
                <CustomMalware domain={this.state.my_domain_name} />
              ) : (
                <span
                  className="clickToviewDetails"
                  onClick={() =>
                    this.setState({
                      domaincustomMalwareColleps:
                        !this.state.domaincustomMalwareColleps,
                    })
                  }
                >
                  {" "}
                  Click To View Details{" "}
                  <IoIosArrowDown size={20}></IoIosArrowDown>{" "}
                </span>
              )}
            </div>
          </div>
        </div>

        <div className="card  overflow-inherit">
          <div className="card-header">
            <div className="d-flex">
              <h3 className="mr-2">DNS Details</h3>
              <span className="bottom tsize-400 tooltip ">
                <AiOutlineInfoCircle className="infoIcon" />
                <span className="tooltiptext tooltip-top">
                  <div className="row">
                    <div className="col-md-12">
                      <p>
                        This section lists the DNS details for the domain. This
                        data is updated once everyday.
                      </p>
                    </div>
                  </div>
                </span>
              </span>
            </div>
          </div>
          <div className="serverDetails">
            <div className="mt-3">
              <div className="row mt-1">
                <div className="col-2">
                  <div className="sDetailsCol">
                    <label>Type</label>
                  </div>
                </div>
                <div className="col-4">
                  <div className="sDetailsCol">
                    <label>Name</label>
                  </div>
                </div>
                <div className="col-6">
                  <div className="sDetailsCol">
                    <label>Content</label>
                  </div>
                </div>
              </div>

              {Array.isArray(this.state.dnsdetails) &&
                this.state.dnsdetails.map((dns) => {
                  return (
                    <div className="row mt-1 dnsDetailsList">
                      <div className="col-2">
                        <div className="sDetailsCol">
                          {" "}
                          <strong>{dns.type}</strong>
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="sDetailsCol">
                          {" "}
                          <strong>{dns.name}</strong>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="sDetailsCol">
                          {" "}
                          <strong>{dns.content}</strong>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ViewDomainDetails;
