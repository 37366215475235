import React, {
    useEffect,
    useState
} from 'react'
import LineStackChart from '../Charts/LineStackChart'
import { Loader } from '../../_components/CommonComponents/index'
const MonitorUrlChartOperation = ({ ...props }) => {
    const [xAxis, setXAxis] = useState(false)
    const [yAxis, setyAxis] = useState(false)
    const [series, setSeries] = useState(false)
    const [setUnSet, setUnSetUI] = useState(true)
    const [dataSizeType, setDataSizeType] = useState("ms")
    const [loader, setLoader] = useState(false)
    const [allobj, setAllObj] = useState(true)
    useEffect(() => {
        if (props.urlDetails)
            getGraphDataFromServer()
    }, [props.urlDetails, props.refresh])

    const getGraphDataFromServer = async () => {
        setSeries([{
            data: [1],
            type: 'line'
        }])
        setUnSetUI(false)
        setXAxis({
            type: 'category',
            data: ["3:00", "4:00"]
        })
        setyAxis({
            type: 'value',
            axisLabel: {
                formatter: `{value} ms`
            },
        })
        setSeries([{
            data: [1],
            type: 'line'
        }])

        setLoader(true)
        let seriesList = []

        if (props.urlDetails && props.urlDetails.timestamp) {
            let lines = Object.keys(props.urlDetails)
            Array.isArray(lines) && lines.map((line) => {
                if (line !== "timestamp") {
                //  let nArray= []
                //     Array.isArray(props.urlDetails[line]) &&props.urlDetails[line].map((val,index)=>{
                //         if(val){
                //             nArray.push(parseFloat(val)*1000)
                //         }else{
                //             nArray.push(val)
                //         }
                //     })

                    seriesList.push({
                        name: line.toString(),
                        data: props.urlDetails[line],
                        type: 'line'
                        // smooth: true,
                    })
                }
            })

            setTimeout(() => {
                setXAxis({
                    type: 'category',
                    data: props.urlDetails.timestamp
                })

                setyAxis({
                    type: 'value',
                    axisLabel: {
                        formatter: `{value} ms`
                    },
                })

                setSeries(seriesList)
                setLoader(false)
                setUnSetUI(true)
            }, 100)

        } else {
            setTimeout(() => {
                setSeries([])
                setUnSetUI(true)
                setLoader(false)
            }, 50)

        }

    }
    return <>
        <div className="chart-loader"> <Loader loader={loader || props.loader}></Loader></div>
        {setUnSet && <LineStackChart
            xAxis={xAxis}
            yAxis={yAxis}
            series={series}
            dataSizeType={'ms'}
            allobj={allobj}
            {...props}
           // notShow={true}
        ></LineStackChart>}</>
}
export default MonitorUrlChartOperation