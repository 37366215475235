import { RestMethodSecondOrigin } from '../../../_helpers/ApiConfigTwo/RestMethod'
import FilterResponse from '../../../_helpers/FilterResponse'
import {isAuthorized} from '../../../_helpers/Common/Common'
export const domainDetailsServices = {
    getServerListByDomain,
    addServerListToDomain,
    getAllDomainServer,
    getBlacklistCall,
    ShareDomain,
    addNewDomain,
    domainSharedWithList,
    domainServerSharedWithRemoveFromOther,
    getMalwarelistCall,
    getCustomMalwarelistCall
}

async function getServerListByDomain(data) {
    try {
        const response = await RestMethodSecondOrigin.POST('/domain-servers/get-domain-server',data);
        const api_response = FilterResponse(response);  
        return api_response;
    }
    catch (error) {
        isAuthorized(error)
        return null;
    }
}


async function addServerListToDomain(data) {
    try {
        const response = await RestMethodSecondOrigin.POST('/domain-servers/add-domain-server',data);
        const api_response = FilterResponse(response);  
        return api_response;
    }
    catch (error) {
        isAuthorized(error)
        return null;
    }
}

async function getAllDomainServer(data) {
    try {
        const response = await RestMethodSecondOrigin.POST('/domain-servers/all-domain-server',data);
        const api_response = FilterResponse(response);  
        return api_response;
    }
    catch (error) {
        isAuthorized(error)
        return null;
    }
}

async function getBlacklistCall(data) {
    try {
        const response = await RestMethodSecondOrigin.POST('/domain-blacklist/black-list',data);
        const api_response = FilterResponse(response);  
        return api_response;
    }
    catch (error) {
        isAuthorized(error)
        return null;
    }
}

async function getMalwarelistCall(data) {
    try {
        const response = await RestMethodSecondOrigin.POST('/domain-blacklist/malware',data);
        const api_response = FilterResponse(response);  
        return api_response;
    }
    catch (error) {
        isAuthorized(error)
        return null;
    }
}


async function ShareDomain(data,id) {
    try {
        const response = await RestMethodSecondOrigin.PUT('/domain-share/share-domain?id='+id,data);
        const api_response = FilterResponse(response);  
        return api_response;
    }
    catch (error) {
        isAuthorized(error)
        return null;
    }
}

async function addNewDomain(data) {
    try {
        const response = await RestMethodSecondOrigin.POST('/domain/new-domain',data);
        const api_response = FilterResponse(response);  
        return api_response;
    }
    catch (error) {
        isAuthorized(error)
        return null;
    }
}

async function domainSharedWithList(data) {
    try {
        const response = await RestMethodSecondOrigin.POST('/domain-share/domain-shared-with',data);
        const api_response = FilterResponse(response);  
        return api_response;
    }
    catch (error) {
        isAuthorized(error)
        return null;
    }
}

async function domainServerSharedWithRemoveFromOther(data) {
    try {
        const response = await RestMethodSecondOrigin.POST('/domain-share/remove-shared-domain',data);
        const api_response = FilterResponse(response);  
        return api_response;
    }
    catch (error) {
        isAuthorized(error)
        return null;
    }
}


async function getCustomMalwarelistCall(data) {
    try {
        const response = await RestMethodSecondOrigin.POST('/domain-blacklist/custom-malware',data);
        const api_response = FilterResponse(response);  
        return api_response;
    }
    catch (error) {
        isAuthorized(error)
        return null;
    }
}