import React, { useState } from "react";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";
import { Validation } from "../../_helpers/validation/Validation";
import { Constants } from "./Constants";
import { loginService } from "../../_services/servicesWithSecondOrigin/Login/login";
import toastr from "toastr";
import PageLader from "../../_components/CommonComponents/PageLoader/Loader";
import { Error, Select } from "../CommonComponents";
import { URL_CONFIG } from "../../_constants/Config/URL_CONFIG";
import { Link } from "react-router-dom";
const SignUpForm = ({ ...props }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [first_name, setFirstname] = useState("");
  const [company, setCompany] = useState("");
  const [error, setError] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [data_center, setDataCenter] = useState("US");
  const [checked, setTermsCheck] = useState(false);

  const login = async (e) => {
    e.preventDefault();
    setLoading(true);
    let flag = 0;
    const field = Constants().loginRule;
    let errobj = Validation(
      {
        email: email,
        password: password,
        first_name: first_name,
        company: company,
        data_center: data_center,
      },
      field
    );
    setError(errobj);
    Object.keys(errobj).map((index) => {
      if (errobj[index] !== "") {
        flag = 1;
      }
    });

    console.log("ssssss", checked, errobj);
    if (flag === 0 && checked) {
      let data = {
        first_name: first_name,
        email: email,
        password: password,
        company: company,
        data_center: data_center,
      };

      let response = await loginService.signUp(data);
      if (response && response.message) {
        toastr.info(response.message);
        props.history.push("/login");
      }
    }
    setLoading(false);
  };

  const onChangeTerms = (click) => {
    !checked &&
      click === 2 &&
      window.open(URL_CONFIG.TERMS_CONDITIONS_URL, "_blank");
    setTermsCheck(!checked);
  };

  return (
    <div>
      <PageLader isLoading={isLoading}></PageLader>

      <Form onSubmit={login}>
        <div className="form-group">
          <label>Name</label>
          <Input
            placeholder="Name"
            value={first_name}
            onChange={(e) => {
              setFirstname(e.target.value, setError({}));
            }}
          ></Input>
          <Error error={error?.first_name} />
          {/* {error && error.first_name !== "" && <p>{error.first_name}</p>} */}
        </div>

        <div className="form-group">
          <label>Company</label>
          <Input
            placeholder="Company"
            value={company}
            onChange={(e) => {
              setCompany(e.target.value, setError({}));
            }}
          ></Input>
          {/* {error && error.company !== "" && <p>{error.company}</p>} */}
          <Error error={error?.company} />
        </div>

        <div className="form-group">
          <label>Email address</label>
          <Input
            placeholder="Email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value, setError({}));
            }}
          ></Input>
          {/* {error && error.email !== "" && <p>{error.email}</p>} */}
          <Error error={error?.email} />
        </div>

        <div className="form-group">
          <label>Password</label>
          <Input
            placeholder="Password"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value, setError({}));
            }}
          ></Input>
          {/* {error && error.password !== "" && <p>{error.password}</p>} */}
          <Error error={error?.password} />
        </div>

        <div className="form-group">
          <label>Select datacenter</label>
          <Select
            selectedOption={data_center}
            setSelectedOption={(e) => setDataCenter(e.value)}
            options={[{ label: "US", value: "US" }]}
          ></Select>
          {/* {error && error.data_center !== "" && <p>{error.data_center}</p>} */}
          <Error error={error?.data_center} />
        </div>

        <FormGroup check>
          <Label check>
            <Input
              type="checkbox"
              checked={checked}
              onClick={() => onChangeTerms(1)}
            />{" "}
            Agree to{" "}
            {/* <Link to={URL_CONFIG.TERMS_CONDITIONS_URL}>
              terms and conditions
            </Link> */}
            <a
              onClick={() => onChangeTerms(2)}
              className="terms-condition-checkbox"
            >
              terms and conditions
            </a>
          </Label>
        </FormGroup>

        <div className="mt-4">
          <div className="user-button-login">
            <Button
              className={
                "btn-primary btn-block" + (!checked ? " signup-disabled" : "")
              }
            >
              Sign Up
            </Button>
          </div>
        </div>
        <div style={{ textAlign: "center", margin: "5px" }}>
          <a onClick={() => props.history.push("/login")} href="#">
            Sign In
          </a>
        </div>
      </Form>
    </div>
  );
};
export default SignUpForm;
