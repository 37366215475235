import React from 'react'
import { Input, Row, Col } from 'reactstrap';
// import Autocomplete from '../AutoCompleteInput/AutoComplete'
import { MdRemoveCircleOutline } from "react-icons/md";
const FormItem = ({ ...props }) => {

    return <Row className="mt-4">
        <Col className="col-md-10">
            <Input placeholder="keyword" value={props.object.keyword} onChange={(e) => props.onChange(e.target.value, props.index, "keyword")}></Input>
            {props.errorArray && props.errorArray[props.index] && props.errorArray[props.index].keyword !== "" && <div>{props.errorArray[props.index].keyword}</div>}
        </Col>
        {/* <Col className="col-md-5">
            <Autocomplete value={props.object.url} setValue={(e) => props.onChange(e, props.index, "url")} suggestions={props.suggestionsArray} />
            {props.errorArray && props.errorArray[props.index] && props.errorArray[props.index].url !== "" && <div>{props.errorArray[props.index].url}</div>}
        </Col> */}
        <Col className="text-right"><MdRemoveCircleOutline  className="deleteIcon actionIcon " size={30} onClick={()=>props.removeKeyword(props.index)}></MdRemoveCircleOutline></Col>
    </Row>
}
export default FormItem