import { RestMethodSecondOrigin } from '../../../_helpers/ApiConfigTwo/RestMethod'
import FilterResponse from '../../../_helpers/FilterResponse'
import toastr from 'toastr'

export const loginService = {
    login,
    signUp,
    verifyEmail,
    logout
}

async function login(data) {
    try {
        const response = await RestMethodSecondOrigin.POST('/user/login', data);
        const api_response = FilterResponse(response);
        return api_response;
    }
    catch (error) {
        if (error && error.response && error.response.data && error.response.data.Error) {
            toastr.error(error.response.data.Error)
        }
        console.log("error detected while fetching data from api");
        return null;
    }
}

async function signUp(data) {
    try {
        const response = await RestMethodSecondOrigin.POST('/champion/champion-register', data);
        const api_response = FilterResponse(response);
        return api_response;
    }
    catch (error) {
        if (error && error.response && error.response.data && error.response.data.Error) {
            toastr.error(error.response.data.Error)
        }
        console.log("error detected while fetching data from api");
        return null;
    }
}

async function verifyEmail(id, token) {
    try {
        const response = await RestMethodSecondOrigin.GET(`/champion/verify-email?id=${id}&token=${token}`);
        const api_response = FilterResponse(response);
        return api_response;
    }
    catch (error) {
        if (error && error.response && error.response.data && error.response.data.Error) {
            toastr.error(error.response.data.Error)
        }
        console.log("error detected while fetching data from api");
        return null;
    }
}

async function logout() {
    try {
        const response = await RestMethodSecondOrigin.GET(`/user/logout`);
        const api_response = FilterResponse(response);
        return api_response;
    }
    catch (error) {
        if (error && error.response && error.response.data && error.response.data.Error) {
            toastr.error(error.response.data.Error)
        }
        console.log("error detected while fetching data from api");
        return null;
    }
}

