import React from 'react'
import { Button } from 'reactstrap';
import Header from '../../_components/CommonComponents/Header/Header'
import { Validation } from '../../_helpers/validation/Validation'
import SocialMediaForm from '../../_components/SocialMediaForm/SocialMediaForm'
import { Constants } from '../../_components/SocialMediaForm/Constants'
import { socialMediaServices } from '../../_services/servicesWithSecondOrigin/index'
import toastr from 'toastr'
import { Constant } from './Constants'
import FbPage from '../../_components/SocialMediaForm/FBPageSelectModel'
import { Loader } from '../../_components/CommonComponents/index'
class SocialMedia extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            socialMediaArray: [{
                "social_media_type": "",
                "url": ""
            }],
            domain: "Domain Not Found",
            errorArray: [],
            id: "",
            update: false,
            fbPageModel: false,
            fbPageData: [],
            loader: false
        }
    }

    componentDidMount() {
        if (!this.props.popup) {
            if (this.props.location && this.props.location.state && this.props.location.state.domain) {
                this.setState({
                    domain: this.props.location.state.domain
                })
            } else {

            }
            if (this.props.location && this.props.location.state && this.props.location.state.update) {
                this.setState({
                    update: this.props.location.state.update,
                    id: this.props.location.state.id,
                    domain: this.props.location.state.domain,
                }, () => {
                    this.getAllSocialMediaURL()
                })
            }
        } else {
            this.setState({
                update: true,
                id: this.props.data.id,
                domain: this.props.data.domain,
            }, () => {
                this.getAllSocialMediaURL()
            })
        }
    }

    componentDidUpdate(prev) {
        if (prev.data.domain !== this.props.data.domain) {
            if (!this.props.popup) {
                if (this.props.location && this.props.location.state && this.props.location.state.domain) {
                    this.setState({
                        domain: this.props.location.state.domain
                    })
                } else {

                }
                if (this.props.location && this.props.location.state && this.props.location.state.update) {
                    this.setState({
                        update: this.props.location.state.update,
                        id: this.props.location.state.id,
                        domain: this.props.location.state.domain,
                    }, () => {
                        this.getAllSocialMediaURL()
                    })
                }
            } else {
                this.setState({
                    update: true,
                    id: this.props.data.id,
                    domain: this.props.data.domain,
                }, () => {
                    this.getAllSocialMediaURL()
                })
            }
        }
    }

    getAllSocialMediaURL = async () => {
        let data = {
            "domain_name": this.state.domain
        }
        let response = await socialMediaServices.getSocialMediaData(data)
        if (response && response.data && Array.isArray(response.data)) {
            this.setState({
                socialMediaArray: response.data,
            })
        }
    }

    addSocailMedia = () => {
        let socialMediaArray = [...this.state.socialMediaArray]
        socialMediaArray.push({
            "social_media_type": "",
            "url": ""
        })
        this.setState({ socialMediaArray: socialMediaArray })
    }


    onSubmit = async () => {
        let socialMediaArray = this.state.socialMediaArray.slice()
        // if (socialMediaArray.length === 0) {
        //     toastr.warning("Please Add atleast One Social Media URL")
        //     return false
        // }
        let flag = 0
        let errorArray = []
        const field = Constant().SocialMediaRule;
        Array.isArray(socialMediaArray) && socialMediaArray.map((obj) => {
            let errobj = Validation(obj, field);
            Object.keys(errobj).map(index => {
                if (errobj[index] !== "") {
                    flag = 1;
                }
            });
            errorArray = errorArray.concat(errobj)
        })

        this.setState({
            errorArray: errorArray,
        })

        if (flag === 0) {
            let data = {
                "domain_name": this.state.domain,
                "social": this.state.socialMediaArray
            }
            let response = ""
            if (this.state.update) {
                response = await socialMediaServices.updateSocialMediaData(data)
            } else {
                response = await socialMediaServices.addSocialMediaData(data)
            }

            if (response) {
                if (response && response.message) {
                    toastr.success(response.message)
                }
                if (this.state.update) {
                    if (this.props.popup) {
                        this.props.callback()
                    } else {
                        this.props.history.push('/update-monitor-urls', { domain: this.state.domain, id: this.state.id, update: true })
                    }
                } else {
                    this.props.history.push('/monitor-urls', { domain: this.state.domain })
                }
            }
        }
    }

    onChange = (value, index, name) => {
        let socialMediaArray = [...this.state.socialMediaArray]
        socialMediaArray[index][name] = value
        if (name === "url") {
            socialMediaArray[index]["social_media_type"] = ""
            Array.isArray(Constants.socialMedia) && Constants.socialMedia.map((obj) => {
                if (value.includes(obj.value)) {
                    socialMediaArray[index]["social_media_type"] = obj.value
                }
            })
        }

        this.setState({
            socialMediaArray: socialMediaArray,
            errorArray: []
        })
    }


    addArrayInMediaArray = (data) => {
        let socialMediaArray = [...this.state.socialMediaArray]
        Array.isArray(data) && data.map((obj) => {
            if (socialMediaArray.some(objj => objj.PageId === obj.id)) {
                socialMediaArray.map((nextobj) => {
                    if (nextobj.PageId === obj.id) {
                        nextobj.accessToken = obj.access_token
                    }
                })
            } else {
                socialMediaArray.push({
                    "social_media_type": "facebook",
                    "url": 'https://www.facebook.com/' + obj.name.replace(/ /g, '-') + '-' + obj.id,
                    'accessToken': obj.access_token,
                    'PageId': obj.id
                })
            }
        })
        this.setState({ socialMediaArray: socialMediaArray })
    }

    removeSocialMediaURL = (index) => {
        let socialMediaArray = [...this.state.socialMediaArray]
        socialMediaArray.splice(index, 1)
        this.setState({
            socialMediaArray: socialMediaArray
        })
    }

    previousButton = () => {
        if (this.state.update) {
            this.props.history.push('/update-seo-monitoring', { domain: this.state.domain, id: this.state.id, update: true })
        } else {
            this.props.history.push('/seo-monitoring', { domain: this.state.domain })
        }
    }

    getKey = async (data) => {
        await Array.isArray(data) && data.map((v, i) => {
            fetch(`https://graph.facebook.com/v10.0/oauth/access_token?grant_type=fb_exchange_token&client_id=805741630356591&client_secret=1ebde728a7432f13d50d0a5f67aa2f61&fb_exchange_token=${v.access_token}`).then((res) => res.json()).then((res) => {
                data[i].access_token = res.access_token
            })
        })
        this.setState({ fbPageData: data })
    }

    onCallback = async (data) => {
        if (Array.isArray(data.data)) {
            this.setState({
                fbPageModel: true,
                fbPageData: data.data
            }, () => {
                this.getKey(this.state.fbPageData)
            })
        }
    }

    twitterData = (data) => {
        let socialMediaArray = [...this.state.socialMediaArray]
        socialMediaArray.push({
            "social_media_type": "twitter",
            "url": 'https://twitter.com/' + data.screen_name,
            'accessToken': data.oauth_token,
            'PageId': data.user_id,
            'secret_accessToken': data.oauth_token_secret,
            'name': data.screen_name
        })
        this.setState({
            socialMediaArray: socialMediaArray
        })
    }

    ongetInstraCallBack = async (data) => {
        this.setState({ loader: true })
        let socialMediaArray = [...this.state.socialMediaArray]
        await Array.isArray(data.data) && data.data.map(async (obj, index) => {
            if (obj.access_token) {
                await fetch(`https://graph.facebook.com/v11.0/${obj.id}?fields=instagram_business_account&access_token=${obj.access_token}`).then((res) => res.json()).then(async (res) => {
                    if (res && res.instagram_business_account) {
                        await fetch(`https://graph.facebook.com/v11.0/${res.instagram_business_account.id}?fields=followers_count,follows_count,media_count,username&access_token=${obj.access_token}`).then((res1) => res1.json()).then((res2) => {
                            if (res2.id) {
                                socialMediaArray.push({
                                    "social_media_type": "instagram",
                                    "url": `https://www.instagram.com/${res2.username}/`,
                                    'accessToken': obj.access_token,
                                    'PageId': res2.id,
                                    'secret_accessToken': "",
                                    'name': res2.username
                                })
                            }
                        })
                    }
                })
            }
        })

        await setTimeout(() => {
            this.setState({ socialMediaArray: socialMediaArray }, () => {
                this.setState({ loader: false })
                let arrData = this.state.socialMediaArray.slice()
                this.instaExchange(arrData)

            })
        }, 2000)



        // let data = {
        //     client_id: '811418753091786',
        //     client_secret: 'fdb9beafdb55410e2ca6e6c9bf65d4b2',
        //     grant_type: 'authorization_code',
        //     redirect_uri: 'https://www.a1websitetools.com/g8keeper/update-social-media/',
        //     code: code
        // }

        // let response = await socialMediaServices.geInstagramData(data)
        // if (response) {
        //     let socialMediaArray = [...this.state.socialMediaArray]
        //     fetch(`https://graph.instagram.com/${response.user_id}?fields=id,username&access_token=${response.access_token}`).then((res) => res.json()).then((res) => {
        //         socialMediaArray.push({
        //             "social_media_type": "instagram",
        //             "url": `https://www.instagram.com/${res.username}/`,
        //             'accessToken': response.access_token,
        //             'PageId': response.user_id,
        //             'secret_accessToken':"",
        //             'name': res.username
        //         })
        //         this.setState({
        //             socialMediaArray: socialMediaArray
        //         })
        //     })

        // }
    }


    instaExchange = async (data) => {
        await Array.isArray(data) && data.map((v, i) => {
            if (v.social_media_type === "instagram") {
                fetch(`https://graph.facebook.com/v10.0/oauth/access_token?grant_type=fb_exchange_token&client_id=805741630356591&client_secret=1ebde728a7432f13d50d0a5f67aa2f61&fb_exchange_token=${v.accessToken}`).then((res) => res.json()).then((res) => {
                    data[i].accessToken = res.access_token
                })
            }
        })
        this.setState({
            socialMediaArray: data
        })
    }

    render() {
        return (<div>
          {!this.props.popup &&  <Header history={this.props.history} {...this.props}></Header>}
            <div className="container">
                <div className="seo-monitoring">
                    <div className="setLoaderPosition" style={{ top: '66%', marginLeft: '0%' }}>
                        <Loader loader={this.state.loader}></Loader>
                    </div>
                    {/* {!this.props.popup && <div >
                        <h3>Social Media</h3>
                    </div>
                    } */}
                    <SocialMediaForm
                        domain={this.state.domain}
                        socialMediaArray={this.state.socialMediaArray}
                        addSocailMedia={this.addSocailMedia}
                        removeSocialMediaURL={this.removeSocialMediaURL}
                        onChange={this.onChange}
                        errorArray={this.state.errorArray}
                        onCallback={(e) => this.onCallback(e)}
                        twitterData={this.twitterData}
                        ongetInstraCallBack={this.ongetInstraCallBack}
                        popup={this.props.popup}
                    ></SocialMediaForm>
                </div>
                <div className="belowBtns">
                    {!this.props.popup && <Button className="PreviousBotton" onClick={() => this.previousButton()}>Previous</Button>}
                    <Button className="NextButton" onClick={() => this.onSubmit()}>{this.props.popup ? "Update" : "Next"}</Button>
                </div>
                <FbPage
                    modal={this.state.fbPageModel}
                    fbPageData={this.state.fbPageData}
                    toggle={() => this.setState({ fbPageModel: false })}
                    addArrayInMediaArray={this.addArrayInMediaArray}
                />
            </div>
        </div>)
    }
}

export default SocialMedia