import React, { useEffect, useState } from "react";
import moment from "moment";
import LineStackChart from "../Charts/LineStackChart";
import { graphService } from "../../_services/servicesWithSecondOrigin/index";
import { MemoryType } from "./Constants";
import { Loader } from "../../_components/CommonComponents/index";

const alternateSeriesNames = {
  Download: "Network in",
  Upload: "Network out",
};

const CpuUsageChart = ({ ...props }) => {
  const [xAxis, setXAxis] = useState(false);
  const [yAxis, setyAxis] = useState(false);
  const [series, setSeries] = useState(false);
  const [setUnSet, setUnSetUI] = useState(true);
  const [dataSizeType, setDataSizeType] = useState("");
  const [loader, setLoader] = useState(true);
  const [legend, setLegend] = useState([]);

  useEffect(() => {
    if (props.uuid) getGraphDataFromServer();
  }, [
    props.uuid,
    props.active,
    props.start_datetime,
    props.end_datetime,
    props.isRefresh,
    props.mytimezone,
    props.unique_id,
  ]);

  const getGraphDataFromServer = async () => {
    setSeries([
      {
        name: "",
        data: [0],
        type: "line",
        smooth: true,
      },
    ]);
    setXAxis({
      type: "category",
      triggerEvent: true,
      data: [],
    });
    var d = new Date();
    var n = d.getTimezoneOffset();
    setLoader(true);
    let data = {
      uuid: props.uuid,
      user_element: props.user_element,
      start_datetime: props.start_datetime
        ? props.start_datetime
        : moment(new Date())
            .add(-props.active || -4, "hours")
            .format("YYYY-MM-DD HH:mm:ss"),
      end_datetime: props.end_datetime
        ? props.end_datetime
        : moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      timezone: props.mytimezone,
      timediff: n,
    };

    if (props.unique_id) {
      data.unique_id = props.unique_id;
    }

    let response = {};
    if (
      props.user_element !== "db_backup" &&
      props.user_element !== "folder_backup"
    ) {
      response = await graphService.getGraphData(data);
    } else {
      response = await graphService.getGraphData2(data);
    }

    if (response && response.data) {
      setUnSetUI(false);
      if (Array.isArray(response.data.timestamp)) {
        setXAxis({
          type: "category",
          data: response.data.timestamp,
        });
      } else {
        setXAxis({
          type: "category",
          triggerEvent: true,
          data: [],
        });
      }
      const Series = [];
      let inDataSize = "";
      let SeriesType = [];
      Array.isArray(props.SeriesType) &&
        props.SeriesType.map((object) => {
          if (
            Array.isArray(response.data[object]) &&
            MemoryType.includes(props.user_element)
          ) {
            SeriesType.push(alternateSeriesNames[object] || object);
            response.data[object].map((check, index) => {
              inDataSize = "GB";
              if (props.user_element === "network-stats") {
                inDataSize = "KB/s";
                const value = (
                  (parseInt(check) /
                  1024 ** 1)
                )?.toFixed(2)
                response.data[object][index] = value === 'NaN' ? '' : value;
              } else {
                const value = (
                  (parseFloat(check) /
                  1024 ** 3)
                )?.toFixed(2)
                response.data[object][index] = value === 'NaN' ? '' : value;
              }
              setDataSizeType(inDataSize);
            });
          } else if (Array.isArray(response.data[object])) {
            SeriesType.push(alternateSeriesNames[object] || object);
            if (props.user_element === "failed_login_activity") {
              setDataSizeType("");
            } else if (props.user_element !== "disk_io_stats") {
              setDataSizeType("%");
            } else {
              setDataSizeType("KB/s");
            }

            response.data[object].map((check, index) => {
              response.data[object][index] =
                check === "" ? "" : (parseFloat(check) || 0).toFixed(2);
            });
          }
          // let legendArr = legend.slice()
          // legendArr.push(object)
          // if (props.isLegend) {
          //     setLegend(props.SeriesType);
          // }

          Array.isArray(response.data[object]) &&
            Series.push({
              name: alternateSeriesNames[object] || object,
              data: response.data[object],
              type: "line",
              smooth: true,
            });
        });

      if (props.isLegend) {
        setLegend(SeriesType);
      }

      setUnSetUI(true);
      if (Series.length === 0) {
        setSeries([
          {
            name: "",
            data: [0],
            type: "line",
            smooth: true,
          },
        ]);
      } else {
        setSeries(Series);
      }
      setLoader(false);
    } else {
      setLoader(false);
    }
  };

  return (
    <>
      <div className="chart-loader">
        {" "}
        <Loader loader={loader}></Loader>
      </div>
      {setUnSet && (
        <LineStackChart
          xAxis={xAxis}
          yAxis={yAxis}
          series={series}
          dataSizeType={dataSizeType}
          legend={legend}
          {...props}
        ></LineStackChart>
      )}
    </>
  );
};
export default CpuUsageChart;
