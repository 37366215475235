import React from "react";
import toastr from "toastr";
import { confirmAlert } from "react-confirm-alert";
import { MdAdd, MdRefresh } from "react-icons/md";
import Header from "../../_components/CommonComponents/Header/Header";
import PageLoader from "../../_components/CommonComponents/PageLoader/Loader";
import {
  ApiMonitorService,
  dashBoardServices,
} from "../../_services/servicesWithSecondOrigin/index";
import defaultDashboard from "../../assets/img/blank-dashboard.png";
import topArrow from "../../assets/img/top-arrow.svg";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import ApiMonitorListingTable from "../../_components/ApiMonitorListingTable/ApiMonitorListingTable";
import moment from "moment";
import { AiFillClockCircle } from "react-icons/ai";
import MonitorUrlChartOperation from "../../_components/MonitorUrlsChartOperation/MonitorUrlChartOperation";
import { ApiStatusService } from "../../_services/servicesWithSecondOrigin/ApiStatusServices/ApiStatusService";
import { DateTimeFilter, Notes } from "../../_components/CommonComponents";
import { RiBarChartFill } from "react-icons/ri";
import MonitorUrlprogressBar from "../../_components/MonitorUrlsListing/MonitorUrlprogressBar";
import MonitorLogTable from "../../_components/MonitorLogTable/MonitorLogTable";

export class ApiMonitorListing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      allApisList: [],
      disabledApi: [],
      isLoading: true,
      myTimezone: "",
      allSharedDomainData: [],
      apiDetails: [],
      hours: 24,
      minute: 0,

      //isRefresh:true
    };
  }

  componentDidMount() {
    this.getAllDomain();
  }

  refresh = () => {
    this.getAllDomain();
  };

  getAllDomain = async () => {
    var date = new Date();
    var timeDiff = date.getTimezoneOffset();

    this.setState({
      isLoading: true,
      allApisList: [],
      allSharedDomainData: [],
    });
    let response = await ApiMonitorService.allApis({
      timezone: this.state.myTimezone,
      timediff: timeDiff,
    });

    if (response && response.data && Array.isArray(response.data)) {
      this.setState({
        disabledApi: response.disbale,
        allApisList: response.data,
      });
    }

    this.setState({
      isLoading: false,
    });
  };

  deleteDomain = (id) => {
    confirmAlert({
      title: "Confirm to Delete",
      message: <p>Are you sure to do this.</p>,
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            let response = await dashBoardServices.deleteDomain(id);
            if (response && response.message) {
              toastr.info(response.message);
            }
            if (response && response.status === 200) {
              this.getAllDomain();
            }
          },
        },
        {
          label: "No",
          onClick: () => console.log("No"),
        },
      ],
    });
  };

  removeDomain = (domain) => {
    confirmAlert({
      title: "Confirm to Delete",
      message: <p>Are you sure to do this.</p>,
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            let data = {
              domain_name: domain,
            };
            let response = await dashBoardServices.removeDomainFromDashboard(
              data
            );
            if (response && response.message) {
              toastr.info(response.message);
            }
            if (response && response.status === 200) {
              this.getAllDomain();
            }
          },
        },
        {
          label: "No",
          onClick: () => console.log("No"),
        },
      ],
    });
  };

  getApiDetails = async (id) => {
    var d = new Date();
    var n = d.getTimezoneOffset();
    this.setState({
      loader: true,
      id: id,
      urlDetails: [],
      refresh: Math.random(),
    });

    let response = await ApiStatusService.ApiDetails(
      id,
      `start_datetime=${this.state.start_datetime}&end_datetime=${this.state.end_datetime}&timezone=${this.state.myTimezone}&timediff=${n}`
    );
    if (response && response?.data) {
      this.setState({
        apiDetails: response.data,
        loader: false,
      });
    } else {
      this.setState({
        apiDetails: "",
        loader: false,
      });
    }
  };

  onClickGetFreshUserdetails = (id) => {
    this.setState(
      {
        start_datetime: moment(new Date())
          .add(-24, "hours")
          .format("YYYY-MM-DD HH:mm:ss"),
        hours: 24,
        minute: 0,
        end_datetime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      },
      () => {
        this.getApiDetails(id);
      }
    );
  };

  onClickShowGraphData = () => {
    var now = moment(this.state.end_datetime); //todays date
    var end = moment(this.state.start_datetime); // another date
    var duration = moment.duration(now.diff(end));
    var hours = duration.asHours();
    var minute = duration.asMinutes();
    minute = minute % 60;
    this.setState({
      hours: hours,
      minute: minute,
    });
    this.getApiDetails(this.state.id);
  };

  render() {
    const { allApisList, apiDetails, hours, minute, disabledApi } = this.state;

    return (
      <div className="container">
        <PageLoader isLoading={this.state.isLoading}></PageLoader>
        <Header
          history={this.props.history}
          {...this.props}
          callRefresh={this.refreshData}></Header>

        <div className="row">
          <div className="col-lg-5">
            <div className="card">
              <div className="card-header">
                <div>
                  <h3>Monitor API</h3>
                  <h6 className="headerDes">
                    This page lists all the APIs that are being monitored. This
                    displayes all the detailed statistics of API and notifies
                    you when the API is up or down
                  </h6>
                </div>

                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>Add a new API</Tooltip>}>
                  <Button className="iconBtn addIconBtn px-3">
                    <MdAdd
                      style={{ width: "3rem", height: "3rem" }}
                      className=""
                      onClick={() =>
                        this.props.history.push("/api-monitor-form")
                      }></MdAdd>
                  </Button>
                </OverlayTrigger>

                <OverlayTrigger
                  key={"bottom"}
                  placement={"bottom"}
                  overlay={<Tooltip>Refresh</Tooltip>}>
                  <MdRefresh
                    style={{ width: "50px" }}
                    className="iconBtn pl-1 ml-3"
                    onClick={() => this.refresh()}></MdRefresh>
                </OverlayTrigger>
              </div>
              {Array.isArray(allApisList) && allApisList.length > 0 && (
                <div className="monitorUrlTitle">
                  <div className="row">
                    <div className="col-md-6 col-5">
                      <div className="lastTitle">
                        <AiFillClockCircle className="clockIcon" />
                        <strong>Last 24 hours</strong>
                      </div>
                    </div>
                    <div className="col-md-4 col-7">
                      <div className="hoursValue">
                        <span>{moment().format("HH:mm")}</span>
                        <span>{moment().add(6, "hours").format("HH:mm")}</span>
                        <span>{moment().add(-6, "hours").format("HH:mm")}</span>
                        <span>{moment().format("HH:mm")}</span>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {Array.isArray(allApisList) &&
                allApisList.map((obj, index) => {
                  return (
                    <ApiMonitorListingTable
                      myTimezone={this.state.myTimezone}
                      removeDomain={this.removeDomain}
                      object={obj}
                      allData={obj}
                      history={this.props.history}
                      deleteDomain={this.deleteDomain}
                      onClick={() => this.onClickGetFreshUserdetails(obj.id)}
                      key1={index}
                      hour_status={obj["24_hour_status"]}
                      id={this.state.id}></ApiMonitorListingTable>
                  );
                })}
            </div>

            {Array.isArray(disabledApi) && disabledApi.length !== 0 && (
              <div className="card">
                <div className="card-header">
                  <div>
                    <h3>Disabled Monitor URL</h3>
                  </div>
                </div>{" "}
                {disabledApi.map((data, index) => {
                  return (
                    <ApiMonitorListingTable
                      myTimezone={this.state.myTimezone}
                      removeDomain={this.removeDomain}
                      object={data}
                      allData={data}
                      history={this.props.history}
                      deleteDomain={this.deleteDomain}
                      onClick={() => this.onClickGetFreshUserdetails(data.id)}
                      key1={index}
                      hour_status={data["24_hour_status"]}
                      id={this.state.id}></ApiMonitorListingTable>
                  );
                })}
              </div>
            )}
          </div>
          <div className="col-lg-7">
            {this.state.id && (
              <>
                <div className="card overflow-inherit">
                  <div className="card-header">
                    {apiDetails &&
                      apiDetails.apiDetails &&
                      apiDetails.apiDetails.api_url && (
                        <h3> {apiDetails.apiDetails.api_url} </h3>
                      )}
                    <Notes id={this.state.id} type="api_monitor"></Notes>
                  </div>

                  <div className="m-2">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="card">
                          <div className="card-header">
                            <h3>Current Status</h3>
                          </div>
                          <div
                            className="card-body componentCenter"
                            style={{ minHeight: "138px" }}>
                            <div className="serverstatusbg">
                              {apiDetails && apiDetails.current_status && (
                                <span
                                  className="statusText"
                                  style={
                                    apiDetails &&
                                    apiDetails.current_status &&
                                    apiDetails.current_status.status === "UP"
                                      ? {
                                          background: "#ebf7ee",
                                          color: "#27a644",
                                        }
                                      : {
                                          background: "#fef1f1",
                                          color: "#dc3545",
                                        }
                                  }>
                                  {apiDetails &&
                                    apiDetails.current_status &&
                                    apiDetails.current_status.status}
                                </span>
                              )}
                              {apiDetails && apiDetails.current_status && (
                                <span className="font-13">{`Since ${
                                  apiDetails &&
                                  apiDetails.current_status &&
                                  apiDetails.current_status.time
                                } (${
                                  apiDetails &&
                                  apiDetails.current_status &&
                                  apiDetails.current_status.date
                                })`}</span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="card">
                          <div className="card-header">
                            <h3>Uptime Status</h3>
                          </div>
                          <div className="card-body pt-3 pb-3">
                            {apiDetails &&
                              apiDetails.last_24_hours !== "" &&
                              apiDetails.last_24_hours !== null && (
                                <span
                                  className={
                                    parseInt(apiDetails.last_24_hours) > 94
                                      ? "greenOver95 statusText mb-1"
                                      : parseInt(apiDetails.last_24_hours) > 79
                                      ? "onabove79 statusText mb-1"
                                      : apiDetails.last_24_hours ===
                                        "No suficient data found!"
                                      ? "no-sufficient-data statusText mb-1"
                                      : "red-on-blow79 statusText mb-1"
                                  }>
                                  {isNaN(apiDetails.last_24_hours) ? (
                                    <span>{apiDetails.last_24_hours}</span>
                                  ) : (
                                    `${parseFloat(
                                      apiDetails.last_24_hours
                                    ).toFixed(2)}% Last 24 Hours`
                                  )}
                                </span>
                              )}
                            {apiDetails &&
                              apiDetails.last_7_days !== "" &&
                              apiDetails.last_7_days !== null && (
                                <span
                                  className={
                                    parseInt(apiDetails.last_7_days) > 94
                                      ? "greenOver95 statusText mb-1"
                                      : parseInt(apiDetails.last_7_days) > 79
                                      ? "onabove79 statusText mb-1"
                                      : apiDetails.last_7_days ===
                                        "No suficient data found!"
                                      ? "no-sufficient-data statusText mb-1"
                                      : "red-on-blow79 statusText mb-1"
                                  }>
                                  {isNaN(apiDetails.last_7_days) ? (
                                    <span>{apiDetails.last_7_days}</span>
                                  ) : (
                                    `${parseFloat(
                                      apiDetails.last_7_days
                                    ).toFixed(2)}% Last 7 Days`
                                  )}
                                </span>
                              )}

                            {apiDetails &&
                              apiDetails.last_30_days !== "" &&
                              apiDetails.last_30_days !== null && (
                                <span
                                  className={
                                    parseInt(apiDetails.last_30_days) > 94
                                      ? "greenOver95 statusText mb-1"
                                      : parseInt(apiDetails.last_30_days) > 79
                                      ? "onabove79 statusText mb-1"
                                      : apiDetails.last_30_days ===
                                        "No suficient data found!"
                                      ? "no-sufficient-data statusText mb-1"
                                      : "red-on-blow79 statusText mb-1"
                                  }>
                                  {isNaN(apiDetails.last_30_days) ? (
                                    <span>{apiDetails.last_30_days}</span>
                                  ) : (
                                    `${parseFloat(
                                      apiDetails.last_30_days
                                    ).toFixed(2)}% Last 30 Days`
                                  )}
                                </span>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="card m-2">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="card-header">
                          <h3>Latest Downtime</h3>
                        </div>
                        <div className="card-body componentCenter">
                          <div className="serverstatusbg">
                            {apiDetails && apiDetails.last_down_time && (
                              <span
                                className="statusText"
                                style={
                                  apiDetails.last_down_time.status === "UP"
                                    ? {
                                        background: "#ebf7ee",
                                        color: "#27a644",
                                      }
                                    : {
                                        background: "#fef1f1",
                                        color: "#dc3545",
                                      }
                                }>
                                {apiDetails.last_down_time.status}
                              </span>
                            )}
                            {apiDetails &&
                              apiDetails.last_down_time &&
                              apiDetails.last_down_time.date &&
                              apiDetails.last_down_time.time && (
                                <span className="font-13">{`it was recorded on ${apiDetails.last_down_time.date} and down time latest for ${apiDetails.last_down_time.time}`}</span>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="dateTimeFilterSec">
                    <div className="uptimeIntro">
                      <RiBarChartFill className="upBarChartIcon" />{" "}
                      <h3>
                        Uptime <span>(Last 24 hours)</span>
                      </h3>
                    </div>
                    <div className="pb-3 mb-1">
                      <MonitorUrlprogressBar
                        hour_status={
                          apiDetails && apiDetails["24_hour_status"]
                            ? apiDetails["24_hour_status"]
                            : []
                        }
                        create_datetime={
                          apiDetails?.apiDetails?.create_datetime
                            ? apiDetails.apiDetails.create_datetime
                            : ""
                        }
                      />
                    </div>

                    <div className="select_startdatetime">
                      <DateTimeFilter
                        start_datetime={this.state.start_datetime}
                        end_datetime={this.state.end_datetime}
                        setStartDateTime={this.setStartDateTime}
                        setEndDateTime={this.setEndDateTime}
                        onClickShowGraphData={this.onClickShowGraphData}
                        onClickGetFreshUserdetails={() =>
                          this.onClickGetFreshUserdetails(this.state.id)
                        }
                      />
                    </div>

                    <div className="respondTimeSec">
                      Response Time:{" "}
                      <span>
                        {hours && parseInt(hours)} Hours{" "}
                        {minute && parseInt(minute)} Minutes
                      </span>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header">
                    <h3>Server Response Time</h3>
                  </div>
                  <div className="row">
                    <div className="col-md-12 pb-2 monitorURLchart">
                      <MonitorUrlChartOperation
                        urlDetails={
                          apiDetails.response_time
                            ? apiDetails.response_time
                            : []
                        }
                        title={""}
                        tooltip={true}
                        toolbox={true}
                        loader={this.state.loader}
                        refresh={this.state.refresh}
                        dataZoom={true}
                        onRefresh={this.state.onRefresh}
                      />
                    </div>
                  </div>
                </div>

                <div className="card">
                  {apiDetails && (
                    <MonitorLogTable data={apiDetails?.api_status_log} />
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}
