import React, { useEffect, useState } from "react";
import moment from "moment";
import { graphService } from "../../_services/servicesWithSecondOrigin/index";
import ChartOperationsNginx from "./ChartOperationsNginx";
import { VERSION_NUMBER } from "../../Pages/MonitorServereCharts/Constants";
import DiskIOProcess from "../../Pages/MonitorServereCharts/DiskIOProcess.component";
import { objectToQuery } from "../../_helpers/Common/Common";
const ChartOperationsApacheInnerNgnix = ({ ...props }) => {
  const [timestemp, setTimestemp] = useState([]);
  const [data, setData] = useState("");
  const [tableData, setTableDataonOneTimezon] = useState([]);
  const [response_code_with_request_name, setTableData] = useState({});
  useEffect(() => {
    if (response_code_with_request_name[props.topProcessDataDate]) {
      setTableDataonOneTimezon(
        response_code_with_request_name[props.topProcessDataDate]
      );
    } else {
      setTableDataonOneTimezon([]);
    }
  }, [props.topProcessDataDate]);

  useEffect(() => {
    if (props.uuid) getGraphDataFromServer();
  }, [
    props.uuid,
    props.active,
    props.start_datetime,
    props.end_datetime,
    props.isRefresh,
    props.mytimezone,
    props.unique_id,
    props.App_version,
  ]);

  const getGraphDataFromServer = async () => {
    var d = new Date();
    var n = d.getTimezoneOffset();
    let data = {
      uuid: props.uuid,
      user_element: props.user_element,
      start_datetime: props.start_datetime
        ? props.start_datetime
        : moment(new Date())
            .add(-props.active || -4, "hours")
            .format("YYYY-MM-DD HH:mm:ss"),
      end_datetime: props.end_datetime
        ? props.end_datetime
        : moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      timezone: props.mytimezone,
      timediff: n,
      // "uuid": "603cebf9b5f25",
      // "user_element": props.user_element,
      // "start_datetime": "2022-07-07 07:45:35",
      // "end_datetime": "2022-07-09 11:45:35",
      // "timezone": "Asia/Kolkata",
      // "timediff": -330
    };

    if (props.unique_id) {
      data.unique_id = props.unique_id;
    }

    let response = "";
    if (props.App_version && props.App_version >= VERSION_NUMBER) {
      response = await graphService.getApachheGraphData2(data);
      if (response && response.data && response.data) {
        if (response.data && response.data.timestamp) {
          setTimestemp(response.data.timestamp);
        }
        if (response?.data?.response_code_with_request_name) {
          setTableData(response.data.response_code_with_request_name);
        }

        setData(response.data);
      } else {
        setData([]);
      }
    } else {
      response = await graphService.getNgnixGraphData(data);
      if (response && response.data && response.data) {
        if (response.data && response.data.timestamp) {
          setTimestemp(response.data.timestamp);
        }
        setData(response.data);
      } else {
        setData([]);
      }
    }
  };

  return (
    <>
      {data.InnerData && (
        <div
          className="col-md-6"
          onClick={() => {
            const object = {
              user_element: props.user_element,
              title: "Ngnix Traffic",
              id: props.id,
              uuid: props.uuid,
              update: true,
              server_name: props.server_name,
              mytimezone: props.mytimezone,
              chart: 5,
              type: ["InnerData", "InnerData2"],
              cKey: "Nginx",
              activeTab: 3,
              App_version: props.App_version,
            };
            props.history.push(
              "/monitor-server/chart" + objectToQuery(object),
              object
            );
          }}>
          <div className="card overflow-visible">
            <div className="card-header">
              <h3>
                Ngnix Traffic{" "}
                <span className="requist-per-second">(Requests/min)</span>
              </h3>
            </div>
            <div className="chartInnerDiv">
              <ChartOperationsNginx
                {...props}
                timestemp={timestemp}
                chartData={data.InnerData}
                outer={true}
                tooltipFormatterCallBack
              />
            </div>
          </div>
        </div>
      )}

      {data.InnerData &&
        props.App_version &&
        props.App_version >= VERSION_NUMBER && (
          <DiskIOProcess
            diskIOKeyList={diskIOKeyList}
            diskIOList={tableData}
            disk_io_stats_date={props.topProcessDataDate}
            tableName="Ngnix traffic details"
            hideSearch
          />
        )}

      {data.InnerData2 && (
        <div
          className="col-md-6"
          onClick={() => {
            const object = {
              user_element: props.user_element,
              title: "Ngnix Traffic Request",
              id: props.id,
              uuid: props.uuid,
              update: true,
              server_name: props.server_name,
              mytimezone: props.mytimezone,
              chart: 5,
              type: ["InnerData", "InnerData2"],
              cKey: "Nginx",
              activeTab: 3,
              App_version: props.App_version,
            };
            props.history.push(
              "/monitor-server/chart" + objectToQuery(object),
              object
            );
          }}>
          <div className="card overflow-visible">
            <div className="card-header">
              <h3>
                Ngnix Traffic (GET / POST Requests){" "}
                <span className="requist-per-second">(Requests/min)</span>
              </h3>
            </div>
            <div className="chartInnerDiv">
              <ChartOperationsNginx
                {...props}
                timestemp={timestemp}
                chartData={data.InnerData2}
                outer={true}
              />
            </div>
          </div>
        </div>
      )}
      {!props.performanceChartData && !data.InnerData && !data.InnerData2 && (
        <div style={{ height: "90px" }} className="w-100">
          No Data Found
        </div>
      )}
    </>
  );
};
export default ChartOperationsApacheInnerNgnix;
const diskIOKeyList = [
  {
    dataField: "method",
    text: "Method",
    sort: true,
    headerStyle: (colum, colIndex) => {
      return { width: "40px" };
    },
  },
  {
    dataField: "status",
    text: "Status",
    sort: true,
    headerStyle: (colum, colIndex) => {
      return { width: "40px" };
    },
  },
  {
    dataField: "url",
    text: "URL",
    sort: true,
    headerStyle: (colum, colIndex) => {
      return { width: "40px" };
    },
  },
];
