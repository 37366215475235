import React, { useRef, useEffect, useState, useMemo } from "react";
import ReactECharts from "echarts-for-react";
import refreshImg from "./../../assets/img/refresh_new.png";
import "echarts/lib/component/tooltip";

const LineStackChartCommonLegend = ({ ...props }) => {
  const propSeries = props.series.sort?.((a, b) =>
    a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : -1
  );

  const [setUnSet, setUnSetUI] = useState(true);
  const [series, setSeries] = useState(propSeries);

  const myRef = useRef(null);
  const seriesRef = useRef(propSeries);

  useEffect(() => {
    setSeries(propSeries);
    seriesRef.current = propSeries;
  }, [propSeries]);

  useEffect(() => {
    if (props.onRefresh) {
      setUnSetUI(false);
      setTimeout(() => {
        setUnSetUI(true);
      }, 1);
    }
  }, [props.onRefresh]);
  let options = {
    group: "group1",
    grid: {
      top: "25%",
      left: "3%",
      right: "4%",
      bottom: props.dataZoom ? "18%" : "3%",
      containLabel: true,
    },
    xAxis: {
      type: "category",
      data: ["03:00", "04:00"],
    },
    yAxis: {
      type: "value",
      axisLabel: {
        formatter: `{value} ${props.dataSizeType ? props.dataSizeType : ""}`,
      },
    },
    series: [
      {
        data: [0],
        type: "line",
        smooth: true,
      },
    ],
    legend: {
      data: [],
    },
  };

  if (props.title) {
    let style = {};
    var element = document.body;
    if (element.classList.contains("dark")) {
      style = {
        textStyle: {
          color: "#b6c2e4",
        },
      };
    }

    options.title = {
      text: props.title,
      ...style,
    };
  }
  if (props.legend) {
    if (props.legend) {
      if (props.legendObj) {
        options.legend = props.legendObj;
      } else {
        options.legend = {
          data: props.legend,
          type: "scroll",
        };
      }
    }
  }

  if (props.toolbox) {
    options.toolbox = {
      feature: {
        saveAsImage: {},
      },
      right: "06%",
    };

    // for restore custom toolbox feature
    if (props.restore) {
      options.toolbox.feature.myTool1 = {
        show: true,
        title: "Refresh",
        icon: "image://" + refreshImg,
        onclick: function () {
          if (
            myRef &&
            myRef.current &&
            myRef.current.getEchartsInstance() &&
            myRef.current.props
          ) {
            if (props.getRestoreChart) {
              props.getRestoreChart();
            } else {
              myRef.current
                .getEchartsInstance()
                .setOption(myRef.current.props.option, true);
            }
          }
        },
      };
    }
  }

  if (props.tooltip) {
    options.tooltip = {
      trigger: "axis",

      formatter: function (e) {
        if (props.tooltipFormatterCallBack) {
          localStorage.setItem("topProcess", e[0].name);
        }

        if (props.tooltipFormatterValueName) {
          localStorage.setItem(props.tooltipFormatterValueName, e[0].name);
        }

        if (props.title) {
          localStorage.setItem("table_title", props.title);
        }

        let rowData = e[0].name;

        Array.isArray(e) &&
          e.forEach((data) => {
            rowData =
              rowData +
              `<br/> <span style="font-weight:bold">${data.marker} ${
                props.notShow ? "" : data.seriesName + " : "
              }</span>${
                props.tofixed ? parseFloat(data.data).toFixed(2) : data.data
              } ${props.dataSizeType ? props.dataSizeType : ""} ${
                !data.data && props.ShowOnBlank ? props.ShowOnBlank : ""
              }`;
          });
        return rowData;
      },
    };
    // Dark Mode custom color update on chart
    var element = document.body;
    if (element.classList.contains("dark")) {
      // code to update tooltip color on theme change
      let textColor = "#b6c2e4";
      let BgColor = "#30384c";
      options.tooltip = {
        ...options.tooltip,
        backgroundColor: BgColor,
        textStyle: {
          color: textColor,
        },
      };
      // code to update legend color on theme change
      options.legend = {
        ...options.legend,
        textStyle: {
          color: textColor,
        },
      };
    }
  }

  if (props.dataZoom) {
    options.dataZoom = [{}];
  }
  if (props.grid) {
    options.grid = props.grid;
  }
  if (props.xAxis) {
    options.xAxis = props.xAxis;
  }
  if (props.yAxis) {
    options.yAxis = props.yAxis;
  }
  if (series) {
    options.series = series;
  }

  const onEvents = useMemo(
    () => ({
      click: () => {},
      legendselectchanged: ({ selected, name }) => {
        if (!name.includes(" Response")) return;

        setSeries((prev) => {
          if (!prev) return prev;

          const newSeries = prev.map((series, idx) => {
            const name = `${series.name.slice(0, 1)}00s Response`;

            if (!series.name.includes(" Response") || !(name in selected))
              return series;

            const flag = selected[name];

            const newSeriesObj = flag
              ? {
                  ...series,
                  data: (seriesRef.current[idx] || series).data,
                }
              : { ...series, data: [] };

            return newSeriesObj;
          });

          return newSeries;
        });
      },
      //'dataZoom': this.onDataZoom,
    }),
    []
  );

  useEffect(() => {
    if (props.getEchartRef && myRef) {
      props.getEchartRef(myRef);
    }
  }, []);

  const legend = options.legend?.data
    ? options.legend
    : {
        ...options.legend,
        data: (() => {
          const data = options.series
            .map((s) =>
              !s.name?.includes(" Response")
                ? s.name
                : `${s.name.slice(0, 1)}00s Response`
            )
            .filter((item, i, ar) => ar.indexOf(item) === i)
            .sort((a, b) => (a?.toLowerCase() > b?.toLowerCase() ? 1 : -1));

          return data;
        })(),
      };

  const chartOption = !options
    ? {}
    : {
        ...options,
        legend: legend,
        series: (({ series, legend }) => {
          const s = [...series];

          if (legend?.data)
            for (const leg of legend.data) {
              if (
                !leg ||
                !leg.includes(" Response") ||
                s.find((s) => s.name === leg) ||
                !s[0]
              )
                continue;

              s.push({
                ...s[0],
                name: leg,
                data: [],
              });
            }

          return s;
        })({ series: options.series, legend }),
      };

  return (
    <>
      {setUnSet && (
        <ReactECharts
          //theme="dark"
          ref={myRef}
          option={chartOption}
          onEvents={onEvents}
          opts={props.opts}
        />
      )}
    </>
  );
};
export default LineStackChartCommonLegend;
