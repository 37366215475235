import React from 'react'
import Header from '../../_components/CommonComponents/Header/Header'
import LoginForm from '../../_components/LoginForm/LoginForm'
import g8keeperLogo from '../../assets/img/logo.svg';
import g8whitelogo from '../../assets/img/g8logo_white.png'

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        return (<div>
            <Header history={this.props.history} {...this.props}></Header>
            <div className="loginForm">
            <div className="card">
                <div className="loginLogo lightLogo"><img src={g8keeperLogo} alt="Logo" /></div>    
                <div className="loginLogo darkLogo"><img src={g8whitelogo} alt="Logo" /></div>         
                    {/* <h2>User Login</h2> */}
                    <h4 className="card-title"> Welcome to g8keeper! 👋 </h4>
                <div className="seo-monitoring-form">
                    <LoginForm
                        history={this.props.history}
                    ></LoginForm>
                </div>
            </div>
            </div>
        </div>)
    }
}

export default Login