import React, { useState, useEffect, useMemo } from "react";
import {
  graphService,
  monitorServersServices,
} from "../../_services/servicesWithSecondOrigin/index";
import moment from "moment";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import SearchBootstrapTable from "../BootstrapTable/SearchBootstrapTable";
import { CpuMemoMadal } from "../../Pages/MonitorServereCharts/CPUMemoModal";
import { Button } from "reactstrap";

const statusOBj = {
  200: "200 OK",
  201: "201 Created",
  202: "202 Accepted",
  203: "203 Non-authoritative Information",
  204: "204 No Content",

  205: "205 Reset Content",
  206: "206 Partial Content",
  207: "207 Multi-Status",
  208: "208 Already Reported",
  226: "226 IM Used",

  400: "400 Bad Request",
  401: " 401 Unauthorized",
  402: "402 Payment Required",
  403: "403 Forbidden",
  404: "404 Not Found",
  405: "405 Method Not Allowed",
  406: "406 Not Acceptable",
  407: "407 Proxy Authentication Required",
  408: "408 Request Timeout",
  409: "409 Conflict",
  410: "410 Gone",
  411: "411 Length Required",
  412: "412 Precondition Failed",
  413: "413 Payload Too Large",
  414: "414 Request-URI Too Long",

  415: "415 Unsupported Media Type",
  416: "416 Requested Range Not Satisfiable",
  417: "417 Expectation Failed",
  418: "418 I’m a teapot",
  421: "421 Misdirected Request",
  422: "422 Unprocessable Entity",
  423: "423 Locked",
  424: "424 Failed Dependency",
  426: "426 Upgrade Required",
  428: "428 Precondition Required",
  429: "429 Too Many Requests",
  431: "431 Request Header Fields Too Large",
  444: "444 Connection Closed Without Response",
  451: "451 Unavailable For Legal Reasons",
  499: "499 Client Closed Request",

  500: "500 Internal Server Error",
  501: "501 Not Implemented",
  502: "502 Bad Gateway",
  503: "503 Service Unavailable",
  504: "504 Gateway Timeout",
  505: "505 HTTP Version Not Supported",

  506: "506 Variant Also Negotiates",
  507: "507 Insufficient Storage",
  508: "508 Loop Detected",
  510: "510 Not Extended",
  511: "511 Network Authentication Required",
  599: "599 Network Connect Timeout Error",
};

export const TopMemoryProcress = ({
  uuid,
  active,
  mytimezone,
  dateTime,
  isRefresh,
  start_datetime,
  end_datetime,
}) => {
  const [processType, setProcessType] = useState("top_process");
  const [processData, setProcessData] = useState([]);
  const [processKeyList, setProcessKeyList] = useState([]);
  const [processResponseData, setProcessResponseData] = useState([]);
  const [startDateTime, setStartDateTime] = useState(null);
  const [endDateTime, setEndDateTime] = useState(null);
  const [search, setSearch] = useState("");

  const topProcessKeyList = useMemo(
    () => [
      {
        dataField: "sid",
        text: "#",
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: "04px" };
        },
      },
      {
        dataField: "PID",
        text: "PID",
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: "05px" };
        },
        formatter: (cellContent, row) => {
          return (
            <CpuMemoMadal
              COMMAND={row.COMMAND}
              cellContent={cellContent}
              uuid={uuid}
            />
          );
        },
      },
      {
        dataField: "%CPU",
        text: "CPU",
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: "05px" };
        },
      },
      {
        dataField: "%MEM",
        text: "MEM",
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: "05px" };
        },
      },
      {
        dataField: "COMMAND",
        text: "COMMAND",
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: "20px" };
        },
      },
      {
        dataField: "TIME+",
        text: "TIME",
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: "05px" };
        },
      },
    ],
    [uuid]
  );

  const memoryProcessKeyList = useMemo(
    () => [
      {
        dataField: "sid",
        text: "#",
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: "03px" };
        },
      },
      {
        dataField: "PID",
        text: "PID",
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: "05px" };
        },
        formatter: (cellContent, row) => {
          return (
            <CpuMemoMadal
              COMMAND={row.COMMAND}
              cellContent={cellContent}
              uuid={uuid}
            />
          );
        },
      },
      {
        dataField: "USER",
        text: "USER",
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: "07px" };
        },
      },
      {
        dataField: "%MEM",
        text: "MEM",
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: "06px" };
        },
      },
      {
        dataField: "COMMAND",
        text: "COMMAND",
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: "15px" };
        },
      },
      {
        dataField: "PROCESS_TYPE",
        text: "PROCESS_TYPE",
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: "15px" };
        },
      },
    ],
    [uuid]
  );

  useEffect(() => {
    if (dateTime) {
      let flag = false;

      if (
        moment(startDateTime).isSameOrBefore(dateTime) &&
        moment(endDateTime).isSameOrAfter(dateTime)
      )
        flag = true;

      if (flag === false) {
        if (processType === "top_process") {
          getTopProcessData();
        } else {
          getMemoryProcessData();
        }
      } else {
        processDataByDateTime();
      }
    } else {
      setProcessResponseData([]);
    }
  }, [uuid, active, mytimezone, isRefresh, dateTime]);

  const getTopProcessData = async () => {
    setProcessType("top_process");

    if (!dateTime) return;

    let data = {
      uuid: uuid,
      user_element: "top_process",
      start_datetime: moment(dateTime)
        .add(-1, "hours")
        .format("YYYY-MM-DD HH:mm:ss"),
      end_datetime: moment(dateTime)
        .add(1, "hours")
        .format("YYYY-MM-DD HH:mm:ss"),
      timezone: mytimezone,
    };

    setStartDateTime(data.start_datetime);
    setEndDateTime(data.end_datetime);

    let processResponseData = [];

    let response = await monitorServersServices.topProcessServerDetails(data);
    if (response && response.data) {
      processResponseData =
        typeof response.data === "object" ? Object.entries(response.data) : [];
    }
    setProcessResponseData(processResponseData);
    setProcessKeyList(topProcessKeyList);
  };

  const getMemoryProcessData = async () => {
    setProcessType("disk_io_process");
    if (!dateTime) return;

    let data = {
      uuid: uuid,
      user_element: "disk_io_process",
      start_datetime: moment(dateTime)
        .add(-1, "hours")
        .format("YYYY-MM-DD HH:mm:ss"),
      end_datetime: moment(dateTime)
        .add(1, "hours")
        .format("YYYY-MM-DD HH:mm:ss"),
      timezone: mytimezone,
    };

    setStartDateTime(data.start_datetime);
    setEndDateTime(data.end_datetime);

    let processResponseData = [];

    let response = await monitorServersServices.diskIOProcessServerDetails(
      data
    );
    if (response && response.data)
      processResponseData =
        typeof response.data === "object" ? Object.entries(response.data) : [];

    setProcessResponseData(processResponseData);
    setProcessKeyList(memoryProcessKeyList);
  };

  useEffect(() => {
    processDataByDateTime();
  }, [processResponseData]);

  const processDataByDateTime = () => {
    let processData = [];
    if (dateTime) {
      if (Array.isArray(processResponseData)) {
        processResponseData.map((data) => {
          if (data && data[0] === dateTime && data[1]) {
            processData = data[1];
          }
        });
      }

      if (processType === "top_process") {
        processData.map((d, i) => {
          d.sid = parseFloat(i + 1);
          d.PID = parseFloat(d.PID);
          d["%MEM"] = parseFloat(d["%MEM"]);
          d["%CPU"] = parseFloat(d["%CPU"]);
        });
      } else if (processType === "disk_io_process") {
        processData.map((d, i) => {
          d.sid = parseFloat(i + 1);
          d.PID = parseFloat(d.PID);
          d["%MEM"] = parseFloat(d["%MEM"]);
          d.Write = parseFloat(d.Write);
        });
      }
    }
    setProcessData(processData);
  };

  const tableList = useMemo(
    () =>
      !search
        ? processData
        : processData.filter(
            (row) =>
              row.PID?.toString().includes(search) ||
              row.COMMAND?.toLowerCase().includes(search.toLowerCase())
          ),
    [processData, search]
  );

  return (
    <div className="card overflow-visible">
      <div className="row">
        <div className="col-2">
          <Button
            className="float-left"
            onClick={() => getTopProcessData()}
            color={processType === "top_process" ? "primary" : "secondary"}>
            Top Process
          </Button>
        </div>
        <div className="col-7">
          <div className="d-flex justify-content-between">
            <div className="mt-2">{dateTime}</div>
            <div>
              <input
                type="search"
                className="form-control"
                placeholder="Search"
                onChange={(e) => setSearch(e.target.value)}
                style={{
                  fontSize: 12,
                  height: 32,
                }}
              />
            </div>
          </div>
        </div>
        <div className="col-3">
          <Button
            className="float-right"
            style={{
              marginRight: "100px",
            }}
            onClick={() => getMemoryProcessData()}
            color={processType === "disk_io_process" ? "primary" : "secondary"}>
            Memory Proceses
          </Button>
        </div>
      </div>

      <DiskIOWaitActualTable keyList={processKeyList} tableList={tableList} />
    </div>
  );
};

export const DiskIOAndWaitProcress = ({
  uuid,
  active,
  mytimezone,
  dateTime,
  isRefresh,
  start_datetime,
  end_datetime,
}) => {
  const [processType, setProcessType] = useState("disk_io_process");
  const [processData, setProcessData] = useState([]);
  const [processKeyList, setProcessKeyList] = useState([]);
  const [processResponseData, setProcessResponseData] = useState([]);
  const [startDateTime, setStartDateTime] = useState(null);
  const [endDateTime, setEndDateTime] = useState(null);
  const [search, setSearch] = useState("");

  const diskIOProcessKeyList = useMemo(
    () => [
      {
        dataField: "sid",
        text: "#",
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: "10px" };
        },
      },
      {
        dataField: "PID",
        text: "PID",
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: "20px" };
        },
        formatter: (cellContent, row) => {
          return (
            <CpuMemoMadal
              COMMAND={row.COMMAND}
              cellContent={cellContent}
              uuid={uuid}
            />
          );
        },
      },
      {
        dataField: "Process",
        text: "Process",
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: "50px" };
        },
      },
      {
        dataField: "Read",
        text: "Read (KB/s)",
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: "30px" };
        },
      },
      {
        dataField: "Write",
        text: "Write (KB/s)",
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: "30px" };
        },
      },
    ],
    [uuid]
  );

  //   const diskIOWaitProcessKeyList = [
  //     {
  //       dataField: "sid",
  //       text: "#",
  //       sort: true,
  //       headerStyle: (colum, colIndex) => {
  //         return { width: "10px", textAlign: "center" };
  //       },
  //     },
  //     {
  //       dataField: "PID",
  //       text: "PID",
  //       sort: true,
  //       headerStyle: (colum, colIndex) => {
  //         return { width: "15px" };
  //       },
  //       formatter: (cellContent, row) => {
  //         return (
  //           <CpuMemoMadal
  //             COMMAND={row.COMMAND}
  //             cellContent={cellContent}
  //             uuid={uuid}
  //           />
  //         );
  //       },
  //     },
  //     {
  //       dataField: "COMMAND",
  //       text: "COMMAND",
  //       sort: true,
  //       headerStyle: (colum, colIndex) => {
  //         return { width: "50px" };
  //       },
  //     },
  //     {
  //       dataField: "Cumulative IO-wait ticks",
  //       text: "Cumulative IO-wait ticks",
  //       sort: true,
  //       headerStyle: (colum, colIndex) => {
  //         return { width: "40px" };
  //       },
  //     },
  //     {
  //       dataField: "IO-wait ticks",
  //       text: "IO-wait ticks",
  //       sort: true,
  //       headerStyle: (colum, colIndex) => {
  //         return { width: "40px" };
  //       },
  //     },
  //   ];

  useEffect(() => {
    if (dateTime) {
      let flag = false;

      if (
        moment(startDateTime).isSameOrBefore(dateTime) &&
        moment(endDateTime).isSameOrAfter(dateTime)
      )
        flag = true;

      if (flag === false) {
        if (processType === "disk_io_process") {
          getDiskIoProcessData();
        } else {
          //   getDiskIoWaitProcessData();
        }
      } else {
        processDataByDateTime();
      }
    } else {
      setProcessResponseData([]);
    }
  }, [uuid, active, mytimezone, isRefresh, dateTime]);

  const getDiskIoProcessData = async () => {
    let d = new Date();
    var n = d.getTimezoneOffset();
    setProcessType("disk_io_process");
    if (!dateTime) return;

    let data = {
      uuid: uuid,
      user_element: "actual_disk_io_process",
      start_datetime: moment(dateTime)
        .add(-1, "h")
        .format("YYYY-MM-DD HH:mm:ss"),
      end_datetime: moment(dateTime).add(1, "h").format("YYYY-MM-DD HH:mm:ss"),
      timezone: mytimezone,
      timediff: n,
    };

    setStartDateTime(data.start_datetime);
    setEndDateTime(data.end_datetime);

    let processResponseData = [];

    let response = await graphService.getMysqlChartGraphData(
      data,
      "actual-disk-io-process"
    );
    if (response && response.data)
      processResponseData =
        typeof response.data === "object" ? Object.entries(response.data) : [];

    setProcessResponseData(processResponseData);
    setProcessKeyList(diskIOProcessKeyList);
  };

  // const getDiskIoWaitProcessData = async () => {
  //     let d = new Date()
  //     var n = d.getTimezoneOffset();
  //     setProcessType('disk_io_wait_process');
  //     if (!dateTime) return;

  //     let data = {
  //         "uuid": uuid,
  //         "user_element": "actual_disk_io_process",
  //         "start_datetime": moment(dateTime).add(-1, 'h').format("YYYY-MM-DD HH:mm:ss"),
  //         "end_datetime": moment(dateTime).add(1, 'h').format("YYYY-MM-DD HH:mm:ss"),
  //         "timezone": mytimezone,
  //         timediff: n
  //     }
  //     setStartDateTime(data.start_datetime);
  //     setEndDateTime(data.end_datetime);

  //     let processResponseData = [];

  //     let response = await graphService.getMysqlChartGraphData(data, "disk-io-wait-process");
  //     if (response && response.data) {
  //         processResponseData = (typeof response.data === "object" ? Object.entries(response.data) : []);
  //     }

  //     setProcessResponseData(processResponseData);
  //     setProcessKeyList(diskIOWaitProcessKeyList);
  // }

  useEffect(() => {
    processDataByDateTime(search);
  }, [processResponseData, search]);

  const processDataByDateTime = (search) => {
    let processData = [];
    if (dateTime) {
      if (Array.isArray(processResponseData)) {
        processResponseData.map((data) => {
          if (data && data[0] === dateTime && data[1]) {
            processData = data[1];
          }
        });
      }

      if (processType === "disk_io_process") {
        processData.map((d, i) => {
          d.sid = parseFloat(i + 1);
          d.PID = parseFloat(d.PID);
          d.Read = parseFloat(d.Read);
          d.Write = parseFloat(d.Write);
        });
      } else if (processType === "disk_io_wait_process") {
        processData.map((d, i) => {
          d.sid = parseFloat(i + 1);
          d.PID = parseFloat(d.PID);
          d["Cumulative IO-wait ticks"] = parseFloat(
            d["Cumulative IO-wait ticks"]
          );
        });
      }
    }

    setProcessData(
      !search
        ? processData
        : processData.filter(
            (row) =>
              row.PID?.toString().includes(search) ||
              row.Process?.toLowerCase().includes(search.toLowerCase())
          )
    );
  };

  return (
    <div className="card overflow-visible">
      <div className="row">
        <div className="col-4 col-lg-2">
          <Button
            className="float-left"
            onClick={() => getDiskIoProcessData()}
            color={processType === "disk_io_process" ? "primary" : "secondary"}>
            Disk IO Process
          </Button>
        </div>
        <div className="col-8 col-lg-10">
          <div className="d-flex justify-content-between">
            <div className="mt-2">{dateTime}</div>
            <div className="mr-4">
              <input
                type="search"
                className="form-control"
                placeholder="Search"
                onChange={(e) => setSearch(e.target.value)}
                style={{
                  fontSize: 12,
                  height: 32,
                }}
              />
            </div>
          </div>
        </div>
        {/* <div className="col-5">
                <Button
                    style={{
                        marginRight: '100px'
                    }}
                    className="float-right"
                    onClick={() => getDiskIoWaitProcessData()}
                    color={processType === "disk_io_wait_process" ? "primary" : "secondary"}
                >Disk IO Wait Process</Button>
            </div> */}
      </div>
      <DiskIOWaitActualTable keyList={processKeyList} tableList={processData} />
    </div>
  );
};

export const NetworkIOAndWaitProcess = ({
  uuid,
  active,
  mytimezone,
  dateTime,
  isRefresh,
}) => {
  const [processType, setProcessType] = useState("network_io_process");
  const [processData, setProcessData] = useState([]);
  const [processKeyList, setProcessKeyList] = useState([]);
  const [processResponseData, setProcessResponseData] = useState([]);
  const [startDateTime, setStartDateTime] = useState(null);
  const [endDateTime, setEndDateTime] = useState(null);
  const [search, setSearch] = useState("");

  const networkIOProcessKeyList = useMemo(
    () => [
      {
        dataField: "sid",
        text: "#",
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: "10px" };
        },
      },
      // {
      //   dataField: "PID",
      //   text: "PID",
      //   sort: true,
      //   headerStyle: (colum, colIndex) => {
      //     return { width: "20px" };
      //   },
      //   formatter: (cellContent, row) => {
      //     return (
      //       <CpuMemoMadal
      //         COMMAND={row.COMMAND}
      //         cellContent={cellContent}
      //         uuid={uuid}
      //       />
      //     );
      //   },
      // },
      {
        dataField: "Process",
        text: "Process",
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: "50px" };
        },
      },
      {
        dataField: "Read",
        text: "Network In (KB/s)",
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: "30px" };
        },
      },
      {
        dataField: "Write",
        text: "Network Out (KB/s)",
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: "30px" };
        },
      },
    ],
    [uuid]
  );


  useEffect(() => {
    if (dateTime) {
      let flag = false;

      if (
        moment(startDateTime).isSameOrBefore(dateTime) &&
        moment(endDateTime).isSameOrAfter(dateTime)
      )
        flag = true;

      if (flag === false) {
        if (processType === "network_io_process") {
          getNetworkIoProcessData();
        } else {
          //   getNetworkIoWaitProcessData();
        }
      } else {
        processDataByDateTime();
      }
    } else {
      setProcessResponseData([]);
    }
  }, [uuid, active, mytimezone, isRefresh, dateTime]);

  const getNetworkIoProcessData = async () => {
    let d = new Date();
    var n = d.getTimezoneOffset();
    setProcessType("network_io_process");
    if (!dateTime) return;

    let data = {
      uuid: uuid,
      user_element: "nethogs_data",
      start_datetime: moment(dateTime)
        .add(-1, "h")
        .format("YYYY-MM-DD HH:mm:ss"),
      end_datetime: moment(dateTime).add(1, "h").format("YYYY-MM-DD HH:mm:ss"),
      timezone: mytimezone,
      timediff: n,
    };

    setStartDateTime(data.start_datetime);
    setEndDateTime(data.end_datetime);

    let processResponseData = [];

    let response = await graphService.getMysqlChartGraphData(
      data,
      "actual-network-io-process"
    );
    if (response && response.data)
      processResponseData =
        typeof response.data === "object" ? Object.entries(response.data) : [];

    setProcessResponseData(processResponseData);
    setProcessKeyList(networkIOProcessKeyList);
  };


  useEffect(() => {
    processDataByDateTime(search);
  }, [processResponseData, search]);

  const processDataByDateTime = (search) => {
    let processData = [];
    if (dateTime) {
      if (Array.isArray(processResponseData)) {
        processResponseData.map((data) => {
          if (data && data[0] === dateTime && data[1]) {
            processData = data[1];
          }
        });
      }

      if (processType === "network_io_process") {
        processData.map((d, i) => {
          d.sid = parseFloat(i + 1);
          d.Process = (d.pid);
          d.Read = parseFloat(d.received);
          d.Write = parseFloat(d.sent);
        });
      } else if (processType === "network_io_wait_process") {
        processData.map((d, i) => {
          d.sid = parseFloat(i + 1);
          d.PID = parseFloat(d.PID);
          d["Cumulative IO-wait ticks"] = parseFloat(
            d["Cumulative IO-wait ticks"]
          );
        });
      }
    }

    setProcessData(
      !search
        ? processData
        : processData.filter(
            (row) =>
              row.PID?.toString().includes(search) ||
              row.Process?.toLowerCase().includes(search.toLowerCase())
          )
    );
  };

  return (
    <div className="card overflow-visible">
      <div className="row">
        <div className="col-4 col-lg-2">
          <Button
            className="float-left"
            onClick={getNetworkIoProcessData}
            color={processType === "network_io_process" ? "primary" : "secondary"}>
            Network IO Process
          </Button>
        </div>
        <div className="col-8 col-lg-10">
          <div className="d-flex justify-content-between">
            <div className="mt-2">{dateTime}</div>
            <div className="mr-4">
              <input
                type="search"
                className="form-control"
                placeholder="Search"
                onChange={(e) => setSearch(e.target.value)}
                style={{
                  fontSize: 12,
                  height: 32,
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <NetworkIOWaitActualTable keyList={processKeyList} tableList={processData} />
    </div>
  );
};

export const MySqlCurrentQuery = ({
  uuid,
  active,
  mytimezone,
  dateTime,
  isRefresh,
  start_datetime,
  end_datetime,
}) => {
  const [processData, setProcessData] = useState([]);
  const [processKeyList, setProcessKeyList] = useState([]);
  const [processResponseData, setProcessResponseData] = useState([]);
  const [startDateTime, setStartDateTime] = useState(null);
  const [endDateTime, setEndDateTime] = useState(null);

  const topProcessKeyList = [
    {
      dataField: "sid",
      text: "#",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "04px" };
      },
    },
    {
      dataField: "user",
      text: "User",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "04px" };
      },
    },
    {
      dataField: "host",
      text: "Host",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "04px" };
      },
    },
    {
      dataField: "db",
      text: "Database",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "05px" };
      },
    },
    {
      dataField: "time",
      text: "Time",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "05px" };
      },
    },
    {
      dataField: "state",
      text: "State",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "05px" };
      },
    },
    {
      dataField: "command",
      text: "Command",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "05px" };
      },
    },
    {
      dataField: "Info",
      text: "Info",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "05px" };
      },
    },
  ];

  useEffect(() => {
    if (dateTime) {
      let flag = false;

      if (
        moment(startDateTime).isSameOrBefore(dateTime) &&
        moment(endDateTime).isSameOrAfter(dateTime)
      )
        flag = true;

      if (flag == false) {
        getTopProcessData();
      } else {
        processDataByDateTime();
      }
    } else {
      setProcessResponseData([]);
    }
  }, [uuid, active, mytimezone, isRefresh, dateTime]);

  const getTopProcessData = async () => {
    let d = new Date();
    var n = d.getTimezoneOffset();

    if (!dateTime) return;

    let data = {
      uuid: uuid,
      user_element: "mysql_metadata1",
      start_datetime: moment(dateTime)
        .add(-1, "hours")
        .format("YYYY-MM-DD HH:mm:ss"),
      end_datetime: moment(dateTime)
        .add(1, "hours")
        .format("YYYY-MM-DD HH:mm:ss"),
      timezone: mytimezone,
      timediff: n,
    };

    setStartDateTime(data.start_datetime);
    setEndDateTime(data.end_datetime);

    let processResponseData = [];

    let response = await graphService.getMysqlChartGraphData(
      data,
      "mysql-current-query"
    );
    if (response && response.data) {
      processResponseData =
        typeof response.data === "object" ? Object.entries(response.data) : [];
    }
    setProcessResponseData(processResponseData);
    setProcessKeyList(topProcessKeyList);
  };

  useEffect(() => {
    processDataByDateTime();
  }, [processResponseData]);

  const processDataByDateTime = () => {
    let processData = [];
    if (dateTime) {
      if (Array.isArray(processResponseData)) {
        processResponseData.map((data) => {
          if (data && data[0] === dateTime && data[1]) {
            processData = data[1];
          }
        });
      }

      processData.map((d, i) => {
        d.sid = parseFloat(i + 1);
        d.PID = parseFloat(d.PID);
        d["%MEM"] = parseFloat(d["%MEM"]);
        d["%CPU"] = parseFloat(d["%CPU"]);
      });
    }
    setProcessData(processData);
  };

  return (
    <div>
      <div className="row">
        <div className="col-4">
          <h5 className="sqlheading">Mysql Current Query</h5>
        </div>
        <div className="col-3">{dateTime && dateTime}</div>
      </div>
      <div className="serverchartTable sql table">
        <DiskIOWaitActualTable
          keyList={processKeyList}
          tableList={processData}
        />
      </div>
    </div>
  );
};

export const DiskIOWaitActualTable = ({
  dateTime,
  keyList,
  tableList,
  style,
}) => {
  const [data, setData] = useState([]);
  const [diskIoCollapse, setDiskIoCollapse] = useState(false);

  useEffect(() => {
    if (diskIoCollapse) {
      if (Array.isArray(tableList)) setData(tableList);
    } else {
      if (Array.isArray(tableList)) setData(tableList.filter((d, i) => i < 10));
    }
  }, [diskIoCollapse, tableList, dateTime, keyList]);

  return (
    <>
      {Array.isArray(keyList) && keyList.length > 0 && (
        <SearchBootstrapTable
          data={data}
          columns={keyList}
          searchFlag={false}
          className={"bhhh"}
        />
      )}

      {Array.isArray(data) && data.length > 0 && (
        <div>
          <a
            style={
              style
                ? style
                : {
                    float: "right",
                    cursor: "pointer",
                    height: "30px",
                  }
            }
            onClick={() => setDiskIoCollapse(!diskIoCollapse)}>
            {diskIoCollapse ? (
              <>
                Show Less <IoIosArrowUp size={20}></IoIosArrowUp>
              </>
            ) : (
              <>
                Show More <IoIosArrowDown size={20}></IoIosArrowDown>
              </>
            )}
          </a>
        </div>
      )}
    </>
  );
};

export const NetworkIOWaitActualTable = ({
  dateTime,
  keyList,
  tableList,
  style,
}) => {
  const [data, setData] = useState([]);
  const [networkIoCollapse, setNetworkIoCollapse] = useState(false);

  useEffect(() => {
    let columns = [];
    if (networkIoCollapse) {
      if (Array.isArray(tableList)) setData(tableList);
    } else {
      if (Array.isArray(tableList)) setData(tableList.filter((d, i) => i < 10));
    }
  }, [networkIoCollapse, tableList, dateTime, keyList]);

  return (
    <>
      {Array.isArray(keyList) && keyList.length > 0 && (
        <SearchBootstrapTable
          data={data}
          columns={keyList}
          searchFlag={false}
          className={"bhhh"}
        />
      )}

      {Array.isArray(data) && data.length > 0 && (
        <div>
          <a
            style={
              style
                ? style
                : {
                    float: "right",
                    cursor: "pointer",
                    height: "30px",
                  }
            }
            onClick={() => setNetworkIoCollapse(!networkIoCollapse)}>
            {networkIoCollapse ? (
              <>
                Show Less <IoIosArrowUp size={20}></IoIosArrowUp>
              </>
            ) : (
              <>
                Show More <IoIosArrowDown size={20}></IoIosArrowDown>
              </>
            )}
          </a>
        </div>
      )}
    </>
  );
};

export const ApacheLogTable = React.memo(
  ({ response_code_with_request_name, dateTime, tableData, title }) => {
    const [processData, setProcessData] = useState([]);
    // console.log(
    //   "response_code_with_request_name",
    //   response_code_with_request_name,
    //   dateTime,
    //   tableData
    // );

    useEffect(() => {
      let table_title = localStorage.getItem("table_title");
      if (tableData[table_title] && tableData[table_title][dateTime]) {
        setProcessData(tableData[table_title][dateTime]);
      } else if (response_code_with_request_name[dateTime]) {
        setProcessData(response_code_with_request_name[dateTime]);
      } else if (!tableData) {
        setProcessData([]);
      }
    }, [dateTime, response_code_with_request_name, tableData]);
    return (
      <div className="card overflow-visible">
        <div className="row align-items-center">
          <div className="col-4 col-lg-2 mt-1" style={{ fontWeight: "600" }}>
            {title}
          </div>
          <div className="col-8 col-lg-10">
            <div className="d-flex justify-content-between">
              <div className="mt-2">{dateTime}</div>
            </div>
          </div>
        </div>
        <DiskIOWaitActualTable
          keyList={apache_log_details}
          tableList={processData}
        />
      </div>
    );
  }
);

export default DiskIOWaitActualTable;

const apache_log_details = [
  {
    dataField: "method",
    text: "Method",
    sort: true,
    headerStyle: (colum, colIndex) => {
      return { width: "20px" };
    },
  },
  {
    dataField: "status",
    text: "Status ",
    sort: true,
    headerStyle: (colum, colIndex) => {
      return { width: "40px" };
    },
    formatter: (cellContent, row) => {
      return `${cellContent} (${statusOBj[cellContent?.trim()]})`;
    },
  },
  {
    dataField: "url",
    text: "URL",
    sort: true,
    headerStyle: (colum, colIndex) => {
      return { width: "70px" };
    },
  },
  {
    dataField: "IP",
    text: "IP",
    sort: true,
    headerStyle: (colum, colIndex) => {
      return { width: "30px", textAlign: "center" };
    },
  },

  {
    dataField: "date",
    text: "Server Date Time",
    sort: true,
    headerStyle: (colum, colIndex) => {
      return { width: "40px" };
    },
  },

  {
    dataField: "time",
    text: "Response Size",
    sort: true,
    headerStyle: (colum, colIndex) => {
      return { width: "37px" };
    },
  },
];
