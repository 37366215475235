import React, { Fragment, useState, useEffect } from "react";
import { Form, Input, Button } from "reactstrap";
import { monitorServersServices } from "../../../_services/servicesWithSecondOrigin/index";
import { Constants } from "../Constants";
import { Validation } from "../../../_helpers/validation/Validation";
import { IoIosArrowRoundBack } from "react-icons/io";
import toastr from "toastr";
import {
  Error,
  MButton,
  Select,
  TooltipPro,
} from "../../CommonComponents/index";
import { AiOutlineInfoCircle } from "react-icons/ai";

const planArr = [
  { label: "Minimal", value: 1 },
  { label: "Basic", value: 2 },
  { label: "Advanced", value: 3 },
];
const MonitorServersForm = ({ ...props }) => {
  const [server_name, setServer_name] = useState("");
  const [server_description, setServer_description] = useState("");
  const [error, setError] = useState({});
  const [plan_id, setPlan] = useState("");

  useEffect(() => {
    if (props.update) {
      getDataFromServer(props.id);
    }
  }, [props.update, props.id]);

  const getDataFromServer = async (id) => {
    let response = await monitorServersServices.getMonitorServer(id);
    if (response && response.data) {
      if (response.data.id) {
        setServer_name(response.data.server_name);
        setServer_description(response.data.server_description);
      }
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    let data = {
      server_name: server_name,
      server_description: server_description,
    };
    if (!props.update) {
      data = { ...data, plan_id: plan_id };
    }
    let flag = 0;
    let errobj = Validation(data, Constants().rules);
    Object.keys(errobj).map((index) => {
      if (errobj[index] !== "") {
        flag = 1;
      }
    });

    setError(errobj);
    if (flag === 0) {
      let response = "";
      if (props.update) {
        response = await monitorServersServices.updateMonitorServers(
          props.id,
          data
        );
      } else {
        const res = await monitorServersServices.addMonitorServer(data);
        if (res?.data?.status >= 200 && res?.data?.status <= 298) {
          response = res.data;
        } else {
          res?.data?.errors?.plan_id &&
            setError({ plan_id: res.data?.errors?.plan_id });
        }
      }

      if (response) {
        if (response && response.message) {
          toastr.success(response.message);
        }
        if (response && response?.status === 200) {
          props.toggle(response);
          setServer_name("");
          setServer_description("");
          props.responseAfterAdd(response, {
            server_name,
            server_description,
            id: props.id,
          });
          setPlan("");
        }
      }
    }
  };

  return (
    <Fragment>
      <div className="card overflow-inherit">
        {props.popup ? (
          ""
        ) : (
          <div className="card-header justify-content-start">
            {props.update ? (
              <Button
                className="backBtn mr-3"
                onClick={() => props.history.push("/monitor-servers")}
              >
                <IoIosArrowRoundBack></IoIosArrowRoundBack>
              </Button>
            ) : null}
            <h3>{props.update ? "Update" : "Add"} Servers</h3>
          </div>
        )}
        <div className="p-4">
          <Form onSubmit={(e) => onSubmit(e)}>
            <div>
              <div className="d-flex align-items-center">
                <Input
                  placeholder="Server Name"
                  value={server_name}
                  onChange={(e) => setServer_name(e.target.value)}
                ></Input>
                <span className="ml-2">
                  <TooltipPro
                    className="bottom tsize-400"
                    tooltip={
                      <div className="row">
                        <div className="col-md-12">
                          <p>
                            Add a name that will help you identify the server
                            quickly. This name will be displayed on most of the
                            screens and dropdowns
                          </p>
                        </div>
                      </div>
                    }
                  >
                    <AiOutlineInfoCircle className="infoIcon" />
                  </TooltipPro>
                </span>
              </div>
              {error && error.server_name && error.server_name !== "" && (
                <Error error={error.server_name}></Error>
              )}
            </div>
            <div className="mt-3 d-flex align-items-center">
              <textarea
                className="form-control"
                placeholder="Server Description"
                value={server_description}
                onChange={(e) => setServer_description(e.target.value)}
              ></textarea>
              {error &&
                error.server_description &&
                error.server_description !== "" && (
                  <Error error={error.server_description}></Error>
                )}
              <span className="ml-2">
                <TooltipPro
                  className="bottom tsize-400"
                  tooltip={
                    <div className="row">
                      <div className="col-md-12">
                        <p>
                          Add any description that helps you get details of the
                          server.{" "}
                        </p>
                      </div>
                    </div>
                  }
                >
                  <AiOutlineInfoCircle className="infoIcon" />
                </TooltipPro>
              </span>
            </div>

            {!props.update && (
              <div className="mt-3">
                <div
                  className="d-flex align-items-center"
                  style={{ width: "94%" }}
                >
                  <Select
                    selectedOption={plan_id}
                    setSelectedOption={(e) => setPlan(e.value)}
                    options={planArr}
                    propsInput={{ placeholder: <div>Select Plan</div> }}
                  ></Select>
                  <span className="ml-2">
                    <TooltipPro
                      className="bottom tsize-400"
                      tooltip={
                        <div className="row">
                          <div className="col-md-12">
                            <p>
                              Select a plan that will help you identify the
                              server quickly. This name will be displayed on
                              most of the screens and dropdowns
                            </p>
                          </div>
                        </div>
                      }
                    >
                      <AiOutlineInfoCircle className="infoIcon" />
                    </TooltipPro>
                  </span>
                </div>
                {error && error.plan_id && error.plan_id !== "" && (
                  <Error error={error.plan_id}></Error>
                )}
              </div>
            )}

            <MButton
              label="Save"
              className="mt-3 primary-btn  btn-block"
              onClick={(e) => onSubmit(e)}
            ></MButton>
          </Form>
        </div>
      </div>
    </Fragment>
  );
};

export default MonitorServersForm;
