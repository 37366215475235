import React, { useState, useEffect } from 'react'
import { domainService } from '../../_services/DomainServices/domainServices'
import Accordion from '../../_components/DomainInformation/CloudFlareAccordion'


const CloudFireInformation = ({ data, ...props }) => {
    const [domainInformation, setdomainInformation] = useState("")

    useEffect(() => {
        getDomainInformation()
    }, [data]);

    const getDomainInformation = async () => {
        let dataObj = {
            "domain_name": data.DOMAIN_NAME
        }
        let response = await domainService.getDomainInformation(dataObj)
        if (response) {
            setdomainInformation(response)
        }
    }

    return <>
        {domainInformation && <Accordion
            domainInformation={domainInformation}
            setToggelValue={props.setToggelValue}
            toggle={props.toggle}
            index={props.index}
            myDomain={data.DOMAIN_NAME}
            DOMAIN_ID={data.DOMAIN_ID}
            {...props}
        ></Accordion>}
    </>
}

export default CloudFireInformation