import React, { useEffect, useState } from "react";
import moment from "moment";
import LineStackChart from "../Charts/LineStackChart";
import { graphService } from "../../_services/servicesWithSecondOrigin/index";
import { Loader } from "../../_components/CommonComponents/index";
const MySqlUsageChart = ({ ...props }) => {
  const [xAxis, setXAxis] = useState(false);
  const [yAxis, setyAxis] = useState(false);
  const [series, setSeries] = useState(false);
  const [setUnSet, setUnSetUI] = useState(true);
  const [dataSizeType, setDataSizeType] = useState("");
  const [loader, setLoader] = useState(true);
  const [legend, setLegend] = useState([]);
  const [LegendObj, setLegendObj] = useState(false);
  useEffect(() => {
    if (props.uuid) getGraphDataFromServer();
  }, [
    props.uuid,
    props.active,
    props.start_datetime,
    props.end_datetime,
    props.isRefresh,
    props.mytimezone,
    props.dbName,
    props.unique_id,
  ]);

  const getGraphDataFromServer = async () => {
    setSeries([
      {
        name: "",
        data: [],
        type: "line",
        smooth: true,
      },
    ]);
    setXAxis({
      type: "category",
      triggerEvent: true,
      data: [],
    });
    var d = new Date();
    var n = d.getTimezoneOffset();
    setLoader(true);
    let data = {
      uuid: props.uuid,
      user_element: props.user_element,
      start_datetime: props.start_datetime
        ? props.start_datetime
        : moment(new Date())
            .add(-props.active || -4, "hours")
            .format("YYYY-MM-DD HH:mm:ss"),
      end_datetime: props.end_datetime
        ? props.end_datetime
        : moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      timezone: props.mytimezone,
      timediff: n,
    };

    if (props.unique_id) {
      data.unique_id = props.unique_id;
    }

    let response = {};
    response = await graphService.getMysqlChartGraphData(data, props.type);

    if (response && (response.data || response.finalData)) {
      setUnSetUI(false);
      if (response.data && Array.isArray(response.data.timestamp)) {
        setXAxis({
          type: "category",
          data: response.data.timestamp,
        });
      } else if (Array.isArray(response.finalData.final_timeStamp)) {
        setXAxis({
          type: "category",
          data: response.finalData.final_timeStamp,
        });
      } else {
        setXAxis({
          type: "category",
          triggerEvent: true,
          data: [],
        });
      }
      const Series = [];
      let inDataSize = "";
      let SeriesType = [];
      if (response && response.data) {
        Array.isArray(props.SeriesType) &&
          props.SeriesType.map((object) => {
            if (Array.isArray(response.data[object])) {
              setDataSizeType("");
              SeriesType.push(object);
              response.data[object].map((check, index) => {
                if (props.type === "mysql-read-write-data") {
                  inDataSize = "KB";
                  response.data[object][index] = (
                    parseInt(check) /
                    1024 ** 1
                  ).toFixed(2);
                  setDataSizeType(inDataSize);
                } else {
                  response.data[object][index] = parseFloat(check).toFixed(2);
                }

                if (props.type === "total-latency") {
                  setDataSizeType(" s");
                } else if (props.type === "mysql-latency") {
                  setDataSizeType("ms");
                }
              });
            }
            // if (props.isLegend) {
            //     setLegend(props.SeriesType)
            // }
            Array.isArray(response.data[object]) &&
              Series.push({
                name: object,
                data: response.data[object],
                type: "line",
                smooth: true,
              });
          });
        if (props.isLegend) {
          setLegend(SeriesType);
        }
      } else if (response && response.finalData && response.finalData.data) {
        let SeriesTypeObjToArr = Array.isArray(
          Object.keys(response.finalData.data)
        )
          ? Object.keys(response.finalData.data)
          : [];
        let tempCount = 0;
        SeriesTypeObjToArr.sort((a, b) =>
          response.finalData.data[a][0] < response.finalData.data[b][0]
            ? 1
            : response.finalData.data[a][0] > response.finalData.data[b][0]
            ? -1
            : 0
        );
        SeriesTypeObjToArr.map((object, _index) => {
          let checktable = 0;
          if (Array.isArray(response.finalData.data[object])) {
            setDataSizeType("");
            response.finalData.data[object].map((check, index) => {
              response.finalData.data[object][index] = check
                ? parseFloat(check).toFixed(2)
                : "";
              if (check) {
                checktable = 1;
              }
            });

            if (
              props.dbName &&
              Array.isArray(props.dbName) &&
              props.dbName.indexOf(object) < 0
            ) {
              checktable = 0;
            }
            if (props.type === "total-latency") {
              setDataSizeType(" s");
            } else if (props.type === "mysql-latency") {
              setDataSizeType("ms");
            }
          }

          if (props.isLegend) {
            setLegend(SeriesTypeObjToArr);
            setLegendObj({
              data: props.legend,
              type: "scroll",
              horizontalAlign: "left",
              right: 400,
              left: 100,
              padding: 50,
            });
          }

          if (props.onTotalLatencyChart) {
            if (checktable === 1 && tempCount < (props.limit || 10)) {
              tempCount += 1;
              Array.isArray(response.finalData.data[object]) &&
                Series.push({
                  name: object,
                  data: response.finalData.data[object],
                  type: "line",
                  smooth: true,
                });
            }
          } else {
            checktable === 1 &&
              Array.isArray(response.finalData.data[object]) &&
              Series.push({
                name: object,
                data: response.finalData.data[object],
                type: "line",
                smooth: true,
              });
          }
        });
      }
      setUnSetUI(false);
      if (Series.length === 0) {
        setSeries([
          {
            name: "",
            data: [0],
            type: "line",
            smooth: true,
          },
        ]);
      } else {
        setSeries(Series);
      }
      setLoader(false);
      setUnSetUI(true);
    } else {
      setUnSetUI(false);
      setXAxis({
        type: "category",
        triggerEvent: true,
        data: [],
      });
      setSeries([
        {
          name: "",
          data: [0],
          type: "line",
          smooth: true,
        },
      ]);
      setLoader(false);
      setUnSetUI(true);
    }
  };

  return (
    <>
      <div className="chart-loader">
        {" "}
        <Loader loader={loader}></Loader>
      </div>
      {setUnSet && (
        <LineStackChart
          xAxis={xAxis}
          yAxis={yAxis}
          series={series}
          dataSizeType={dataSizeType}
          legend={legend}
          legendObj={LegendObj}
          {...props}
        ></LineStackChart>
      )}
    </>
  );
};
export default MySqlUsageChart;
