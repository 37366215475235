import React, { useState, useEffect, useMemo } from "react";
import { monitorServersServices } from "../../_services/servicesWithSecondOrigin/index";
import { ChartDatabaseSchemaOperation } from "../../_components/MonitorServerChart/index";
import { Loader, TooltipPro } from "../../_components/CommonComponents/index";
import { AiOutlineInfoCircle } from "react-icons/ai";
import SearchBootstrapTable from "../BootstrapTable/SearchBootstrapTable";
const DatabaseSchema = (props) => {
  const [dataObj, setDataObj] = useState("");
  const [loader, setLoader] = useState(true);
  const [errorData, setErrorData] = useState("");

  const keyList = useMemo(
    () => [
      {
        dataField: "table_name",
        text: "Table Name",
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: "03px" };
        },
      },
      {
        dataField: "table_type",
        text: "Table Type",
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: "03px" };
        },
      },
      {
        dataField: "table_comment",
        text: "Table Comment",
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: "03px" };
        },
      },
    ],
    []
  );

  useEffect(() => {
    if (props.id) {
      getData();
    }
  }, [props.id, props.unique_id]);

  const getData = async () => {
    setLoader(true);
    setDataObj("");
    let data = {
      resource_id: props.id,
    };

    if (props.limit) data.limit = props.limit;

    if (props.unique_id) {
      data.unique_id = props.unique_id;
    }
    let response = await monitorServersServices.getDatabaseSchema(data);
    if (
      response &&
      response.data &&
      typeof response.data === "object" &&
      Object.keys(response.data).length !== 0
    ) {
      setDataObj(response.data);
      setErrorData(response?.errorData);
      setLoader(false);
    } else {
      setLoader(false);
    }
  };

  const onClick = () => {
    if (!props.details)
      props.history.push("/monitor-server/mysql-charts", {
        user_element: "",
        title: "",
        id: props.id,
        uuid: props.uuid,
        update: true,
        server_name: props.server_name,
        mytimezone: props.mytimezone,
        chart: 2,
      });
  };

  return (
    <div className="card overflow-inherit">
      <div className="chart-loader">
        {" "}
        <Loader loader={loader}></Loader>
      </div>
      <div
        style={props.styleObj ? props.styleObj : {}}
        className="subTitleOuter card-header justify-content-start"
      >
        <h2 className="sectionHeading mr-3">Database Schema</h2>
        <TooltipPro
          className="bottom tsize-400"
          style={{ zIndex: 20 }}
          tooltip={
            <div className="row">
              <div className="col-md-12">
                <p>
                  This displays the total number of rows in a table in the
                  databases. If you have multiple schemas in the DB, it shows
                  data of all the schema it has access to. Only top 10 tables
                  with maximum number of rows is displayed. With this widget you
                  can see how tables are growing over time, and if you see
                  latency increasing on the tables along with increase in number
                  of rows, you may need to optimise data or archive historical
                  data (not in active usage).
                </p>
              </div>
            </div>
          }
        >
          <AiOutlineInfoCircle className="infoIcon" />
        </TooltipPro>
      </div>
      <div style={props.styleObj ? props.styleObj : {}} className="row px-3">
        {dataObj && Array.isArray(Object.keys(dataObj)) ? (
          Object.keys(dataObj).map((key) => {
            console.log(errorData[key]);

            return (
              <div
                className={props.details ? "col-md-12" : "col-md-6"}
                onClick={() => onClick()}
              >
                {props.details &&
                  Array.isArray(errorData[key]) &&
                  errorData[key].length !== 0 && (
                    <div
                      className="card schema-table"
                      style={
                        !props.opts?.height
                          ? undefined
                          : { height: props.opts.height + 50 }
                      }
                    >
                      <div className="card-header">
                        <h3>{key}</h3>
                      </div>
                      <div
                        className={
                          props.opts?.height ? undefined : "chartInnerDiv"
                        }
                      >
                        {/* {JSON(errorData[key])} */}
                        <SearchBootstrapTable
                          data={errorData[key]}
                          columns={keyList}
                          searchFlag={false}
                          className={"bhhh"}
                        />
                      </div>
                    </div>
                  )}

                <div
                  className="card"
                  style={
                    !props.opts?.height
                      ? undefined
                      : { height: props.opts.height + 50 }
                  }
                >
                  <div className="card-header">
                    <h3>{key}</h3>
                  </div>
                  <div
                    className={props.opts?.height ? undefined : "chartInnerDiv"}
                  >
                    <ChartDatabaseSchemaOperation
                      tooltip={true}
                      toolbox={true}
                      chartData={dataObj[key]}
                      //isRefresh={this.state.isRefresh}
                      {...props}
                    />
                  </div>
                </div>
              </div>
            );
            // }):"No Data Found"}
          })
        ) : (
          <div className="col-md-6">
            {loader ? (
              <div className="alert alert-danger">Loading...</div>
            ) : (
              <div className="alert alert-danger">No Data Found</div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default DatabaseSchema;
