import React from "react";
import moment from "moment";
import { Button, ModalHeader, Modal, ModalBody } from "reactstrap";
import { IoIosArrowRoundBack } from "react-icons/io";
import { MdRefresh, MdRemoveRedEye } from "react-icons/md";

import Header from "../../_components/CommonComponents/Header/Header";
import LineStackChart from "../../_components/Charts/LineStackChart";
import DatePicker from "../../_components/CommonComponents/DatePicker/DatePicker";
import {
  DateTime,
  Pagination,
  Select,
  ServerCurrentLoggedIn,
  TooltipPro,
} from "../../_components/CommonComponents/index";
import { AiOutlineEdit, AiOutlineInfoCircle } from "react-icons/ai";
import { monitorServersServices } from "../../_services/servicesWithSecondOrigin";
import { Loader } from "../../_components/CommonComponents/index";
import cronstrue from "cronstrue";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import toastr from "toastr";
import { objectToQuery } from "../../_helpers/Common/Common";
const initialSearchForm = Object.freeze({
  name: "",
  command: "",
  start_time: "",
  end_time: "",
  duration: "",
  status: 2,
  cron_uid: null,
  resource_id: null,
  pageSize: 10,
  pageNumber: 1,
});

const states = {
  cron_uid: "",
  uuid: "",
  title: "",
  chart: "",
  user_element: "",
  server_name: "",
  mytimezone: "",
  // start_datetime: moment(new Date()).add(-4, "hours").format("YYYY-MM-DD hh:mm:ss"),
  // end_datetime: moment(new Date()).format("YYYY-MM-DD hh:mm:ss"),
  start_datetime: null,
  end_datetime: null,
  type: "",
  unique_id: null,
  echartRefObj: {},
  activeTab: 1,
  apachheReq: false,
  apachheFirstReq: true,
  show: false,
  logs: [],
  totalCount: 0,

  chartData: {
    xAxis: {
      type: "category",
      triggerEvent: true,
      data: [],
    },
    series: [
      {
        name: "cron_jobs",
        data: [0],
        type: "line",
        smooth: true,
      },
    ],
    legend: ["cron_jobs"],
  },
  isLoading: false,
};

class MonitorSettingCron extends React.Component {
  initialSearchForm = {
    ...initialSearchForm,
  };
  constructor(props) {
    super(props);

    const { CRON_UID, resource_id } = props.location.state;

    this.initialSearchForm.cron_uid = CRON_UID;
    this.initialSearchForm.resource_id = resource_id;
    this.state = {
      ...states,
      searchForm: {
        ...this.initialSearchForm,
      },
      ID: "",
      cronLoader: false,
      loader: false,
      refreshTime: true,
      Command: "",
      Name: "",
      onRefresh: "",
      job: "",

      showCron: false,
      cronForm: {
        id: null,
        name: "",
        description: "",
      },
      cronPagination: {
        pageSize: 10,
        pageNumber: 1,
        total: 0,
      },
    };
  }

  async componentDidMount() {
    const { CRON_UID, resource_id, ID, Command, Name } =
      this.props.location.state;
    this.getCronJobs(resource_id);
    if (!CRON_UID) return this.props.history.push("/monitor-servers");
    this.setState(
      {
        cron_uid: this.props.location.state.CRON_UID,
        ID: ID,
        Command,
        Name,
        job: this.props.location.state,
      },
      () => {
        this.callApi();
      }
    );
  }

  onChangeSearch = ({ target: { name, value } }) =>
    this.setState((prevState) => ({
      ...prevState,
      searchForm: { ...prevState.searchForm, [name]: value },
    }));

  onChangePageSize = (size) =>
    this.setState(
      (prevState) => ({
        ...prevState,
        searchForm: { ...prevState.searchForm, pageSize: size.value },
      }),
      this.getCronLogs
    );
  onChangePageNumber = (number) =>
    this.setState(
      (prevState) => ({
        ...prevState,
        searchForm: { ...prevState.searchForm, pageNumber: number },
      }),
      this.getCronLogs
    );
  onResetSearch = () =>
    this.setState(
      (prevState) => ({
        ...prevState,
        searchForm: { ...this.initialSearchForm },
        start_datetime: null,
        end_datetime: null,
      }),
      this.callApi
    );
  onSubmitSearch = () => {
    this.setState(
      (prev) => ({
        ...prev,
        totalCount: 0,
        searchForm: { ...prev.searchForm, pageNumber: 1 },
      }),
      this.callApi
    );
  };
  // this.getCronLogs);

  getCronLogs = async () => {
    var d = new Date();
    this.setState({ loader: true });
    let data = {
      ...this.state.searchForm,
      duration:
        this.state.searchForm.duration !== ""
          ? parseInt(this.state.searchForm.duration)
          : "",
      status:
        this.state.searchForm.status !== ""
          ? parseInt(this.state.searchForm.status)
          : "",
      start_time: this.state.start_datetime ? this.state.start_datetime : "",
      end_time: this.state.end_datetime ? this.state.end_datetime : "",
      cron_uid: this.state.cron_uid,
      timediff: d.getTimezoneOffset(),
      timezone: localStorage.getItem("Timezone"),
    };

    const logsResponse = await monitorServersServices.getMonitorServerCronLogs(
      data
    );

    this.setState({
      logs: Array.isArray(logsResponse.data) ? logsResponse.data : [],
      totalCount:
        logsResponse._meta?.totalCount || logsResponse.data?.length || 0,
      loader: false,
    });
  };

  getCronJobs = async (id, update) => {
    this.setState({ cronLoader: true });
    const response = await monitorServersServices.getMonitorServerCronList({
      resource_id: id,
    });
    if (Array.isArray(response.data)) {
      let updateJob = {};
      response.data.map((data) => {
        data.label = data.Name ? data.Name : data.Command;
        data.value = data.ID;
        if (update && this.state.job?.Command === data.Command) {
          updateJob = {
            job: data,
          };
        }
      });

      this.setState((prevState) => ({
        ...prevState,
        cronJobs: response.data,
        cronLoader: false,
        ...updateJob,
      }));
    } else {
      this.setState({ cronLoader: false });
    }
  };

  callApi = async () => {
    this.setState({ isLoading: true });
    this.getCronLogs();
    const { CRON_UID, uuid, server_name, id } = this.props.location.state;

    const timeZone = localStorage.getItem("Timezone");

    const chartResponse =
      await monitorServersServices.getMonitorServerCronLogChartData({
        cron_uid: this.state.cron_uid,
        start_datetime: this.state.start_datetime
          ? this.state.start_datetime
          : moment(new Date(Date.now() - 1000 * 60 * 60 * 24)).format(
              "YYYY-MM-DD hh:mm:ss"
            ),
        end_datetime: this.state.end_datetime
          ? this.state.end_datetime
          : moment().format("YYYY-MM-DD hh:mm:ss"),
        timezone: timeZone,
        timediff: new Date().getTimezoneOffset(),
      });

    this.setState({
      id,
      isLoading: false,
      //  cron_uid: CRON_UID,
      uuid,
      server_name,
      chartData: {
        xAxis: {
          type: "category",
          triggerEvent: true,
          data: chartResponse?.data?.timestamp || [],
        },
        legend: ["cron_jobs"],
        series: [
          {
            name: "cron_jobs",
            data: chartResponse?.data?.executiontime || [],
            type: "line",
            smooth: true,
          },
        ],
      },
    });
  };

  onSelectUniqueId = (e) => {
    this.setState({
      unique_id: e.value,
    });
  };

  toggle = () => {
    this.setState((prevState) => ({ ...prevState, show: !prevState.show }));
  };

  setStartDateTime = (date) => {
    this.setState(
      {
        start_datetime: moment(date).format("YYYY-MM-DD hh:mm:ss"),
      },
      () => {
        var a = moment(this.state.end_datetime, "YYYY-MM-DD hh:mm:ss");
        var b = moment(this.state.start_datetime, "YYYY-MM-DD hh:mm:ss");
        let c = a.diff(b, "days");
        if (c > 5) {
          this.setState({
            end_datetime: b.add(5, "d").format("YYYY-MM-DD hh:mm:ss"),
          });
        }
      }
    );
  };

  setEndDateTime = (date) => {
    this.setState(
      {
        end_datetime: moment(date).format("YYYY-MM-DD hh:mm:ss"),
      },
      () => {
        var a = moment(this.state.end_datetime, "YYYY-MM-DD hh:mm:ss");
        var b = moment(this.state.start_datetime, "YYYY-MM-DD hh:mm:ss");
        let c = a.diff(b, "days");
        if (c > 5) {
          this.setState({
            start_datetime: a.add(-5, "d").format("YYYY-MM-DD hh:mm:ss"),
          });
        }
      }
    );
  };

  filterPassedStartDate = (time) => {
    if (this.state.end_datetime) {
      const currentDate = new Date();
      const selectedDate = new Date(time);
      const end_datetime = new Date(moment(this.state.end_datetime));
      return currentDate > selectedDate && end_datetime >= selectedDate;
    } else {
      const currentDate = new Date();
      const selectedDate = new Date(time);
      return (
        currentDate > selectedDate &&
        selectedDate >= currentDate.setMonth(currentDate.getMonth() - 1)
      );
    }
  };

  filterPassedEndDate = (time) => {
    const currentDate = new Date();
    const start_datetime = new Date(moment(this.state.start_datetime));
    const selectedDate = new Date(time);
    return currentDate > selectedDate && start_datetime <= selectedDate;
  };

  onReset = () => {
    this.setState(
      {
        ...states,
        start_datetime: null,
        end_datetime: null,
        refreshTime: false,
      },
      () => {
        this.setState({ refreshTime: true });
        this.callApi();
      }
    );
  };

  refreshData = () => {
    this.setState({ onRefresh: Math.random() });
  };

  updateCronJob = async () => {
    const { id, name, description } = this.state.cronForm;

    const errors = {};

    if (!name) errors.nameError = "Name is required.";
    // if (!description) errors.descriptionError = "Description is required.";

    if (
      !name
      // || !description
    )
      return this.setState((prevState) => ({
        ...prevState,
        cronForm: { ...prevState.cronForm, ...errors },
      }));

    const response = await monitorServersServices.updateMonitorServerCronJob({
      id,
      name,
      description,
    });

    if (response?.status !== 200)
      return toastr.warning(response?.message || "Something went wrong.");

    toastr.success(response.message || "Updated successfully.");
    this.getCronJobs(this.state.job.resource_id, "update");
    this.setState((prevState) => ({
      ...prevState,
      cronForm: { id: null, name: "", description: "" },
      showCron: false,
      cronJobs: prevState.cronJobs.map((c) =>
        c.ID !== id
          ? c
          : { ...c, Name: name ?? "", Description: description ?? "" }
      ),
    }));
  };

  toggleEditCron = (data) => {
    this.setState((prevState) => ({
      ...prevState,
      showCron: !prevState.showCron,
      cronForm: {
        id: data.ID,
        name: data.Name ?? "",
        description: data.Description ?? "",
      },
    }));
  };

  render() {
    const { searchForm, totalCount, loader, cronLoader } = this.state;
    const { Command, Name, Description } = this.props.location.state || {};
    let Timezone = localStorage.getItem("Timezone");
    let object = {
      id: this.state.id,
      uuid: this.state.uuid,
      server_name: this.state.server_name,
      activeTab: 4,
    };
    return (
      <>
        <div className="container">
          <Header
            history={this.props.history}
            {...this.props}
            callRefresh={this.refreshData}
          />
          <div className="serverDetailsSec justify-content-between">
            <div className="d-flex pt-3">
              <Button
                className="backBtn mr-4"
                onClick={() =>
                  this.props.history.push(
                    "/monitor-server/charts" + objectToQuery(object),
                    object
                  )
                }>
                <IoIosArrowRoundBack />
              </Button>
              <ServerCurrentLoggedIn
                server_name={this.state.server_name}
                uuid={this.state.uuid}
                onSelectServer={this.onSelectServer}
                mytimezone={Timezone}
                unique_id={this.state.unique_id}
                onSelectUniqueId={this.onSelectUniqueId}
                noUIRender
              />
              <div className="d-flex">
                <strong>
                  SERVER: {this.props.location?.state?.server_name}
                </strong>
                <p className="ml-3">
                  {" "}
                  <strong>UUID: </strong>
                  {this.props.location?.state?.uuid}
                </p>
              </div>
            </div>
            <div className="select_startdatetime">
              {/* <div className="row align-items-end">
                                <div className="col-md-4">
                                    <label>Start Date Time</label>
                                    <DateTime dateTime={this.state.start_datetime} setDateTime={this.setStartDateTime} filterPassedDate={this.filterPassedStartDate}></DateTime>
                                </div>
                                <div className="col-md-4">
                                    <label>End Date Time</label>
                                    <DateTime dateTime={this.state.end_datetime} setDateTime={this.setEndDateTime} filterPassedDate={this.filterPassedEndDate}></DateTime>
                                </div>
                                <div className="col-md-3 d-flex" style={{ paddingBottom: "1px" }}>
                                    <Button className="iconBtn">
                                        <MdRemoveRedEye onClick={() => this.callApi()} />
                                    </Button>
                                    <Button className="iconBtn ml-3">
                                        <MdRefresh onClick={() => this.onReset()} />
                                    </Button>
                                </div>
                            </div> */}
            </div>
          </div>

          {/* {<div className="card overflow-inherit">
                        <div className="card-header">

                            {<div className="d-flex serverDetailsHead w-100 position-relative">
                                <h3 className="mt-2 tt-n">Select CRON job:&nbsp; </h3>
                                <div style={{ minWidth: '250px', width: '84%' }}>

                                    <Select
                                        selectedOption={this.state.ID}
                                        setSelectedOption={e => this.setState({ cron_uid: e.CRON_UID, logs: [], chartData: states.chartData, ID: e.ID, Name: e.Name, Command: e.Command }, () => { this.callApi() })}
                                        options={this.state.cronJobs}
                                    ></Select>
                                </div>
                                <div style={{ marginLeft: '-18px' }} className="crone-dropdown-spinner"><Loader loader={cronLoader}></Loader> </div>
                            </div>}
                        </div>
                    </div>} */}

          <div
            className="card charts-screen-data overflow-inherit"
            id="awsView">
            <div className="card-header">
              {
                <div className="d-flex serverDetailsHead w-100 position-relative">
                  <h3 className="mt-2 tt-n">Select CRON job:&nbsp; </h3>
                  <div style={{ minWidth: "250px", width: "84%" }}>
                    <Select
                      selectedOption={this.state.ID}
                      setSelectedOption={(e) =>
                        this.setState(
                          {
                            cron_uid: e.CRON_UID,
                            logs: [],
                            chartData: states.chartData,
                            ID: e.ID,
                            Name: e.Name,
                            Command: e.Command,
                            job: e,
                          },
                          () => {
                            this.callApi();
                          }
                        )
                      }
                      options={this.state.cronJobs}></Select>
                  </div>
                  <div
                    style={{ marginLeft: "-18px" }}
                    className="crone-dropdown-spinner">
                    <Loader loader={cronLoader}></Loader>{" "}
                  </div>
                </div>
              }

              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>Edit Details</Tooltip>}>
                <AiOutlineEdit
                  className="mr-1 actionIcon"
                  size={27}
                  onClick={() => this.toggleEditCron(this.state.job)}
                />
              </OverlayTrigger>
            </div>
            {this.state.Command ? (
              <div className="serverDetails">
                <div className="sDetailsCol">
                  <label>Command:</label>
                  <strong>{this.state.Command}</strong>
                </div>
              </div>
            ) : null}

            <div className="serverDetails">
              <div className="row">
                <div className="col-md-6">
                  <div className="sDetailsCol">
                    <label>Description:</label>
                    <strong>{this.state.job?.Description}</strong>
                  </div>

                  <div className="sDetailsCol">
                    <label>Schedule:</label>
                    <strong>{this.state.job?.Cron_Schedule}</strong>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="sDetailsCol">
                    <label>CRON user:</label>
                    <strong>{this.state.job?.user_name}</strong>
                  </div>
                  <div className="sDetailsCol">
                    <label>Schedule Description:</label>
                    <strong>
                      {this.state.job.Cron_Schedule
                        ? `Runs ${cronstrue.toString(
                            this.state.job.Cron_Schedule
                          )}`
                        : ""}
                    </strong>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="card overflow-inherit">
                <div className="card-body">
                  <div className="row bckupfolderChange">
                    <div className="col-md-4 mt-2">
                      <label>Start Time</label>
                      <TooltipPro
                        className="bottom tsize-300 ml-1"
                        tooltip={
                          <div className="row">
                            <div className="col-md-12">
                              <p>
                                <strong> For help </strong>
                                Text will reflect soon
                              </p>
                            </div>
                          </div>
                        }>
                        <AiOutlineInfoCircle className="infoIcon" />
                      </TooltipPro>
                      {/* <DatePicker
                                                dateTime={this.state.searchForm.start_time}
                                                setDateTime={(date) => {
                                                    this.setState({
                                                        start_datetime: moment(date).format("YYYY-MM-DD hh:mm:ss")
                                                    })
                                                    this.onChangeSearch({ target: { name: "start_time", value: moment(date).format("YYYY-MM-DD hh:mm:ss") } });

                                                }}
                                            /> */}
                      {this.state.refreshTime ? (
                        <DateTime
                          className="form-control"
                          dateTime={this.state.start_datetime}
                          setDateTime={this.setStartDateTime}
                          filterPassedDate={
                            this.filterPassedStartDate
                          }></DateTime>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="col-md-4 mt-2">
                      <label>End Time</label>
                      <TooltipPro
                        className="bottom tsize-300 ml-1"
                        tooltip={
                          <div className="row">
                            <div className="col-md-12">
                              <p>
                                <strong> For help </strong>
                                Text will reflect soon
                              </p>
                            </div>
                          </div>
                        }>
                        <AiOutlineInfoCircle className="infoIcon" />
                      </TooltipPro>
                      {/* <DatePicker
                                                dateTime={this.state.searchForm.end_time}
                                                setDateTime={(date) => {
                                                    this.setState({
                                                        end_datetime: moment(date).format("YYYY-MM-DD hh:mm:ss")
                                                    })
                                                    this.onChangeSearch({ target: { name: "end_time", value: moment(date).format("YYYY-MM-DD hh:mm:ss") } });
                                                }}
                                            /> */}
                      {this.state.refreshTime ? (
                        <DateTime
                          dateTime={this.state.end_datetime}
                          setDateTime={this.setEndDateTime}
                          filterPassedDate={this.filterPassedEndDate}
                          className="form-control"></DateTime>
                      ) : null}
                    </div>
                    <div className="col-md-4 mt-2">
                      <label>Duration</label>
                      <TooltipPro
                        className="bottom tsize-300 ml-1"
                        tooltip={
                          <div className="row">
                            <div className="col-md-12">
                              <p>
                                <strong> For help </strong>
                                Text will reflect soon
                              </p>
                            </div>
                          </div>
                        }>
                        <AiOutlineInfoCircle className="infoIcon" />
                      </TooltipPro>
                      <input
                        placeholder="Duration"
                        className="form-control"
                        name="duration"
                        value={searchForm.duration}
                        onChange={this.onChangeSearch}
                        type="text"
                      />
                    </div>
                    <div className="col-md-4 mt-2">
                      <label>Status</label>
                      <TooltipPro
                        className="bottom tsize-300 ml-1"
                        tooltip={
                          <div className="row">
                            <div className="col-md-12">
                              <p>
                                <strong> For help </strong>
                                Text will reflect soon
                              </p>
                            </div>
                          </div>
                        }>
                        <AiOutlineInfoCircle className="infoIcon" />
                      </TooltipPro>
                      <select
                        className="form-control"
                        name="status"
                        value={searchForm.status}
                        onChange={this.onChangeSearch}
                        type="text">
                        <option value={2}>Select</option>
                        <option value={1}>Success</option>
                        <option value={0}>Error</option>
                      </select>
                    </div>
                  </div>
                  <div className="mt-3">
                    <Button
                      className="btn-primary mr-2"
                      type="button"
                      onClick={this.onSubmitSearch}>
                      Search
                    </Button>
                    <Button type="button" onClick={this.onResetSearch}>
                      Reset
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-7 col-12">
              <table className="table table-bordered mb-0">
                <thead>
                  <tr>
                    {/* <th scope="col">CRON Name</th>
                                        <th scope="col">Command</th> */}
                    <th scope="col">Start Time</th>
                    <th scope="col">End Time</th>
                    <th scope="col">Duration (Seconds)</th>
                    <th scope="col">Status</th>
                    <th scope="col">Message</th>
                  </tr>
                </thead>
                <tbody>
                  <div className="chart-loader">
                    {" "}
                    <Loader loader={loader}></Loader>
                  </div>
                  {this.state.logs.map((d, index) => (
                    <tr key={index}>
                      {/* <td>{d.name || Name}</td>
                                            <td>{d.command || Command}</td> */}
                      <td>{d.Start_time}</td>
                      <td>{d.End_time}</td>
                      <td>{d.Execution_time}</td>
                      <td>{d.Status == 0 ? "Error" : "Success"}</td>
                      <td>{d.Message}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {this.state.logs.length !== 0 ? (
                <div className="paginationSec">
                  <div className="totalPage">
                    <Select
                      selectedOption={searchForm.pageSize}
                      setSelectedOption={this.onChangePageSize}
                      options={[
                        { label: 10, value: 10 },
                        { label: 30, value: 30 },
                        { label: 50, value: 50 },
                        { label: 70, value: 70 },
                        { label: 100, value: 100 },
                      ]}></Select>
                  </div>
                  <Pagination
                    onClickPage={this.onChangePageNumber}
                    pageCount={Math.ceil(totalCount / searchForm.pageSize) || 1}
                    currentPage={searchForm.pageNumber}></Pagination>
                </div>
              ) : (
                !this.state.isLoading &&
                !this.state.loader && (
                  <div
                    style={{
                      textAlign: "center",
                      marginTop: "10%",
                      color: "#cf55aa",
                      fontSize: "27px",
                    }}>
                    No Record Available
                  </div>
                )
              )}
            </div>
            <div className="col-lg-5 col-12">
              <div className="card overflow-visible">
                <div className="card-header justify-content-start">
                  <h3 className="mr-3">CRON Chart</h3>
                  <TooltipPro
                    className="bottom tsize-400"
                    style={{ zIndex: 10 }}
                    tooltip={
                      <div className="row">
                        <div className="col-md-12">
                          <p>
                            This shows is SWAP is setup on the server. Also it
                            shows Total SWAP setup and how much has been used.
                          </p>
                        </div>
                      </div>
                    }>
                    <AiOutlineInfoCircle className="infoIcon" />
                  </TooltipPro>
                </div>
                <div className="chartInnerDiv">
                  <div className="chart-loader">
                    {" "}
                    <Loader loader={loader}></Loader>
                  </div>
                  <LineStackChart
                    tooltip={true}
                    toolbox={true}
                    xAxis={this.state.chartData.xAxis}
                    yAxis={this.state.chartData.yAxis}
                    series={this.state.chartData.series}
                    legend={this.state.chartData.legend}
                    dataSizeType="s"
                    onRefresh={this.state.onRefresh}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal isOpen={this.state.showCron} toggle={this.toggleEditCron}>
          <ModalHeader toggle={this.toggleEditCron}>
            Edit CRON Details
          </ModalHeader>
          <ModalBody>
            <div className="row">
              <div className="col-12">
                <div className="form-group">
                  <label>CRON Name</label>
                  <input
                    className="form-control"
                    placeholder="Enter CRON Name"
                    onChange={({ target: { name, value } }) =>
                      this.setState((prev) => ({
                        ...prev,
                        cronForm: { ...prev.cronForm, [name]: value },
                      }))
                    }
                    name="name"
                    value={this.state.cronForm.name ?? ""}
                  />
                  <span className="small text-danger">
                    {this.state.cronForm.nameError}
                  </span>
                </div>
              </div>
              <div className="col-12">
                <div className="form-group">
                  <label>Description</label>
                  <input
                    className="form-control"
                    placeholder="Enter Description"
                    onChange={({ target: { name, value } }) =>
                      this.setState((prev) => ({
                        ...prev,
                        cronForm: { ...prev.cronForm, [name]: value },
                      }))
                    }
                    name="description"
                    value={this.state.cronForm.description ?? ""}
                  />
                  <span className="small text-danger">
                    {this.state.cronForm.descriptionError}
                  </span>
                </div>
              </div>
              <div className="col-12">
                <div className="text-right">
                  <button
                    className="btn btn-lg btn-success"
                    type="button"
                    onClick={this.updateCronJob}>
                    Save
                  </button>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </>
    );
  }
}

export default MonitorSettingCron;
