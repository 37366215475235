const socialMedia = [{
    label:'Facebook',
    value:'facebook'
},
{
    label:'Twitter',
    value:'twitter'
},{
    label:'Instagram',
    value:'instagram'
},{
    label:'Linkedin',
    value:'linkedin'
}]

export const Constants ={
    socialMedia:socialMedia
}