import React, { useState, useEffect } from "react";
import { Input } from "reactstrap";
import { Modal, Button } from "react-bootstrap";
import { MdShare } from "react-icons/md";
import { monitorServersServices } from "../../../_services/servicesWithSecondOrigin/index";
import { IoIosRemoveCircleOutline } from "react-icons/io";
import { confirmAlert } from "react-confirm-alert";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import toastr from "toastr";

const ServerShare = ({ ...props }) => {
  const [email, setEmail] = useState("");
  const [show, setShow] = useState(false);
  const [sharedWithArray, setSharedWithArray] = useState([]);
  const onsubmit = () => {
    props.share(props.id, email, callBack);
  };

  const sharedWithFunc = async () => {
    let response = await monitorServersServices.monitorServerSharedWith(
      props.uuid
    );
    if (response && Array.isArray(response.data)) {
      setSharedWithArray(response.data);
    }
    setShow(true);
  };

  const monitorServerSharedWithRemoveFromOther = async (obj) => {
    let data = {
      receiver_company_id: obj.company_id,
      target_id: props.uuid,
    };
    let response =
      await monitorServersServices.monitorServerSharedWithRemoveFromOther(data);
    if (response && response.message) {
      toastr.success(response.message);
      sharedWithFunc();
    }

    // confirmAlert({
    //     title: 'Confirm to Delete',
    //     message: <p>Are you sure to do this.</p>,
    //     buttons: [
    //         {
    //             label: 'Yes',
    //             onClick: async () => {
    //                 let data = {
    //                     "receiver_company_id": obj.company_id,
    //                     "target_id": props.uuid
    //                 }
    //                 let response = await monitorServersServices.monitorServerSharedWithRemoveFromOther(data)
    //                 if (response && response.message) {
    //                     toastr.success(response.message)
    //                     sharedWithFunc()
    //                 }
    //             }
    //         },
    //         {
    //             label: 'No',
    //             onClick: () => console.log("No")
    //         }
    //     ]
    // });
  };

  const callBack = () => {
    setEmail("");
    setShow(false);
  };

  return (
    <>
      <div>
        <OverlayTrigger
          placement={props.placement ? props.placement : "top"}
          overlay={<Tooltip>Share Server</Tooltip>}
        >
          <MdShare
            className={props.className}
            onClick={() => sharedWithFunc(true)}
          />
        </OverlayTrigger>
        {/* <Modal show={show} onHide={() => setShow(false)} {...props}> */}
        <Modal show={show} onHide={() => setShow(false)}>
          <Modal.Header closeButton>
            <h1 className="modal-title">Share ({props.server_name})</h1>
          </Modal.Header>
          <Modal.Body>
            <p>
              You can share the server data access with others. They will be
              able to view and edit details of the server on g8keeper. Only
              share with people you know. It will only work if they already have
              account on g8keeper. You can revoke access anytime you choose to
              do so.
            </p>
            <Input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter Email"
            ></Input>
            <div className="mt-3 text-right">
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => onsubmit()}
              >
                Share
              </button>
            </div>
          </Modal.Body>
          <div className="modal-body" style={{ borderTop: "1px solid #ddd" }}>
            {Array.isArray(sharedWithArray) && (
              <h6>Monitor Server Shared With</h6>
            )}
            {Array.isArray(sharedWithArray) &&
              sharedWithArray.map((data) => {
                return (
                  <div className="row mt-1">
                    <div className="col-md-9">{data.email}</div>
                    <div className="col-md-3">
                      <IoIosRemoveCircleOutline
                        color="red"
                        size={20}
                        className="actionIcon deleteIcon"
                        onClick={() =>
                          monitorServerSharedWithRemoveFromOther(data)
                        }
                      ></IoIosRemoveCircleOutline>
                    </div>
                  </div>
                );
              })}
          </div>
        </Modal>
      </div>
    </>
  );
};

export default ServerShare;
