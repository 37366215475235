import { RestMethodSecondOrigin } from '../../../_helpers/ApiConfigTwo/RestMethod'
import FilterResponse from '../../../_helpers/FilterResponse'
import { isAuthorized } from '../../../_helpers/Common/Common'
export const UrlStatusServices = {
    allUrls,
    UrlDetails,
    urlResponse,
    updateUrls
}

async function allUrls(data) {
    try {
        const response = await RestMethodSecondOrigin.POST('/url-monitor/all-urls', data);
        const api_response = FilterResponse(response);
        return api_response;
    }
    catch (error) {
        isAuthorized(error)
        return null;
    }
}

async function UrlDetails(id, data) {
    try {
        const response = await RestMethodSecondOrigin.PUT('/url-status/url-details?id=' + id, data);
        const api_response = FilterResponse(response);
        return api_response;
    }
    catch (error) {
        isAuthorized(error)
        return null;
    }
}

async function urlResponse(id, data) {
    try {
        const response = await RestMethodSecondOrigin.PUT('/url-chart/url-response?id=' + id, data);
        const api_response = FilterResponse(response);
        return api_response;
    }
    catch (error) {
        isAuthorized(error)
        return null;
    }
}

async function updateUrls(id, data) {
    try {
        const response = await RestMethodSecondOrigin.PUT('/url-monitor/update-urls?id=' + id, data);
        const api_response = FilterResponse(response);
        return api_response;
    }
    catch (error) {
        isAuthorized(error)
        return null;
    }
}
