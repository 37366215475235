import React, { useState, useEffect } from "react";
import { Collapse, Button } from "reactstrap";
import NotificationChannels from "./NotificationChannels";
import Switch from "../../_components/CommonComponents/SwitchBotton/SwitchBotton";
import { notificationChannelServices } from "../../_services/servicesWithSecondOrigin/index";
import toastr from "toastr";
import { confirmAlert } from "react-confirm-alert";
import PageLoader from "../../_components/CommonComponents/PageLoader/Loader";
import { Loader } from "../../_components/CommonComponents";

const CollapseData = ({
  urlServerName,
  data,
  tab,
  innerKey,
  allNotification,
  chanelD,
  resource_type,
  callBackGetData,
  isRefresh,
  onErrorCallBack,
  switchAll,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  // const [error, setError] = useState(false);
  const [loader, setLoader] = useState(false);
  const [updateCurrentId, setUpdateCurrentId] = useState([]);
  const toggle = () => {
    setIsOpen(!isOpen);
    // if (chanelD) {
    //     setError(false)
    // } else {
    //     setError(true)
    // }
    if (!isOpen) {
      callBackGetData();
      onErrorCallBack();
    }
  };

  const [keys, setKets] = useState([]);

  useEffect(() => {
    if (data) {
      setKets(Object.keys(data));
    }
  }, [data]);

  useEffect(() => {
    if (isOpen) {
      callBackGetData();
    }
  }, [isRefresh]);

  const onClickCheckRow = async (chanelD, resId, innerKey, isTrue) => {
    // confirmAlert({
    //   title: "Confirm to do this",
    //   message: <p>Are you sure to do this.</p>,
    //   buttons: [
    //     {
    //       label: "Yes",
    // onClick: async () => {
    let objData = {
      resource_type: resource_type,
      resource_id: resId,
    };
    setLoader(true);
    setUpdateCurrentId((prev) => [...prev, resId]);
    let AllResource =
      await notificationChannelServices.activateResourceNotification(objData);

    if (AllResource && AllResource.data && Array.isArray(AllResource.data)) {
      allNotification.map((obj, index) => {
        allNotification[index].status = 0;
        AllResource.data.map((s_obj) => {
          if (parseInt(obj.id) === parseInt(s_obj.notification_channel_id)) {
            allNotification[index].status = s_obj.status;
          }
        });
      });
      onSubmit(allNotification, resId, isTrue);
    } else {
      setLoader(false);
    }
    // },
    //     },
    //     {
    //       label: "No",
    //       onClick: () => console.log("No"),
    //     },
    //   ],
    // });
  };

  const onSubmit = async (dataArr, resId, isTrue) => {
    let notifyingData = [...dataArr];
    let objData = [];
    let updatedData = "";
    Array.isArray(notifyingData) &&
      notifyingData.map((obj) => {
        if (parseInt(obj.id) === parseInt(chanelD)) {
          obj.status = isTrue ? 0 : 1;
          updatedData = {
            resource_id: resId,
            notification_channel_id: obj.id,
            status: parseInt(obj.status) === 1 ? 1 : 0,
          };
        }
        objData.push({
          resource_id: resId,
          notification_channel_id: obj.id,
          status: parseInt(obj.status) === 1 ? 1 : 0,
        });
      });

    let objdata = {
      resource_type: resource_type,
      data: objData,
    };

    let response = await notificationChannelServices.masterActivateNotification(
      objdata
    );
    if (response && response.message) {
      toastr.info(response.message);
      // callBackGetData();
      setTimeout(
        () =>
          setUpdateCurrentId((prev) => [...prev].filter((k) => k !== resId)),
        100
      );
      updatedData && switchAll(updatedData);
      setLoader(false);
    } else {
      setLoader(false);
      setTimeout(
        () =>
          setUpdateCurrentId((prev) => [...prev].filter((k) => k !== resId)),
        100
      );
    }
  };

  return (
    <div>
      {/* <PageLoader isLoading={loader}></PageLoader> */}
      <div className="d-flex px-4 pt-4">
        <Button
          color="primary"
          onClick={toggle}
          style={{ marginBottom: "1rem" }}
        >
          {tab}{" "}
        </Button>{" "}
        <h6 onClick={toggle} className="m-2">
          {!isOpen ? "Click to expand" : "Click to collapse"}
        </h6>
      </div>
      {/* <div className="pl-3">
                {!chanelD && error && <Error error="Please Choose Notification Channel"></Error>}
            </div> */}
      {
        <Collapse isOpen={isOpen}>
          {data && (
            <div className="table-responsive mb-4">
              <table className="table table-bordered manageNotif">
                <tr>
                  <th style={{ padding: "10px" }}>{urlServerName}</th>
                  <th style={{ padding: "10px", width: "130px" }}>All</th>
                  {keys &&
                    Array.isArray(keys) &&
                    keys.map((key) => {
                      return (
                        <th style={{ padding: "10px" }}>
                          {key}{" "}
                          <p style={{ color: "#806060" }}>
                            {data[key] && data[key].description}
                          </p>
                        </th>
                      );
                    })}
                </tr>

                {keys &&
                  Array.isArray(keys) &&
                  data[keys[0]] &&
                  data[keys[0]].setting &&
                  Array.isArray(data[keys[0]].setting) &&
                  data[keys[0]].setting
                    .sort((a, b) =>
                      a[innerKey].toLowerCase() > b[innerKey].toLowerCase()
                        ? 1
                        : a[innerKey].toLowerCase() < b[innerKey].toLowerCase()
                        ? -1
                        : 0
                    )
                    .map((key, index) => {
                      let resId = "";
                      let ArrLenght = 0;
                      Array.isArray(data[keys[0]]?.setting) &&
                        data[keys[0]]?.setting?.map((re) => {
                          if (re.id === key.id) {
                            resId = re.id;
                          }
                        });

                      keys &&
                        Array.isArray(keys) &&
                        keys.map((keyval) => {
                          data[keyval]?.setting[index]?.channels?.map(
                            (obj, index) => {
                              if (
                                parseInt(chanelD) === parseInt(obj.id) &&
                                parseInt(obj.status) === 1
                              ) {
                                ArrLenght = ArrLenght + 1;
                              }
                            }
                          );
                        });

                      return (
                        <tr>
                          <td style={{ padding: "10px" }}>
                            {key && key[innerKey]}
                          </td>
                          <td
                            style={{
                              padding: "25px 27px 0px",
                            }}
                          >
                            {updateCurrentId.includes(resId) && (
                              <div
                                style={{
                                  position: "relative",
                                  left: "40%",
                                  top: "35%",
                                }}
                              >
                                <Loader
                                  loader={true}
                                  className="position-absolute loader-all-notification"
                                ></Loader>
                              </div>
                            )}
                            {chanelD && (
                              <Switch
                                checked={ArrLenght === Object.keys(data).length}
                                onClick={(e) =>
                                  onClickCheckRow(
                                    chanelD,
                                    resId,
                                    innerKey,
                                    ArrLenght === Object.keys(data).length
                                  )
                                }
                              ></Switch>
                            )}
                          </td>
                          {keys &&
                            Array.isArray(keys) &&
                            keys.map((keyval) => {
                              let setting = data[keyval].setting.sort((a, b) =>
                                a[innerKey].toLowerCase() >
                                b[innerKey].toLowerCase()
                                  ? 1
                                  : a[innerKey].toLowerCase() <
                                    b[innerKey].toLowerCase()
                                  ? -1
                                  : 0
                              );
                              return (
                                <td style={{ padding: "10px" }}>
                                  <NotificationChannels
                                    allNotification={setting[index].channels}
                                    id={setting[index].id}
                                    master_event_id={
                                      data[keyval].master_event_id
                                    }
                                    description={data[keyval].description}
                                    chanelD={chanelD}
                                    resource_type={resource_type}
                                  />
                                </td>
                              );
                            })}
                        </tr>
                      );
                    })}
              </table>
            </div>
          )}
        </Collapse>
      }
    </div>
  );
};
export default CollapseData;
