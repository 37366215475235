import React, { useRef, useEffect } from 'react';
import ReactECharts from 'echarts-for-react';
import refreshImg from './../../assets/img/refresh.png'

const LineStackChart = ({ ...props }) => {
    const myref = useRef(null)
    let options = {
        group: 'group1',
        grid: {
            top: '25%',
            left: '3%',
            right: '4%',
            bottom: props.dataZoom ? '18%' : '3%',
            containLabel: true
        },
        xAxis: {
            type: 'category',
            data: ['03:00', '04:00'],
        },
        yAxis: {
            type: 'value',
            axisLabel: {
                formatter: `{value} ${props.dataSizeType ? props.dataSizeType : ""}`
            },
        },
        series: [
            {
                data: [0],
                type: 'line',
                smooth: true,
            },
        ],
        legend: {
            data: []
        },
    };

    if (props.title) {
        options.title = {
            text: props.title
        }
    }
    if (props.legend) {
        if (props.legend) {
            if (props.legendObj) {
                options.legend = props.legendObj
            } else {
                options.legend = {
                    data: props.legend,
                    type: "scroll",
                }
            }
        }
    }

    if (props.toolbox) {
        options.toolbox = {
            feature: {
                saveAsImage: {},
            },
            right: "06%",
        }

        if (props.restore) {
            options.toolbox.feature.myTool1 = {
                show: true,
                title: 'Refresh',
                icon: 'image://' + refreshImg,
                onclick: function () {
                    if (myref && myref.current && myref.current.getEchartsInstance() && myref.current.props) {
                        if (props.getRestoreChart) {
                            props.getRestoreChart();
                        } else {
                            myref.current.getEchartsInstance().setOption(myref.current.props.option, true);
                        }
                    }
                }
            };
        }
    }

    if (props.tooltip) {
        options.tooltip = {
            trigger: 'axis',
            formatter: function (e) {
                let rowData = e[0].name
                const {currently_Login_data}= props.allobjs
                let   objArr = Array.isArray(currently_Login_data) && Array.isArray(currently_Login_data[e[0].dataIndex]) ?currently_Login_data[e[0].dataIndex]:[]
                    Array.isArray(objArr) && objArr.map((data) => {
                        rowData = rowData + `<br/> ${e[0].marker} ${data[0]} :  ${data[2]} `
                    })
                return rowData
            },
        }
        if (localStorage.getItem('themes') === 'dark') {
            options.tooltip = {
                ...options.tooltip, backgroundColor: '#30384c',
                textStyle: {
                    color: '#b6c2e4'
                },
            }
        }
    }

    if (props.dataZoom) {
        options.dataZoom = [{}]
    }
    if (props.grid) {
        options.grid = props.grid
    }
    if (props.xAxis) {
        options.xAxis = props.xAxis
    }
    if (props.yAxis) {
        options.yAxis = props.yAxis
    }
    if (props.series) {
        options.series = props.series
    }

    useEffect(() => {
        if (props.getEchartRef && myref) {
            props.getEchartRef(myref)
        }
    }, [])

    return (<ReactECharts ref={myref} option={options ? options : {}} />)
}
export default LineStackChart
