import { RestMethodSecondOrigin } from '../../../_helpers/ApiConfigTwo/RestMethod'
import FilterResponse from '../../../_helpers/FilterResponse'
import toastr from 'toastr'

export const emailService = {
    getAllEmail,
    readEmail,
    getAllEmailInboxes,
    shareEmailInbox,
    sharedEmailWith,
    shareEmailInboxByMeRemove,
    shareEmailInboxByOtherRemove,
    readUnread
}

async function getAllEmail(page, data) {
    let url = '/email-parse/email-log'
    if (page) {
        url += page
    }
    try {
        const response = await RestMethodSecondOrigin.POST(url, data);
        const api_response = FilterResponse(response);
        return api_response;
    }
    catch (error) {
        if (error && error.response && error.response.data && error.response.data.Error) {
            toastr.error(error.response.data.Error)
        }
        console.log("error detected while fetching data from api");
        return null;
    }
}

async function getAllEmailInboxes(page) {
    let url = '/email-parse/all-email'
    if (page) {
        url += page
    }
    try {
        const response = await RestMethodSecondOrigin.GET(url);
        const api_response = FilterResponse(response);
        return api_response;
    }
    catch (error) {
        if (error && error.response && error.response.data && error.response.data.Error) {
            toastr.error(error.response.data.Error)
        }
        console.log("error detected while fetching data from api");
        return null;
    }
}


async function readEmail(id, data) {
    try {
        const response = await RestMethodSecondOrigin.PUT('/email-parse/email-read?id=' + id, data);
        const api_response = FilterResponse(response);
        return api_response;
    }
    catch (error) {
        if (error && error.response && error.response.data && error.response.data.Error) {
            toastr.error(error.response.data.Error)
        }
        console.log("error detected while fetching data from api");
        return null;
    }
}

async function shareEmailInbox(data) {
    try {
        const response = await RestMethodSecondOrigin.POST('/email-parse/share-email', data);
        const api_response = FilterResponse(response);
        return api_response;
    }
    catch (error) {
        if (error && error.response && error.response.data && error.response.data.Error) {
            toastr.error(error.response.data.Error)
        }
        console.log("error detected while fetching data from api");
        return null;
    }
}

async function readUnread(data) {
    try {
        const response = await RestMethodSecondOrigin.POST('/email-parse/mark-read', data);
        const api_response = FilterResponse(response);
        return api_response;
    }
    catch (error) {
        if (error && error.response && error.response.data && error.response.data.Error) {
            toastr.error(error.response.data.Error)
        }
        console.log("error detected while fetching data from api");
        return null;
    }
}

async function shareEmailInboxByMeRemove(data) {
    try {
        const response = await RestMethodSecondOrigin.POST('/email-parse/remove-my-email', data);
        const api_response = FilterResponse(response);
        return api_response;
    }
    catch (error) {
        if (error && error.response && error.response.data && error.response.data.Error) {
            toastr.error(error.response.data.Error)
        }
        console.log("error detected while fetching data from api");
        return null;
    }
}

async function shareEmailInboxByOtherRemove(data) {
    try {
        const response = await RestMethodSecondOrigin.POST('/email-parse/remove-email-shared', data);
        const api_response = FilterResponse(response);
        return api_response;
    }
    catch (error) {
        if (error && error.response && error.response.data && error.response.data.Error) {
            toastr.error(error.response.data.Error)
        }
        console.log("error detected while fetching data from api");
        return null;
    }
}


async function sharedEmailWith(email) {
    try {
        const response = await RestMethodSecondOrigin.GET('/email-parse/email-shared-with?email=' + email);
        const api_response = FilterResponse(response);
        return api_response;
    }
    catch (error) {
        if (error && error.response && error.response.data && error.response.data.Error) {
            toastr.error(error.response.data.Error)
        }
        console.log("error detected while fetching data from api");
        return null;
    }
}