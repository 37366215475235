import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody} from 'reactstrap';
import AddServer from '../../../Pages/MonitorServers/AddServer'
import { MdAdd } from "react-icons/md";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FiCopy } from "react-icons/fi";
import toastr from 'toastr'
const AddServerPoppup = (props) => {
    const {
        buttonLabel,
        className
    } = props;

    const [modal, setModal] = useState(false);
    const [command, setCommand] = useState(false);
    const toggle = () => setModal(!modal);
    const [uuid, setUuid] = useState(false);

    const responseAfterAdd = (res) => {
        setUuid(res.data.uuid)
    }

    const copyText = (key) => {
        if (key) {
            navigator.clipboard.writeText(key)
            toastr.success("Copied!")
        }
    }

    return (
        <>
            <OverlayTrigger placement="left" overlay={<Tooltip>Add a new server</Tooltip>}>
                {props.fromClickHere ? <a href="#" onClick={toggle}>click here</a> : <MdAdd className="headerIcon addIconBtn" onClick={toggle}>{buttonLabel}</MdAdd>}
            </OverlayTrigger>

            <Modal isOpen={modal} toggle={toggle} className={className} id="ms-addServer">
                <ModalHeader toggle={toggle}>Add New Server</ModalHeader>
                <ModalBody>
                    <AddServer responseAfterAdd={(rez) => responseAfterAdd(rez)} popup={true} toggle={() => {
                        // toggle()
                        setCommand(true)
                        props.getData()
                    }}></AddServer>
                    {command && <div>
                        
                        {/* Run this command on your server to setup g8keeper agent
                        <p> {`sudo bash -c "$(curl -sL http://static.g8keeperapp.com/install-g8keeper.sh)" -i -key=${uuid} -secret=${props.secretkey}`}</p>
                    */}
                   
                        <p>
                            <h6 className="command-title">Run this command on your server to setup g8keeper agent
                            To view what all you can do with the agent on server <a href="https://g8keeperapp.com/how-it-works/manage-g8keeper-agent-on-server/" target="_blank">click here </a>
                            </h6>
                            <div className="p-relative">
                                <p className="command-box"> {`sudo bash -c "$(curl -sL http://static.g8keeperapp.com/install-g8keeper.sh)" -i -key=${uuid} -secret=${props.secretkey}`}
                                    <FiCopy style={{cursor:'pointer'}} className="copy-text-button" onClick={() => copyText(`sudo bash -c "$(curl -sL http://static.g8keeperapp.com/install-g8keeper.sh)" -i -key=${uuid} -secret=${props.secretkey}`)}></FiCopy>
                                </p>
                            </div>
                        </p>
                   
                    </div>
                    }
                </ModalBody>

            </Modal>
        </>
    );
}

export default AddServerPoppup;
