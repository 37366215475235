import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import Notification from './Notification'
import { IoMdNotificationsOutline } from "react-icons/io";
import { ModalTitle, OverlayTrigger, Tooltip } from 'react-bootstrap';
const DomainNotificationPoppup = (props) => {
    const {
        className
    } = props;
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);

    return (
        <>
            <OverlayTrigger placement="top" overlay={<Tooltip>Configure notifications for domain</Tooltip>}>
                <IoMdNotificationsOutline className="mr-1 actionIcon" size={27} onClick={toggle}></IoMdNotificationsOutline>
            </OverlayTrigger>
            {/* <OverlayTrigger placement="top" overlay={<Tooltip>Configure notifications for domain</Tooltip>}><IoMdNotificationsOutline className="mr-1 actionIcon" size={27}  onClick={toggle}></IoMdNotificationsOutline></OverlayTrigger> */}


            <Modal isOpen={modal} toggle={toggle} className={className}>
                <ModalHeader toggle={toggle}>
                    <ModalTitle>Notification Setting</ModalTitle></ModalHeader>
                {<ModalBody>
                    <Notification MSId={props.allData.id}></Notification>
                </ModalBody>}
            </Modal>
        </>
    );
}

export default DomainNotificationPoppup;