const loginRule = [
    {
        field_name: 'email',
        label: 'Email',
        type: "email",
        isRequired: true
    },
    {
        field_name: 'password',
        label: 'Password',
        type: "password",
        isRequired: true
    }
]

export function Constants() {
    return {loginRule}
}