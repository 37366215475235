import React from "react";
import { Button } from "reactstrap";
import Header from "../../_components/CommonComponents/Header/Header";
import { ServerCurrentLoggedIn } from "../../_components/CommonComponents/index";
import ServerHistoryTable from "../../_components/ServerHistoryTable/ServerHistoryTable";
import { IoIosArrowRoundBack } from "react-icons/io";
import { objectToQuery } from "../../_helpers/Common/Common";
class BackupFolderChange extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      uuid: "",
      myTimezone: "",
      server_name: "",
      unique_id: null,
    };
  }

  componentDidMount() {
    let Timezone = localStorage.getItem("Timezone");
    this.setState(
      {
        myTimezone: Timezone,
      },
      () => {
        if (this.props.location.state.uuid) {
          this.setState({
            uuid: this.props.location.state.uuid,
          });
        }
        if (
          this.props.location &&
          this.props.location.state &&
          this.props.location.state.id
        ) {
          this.setState(
            {
              id: this.props.location.state.id,
              uuid: this.props.location.state.uuid,
              server_name: this.props.location.state.server_name,
            },
            async () => {}
          );
        } else {
          this.props.history.push("/monitor-servers");
        }
      }
    );
  }

  onSelectServer = (e) => {
    this.setState({
      id: e.id,
      uuid: e.value,
      server_name: e.label,
    });
  };

  onSelectUniqueId = (e) => {
    this.setState({
      unique_id: e.value,
    });
  };

  render() {
    let object = {
      id: this.state.id,
      uuid: this.state.uuid,
      update: true,
      server_name: this.state.server_name,
    };
    return (
      <div className="container">
        <Header history={this.props.history} {...this.props}></Header>

        <div className="serverDetailsSec">
          <Button
            className="backBtn mr-4"
            onClick={() =>
              this.props.history.push(
                "/monitor-server/charts" + objectToQuery(object),
                object
              )
            }>
            <IoIosArrowRoundBack></IoIosArrowRoundBack>
          </Button>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <ServerCurrentLoggedIn
              server_name={this.state.server_name}
              uuid={this.state.uuid}
              onSelectServer={this.onSelectServer}
              mytimezone={this.state.mytimezone}
              unique_id={this.state.unique_id}
              onSelectUniqueId={this.onSelectUniqueId}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="card">
              <ServerHistoryTable
                tableName="Current Login"
                user_element="currently_logged_in"
                {...this.state}
                uuid={this.state.uuid}></ServerHistoryTable>
            </div>
          </div>
          {/* <div className="col-md-6">
                    <div className="card">
                        <ServerHistoryTable
                            tableName="Public IP"
                            user_element="Public_ip"
                            {...this.state}
                            uuid={this.state.uuid}
                        ></ServerHistoryTable>
                    </div>
                </div> */}
          {/* <div className="col-md-6">
                    <div className="card">
                        <ServerHistoryTable
                            tableName="Mac Address"
                            user_element="mac_address"
                            {...this.state}
                            uuid={this.state.uuid}
                        ></ServerHistoryTable>
                    </div>
                </div> */}
          {/* <div className="col-md-6">
                    <div className="card">
                        <ServerHistoryTable
                            tableName="Machine Timezone"
                            user_element="machine_timezone"
                            {...this.state}
                            uuid={this.state.uuid}
                            firstCol={"Timezone"}
                        ></ServerHistoryTable>
                    </div>
                </div> */}
          {/* <div className="col-md-6">
                    <div className="card">
                        <ServerHistoryTable
                            tableName="Last Reboot Time"
                            user_element="last_reboot_time"
                            {...this.state}
                            uuid={this.state.uuid}
                            firstCol={"Date"}
                        ></ServerHistoryTable>
                    </div>
                </div> */}
          {/* <div className="col-md-6">
                    <div className="card">
                        <ServerHistoryTable
                            tableName="App Version"
                            user_element="App_version"
                            {...this.state}
                            uuid={this.state.uuid}
                        ></ServerHistoryTable>
                    </div>
                </div> */}
        </div>
      </div>
    );
  }
}

export default BackupFolderChange;
