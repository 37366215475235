import React, { useState, useEffect } from 'react'
import { Form } from 'reactstrap';
import { MButton } from '../CommonComponents/index'
import { notificationChannelServices } from '../../_services/servicesWithSecondOrigin/index'
import toastr from 'toastr'

const Notification = ({ ...props }) => {
    const [data, setData] = useState([])

    useEffect(async () => {
        if (props.MSId)
            getData()
    }, [props.MSId])

    const getData = async () => {
        let objData = {
            "resource_type": "url_monitor",
            "resource_id": props.MSId,
        }
        let allNotifications = await notificationChannelServices.getAllNotificationChannel()

        if (allNotifications && Array.isArray(allNotifications.data)) {

            let AllResource = await notificationChannelServices.activateResourceNotification(objData)
            if (AllResource && AllResource.data && Array.isArray(AllResource.data)) {
                allNotifications.data.map((obj, index) => {
                    allNotifications.data[index].status = 0
                    AllResource.data.map((s_obj) => {
                        if (parseInt(obj.id) === parseInt(s_obj.notification_channel_id)) {
                            allNotifications.data[index].status = s_obj.status
                        }
                    })
                })
                setData(allNotifications.data)
            }
        }
    }

    const onSubmit = async (e) => {
        let notifyingData = [...data]
        let objData = []
        Array.isArray(notifyingData) && notifyingData.map((obj) => {
            objData.push({
                "resource_id": props.MSId,
                "notification_channel_id": obj.id,
                "status": parseInt(obj.status) === 1 ? 1 : 0
            })
        })

        let objdata = {
            "resource_type": "url_monitor",
            "data": objData
        }

        let response = await notificationChannelServices.masterActivateNotification(objdata)
        if (response && response.message) {
            toastr.info(response.message)
            props.callback()
        }
    }

    const onClick = (e, index) => {
        let notifyingData = [...data]
        notifyingData[index].status = (parseInt(notifyingData[index].status) === 1) ? 0 : 1
        setData(notifyingData)
    }

    return <div className="notificationSetting">
        <div className="notificationTitle">
            <h3>Notification Setting</h3>
        </div>
        <Form onSubmit={(e) => onSubmit(e)}>
            <ul>
                {Array.isArray(data) && data.map((obj, index) => {
                    return <li>
                        <label>
                            <input type="checkbox" checked={parseInt(obj.status) === 1} onChange={(e) => onClick(e, index)} id={index} />
                            <span>{obj.custom_channel_name}</span>
                        </label>
                    </li>
                })}
            </ul>
            <div className="text-center pt-1 pb-2">
                <MButton label="Update Notification Setting" onClick={(e) => onSubmit(e)} color="primary"></MButton>
            </div>
        </Form>
    </div>
}

export default Notification