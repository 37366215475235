import React, { useState, useEffect } from "react";
import { Table } from "reactstrap";
import { Pagination } from "../CommonComponents/index";
const MonitorServerLogTable = (props) => {
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [sort, setSort] = useState({
    field: "date",
    dir: "ASC",
  });

  const onChangeSort = ({ target }) => {
    const field = target.getAttribute("about");
    setSort((prev) => ({
      field: field,
      dir: prev.field !== field ? "ASC" : prev.dir === "ASC" ? "DESC" : "ASC",
    }));
  };

  useEffect(() => {
    if (props.serverLog && Array.isArray(props.serverLog)) {
      let length = props.serverLog.length;
      let pageCount = parseInt(length / 10);
      if (length % 10 !== 0) {
        pageCount = pageCount + 1;
      }
      setPageCount(pageCount);
    }
  }, [props.serverLog]);

  const setPageNumber = (page) => {
    setCurrentPage(page);
  };

  return (
    <div>
      <Table className="table table-bordered table-sortable">
        <thead>
          <tr>
            <th style={{ width: 100 }}>S.No.</th>
            <th
              className={`sortable${
                sort.field === "date" ? ` ${sort.dir.toLowerCase()}` : ""
              }`}
              onClick={onChangeSort}
              about="date"
              style={{ width: 300 }}
            >
              Date
            </th>
            <th>Duration</th>
            <th
              style={{ width: 300 }}
              className={`sortable${
                sort.field === "status" ? ` ${sort.dir.toLowerCase()}` : ""
              }`}
              onClick={onChangeSort}
              about="status"
            >
              Status
            </th>
          </tr>
        </thead>
        <tbody>
          {props.serverLog &&
            Array.isArray(props.serverLog) &&
            props.serverLog
              .sort((a, b) => {
                if (
                  sort.dir === "ASC"
                    ? a[sort.field] > b[sort.field]
                    : a[sort.field] < b[sort.field]
                )
                  return -1;

                return 1;
              })
              .map((data, index) => {
                return (
                  currentPage * 10 > index &&
                  currentPage * 10 < index + 1 + 10 && (
                    <tr key={index}>
                      <th scope="row">{index + 1}</th>
                      <td>{data.date}</td>
                      <td>{data.duration}</td>
                      <td>{data.status}</td>
                    </tr>
                  )
                );
              })}
        </tbody>
      </Table>
      {Array.isArray(props.serverLog) && props.serverLog.length !== 0 && (
        <div className="paginationSec">
          <Pagination
            currentPage={currentPage}
            pageCount={pageCount}
            onClickPage={(page) => setPageNumber(page)}
          ></Pagination>
        </div>
      )}
    </div>
  );
};

export default MonitorServerLogTable;
