import { RestMethodSecondOrigin } from '../../../_helpers/ApiConfigTwo/RestMethod'
import FilterResponse from '../../../_helpers/FilterResponse'
import { isAuthorized } from '../../../_helpers/Common/Common'

export const helperService = {
    getTimeZoon
}

async function getTimeZoon(data) {
    let api = '/chart/timezone'
    try {
        const response = await RestMethodSecondOrigin.POST(api, data);
        const api_response = FilterResponse(response);
        return api_response;
    }
    catch (error) {
        isAuthorized(error)
        console.log("error detected while fetching data from api");
        return null;
    }
}
