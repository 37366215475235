import { RestMethodSecondOrigin } from "../../../../_helpers/ApiConfigTwo/RestMethod";
import FilterResponse from "../../../../_helpers/FilterResponse";
import { isAuthorized } from "../../../../_helpers/Common/Common";
export const monitorServersSettingServices = {
  updateMonitorServerdisallowed,
  updateMonitorServerAllowed,
  updateMonitorServerBackup,
  updateMonitorDBBackup,
  updateMonitorUploadPath,

  getMonitorServerdisallowed,
  getMonitorServerAllowed,
  getMonitorServerBackup,
  getMonitorDBBackup,
  getMonitorUploadPath,

  deleteSettingData,
  generalSettingSave,
  getGeneralSettingSave,
  getGeneralSettingApachhe,
  getGeneralSettingMySqlStatus,
  updatePublicKey,
  serverTimeZone,

  getPerformanceStatus,
  getPerformanceChartsData,
  saveCustomLogs,
  getCustomLogs,
  deleteCustomLogs,
  updateCustomLogs,
};

async function updateMonitorServerdisallowed(data, id) {
  try {
    const response = await RestMethodSecondOrigin.PUT("/monitor-server/disallowed-files?id=" + id, data);
    const api_response = FilterResponse(response);
    return api_response;
  } catch (error) {
    isAuthorized(error);
    return null;
  }
}

async function updateMonitorServerAllowed(data, id) {
  try {
    const response = await RestMethodSecondOrigin.PUT("/monitor-server/allowed-files?id=" + id, data);
    const api_response = FilterResponse(response);
    return api_response;
  } catch (error) {
    isAuthorized(error);
    return null;
  }
}

async function updateMonitorServerBackup(data, id) {
  try {
    const response = await RestMethodSecondOrigin.PUT("/monitor-server/server-backup?id=" + id, data);
    const api_response = FilterResponse(response);
    return api_response;
  } catch (error) {
    isAuthorized(error);
    return null;
  }
}

async function updateMonitorDBBackup(data, id) {
  try {
    const response = await RestMethodSecondOrigin.PUT("/monitor-server/db-backup?id=" + id, data);
    const api_response = FilterResponse(response);
    return api_response;
  } catch (error) {
    isAuthorized(error);
    return null;
  }
}

async function updateMonitorUploadPath(data, id) {
  try {
    const response = await RestMethodSecondOrigin.PUT("/monitor-server/upload-path?id=" + id, data);
    const api_response = FilterResponse(response);
    return api_response;
  } catch (error) {
    isAuthorized(error);
    return null;
  }
}

/////////////////////////////////////////////////////////////////////////////

async function getMonitorServerdisallowed(id) {
  try {
    const response = await RestMethodSecondOrigin.GET("/monitor-server/view-disallowed-files?id=" + id);
    const api_response = FilterResponse(response);
    return api_response;
  } catch (error) {
    isAuthorized(error);
    return null;
  }
}

async function getMonitorServerAllowed(id) {
  try {
    const response = await RestMethodSecondOrigin.GET("/monitor-server/view-allowed-files?id=" + id);
    const api_response = FilterResponse(response);
    return api_response;
  } catch (error) {
    isAuthorized(error);
    return null;
  }
}

async function getMonitorServerBackup(id) {
  try {
    const response = await RestMethodSecondOrigin.GET("/monitor-server/view-server-backup?id=" + id);
    const api_response = FilterResponse(response);
    return api_response;
  } catch (error) {
    isAuthorized(error);
    return null;
  }
}

async function getMonitorDBBackup(id) {
  try {
    const response = await RestMethodSecondOrigin.GET("/monitor-server/view-db-backup?id=" + id);
    const api_response = FilterResponse(response);
    return api_response;
  } catch (error) {
    isAuthorized(error);
    return null;
  }
}

async function getMonitorUploadPath(id) {
  try {
    const response = await RestMethodSecondOrigin.GET("/monitor-server/view-upload-backup-path?id=" + id);
    const api_response = FilterResponse(response);
    return api_response;
  } catch (error) {
    isAuthorized(error);
    return null;
  }
}

async function deleteSettingData(data) {
  try {
    const response = await RestMethodSecondOrigin.POST("/monitor-server/delete-setting", data);
    const api_response = FilterResponse(response);
    return api_response;
  } catch (error) {
    isAuthorized(error);
    return null;
  }
}

async function generalSettingSave(data, id) {
  try {
    const response = await RestMethodSecondOrigin.PUT("/monitor-server/general-setting?id=" + id, data);
    const api_response = FilterResponse(response);
    return api_response;
  } catch (error) {
    isAuthorized(error);
    return null;
  }
}

async function getGeneralSettingSave(id) {
  try {
    const response = await RestMethodSecondOrigin.GET("/monitor-server/view-general-setting?id=" + id);
    const api_response = FilterResponse(response);
    return api_response;
  } catch (error) {
    isAuthorized(error);
    return null;
  }
}

async function getPerformanceStatus(data) {
  try {
    // {
    //     "uuid": "603cebf9b5f25",
    //     "monitor_server_id": 22,
    //     "start_datetime": "2022-07-06 08:04:09",
    //     "end_datetime": "2022-07-09 12:04:09",
    //     "timezone": "Asia/Kolkata",
    //     "timediff": -330
    // }

    const response = await RestMethodSecondOrigin.POST("/service-data/service-data-status", data);
    const api_response = FilterResponse(response);
    return api_response;
  } catch (error) {
    isAuthorized(error);
    return null;
  }
}

async function getPerformanceChartsData(data) {
  try {
    // {
    //     "uuid": "603cebf9b5f25",
    //     "monitor_server_id": 22,
    //     "start_datetime": "2022-07-06 08:04:09",
    //     "end_datetime": "2022-07-09 12:04:09",
    //     "timezone": "Asia/Kolkata",
    //     "timediff": -330
    // }

    const response = await RestMethodSecondOrigin.POST("/service-data/service-data-chart", data);
    const api_response = FilterResponse(response);
    return api_response;
  } catch (error) {
    isAuthorized(error);
    return null;
  }
}

async function getGeneralSettingApachhe(data) {
  try {
    const response = await RestMethodSecondOrigin.POST("/apache/apache-data", data);
    const api_response = FilterResponse(response);
    return api_response;
  } catch (error) {
    isAuthorized(error);
    return null;
  }
}

async function getGeneralSettingMySqlStatus(data) {
  try {
    const response = await RestMethodSecondOrigin.POST("/apache/mysql-status", data);
    const api_response = FilterResponse(response);
    return api_response;
  } catch (error) {
    isAuthorized(error);
    return null;
  }
}

async function updatePublicKey(id, data) {
  try {
    const response = await RestMethodSecondOrigin.PUT("/monitor-server/update-public-key?id=" + id, data);
    const api_response = FilterResponse(response);
    return api_response;
  } catch (error) {
    isAuthorized(error);
    return null;
  }
}

async function serverTimeZone(data) {
  try {
    const response = await RestMethodSecondOrigin.POST("/chart/server-timezone", data);
    const api_response = FilterResponse(response);
    return api_response;
  } catch (error) {
    isAuthorized(error);
    return null;
  }
}

async function saveCustomLogs(data, id) {
  try {
    const response = await RestMethodSecondOrigin.POST("/monitor-server/create-custom-logs?id=" + id, data);
    const api_response = FilterResponse(response);
    return api_response;
  } catch (error) {
    isAuthorized(error);
    return null;
  }
}

async function getCustomLogs(id) {
  try {
    const response = await RestMethodSecondOrigin.GET("/monitor-server/get-custom-logs?id=" + id);
    const api_response = FilterResponse(response);
    return api_response;
  } catch (error) {
    isAuthorized(error);
    return null;
  }
}

async function deleteCustomLogs(id) {
  try {
    const response = await RestMethodSecondOrigin.GET("/monitor-server/delete-custom-log?id=" + id);
    const api_response = FilterResponse(response);
    return api_response;
  } catch (error) {
    isAuthorized(error);
    return null;
  }
}

async function updateCustomLogs(id, data) {
  try {
    const response = await RestMethodSecondOrigin.POST("/monitor-server/update-custom-log?id=" + id, data);
    const api_response = FilterResponse(response);
    return api_response;
  } catch (error) {
    isAuthorized(error);
    return null;
  }
}
