import React, { useState, useEffect } from "react";
import { notificationService } from "../../_services/servicesWithSecondOrigin/index";
import { Form } from "reactstrap";
import { Loader } from "../../_components/CommonComponents/index";
import { confirmAlert } from "react-confirm-alert";
import Switch from "../../_components/CommonComponents/SwitchBotton/SwitchBotton";
const NotificationChannels = (props) => {
  const [data, setDatas] = useState([]);
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    if (props.allNotification) {
      setDatas(props.allNotification);
    }
  }, [props.allNotification]);

  //  onSubmit={(e) => onSubmit(e)}
  const onClick = async (e, index, obj) => {
    // confirmAlert({
    //   title: "Confirm to do this",
    //   message: <p>Are you sure to do this.</p>,
    //   buttons: [
    //     {
    //       label: "Yes",
    //       onClick: async () => {
    setLoader(true);
    let dObj = {
      resource_id: props.id,
      resource_type: props.resource_type ? props.resource_type : "url_monitor",
      notification_channel_id: obj.id,
      master_event_id: props.master_event_id,
      status: parseInt(obj.status) === 1 ? 0 : 1,
    };
    let res = await notificationService.updateSettingData(dObj);
    if (res && res.status === 200) {
      let dataArr = data.slice();
      dataArr[index].status = parseInt(obj.status) === 1 ? 0 : 1;
      setDatas(dataArr);
    }
    setLoader(false);
    // },
    //     },
    //     {
    //       label: "No",
    //       onClick: () => console.log("No"),
    //     },
    //   ],
    // });
  };

  return (
    <div>
      <Form>
        <div style={{ position: "relative", left: "40%", top: "35%" }}>
          <Loader
            loader={loader}
            className="position-absolute loader-all-notification"
          ></Loader>
        </div>
        <div className="row mt-3 pl-4">
          {Array.isArray(data) &&
            data.map((obj, index) => {
              if (parseInt(props.chanelD) === parseInt(obj.id))
                return (
                  <div className="d-flex">
                    <Switch
                      checked={parseInt(obj.status) === 1}
                      onClick={(e) => onClick(e, index, obj)}
                    ></Switch>
                  </div>
                );
            })}
        </div>
      </Form>
    </div>
  );
};

export default NotificationChannels;
