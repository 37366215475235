import React from 'react'
import { Row, Col } from 'reactstrap';
import { Collapse, Card, CardHeader } from 'reactstrap';
import AccordionBody from './AccordionBody'
const Accordion = ({ domainInformation, ...props }) => {
    const response = domainInformation
    return <Card>
        <CardHeader onClick={() => props.setToggelValue(props.index)}>
            <div>
                <Row>
                    <Col>
                        <div scope="col"><input type="checkbox" /></div>
                    </Col>
                    <Col>
                        <div scope="col">{response ? response.domain_name : ""}</div>
                    </Col>
                    <Col>
                        <div scope="col">{response ? response.registrar_url : ""}</div>
                    </Col>
                    <Col>
                        <div scope="col">{response ? response.expiration_date : ""}</div>
                    </Col>
                </Row>
            </div>
        </CardHeader>
        <Collapse isOpen={props.toggle === props.index ? true : false}>
            {props.toggle === props.index && <AccordionBody my_domain_name={props.myDomain} response={response} {...props}></AccordionBody>}
        </Collapse>
    </Card>
}

export default Accordion