import React from "react";
import { Form, Row, Col } from "reactstrap";
import FormItem from "./FormItem";
import { MdAdd } from "react-icons/md";

const SeoMonitoringForm = ({ ...props }) => {
    return (
        <div>
            <div>
                <Row>
                    <Col>
                        <h5>Keywords</h5>
                    </Col>
                    {/* <Col>
                        <label>URLs</label>
                    </Col> */}
                </Row>
            </div>
            <div>
                <Form>
                    {Array.isArray(props.seoMonitoringArray) &&
                        props.seoMonitoringArray.map((obj, index) => {
                            return <FormItem {...props} index={index} object={obj}></FormItem>;
                        })}

                    <Row className="m-2 mt-4">
                        <MdAdd className="socialIcon bluesum " size={30} onClick={() => props.addSeoMonitoringKeyword()}/>
                        {/* <GrAddCircle className="socialIcon" size={30} onClick={() => props.addSeoMonitoringKeyword()}></GrAddCircle> */}
                    </Row>
                </Form>
            </div>
        </div>
    );
};
export default SeoMonitoringForm;
