import React, { useEffect, useState } from "react";
import LineStackChart from "../Charts/LineStackChart";
import { domainServices } from "../../_services/servicesWithSecondOrigin/index";

import { Loader } from "../../_components/CommonComponents/index";
const SocialMediaChartOperation = ({ setShow, ...props }) => {
    const [xAxis, setXAxis] = useState(false);
    const [yAxis, setyAxis] = useState(false);
    const [series, setSeries] = useState(false);
    const [dataSizeType, setDataSizeType] = useState("");
    const [loader, setLoader] = useState(false);
    const [reload, setReload] = useState(true);
    const [message, setMessage] = useState("");

    useEffect(() => {
        if (props.domain_name) getGraphDataFromServer();
    }, [props.refresh, props.domain_name, props.myTimezone]);

    const getGraphDataFromServer = async () => {
        setMessage("");
        var d = new Date();
        var n = d.getTimezoneOffset();
        setSeries([
            {
                name: "",
                data: [],
                type: "line",
            },
        ]);

        setXAxis({
            type: "category",
            data: ["3:00"],
        });
        setLoader(true);
        let data = {
            domain_name: props.domain_name,
            start_datetime: props.start_datetime,
            end_datetime: props.end_datetime,
            timezone: props.myTimezone,
            timediff: n,
        };

        let response = await domainServices.socialMediaChartData(data, props.metakey);
        if (response && response.data) {
            let series = [];
            setReload(false);
            if (response.data && response.data.timestamp) {
                setXAxis({
                    type: "category",
                    data: response.data.timestamp,
                });
            }
            if (response.data.data) {
                Object.keys(response.data.data).map((rowName) => {
                    series.push({
                        name: rowName.toString(),
                        data: response.data.data[rowName],
                        type: "line",
                        //  smooth: true,
                    });
                });
                setSeries(series);
                setReload(true);
            } else {
                setReload(true);
            }
        } else {
            if (response && response.message) {
                setMessage(response.message);
            } 
        }
        setyAxis({
            type: "value",
        });
        setLoader(false);
    };

    return (
        <>
            <div className="chart-loader">
                {" "}
                <Loader loader={loader || props.loader}></Loader>
            </div>

            {message === "Waiting for data!" ? (
                <div className="text-center">
                    <h3 className="chart-configure chartInfo">

                        Social Media monitoring is configured, you can <span onClick={() => setShow(true)} style={{ textDecoration: "underline", cursor: "pointer" }} >Click Here</span> to view or modify your tracked social media accounts.
                        It may take us upto 24 hours to update the data.


                        {/* {message}. <span onClick={() => setShow(true)} style={{ textDecoration: "underline", cursor: "pointer" }} >Click Here</span> to configure now. */}
                    </h3>
                </div>
            ) : message ? (
                <div className="text-center">
                    <h3 className="chart-configure ">
                        {message}. <span onClick={() => setShow(true)} style={{ textDecoration: "underline", cursor: "pointer" }} >Click Here</span> to configure now.
                    </h3>
                </div>
            ) : (
                <>{reload && <LineStackChart xAxis={xAxis} yAxis={yAxis} series={series} dataSizeType={dataSizeType} {...props}></LineStackChart>}</>
            )}
        </>
    );
};
export default SocialMediaChartOperation;
