import React, { Component } from "react";
import Switch from "react-switch";

export default class SBotton extends Component {
    constructor(props) {
        super(props);
        this.state = { checked: false };
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(checked) {
        this.props.onClick(checked);
    }

    render() {
        return (
            <Switch onChange={this.handleChange} checked={this.props.checked} disabled={this.props.disabled?this.props.disabled:false}/>
        );
    }
}