import React, { useState } from "react";
import MultiSelect from "react-multi-select-component";
import './Style.css'

const MultiDropDown = (props) => {
    const [selected, setSelected] = useState([]);
    const options = []
    Array.isArray(props.options) && props.options.map((data) => {
        options.push({
            label: props.keys && props.keys.label ? data[props.keys.label] : data.label,
            value: props.keys && props.keys.value ? data[props.keys.value] : data.value,
        })
    })

    const checkSelected = (e) => {
        setSelected(e)
        if (props.onSelect) {
            props.onSelect(e)
        }
    }

    return (
        <div>
            <MultiSelect
                options={options ? options : []}
                value={props.selected ? props.selected : selected}
                onChange={checkSelected}
                labelledBy="Select"
                disabled={props.disabled ? props.disabled : false}
            />
        </div>
    );
};

export default MultiDropDown;