
let rules = [
    {
        field_name: 'master_channel_id',
        label: 'Channel',
        type: "string",
        isRequired: true
    },
    {
        field_name: 'custom_channel_name',
        label: 'Name',
        type: "string",
        isRequired: true
    },
]

const channels = {
    TELEGRAM: "Telegram",
    ROCKET_CHAT: "RocketChat",
    EMAIL: "Email",
    SLACK: "Slack"
}

const emailRule = [{
    field_name: 'email',
    label: 'Email',
    type: "email",
    isRequired: true
}]

const slackRule = [{
    field_name: 'slack',
    label: 'Slack',
    type: "string",
    isRequired: true
}]

export function Constants() {
    return {
        rules,
        channels,
        emailRule,
        slackRule
    }
}