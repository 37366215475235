import React, { useState } from "react";
import "../postman-style.css";

function KeyValuePair({
  paramsHeader,
  handleChange,
  removeFormFields,
  keyValueErr,
  apiMethod,
  headerSelected,
}) {
  return (
    <div>
      {!!paramsHeader?.length && (
        <div className="row">
          <div className="col-md-4">
            <label className="d-none d-md-block">Key</label>
          </div>
          <div className="col-md-4">
            <label className="d-none d-md-block">Value</label>
          </div>
        </div>
      )}
      {paramsHeader?.map((elem, index) => {
        return (
          <div key={index} className="row">
            <div className="col-md-4">
              <input
                name="objKey"
                type="text"
                value={elem.objKey}
                className="form-control mb-2"
                onChange={(e) => handleChange(e, index)}
                placeholder="Key"
              />
              {keyValueErr && keyValueErr[index]?.objKey !== "" && (
                <p className="color-red">{keyValueErr[index]?.objKey}</p>
              )}
            </div>
            <div className="col-md-4">
              <input
                type="text"
                name="objValue"
                value={elem.objValue}
                onChange={(e) => handleChange(e, index)}
                className="form-control mb-2"
                placeholder="Value"
              />
              {keyValueErr && keyValueErr[index]?.objValue !== "" && (
                <p className="color-red">{keyValueErr[index]?.objValue}</p>
              )}
            </div>
            <div className="col-md-4">
              <button
                data-add-query-param-btn
                className={`btn btn-outline-danger my-1`}
                type="button"
                onClick={() => removeFormFields(index)}>
                Remove
              </button>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default KeyValuePair;
