import { RestMethodSecondOrigin } from '../../../_helpers/ApiConfigTwo/RestMethod'
import FilterResponse from '../../../_helpers/FilterResponse'
import { isAuthorized } from '../../../_helpers/Common/Common'

export const notificationChannelServices = {
    getAllNotificationChannel,
    getAllMasterNotificationChannel,
    addNotificationChannel,
    getNotificationChannel,
    updateNotificationChannel,
    deleteNotificationChannel,
    changeStatusOfChannel,
    masterActivateNotification,
    activateResourceNotification,
    autoNotificationSettingActivate
}

async function getAllMasterNotificationChannel(data) {
    try {
        const response = await RestMethodSecondOrigin.GET('/master-channel/all-channel');
        const api_response = FilterResponse(response);
        return api_response;
    }
    catch (error) {
        isAuthorized(error)
        return null;
    }
}

async function getAllNotificationChannel(data) {
    try {
        const response = await RestMethodSecondOrigin.GET('/notification-channels/all-notification-channels');
        const api_response = FilterResponse(response);
        return api_response;
    }
    catch (error) {
        isAuthorized(error)
        return null;
    }
}

async function addNotificationChannel(data) {
    try {
        const response = await RestMethodSecondOrigin.POST('/notification-channels/add-notification-channels', data);
        const api_response = FilterResponse(response);
        return api_response;
    }
    catch (error) {
        isAuthorized(error)
        return null;
    }
}

async function updateNotificationChannel(id, data) {
    try {
        const response = await RestMethodSecondOrigin.PUT('/notification-channels/update-notification-channels?id=' + id, data);
        const api_response = FilterResponse(response);
        return api_response;
    }
    catch (error) {
        isAuthorized(error)
        return null;
    }
}

async function getNotificationChannel(id) {
    try {
        const response = await RestMethodSecondOrigin.GET('/notification-channels/view-notification-channels?id=' + id);
        const api_response = FilterResponse(response);
        return api_response;
    }
    catch (error) {
        isAuthorized(error)
        return null;
    }
}

async function deleteNotificationChannel(id) {
    try {
        const response = await RestMethodSecondOrigin.GET('/notification-channels/delete-notification-channels?id=' + id);
        const api_response = FilterResponse(response);
        return api_response;
    }
    catch (error) {
        isAuthorized(error)
        return null;
    }
}

async function changeStatusOfChannel(id, data) {
    try {
        const response = await RestMethodSecondOrigin.PUT('/notification-channels/change-status?id=' + id, data);
        const api_response = FilterResponse(response);
        return api_response;
    }
    catch (error) {
        isAuthorized(error)
        return null;
    }
}

async function masterActivateNotification(data) {
    try {
        const response = await RestMethodSecondOrigin.POST('/resource-notification-channels/add-resource-channel', data);
        const api_response = FilterResponse(response);
        return api_response;
    }
    catch (error) {
        isAuthorized(error)
        return null;
    }
}

async function activateResourceNotification(data) {
    try {
        const response = await RestMethodSecondOrigin.POST('/resource-notification-channels/resource-channel', data);
        const api_response = FilterResponse(response);
        return api_response;
    }
    catch (error) {
        isAuthorized(error)
        return null;
    }
}

async function autoNotificationSettingActivate(data,id) {
    try {
        const response = await RestMethodSecondOrigin.PUT('/notification-channels/set-in-default?id='+id, data);
        const api_response = FilterResponse(response);
        return api_response;
    }
    catch (error) {
        isAuthorized(error)
        return null;
    }
}

