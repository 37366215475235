import React, { useState, useEffect } from "react";
import { Form } from "reactstrap";
import {
  MButton,
  Select,
  Loader,
  TooltipPro,
} from "../../CommonComponents/index";
import ServerBackUpForm from "./ServerBackUpForm/ServerBackUpForm";
import { Constants } from "../Constants";
import { monitorServersSettingServices } from "../../../_services/servicesWithSecondOrigin/index";
import toastr from "toastr";
import { Button, Input } from "reactstrap";
import { Validation } from "../../../_helpers/validation/Validation";
import DBBackup from "./DBBackup";
import UploadPath from "./UploadPath";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { confirmAlert } from "react-confirm-alert";

const fileObj = {
  friendly_folder_name: "",
  folder: "",
  update: false,
  updateFolter: false,
};

const ServerBackup = ({ ...props }) => {
  const [dataObj, setDataObj] = useState({
    hour: 0,
    day: "*",
    month: "*",
    weekday: "*",
  });
  const [folders, setFolders] = useState([fileObj]);
  const [errorArray, setErrorArray] = useState([]);
  const [loader, setLoader] = useState(false);
  const [public_key_path, setKeyPath] = useState("");
  const [machine_timezone, setMachineTimezone] = useState("");
  useEffect(() => {
    if (props.active === 3) {
      getDataFromServer();
      getTimezone();
    }
  }, [props.MSId, props.active]);

  const getTimezone = () => {
    let data = {
      uuid: props.uuid,
      timezone: localStorage.getItem("Timezone"),
      timediff: new Date().getTimezoneOffset(),
    };
    monitorServersSettingServices.serverTimeZone(data).then((res) => {
      if (!res?.data?.machine_timezone) return;
      setMachineTimezone(
        `(${res.data.machine_timezone ? res.data.machine_timezone.trim() : ""})`
      );
    });
  };

  const getDataFromServer = async () => {
    setLoader(true);
    setFolders([fileObj]);
    setKeyPath("");
    setDataObj({
      hour: 0,
      day: "*",
      month: "*",
      weekday: "*",
    });
    if (props.MSId) {
      let response = await monitorServersSettingServices.getMonitorServerBackup(
        props.MSId
      );
      if (response && response.data && response.data.length !== 0) {
        Array.isArray(response.data) &&
          response.data.map((allData, i) => {
            allData.folder = allData.folder_to_show
              ? allData.folder_to_show
              : allData.folder;
          });
        setFolders(response.data);
        if (
          response.data[0] &&
          response.data[0].hour !== "" &&
          response.data[0] &&
          response.data[0].hour !== undefined
        ) {
          setDataObj({
            hour: parseInt(response.data[0].hour),
            day:
              response.data[0].day === "*"
                ? "*"
                : parseInt(response.data[0].day),
            month: response.data[0].month,
            weekday: response.data[0].weekday,
          });
          setKeyPath(response.data[0].public_key_path);
        }
      }
    }
    setLoader(false);
  };

  const addFiles = () => {
    let cloneArray = folders.concat(fileObj);
    setFolders(cloneArray);
  };

  const remove = (index) => {
    confirmAlert({
      title: "Confirm to Delete",
      message: <p>Are you sure to do this.</p>,
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            let cloneArray = folders.slice();
            if (cloneArray[index].id) {
              deletePerticularSettingData(
                cloneArray[index].id,
                cloneArray,
                index
              );
            } else {
              if (cloneArray.length === 1) {
                allreset();
              } else {
                cloneArray.splice(index, 1);
                setFolders(cloneArray);
              }
            }
          },
        },
        {
          label: "No",
          onClick: () => console.log("No"),
        },
      ],
    });
  };

  const deletePerticularSettingData = async (id, cloneArray, index) => {
    let data = {
      type: "server_bkp",
      id: id,
    };
    let response = await monitorServersSettingServices.deleteSettingData(data);
    if (response && response.status === 200) {
      if (response.message) toastr.success(response.message);
      if (cloneArray.length === 1) {
        allreset();
      } else {
        cloneArray.splice(index, 1);
        setFolders(cloneArray);
      }
    }
  };

  const allreset = () => {
    setFolders([fileObj]);
    setDataObj({
      hour: 0,
      day: "*",
      month: "*",
      weekday: "*",
    });
  };

  const onChangeFolders = (e, index) => {
    let cloneArray = JSON.parse(JSON.stringify(folders));
    cloneArray[index][e.target.name] = e.target.value;
    cloneArray[index].update = true;
    if (e.target && e.target.name === "folder") {
      cloneArray[index].updateFolter = true;
    }

    setFolders(cloneArray);
  };

  const onSubmit = async (e) => {
    let cloneArray = folders.slice();
    let flag = 0;
    let errorArray = [];
    const field = Constants().fileRules;
    let folderAr = [];
    Array.isArray(cloneArray) &&
      cloneArray.map((obj) => {
        let errobj = Validation(obj, field);
        Object.keys(errobj).map((index) => {
          if (errobj[index] !== "") {
            flag = 1;
          }
        });
        errorArray = errorArray.concat(errobj);

        let newObjData = { friendly_folder_name: obj.friendly_folder_name };
        if (obj.id) {
          newObjData.id = obj.id;
        }
        if (obj && obj.update) {
          if (obj.updateFolter) {
            newObjData = obj;
          }
        }
        folderAr.push(newObjData);
      });

    setErrorArray(errorArray);
    if (flag === 0) {
      let data = {
        ...dataObj,
        folders: folderAr,
        // public_key_path: public_key_path
      };

      let response =
        await monitorServersSettingServices.updateMonitorServerBackup(
          data,
          props.MSId
        );
      if (response) {
        await monitorServersSettingServices.updatePublicKey(props.MSId, {
          public_key_path: public_key_path,
        });
        if (response && response.message) {
          toastr.success(response.message);
          getDataFromServer();
        }
      }
    }
  };

  const onChange = (e, name) => {
    let obj = { ...dataObj };
    obj[name] = e.value;
    setDataObj(obj);
  };

  const onChangeDay = (e) => {
    let check = 0;
    if (
      parseInt(e.value) === 31 &&
      Constants().month31check.includes(parseInt(dataObj.month))
    ) {
      check = 1;
    } else if (
      parseInt(e.value) === 30 &&
      Constants().month30check.includes(parseInt(dataObj.month))
    ) {
      check = 1;
    }
    if (
      (parseInt(e.value) !== 31 && parseInt(e.value) !== 30) ||
      e.value === "*" ||
      check === 1
    ) {
      onChange(e, "day");
    } else {
      let obj = {
        value: "*",
      };
      onChange(e, "day");
      onChange(obj, "month");
    }
  };

  const setPublicKey = (val) => {
    if (!public_key_path) {
      setKeyPath(val);
    }
  };

  return (
    <>
      <div className="serverSettingData">
        <div className="setLoaderPosition">
          <Loader loader={loader}></Loader>
        </div>
        <div className="row mt-4">
          <div className="col-md-3">
            <label>Encryption Public Key Path</label>
            <span className="ml-2">
              <TooltipPro
                className="bottom tsize-600"
                tooltip={
                  <div className="row">
                    <div className="col-md-12">
                      <p>
                        It is an industry best practice to encrypt the backups.
                        G8Keeper provides you a way to encrypt your backups.
                        Please generate a private / public key pair
                        (instructions here) and keep the private key on the
                        server and private key that can be used to decrypt the
                        backup in a secure location so that no one other that
                        you has access to private key. (G8Keeper does not need
                        access to your private key)
                      </p>
                      <p>
                        Provide us the absolute path of public key, which needs
                        to be stored on the server, so that we can use that key
                        to encrypt the backup before uploading it to your
                        preferred location.
                      </p>
                    </div>
                  </div>
                }
              >
                <AiOutlineInfoCircle className="infoIcon" />
              </TooltipPro>
            </span>
            <Input
              value={public_key_path}
              onChange={(e) => setKeyPath(e.target.value)}
            ></Input>
          </div>
        </div>
        <div className="serverInfoTabsTitle pt-4">
          {/* <Button className="backBtn mr-4" onClick={(e) => props.history.push('/monitor-servers')}><IoIosArrowRoundBack></IoIosArrowRoundBack></Button> */}
          <h3>
            Folder Backup
            <span className="ml-2">
              <TooltipPro
                className="bottom tsize-600"
                tooltip={
                  <div className="row">
                    <div className="col-md-12">
                      <p>
                        In this section you can configure the path of the
                        folders that you want to backup. A zip file is created,
                        which is then encryped (provided you have setup a public
                        key) and then uploaded to the location you have
                        configured.{" "}
                      </p>
                      <p>You can configure multiple folderpaths for backup.</p>
                      <p>
                        <strong>Note: </strong>Backup is only created if there
                        is atleast 10% disk space remaining on the server.{" "}
                      </p>
                    </div>
                  </div>
                }
              >
                <AiOutlineInfoCircle className="infoIcon" />
              </TooltipPro>
            </span>
          </h3>
        </div>

        <div>
          <Form onSubmit={(e) => onSubmit(e)}>
            <div className="row mt-4">
              <div className="col-md-3">
                <label>Hour {machine_timezone ? machine_timezone : ""}</label>
                <span className="ml-2">
                  <TooltipPro
                    className="bottom tsize-400"
                    tooltip={
                      <div className="row">
                        <div className="col-md-12">
                          <p>
                            Setup hour of the day when you want the backup to be
                            done. This time is as per the server timezone. You
                            should select the time when there will be minimal
                            activity on the server. Backup is generally quick
                            but performance intensive process. Also please
                            ensure that folder backup and DB backup are not
                            schuduled at the same time
                          </p>
                        </div>
                      </div>
                    }
                  >
                    <AiOutlineInfoCircle className="infoIcon" />
                  </TooltipPro>
                </span>
                <Select
                  options={Constants().hour()}
                  setSelectedOption={(e) => onChange(e, "hour")}
                  selectedOption={dataObj.hour}
                ></Select>
              </div>
              <div className="col-md-3">
                <label>Day</label>
                <span className="ml-2">
                  <TooltipPro
                    className="bottom tsize-400"
                    tooltip={
                      <div className="row">
                        <div className="col-md-12">
                          <p>
                            If the Database does not change frequently you may
                            not want to do backup everyday, in that case you can
                            select the date of the month when you want to backup
                            the database
                          </p>
                        </div>
                      </div>
                    }
                  >
                    <AiOutlineInfoCircle className="infoIcon" />
                  </TooltipPro>
                </span>
                <Select
                  options={Constants().day()}
                  selectedOption={dataObj.day}
                  setSelectedOption={(e) => onChangeDay(e, "day")}
                ></Select>
              </div>
              <div className="col-md-3">
                <label>Month</label>
                <span className="ml-2">
                  <TooltipPro
                    className="bottom tsize-400"
                    tooltip={
                      <div className="row">
                        <div className="col-md-12">
                          <p>
                            You can select a Month if you only want to do backup
                            in a particular month.{" "}
                          </p>
                        </div>
                      </div>
                    }
                  >
                    <AiOutlineInfoCircle className="infoIcon" />
                  </TooltipPro>
                </span>
                <Select
                  options={
                    parseInt(dataObj.day) === 31
                      ? Constants().month31
                      : parseInt(dataObj.day) === 30
                      ? Constants().month30
                      : Constants().month
                  }
                  selectedOption={dataObj.month}
                  setSelectedOption={(e) => onChange(e, "month")}
                ></Select>
              </div>
              <div className="col-md-3">
                <label>Weekday</label>
                <span className="ml-2">
                  <TooltipPro
                    className="bottom tsize-400"
                    tooltip={
                      <div className="row">
                        <div className="col-md-12">
                          <p>
                            For a weekly backup you can select a day, then the
                            backup will only be done on that day at the
                            specified time.
                          </p>
                        </div>
                      </div>
                    }
                  >
                    <AiOutlineInfoCircle className="infoIcon" />
                  </TooltipPro>
                </span>
                <Select
                  options={Constants().weekDay}
                  selectedOption={dataObj.weekday}
                  setSelectedOption={(e) => onChange(e, "weekday")}
                ></Select>
              </div>
            </div>

            {Array.isArray(folders) &&
              folders.map((data, index) => {
                return (
                  <ServerBackUpForm
                    hideRemove={folders.length !== 1}
                    errorArray={errorArray}
                    remove={() => remove(index)}
                    data={data}
                    onChangeFolders={onChangeFolders}
                    index={index}
                  ></ServerBackUpForm>
                );
              })}

            <div className="mt-4 disallowedbuttons justify-content-center">
              {/* <Button className="bluebutton mx-1" onClick={() => addFiles()} color="info"><MdAdd></MdAdd></Button> */}
              <MButton
                label="Add More"
                className="bluebutton mx-1"
                onClick={() => addFiles()}
              ></MButton>
              <MButton
                label="Schedule"
                onClick={(e) => onSubmit(e)}
                className="bluebutton mx-1"
              ></MButton>
            </div>
          </Form>
        </div>
      </div>
      <DBBackup
        machine_timezone={machine_timezone}
        MSId={props.MSId}
        history={props.history}
        active={props.active === 3 ? 4 : 3}
        public_key_path={public_key_path}
        setPublicKey={setPublicKey}
      />
      <UploadPath
        MSId={props.MSId}
        history={props.history}
        active={props.active === 3 ? 5 : 3}
      />
    </>
  );
};

export default ServerBackup;
