import React from 'react'
import Header from '../../_components/CommonComponents/Header/Header'
import NotificationChannelList from '../../_components/NotificationChannelList/NotificationChannelList'
class NotificationChannel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        return (<div>
            <Header history={this.props.history} {...this.props}></Header>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <NotificationChannelList {...this.props}></NotificationChannelList>
                    </div>
                </div>
            </div>    
        </div>)
    }
}

export default NotificationChannel