import { RestMethodSecondOrigin } from '../../../_helpers/ApiConfigTwo/RestMethod'
import FilterResponse from '../../../_helpers/FilterResponse'
import {isAuthorized} from '../../../_helpers/Common/Common'

export const monitorUrlsServices = {
    getMonitorURLs,
    updateMonitorURLs,
    addMonitorURLs
}

async function getMonitorURLs(data) {
    try {
        const response = await RestMethodSecondOrigin.POST('/url-monitor/all-url-monitor',data);
        const api_response = FilterResponse(response);  
        return api_response;
    }
    catch (error) {
        isAuthorized(error)
        return null;
    }
}

async function updateMonitorURLs(data) {
    try {
        const response = await RestMethodSecondOrigin.POST('/url-monitor/update-url-monitor',data);
        const api_response = FilterResponse(response);  
        return api_response;
    }
    catch (error) {
        isAuthorized(error)
        return null;
    }
}

async function addMonitorURLs(data) {
    try {
        const response = await RestMethodSecondOrigin.POST('/url-monitor/add-url-monitor',data);
        const api_response = FilterResponse(response);  
        return api_response;
    }
    catch (error) {
        isAuthorized(error)
        return null;
    }
}