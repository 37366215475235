import React from "react";
import cronstrue from "cronstrue";
import moment from "moment";
import toastr from "toastr";
import { Link } from "react-router-dom";
import { Table } from "react-bootstrap";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { MdRefresh, MdRemoveRedEye } from "react-icons/md";
import { BiTimeFive } from "react-icons/bi";
import { IoIosArrowRoundBack, IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { FcGlobe } from "react-icons/fc";

import Header from "../../_components/CommonComponents/Header/Header";
import FolderBackUpList from "../../_components/FolderBackUpList/FolderBackUpList";
import DbBackupList from "../../_components/DbBackupList/DbBackupList";
import MonitorServerLogTable from "../../_components/MonitorServerLogTable/MonitorServerLogTable";
import OpenPortChartOperation from "../../_components/OpenPortChartOperation/OpenPortChartOperation";
import BackupFolderChange from "../../_components/BackUpFolderListing/BackUpFolderListing";
import server_on from "../../assets/img/serveron.png";
import server_off from "../../assets/img/serveroff.png";
import DisallowedFilesForm from "../../_components/MonitorServersComponents/DisallowedFilesForm/DisallowedFilesForm";
import HistoryModal from "../../_components/ServerHistoryTable/ServerHistoryModal";
import OpenNotificationsPopup from "../NotificationChannel/NotificationsPopup";
import DatabaseSchema from "../../_components/DatabaseSchema/DatabaseSchema";
import MysqlRunTime from "../MySqlRuntime/MySqlRuntime";
import OpenPortChartOperationPublic from "../../_components/OpenPortChartOperation/OpenPortChartOperationPublic";
import {
  UsageChart,
  BackupChartOperation,
  ChartMySqlOperation,
  StatisticsLatencyOperation,
  ChartOperationsApacheInner,
  LoggedChartOperation,
} from "../../_components/MonitorServerChart/index";
import { graphsKeys } from "./Constants";
import { monitorServersServices, helperService, graphService, monitorServersSettingServices } from "../../_services/servicesWithSecondOrigin/index";
import { Select, ServerCurrentLoggedIn, Notes, TooltipPro, Pagination } from "../../_components/CommonComponents/index";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { AiOutlineUser, AiFillSetting, AiOutlineInfoCircle, AiOutlineEdit } from "react-icons/ai";
import { Loader } from "../../_components/CommonComponents/index";
import { BsFillXCircleFill } from "react-icons/bs";
import { BsCheckCircleFill } from "react-icons/bs";
import { CpuMemoMadal } from "./CPUMemoModal";
import ChartOperationsApacheInnerNgnix from "../../_components/MonitorServerChart/ChartOperationsApacheInnerNgnix";
import PerformanceChartsOperation from "../../_components/MonitorServerChart/PerformanceChartsOperation";
import TopProcess from "./TopProcess.component";
import { ServerShare } from "../../_components/MonitorServersComponents";
import { Validation } from "../../_helpers/validation/Validation";
import DiskIOProcess from "./DiskIOProcess.component";
import NetworkIOProcess from "./NetworkIoProcess.component";
import DiskPartitionOperation from "../../_components/MonitorServerChart/DiskPartitionDetailsOperation";
import { getQueryObject, objectToQuery } from "../../_helpers/Common/Common";
import MonitorServerCustomLog from "../../_components/MonitorServerLogTable/MonitorServerCustomLog";

class MonitorServereCharts extends React.Component {
  constructor(props) {
    super(props);
    let Timezone = localStorage.getItem("Timezone");
    this.state = {
      id: "",
      uuid: "",
      server_name: "",
      active: 4,
      serverDetails: "",
      awsMetaData: "",
      mySqlErrorMessage: "",
      start_datetime: moment(new Date()).add(-4, "hours").format("YYYY-MM-DD HH:mm:ss"),
      end_datetime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      topProcessData: [],
      mySqlCurrentQuery: [],
      topProcessDataDate: "",
      isRefresh: "",
      mytimezone: Timezone ? Timezone : "",
      browerTimeZone: Timezone ? Timezone : "",
      serverDetailsLog: "",
      last_rebootTime: "",
      dbbackupNotConfigured: "",
      folderbackUpNotConfigured: "",
      processType: "top_process",
      mysqlDetails: "",
      chartRefresh: true,
      unique_id: null,
      databaseAndTable: "",
      dbName: "",
      activeTab: 1,
      isLoading: false,
      selectDateTimeTypeObj: {},
      disk_io_stats_date: null,
      network_io_stats_date: null,
      diskIOKeyList: [],
      diskIOList: [],
      networkIOList: [],
      diskIoWait: [],
      actualDiskIo: [],
      networkIoWait: [],
      actualNetworkIo: [],
      cronJobs: [],
      diskIOType: "disk_io_process",
      networkIOType: "network_io_process",
      openPortType: "private",
      showCron: false,
      cronForm: {
        id: null,
        name: "",
        description: "",
      },
      cronPagination: {
        pageSize: 10,
        pageNumber: 1,
        total: 0,
      },
      loginType: "Failed",
      onRefresh: "",
      performanceStatus: "",
      performanceChartData: "",
      statusandSubstatus: "",
      loadingTab: false,
      App_version: "",
    };
    this.timestamp = 0;

    const query_object = getQueryObject(props.location.search);
    if (query_object) this.props.history.replace(this.props.location.pathname + props.location.search, query_object);
  }

  share = async (id, email, callBack) => {
    let rule = [
      {
        field_name: "email",
        label: "Email",
        type: "email",
        isRequired: true,
      },
    ];
    let errobj = Validation({ email: email }, rule);
    if (errobj && errobj.email === "") {
      let data = {
        email: email,
      };
      let response = await monitorServersServices.shareMonitorServers(id, data);
      if (response && response.message) {
        toastr.warning(response.message);
      }
      if (callBack && response && response.status === 200) {
        callBack();
      }
    } else {
      toastr.warning(errobj.email);
    }
  };

  refreshData = () => {
    this.setState({ onRefresh: Math.random() });
  };

  componentDidMount() {
    if (this.props?.location?.state?.activeTab) {
      const { activeTab } = this.props?.location?.state;
      this.setState(
        {
          activeTab: activeTab,
        },
        () => {
          this.allapisCall();
        }
      );
    } else {
      this.allapisCall();
    }
  }

  allapisCall = async () => {
    await this.onScrollById();
    if (this.props.location.state && this.props.location.state.uuid) {
      await this.getUniqe(this.props.location.state.uuid);
    }
    var timezone_offset_minutes = new Date().getTimezoneOffset();
    timezone_offset_minutes = timezone_offset_minutes === 0 ? 0 : -timezone_offset_minutes;
    let data = {
      timezone: timezone_offset_minutes,
    };
    let response = await helperService.getTimeZoon(data);
    if (response && response.data) {
      this.setState({
        mytimezone: response.data,
        browerTimeZone: response.data,
      });
    }

    if (this.props.location && this.props.location.state && this.props.location.state.id) {
      this.setState(
        {
          id: this.props.location.state.id,
          uuid: this.props.location.state.uuid,
          server_name: this.props.location.state.server_name,
        },
        async () => {
          this.getPerformanceData(this.state.activeTab, "nodbtable", "getMysql");
          // this.getMySqlCurrentQuery();
          // this.getMysqlDetails();
          // this.getdbTable()          not hit;

          await this.serverDetails();
          await this.getTopProcess();
          await this.serverDetailsLog(this.state.id);
          // await this.getMySqlCurrentQuery();
          await this.getActualDiskIo();
          await this.getActualNetworkIo();
        }
      );
    } else {
      this.props.history.push("/monitor-servers");
    }

    this.timestamp = setInterval(() => {
      if (localStorage.getItem("topProcess") !== this.state.topProcessDataDate)
        this.setState({
          topProcessDataDate: localStorage.getItem("topProcess"),
        });

      if (localStorage.getItem("disk_io_stats_date") !== this.state.disk_io_stats_date)
        this.setState({
          disk_io_stats_date: localStorage.getItem("disk_io_stats_date"),
        });
      if (localStorage.getItem("network_io_stats_date") !== this.state.network_io_stats_date)
        this.setState({
          network_io_stats_date: localStorage.getItem("network_io_stats_date"),
        });
    }, 500);
  };

  onScrollById = () => {
    let id = localStorage.getItem("moniterServerScroll");
    if (id && localStorage.getItem("moniterServerScroll") !== "null") {
      this.setState(
        {
          openCloud: id === "awsView" ? true : false,
          activeTab: id === "performance" ? 3 : id === "backup" ? 2 : this.state.activeTab,
        },
        () => {
          //  setTimeout(() => {
          document.getElementById(id).scrollIntoView({
            behavior: "smooth",
            // block: "start",
          });
          setTimeout(() => {
            window.scrollBy(0, -80);
          }, 500);
          localStorage.setItem("moniterServerScroll", null);
          //  }, 1000)
        }
      );
    }
  };

  getUniqe = async (uuid) => {
    let data = {
      uuid: uuid,
    };
    let res = await monitorServersServices.selectedId(data);
    if (res && res.data && res.data && res.data.unique_id) {
      this.setState({
        // unique_id: res.data.unique_id
      });
    }
  };

  getCronJobs = () => {
    this.getCronJobsFromServer();
    setTimeout(() => {
      this.getCronJobsFromServer(1);
    }, 1000);
  };

  getCronJobsFromServer = (last_run_time) => {
    this.setState({ isLoading: last_run_time ? false : true }, async () => {
      const response = await monitorServersServices.getMonitorServerCronList({
        resource_id: this.state.id,
        pageSize: 10,
        last_run_time,
      });
      if (Array.isArray(response.data)) {
        this.setState(
          {
            cronJobs: response.data,
            cronPagination: {
              ...this.state.cronPagination,
              total: response.total || response.data.length,
            },
          },
          () => {
            this.setState({ isLoading: false });
          }
        );

        // this.setState((prevState) => ({ ...prevState, cronJobs: response.data, cronPagination: { ...prevState.cronPagination, total: response.total || response.data.length } }),()=>{
        //     setTimeout(()=>{
        //         this.setState({ isLoading: false})
        //     },2000)
        // });
      }
    });
  };

  serverDetails = async () => {
    var d = new Date();
    var n = d.getTimezoneOffset();
    this.setState({
      serverDetails: "",
      awsMetaData: "",
    });

    if (
      this.state.activeTab === 4
      //&& !this.state.isLoading
    ) {
      this.setState(
        (prevState) => ({
          ...prevState,
          cronPagination: { ...prevState.cronPagination, pageNumber: 1 },
        }),
        this.getCronJobs
      );
    }

    if (this.state.mytimezone) {
      this.lastRebootTime();
      let response = await monitorServersServices.monitorServerChartDetails({
        uuid: this.state.uuid,
        timezone: this.state.mytimezone,
        timediff: n,
      });
      if (response && response.data) {
        this.setState({
          serverDetails: response.data,
          App_version: response.data?.App_version || "",
        });
      }

      let data = { uuid: this.state.uuid };

      if (this.state.unique_id) {
        data.unique_id = this.state.unique_id;
      }

      let awsMetaData = await monitorServersServices.awsMetaData(data);
      if (awsMetaData && awsMetaData.data && awsMetaData.data) {
        this.setState({
          awsMetaData: awsMetaData.data,
        });
      }
    }
  };

  getMysqlDetails = async () => {
    if (this.state.activeTab === 3) {
      this.setState({
        mysqlDetails: "",
      });

      let data = {
        uuid: this.state.uuid,
        timezone: this.state.mytimezone,
        user_element: "mysql_metadata1",
      };

      if (this.state.unique_id) {
        data.unique_id = this.state.unique_id;
      }

      if (this.state.mytimezone) {
        let mysqlDetails = await graphService.getMysqlDetails(data);
        if (mysqlDetails && mysqlDetails.data && mysqlDetails.data) {
          this.getMySqlErrorStatus();
          this.setState({
            mysqlDetails: mysqlDetails.data,
          });
        }
      }
    }
  };

  lastRebootTime = async () => {
    if (this.state.mytimezone) {
      let response = await monitorServersServices.lastRebootTime({
        uuid: this.state.uuid,
        timezone: this.state.mytimezone,
        user_element: "currently_logged_in",
      });
      if (response && response.data) {
        this.setState({
          last_rebootTime: response.data,
        });
      } else {
        this.setState({
          last_rebootTime: "",
        });
      }
    }
  };

  serverDetailsLog = async (id) => {
    let data = {
      id: id,
      timezone: this.state.mytimezone,
    };

    let response = await monitorServersServices.monitorServerChartDetailsLog(data);
    if (response && response.data && response.data.server_status_log) {
      this.setState({
        serverDetailsLog: response.data.server_status_log,
      });
    }
  };

  onActive = (value) => {
    this.setState(
      {
        active: value,
        start_datetime: moment(new Date()).add(-value, "hours").format("YYYY-MM-DD HH:mm:ss"),
      },
      () => {
        this.getTopProcess();
        this.getMySqlCurrentQuery();
        this.getActualDiskIo();
        this.getActualNetworkIo();
      }
    );
  };

  onChangeCronPageSize = (size) =>
    this.setState((prevState) => ({
      ...prevState,
      cronPagination: { ...prevState.cronPagination, pageSize: size.value },
    }));
  onChangeCronPageNumber = (number) =>
    this.setState((prevState) => ({
      ...prevState,
      cronPagination: { ...prevState.cronPagination, pageNumber: number },
    }));

  updateCronJob = async () => {
    const { id, name, description } = this.state.cronForm;

    const errors = {};

    if (!name) errors.nameError = "Name is required.";
    // if (!description) errors.descriptionError = "Description is required.";

    if (
      !name
      // || !description
    )
      return this.setState((prevState) => ({
        ...prevState,
        cronForm: { ...prevState.cronForm, ...errors },
      }));

    const response = await monitorServersServices.updateMonitorServerCronJob({
      id,
      name,
      description,
    });

    if (response?.status !== 200) return toastr.warning(response?.message || "Something went wrong.");

    toastr.success(response.message || "Updated successfully.");
    this.setState((prevState) => ({
      ...prevState,
      cronForm: { id: null, name: "", description: "" },
      showCron: false,
      cronJobs: prevState.cronJobs.map((c) => (c.ID !== id ? c : { ...c, Name: name ?? "", Description: description ?? "" })),
    }));
  };

  toggleEditCron = (data) => {
    this.setState((prevState) => ({
      ...prevState,
      showCron: !prevState.showCron,
      cronForm: {
        id: data.ID,
        name: data.Name ?? "",
        description: data.Description ?? "",
      },
    }));
  };

  getTopProcess = async () => {
    if (this.state.activeTab === 1) {
      this.setState(
        {
          topProcessData: [],
          processType: "top_process",
          chartRefresh: false,
        },
        () => {
          setTimeout(() => {
            this.setState({ chartRefresh: true });
          }, 500);
        }
      );
      let data = {
        uuid: this.state.uuid,
        user_element: "top_process",
        start_datetime: moment(new Date()).add(-this.state.active, "hours").format("YYYY-MM-DD HH:mm:ss"),
        end_datetime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
        timezone: this.state.mytimezone,
      };
      if (this.state.unique_id) {
        data.unique_id = this.state.unique_id;
      }

      let response = await monitorServersServices.topProcessServerDetails(data);
      if (response && response.data) {
        this.setState({
          topProcessData: typeof response.data === "object" ? Object.entries(response.data) : [],
        });
      }
    }
  };

  diskIOProcessServerDetails = async (top_process) => {
    this.setState({
      topProcessData: [],
      processType: "disk_io_process",
    });
    let data = {
      uuid: this.state.uuid,
      user_element: "disk_io_process",
      start_datetime: moment(new Date()).add(-this.state.active, "hours").format("YYYY-MM-DD HH:mm:ss"),
      end_datetime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      timezone: this.state.mytimezone,
    };
    let response = await monitorServersServices.diskIOProcessServerDetails(data);
    if (response && response.data) {
      this.setState({
        topProcessData: typeof response.data === "object" ? Object.entries(response.data) : [],
      });
    }
  };

  getMySqlCurrentQuery = async () => {
    let d = new Date();
    var n = d.getTimezoneOffset();
    if (this.state.activeTab === 3) {
      this.setState({
        mySqlCurrentQuery: [],
      });
      let data = {
        uuid: this.state.uuid,
        user_element: "mysql_metadata1",
        start_datetime: moment(new Date()).add(-this.state.active, "hours").format("YYYY-MM-DD HH:mm:ss"),
        end_datetime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
        timezone: this.state.mytimezone,
        timediff: n,
      };

      if (this.state.unique_id) {
        data.unique_id = this.state.unique_id;
      }

      let response = await graphService.getMysqlChartGraphData(data, "mysql-current-query");

      if (response && response.data) {
        this.setState({
          mySqlCurrentQuery: typeof response.data === "object" ? Object.entries(response.data) : [],
        });
      }
    }
  };

  onSelectServer = (e) => {
    this.setState(
      {
        id: e.id,
        uuid: e.value,
        server_name: e.label,
        topProcessData: [],
        selectDateTimeTypeObj: {},
        disk_io_stats_date: null,
        network_io_stats_date: null,
        diskIoWait: [],
        actualDiskIo: [],
        networkIoWait: [],
        actualNetworkIo: [],
        // unique_id: e?.unique ? e.unique : null
        App_version: e.Appversion,
      },
      async () => {
        await this.getTopProcess();
        await this.serverDetails();
        // await this.getMysqlDetails();
        // await this.getdbTable();
        // await this.getMySqlCurrentQuery();
        this.getPerformanceData(this.state.activeTab);
        await this.serverDetailsLog(this.state.id);
        await this.getActualDiskIo();
        await this.getActualNetworkIo();
      }
    );
  };

  getdbTable = async () => {
    if (this.state.activeTab === 3) {
      this.setState({
        databaseAndTable: "",
      });
      let data = {
        uuid: this.state.uuid,
        timezone: this.state.mytimezone,
      };

      if (this.state.unique_id) {
        data.unique_id = this.state.unique_id;
      }

      let response = await graphService.getMysqlDBTable(data);

      if (response && response.data && typeof response.data === "object") {
        this.setState({
          databaseAndTable: response.data,
        });
      }
    }
  };

  refresh = () => {
    this.setState(
      {
        isRefresh: Math.random(),
        selectDateTimeTypeObj: {},
        disk_io_stats_date: null,
        network_io_stats_date: null,
        diskIoWait: [],
        actualDiskIo: [],
        networkIoWait: [],
        actualNetworkIo: [],
      },
      async () => {
        await this.getTopProcess();
        await this.serverDetails();

        this.getPerformanceData(this.state.activeTab, "nodbtable");
        // this.getMySqlCurrentQuery();
        // this.getMysqlDetails();
        // this.getdbTable()          not hit;

        await this.getActualDiskIo();
        await this.getActualNetworkIo();
      }
    );
  };

  getMySqlErrorStatus = async () => {
    this.setState({
      mySqlErrorMessage: "",
    });
    const data = {
      uuid: this.state.uuid,
    };
    const response = await monitorServersSettingServices.getGeneralSettingMySqlStatus(data);

    if (response?.data?.message) {
      this.setState({
        mySqlErrorMessage: response.data.message,
      });
    }
  };

  onChangeTimeZone = (e) => {
    this.setState({
      mytimezone: e,
    });
  };

  getTopProcessDate = (date, type) => {
    let selectDateTimeTypeObj = this.state.selectDateTimeTypeObj;

    if (type) {
      selectDateTimeTypeObj[type] = date;
    }

    this.setState({
      selectDateTimeTypeObj: selectDateTimeTypeObj,
    });
  };

  componentWillUnmount() {
    clearInterval(this.timestamp);
    localStorage.setItem("topProcess", "");
  }

  onSelectUniqueId = (e) => {
    this.setState({
      unique_id: e.value === "all" ? null : e.value,
    });
  };

  activeTabSet = (tab, id) => {
    let eid = this.state.id ? this.state.id : id;
    this.setState(
      {
        activeTab: tab,
        id: eid,
      },
      () => {
        this.getPerformanceData(this.state.activeTab);
        // this.getMysqlDetails();
        // this.getdbTable();
        // this.getMySqlCurrentQuery();
        if (tab === 4) {
          this.setState(
            (prevState) => ({
              ...prevState,
              cronPagination: { ...prevState.cronPagination, pageNumber: 1 },
            }),
            this.getCronJobs
          );
        }
      }
    );
  };

  getPerformanceData = async (tab, nodb, nogetMysql) => {
    if (tab === 3) {
      this.setState({ loadingTab: true });
      let d = new Date();
      var n = d.getTimezoneOffset();
      let res = await monitorServersSettingServices.getGeneralSettingSave(this.state.id);

      this.setState({ loadingTab: false });

      if (res?.data) {
        let response = await monitorServersSettingServices.getPerformanceStatus({
          uuid: this.state.uuid,
          monitor_server_id: this.state.id,
          start_datetime: moment(new Date()).add(-this.state.active, "hours").format("YYYY-MM-DD HH:mm:ss"),
          end_datetime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
          timezone: this.state.mytimezone,
          timediff: n,
          // "uuid": "603cebf9b5f25",
          // "monitor_server_id": 22,
          // "start_datetime": "2022-07-06 08:04:09",
          // "end_datetime": "2022-07-09 12:04:09",
          // "timezone": "Asia/Kolkata",
          // "timediff": -330
        });

        this.setState(
          {
            performanceStatus: res.data,
            statusandSubstatus: response?.data ? response?.data : "",
          },
          () => {
            !nogetMysql && this.getMysqlDetails();
            !nodb && this.getdbTable();
            this.getMySqlCurrentQuery();
            this.getPerformanceChartsData();
          }
        );
      }
    }
  };

  getPerformanceChartsData = async () => {
    this.setState({ performanceChartData: "" });
    let d = new Date();
    var n = d.getTimezoneOffset();
    let res = await monitorServersSettingServices.getPerformanceChartsData({
      // "uuid": "600bd189915ed",
      // "monitor_server_id": 6,
      // "start_datetime": "2022-07-09 13:37:10",
      // "end_datetime": "2022-07-09 17:48:10",
      // "timezone": "Asia/Kolkata",
      // "timediff": -330,

      uuid: this.state.uuid,
      monitor_server_id: this.state.id,
      start_datetime: moment(new Date()).add(-this.state.active, "hours").format("YYYY-MM-DD HH:mm:ss"),
      end_datetime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      timezone: this.state.mytimezone,
      timediff: n,
    });
    const { data } = res || {};
    if (data) {
      this.setState({
        performanceChartData: data,
      });
    }
  };

  // getActualDiskIoWait = async () => {
  //     let d = new Date();
  //     var n = d.getTimezoneOffset();
  //     let data = {
  //         uuid: this.state.uuid,
  //         user_element: "actual_disk_io_process",
  //         start_datetime: moment(new Date()).add(-this.state.active, "hours").format("YYYY-MM-DD HH:mm:ss"),
  //         end_datetime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
  //         timezone: this.state.mytimezone,
  //         timediff: n,
  //     };

  //     let diskIoWait = [];

  //     let response = await graphService.getMysqlChartGraphData(data, "disk-io-wait-process");
  //     if (response && response.data) {
  //         diskIoWait = typeof response.data === "object" ? Object.entries(response.data) : [];
  //     }

  //     this.setState({
  //         diskIoWait: diskIoWait,
  //         diskIOType: "disk_io_wait_process",
  //     });
  // };

  getActualDiskIo = async () => {
    let d = new Date();
    var n = d.getTimezoneOffset();
    let data = {
      uuid: this.state.uuid,
      user_element: "actual_disk_io_process",
      start_datetime: moment(new Date()).add(-this.state.active, "hours").format("YYYY-MM-DD HH:mm:ss"),
      end_datetime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      timezone: this.state.mytimezone,
      timediff: n,
    };

    if (this.state.unique_id) {
      data.unique_id = this.state.unique_id;
    }

    let actualDiskIo = [];

    let response = await graphService.getMysqlChartGraphData(data, "actual-disk-io-process");
    if (response && response.data) {
      actualDiskIo = typeof response.data === "object" ? Object.entries(response.data) : [];
    }

    this.setState({
      actualDiskIo,
      diskIOType: "disk_io_process",
    });
  };

  getActualNetworkIo = async () => {
    let d = new Date();
    var n = d.getTimezoneOffset();
    let data = {
      uuid: this.state.uuid,
      user_element: "nethogs_data",
      start_datetime: moment(new Date()).add(-this.state.active, "hours").format("YYYY-MM-DD HH:mm:ss"),
      end_datetime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      timezone: this.state.mytimezone,
      timediff: n,
    };

    if (this.state.unique_id) {
      data.unique_id = this.state.unique_id;
    }

    let actualNetworkIo = [];

    let response = await graphService.getMysqlChartGraphData(data, "actual-network-io-process");
    if (response && response.data) {
      actualNetworkIo = typeof response.data === "object" ? Object.entries(response.data) : [];
    }

    this.setState({
      actualNetworkIo,
      networkIOType: "network_io_process",
    });
  };

  render() {
    const { processType, isLoading } = this.state;
    let topProcessData = [];
    let date = "";
    let processArray = JSON.parse(JSON.stringify(this.state.topProcessData));
    Array.isArray(processArray) &&
      processArray.map((data) => {
        if (data && data[0] === this.state.topProcessDataDate && data[1]) {
          date = this.state.topProcessDataDate;
          topProcessData = data[1];
        }
      });

    if (Array.isArray(topProcessData) && topProcessData.length > 0) {
      if (this.state.processType === "top_process") {
        topProcessData.map((d, i) => {
          d.sid = parseFloat(i + 1);
          d.PID = parseFloat(d.PID);
          d["%MEM"] = parseFloat(d["%MEM"]);
          d["%CPU"] = parseFloat(d["%CPU"]);
        });
      } else {
        topProcessData.map((d, i) => {
          d.sid = parseFloat(i + 1);
          d.PID = parseFloat(d.PID);
          d["%MEM"] = parseFloat(d["%MEM"]);
          d.Write = parseFloat(d.Write) || 0;
        });
      }
    }

    let mySqlCurrentQuery = [];
    let datemysql = "";
    let mySqlCurrentQueryArray = JSON.parse(JSON.stringify(this.state.mySqlCurrentQuery));
    Array.isArray(mySqlCurrentQueryArray) &&
      mySqlCurrentQueryArray.map((data) => {
        if (data && data[0] === this.state.topProcessDataDate && data[1]) {
          datemysql = this.state.topProcessDataDate;
          mySqlCurrentQuery = data[1];
        }
      });

    let disk_io_stats_date = this.state.disk_io_stats_date;
    let network_io_stats_date = this.state.network_io_stats_date;

    let diskIOList = [];
    let diskIOKeyList = [];
    let networkIOList = [];
    let networkIOKeyList = [];

    if (disk_io_stats_date && this.state.diskIOType === "disk_io_process") {
      let actualDiskIo = this.state.actualDiskIo;
      diskIOKeyList = [
        {
          dataField: "sid",
          text: "#",
          sort: true,
          headerStyle: (colum, colIndex) => {
            return { width: "10px" };
          },
        },
        {
          dataField: "PID",
          text: "PID",
          sort: true,
          headerStyle: (colum, colIndex) => {
            return { width: "20px" };
          },
          formatter: (cellContent, row) => {
            return <CpuMemoMadal COMMAND={row.COMMAND} cellContent={cellContent} uuid={this.state.uuid} />;
          },
        },
        {
          dataField: "Process",
          text: "Process",
          sort: true,
          headerStyle: (colum, colIndex) => {
            return { width: "50px" };
          },
        },
        {
          dataField: "Read",
          text: "Read (KB/s)",
          sort: true,
          headerStyle: (colum, colIndex) => {
            return { width: "30px" };
          },
        },
        {
          dataField: "Write",
          text: "Write (KB/s)",
          sort: true,
          headerStyle: (colum, colIndex) => {
            return { width: "30px" };
          },
        },
      ];

      if (Array.isArray(actualDiskIo)) {
        actualDiskIo.map((data) => {
          if (data && data[0] === disk_io_stats_date && data[1]) {
            diskIOList = data[1];
          }
        });
      }

      diskIOList.map((d, i) => {
        d.sid = parseFloat(i + 1);
        d.PID = parseFloat(d.PID);
        d.Read = parseFloat(d.Read) || 0;
        d.Write = parseFloat(d.Write) || 0;
      });
    } else if (this.state.diskIOType === "disk_io_wait_process") {
      let diskIoWait = this.state.diskIoWait;
      diskIOKeyList = [
        {
          dataField: "sid",
          text: "#",
          sort: true,
          headerStyle: (colum, colIndex) => {
            return { width: "10px", textAlign: "center" };
          },
        },
        {
          dataField: "PID",
          text: "PID",
          sort: true,
          headerStyle: (colum, colIndex) => {
            return { width: "15px" };
          },
          formatter: (cellContent, row) => {
            return <CpuMemoMadal COMMAND={row.COMMAND} cellContent={cellContent} uuid={this.state.uuid} />;
          },
        },
        {
          dataField: "COMMAND",
          text: "COMMAND",
          sort: true,
          headerStyle: (colum, colIndex) => {
            return { width: "50px" };
          },
        },
        {
          dataField: "Cumulative IO-wait ticks",
          text: "Cumulative IO-wait ticks",
          sort: true,
          headerStyle: (colum, colIndex) => {
            return { width: "40px" };
          },
        },
        {
          dataField: "IO-wait ticks",
          text: "IO-wait ticks",
          sort: true,
          headerStyle: (colum, colIndex) => {
            return { width: "40px" };
          },
        },
      ];

      if (Array.isArray(diskIoWait)) {
        diskIoWait.map((data) => {
          if (data && data[0] === disk_io_stats_date && data[1]) {
            diskIOList = data[1];
          }
        });
      }

      diskIOList.map((d, i) => {
        d.sid = parseFloat(i + 1);
        d.PID = parseFloat(d.PID);
        d["Cumulative IO-wait ticks"] = parseFloat(d["Cumulative IO-wait ticks"]);
      });
    }
    if (network_io_stats_date && this.state.networkIOType === "network_io_process") {
      let actualNetworkIo = this.state.actualNetworkIo;
      networkIOKeyList = [
        {
          dataField: "sid",
          text: "#",
          sort: true,
          headerStyle: (colum, colIndex) => {
            return { width: "10px" };
          },
        },
        {
          dataField: "Process",
          text: "Process",
          sort: true,
          headerStyle: (colum, colIndex) => {
            return { width: "50px" };
          },
        },
        {
          dataField: "Received",
          text: "Network In (KB/s)",
          sort: true,
          headerStyle: (colum, colIndex) => {
            return { width: "30px" };
          },
        },
        {
          dataField: "Sent",
          text: "Network Out (KB/s)",
          sort: true,
          headerStyle: (colum, colIndex) => {
            return { width: "30px" };
          },
        },
      ];

      if (Array.isArray(actualNetworkIo)) {
        actualNetworkIo.map((data) => {
          if (data && data[0] === network_io_stats_date && data[1]) {
            networkIOList = data[1];
          }
        });
      }

      networkIOList.map((d, i) => {
        d.sid = parseFloat(i + 1);
        d.Process = d.pid;
        d.Received = parseFloat(d.received);
        d.Sent = parseFloat(d.sent) || 0;
      });
    } else if (this.state.networkIOType === "network_io_wait_process") {
      let networkIoWait = this.state.networkIoWait;
      networkIOKeyList = [
        {
          dataField: "sid",
          text: "#",
          sort: true,
          headerStyle: (colum, colIndex) => {
            return { width: "10px", textAlign: "center" };
          },
        },
        {
          dataField: "PID",
          text: "PID",
          sort: true,
          headerStyle: (colum, colIndex) => {
            return { width: "15px" };
          },
          formatter: (cellContent, row) => {
            return <CpuMemoMadal COMMAND={row.COMMAND} cellContent={cellContent} uuid={this.state.uuid} />;
          },
        },
        {
          dataField: "COMMAND",
          text: "COMMAND",
          sort: true,
          headerStyle: (colum, colIndex) => {
            return { width: "50px" };
          },
        },
        {
          dataField: "Cumulative IO-wait ticks",
          text: "Cumulative IO-wait ticks",
          sort: true,
          headerStyle: (colum, colIndex) => {
            return { width: "40px" };
          },
        },
        {
          dataField: "IO-wait ticks",
          text: "IO-wait ticks",
          sort: true,
          headerStyle: (colum, colIndex) => {
            return { width: "40px" };
          },
        },
      ];

      if (Array.isArray(networkIoWait)) {
        networkIoWait.map((data) => {
          if (data && data[0] === network_io_stats_date && data[1]) {
            networkIOList = data[1];
          }
        });
      }

      networkIOList.map((d, i) => {
        d.sid = parseFloat(i + 1);
        d.Process = d.pid;
        d["Cumulative IO-wait ticks"] = parseFloat(d["Cumulative IO-wait ticks"]);
      });
    }

    let toredirec = parseInt(localStorage.getItem("chartsRedireactTo"));

    let properties = {
      id: this.state.id,
      uuid: this.state.uuid,
      server_name: this.state.server_name,
      mytimezone: this.state.mytimezone,
      unique_id: this.state.unique_id,
      activeTab: this.state.activeTab,
    };

    return (
      <div className="container">
        <Header history={this.props.history} {...this.props} callRefresh={this.refreshData}></Header>
        <div className="serverDetailsSec topbarFixMob">
          <div className="serverDetailsSecIcons">
            <OverlayTrigger placement="bottom" overlay={<Tooltip>Back</Tooltip>}>
              <Button
                className="backBtn mr-3 mr-lg-3"
                onClick={() => {
                  toredirec === 1 ? this.props.history.push("/monitor-servers") : this.props.history.goBack();
                }}
              >
                <IoIosArrowRoundBack></IoIosArrowRoundBack>
              </Button>
            </OverlayTrigger>
            {this.state.serverDetails && this.state.serverDetails.server_status && this.state.serverDetails.server_status.status === "UP" ? (
              <OverlayTrigger placement="bottom" overlay={<Tooltip>Server is UP</Tooltip>}>
                <img src={server_on} width="30px" className="mr-3" alt="server_on" />
              </OverlayTrigger>
            ) : (
              <OverlayTrigger placement="bottom" overlay={<Tooltip>Server is DOWN</Tooltip>}>
                <img src={server_off} width="30px" className="mr-3" alt="server_off"></img>
              </OverlayTrigger>
            )}
          </div>{" "}
          <ServerCurrentLoggedIn
            server_name={this.state.server_name}
            uuid={this.state.uuid}
            onSelectServer={this.onSelectServer}
            mytimezone={this.state.mytimezone}
            unique_id={this.state.unique_id}
            onSelectUniqueId={this.onSelectUniqueId}
          />
          <div className="d-flex smlBlueIcons">
            <OverlayTrigger placement="bottom" overlay={<Tooltip>Refresh</Tooltip>}>
              <Button className="iconBtn addIconBtn ml-0" onClick={() => this.refresh()}>
                <MdRefresh></MdRefresh>
              </Button>
            </OverlayTrigger>
            <OverlayTrigger placement="bottom" overlay={<Tooltip>Manage Server Setting</Tooltip>}>
              <Button
                className="iconBtn ml-2 mr-2"
                onClick={() =>
                  this.props.history.push("/monitor-server/server-setting", {
                    unique_id: this.state.unique_id,
                    id: this.state.id,
                    uuid: this.state.uuid,
                    server_name: this.state.server_name,
                    update: true,
                  })
                }
              >
                {" "}
                <AiFillSetting></AiFillSetting>
              </Button>
            </OverlayTrigger>

            <Notes id={this.state.id} type="monitor_server"></Notes>

            <ServerShare uuid={this.state.uuid} className="actionIcon" id={this.state.id} share={this.share} server_name={this.state.server_name} placement="bottom"></ServerShare>
          </div>
        </div>
        <div className="charts-screen-tabs">
          <Button onClick={() => this.activeTabSet(1)} color={this.state.activeTab === 1 ? "primary" : ""}>
            General
          </Button>
          <Button onClick={() => this.activeTabSet(2)} color={this.state.activeTab === 2 ? "primary" : ""}>
            Backup
          </Button>
          <Button onClick={() => this.activeTabSet(3)} color={this.state.activeTab === 3 ? "primary" : ""}>
            Performance
          </Button>
          <Button onClick={() => this.activeTabSet(4)} color={this.state.activeTab === 4 ? "primary" : ""}>
            CRON Jobs
          </Button>
          <Button onClick={() => this.activeTabSet(5)} color={this.state.activeTab === 5 ? "primary" : ""}>
            Custom Logs
          </Button>
        </div>

        {this.state.activeTab !== 2 && (
          <div className="card charts-screen-data overflow-inherit" id="awsView">
            <div className="card-header flexDisableMob">
              <div className="d-flex serverDetailsHead">
                {this.state.activeTab !== 4 && this.state.activeTab !== 3 && <h3>{this.state.activeTab === 1 ? "Server" : "MySQL"} Details</h3>}
                <div className="sDetailsCol pl-md-5 pl-0">
                  <label>UUId:</label>
                  <strong>{this.state.uuid ? `(${this.state.uuid})` : ""} </strong>
                </div>
              </div>
              <div className="d-flex serverDetailsHead">
                <div className="sDetailsCol pl-5">
                  <label>OS:</label>
                  <strong>{this.state.serverDetails && this.state.serverDetails.system_os_info && this.state.serverDetails.system_os_info["description"]}</strong>
                  <strong className="ml-2">
                    {" "}
                    {this.state.serverDetails && this.state.serverDetails.system_os_info && "(" + this.state.serverDetails.system_os_info["platform-name"]}
                  </strong>
                  <strong className="ml-2">
                    {this.state.serverDetails && this.state.serverDetails.system_os_info && this.state.serverDetails.system_os_info["os-name"] + ")"}
                  </strong>
                </div>
              </div>
            </div>
            <div className="serverDetails">
              <div className="row">
                <div className={this.state.activeTab === 4 ? "col-md-9 p-3" : "col-md-6"}>
                  {this.state.activeTab === 1 ? (
                    <>
                      <div className="sDetailsCol">
                        <label>Last Login:</label>
                        <strong>
                          {this.state.serverDetails && this.state.serverDetails.get_last_login && (
                            <div>
                              <TooltipPro
                                className="bottom tsize-400"
                                tooltip={
                                  <div className="row">
                                    <div className="col-md-12">
                                      <p>This shows when was the last SSH login on the server.</p>
                                    </div>
                                  </div>
                                }
                              >
                                <AiOutlineInfoCircle className="infoIcon" />
                              </TooltipPro>
                              <AiOutlineUser size={15} color={"#6982bc"}></AiOutlineUser>
                              {`${this.state.serverDetails.get_last_login.name}`}
                              <FcGlobe size={15} color={"#6982bc"}></FcGlobe>
                              {` ${this.state.serverDetails.get_last_login.ip}`} <BiTimeFive size={15} color={"#6982bc"}></BiTimeFive>
                              {`${this.state.serverDetails.get_last_login.date} ${this.state.serverDetails.get_last_login.status}`}
                            </div>
                          )}
                        </strong>
                        {this.state.serverDetails && this.state.serverDetails.get_last_login && (
                          <HistoryModal
                            tableName="Current Login"
                            user_element="currently_logged_in"
                            uuid={this.state.uuid}
                            myTimezone={this.state.mytimezone}
                            tooltip="Last login history"
                            unique_id={this.state.unique_id}
                          />
                        )}
                      </div>

                      <div className="sDetailsCol">
                        <label>Last Reboot Time:</label>
                        <strong>
                          <TooltipPro
                            className="bottom tsize-400"
                            tooltip={
                              <div className="row">
                                <div className="col-md-12">
                                  <p>This shows the time when the server was last rebooted.</p>
                                </div>
                              </div>
                            }
                          >
                            <AiOutlineInfoCircle className="infoIcon" />
                          </TooltipPro>
                        </strong>
                        <strong>
                          {this.state.last_rebootTime && this.state.last_rebootTime.last_reboot_time}
                          {this.state.serverDetails && this.state.last_rebootTime.last_reboot_time && (
                            <HistoryModal
                              tableName="Last Reboot Time"
                              user_element="last_reboot_time"
                              uuid={this.state.uuid}
                              myTimezone={this.state.mytimezone}
                              firstCol={"Date"}
                              tooltip={`Last reboot history`}
                              unique_id={this.state.unique_id}
                            />
                          )}
                        </strong>
                      </div>

                      <div className="sDetailsCol">
                        <label>Server timezone:</label>
                        <strong>
                          <TooltipPro
                            className="bottom tsize-400"
                            tooltip={
                              <div className="row">
                                <div className="col-md-12">
                                  <p>This shows the timezone that is set on the server.</p>
                                </div>
                              </div>
                            }
                          >
                            <AiOutlineInfoCircle className="infoIcon" />
                          </TooltipPro>
                        </strong>
                        <strong>
                          {this.state.serverDetails && this.state.serverDetails.machine_timezone}
                          {this.state.serverDetails && this.state.serverDetails.machine_timezone && (
                            <HistoryModal
                              tableName="Server timezone"
                              user_element="machine_timezone"
                              uuid={this.state.uuid}
                              myTimezone={this.state.mytimezone}
                              firstCol={"Timezone"}
                              tooltip="Change history of timezone on server"
                              unique_id={this.state.unique_id}
                            />
                          )}
                        </strong>
                      </div>
                      <div className="sDetailsCol">
                        <label>Agent Version:</label>
                        <TooltipPro
                          className="bottom tsize-400"
                          tooltip={
                            <div className="row">
                              <div className="col-md-12">
                                <p>This shows the current G8Keeper Agent running on the server.</p>
                              </div>
                            </div>
                          }
                        >
                          <AiOutlineInfoCircle className="infoIcon" />
                        </TooltipPro>
                        <strong>
                          {this.state.serverDetails && this.state.serverDetails.App_version}
                          {this.state.serverDetails && this.state.serverDetails.App_version && (
                            <HistoryModal
                              tableName="Agent Version"
                              user_element="App_version"
                              uuid={this.state.uuid}
                              myTimezone={this.state.mytimezone}
                              firstCol={"IP"}
                              tooltip="G8keeper agent update history"
                              unique_id={this.state.unique_id}
                            />
                          )}
                        </strong>
                      </div>
                    </>
                  ) : (
                    <>
                      {this.state.activeTab === 4 ? (
                        <div>
                          <p>
                            This displays the CRON jobs scheduled on the server using crontab. It displays the CRON command, user under which the cron command is scheduled, the run
                            schedule of the CRON jobs.Depending on the operating system and server settings, We also display when the job was last executed, and was the job
                            successful or failed. You can also view all the previous execution details by clicking "View Details" for a specific job.
                          </p>
                          <p> Optionally you can add a Name and description for the CRON job so that you can easily understand what the job actually does.</p>
                        </div>
                      ) : (
                        <>
                          {/* <div className="sDetailsCol">
                                                    <label>Mysql Timezone:</label> <strong>{this.state.mysqlDetails && this.state.mysqlDetails.mysql_time_zone}</strong>
                                                </div>
                                                <div className="sDetailsCol">
                                                    <label>Mysql Version:</label> <strong>{this.state.mysqlDetails && this.state.mysqlDetails.mysql_version}</strong>
                                                </div> */}
                        </>
                      )}
                    </>
                  )}
                </div>
                {this.state.activeTab !== 4 && (
                  <div className="col-md-3">
                    {this.state.activeTab === 1 ? (
                      <>
                        <div className="sDetailsCol">
                          <label>Public IP:</label>
                          <TooltipPro
                            className="bottom tsize-400"
                            tooltip={
                              <div className="row">
                                <div className="col-md-12">
                                  <p>This shows the current public IP of the server. Your server can remotely be accessed on this IP.</p>
                                </div>
                              </div>
                            }
                          >
                            <AiOutlineInfoCircle className="infoIcon" />
                          </TooltipPro>
                          <strong>
                            {this.state.serverDetails && this.state.serverDetails.Public_ip}
                            {this.state.serverDetails && this.state.serverDetails.Public_ip && (
                              <HistoryModal
                                tableName="Public IP"
                                user_element="Public_ip"
                                uuid={this.state.uuid}
                                myTimezone={this.state.mytimezone}
                                firstCol="IP"
                                tooltip="Public IP History"
                                unique_id={this.state.unique_id}
                              />
                            )}
                          </strong>
                        </div>
                        <div className="sDetailsCol">
                          <label>Private IP:</label>
                          <TooltipPro
                            className="bottom tsize-400"
                            tooltip={
                              <div className="row">
                                <div className="col-md-12">
                                  <p>Some Cloud networks provide private IP for servers. Servers can be accessed with that IP on virtual private cloud.</p>
                                </div>
                              </div>
                            }
                          >
                            <AiOutlineInfoCircle className="infoIcon" />
                          </TooltipPro>
                          <strong>
                            {this.state.serverDetails && this.state.serverDetails.private_ip}
                            {this.state.serverDetails && this.state.serverDetails.private_ip && (
                              <HistoryModal
                                tableName="Private IP"
                                user_element="private_ip"
                                uuid={this.state.uuid}
                                myTimezone={this.state.mytimezone}
                                firstCol="IP"
                                tooltip="Private IP History"
                                unique_id={this.state.unique_id}
                              />
                            )}
                          </strong>
                        </div>
                        <div className="sDetailsCol">
                          <label>Mac Address:</label>
                          <TooltipPro
                            className="bottom tsize-400"
                            tooltip={
                              <div className="row">
                                <div className="col-md-12">
                                  <p>This shows the mac address of the server.</p>
                                </div>
                              </div>
                            }
                          >
                            <AiOutlineInfoCircle className="infoIcon" />
                          </TooltipPro>
                          <strong>
                            {this.state.serverDetails && this.state.serverDetails.mac_address}
                            {this.state.serverDetails && this.state.serverDetails.mac_address && (
                              <HistoryModal
                                tableName="Mac Address"
                                user_element="mac_address"
                                uuid={this.state.uuid}
                                myTimezone={this.state.mytimezone}
                                firstCol="IP"
                                tooltip="Mac address update history"
                                unique_id={this.state.unique_id}
                              />
                            )}
                          </strong>
                        </div>
                      </>
                    ) : (
                      <>
                        {/* {<div className="sDetailsCol">
                                                <label>Mysql Uptime:</label> <strong>{this.state.mysqlDetails && this.state.mysqlDetails.mysql_uptime}</strong>
                                            </div>} */}
                      </>
                    )}
                  </div>
                )}

                <div className="col-md-3">
                  <div className="notificationCol">
                    <OpenNotificationsPopup
                      unique_id={this.state.unique_id}
                      id={this.state.id}
                      mytimezone={this.state.mytimezone}
                      isRefresh={this.state.isRefresh}
                    ></OpenNotificationsPopup>
                  </div>
                </div>
              </div>
              {this.state.activeTab === 1 && this.state.awsMetaData && (
                <div className="mt-3 cloudMetaData">
                  <div className="d-flex ">
                    <h6 className="font-weight-bold pl-2 mr-3">
                      Cloud Meta Data{" "}
                      <TooltipPro
                        className="bottom tsize-400"
                        style={{ zIndex: 10 }}
                        tooltip={
                          <div className="row">
                            <div className="col-md-12">
                              <p>This metadata provide information about the server from Cloud service provider. Currently we only support AWS / Azure / GCP</p>
                            </div>
                          </div>
                        }
                      >
                        <AiOutlineInfoCircle className="infoIcon" />
                      </TooltipPro>
                    </h6>
                    <span className="myTimezone pl-5">
                      {this.state.openCloud ? (
                        <span onClick={() => this.setState({ openCloud: !this.state.openCloud })}>
                          {" "}
                          Click To Close Details <IoIosArrowUp size={20}></IoIosArrowUp>
                        </span>
                      ) : (
                        <span onClick={() => this.setState({ openCloud: !this.state.openCloud })}>
                          {" "}
                          Click To View Details <IoIosArrowDown size={20}></IoIosArrowDown>{" "}
                        </span>
                      )}
                    </span>
                  </div>
                  {this.state.openCloud && (
                    <div className="row mt-1">
                      <div className="col-md-6">
                        <div className="sDetailsCol">
                          <label>Admin User:</label> <strong>{this.state.awsMetaData && this.state.awsMetaData.AdminUser}</strong>
                        </div>
                        <div className="sDetailsCol">
                          <label>Account Id:</label> <strong>{this.state.awsMetaData && this.state.awsMetaData.accountId}</strong>
                        </div>
                        <div className="sDetailsCol">
                          <label>Architecture:</label> <strong>{this.state.awsMetaData && this.state.awsMetaData.architecture}</strong>
                        </div>
                        <div className="sDetailsCol">
                          <label>Availability Zone:</label> <strong>{this.state.awsMetaData && this.state.awsMetaData.availabilityZone}</strong>
                        </div>

                        <div className="sDetailsCol">
                          <label>BillingProducts:</label> <strong>{this.state.awsMetaData && this.state.awsMetaData.billingProducts}</strong>
                        </div>
                        <div className="sDetailsCol">
                          <label>DevpayProductCodes:</label> <strong>{this.state.awsMetaData && this.state.awsMetaData.devpayProductCodes}</strong>
                        </div>
                        <div className="sDetailsCol">
                          <label>MarketplaceProductCodes:</label> <strong>{this.state.awsMetaData && this.state.awsMetaData.marketplaceProductCodes}</strong>
                        </div>
                        <div className="sDetailsCol">
                          <label>ImageId:</label> <strong>{this.state.awsMetaData && this.state.awsMetaData.imageId}</strong>
                        </div>
                        <div className="sDetailsCol">
                          <label>InstanceId:</label> <strong>{this.state.awsMetaData && this.state.awsMetaData.instanceId}</strong>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="sDetailsCol">
                          <label>Cloud Name:</label> <strong style={{ textTransform: "uppercase" }}>{this.state.awsMetaData && this.state.awsMetaData.CloudName}</strong>
                        </div>
                        <div className="sDetailsCol">
                          <label>Machine Name:</label> <strong>{this.state.awsMetaData && this.state.awsMetaData.MachineName}</strong>
                        </div>
                        <div className="sDetailsCol">
                          <label>InstanceType:</label> <strong>{this.state.awsMetaData && this.state.awsMetaData.instanceType}</strong>
                        </div>
                        <div className="sDetailsCol">
                          <label>KernelId:</label> <strong>{this.state.awsMetaData && this.state.awsMetaData.kernelId}</strong>
                        </div>
                        <div className="sDetailsCol">
                          <label>PendingTime:</label> <strong>{this.state.awsMetaData && this.state.awsMetaData.pendingTime}</strong>
                        </div>
                        <div className="sDetailsCol">
                          <label>PrivateIp:</label> <strong>{this.state.awsMetaData && this.state.awsMetaData.privateIp}</strong>
                        </div>

                        <div className="sDetailsCol">
                          <label>RamdiskId:</label> <strong>{this.state.awsMetaData && this.state.awsMetaData.ramdiskId}</strong>
                        </div>
                        <div className="sDetailsCol">
                          <label>Region:</label> <strong>{this.state.awsMetaData && this.state.awsMetaData.region}</strong>
                        </div>
                        <div className="sDetailsCol">
                          <label>Version:</label> <strong>{this.state.awsMetaData && this.state.awsMetaData.version}</strong>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        )}
        {this.state.activeTab === 4 && (
          <div className="chart-loader">
            <Loader loader={isLoading}></Loader>
          </div>
        )}
        {!isLoading && (
          <>
            {this.state.activeTab === 4 && Array.isArray(this.state.cronJobs) && this.state.cronJobs.length > 0 ? (
              <div className="mt-5">
                {/* <div className="card">
                            <div className="card-header">
                                <div className="d-flex serverDetailsHead">
                                    <h3>CRON Job List</h3>
                                </div>
                            </div>
                        </div> */}
                <div className="table-responsive">
                  <table className="table table-bordered mb-0">
                    <thead>
                      <tr>
                        <th scope="col">CRON Name</th>
                        <th scope="col">Description</th>
                        <th scope="col">Command</th>
                        <th scope="col">CRON User</th>
                        <th scope="col">Schedule</th>
                        <th scope="col">Schedule Description</th>
                        <th scope="col" style={{ minWidth: 140 }}>
                          Last Run Date Time
                        </th>
                        <th scope="col">Server Time Zone</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.cronJobs.map((job, index) => {
                        job.uuid = this.state.uuid;
                        job.server_name = this.state.server_name;
                        job.id = this.state.id;

                        return (
                          this.state.cronPagination.pageNumber * this.state.cronPagination.pageSize > index &&
                          this.state.cronPagination.pageNumber * this.state.cronPagination.pageSize < index + 1 + this.state.cronPagination.pageSize && (
                            <tr key={index}>
                              <td>{job.Name}</td>
                              <td>{job.Description}</td>
                              <td>{job.Command}</td>
                              <td>{job.user_name}</td>
                              <td>{job.Cron_Schedule}</td>
                              <td>{job.Cron_Schedule ? `Runs ${cronstrue.toString(job.Cron_Schedule)}` : ""}</td>
                              <td>
                                <span>
                                  {job.last_status === 0 ? (
                                    <>
                                      {["top"].map((placement) => (
                                        <OverlayTrigger key={placement} placement={placement} overlay={<Tooltip id={`tooltip-${placement}`}>Error</Tooltip>}>
                                          <BsFillXCircleFill color="#d95d60" />
                                        </OverlayTrigger>
                                      ))}
                                    </>
                                  ) : job.last_status === 1 ? (
                                    <>
                                      {["top"].map((placement) => (
                                        <OverlayTrigger key={placement} placement={placement} overlay={<Tooltip id={`tooltip-${placement}`}>Success</Tooltip>}>
                                          <BsCheckCircleFill color="#509428" />
                                        </OverlayTrigger>
                                      ))}
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </span>
                                <span> {job.last_run_time ? job.last_run_time : ""}</span>
                              </td>
                              <td>{job.time_zone || "IST"}</td>
                              <td>
                                <div className="d-flex">
                                  <OverlayTrigger placement="top" overlay={<Tooltip>View Details</Tooltip>}>
                                    <Link
                                      to={() => ({
                                        pathname: "cron",
                                        state: job,
                                      })}
                                    >
                                      <MdRemoveRedEye className="mr-1 actionIcon" size={27} />
                                    </Link>
                                  </OverlayTrigger>
                                  <OverlayTrigger placement="top" overlay={<Tooltip>Edit Details</Tooltip>}>
                                    <AiOutlineEdit className="mr-1 actionIcon" size={27} onClick={() => this.toggleEditCron(job)} />
                                  </OverlayTrigger>
                                </div>
                              </td>
                            </tr>
                          )
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <div className="paginationSec">
                  <div className="totalPage">
                    <Select
                      selectedOption={this.state.cronPagination.pageSize}
                      setSelectedOption={this.onChangeCronPageSize}
                      options={[
                        { label: 10, value: 10 },
                        { label: 30, value: 30 },
                        { label: 50, value: 50 },
                        { label: 70, value: 70 },
                        { label: 100, value: 100 },
                      ]}
                    ></Select>
                  </div>
                  <Pagination
                    onClickPage={this.onChangeCronPageNumber}
                    pageCount={Math.ceil(this.state.cronPagination.total / this.state.cronPagination.pageSize) || 1}
                    currentPage={this.state.cronPagination.pageNumber}
                  ></Pagination>
                </div>
              </div>
            ) : this.state.activeTab === 4 && !isLoading ? (
              <div
                style={{
                  textAlign: "center",
                  marginTop: "10%",
                  color: "#cf55aa",
                  fontSize: "27px",
                }}
              >
                No Record Available
              </div>
            ) : null}
          </>
        )}
        {this.state.activeTab === 1 ? (
          <div className="serverStatisticsSec">
            <div className="subTitleOuter">
              <h2 className="sectionHeading">Server Statistics</h2>
              <div className="selectMtTimezone">
                <span className="myTimezone">My Timezone: {this.state.browerTimeZone}</span>
                <div className="timeZoneSelect">
                  <Select
                    selectedOption={this.state.active}
                    setSelectedOption={(e) => this.onActive(e.value)}
                    options={[
                      { label: "4 Hours", value: 4 },
                      { label: "8 Hours", value: 8 },
                      { label: "12 Hours", value: 12 },
                    ]}
                  ></Select>
                </div>
              </div>
            </div>
            <div className="row">
              <div
                className="col-md-6"
                onClick={() => {
                  const object = {
                    user_element: "cpu_usage",
                    title: "CPU Usage",
                    id: this.state.id,
                    uuid: this.state.uuid,
                    update: true,
                    server_name: this.state.server_name,
                    mytimezone: this.state.mytimezone,
                    chart: 1,
                    unique_id: this.state.unique_id,
                  };
                  this.props.history.push("/monitor-server/chart" + objectToQuery(object), object);
                }}
              >
                <div className="card overflow-visible">
                  <div className="card-header justify-content-start">
                    <h3 className="mr-3">CPU Usage</h3>
                    <TooltipPro
                      className="bottom tsize-400"
                      style={{ zIndex: 10 }}
                      tooltip={
                        <div className="row">
                          <div className="col-md-12">
                            <p>
                              This displays the CPU usage on the server. Total CPU usage is composed of multiple individual components. Most important being *User* and *System*
                            </p>
                          </div>
                        </div>
                      }
                    >
                      <AiOutlineInfoCircle className="infoIcon" />
                    </TooltipPro>
                  </div>
                  <div className="chartInnerDiv">
                    <UsageChart
                      // title="CPU Usage"
                      tooltip={true}
                      toolbox={true}
                      uuid={this.state.uuid}
                      user_element="cpu_usage"
                      SeriesType={["total", "system", "user"]}
                      active={this.state.active}
                      tooltipFormatterCallBack={this.getTopProcessDate}
                      isRefresh={this.state.isRefresh}
                      mytimezone={this.state.mytimezone}
                      unique_id={this.state.unique_id}
                      onRefresh={this.state.onRefresh}
                    />
                  </div>
                </div>
              </div>
              <TopProcess
                date={date}
                processType={processType}
                topProcessData={topProcessData}
                diskIOProcessServerDetails={this.diskIOProcessServerDetails}
                getTopProcess={this.getTopProcess}
                uuid={this.state.uuid}
              />
              <div
                className="col-md-6"
                onClick={() => {
                  const object = {
                    user_element: "ram_usage",
                    title: "RAM Usage",
                    id: this.state.id,
                    uuid: this.state.uuid,
                    update: true,
                    server_name: this.state.server_name,
                    mytimezone: this.state.mytimezone,
                    chart: 1,
                    unique_id: this.state.unique_id,
                  };
                  this.props.history.push("/monitor-server/chart" + objectToQuery(object), object);
                }}
              >
                {/* For New UI testing */}
                {/* <div
                                className="col-md-6"
                                onClick={() =>
                                    this.props.history.push("/monitor-server/testchart", {
                                        user_element: "ram_usage",
                                        title: "RAM Usage",
                                        id: this.state.id,
                                        uuid: this.state.uuid,
                                        update: true,
                                        server_name: this.state.server_name,
                                        mytimezone: this.state.mytimezone,
                                        chart: 1,
                                        unique_id: this.state.unique_id
                                    })
                                }
                            > */}
                {/* For  NEW UI testing  END*/}

                <div className="card overflow-visible">
                  <div className="card-header justify-content-start">
                    <h3 className="mr-3">RAM Usage</h3>
                    <TooltipPro
                      className="bottom tsize-400"
                      style={{ zIndex: 10 }}
                      tooltip={
                        <div className="row">
                          <div className="col-md-12">
                            <p>This shows the Total RAM installed on the server and details on how much RAM is consumed (used), free and available.</p>
                          </div>
                        </div>
                      }
                    >
                      <AiOutlineInfoCircle className="infoIcon" />
                    </TooltipPro>
                  </div>
                  <div className="chartInnerDiv">
                    <UsageChart
                      // title="RAM Usage"
                      tooltip={true}
                      toolbox={true}
                      uuid={this.state.uuid}
                      user_element="ram_usage"
                      SeriesType={graphsKeys}
                      active={this.state.active}
                      isRefresh={this.state.isRefresh}
                      mytimezone={this.state.mytimezone}
                      unique_id={this.state.unique_id}
                      onRefresh={this.state.onRefresh}
                    />
                  </div>
                </div>
              </div>
              <div
                className="col-md-6"
                onClick={() => {
                  const object = {
                    user_element: "swap_usage",
                    title: "Swap Usage",
                    id: this.state.id,
                    uuid: this.state.uuid,
                    update: true,
                    server_name: this.state.server_name,
                    mytimezone: this.state.mytimezone,
                    chart: 1,
                    unique_id: this.state.unique_id,
                  };
                  this.props.history.push("/monitor-server/chart" + objectToQuery(object), object);
                }}
              >
                <div className="card overflow-visible">
                  <div className="card-header justify-content-start">
                    <h3 className="mr-3">Swap Usage</h3>
                    <TooltipPro
                      className="bottom tsize-400"
                      style={{ zIndex: 10 }}
                      tooltip={
                        <div className="row">
                          <div className="col-md-12">
                            <p>This shows is SWAP is setup on the server. Also it shows Total SWAP setup and how much has been used.</p>
                          </div>
                        </div>
                      }
                    >
                      <AiOutlineInfoCircle className="infoIcon" />
                    </TooltipPro>
                  </div>
                  <div className="chartInnerDiv">
                    <UsageChart
                      // title="Swap Usage"
                      tooltip={true}
                      toolbox={true}
                      uuid={this.state.uuid}
                      user_element="swap_usage"
                      SeriesType={["total", "used"]}
                      active={this.state.active}
                      isRefresh={this.state.isRefresh}
                      mytimezone={this.state.mytimezone}
                      unique_id={this.state.unique_id}
                      onRefresh={this.state.onRefresh}
                    />
                  </div>
                </div>
              </div>

              <div
                className="col-md-6"
                onClick={() => {
                  let object = {
                    user_element: "disk_io_stats",
                    title: "Disk IO Stats",
                    id: this.state.id,
                    uuid: this.state.uuid,
                    update: true,
                    server_name: this.state.server_name,
                    mytimezone: this.state.mytimezone,
                    chart: 1,
                    unique_id: this.state.unique_id,
                  };
                  this.props.history.push("/monitor-server/chart" + objectToQuery(object), object);
                }}
              >
                <div className="card overflow-visible">
                  <div className="card-header justify-content-start">
                    <h3 className="mr-3">Disk IO Stats</h3>
                    <TooltipPro
                      className="bottom tsize-400"
                      style={{ zIndex: 10 }}
                      tooltip={
                        <div className="row">
                          <div className="col-md-12">
                            <p>This shows the Disk Input and Output in terms of bytes per seconds.</p>
                          </div>
                        </div>
                      }
                    >
                      <AiOutlineInfoCircle className="infoIcon" />
                    </TooltipPro>
                  </div>
                  <div className="chartInnerDiv">
                    <UsageChart
                      // title="Memory Usage"
                      tooltip={true}
                      toolbox={true}
                      uuid={this.state.uuid}
                      user_element="disk_io_stats"
                      SeriesType={graphsKeys}
                      active={this.state.active}
                      isRefresh={this.state.isRefresh}
                      mytimezone={this.state.mytimezone}
                      sizeTypenotSHow={true}
                      // tooltipFormatterCallBack={(value) => this.getTopProcessDate(value, "disk_io_stats")}
                      tooltipFormatterCallBack={this.getTopProcessDate}
                      tooltipFormatterValueName={"disk_io_stats_date"}
                      unique_id={this.state.unique_id}
                      onRefresh={this.state.onRefresh}
                    />
                  </div>
                </div>
              </div>
              <DiskIOProcess diskIOKeyList={diskIOKeyList} diskIOList={diskIOList} disk_io_stats_date={this.state.disk_io_stats_date} />
              <div
                className="col-md-6"
                onClick={() => {
                  const object = {
                    user_element: "network-stats",
                    title: "Network Stats",
                    id: this.state.id,
                    uuid: this.state.uuid,
                    update: true,
                    server_name: this.state.server_name,
                    mytimezone: this.state.mytimezone,
                    chart: 1,
                    unique_id: this.state.unique_id,
                  };
                  this.props.history.push("/monitor-server/chart" + objectToQuery(object), object);
                }}
              >
                <div className="card overflow-visible">
                  <div className="card-header justify-content-start">
                    <h3 className="mr-3">Network Stats</h3>
                    <TooltipPro
                      className="bottom tsize-400"
                      style={{ zIndex: 10 }}
                      tooltip={
                        <div className="row">
                          <div className="col-md-12">
                            <p>This shows the Network Input and Output operations in terms of Bytes.</p>
                          </div>
                        </div>
                      }
                    >
                      <AiOutlineInfoCircle className="infoIcon" />
                    </TooltipPro>
                  </div>
                  <div className="chartInnerDiv">
                    <UsageChart
                      // title="Network Stats"
                      tooltip={true}
                      toolbox={true}
                      uuid={this.state.uuid}
                      user_element="network-stats"
                      SeriesType={graphsKeys}
                      active={this.state.active}
                      isRefresh={this.state.isRefresh}
                      mytimezone={this.state.mytimezone}
                      unique_id={this.state.unique_id}
                      onRefresh={this.state.onRefresh}
                      tooltipFormatterCallBack={this.getTopProcessDate}
                      tooltipFormatterValueName={"network_io_stats_date"}
                    />
                  </div>
                </div>
              </div>
              <NetworkIOProcess
                serverId={this.state.id}
                networkIOKeyList={networkIOKeyList}
                networkIOList={networkIOList}
                network_io_stats_date={this.state.network_io_stats_date}
              />
              <div
                className="col-md-6"
                onClick={() => {
                  const object = {
                    user_element: "memory_usage",
                    title: "Disk Usage",
                    id: this.state.id,
                    uuid: this.state.uuid,
                    update: true,
                    server_name: this.state.server_name,
                    mytimezone: this.state.mytimezone,
                    chart: 1,
                    unique_id: this.state.unique_id,
                  };
                  this.props.history.push("/monitor-server/chart" + objectToQuery(object), object);
                }}
              >
                <div className="card overflow-visible">
                  <div className="card-header justify-content-start">
                    <h3 className="mr-3">Disk Usage</h3>
                    <TooltipPro
                      className="bottom tsize-400"
                      style={{ zIndex: 10 }}
                      tooltip={
                        <div className="row">
                          <div className="col-md-12">
                            <p>This shows the total Disk installed on the system in GB and how much is consumed.</p>
                          </div>
                        </div>
                      }
                    >
                      <AiOutlineInfoCircle className="infoIcon" />
                    </TooltipPro>
                  </div>
                  <div className="chartInnerDiv">
                    <UsageChart
                      // title="Memory Usage"
                      tooltip={true}
                      toolbox={true}
                      uuid={this.state.uuid}
                      user_element="memory_usage"
                      SeriesType={["total", "used"]}
                      active={this.state.active}
                      isRefresh={this.state.isRefresh}
                      mytimezone={this.state.mytimezone}
                      unique_id={this.state.unique_id}
                      onRefresh={this.state.onRefresh}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6 px-2">
                <div className="card p-2">
                  <DiskPartitionOperation
                    uuid={this.state.uuid}
                    user_element="currently_logged_in"
                    SeriesType={graphsKeys}
                    active={this.state.active}
                    isRefresh={this.state.isRefresh}
                    mytimezone={this.state.mytimezone}
                    unique_id={this.state.unique_id}
                    onRefresh={this.state.onRefresh}
                    title="Partition"
                    height={"300px"}
                  />
                </div>
              </div>
              {/* <div
                                    className="col-md-6">
                                    <div className="card overflow-visible">
                                        <div className="card-header justify-content-start">
                                            <h3 className="mr-3">Failed Login Activity</h3>
                                            <TooltipPro
                                                className="bottom tsize-400"
                                                style={{ zIndex: 10 }}
                                                tooltip={
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <p>
                                                                The server logs the details of failed SSH login attempts on the server. This widget displays the number of failed login
                                                                attempts at each interval of time.
                                                            </p>
                                                        </div>
                                                    </div>
                                                }
                                            >
                                                <AiOutlineInfoCircle className="infoIcon" />
                                            </TooltipPro>
                                        </div>
                                        <div className="chartInnerDiv"
                                            onClick={() =>
                                                this.props.history.push("/monitor-server/chart", {
                                                    user_element: "failed_login_activity",
                                                    title: "Failed Login Activity",
                                                    id: this.state.id,
                                                    uuid: this.state.uuid,
                                                    update: true,
                                                    server_name: this.state.server_name,
                                                    mytimezone: this.state.mytimezone,
                                                    chart: 1,
                                                    unique_id: this.state.unique_id,
                                                })
                                            }
                                        >
                                            <UsageChart
                                                // title="Network Stats"
                                                tooltip={true}
                                                toolbox={true}
                                                uuid={this.state.uuid}
                                                user_element="failed_login_activity"
                                                SeriesType={graphsKeys}
                                                active={this.state.active}
                                                isRefresh={this.state.isRefresh}
                                                mytimezone={this.state.mytimezone}
                                                unique_id={this.state.unique_id}
                                            />
                                        </div>
                                    </div>
                                </div> */}

              <div className="col-md-6">
                <div className="card overflow-visible">
                  <div className="card-header" style={{ justifyContent: "space-between", zIndex: 99 }}>
                    <div>
                      <Button className="mr-3" onClick={() => this.setState({ loginType: "Failed" })} color={this.state.loginType === "Failed" ? "primary" : "secondary"}>
                        Failed Login Activity
                      </Button>
                      <TooltipPro
                        className="bottom tsize-400"
                        style={{ zIndex: 10 }}
                        tooltip={
                          <div className="row">
                            <div className="col-md-12">
                              <p>
                                The server logs the details of failed SSH login attempts on the server. This widget displays the number of failed login attempts at each interval of
                                time.
                              </p>
                            </div>
                          </div>
                        }
                      >
                        <AiOutlineInfoCircle className="infoIcon" />
                      </TooltipPro>
                    </div>
                    <div>
                      <Button className="mr-3" onClick={() => this.setState({ loginType: "Loggedin" })} color={this.state.loginType === "Loggedin" ? "primary" : "secondary"}>
                        Logged in Users
                      </Button>
                      <TooltipPro
                        className="bottom tsize-400"
                        style={{ zIndex: 10 }}
                        tooltip={
                          <div className="row">
                            <div className="col-md-12">
                              <p>
                                The server logs the details of SSH login attempts on the server. This widget displays the number of failed login attempts at each interval of time.
                              </p>
                            </div>
                          </div>
                        }
                      >
                        <AiOutlineInfoCircle className="infoIcon" />
                      </TooltipPro>
                    </div>
                  </div>
                  <div className="">
                    <div
                      className="chartInnerDiv"
                      onClick={() => {
                        const object = {
                          user_element: this.state.loginType === "Failed" ? "failed_login_activity" : "currently_logged_in",
                          title: "Failed Login Activity",
                          id: this.state.id,
                          uuid: this.state.uuid,
                          update: true,
                          server_name: this.state.server_name,
                          mytimezone: this.state.mytimezone,
                          chart: this.state.loginType === "Failed" ? 1 : 8,
                          unique_id: this.state.unique_id,
                        };
                        this.props.history.push("/monitor-server/chart" + objectToQuery(object), object);
                      }}
                    >
                      {this.state.loginType === "Failed" ? (
                        <UsageChart
                          // title="Network Stats"
                          tooltip={true}
                          toolbox={true}
                          uuid={this.state.uuid}
                          user_element="failed_login_activity"
                          SeriesType={graphsKeys}
                          active={this.state.active}
                          isRefresh={this.state.isRefresh}
                          mytimezone={this.state.mytimezone}
                          unique_id={this.state.unique_id}
                          onRefresh={this.state.onRefresh}
                        />
                      ) : (
                        <LoggedChartOperation
                          // title="Network Stats"
                          tooltip={true}
                          toolbox={true}
                          uuid={this.state.uuid}
                          user_element="currently_logged_in"
                          SeriesType={graphsKeys}
                          active={this.state.active}
                          isRefresh={this.state.isRefresh}
                          mytimezone={this.state.mytimezone}
                          unique_id={this.state.unique_id}
                          onRefresh={this.state.onRefresh}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="card overflow-visible">
                  <div className="card-header" style={{ justifyContent: "space-between", zIndex: 99 }}>
                    <div>
                      <Button className="mr-3" onClick={() => this.setState({ openPortType: "private" })} color={this.state.openPortType === "private" ? "primary" : "secondary"}>
                        Ports used by processes
                      </Button>
                      <TooltipPro
                        className="bottom tsize-400"
                        style={{ zIndex: 10 }}
                        tooltip={
                          <div className="row">
                            <div className="col-md-12">
                              <p>
                                <strong> Ports used by processes </strong>
                                Displays the ports (both UDP and TCP) that are listening on the system and the related processes that are using the port.
                                {/* This widget displays the ports (both UDP and TCP) that are listening on the system and the related processes. It does not indicate that
                                                                the port is open to the world, as this does not check if the port has been blocked by the firewall. It only indicates that these ports
                                                                are open and are in listening mode. */}
                              </p>
                            </div>
                          </div>
                        }
                      >
                        <AiOutlineInfoCircle className="infoIcon" />
                      </TooltipPro>
                    </div>
                    <div>
                      <Button className="mr-3" onClick={() => this.setState({ openPortType: "public" })} color={this.state.openPortType === "public" ? "primary" : "secondary"}>
                        Ports open to world
                      </Button>
                      <TooltipPro
                        className="bottom tsize-400"
                        style={{ zIndex: 10 }}
                        tooltip={
                          <div className="row">
                            <div className="col-md-12">
                              <p>
                                <strong>Ports open to world </strong>
                                Indicates that the port is open to the world and is not blocked by the firewall.
                              </p>
                            </div>
                          </div>
                        }
                      >
                        <AiOutlineInfoCircle className="infoIcon" />
                      </TooltipPro>
                    </div>
                  </div>
                  <div className="">
                    {/* <div className="card-header justify-content-start">
                                                <h3 className="mr-3">Open Port</h3>
                                                <TooltipPro
                                                    className="bottom tsize-400"
                                                    style={{ zIndex: 10 }}
                                                    tooltip={
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <p>
                                                                    This widget displays the ports (both UDP and TCP) that are listening on the system and the related processes. It does not indicate that
                                                                    the port is open to the world, as this does not check if the port has been blocked by the firewall. It only indicates that these ports
                                                                    are open and are in listening mode.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    }
                                                >
                                                   <AiOutlineInfoCircle className="infoIcon" />
                                                </TooltipPro>
                                            </div> */}

                    <div
                      className="chartInnerDiv"
                      onClick={() => {
                        const object = {
                          user_element: this.state.openPortType === "public" ? "public-open-port" : "open-port",
                          title: this.state.openPortType === "public" ? "Ports open to world" : "Ports used by processes",
                          id: this.state.id,
                          uuid: this.state.uuid,
                          update: true,
                          chart: this.state.openPortType === "public" ? 7 : 3,
                          server_name: this.state.server_name,
                          mytimezone: this.state.mytimezone,
                          unique_id: this.state.unique_id,
                        };
                        this.props.history.push("/monitor-server/chart" + objectToQuery(object), object);
                      }}
                    >
                      {this.state.openPortType === "public" ? (
                        <OpenPortChartOperationPublic
                          //  title={'Open Port'}
                          tooltip={true}
                          // toolbox={true}
                          uuid={this.state.uuid}
                          user_element={"open-port"}
                          SeriesType={graphsKeys}
                          // start_datetime={this.state.start_datetimeForChart}
                          // end_datetime={this.state.end_datetimeForChart}
                          active={this.state.active}
                          mytimezone={this.state.mytimezone}
                          isRefresh={this.state.isRefresh}
                          openPortType={this.state.openPortType}
                          unique_id={this.state.unique_id}
                          onRefresh={this.state.onRefresh}
                        />
                      ) : (
                        <OpenPortChartOperation
                          //  title={'Open Port'}
                          tooltip={true}
                          //  toolbox={true}
                          uuid={this.state.uuid}
                          user_element={"open-port"}
                          SeriesType={graphsKeys}
                          // start_datetime={this.state.start_datetimeForChart}
                          // end_datetime={this.state.end_datetimeForChart}
                          active={this.state.active}
                          mytimezone={this.state.mytimezone}
                          isRefresh={this.state.isRefresh}
                          unique_id={this.state.unique_id}
                          onRefresh={this.state.onRefresh}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}

        {this.state.activeTab === 1 ? (
          <>
            <div className="serverStatisticsSec">
              <div className="subTitleOuter">
                <h2 className="sectionHeading">Security</h2>
              </div>

              <div className="row">
                <div className="col-md-6" id="notAllowedFile" style={{ marginBottom: "100px" }}>
                  <div
                    className="card"
                    onClick={() =>
                      this.props.history.push("/monitor-servers/disallowed-files", {
                        id: this.state.id,
                        uuid: this.state.uuid,
                        update: true,
                        server_name: this.state.server_name,
                        unique_id: this.state.unique_id,
                      })
                    }
                  >
                    <DisallowedFilesForm
                      unique_id={this.state.unique_id}
                      serverDisallowedFilesShow={true}
                      {...this.props}
                      uuid={this.state.uuid}
                      paginationCount={5}
                      showFolderFiles={true}
                    />
                  </div>
                </div>

                {/* Folder Change Listing */}

                <div className="col-md-6" id="folderChange">
                  <div className="card overflow-visible fixedChartTable">
                    <BackupFolderChange
                      unique_id={this.state.unique_id}
                      pageSize={5}
                      {...this.props}
                      paginationCount={5}
                      uuid={this.state.uuid}
                      serverChartsShow={true}
                      onClick={() =>
                        this.props.history.push("/monitor-servers/backup-folder-change", {
                          id: this.state.id,
                          uuid: this.state.uuid,
                          update: true,
                          server_name: this.state.server_name,
                          unique_id: this.state.unique_id,
                        })
                      }
                      tooltip
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="serverStatisticsSec">
              <div className="subTitleOuter">
                <h2 className="sectionHeading">Server Status</h2>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="card overflow-visible">
                    <div className="card-header">
                      <h3>Server Status Log</h3>
                    </div>
                    {Array.isArray(this.state.serverDetailsLog) && <MonitorServerLogTable serverLog={this.state.serverDetailsLog} />}
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          ""
        )}

        {/* {this.state.activeTab === 1 ? (
          <>
            <div className="serverStatisticsSec">
              <div className="subTitleOuter">
                <h2 className="sectionHeading">Partition</h2>
              </div>

              <div className="row">
                <div
                  className="col-md-12"
                  id="notAllowedFile"
                  style={{ marginBottom: "100px" }}
                >
                  <div className="card">
                    <div className="card-header">
                      <h3>Partition</h3>
                    </div>
                    <DiskPartitionOperation
                      uuid={this.state.uuid}
                      user_element="currently_logged_in"
                      SeriesType={graphsKeys}
                      active={this.state.active}
                      isRefresh={this.state.isRefresh}
                      mytimezone={this.state.mytimezone}
                      unique_id={this.state.unique_id}
                      onRefresh={this.state.onRefresh}
                      //  title="Partition"
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          ""
        )} */}

        {this.state.activeTab === 5 && <MonitorServerCustomLog uuid={this.state.uuid} mytimezone={this.state.mytimezone} />}

        {this.state.activeTab === 2 ? (
          <>
            <div className="serverStatisticsSec" id="backup">
              <div className="subTitleOuter">
                <h2 className="sectionHeading">Backup Details</h2>
              </div>
              <div>
                {this.state.dbbackupNotConfigured === "Db Backup not configured" && (
                  <div style={{ color: "red" }}>
                    DB Backup not configured,{" "}
                    <Link
                      style={{ color: "#e78383" }}
                      onClick={() => localStorage.setItem("moniterServerScroll", "backup")}
                      to={{
                        pathname: "/monitor-server/server-setting",
                        state: {
                          unique_id: this.state.unique_id,
                          id: this.state.id,
                          uuid: this.state.uuid,
                          server_name: this.state.server_name,
                          update: true,
                          activeTab: 3,
                        },
                      }}
                    >
                      Click here
                    </Link>{" "}
                    to configure
                  </div>
                )}
                {this.state.folderbackUpNotConfigured === "Folder Backup not configured" && (
                  <div style={{ color: "red" }}>
                    Folder Backup not configured,{" "}
                    <Link
                      style={{ color: "#e78383" }}
                      onClick={() => localStorage.setItem("moniterServerScroll", "folderChange")}
                      to={{
                        pathname: "/monitor-server/server-setting",
                        state: {
                          unique_id: this.state.unique_id,
                          id: this.state.id,
                          uuid: this.state.uuid,
                          server_name: this.state.server_name,
                          update: true,
                          activeTab: 2,
                        },
                      }}
                    >
                      Click here
                    </Link>{" "}
                    to configure
                  </div>
                )}
              </div>
              <div className="row">
                <div
                  className="col-md-6"
                  style={{
                    display: this.state.dbbackupNotConfigured === "Db Backup not configured" ? "none" : "block",
                  }}
                >
                  <div className="card overflow-visible">
                    <DbBackupList
                      unique_id={this.state.unique_id}
                      uuid={this.state.uuid}
                      isRefresh={this.state.isRefresh}
                      // start_datetime={this.state.start_datetime}
                      // end_datetime={this.state.end_datetime}
                      setMessage={(message) =>
                        this.setState({
                          dbbackupNotConfigured: message,
                        })
                      }
                      tooltip="This section lists all the database backups that have been saved. It also displayes the backup size and message. The message becomes important when there is any failure in the backup process."
                    ></DbBackupList>
                  </div>
                </div>
                <div
                  className="col-md-6"
                  style={{
                    display: this.state.folderbackUpNotConfigured === "Folder Backup not configured" ? "none" : "block",
                  }}
                >
                  <div className="card overflow-visible">
                    <FolderBackUpList
                      unique_id={this.state.unique_id}
                      uuid={this.state.uuid}
                      isRefresh={this.state.isRefresh}
                      // start_datetime={this.state.start_datetime}
                      // end_datetime={this.state.end_datetime}
                      setMessage={(message) =>
                        this.setState({
                          folderbackUpNotConfigured: message,
                        })
                      }
                      tooltip="This section lists all the folder backups that have been saved. It also displayes the backup size and message. The message becomes important when there is any failure in the backup process."
                    ></FolderBackUpList>
                  </div>
                </div>

                <div
                  style={{
                    display: this.state.dbbackupNotConfigured === "Db Backup not configured" ? "none" : "block",
                  }}
                  className="col-md-6"
                  onClick={() => {
                    const object = {
                      user_element: "db_backup",
                      title: "DB Backup",
                      id: this.state.id,
                      uuid: this.state.uuid,
                      update: true,
                      chart: 2,
                      server_name: this.state.server_name,
                      mytimezone: this.state.mytimezone,
                      unique_id: this.state.unique_id,
                      activeTab: this.state.activeTab,
                    };
                    this.props.history.push("/monitor-server/chart" + objectToQuery(object), object);
                  }}
                >
                  <div className="card overflow-visible">
                    <div className="card-header justify-content-start">
                      <h3 className="mr-3">DB Backup</h3>
                      <TooltipPro
                        className="bottom tsize-400"
                        style={{ zIndex: 10 }}
                        tooltip={
                          <div className="row">
                            <div className="col-md-12">
                              <p>This section displays the plot of how the size of DB backup varies over time. This can be used to estimate how fast DB is growing.</p>
                            </div>
                          </div>
                        }
                      >
                        <AiOutlineInfoCircle className="infoIcon" />
                      </TooltipPro>
                    </div>
                    <div className="chartInnerDiv">
                      <BackupChartOperation
                        // title="DB Backup"
                        tooltip={true}
                        toolbox={true}
                        uuid={this.state.uuid}
                        user_element="db_backup"
                        SeriesType={graphsKeys}
                        mytimezone={this.state.mytimezone}
                        isRefresh={this.state.isRefresh}
                        unique_id={this.state.unique_id}
                        onRefresh={this.state.onRefresh}
                      />
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: this.state.folderbackUpNotConfigured === "Folder Backup not configured" ? "none" : "block",
                  }}
                  className="col-md-6"
                  onClick={() => {
                    const object = {
                      user_element: "folder_backup",
                      title: "Folder Backup",
                      id: this.state.id,
                      uuid: this.state.uuid,
                      update: true,
                      chart: 2,
                      server_name: this.state.server_name,
                      mytimezone: this.state.mytimezone,
                      unique_id: this.state.unique_id,
                      activeTab: this.state.activeTab,
                    };
                    this.props.history.push("/monitor-server/chart" + objectToQuery(object), object);
                  }}
                >
                  <div className="card overflow-visible">
                    <div className="card-header justify-content-start">
                      <h3 className="mr-3">Folder Backup</h3>
                      <TooltipPro
                        className="bottom tsize-400"
                        style={{ zIndex: 10 }}
                        tooltip={
                          <div className="row">
                            <div className="col-md-12">
                              <p>
                                This section displays the plot of how the size of Folder backup varies over time. This can be used to see if the folder size is growing / reducing
                                over time.
                              </p>
                            </div>
                          </div>
                        }
                      >
                        <AiOutlineInfoCircle className="infoIcon" />
                      </TooltipPro>
                    </div>
                    <div className="chartInnerDiv">
                      <BackupChartOperation
                        // title="Folder Backup"
                        tooltip={true}
                        toolbox={true}
                        uuid={this.state.uuid}
                        user_element="folder_backup"
                        SeriesType={graphsKeys}
                        mytimezone={this.state.mytimezone}
                        isRefresh={this.state.isRefresh}
                        unique_id={this.state.unique_id}
                        onRefresh={this.state.onRefresh}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          ""
        )}

        {this.state.activeTab === 3 ? (
          <>
            <div className="chart-loader">
              <Loader loader={this.state.loadingTab}></Loader>
            </div>
            <div className="serverStatisticsSec" id="performance" style={{ overflow: "unset" }}>
              <div className="subTitleOuter">
                {/* <h2 className="sectionHeading">Apache</h2> */}
                <div
                  style={{
                    width: "100%",
                    textAlign: "right",
                  }}
                  className="selectMtTimezone"
                >
                  <span
                    style={{
                      width: "100%",
                    }}
                    className="myTimezone"
                  >
                    My Timezone: {this.state.browerTimeZone}
                  </span>
                  <div className="timeZoneSelect">
                    <Select
                      selectedOption={this.state.active}
                      setSelectedOption={(e) => this.onActive(e.value)}
                      options={[
                        { label: "4 Hours", value: 4 },
                        { label: "8 Hours", value: 8 },
                        { label: "12 Hours", value: 12 },
                      ]}
                    ></Select>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="serverStatisticsSec" id="performance"> */}
            {+this.state.performanceStatus?.nginx_performance === 1 && (
              <div className="serverStatisticsSec">
                <div className="subTitleOuter">
                  <h2 className="sectionHeading">Nginx</h2>
                  <h2 className="sectionHeading">
                    <span
                      style={{
                        color: this.state.statusandSubstatus?.Nginx?.ActiveState === "Active" ? "green" : "#bd6464",
                      }}
                    >
                      &nbsp;
                      {this.state.statusandSubstatus?.Nginx?.ActiveState
                        ? this.state.statusandSubstatus?.Nginx?.ActiveState + `(${this.state.statusandSubstatus?.Nginx?.SubState})`
                        : ""}
                    </span>
                  </h2>
                </div>
                <div
                  style={{
                    margin: "-10px 0px 18px 0px",
                  }}
                  className="row"
                >
                  <ChartOperationsApacheInnerNgnix
                    tooltip={true}
                    toolbox={true}
                    uuid={this.state.uuid}
                    user_element="nginx_log_data"
                    SeriesType={graphsKeys}
                    active={this.state.active}
                    isRefresh={this.state.isRefresh}
                    mytimezone={this.state.mytimezone}
                    id={this.state.id}
                    server_name={this.state.server_name}
                    history={this.props.history}
                    unique_id={this.state.unique_id}
                    onRefresh={this.state.onRefresh}
                    performanceChartData={this.state.performanceChartData}
                    App_version={this.state.App_version}
                    topProcessDataDate={this.state.topProcessDataDate}
                  ></ChartOperationsApacheInnerNgnix>
                  {this.state.performanceChartData && <PerformanceChartsOperation properties={properties} name="Nginx" data={this.state.performanceChartData} />}
                </div>
              </div>
            )}

            {+this.state.performanceStatus?.apache_performance === 1 && (
              <div className="serverStatisticsSec">
                <div className="subTitleOuter">
                  <h2 className="sectionHeading">Apache</h2>
                  <h2 className="sectionHeading">
                    <span
                      style={{
                        color: this.state.statusandSubstatus?.Apache?.ActiveState === "Active" ? "green" : "#bd6464",
                      }}
                    >
                      {this.state.statusandSubstatus?.Apache?.ActiveState
                        ? this.state.statusandSubstatus?.Apache?.ActiveState + `(${this.state.statusandSubstatus?.Apache?.SubState})`
                        : ""}
                    </span>
                  </h2>
                </div>
                <div
                  style={{
                    margin: "-10px 0px 18px 0px",
                  }}
                  className="row"
                >
                  <ChartOperationsApacheInner
                    tooltip={true}
                    toolbox={true}
                    uuid={this.state.uuid}
                    user_element="apache_log_data"
                    SeriesType={graphsKeys}
                    active={this.state.active}
                    isRefresh={this.state.isRefresh}
                    mytimezone={this.state.mytimezone}
                    id={this.state.id}
                    server_name={this.state.server_name}
                    history={this.props.history}
                    unique_id={this.state.unique_id}
                    onRefresh={this.state.onRefresh}
                    performanceChartData={this.state.performanceChartData}
                    App_version={this.state.App_version}
                    topProcessDataDate={this.state.topProcessDataDate}
                  ></ChartOperationsApacheInner>
                  {this.state.performanceChartData && <PerformanceChartsOperation properties={properties} name="Apache" data={this.state.performanceChartData} />}
                </div>
              </div>
            )}

            {this.state.performanceChartData &&
              Object.keys(this.state.performanceChartData).map((key) => {
                const notRander = ["Apache", "MySQL", "Nginx"];
                if (!notRander.includes(key))
                  return (
                    <div className="serverStatisticsSec">
                      <div className="subTitleOuter">
                        <h2 className="sectionHeading">{key} &nbsp;</h2>
                        <h2 className="sectionHeading">
                          <span
                            style={{
                              color: this.state.statusandSubstatus[key]?.ActiveState === "Active" ? "green" : "#bd6464",
                            }}
                          >
                            {this.state.statusandSubstatus[key]?.ActiveState
                              ? this.state.statusandSubstatus[key]?.ActiveState + `(${this.state.statusandSubstatus[key]?.SubState})`
                              : ""}
                          </span>
                        </h2>
                      </div>

                      <div
                        style={{
                          margin: "-10px 0px 18px 0px",
                        }}
                        className="row"
                      >
                        {this.state.performanceChartData && <PerformanceChartsOperation name={key} data={this.state.performanceChartData} properties={properties} />}
                      </div>
                    </div>
                  );
              })}
            {+this.state.performanceStatus?.mysql_performance === 1 && (
              <>
                <div className="serverStatisticsSec">
                  <div className="subTitleOuter">
                    <h2 className="sectionHeading">MySql Details</h2>
                    <h2 className="sectionHeading">
                      <span
                        style={{
                          color: this.state.statusandSubstatus?.MySQL?.ActiveState === "Active" ? "green" : "#bd6464",
                        }}
                      >
                        {this.state.statusandSubstatus?.MySQL?.ActiveState
                          ? this.state.statusandSubstatus?.MySQL?.ActiveState + `(${this.state.statusandSubstatus?.MySQL?.SubState})`
                          : ""}
                      </span>
                    </h2>
                  </div>
                  <div className="serverDetails pt-0">
                    <div className="row mb-2">
                      <div className={"col-lg-4 col-md-4 col-12"}>
                        <div className="sDetailsCol">
                          <label>Mysql Timezone:</label> <strong>{this.state.mysqlDetails && this.state.mysqlDetails.mysql_time_zone}</strong>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-12">
                        <div className="sDetailsCol justify-content-center">
                          <label>Mysql Version:</label> <strong>{this.state.mysqlDetails && this.state.mysqlDetails.mysql_version}</strong>
                        </div>
                      </div>
                      <div className={"col-lg-4 col-md-4 col-12"}>
                        <div className="sDetailsCol justify-content-end">
                          <label>Mysql Uptime:</label> <strong>{this.state.mysqlDetails && this.state.mysqlDetails.mysql_uptime}</strong>
                        </div>
                      </div>
                      {this.state.mySqlErrorMessage && (
                        <div className="col-12">
                          <div className="p-2 text-danger text-center">
                            <span>{this.state.mySqlErrorMessage}</span>
                            <span> </span>
                            <Link
                              onClick={() => localStorage.setItem("moniterServerScroll", "performance")}
                              to={{
                                pathname: "/monitor-server/server-setting",
                                state: {
                                  unique_id: this.state.unique_id,
                                  id: this.state.id,
                                  uuid: this.state.uuid,
                                  server_name: this.state.server_name,
                                  update: true,
                                  activeTab: 7,
                                },
                              }}
                            >
                              <u>Click here to fix</u>
                            </Link>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div style={{ margin: "-10px 0px 18px 0px" }} className="row">
                    <div
                      className="col-md-6"
                      onClick={() => {
                        const object = {
                          user_element: "mysql_metadata1",
                          title: "MySql Connections / Threads",
                          id: this.state.id,
                          uuid: this.state.uuid,
                          update: true,
                          server_name: this.state.server_name,
                          mytimezone: this.state.mytimezone,
                          chart: 4,
                          type: "mysql",
                          unique_id: this.state.unique_id,
                          activeTab: this.state.activeTab,
                          cKey: "MySQL",
                        };
                        this.props.history.push("/monitor-server/chart" + objectToQuery(object), object);
                      }}
                    >
                      {/* <div className="card overflow-visible">
                                                <div className="card-header"> */}
                      <div className="card overflow-visible">
                        <div className="card-header justify-content-start">
                          <h3 className="mr-3">MySql Connections / Threads</h3>
                          <TooltipPro
                            className="bottom tsize-400"
                            style={{ zIndex: 10 }}
                            tooltip={
                              <div className="row">
                                <div className="col-md-12">
                                  <p>This displays the number of connections made to the DB per second on an average in that minute and number of threads running per second.</p>
                                </div>
                              </div>
                            }
                          >
                            <AiOutlineInfoCircle className="infoIcon" />
                          </TooltipPro>
                        </div>
                        <div className="chartInnerDiv">
                          <ChartMySqlOperation
                            tooltip={true}
                            toolbox={true}
                            uuid={this.state.uuid}
                            user_element="mysql_metadata1"
                            SeriesType={graphsKeys}
                            active={this.state.active}
                            isRefresh={this.state.isRefresh}
                            mytimezone={this.state.mytimezone}
                            tooltipFormatterCallBack={this.getTopProcessDate}
                            type="mysql"
                            unique_id={this.state.unique_id}
                            onRefresh={this.state.onRefresh}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="card fixedChartPerformancTable">
                        <div className="card-header justify-content-start">
                          <h3 className="mr-3">MySql Current Query</h3>
                          <TooltipPro
                            className="bottom tsize-400"
                            style={{ zIndex: 10 }}
                            tooltip={
                              <div className="row">
                                <div className="col-md-12">
                                  <p>This displays the query being executed on the thread running on the database.</p>
                                </div>
                              </div>
                            }
                          >
                            <AiOutlineInfoCircle className="infoIcon" />
                          </TooltipPro>
                          <h3> {datemysql && ": " + datemysql}</h3>
                        </div>
                        <div className="table-responsive">
                          <Table className="table table-bordered topProcessTbl">
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>User</th>
                                <th>Database</th>
                                <th>Info</th>
                              </tr>
                            </thead>
                            <tbody>
                              {Array.isArray(Object.entries(mySqlCurrentQuery)) &&
                                Object.entries(mySqlCurrentQuery).map((obj, index) => {
                                  return (
                                    <tr>
                                      <th scope="row">{index + 1}</th>
                                      <td>{obj[1] && obj[1]["user"]}</td>
                                      <td>{obj[1] && obj[1]["db"]}</td>
                                      <td>{obj[1] && obj[1]["Info"]}</td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </Table>
                        </div>{" "}
                      </div>
                    </div>

                    <div
                      className="col-md-6"
                      onClick={() => {
                        const object = {
                          user_element: "mysql_metadata1",
                          title: "MySql Read Write Count",
                          id: this.state.id,
                          uuid: this.state.uuid,
                          update: true,
                          server_name: this.state.server_name,
                          mytimezone: this.state.mytimezone,
                          chart: 4,
                          type: "mysql-read-write-count",
                          unique_id: this.state.unique_id,
                          activeTab: this.state.activeTab,
                          cKey: "MySQL",
                        };
                        this.props.history.push("/monitor-server/chart" + objectToQuery(object), object);
                      }}
                    >
                      <div className="card overflow-visible">
                        <div className="card-header justify-content-start">
                          <h3 className="mr-3">MySql Read Write Count</h3>
                          <TooltipPro
                            className="bottom tsize-400"
                            style={{ zIndex: 10 }}
                            tooltip={
                              <div className="row">
                                <div className="col-md-12">
                                  <p>This displays number of read / write / sum of all transactions happening in the database per second.</p>
                                </div>
                              </div>
                            }
                          >
                            <AiOutlineInfoCircle className="infoIcon" />
                          </TooltipPro>
                        </div>
                        <div className="chartInnerDiv">
                          <ChartMySqlOperation
                            tooltip={true}
                            toolbox={true}
                            uuid={this.state.uuid}
                            user_element="mysql_metadata1"
                            SeriesType={graphsKeys}
                            active={this.state.active}
                            isRefresh={this.state.isRefresh}
                            mytimezone={this.state.mytimezone}
                            type="mysql-read-write-count"
                            unique_id={this.state.unique_id}
                            onRefresh={this.state.onRefresh}
                          />
                        </div>
                      </div>
                    </div>

                    <div
                      className="col-md-6"
                      onClick={() => {
                        const object = {
                          user_element: "mysql_metadata1",
                          title: "MySql Statement",
                          id: this.state.id,
                          uuid: this.state.uuid,
                          update: true,
                          server_name: this.state.server_name,
                          mytimezone: this.state.mytimezone,
                          chart: 4,
                          type: "mysql-statement",
                          unique_id: this.state.unique_id,
                          activeTab: this.state.activeTab,
                          cKey: "MySQL",
                        };
                        this.props.history.push("/monitor-server/chart" + objectToQuery(object), object);
                      }}
                    >
                      <div className="card overflow-visible">
                        <div className="card-header justify-content-start">
                          <h3 className="mr-3">MySql Statement</h3>
                          <TooltipPro
                            className="bottom tsize-400"
                            style={{ zIndex: 10 }}
                            tooltip={
                              <div className="row">
                                <div className="col-md-12">
                                  <p>This displays the number of statements executing per second on the database.</p>
                                </div>
                              </div>
                            }
                          >
                            <AiOutlineInfoCircle className="infoIcon" />
                          </TooltipPro>
                        </div>
                        <div className="chartInnerDiv">
                          <ChartMySqlOperation
                            tooltip={true}
                            toolbox={true}
                            uuid={this.state.uuid}
                            user_element="mysql_metadata1"
                            SeriesType={graphsKeys}
                            active={this.state.active}
                            isRefresh={this.state.isRefresh}
                            mytimezone={this.state.mytimezone}
                            type="mysql-statement"
                            unique_id={this.state.unique_id}
                            onRefresh={this.state.onRefresh}
                          />
                        </div>
                      </div>
                    </div>

                    <div
                      className="col-md-6"
                      onClick={() => {
                        const object = {
                          user_element: "mysql_metadata1",
                          title: "MySql Read Write Data",
                          id: this.state.id,
                          uuid: this.state.uuid,
                          update: true,
                          server_name: this.state.server_name,
                          mytimezone: this.state.mytimezone,
                          chart: 4,
                          type: "mysql-read-write-data",
                          unique_id: this.state.unique_id,
                          activeTab: this.state.activeTab,
                          cKey: "MySQL",
                        };
                        this.props.history.push("/monitor-server/chart" + objectToQuery(object), object);
                      }}
                    >
                      <div className="card overflow-visible">
                        <div className="card-header justify-content-start">
                          <h3 className="mr-3">MySql Read Write Data</h3>
                          <TooltipPro
                            className="bottom tsize-400"
                            style={{ zIndex: 10 }}
                            tooltip={
                              <div className="row">
                                <div className="col-md-12">
                                  <p>This displays KBs of data being read / written by all transactions in the database per second.</p>
                                </div>
                              </div>
                            }
                          >
                            <AiOutlineInfoCircle className="infoIcon" />
                          </TooltipPro>
                        </div>
                        <div className="chartInnerDiv">
                          <ChartMySqlOperation
                            tooltip={true}
                            toolbox={true}
                            uuid={this.state.uuid}
                            user_element="mysql_metadata1"
                            SeriesType={graphsKeys}
                            active={this.state.active}
                            isRefresh={this.state.isRefresh}
                            mytimezone={this.state.mytimezone}
                            type="mysql-read-write-data"
                            unique_id={this.state.unique_id}
                            onRefresh={this.state.onRefresh}
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-md-6"
                      onClick={() => {
                        const object = {
                          user_element: "mysql_metadata1",
                          title: "MySql Latency",
                          id: this.state.id,
                          uuid: this.state.uuid,
                          update: true,
                          server_name: this.state.server_name,
                          mytimezone: this.state.mytimezone,
                          chart: 4,
                          type: "mysql-latency",
                          unique_id: this.state.unique_id,
                          activeTab: this.state.activeTab,
                          cKey: "MySQL",
                        };
                        this.props.history.push("/monitor-server/chart" + objectToQuery(object), object);
                      }}
                    >
                      <div className="card overflow-visible">
                        <div className="card-header justify-content-start">
                          <h3 className="mr-3">MySql Latency</h3>
                          <TooltipPro
                            className="bottom tsize-400"
                            style={{ zIndex: 10 }}
                            tooltip={
                              <div className="row">
                                <div className="col-md-12">
                                  <p>This displays the latency (in milli seconds) of read / write operations happening in the database every second.</p>
                                </div>
                              </div>
                            }
                          >
                            <AiOutlineInfoCircle className="infoIcon" />
                          </TooltipPro>
                        </div>
                        <div className="chartInnerDiv">
                          <ChartMySqlOperation
                            tooltip={true}
                            toolbox={true}
                            uuid={this.state.uuid}
                            user_element="mysql_metadata1"
                            SeriesType={graphsKeys}
                            active={this.state.active}
                            isRefresh={this.state.isRefresh}
                            mytimezone={this.state.mytimezone}
                            type="mysql-latency"
                            unique_id={this.state.unique_id}
                            onRefresh={this.state.onRefresh}
                          />
                        </div>
                      </div>
                    </div>

                    {this.state.performanceChartData && <PerformanceChartsOperation properties={properties} name="MySQL" data={this.state.performanceChartData} />}
                  </div>
                </div>
                <div className="card">
                  <div>
                    <div className="selectDiv">
                      <select className="form-control" onChange={(e) => this.setState({ dbName: e.target.value })} value={this.state.dbName}>
                        <option value={""}>Select All</option>
                        {this.state.databaseAndTable &&
                          Array.isArray(Object.keys(this.state.databaseAndTable)) &&
                          Object.keys(this.state.databaseAndTable).map((val) => {
                            return <option value={val}>{val}</option>;
                          })}
                      </select>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div
                        className="col-md-6"
                        onClick={() =>
                          this.props.history.push("/monitor-server/mysql-charts", {
                            user_element: "",
                            title: "Total Latency",
                            id: this.state.id,
                            uuid: this.state.uuid,
                            update: true,
                            server_name: this.state.server_name,
                            mytimezone: this.state.mytimezone,
                            chart: 4,
                            type: "total-latency",
                            unique_id: this.state.unique_id,
                            active: this.state.active,
                            dbName: this.state.databaseAndTable[this.state.dbName],
                          })
                        }
                      >
                        <div className="card  overflow-visible">
                          <div className="card-header justify-content-start">
                            <h3 className="mr-3">Total Latency</h3>
                            <TooltipPro
                              className="bottom tsize-400"
                              style={{ zIndex: 10 }}
                              tooltip={
                                <div className="row">
                                  <div className="col-md-12">
                                    <p>
                                      This widget shows the top 10 tables that have the highest cumulative latency since the DB was last restarted. The data displayed is in
                                      seconds. This is based on cumulative latency and not latency per operation, so if there are large number of operations running on the table
                                      compared to other tables, it will show on top as it is the busiest table.
                                      <br /> <br />
                                      <b>Note </b>: This widget cumulative latency over time so it is normal that latency will linearly increase overtime until the DB is restarted
                                      or latency counter reset.
                                    </p>
                                  </div>
                                </div>
                              }
                            >
                              <AiOutlineInfoCircle className="infoIcon" />
                            </TooltipPro>
                          </div>
                          <div className="chartInnerDiv">
                            <ChartMySqlOperation
                              tooltip={true}
                              toolbox={true}
                              uuid={this.state.uuid}
                              user_element="mysql_metadata1"
                              SeriesType={graphsKeys}
                              active={this.state.active}
                              isRefresh={this.state.isRefresh}
                              mytimezone={this.state.mytimezone}
                              type="total-latency"
                              dbName={this.state.databaseAndTable[this.state.dbName]}
                              onTotalLatencyChart={true}
                              unique_id={this.state.unique_id}
                              onRefresh={this.state.onRefresh}
                            />
                          </div>
                        </div>
                      </div>

                      {this.state.chartRefresh && (
                        <div
                          className="col-md-6"
                          onClick={() =>
                            this.props.history.push("/monitor-server/mysql-charts", {
                              user_element: "",
                              title: "Latency Breakup",
                              id: this.state.id,
                              uuid: this.state.uuid,
                              update: true,
                              server_name: this.state.server_name,
                              mytimezone: this.state.mytimezone,
                              chart: 1,
                              unique_id: this.state.unique_id,
                            })
                          }
                        >
                          <div className="card overflow-visible">
                            <div className="card-header justify-content-start">
                              <h3 className="mr-3">Latency breakup </h3>
                              <TooltipPro
                                className="bottom tsize-400"
                                style={{ zIndex: 10 }}
                                tooltip={
                                  <div className="row">
                                    <div className="col-md-12">
                                      <p>
                                        This widget shows the latency break up by operations of top 10 tables. The data displayed is in seconds. In this widget you can see the
                                        latency for fetch, update, insert and delete operations on the table.This gives you idea on what type of operation is slowing down the top
                                        used tables. Based on this you can try to optimise the queries you are running on that specific table to improve overall performance of DB.
                                      </p>
                                    </div>
                                  </div>
                                }
                              >
                                <AiOutlineInfoCircle className="infoIcon" />
                              </TooltipPro>
                              <h3>{this.state.callBackSetStatisticsLatencyDate ? ": " + this.state.callBackSetStatisticsLatencyDate : ""}</h3>
                            </div>
                            <div className="chartInnerDiv">
                              <StatisticsLatencyOperation
                                tooltip={true}
                                toolbox={true}
                                uuid={this.state.uuid}
                                mytimezone={this.state.mytimezone}
                                top={1}
                                dbName={this.state.databaseAndTable[this.state.dbName] ? this.state.databaseAndTable[this.state.dbName] : []}
                                unique_id={this.state.unique_id}
                                callBackSetStatisticsLatencyDate={(date) => {
                                  this.setState({
                                    callBackSetStatisticsLatencyDate: date,
                                  });
                                }}
                                onRefresh={this.state.onRefresh}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <DatabaseSchema
                  id={this.state.id}
                  tooltip={true}
                  toolbox={true}
                  uuid={this.state.uuid}
                  user_element=""
                  SeriesType={graphsKeys}
                  active={this.state.active}
                  isRefresh={this.state.isRefresh}
                  mytimezone={this.state.mytimezone}
                  type="mysql-latency"
                  server_name={this.state.server_name}
                  {...this.props}
                  unique_id={this.state.unique_id}
                  onRefresh={this.state.onRefresh}
                ></DatabaseSchema>

                {/* <div className="subTitleOuter">
                            <h2 className="sectionHeading">MySql Runtime</h2>
                            {this.state.runtimeColleps ? <IoIosArrowUp size={20} onClick={() => this.setState({ runtimeColleps: !this.state.runtimeColleps })}></IoIosArrowUp> : <><IoIosArrowDown size={20} onClick={() => this.setState({ runtimeColleps: !this.state.runtimeColleps })}>Click To View Details</IoIosArrowDown> </>}
                        </div> */}

                {/* <div className="serverStatisticsSec mt-3" style={{ marginBottom: '100px' }}> */}
                <div className="card overflow-inherit">
                  <div className="subTitleOuter card-header">
                    <div className="justify-content-start card-header">
                      <h2 className="sectionHeading mr-3">MySQL Runtime</h2>
                      <TooltipPro
                        className="bottom tsize-400"
                        style={{ zIndex: 10 }}
                        tooltip={
                          <div className="row">
                            <div className="col-md-12">
                              <p>
                                This displays the MySQL queries that are consuming most of the resources. We display the query, Count (number of times it has executed), Average
                                Latency for each execution, and CPU time (total execution time). All the numbers displayed is since the last DB restart. The data is sorted based on
                                Average Latency number.
                              </p>
                            </div>
                          </div>
                        }
                      >
                        <AiOutlineInfoCircle className="infoIcon" />
                      </TooltipPro>
                    </div>
                    <div className="selectMtTimezone">
                      <span className="myTimezone">
                        {this.state.runtimeColleps ? (
                          <span
                            onClick={() =>
                              this.setState({
                                runtimeColleps: !this.state.runtimeColleps,
                              })
                            }
                          >
                            {" "}
                            Click To Close Details <IoIosArrowUp size={20}></IoIosArrowUp>
                          </span>
                        ) : (
                          <span
                            onClick={() =>
                              this.setState({
                                runtimeColleps: !this.state.runtimeColleps,
                              })
                            }
                          >
                            {" "}
                            Click To View Details <IoIosArrowDown size={20}></IoIosArrowDown>{" "}
                          </span>
                        )}
                      </span>
                      {/* <div className="timeZoneSelect">
                                        <Select selectedOption={this.state.active} setSelectedOption={(e) => this.onActive(e.value)} options={[{ label: '4 Hours', value: 4 }, { label: '8 Hours', value: 8 }, { label: '12 Hours', value: 12 }]}></Select>
                                    </div> */}
                    </div>
                  </div>
                  {this.state.runtimeColleps && <MysqlRunTime unique_id={this.state.unique_id} uuid={this.state.uuid} mytimezone={this.state.mytimezone}></MysqlRunTime>}
                  <div className="p-2 text-center">
                    <div className="selectMtTimezone">
                      <span className="myTimezone">
                        {this.state.runtimeColleps ? (
                          <span
                            onClick={() =>
                              this.setState({
                                runtimeColleps: !this.state.runtimeColleps,
                              })
                            }
                          >
                            {" "}
                            Click To Close Details <IoIosArrowUp size={20}></IoIosArrowUp>
                          </span>
                        ) : (
                          <span
                            onClick={() =>
                              this.setState({
                                runtimeColleps: !this.state.runtimeColleps,
                              })
                            }
                          >
                            {" "}
                            Click To View Details <IoIosArrowDown size={20}></IoIosArrowDown>{" "}
                          </span>
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        ) : (
          ""
        )}

        <div style={{ height: "30px" }}></div>
        <Modal isOpen={this.state.showCron} toggle={this.toggleEditCron}>
          <ModalHeader toggle={this.toggleEditCron}>Edit CRON Details</ModalHeader>
          <ModalBody>
            <div className="row">
              <div className="col-12">
                <div className="form-group">
                  <label>CRON Name</label>
                  <input
                    className="form-control"
                    placeholder="Enter CRON Name"
                    onChange={({ target: { name, value } }) =>
                      this.setState((prev) => ({
                        ...prev,
                        cronForm: { ...prev.cronForm, [name]: value },
                      }))
                    }
                    name="name"
                    value={this.state.cronForm.name ?? ""}
                  />
                  <span className="small text-danger">{this.state.cronForm.nameError}</span>
                </div>
              </div>
              <div className="col-12">
                <div className="form-group">
                  <label>Description</label>
                  <input
                    className="form-control"
                    placeholder="Enter Description"
                    onChange={({ target: { name, value } }) =>
                      this.setState((prev) => ({
                        ...prev,
                        cronForm: { ...prev.cronForm, [name]: value },
                      }))
                    }
                    name="description"
                    value={this.state.cronForm.description ?? ""}
                  />
                  <span className="small text-danger">{this.state.cronForm.descriptionError}</span>
                </div>
              </div>
              <div className="col-12">
                <div className="text-right">
                  <button className="btn btn-lg btn-success" type="button" onClick={this.updateCronJob}>
                    Save
                  </button>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default MonitorServereCharts;
