import { RestMethod } from '../../_helpers/ApiConfig/RestMethod'
import FilterResponse from '../../_helpers/FilterResponse'
import toastr from 'toastr'

export const domainService = {
    getDomainInformation,
    getDomainDNSInformation,
    getDomainDNSInformationByToken,
    getDomainDNSInformationByTokenAndDomainId,
    getDomainSSLRecord,
    getLocationByIps
}

async function getDomainInformation(data) {
    try {
        const response = await RestMethod.POST('/domain_info',data);
        const api_response = FilterResponse(response);  
        return api_response;
    }
    catch (error) {
        if(error && error.response && error.response.data && error.response.data.Error){
            toastr.error(error.response.data.Error)
        }
        console.log("error detected while fetching data from api");
        return null;
    }
}

async function getDomainDNSInformation(data) {
    try {
        const response = await RestMethod.POST('/find_dns_records_using_by_domain',data);
        const api_response = FilterResponse(response);  
        return api_response;
        
    }
    catch (error) {
        console.log("response",error)
        if(error && error.response && error.response.data && error.response.data.Error){
            toastr.error(error.response.data.Error)
        }
        console.log("error detected while fetching data from api");
        return null;
    }
}

async function getDomainDNSInformationByToken(data) {
    try {
        const response = await RestMethod.POST('/fetch_all_domain_name_from_cloudflare',data);
        const api_response = FilterResponse(response);  
        return api_response;
        
    }
    catch (error) {
        console.log("response",error)
        if(error && error.response && error.response.data && error.response.data.Error){
            toastr.error(error.response.data.Error)
        }
        console.log("error detected while fetching data from api");
        return null;
    }
}

async function getDomainDNSInformationByTokenAndDomainId(data) {
    try {
        const response = await RestMethod.POST('/fetch_a_zone_dns_records',data);
        const api_response = FilterResponse(response);  
        return api_response;
        
    }
    catch (error) {
        console.log("response",error)
        if(error && error.response && error.response.data && error.response.data.Error){
            toastr.error(error.response.data.Error)
        }
        console.log("error detected while fetching data from api");
        return null;
    }
}


async function getDomainSSLRecord(data) {
    try {
        const response = await RestMethod.POST('/fetch_a_domain_ssl_record',data);
        const api_response = FilterResponse(response);  
        return api_response;
        
    }
    catch (error) {
        console.log("response",error)
        if(error && error.response && error.response.data && error.response.data.Error){
            toastr.error(error.response.data.Error)
        }
        console.log("error detected while fetching data from api");
        return null;
    }
}

async function getLocationByIps(data) {
    try {
        const response = await RestMethod.POST('/fetch_city_and_country_name_by_ip',data);
        const api_response = FilterResponse(response);  
        return api_response;
        
    }
    catch (error) {
        console.log("response",error)
        if(error && error.response && error.response.data && error.response.data.Error){
            toastr.error(error.response.data.Error)
        }
        console.log("error detected while fetching data from api");
        return null;
    }
}

// lFuvDzP9cpN84UUqLbcSjY3G6zVaXmwMrWMngb2k