import React, { useState } from 'react'

import { Input } from 'reactstrap';
import { Modal } from "react-bootstrap";
import { MdShare } from "react-icons/md";
import { emailService } from '../../_services/servicesWithSecondOrigin/index'
import { IoIosRemoveCircleOutline } from "react-icons/io";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import toastr from 'toastr'

const IndexesShare = ({ ...props }) => {
    const [email, setEmail] = useState("")
    const [show, setShow] = useState(false)
    const [sharedWithArray, setSharedWithArray] = useState([])
    const onsubmit = () => {
        props.share(props.id, email, callBack)
    }

    const sharedWithFunc = async () => {
        let response = await emailService.sharedEmailWith(props.email)
        if (response && Array.isArray(response.data)) {
            setSharedWithArray(response.data)
        }
        setShow(true)
    }

    const monitorIndexesSharedWithRemoveFromOther = async (obj) => {
        let data = {
            "receiver_company_id": obj.company_id,
            "target_id": props.email
        }
        let response = await emailService.shareEmailInboxByMeRemove(data)
        if (response && response.message) {
            toastr.success(response.message)
            sharedWithFunc()
        }
    }

    const callBack = () => {
        setEmail("")
        setShow(false)
    }

    return <>
        <div>
            <OverlayTrigger placement="top" overlay={<Tooltip>Share Server</Tooltip>}>
                <MdShare className={props.className} onClick={() => sharedWithFunc(true)} />
            </OverlayTrigger>
            {/* <Modal show={show} onHide={() => setShow(false)} {...props}> */}
            <Modal show={show} onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <h1 className="modal-title">Share ({props.server_name})</h1>
                </Modal.Header>
                <Modal.Body>
                    <div className="">
                        <p>You can share the server data access with others.  They will be able to view and edit details of the server on g8keeper. Only share with people you know. It will only work if they already have account on g8keeper. You can revoke access anytime you choose to do so. </p>
                        <Input value={email} onChange={e => setEmail(e.target.value)} placeholder="Enter Email" ></Input>
                    </div>
                    <div className="mt-3 text-right">
                        <button type="button" className="btn btn-primary" onClick={() => onsubmit()}>Share</button>
                    </div>
                    
                </Modal.Body>
                <div className="modal-body" style={{borderTop: '1px solid #ddd'}}>
                        {Array.isArray(sharedWithArray) && sharedWithArray.length > 0 && <h6>Inboxes Shared With</h6>}
                        {Array.isArray(sharedWithArray) && sharedWithArray.map((data) => {
                            return <div className="row mt-1">
                                <div className="col-md-9">
                                    {data.email}
                                </div>
                                <div className="col-md-3 text-right">
                                    <IoIosRemoveCircleOutline color="red" size={20} className="actionIcon deleteIcon" onClick={() => monitorIndexesSharedWithRemoveFromOther(data)} ></IoIosRemoveCircleOutline>
                                </div>
                            </div>
                        })}
                    </div>
            </Modal>
        </div>
    </>
}

export default IndexesShare
