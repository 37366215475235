import React from 'react';
import { Table } from 'reactstrap';
import { mysqlRuntimeServices } from '../../_services/servicesWithSecondOrigin/index'
import { ChartCPUUptimeOperation } from '../../_components/MonitorServerChart/index'
import { Progress } from 'reactstrap';
import { Loader } from '../../_components/CommonComponents/index'
class MysqlRunTime extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            uuid: "",
            mytimezone: "",
            allData: [],
            dbarray: [],
            db: 'all',
            loading: true
        }
    }

    componentDidMount() {
        if (this.props.uuid) {
            this.getDataFromServer()
        }
    }

    componentDidUpdate(prev) {
        if (this.props.uuid != prev.uuid) {
            this.getDataFromServer()
        }
    }

    getDataFromServer = () => {
        this.getData()
        setTimeout(() => {
            this.getData("update")
        }, 1000)
    }

    getData = async (update) => {
        let data = {
            "uuid": this.props.uuid,
            "timezone": this.props.mytimezone,
            timediff: new Date().getTimezoneOffset()
        }
        if (!update)
          {  this.setState({
                allData: [],
                dbarray: [],
                loading: true
            })}else{
                data={
                    ...data,
                    query_chart:1
                }
                
            }

        
        let response = await mysqlRuntimeServices.getMysqlRuntime(data)
        if (response && response.data) {
            this.setState({
                allData: response.data
            }, () => {
                let dbarray = []
                Array.isArray(this.state.allData) && this.state.allData.map((obj) => {
                    if (!dbarray.includes(obj.db) && obj.db !== "NULL" && obj.db) {
                        dbarray.push(obj.db)
                    }
                })
                this.setState({
                    dbarray: dbarray,
                    loading: false
                })
            })
        }
    }

    render() {
        let _index = 0
        return (<div style={{ paddingBottom: '20px' }}>
            <div style={{ float: 'right', margin: '20px' }}>
                <select className="form-control" onChange={(e) => this.setState({ db: e.target.value })} value={this.state.db}>
                    <option value="all">All Database</option>
                    {Array.isArray(this.state.dbarray) && this.state.dbarray.map((ob) => {
                        return <option value={ob}>{ob}</option>
                    })}
                </select>
            </div>
            <div className="table-responsive">
                <div className="chart-loader"> <Loader loader={this.state.loading}></Loader></div>
                <Table>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Query</th>
                            <th>Query Chart</th>
                            <th>Count</th>
                            <th>Avg. Latency</th>
                            <th>CPU Time</th>
                        </tr>
                    </thead>
                    <tbody>
                        {/* Do not show any component untill api complete */}
                        {!this.state.loading ? Array.isArray(this.state.allData) && this.state.allData.length > 0 ? this.state.allData.map((data, index) => {
                            let avg_latency = data.avg_latency
                            let cpu_time = data.cpu_time
                            if (this.state.db === 'all' || this.state.db === data.db) {
                                return <tr>
                                    <td scope="row"><strong>{_index = _index + 1}</strong></td>
                                    <td><div title={data.query} style={{ maxWidth: '500px' }}>{data.query && data.query}</div></td>
                                    <td><div className="mysql-runtime" style={{ maxHeight: '50px', maxWidth: '200px' }}>
                                        <ChartCPUUptimeOperation chartData={data.query_chart}></ChartCPUUptimeOperation>
                                    </div></td>
                                    <td><div style={{ maxWidth: '160px' }}>
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <p>{data.exec_count_percentage}%</p>
                                            <p>{data.exec_count}</p>
                                        </div>
                                        <div>
                                            <Progress value={parseInt(data.exec_count_percentage)} />
                                        </div>
                                    </div></td>
                                    <td><div style={{ maxWidth: '160px' }}>
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <p>{data.avg_latency_percentage}%</p>
                                            <p>{avg_latency}</p>
                                        </div>
                                        <div>
                                            <Progress value={parseInt(data.avg_latency_percentage)} />
                                        </div>
                                    </div></td>
                                    <td><div style={{ maxWidth: '160px' }}>
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <p>{data.cpu_time_percentage}%</p>
                                            <p>{cpu_time}</p>
                                        </div>
                                        <div>
                                            <Progress value={parseInt(data.cpu_time_percentage)} />
                                        </div>
                                    </div></td>
                                </tr>
                            }
                        }) : <tr><td colSpan="6" style={{ textAlign: 'center' }}>No Data Found</td></tr> : null}

                    </tbody>
                </Table>
            </div>
        </div>
        );
    }
}

export default MysqlRunTime;