import React, {
    useEffect,
    useState
} from 'react'
import moment from 'moment'
import LoggedinUserChart from '../Charts/LoggedinUserChart'
import { graphService } from '../../_services/servicesWithSecondOrigin/index'
import { Loader } from '../../_components/CommonComponents/index'
const LoggedChartOperation = ({ ...props }) => {
    const [xAxis, setXAxis] = useState(false)
    const [yAxis, setyAxis] = useState(false)
    const [series, setSeries] = useState(false)
    const [setUnSet, setUnSetUI] = useState(true)
    const [loader, setLoader] = useState(true)
    const [allobjs, setAllObj] = useState(true)
    useEffect(() => {
        if (props.uuid)
            getGraphDataFromServer()
    }, [props.uuid, props.active, props.start_datetime, props.end_datetime, props.mytimezone, props.unique_id])

    const getGraphDataFromServer = async () => {
        setSeries([{
            name: "",
            data: [0],
            type: 'line',
            smooth: true,
        }])
        setXAxis({
            type: 'category',
            data: ['3:00']
        })
        setLoader(true)
        var d = new Date();
        var n = d.getTimezoneOffset();
        let data = {
            "uuid": props.uuid,
            "user_element": props.user_element,
            "start_datetime": props.start_datetime ? props.start_datetime : moment(new Date()).add(-props.active || -4, "hours").format("YYYY-MM-DD HH:mm:ss"),
            "end_datetime": props.end_datetime ? props.end_datetime : moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
            "timezone": props.mytimezone,
            timediff: n
        }

        if (props.unique_id) {
            data.unique_id = props.unique_id
        }

        let response = await graphService.getGraphData(data)
        setUnSetUI(false)
        if (response && response.data) {
            if (Array.isArray(response.data.timestamp)) {
                setXAxis({
                    type: 'category',
                    data: response.data.timestamp
                })
            } else {
                setXAxis({
                    type: 'category',
                    data: []
                })
            }
            let Series = []
            if (response.data && response.data && typeof response.data === 'object') {
                setAllObj(response.data)
                // setyAxis({
                //     type: 'category',
                //     data: ["Currently Login"]
                // })
                
                    let arr = []
                    response.data.currently_Login_count?.map((x) => {
                        if (x) {
                            arr.push(x)
                        } else {
                            arr.push('-')
                        }
                    })

                    Series.push({
                        name: "Currently Login",
                        data: arr,
                        type: 'line',
                        smooth: true,
                    })
               
                setSeries(Series)
                setLoader(false)
            }
    
            setUnSetUI(true)
            if (Series.length === 0) {
                setSeries([{
                    name: "",
                    data: [0],
                    type: 'line',
                    smooth: true,
                }])
            } else {
                setSeries(Series)
            }
            setLoader(false)
        } else {
            setUnSetUI(true)
            setLoader(false)
            setSeries([{
                name: "",
                data: [0],
                type: 'line',
                smooth: true,
            }])
            setXAxis({
                type: 'category',
                data: []
            })
        }
    }

    return <>
        <div className="chart-loader"> <Loader loader={loader}></Loader></div>
        {setUnSet && <LoggedinUserChart
            xAxis={xAxis}
            yAxis={yAxis}
            series={series}
            allobjs={allobjs}
            {...props}
        ></LoggedinUserChart>}</>
}
export default LoggedChartOperation