import React from "react";
import Header from "../../_components/CommonComponents/Header/Header";
import { MonitorServersForm } from "../../_components/MonitorServersComponents/index";

class AddServer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: "",
            update: false,
        };
    }

    componentDidMount() {
        if (this.props.update || this.props.location?.state?.update) {
            this.setState({
                id: this.props.id ?? this.props.location?.state?.id,
                update: this.props.update ?? this.props.location?.state?.update,
            });
        }
    }

    render() {
        return (
            <div className="container">
                {this.props.location?.state?.header && <Header history={this.props.history} {...this.props} />}
                <div className="row justify-content-center addServerContent">
                    <div className={this.props.popup ? "" : "col-lg-5 col-md-10 pt-5"}>
                        <MonitorServersForm
                            {...this.props}
                            id={this.state.id}
                            update={this.state.update}
                            toggle={this.props.toggle}
                            responseAfterAdd={this.props.responseAfterAdd}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default AddServer;
