import React from 'react'
import { Error, TooltipPro } from '../../../CommonComponents/index'
import {Input, Button} from 'reactstrap';
import { MdRemove } from "react-icons/md";
import { AiOutlineInfoCircle } from 'react-icons/ai';

const ServerBackUpForm = ({ ...props }) => {
    return <>
        {props.data && <div className="fileSection mt-4">
            <div className="row mt-4">
                <div className="col-md-5">
                    <label>Friendly Folder Name</label><span className="ml-2">
                            <TooltipPro
                                className="bottom tsize-400"
                                tooltip={
                                    <div className="row">
                                        <div className="col-md-12">
                                            <p>This name will be used in alerts and all reporting. Use a name that helps you quickly understand which folder this refers to.</p>
                                        </div>
                                    </div>
                                }
                            >
                               <AiOutlineInfoCircle className="infoIcon" />
                            </TooltipPro>
                        </span>
                    <Input value={props.data.friendly_folder_name} name="friendly_folder_name" placeholder="Friendly Folder Name" onChange={e => props.onChangeFolders(e, props.index)}></Input>
                     {props.errorArray && props.errorArray[props.index] && <Error error={props.errorArray[props.index].friendly_folder_name}></Error>}
                </div>
                <div className="col-md-5">
                    <label>Folder(add full folder path)</label>
                    <label>Friendly Folder Name</label><span className="ml-2">
                            <TooltipPro
                                className="bottom tsize-400"
                                tooltip={
                                    <div className="row">
                                        <div className="col-md-12">
                                            <p>Specify the path of the folder that needs to be backed up. Please provide absolute folder path. For example if you want to backup  /var/www/html then use this full path.</p>
                                        </div>
                                    </div>
                                }
                            >
                               <AiOutlineInfoCircle className="infoIcon" />
                            </TooltipPro>
                        </span>
                    <Input placeholder="Folder(add full folder path)" value={props.data.folder} name="folder" onChange={e => props.onChangeFolders(e, props.index)}></Input>
                    {props.errorArray && props.errorArray[props.index] && <Error error={props.errorArray[props.index].folder}></Error>}
                </div>
            {(props.hideRemove || props.data.id)&& <div className="mt-4 col-md-2 col-lg-1">
                <Button className="remove-sev" onClick={props.remove}><MdRemove></MdRemove></Button>
            </div>}
        </div>
        
        </div>}
        
    </>
}
export default ServerBackUpForm