import React from "react";
import { Row, Col } from 'reactstrap';
import Switch from '../CommonComponents/SwitchBotton/SwitchBotton'
const FormItem = ({ ...props }) => {
    const onClickSwitch = (e, index) => {
        if (props.onClickSwitch)
            props.onClickSwitch(e, index)
    }

    const onClickGotoUrlDetailsPage = (id) => {
        if (props.onClickGotoUrlDetailsPage) {
            props.onClickGotoUrlDetailsPage(id)
        }
    }

    return <div>
        {Array.isArray(props.monitorUrlsArray) && props.monitorUrlsArray.map((obj, index) => {
            let status = obj.status === "1" ? true : false
            return <Row key={index} className="social-media-form-listing" onClick={()=>onClickGotoUrlDetailsPage(obj.id)}>
                <Col>
                    {obj.url}
                </Col>
                <Col className="text-right">
                    <Switch disabled={props.disabled ? props.disabled : false} checked={status} onClick={(e) => onClickSwitch(e, index)}></Switch>
                </Col>
            </Row>
        })}
    </div>
}
export default FormItem