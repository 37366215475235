import React, { useState, useEffect } from 'react';
import { Progress } from 'reactstrap';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import moment from 'moment';

const MonitorUrlprogressBar = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const [allKey, setAllkeys] = useState([]);
    const [allValues, seAllValues] = useState([]);
    const [progressArray, setProgressArray] = useState([]);
    const toggle = () => setIsOpen(!isOpen);

    useEffect(() => {
        setAllkeys(props['hour_status'] ? Object.keys(props['hour_status']).sort((a, b) => a > b ? 1 : a < b ? -1 : 0) : [])
        seAllValues(props['hour_status'] ? Object.values(props['hour_status']) : [])
    }, [props['hour_status']])


    useEffect(() => {
        if (allKey)
            manageRecprd()
    }, [allKey])

    const manageRecprd = () => {
        let progressArray = [];
        allKey.map((datetime, index) => {
            let last24Hours = moment(new Date()).add(-24, 'hours').format("YYYY-MM-DD HH:mm")
            let currentTime = moment(new Date())
            var a = moment(datetime, "YYYY-MM-DD HH:mm");
            var startTime = moment(last24Hours, "YYYY-MM-DD HH:mm");
            let createDate = ""
            if (props.create_datetime) {
                createDate = moment(props.create_datetime, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm:ss");
            }
            if (last24Hours < datetime) {  // date is less then 24 hours
                if (allKey[index - 1]) { //if value is before current time if not then this is first record
                    if (last24Hours > allKey[index - 1]) {
                        let count = a.diff(startTime, 'seconds')
                        progressArray[0] = {
                            count: count > 240 ? count : 240,
                            // value: allValues[index] === "UP" ? "DOWN" : "UP",
                            value: props['hour_status'][datetime] === "UP" ? "DOWN" : "UP",
                            key: "2"
                        }
                    } else {
                        let count = a.diff(moment(allKey[index - 1], "YYYY-MM-DD HH:mm"), 'seconds')
                        progressArray.push({
                            count: count > 240 ? count : 240,
                            // value: allValues[index] === "UP" ? "DOWN" : "UP",
                            value: props['hour_status'][datetime] === "UP" ? "DOWN" : "UP",
                            key: "3"
                        })
                    }
                } else {
                    let count = a.diff(startTime, 'seconds')
                    progressArray = []


                    if (createDate > last24Hours) {
                        progressArray.push({
                            count: count > 240 ? count : 240,
                            //value: allValues[index] === "UP" ? "DOWN" : "UP",
                            value: "No data available",
                            key: "4"
                        })
                    } else {




                        progressArray.push({
                            count: count > 240 ? count : 240,
                            // value: allValues[index] === "UP" ? "DOWN" : "UP",
                            value: props['hour_status'][datetime] === "UP" ? "DOWN" : "UP",
                            key: "4"
                        })
                    }
                }

                if (allKey.length - 1 === index) {

                    let count1 = currentTime.diff(a, 'seconds')
                    progressArray.push({
                        count: count1 > 240 ? count1 : 240,
                        // value: allValues[index],
                        value: props['hour_status'][datetime],
                        key: "5"
                    })
                }
            } else {
                progressArray[0] = {
                    count: 24 * 60 * 60,
                    //value: allValues[index],
                    value: props['hour_status'][datetime],
                    key: "1"
                }
            }
        })
        setProgressArray(progressArray)
    }

    return (
        <>
            {Array.isArray(progressArray) && <Progress multi >
                {progressArray.map((data, index) => {
                    return <>
                        {['top', 'right', 'bottom', 'left'].map((placement) => (
                            <OverlayTrigger
                                key={placement}
                                placement={placement}
                                overlay={
                                    <Tooltip id={`tooltip-${placement}`}>
                                        {data.value}
                                    </Tooltip>
                                }
                            >
                                <Progress bar className={data.value === "No data available"?"grayProgressbar":""} color={data.value === "No data available"?"":data.value === "UP" ? "success" : "danger"} value={(data.count * 100) / (60 * 60 * 24)} />
                            </OverlayTrigger>
                        ))}
                    </>
                })}
            </Progress>}
        </>
    );
}

export default MonitorUrlprogressBar
