import React, { useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";

const BarStackChartSingleLine = ({ onEvents, height = "500px", ...props }) => {
  const [setUnSet, setUnSetUI] = useState(true);
  useEffect(() => {
    if (props.onRefresh) {
      setUnSetUI(false);
      setTimeout(() => {
        setUnSetUI(true);
      }, 1);
    }
  }, [props.onRefresh, props.series]);

  const options = {
    grid: {
      left: "3%",
      right: "10%",
      bottom: props.dataZoom ? "5%" : "3%",
      containLabel: true,
      top: "10%",
    },
    yAxis: {
      type: "category",
      data: [0],
    },
    xAxis: {
      type: "value",
    },
    series: [],
  };

  if (props.title) {
    let style = {};
    var element = document.body;
    if (element.classList.contains("dark")) {
      style = {
        textStyle: {
          color: "#b6c2e4",
        },
      };
    }
    options.title = {
      text: props.title,
      ...style,
    };
  }
  if (props.legend) {
    options.legend = {
      data: props.legend,
    };
  }

  if (props.toolbox) {
    options.toolbox = {
      feature: {
        saveAsImage: {},
      },
    };
  }
  if (props.tooltip) {
    options.tooltip = {
      trigger: "axis",
      formatter: function (e) {
        let rowData = e[0].name;
        // Array.isArray(e) && console.log(e);
        e.forEach((data) => {
          rowData =
            rowData +
            `<br/> ${data.marker}   ${
              "Partition Size: " +
              props?.allData?.partition_size[data.dataIndex].value +
              " MB"
            }
            <br/> ${data.marker}
            ${
              "Partition Available: " +
              props?.allData?.partition_availble[data.dataIndex] +
              " MB"
            }
            <br/> ${data.marker}
            ${
              "Partition Used: " +
              props?.allData?.partition_used[data.dataIndex] +
              " MB"
            }
            <br/> ${data.marker}
            ${
              "Used Percentage : " +
              props?.allData?.partition_percentage[data.dataIndex].value +
              " %"
            }
            `;
        });
        return rowData;
      },
    };
    if (localStorage.getItem("themes") === "dark") {
      options.tooltip = {
        ...options.tooltip,
        backgroundColor: "#30384c",
        textStyle: {
          color: "#b6c2e4",
        },
      };
    }
  }

  if (props.dataZoom) {
    options.dataZoom = [{}];
  }
  if (props.grid) {
    options.grid = props.grid;
  }
  if (props.xAxis) {
    // options.xAxis = props.xAxis;
    options.xAxis = {
      ...props.xAxis,
      axisLabel: {
        formatter: `{value} ${props.dataSizeType ? props.dataSizeType : ""}`,
      },
    };
  }
  if (props.yAxis) {
    options.yAxis = props.yAxis;
  }
  if (props.series) {
    options.series = props.series;
  }

  return (
    <>
      {" "}
      <>
        {setUnSet && (
          <ReactECharts
            style={{ height }}
            option={options ? options : {}}
            onEvents={onEvents}
          />
        )}
      </>
    </>
  );
};
export default BarStackChartSingleLine;
