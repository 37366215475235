export const graphsKeys = [
  "read_latency",
  "write_latency",
  "total_latency",
  "total_read",
  "total_written",
  "Read Count",
  "Write Count",
  "Read Bytes",
  "read_bytes",
  "Write Bytes",
  "write_bytes",
  "total",
  "system",
  "user",
  "softirq",
  "irq",
  "iowait",
  "steal",
  "idle",
  "free",
  "available",
  "used",
  "curr_Uploading_byte",
  "curr_downloading_byte",
  "Failed Login Activity",
  "Download",
  "Upload",
  "Connection",
  "Threads",
  "total_latency",
];

export const VERSION_NUMBER = "2.0.52";
