import React from 'react'
import Header from '../../_components/CommonComponents/Header/Header'
import SignUpForm from '../../_components/SignUpForm/SignUpForm'
import g8keeperLogo from '../../assets/img/logo.svg';

class SignUp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        return (<div>
            <Header history={this.props.history} {...this.props}></Header>
            <div className="loginForm">
            <div className="card">
                <div className="loginLogo"><img src={g8keeperLogo} alt="Logo" /></div>              
                    {/* <h2>User Login</h2> */}
                    <h4 className="card-title"> Welcome to g8keeper! 👋 </h4>
                <div className="seo-monitoring-form">
                    <SignUpForm
                        history={this.props.history}
                    ></SignUpForm>
                </div>
            </div>
            </div>
        </div>)
    }
}

export default SignUp