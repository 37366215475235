import React from 'react'
import Header from '../../_components/CommonComponents/Header/Header'
import {MonitorServerListing} from '../../_components/MonitorServersComponents/index'
class MonitorServers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
               onRefresh:""
        }
    }

    refreshData=()=>{
          this.setState({onRefresh:Math.random()})
    }

    render() {
        return (<div>
            <Header history={this.props.history} {...this.props} callRefresh={this.refreshData}></Header>
            <div className="container">
                <div className="row">
                    {/* <Col className="col-md-2">
                        <h3>Dashboard</h3>
                    </Col> */}
                    <div className="col-md-12">
                        <MonitorServerListing {...this.props} onRefresh={this.state.onRefresh}></MonitorServerListing>
                    </div>
                </div>
            </div>    
        </div>)
    }
}

export default MonitorServers