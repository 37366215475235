import { Validation } from "../../_helpers/validation/Validation";
import { monitorServersServices } from "../../_services/servicesWithSecondOrigin";
import toastr from "toastr";

export const useShareMoniterServer = () => {
  return async (id, email, callBack) => {
    let rule = [
      {
        field_name: "email",
        label: "Email",
        type: "email",
        isRequired: true,
      },
    ];
    let errobj = Validation({ email: email }, rule);
    if (errobj && errobj.email === "") {
      let data = {
        email: email,
      };
      let response = await monitorServersServices.shareMonitorServers(id, data);
      if (response?.message) {
        toastr.warning(response.message);
      }
      if (callBack && response && response.status === 200) {
        callBack();
      }
    } else {
      toastr.warning(errobj.email);
    }
  };
};
