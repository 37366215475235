import React, { useState, useEffect } from 'react'
import { Form } from 'reactstrap';
import { MButton, Loader } from '../../CommonComponents/index'
import ModifiedFilesForm from './ModifiedFilesForm/ModifiedFilesForm'
import { Constants } from '../Constants'
import { monitorServersSettingServices } from '../../../_services/servicesWithSecondOrigin/index'
import toastr from 'toastr'
import { Validation } from '../../../_helpers/validation/Validation'
import { confirmAlert } from "react-confirm-alert";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const fileObj = {
    "friendly_folder_name": "",
    "folder": "",
    "exclude_sub_folders": "",
    "allowed_file_types": "",
}

const ModifiedFiles = ({ ...props }) => {
    const [frequencyCheck, setFrequncyCheck] = useState(86400)
    const [files, setFiles] = useState([fileObj])
    const [errorArray, setErrorArray] = useState([])
    const [loader, setLoader] = useState(false)

    useEffect(() => {
        if (props.active === 2)
            getDataFromServer()
    }, [props.MSId, props.active]);

    const getDataFromServer = async () => {
        setLoader(true)
        setFiles([fileObj]);
        setFrequncyCheck(86400)
        if (props.MSId) {
            let response = await monitorServersSettingServices.getMonitorServerAllowed(props.MSId)
            if (response && response.data && response.data.length !== 0) {
                setFiles(response.data)
                if (response.data[0] && response.data[0].frequency_for_checks) {
                    setFrequncyCheck(response.data[0].frequency_for_checks)
                } else {
                    setFrequncyCheck(0)
                }
            }
        }
        setLoader(false)
    }


    const addFiles = () => {
        let cloneArray = files.concat(fileObj)
        setFiles(cloneArray)
    }

    const remove = (index) => {
        confirmAlert({
            title: "Confirm to Delete",
            message: <p>Are you sure to do this.</p>,
            buttons: [
                {
                    label: "Yes",
                    onClick: async () => {
                        let cloneArray = files.slice()
                        if (cloneArray[index].id) {
                            deletePerticularSettingData(cloneArray[index].id, cloneArray, index)
                        } else {
                            if (cloneArray.length === 1) {
                                allreset()
                            } else {
                                cloneArray.splice(index, 1)
                                setFiles(cloneArray)
                            }
                        }
                    },
                },
                {
                    label: "No",
                    onClick: () => console.log("No"),
                },
            ],
        });
    }

    const deletePerticularSettingData = async (id, cloneArray, index) => {
        let data = {
            "type": "allowed_files",
            "id": id
        }
        let response = await monitorServersSettingServices.deleteSettingData(data);
        if (response && response.status === 200) {
            if (response.message)
                toastr.success(response.message)
            if (cloneArray.length === 1) {
                allreset()
            } else {
                cloneArray.splice(index, 1)
                setFiles(cloneArray)
            }
        }
    }

    const allreset = () => {
        setFiles([fileObj])
        setFrequncyCheck(86400)
    }

    const onChangeFile = (e, index) => {
        let cloneArray = JSON.parse(JSON.stringify(files))
        cloneArray[index][e.target.name] = e.target.value
        setFiles(cloneArray)
    }

    const onChangeFileTag = (e, index, name) => {
        let cloneArray = JSON.parse(JSON.stringify(files))
        cloneArray[index][name] = e
        setFiles(cloneArray)
    }

    const onSubmit = async (e) => {
        let cloneArray = files.slice()
        let flag = 0
        let errorArray = []
        const field = Constants().fileRules;
        Array.isArray(cloneArray) && cloneArray.map((obj) => {
            let errobj = Validation(obj, field);
            Object.keys(errobj).map(index => {
                if (errobj[index] !== "") {
                    flag = 1;
                }
            });
            errorArray = errorArray.concat(errobj)
        })

        // let errobj = Validation({ frequencyCheck: frequencyCheck }, Constants().frequencyCheck);
        // Object.keys(errobj).map(index => {
        //     if (errobj[index] !== "") {
        //         flag = 1;
        //     }
        // });

        setErrorArray(errorArray)
        //setError(errobj)
        if (flag === 0) {
            let data = {
                // "frequency_for_checks": frequencyCheck,
                "files": files
            }

            let response = await monitorServersSettingServices.updateMonitorServerAllowed(data, props.MSId)
            if (response) {
                if (response && response.message) {
                    toastr.success(response.message)
                    getDataFromServer()
                }
            }
        }
    }

    return <div className="serverSettingData">
        <div className="setLoaderPosition">
            <Loader loader={loader}></Loader>
        </div>
        <div className="serverInfoTabsTitle flex-column align-items-start">
            <h3>File Change Monitoring</h3>
            <p className='serverInfoDetails mb-1'>G8Keeper allows you to keep track of files / folders that are getting modified in your server. This way you are aware about activity on your server and can quickly identify malicious activity. This is the section where you configure the same. </p>
            <p className='serverInfoDetails mb-1'>You can add the list of folders where you want to monitor any/all changes.  G8keeper scans the folder(s) and all sub folders (recursive) in the folder added in this section and reports if any of the files are modified/added/deleted.</p>
            <p className='serverInfoDetails mb-1'>You generally want to monitor the folders that are exposed to web traffic.Web applications at times create some log files, so you can disable the reporting of logfiles by adding those filetypes to "Allowed Filetypes". You can also add some sub-folders or specific files to not be tracked for monitoring to ensure that no false alerts are generated.</p>
            <p className='serverInfoDetails mb-1'><strong>Note: </strong>You might want to add only web exposed folders for this check. If the folder (and sub-folder) being monitored are too many, it can lead to some performance hit. So select the folders intelligently. </p>
        </div>
        <div>
            <Form onSubmit={(e) => onSubmit(e)}>
                {/* <div className="row mt-4">
                    <div className="col-md-12">
                        <label>Frequency For Checks(seconds)</label>
                        <Input value={frequencyCheck} onChange={e => setFrequncyCheck(e.target.value)} placeholder="Frequency For Checks(seconds)"></Input>
                        {error && error.frequencyCheck && <Error error={error.frequencyCheck}></Error>}
                    </div>
                </div> */}
                {Array.isArray(files) && files.map((data, index) => {
                    return <ModifiedFilesForm hideRemove={files.length !== 1} errorArray={errorArray} remove={() => remove(index)} data={data} onChangeFile={onChangeFile} onChangeFileTag={onChangeFileTag} index={index}></ModifiedFilesForm>
                })}

                <div className="mt-4 disallowedbuttons justify-content-center">
                    {/* <Button className="btn btn-primary mr-3" onClick={() => addFiles()} >Add More</Button> */}
                    <OverlayTrigger placement="top" overlay={<Tooltip>Add More</Tooltip>}>
                        {/* <Button className="bluesum btn" onClick={() => addFiles()} ><MdAdd></MdAdd></Button> */}
                        <MButton label="Add More" onClick={() => addFiles()} className="bluebutton mx-1"></MButton>
                    </OverlayTrigger>
                    <MButton label="Save" onClick={(e) => onSubmit(e)} className="bluebutton mx-1"></MButton>
                </div>
            </Form>
        </div>
    </div>
}

export default ModifiedFiles