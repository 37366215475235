import { RestMethodSecondOrigin } from "../../../_helpers/ApiConfigTwo/RestMethod";
import FilterResponse from "../../../_helpers/FilterResponse";
import { isAuthorized } from "../../../_helpers/Common/Common";
export const ApiMonitorService = {
  createApiForMonitor,
  apiMonitorList,
  getApiMonitorById,
  updateApiMonitoring,
  allApis,
};

async function createApiForMonitor(data) {
  try {
    const response = await RestMethodSecondOrigin.POST(
      "/api-monitor/create-api",
      data
    );
    const api_response = FilterResponse(response);
    return api_response;
  } catch (error) {
    isAuthorized(error);
    return null;
  }
}

async function apiMonitorList() {
  try {
    const response = await RestMethodSecondOrigin.GET(
      "/api-monitor/get-api-list"
    );
    const api_response = FilterResponse(response);
    return api_response;
  } catch (error) {
    isAuthorized(error);
    return null;
  }
}

async function getApiMonitorById(id) {
  try {
    const response = await RestMethodSecondOrigin.POST(
      "/api-monitor/view-api?id=" + id
    );
    const api_response = FilterResponse(response);
    return api_response;
  } catch (error) {
    isAuthorized(error);
    return null;
  }
}

async function updateApiMonitoring(data, id) {
  try {
    const response = await RestMethodSecondOrigin.POST(
      "/api-monitor/update-api?id=" + id,
      data
    );
    const api_response = FilterResponse(response);
    return api_response;
  } catch (error) {
    isAuthorized(error);
    return null;
  }
}

async function allApis(data) {
  try {
    const response = await RestMethodSecondOrigin.POST(
      "/api-monitor/all-apis",
      data
    );
    const api_response = FilterResponse(response);
    return api_response;
  } catch (error) {
    isAuthorized(error);
    return null;
  }
}
