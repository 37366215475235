import React from "react";
import Select from "react-select";
const SelectDropDown = ({ ...props }) => {
  let selectedOption = null;
  let isDisabled = props.isDisabled ? props.isDisabled : false;
  Array.isArray(props.options) &&
    props.options.map((obj) => {
      if (
        props.selectedOption !== "" &&
        props.selectedOption !== null &&
        props.selectedOption !== undefined &&
        obj.value === props.selectedOption
      ) {
        selectedOption = obj;
      }
    });
  return (
    <Select
      value={selectedOption !== null ? selectedOption : null}
      onChange={props.setSelectedOption}
      options={Array.isArray(props.options) ? props.options : []}
      isDisabled={isDisabled}
      className={props.className ? props.className : null}
      style={props.style ? props.style : null}
      {...props.propsInput}
    />
  );
};

export default SelectDropDown;
