import React from "react";
import { Spinner } from "reactstrap";

const SpinnerForTable = ({ loader, className }) => {
  return (
    <>
      {loader && (
        <Spinner
          animation="border"
          variant="primary"
          className={className ? className : ""}
        />
      )}
    </>
  );
};

export default SpinnerForTable;
