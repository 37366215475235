import React from 'react'
import { Button } from 'reactstrap';
import Header from '../../_components/CommonComponents/Header/Header'
import SeoMonitoringForm from '../../_components/SeoMonitoringForm/SeoMonitoringForm'
import { seoMonitoringServices } from '../../_services/servicesWithSecondOrigin/index'
import toastr from 'toastr'
import { Validation } from '../../_helpers/validation/Validation'
import { Constants } from './Constants'
class SeoMonitoring extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            domain: "Domain Not Found",
            id: "",
            update: false,
            seoMonitoringArray: [],
            suggestionsArray: [],
            errorArray: []
        }
    }

    componentDidMount() {
        if (!this.props.popup) {
            if (this.props.location && this.props.location.state && this.props.location.state.domain) {
                this.setState({
                    domain: this.props.location.state.domain,
                    suggestionsArray: [this.props.location.state.domain]
                })
            } else {
                this.props.history.push('/')
            }

            if (this.props.location && this.props.location.state && this.props.location.state.update) {
                this.setState({
                    update: this.props.location.state.update,
                    id: this.props.location.state.id,
                    domain: this.props.location.state.domain,
                }, () => {
                    this.getAllSeoDomainKeyword()
                })
            }
        } else {
            if (this.props.data && this.props.data.id) {
                this.setState({
                    update: true,
                    id: this.props.data.id,
                    domain: this.props.domain,
                }, () => {
                    this.getAllSeoDomainKeyword()
                })
            }
        }
    }

    componentDidUpdate(prev){
          if(prev.domain !== this.props.domain){
            if (this.props.data && this.props.data.id) {
                this.setState({
                    update: true,
                    id: this.props.data.id,
                    domain: this.props.domain,
                }, () => {
                    this.getAllSeoDomainKeyword()
                })
            }
          }
    }

    getAllSeoDomainKeyword = async () => {
        let data = {
            "domain_name": this.state.domain
        }
        let response = await seoMonitoringServices.getAllSeoMonitoring(data)
        if (response && response.data && Array.isArray(response.data) && response.data.length > 0) {
            let newArray = []
            response.data.map((obj) => {
                if (!newArray.includes(obj.url))
                    newArray.push(obj.url)
            })
            this.setState({
                seoMonitoringArray: response.data,
                suggestionsArray: newArray
            })
        } else {
            this.setState({
                seoMonitoringArray: [{
                    "keyword": "",
                    "url": this.props.domain
                }]
            })
        }
    }

    addSeoMonitoringKeyword = () => {
        let seoMonitoringArray = [...this.state.seoMonitoringArray]
        if (seoMonitoringArray.length < 10) {
            seoMonitoringArray.push({
                "keyword": "",
                "url": this.state.domain
            });
            this.setState({
                seoMonitoringArray: seoMonitoringArray
            })
        } else {
            toastr.warning("Max 10 Keywords Allowed")
        }
    }


    onSubmit = async () => {
        let seoMonitoringArray = this.state.seoMonitoringArray.slice()
        // if (seoMonitoringArray.length === 0) {
        //     toastr.warning("Please Add atleast One Keyword")
        //     return false
        // }
        let flag = 0
        let errorArray = []
        const field = Constants().keywordRule;
        Array.isArray(seoMonitoringArray) && seoMonitoringArray.map((obj) => {
            let errobj = Validation(obj, field);
            Object.keys(errobj).map(index => {
                if (errobj[index] !== "") {
                    flag = 1;
                }
            });
            errorArray = errorArray.concat(errobj)
        })

        this.setState({
            errorArray: errorArray,
        })

        if (flag === 0) {
            let data = {
                "domain_name": this.state.domain,
                "keywords": this.state.seoMonitoringArray
            }
            let response = ""
            if (this.state.update) {
                response = await seoMonitoringServices.updateSeoMonitoring(data)
            } else {
                response = await seoMonitoringServices.addSeoMonitoringData(data)
            }

            if (response) {
                if (response && response.message) {
                    toastr.success(response.message)
                }
                if (this.state.update) {

                    if (this.props.popup) {
                        this.props.callback()
                    } else {
                        this.props.history.push('/update-social-media', { domain: this.state.domain, id: this.state.id, update: true })
                    }
                } else {
                    this.props.history.push('/social-media', { domain: this.state.domain })
                }

            }
        }
    }

    onChange = (value, index, name) => {
        let seoMonitoringArray = [...this.state.seoMonitoringArray]
        seoMonitoringArray[index][name] = value
        this.setState({
            seoMonitoringArray: seoMonitoringArray
        })
    }

    removeKeyword = (index) => {
        let seoMonitoringArray = [...this.state.seoMonitoringArray]
        seoMonitoringArray.splice(index, 1)
        this.setState({
            seoMonitoringArray: seoMonitoringArray
        })
    }

    backPrevious = () => {
        if (this.state.update) {
            this.props.history.push('/update-domain', { domain: this.state.domain, id: this.state.id })
        } else {
            this.props.history.push('/', { domain: this.state.domain })
        }

    }

    render() {
        return (<div>
            {!this.props.popup && <Header history={this.props.history} {...this.props}></Header>}
            <div>
                <div className="seo-monitoring">
                    <div className="card">
                        {!this.props.popup && <div className="card-header">
                            <h3>SEO Monitoring</h3>
                        </div>}
                        <div className="seo-monitoring-form p-4">
                            {!this.props.popup && <h4>Domain: {this.state.domain}</h4>}
                            {/* <p>Free Keywords 0/10 </p> */}
                            <SeoMonitoringForm
                                seoMonitoringArray={this.state.seoMonitoringArray}
                                addSeoMonitoringKeyword={this.addSeoMonitoringKeyword}
                                suggestionsArray={this.state.suggestionsArray}
                                onChange={this.onChange}
                                errorArray={this.state.errorArray}
                                removeKeyword={this.removeKeyword}
                            ></SeoMonitoringForm>
                        </div>
                    </div>

                </div>
                <div className="belowBtns">
                    {!this.props.popup && <Button className="PreviousBotton" onClick={() => this.backPrevious()}>Previous</Button>}
                    <Button className="NextButton" onClick={() => this.onSubmit()}>{!this.props.popup ? 'Next' : "Update"}</Button>
                </div>
            </div>
        </div>)
    }
}

export default SeoMonitoring