import React from "react";
import { Row, Col } from "react-bootstrap";
import Header from "../../_components/CommonComponents/Header/Header";
import LineStackChart from "../../_components/Charts/LineStackChart";
import {
  UsageChart,
  BackupChartOperation,
  ChartMySqlOperation,
  ChartOperationsApachhe,
  ChartOperationsApachheOuter,
} from "../../_components/MonitorServerChart/index";
import OpenPortChartOperation from "../../_components/OpenPortChartOperation/OpenPortChartOperation";
import {
  DateTime,
  ServerCurrentLoggedIn,
} from "../../_components/CommonComponents/index";
import { Button } from "reactstrap";
import { IoIosArrowRoundBack } from "react-icons/io";
import { MdRefresh, MdRemoveRedEye } from "react-icons/md";
import moment from "moment";
import { graphsKeys } from "./Constants";
import * as echarts from "echarts/core";
import { objectToQuery } from "../../_helpers/Common/Common";
class MonitorServerIndividualApachheChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      uuid: "",
      title: "",
      chart: "",
      user_element: "",
      server_name: "",
      mytimezone: "",
      start_datetime: moment(new Date())
        .add(-4, "hours")
        .format("YYYY-MM-DD HH:mm:ss"),
      end_datetime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      start_datetimeForChart: moment(new Date())
        .add(-4, "hours")
        .format("YYYY-MM-DD HH:mm:ss"),
      end_datetimeForChart: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      graphArray: [],
      type: "",
      unique_id: null,
    };
  }

  componentDidMount() {
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.id
    ) {
      this.setState({
        id: this.props.location.state.id,
        uuid: this.props.location.state.uuid,
        title: this.props.location.state.title,
        user_element: this.props.location.state.user_element,
        chart: this.props.location.state.chart,
        server_name: this.props.location.state.server_name,
        mytimezone: this.props.location.state.mytimezone,
        type: this.props.location.state.type,
        graphArray: [
          {
            title: this.props.location.state.title,
            user_element: this.props.location.state.user_element,
            chart: this.props.location.state.chart,
            type: this.props.location.state.type,
          },
        ],
      });
    } else {
      this.props.history.push("/monitor-servers");
    }

    // setInterval(() => {
    //     this.setState({
    //         "end_datetime": moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
    //     })
    // }, 2000)
  }

  setStartDateTime = (date) => {
    this.setState(
      {
        start_datetime: moment(date).format("YYYY-MM-DD HH:mm:ss"),
      },
      () => {
        var a = moment(this.state.end_datetime, "YYYY-MM-DD HH:mm:ss");
        var b = moment(this.state.start_datetime, "YYYY-MM-DD HH:mm:ss");
        let c = a.diff(b, "days");
        if (c > 31) {
          this.setState({
            end_datetime: b
              .add(1, "M")
              .add(-1, "D")
              .format("YYYY-MM-DD HH:mm:ss"),
          });
        }
      }
    );
  };

  setEndDateTime = (date) => {
    this.setState(
      {
        end_datetime: moment(date).format("YYYY-MM-DD HH:mm:ss"),
      },
      () => {
        var a = moment(this.state.end_datetime, "YYYY-MM-DD HH:mm:ss");
        var b = moment(this.state.start_datetime, "YYYY-MM-DD HH:mm:ss");
        let c = a.diff(b, "days");
        if (c > 31) {
          this.setState({
            start_datetime: a.add(-1, "M").format("YYYY-MM-DD HH:mm:ss"),
          });
        }
      }
    );
  };

  onClickShowGraphData = () => {
    this.setState({
      start_datetimeForChart: this.state.start_datetime,
      end_datetimeForChart: this.state.end_datetime,
    });
  };

  onSelectServer = (e) => {
    this.setState({
      id: e.id,
      uuid: e.value,
      server_name: e.label,
    });
  };

  filterPassedStartDate = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);
    const end_datetime = new Date(moment(this.state.end_datetime));
    return currentDate > selectedDate && end_datetime >= selectedDate;
  };

  filterPassedEndDate = (time) => {
    const currentDate = new Date();
    const start_datetime = new Date(moment(this.state.start_datetime));
    const selectedDate = new Date(time);
    return currentDate > selectedDate && start_datetime <= selectedDate;
  };

  resetTime = () => {
    this.setState({
      start_datetimeForChart: moment(new Date())
        .add(-4, "hours")
        .format("YYYY-MM-DD HH:mm:ss"),
      end_datetimeForChart: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      start_datetime: moment(new Date())
        .add(-4, "hours")
        .format("YYYY-MM-DD HH:mm:ss"),
      end_datetime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
    });
  };

  addOrRemoveChartsInList = (object) => {
    console.log("Listening...");
    let graphArray = this.state.graphArray.slice();
    this.setState({ graphArray: [] }, () => {
      let checkExistance = graphArray.filter((data) => {
        return data.user_element === object.user_element;
      });
      if (checkExistance.length === 0) {
        graphArray.push(object);
        this.setState({ graphArray: graphArray });
      } else {
        this.setState({
          graphArray: graphArray.filter((data) => {
            return data.user_element !== object.user_element;
          }),
        });
      }
    });
  };

  onSelectUniqueId = (e) => {
    this.setState({
      unique_id: e.value,
    });
  };

  render() {
    let object = {
      id: this.state.id,
      uuid: this.state.uuid,
      update: true,
      server_name: this.state.server_name,
    };
    return (
      <div className="container">
        <Header history={this.props.history} {...this.props}></Header>

        {/* Filter section */}
        <div className="serverDetailsSec justify-content-between">
          <div className="d-flex">
            <Button
              className="backBtn mr-4"
              onClick={() =>
                this.props.history.push(
                  "/monitor-server/charts" + objectToQuery(object),
                  object
                )
              }>
              <IoIosArrowRoundBack></IoIosArrowRoundBack>
            </Button>
            <ServerCurrentLoggedIn
              server_name={this.state.server_name}
              uuid={this.state.uuid}
              onSelectServer={this.onSelectServer}
              mytimezone={this.state.mytimezone}
              unique_id={this.state.unique_id}
              onSelectUniqueId={this.onSelectUniqueId}
            />
          </div>
          <div className="select_startdatetime">
            <div className="row align-items-end">
              <div className="col-md-4">
                <label>Start Date Time</label>
                <DateTime
                  dateTime={this.state.start_datetime}
                  setDateTime={this.setStartDateTime}
                  filterPassedDate={this.filterPassedStartDate}
                  className="form-control"></DateTime>
              </div>
              <div className="col-md-4">
                <label>End Date Time</label>
                <DateTime
                  dateTime={this.state.end_datetime}
                  setDateTime={this.setEndDateTime}
                  filterPassedDate={this.filterPassedEndDate}
                  className="form-control"></DateTime>
              </div>
              <div className="col-md-3 d-flex" style={{ paddingBottom: "1px" }}>
                <Button
                  className="addEditBtn primary-btn"
                  onClick={() => this.onClickShowGraphData()}>
                  <MdRemoveRedEye></MdRemoveRedEye>
                </Button>
                <Button
                  className="iconBtn ml-3"
                  onClick={() => this.resetTime()}>
                  <MdRefresh></MdRefresh>
                </Button>
              </div>
            </div>
          </div>
        </div>

        {/* End */}

        {/* <div className="charts-list">
                    <li><a href="#" onClick={() => this.addOrRemoveChartsInList({ user_element: "cpu_usage", title: "CPU Usage", chart: 1 })}>CPU USAGE</a></li>
                    <li><a href="#">TOP PROCESS</a></li>
                    <li><a href="#" onClick={() => this.addOrRemoveChartsInList({ user_element: "ram_usage", title: "RAM Usage", chart: 1 })}>RAM USAGE</a></li>
                    <li><a href="#" onClick={() => this.addOrRemoveChartsInList({ user_element: "swap_usage", title: "Swap Usage", chart: 1 })}>SWAP USAGE</a></li>
                    <li><a href="#" onClick={() => this.addOrRemoveChartsInList({ user_element: "memory_usage", title: "Disk Usage", chart: 1 })}>DISK USAGE</a></li>
                    <li><a href="#" onClick={() => this.addOrRemoveChartsInList({ user_element: "disk_io_stats", title: "Disk IO Stats", chart: 1 })}>DISK IO USAGE</a></li>
                    <li><a href="#" onClick={() => this.addOrRemoveChartsInList({ user_element: "network-stats", title: "Network Stats", chart: 1 })}>NETWORK STATS</a></li>
                    <li><a href="#" onClick={() => this.addOrRemoveChartsInList({ user_element: "open-port", title: "Open Port", chart: 3 })}>OPEN PORT</a></li>
                </div> */}

        {/* {Array.isArray(this.state.graphArray) && this.state.graphArray.map((data, index) => {
                    return 
                })} */}

        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-header"></div>
              <div className="largeChartInnerDiv">
                <ChartOperationsApachhe
                  title={this.state.title}
                  tooltip={true}
                  toolbox={true}
                  uuid={this.state.uuid}
                  // user_element={data.user_element}
                  SeriesType={graphsKeys}
                  start_datetime={this.state.start_datetimeForChart}
                  end_datetime={this.state.end_datetimeForChart}
                  mytimezone={this.state.mytimezone}
                  isLegend={true}
                  dataZoom={true}
                  InnerDataType={this.state.type}
                />
              </div>
            </div>
          </div>
        </div>
        <ChartOperationsApachheOuter
          title={this.state.title}
          tooltip={true}
          toolbox={true}
          uuid={this.state.uuid}
          // user_element={data.user_element}
          SeriesType={graphsKeys}
          start_datetime={this.state.start_datetimeForChart}
          end_datetime={this.state.end_datetimeForChart}
          mytimezone={this.state.mytimezone}
          isLegend={true}
          dataZoom={true}
        />
      </div>
    );
  }
}

export default MonitorServerIndividualApachheChart;
