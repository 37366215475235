import React from "react";
import Header from "../../_components/CommonComponents/Header/Header";
import {
  UsageChart,
  BackupChartOperation,
  ChartMySqlOperation,
  ChartOperationsApachhe,
  LoggedChartOperation,
} from "../../_components/MonitorServerChart/index";
import OpenPortChartOperation from "../../_components/OpenPortChartOperation/OpenPortChartOperation";
import {
  DateTime,
  Loader,
  ServerCurrentLoggedIn,
} from "../../_components/CommonComponents/index";
import { Button } from "reactstrap";
import { IoIosArrowRoundBack } from "react-icons/io";
import { MdRefresh, MdRemoveRedEye } from "react-icons/md";
import moment from "moment";
import { graphsKeys, VERSION_NUMBER } from "./Constants";
import * as echarts from "echarts";
import {
  graphService,
  monitorServersSettingServices,
} from "../../_services/servicesWithSecondOrigin/index";
import { Fragment } from "react";
import {
  DiskIOAndWaitProcress,
  TopMemoryProcress,
  MySqlCurrentQuery,
  ApacheLogTable,
  NetworkIOAndWaitProcess,
} from "../../_components/DiskIOWaitActualTabs/DiskIOWaitActualTabs";
import MoniterUrlDetailsChart from "../../_components/MonitorUrlsListing/MoniterUrlDetailsChart";
import OpenPortChartOperationPublic from "../../_components/OpenPortChartOperation/OpenPortChartOperationPublic";
import PerformanceChartsOperation from "../../_components/MonitorServerChart/PerformanceChartsOperation";
import DiskPartitionOperation from "../../_components/MonitorServerChart/DiskPartitionDetailsOperation";
import ChartOperationsApachheCommonLegend from "../../_components/MonitorServerChart/ChartOperationsApachheCommonLegend";
import { getQueryObject, objectToQuery } from "../../_helpers/Common/Common";

const apacheTab = {
  id: "apache_log_data",
  user_element: "apache_log_data",
  title: "Apache Traffic",
  chart: 5,
  type: ["InnerData", "InnerData2"],
  activeTab: 2,
  dateTimeName: "apache_log_date",
  dynamic: true,
};
const nginxTab = {
  id: "nginx_log_data",
  user_element: "nginx_log_data",
  title: "Nginx Traffic",
  chart: 5,
  type: ["InnerData", "InnerData2"],
  activeTab: 5,
  dateTimeName: "apache_log_date",
  dynamic: true,
};

class MonitorSettingIndividualChart extends React.Component {
  constructor(props) {
    super(props);

    const query_object = getQueryObject(props.location.search);
    if (query_object)
      this.props.history.replace(
        this.props.location.pathname + props.location.search,
        query_object
      );

    this.state = {
      id: "",
      uuid: "",
      title: "",
      chart: "",
      user_element: "",
      server_name: "",
      mytimezone: "",
      start_datetime: moment(new Date())
        .add(-4, "hours")
        .format("YYYY-MM-DD HH:mm:ss"),
      end_datetime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      start_datetimeForChart: moment(new Date())
        .add(-4, "hours")
        .format("YYYY-MM-DD HH:mm:ss"),
      end_datetimeForChart: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      type: "",
      unique_id: null,
      echartRefObj: {},
      activeTab: 1,
      performanceChartData: "",
      graphArray: [], // dynamic add or remove chart
      graphShowObj: {
        // dynamic show chart condition
        cpu_usage: false,
        top_process: false,
        disk_io_process: false,
        ram_usage: false,
        swap_usage: false,
        memory_usage: false,
        disk_io_stats: false,
        "network-stats": false,
        "open-port": false,
        "public-open-port": false,
        apache_log_data: false,
        nginx_log_data: false,
        mysql_metadata1_mysql: false,
        mysql_current_query: false,
        "mysql_metadata1_mysql-read-write-count": false,
        "mysql_metadata1_mysql-statement": false,
        "mysql_metadata1_mysql-read-write-data": false,
        "mysql_metadata1_mysql-latency": false,
      },
      loadingData: false,
      graphList: [
        // list of tab wih chart obj
        {
          id: "cpu_usage",
          user_element: "cpu_usage",
          title: "CPU Usage",
          chart: 1,
          dateTimeName: "md_cpu_usage_date",
          apiCall: true,
          activeTab: 1,
        },
        { id: "top_process", table: true, title: "TOP PROCESS", activeTab: 1 },

        {
          id: "ram_usage",
          user_element: "ram_usage",
          title: "RAM Usage",
          chart: 1,
          apiCall: true,
          activeTab: 1,
        },
        {
          id: "swap_usage",
          user_element: "swap_usage",
          title: "Swap Usage",
          chart: 1,
          apiCall: true,
          activeTab: 1,
        },
        {
          id: "memory_usage",
          user_element: "memory_usage",
          title: "Disk Usage",
          chart: 1,
          apiCall: true,
          activeTab: 1,
        },
        {
          id: "memory_usage_details",
          user_element: "memory_usage_details",
          title: "Disk Usage Details",
          chart: 10,
          apiCall: true,
          activeTab: 1,
        },
        {
          id: "disk_io_stats",
          user_element: "disk_io_stats",
          title: "Disk IO Stats",
          chart: 1,
          apiCall: true,
          dateTimeName: "md_disk_io_process_date",
          activeTab: 1,
        },
        {
          id: "disk_io_process",
          table: true,
          title: "Disk IO PROCESS",
          activeTab: 1,
        },
        {
          id: "network-stats",
          user_element: "network-stats",
          title: "Network Stats",
          chart: 1,
          apiCall: true,
          dateTimeName: "network_io_process_date",
          activeTab: 1,
        },
        {
          id: "network_io_process",
          table: true,
          title: "Network Process",
          activeTab: 1,
        },
        {
          id: "open-port",
          user_element: "open-port",
          title: "Ports used by processes",
          chart: 3,
          apiCall: true,
          activeTab: 1,
        },
        {
          id: "public-open-port",
          user_element: "public-open-port",
          title: "Ports open to world",
          chart: 7,
          apiCall: true,
          activeTab: 1,
        },
        { ...apacheTab },
        { ...nginxTab },
        {
          id: "mysql_metadata1_mysql",
          user_element: "mysql_metadata1",
          type: "mysql",
          title: "MySql Connections / Threads",
          dateTimeName: "md_mysql_current_query",
          chart: 4,
          apiCall: true,
          activeTab: 3,
        },
        {
          id: "mysql_current_query",
          table: true,
          title: "Mysql Current Query",
          activeTab: 3,
        },
        {
          id: "failed_login_activity",
          user_element: "failed_login_activity",
          title: "Failed Login Activity",
          chart: 1,
          apiCall: true,
          activeTab: 1,
        },
        {
          id: "currently_logged_in",
          user_element: "currently_logged_in",
          title: "Logged in Users",
          chart: 8,
          apiCall: true,
          activeTab: 1,
        },
        {
          id: "mysql_metadata1_mysql-read-write-count",
          user_element: "mysql_metadata1",
          type: "mysql-read-write-count",
          title: "MySql Read Write Count",
          chart: 4,
          apiCall: true,
          activeTab: 3,
        },
        {
          id: "mysql_metadata1_mysql-statement",
          user_element: "mysql_metadata1",
          type: "mysql-statement",
          title: "MySql Statement",
          chart: 4,
          apiCall: true,
          activeTab: 3,
        },
        {
          id: "mysql_metadata1_mysql-read-write-data",
          user_element: "mysql_metadata1",
          type: "mysql-read-write-data",
          title: "MySql Read Write Data",
          chart: 4,
          apiCall: true,
          activeTab: 3,
        },
        {
          id: "mysql_metadata1_mysql-latency",
          user_element: "mysql_metadata1",
          type: "mysql-latency",
          title: "MySql Latency",
          chart: 4,
          apiCall: true,
          activeTab: 3,
        },
      ],

      apachheReq: false,
      apachheFirstReq: true,

      // date
      apache_log_date: null,
      md_cpu_usage_date: null,
      md_disk_io_process_date: null,
      network_io_process_date: null,
      md_mysql_current_query: null,
      prevTabs: "",
      onRefresh: "",
      Appversion: "",
      response_code_with_request_name: "",
      tableData: "",

      response_code_with_request_name_ngnix: "",
      tableData_ngnix: "",
    };
  }

  refreshData = () => {
    this.setState({ onRefresh: Math.random() });
    this.state.performanceChartData && this.getPerformanceChartsData();
  };

  setActiveTab = (tab) => {
    this.setState({ activeTab: tab }, () => {
      if (this.state.activeTab === 2 || this.state.activeTab === 5) {
        this.getGraphDataFromServer();
      }

      this.state.activeTab === 6 && this.getPerformanceChartsData();
    });
  };

  initalFunction = () => {
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.id
    ) {
      let graphShowObj = this.state.graphShowObj;

      let graphArray = [];
      let apachheReq = false;
      if (this.props.location.state.user_element) {
        graphArray = this.state.graphList.filter(
          (d) => d.user_element === this.props.location.state.user_element
        );

        if (Array.isArray(graphArray) && graphArray.length > 1) {
          graphArray = graphArray.filter(
            (d) => d.type === this.props.location.state.type
          );
        }

        if (graphArray.length === 1) {
          graphShowObj[graphArray[0].id] = true;
        }

        if (this.props.location.state.user_element === "apache_log_data") {
          apachheReq = true;
        }
      }

      this.setState(
        {
          id: this.props.location.state.id,
          uuid: this.props.location.state.uuid,
          title: this.props.location.state.title,
          user_element: this.props.location.state.user_element,
          chart: this.props.location.state.chart,
          server_name: this.props.location.state.server_name,
          mytimezone: this.props.location.state.mytimezone,
          type: this.props.location.state.type,
          graphArray,
          graphShowObj: graphShowObj,
          apachheReq: apachheReq,
          unique_id: this.props.location.unique_id,
          prevTabs: this.props.location.state?.activeTab
            ? this.props.location.state.activeTab
            : "",
          Appversion: this.props.location.state.App_version,
        },
        () => {
          //  this.getGraphDataFromServer();
          this.onChnageGetDomainRelatedCharts();
          if (
            this.props.location.state.cKey === "Nginx" ||
            this.props.location.state.cKey === "Apache"
          ) {
            this.getGraphDataFromServer();
          }

          if (this.props.location.state.cKey) {
            const { cKey, iKey, user_element, title } =
              this.props.location.state;
            const activeTab =
              cKey === "Apache"
                ? 2
                : cKey === "Nginx"
                ? 5
                : cKey === "MySQL"
                ? 3
                : 6;
            this.getPerformanceChartsData(null, () => {
              if (
                !this.state.graphArray.find(
                  (g) => g.user_element === user_element
                )
              )
                this.addOrRemoveChartsInList({
                  cKey: cKey,
                  iKey: iKey,
                  id: user_element,
                  user_element: user_element,
                  title: title,
                  chart: 9,
                  apiCall: true,
                  isRefesh: Math.random(),
                  activeTab,
                });
            });
            this.setState({
              activeTab,
            });
          }

          if (this.state.user_element === "memory_usage") {
            this.addOrRemoveChartsInList(
              {
                id: "memory_usage_details",
                user_element: "memory_usage_details",
                title: "Disk Usage Details",
                chart: 10,
                apiCall: true,
                activeTab: 1,
              },
              "partition"
            );
          }
        }
      );
    } else {
      // this.props.history.push("/monitor-servers");
    }

    this.timestamp = setInterval(() => {
      // if (localStorage.getItem("topProcess") !== this.state.topProcessDataDate)
      //     this.setState({
      //         topProcessDataDate: localStorage.getItem("topProcess")
      //     })

      if (
        localStorage.getItem("apache_log_date") !== this.state.apache_log_date
      )
        this.setState({
          apache_log_date: localStorage.getItem("apache_log_date"),
        });

      if (
        localStorage.getItem("md_cpu_usage_date") !==
        this.state.md_cpu_usage_date
      )
        this.setState({
          md_cpu_usage_date: localStorage.getItem("md_cpu_usage_date"),
        });

      if (
        localStorage.getItem("md_disk_io_process_date") !==
        this.state.md_disk_io_process_date
      )
        this.setState({
          md_disk_io_process_date: localStorage.getItem(
            "md_disk_io_process_date"
          ),
        });
      if (
        localStorage.getItem("network_io_process_date") !==
        this.state.network_io_process_date
      )
        this.setState({
          network_io_process_date: localStorage.getItem(
            "network_io_process_date"
          ),
        });
      if (
        localStorage.getItem("md_mysql_current_query") !==
        this.state.md_mysql_current_query
      )
        this.setState({
          md_mysql_current_query: localStorage.getItem(
            "md_mysql_current_query"
          ),
        });
    }, 1);

    // setInterval(() => {
    //     this.setState({
    //         "end_datetime": moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
    //     })
    // }, 2000)
  };

  componentDidMount() {
    this.initalFunction();
  }

  componentDidUpdate(prev) {
    if (
      this.props.location?.state &&
      this.props.location?.state?.id !== prev?.location?.state?.id
    ) {
      this.initalFunction();
    }
  }

  componentWillUnmount() {
    localStorage.setItem("apache_log_date", "");
    localStorage.setItem("md_cpu_usage_date", "");
    localStorage.setItem("md_disk_io_process_date", "");
    localStorage.setItem("network_io_process_date", "");
    localStorage.setItem("md_mysql_current_query", "");
  }

  apacheTabs = [];
  nginxTabs = [];

  /*****************  Api Call Method ***********/
  getGraphDataFromServer = async () => {
    var d = new Date();
    var n = d.getTimezoneOffset();

    let graphList = [...this.state.graphList]; // for listing
    let graphArray = [...this.state.graphArray]; // graph obj array
    let graphShowObj = { ...this.state.graphShowObj }; // show obj

    const apacheFlag =
      this.state.activeTab === 2 ||
      this.props.location.state?.cKey === "Apache" ||
      !!graphArray.find((o) => o.user_element === apacheTab.user_element);
    const nginxFlag =
      this.state.activeTab === 5 ||
      this.props.location.state?.cKey === "Nginx" ||
      !!graphArray.find((o) => o.user_element === nginxTab.user_element);

    if (graphArray.find((o) => o.user_element === apacheTab.user_element)) {
      const obj = {};
      const objRef =
        graphList[
          graphList.findIndex((o) => o.user_element === apacheTab.user_element)
        ];
      objRef.chartData = obj;
      objRef.dynamic = true;
      objRef.timestamp = [];
    }
    if (graphArray.find((o) => o.user_element === nginxTab.user_element)) {
      const obj = {};
      const objRef =
        graphList[
          graphList.findIndex((o) => o.user_element === nginxTab.user_element)
        ];
      objRef.chartData = obj;
      objRef.dynamic = true;
      objRef.timestamp = [];
    }

    const updateCharts = async (activeTab) => {
      this.setState({ loadingData: true });

      const data = {
        uuid: this.state.uuid,
        user_element: activeTab === 5 ? "nginx_log_data" : "apache_log_data",
        start_datetime: this.state.start_datetime,
        end_datetime: this.state.end_datetime,
        timezone: this.state.mytimezone,
        timediff: n,
        //"unique_id": "00000000-0000-0000-0000-0641a6ca2d4c"
      };

      // let response = await (activeTab === 5
      //   ? graphService.getNgnixGraphData
      //   : this.state.Appversion >= VERSION_NUMBER
      //   ? graphService.getApachheGraphData2
      //   : graphService.getApachheGraphData)(data);

      let response = await (this.state.Appversion >= VERSION_NUMBER
        ? graphService.getApachheGraphData2
        : activeTab === 5
        ? graphService.getNgnixGraphData
        : graphService.getApachheGraphData)(data);

      if (response?.data?.response_code_with_request_name) {
        // {
        //   id: "apache_log",
        //   table: true,
        //   title: "APACHE TRAFFIC DETAILS",
        //   activeTab: 2,
        // },

        if (activeTab === 5) {
          this.setState({
            response_code_with_request_name_ngnix:
              response.data.response_code_with_request_name,
            tableData_ngnix: response.data?.tableData || "",
          });
        } else {
          this.setState({
            response_code_with_request_name:
              response.data.response_code_with_request_name,
            tableData: response.data?.tableData || "",
          });
        }
      }

      ///////////////////////// start 1//////////////////////////

      if (response && response.data && response.data.OuterData) {
        let timestamp = [];

        // set timestamp
        if (response.data && response.data.timestamp)
          timestamp = response.data.timestamp;

        // get all key
        let key = Object.keys(response.data.OuterData);

        // key based data
        let data = response.data.OuterData;

        if (activeTab === 2) {
          const obj = {};
          apacheTab.type.forEach((key) =>
            Object.assign(obj, response.data[key])
          );
          let index = graphList.findIndex(
            (o) => o.user_element === apacheTab.user_element
          );
          const objRef = graphList[index];

          if (
            this.state.Appversion >= VERSION_NUMBER &&
            !graphList.find((obj) => obj.id === "apache_log")
          ) {
            graphList.splice(index + 1, 0, {
              id: "apache_log",
              table: true,
              title: "APACHE TRAFFIC DETAILS",
              activeTab: 2,
            });
            this.setState({ graphList: graphList });
          }

          objRef.chartData = obj;
          objRef.dynamic = true;
          objRef.timestamp = response.data.timestamp;
          if (graphArray.find((d) => d.user_element === apacheTab.user_element))
            graphArray.splice(
              graphArray.findIndex(
                (d) => d.user_element === apacheTab.user_element
              ),
              1,
              { ...objRef }
            );
        }
        if (activeTab === 5) {
          const obj = {};
          nginxTab.type.forEach((key) =>
            Object.assign(obj, response.data[key])
          );

          let index = graphList.findIndex(
            (o) => o.user_element === nginxTab.user_element
          );

          const objRef = graphList[index];

          if (
            this.state.Appversion >= VERSION_NUMBER &&
            !graphList.find((obj) => obj.id === "apache_log_nginx")
          ) {
            graphList.splice(index + 1, 0, {
              id: "apache_log_nginx",
              table: true,
              title: "NGNIX TRAFFIC DETAILS",
              activeTab: 5,
            });
            this.setState({ graphList: graphList });
          }

          objRef.chartData = obj;
          objRef.dynamic = true;
          objRef.timestamp = response.data.timestamp;

          if (graphArray.find((d) => d.user_element === nginxTab.user_element))
            graphArray.splice(
              graphArray.findIndex(
                (d) => d.user_element === nginxTab.user_element
              ),
              1,
              { ...objRef }
            );
        }

        if (Array.isArray(key)) {
          for (const element of key) {
            const obj = {
              activeTab: activeTab,
              checkRefresh: true,
              id: element,
              user_element: element,
              title: element,
              chart: 5,
              apiCall: true,
              chartData: data[element],
              timestamp: timestamp,
              dynamic: true,
              isRefesh: Math.random(),
            };
            if (
              this.state.Appversion >= VERSION_NUMBER &&
              (+activeTab === 2 || +activeTab === 5)
            ) {
              obj.dateTimeName = "apache_log_date";
            }

            let index = null;
            if (Array.isArray(graphArray)) {
              graphArray.forEach((data, i) => {
                if (data.user_element === element) index = i;
              });
            }

            // remove obj if exist
            if (index != null) graphArray.splice(index, 1);

            let listIndex = null;
            if (Array.isArray(graphList)) {
              graphList.forEach((data, i) => {
                if (data.user_element === element) listIndex = i;
              });
            }

            //  update graph obj if exist otherwise push
            if (listIndex != null) {
              graphList[listIndex] = obj;
            } else {
              graphList.push(obj);
            }

            if (Array.isArray(graphArray) && graphArray.length > 0) {
              // check req if come from apche page
              if (this.state.apachheReq && this.state.apachheFirstReq) {
                graphArray.push(obj);
                graphShowObj[element] = true;
              } else {
                let index = null;
                graphArray.forEach((data, i) => {
                  if (data.user_element === element) index = i;
                });

                // check if chart is true and not exist push
                if (graphShowObj[element] && index == null) {
                  graphArray.push(obj);
                } else {
                  // set false
                  graphShowObj[element] = false;
                }
              }
            }
          }
        }

        let apachheFirstReq = this.state.apachheFirstReq;
        if (this.state.apachheReq) {
          apachheFirstReq = false;
        }

        for (const g of graphArray)
          if (!g.chartData)
            g.chartData =
              graphList.find((u) => u.user_element === g.user_element)
                ?.chartData || {};

        this.setState(
          {
            graphList: graphList,
            graphShowObj: graphShowObj,
            apachheFirstReq: apachheFirstReq,
            graphArray,
            loadingData: false,
          },
          () => {}
        );
      } else {
        this.setState({
          graphArray: graphArray.map((ob) => {
            if (+activeTab === +ob.activeTab) {
              ob.chartData = "";
            }
            return ob;
          }),
          loadingData: false,
        });
      }

      ///////////////////////// end 1//////////////////////////
    };

    if (apacheFlag) updateCharts(2);
    if (nginxFlag) updateCharts(5);
  };

  /*****************  Input Method ***********/
  setStartDateTime = (date) => {
    this.setState(
      {
        start_datetime: moment(date).format("YYYY-MM-DD HH:mm:ss"),
      },
      () => {
        var a = moment(this.state.end_datetime, "YYYY-MM-DD HH:mm:ss");
        var b = moment(this.state.start_datetime, "YYYY-MM-DD HH:mm:ss");
        let c = a.diff(b, "days");
        if (c > 31) {
          this.setState({
            end_datetime: b
              .add(1, "M")
              .add(-1, "D")
              .format("YYYY-MM-DD HH:mm:ss"),
          });
        }
      }
    );
  };

  setEndDateTime = (date) => {
    this.setState(
      {
        end_datetime: moment(date).format("YYYY-MM-DD HH:mm:ss"),
      },
      () => {
        var a = moment(this.state.end_datetime, "YYYY-MM-DD HH:mm:ss");
        var b = moment(this.state.start_datetime, "YYYY-MM-DD HH:mm:ss");
        let c = a.diff(b, "days");
        if (c > 31) {
          this.setState({
            start_datetime: a.add(-1, "M").format("YYYY-MM-DD HH:mm:ss"),
          });
        }
      }
    );
  };

  onChnageGetDomainRelatedCharts = async () => {
    let Timezone = localStorage.getItem("Timezone");
    var d = new Date();
    var n = d.getTimezoneOffset();
    let data = {
      uuid: this.state.uuid,
      start_datetime: this.state.start_datetime,
      end_datetime: this.state.end_datetime,
      timezone: Timezone,
      timediff: n,
    };
    let response = await graphService.getDomainDataChart(data);

    if (response && response.data) {
      let graphList = this.state.graphList.slice();
      let domainArray = [];
      response.data.map((obj) => {
        let check = graphList.filter((obj2) => {
          return obj.url === obj2.id;
        });
        if (check.length === 0) {
          let objData = {
            urlId: obj.id,
            id: obj.url,
            user_element: obj.url,
            title: obj.url,
            chart: 6,
            apiCall: true,
            isRefesh: Math.random(),
            activeTab: 4,
          };
          domainArray.push(objData);
        }
      });
      this.setState({
        graphList: graphList.concat(domainArray),
      });
    }
  };

  onSelectServer = (e) => {
    this.refreshChartList();
    this.setState(
      {
        id: e.id,
        uuid: e.value,
        server_name: e.label,
        Appversion: e.Appversion,
        response_code_with_request_name_ngnix: "",
        response_code_with_request_name: "",
        tableData_ngnix: "",
        tableData: "",
      },
      () => {
        if (
          this.state.graphArray.find(
            (o) => o.user_element === apacheTab.user_element
          ) ||
          this.state.graphArray.find(
            (o) => o.user_element === nginxTab.user_element
          ) ||
          this.state.activeTab === 2 ||
          this.state.activeTab === 5
        ) {
          this.getGraphDataFromServer();
        }
        this.onChnageGetDomainRelatedCharts();
        this.state.performanceChartData &&
          this.getPerformanceChartsData("update");
      }
    );
  };

  refreshChartList = () => {
    this.setState({
      graphList: this.state.graphList.filter((obj) => {
        return obj.chart !== 6 && !obj.checkRefresh;
      }),
      graphArray: this.state.graphArray.filter((obj) => {
        return obj.chart !== 6 && !obj.checkRefresh;
      }),
    });
  };

  onSelectUniqueId = (e) => {
    this.setState({
      unique_id: e.value,
    });
  };

  /*****************  Normal Method ***********/

  onClickShowGraphData = () => {
    this.setState(
      {
        start_datetimeForChart: this.state.start_datetime,
        end_datetimeForChart: this.state.end_datetime,
      },
      () => {
        const apacheFlag =
          this.state.activeTab === 2 ||
          !!this.state.graphArray.find(
            (o) => o.user_element === apacheTab.user_element
          );
        const nginxFlag =
          this.state.activeTab === 5 ||
          !!this.state.graphArray.find(
            (o) => o.user_element === nginxTab.user_element
          );

        if (apacheFlag || nginxFlag) {
          this.getGraphDataFromServer();
        }
        this.onChnageGetDomainRelatedCharts();
        this.state.performanceChartData && this.getPerformanceChartsData();
      }
    );
  };

  filterPassedStartDate = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);
    const end_datetime = new Date(moment(this.state.end_datetime));
    return currentDate > selectedDate && end_datetime >= selectedDate;
  };

  filterPassedEndDate = (time) => {
    const currentDate = new Date();
    const start_datetime = new Date(moment(this.state.start_datetime));
    const selectedDate = new Date(time);
    return currentDate > selectedDate && start_datetime <= selectedDate;
  };

  resetTime = () => {
    this.setState(
      {
        start_datetimeForChart: moment(new Date())
          .add(-4, "hours")
          .format("YYYY-MM-DD HH:mm:ss"),
        end_datetimeForChart: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
        start_datetime: moment(new Date())
          .add(-4, "hours")
          .format("YYYY-MM-DD HH:mm:ss"),
        end_datetime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      },
      () => {
        const apacheFlag =
          this.state.activeTab === 2 ||
          !!this.state.graphArray.find(
            (o) => o.user_element === apacheTab.user_element
          );
        const nginxFlag =
          this.state.activeTab === 5 ||
          !!this.state.graphArray.find(
            (o) => o.user_element === nginxTab.user_element
          );
        if (apacheFlag || nginxFlag) {
          this.getGraphDataFromServer();
        }
        this.onChnageGetDomainRelatedCharts();
        this.state.performanceChartData && this.getPerformanceChartsData();
      }
    );
  };

  addOrRemoveChartsInList = (object, partition) => {
    let graphArray = this.state.graphArray;
    let indexKey = null;
    let checkExistance = graphArray.filter((data, i) => {
      if (data.id === object.id) {
        indexKey = i;
      }
      return data.id === object.id;
    });

    if (checkExistance.length === 0) {
      graphArray.push(object);
      this.setState({ graphArray: graphArray });

      if (object.id === "memory_usage") {
        this.addOrRemoveChartsInList(
          {
            id: "memory_usage_details",
            user_element: "memory_usage_details",
            title: "Disk Usage Details",
            chart: 10,
            apiCall: true,
            activeTab: 1,
          },
          "partition"
        );
      }
    } else {
      if (partition === "partition") {
        return false;
      }
      let index = null;
      // graphArray.forEach((data, i) => {
      //     if (data.id == object.id) index = i;
      // });

      index = indexKey;

      if (object.id === "top_process") {
        localStorage.setItem("md_cpu_usage_date", "");
      }

      if (object.id === "mysql_current_query") {
        localStorage.setItem("md_mysql_current_query", "");
      }

      if (object.id === "disk_io_process") {
        localStorage.setItem("md_disk_io_process_date", "");
      }

      if (object.id === "network_io_process") {
        localStorage.setItem("network_io_process_date", "");
      }

      if (index != null) graphArray.splice(index, 1);
      this.setState({ graphArray: graphArray });
      // this.setState({ graphArray: graphArray.filter((data) => { return data.user_element !== object.user_element }) })
    }
    // })

    let graphShowObj = this.state.graphShowObj;

    if (object && object.id) graphShowObj[object.id] = !graphShowObj[object.id];

    this.setState({ graphShowObj });
  };

  apiCallUpdate = (index) => {};

  /*****************  Chart Method ***********/

  getRestoreChart = () => {
    let echartRefObj = this.state.echartRefObj;
    for (const key in echartRefObj) {
      if (Object.hasOwnProperty.call(echartRefObj, key)) {
        const myref = echartRefObj[key];
        if (myref && myref.current && myref.current.getEchartsInstance())
          myref.current
            .getEchartsInstance()
            .setOption(myref.current.props.option, true);
      }
    }
  };

  setEchartRef = (ref, id) => {
    let echartRefObj = this.state.echartRefObj;
    if (id) echartRefObj[id] = ref;
    this.setState({ echartRefObj }, () => {
      this.connectCharts(echartRefObj);
    });
  };

  connectCharts = (echartRefObj) => {
    let data = [];
    for (const key in echartRefObj) {
      if (Object.hasOwnProperty.call(echartRefObj, key)) {
        const myref = echartRefObj[key];
        if (myref && myref.current && myref.current.getEchartsInstance())
          data.push(myref.current.getEchartsInstance());
      }
    }

    if (data.length > 1) echarts.connect(data);
  };

  isAcitiveSubLink = (title) => {
    let className = "";
    this.state.graphArray.map((key) => {
      if (title === key.title) {
        className = "active-links";
      }
    });
    return className;
  };

  getPerformanceChartsData = async (update, callback) => {
    this.setState({ performanceChartData: "" });
    let d = new Date();
    var n = d.getTimezoneOffset();
    const response =
      await monitorServersSettingServices.getPerformanceChartsData({
        uuid: this.state.uuid,
        monitor_server_id: this.state.id,
        start_datetime: this.state.start_datetimeForChart,
        end_datetime: this.state.end_datetimeForChart,
        timezone: this.state.mytimezone,
        timediff: n,
      });

    if (!response) return;

    let { data } = response;

    if (data) {
      let graphShowObj = this.state.graphShowObj;
      const alternateChartTitles = {
        MemoryCurrent: "Memory Used",
        CPUUsageNSec: "CPU Used",
        TasksCurrent: "Current Tasks",
      };

      let graphList = JSON.parse(JSON.stringify(this.state.graphList)).filter(
        (obj) => !obj.cKey
      );
      let graphArray = this.state.graphArray.slice().filter((obj) => !obj.iKey);
      //  graphList=graphList
      let AdditionalService = [];
      data &&
        Object.keys(data).map((key) => {
          Object.keys(data[key]).map((key2) => {
            let id = key + "-" + key2;
            let title = key + "-" + alternateChartTitles[key2];
            let check = graphList.filter((obj2) => {
              return id === obj2.id;
            });
            if (check.length === 0 && key2 !== "timestamp") {
              let objData = {
                cKey: key,
                iKey: key2,
                id: id,
                user_element: id,
                title: title,
                chart: 9,
                apiCall: true,
                isRefesh: Math.random(),
                activeTab:
                  key === "Apache"
                    ? 2
                    : key === "Nginx"
                    ? 5
                    : key === "MySQL"
                    ? 3
                    : 6,
              };
              AdditionalService.push(objData);
              graphShowObj = { ...graphShowObj, [id]: false };
            }
          });
        });

      let graphArr = {
        performanceChartData: data,
        graphList: graphList.concat(AdditionalService),
      };

      if (update) {
        graphArr = { ...graphArr, graphArray, graphShowObj };
      }

      this.setState(graphArr, () => {
        callback && callback();
      });
    }
  };

  onSetAppVersion = (version) => {
    this.setState({
      Appversion: version,
    });
  };

  render() {
    let object = {
      id: this.state.id,
      uuid: this.state.uuid,
      update: true,
      server_name: this.state.server_name,
      activeTab: this.state.prevTabs,
    };
    return (
      <div className="container">
        <Header
          history={this.props.history}
          {...this.props}
          callRefresh={this.refreshData}></Header>

        {/* Filter section */}
        <div className="serverDetailsSec justify-content-between chartDateTimeFilter">
          <div className="d-flex">
            <Button
              className="backBtn mr-4"
              onClick={() =>
                this.props.history.push(
                  "/monitor-server/charts" + objectToQuery(object),
                  object
                )
              }>
              <IoIosArrowRoundBack></IoIosArrowRoundBack>
            </Button>
            <ServerCurrentLoggedIn
              server_name={this.state.server_name}
              uuid={this.state.uuid}
              onSelectServer={this.onSelectServer}
              mytimezone={this.state.mytimezone}
              unique_id={this.state.unique_id}
              onSelectUniqueId={this.onSelectUniqueId}
              onSetAppVersion={this.onSetAppVersion}
            />
          </div>
          <div className="select_startdatetime">
            <div className="row align-items-end">
              <div className="col-md-4">
                <label>Start Date Time</label>
                <DateTime
                  className="form-control"
                  dateTime={this.state.start_datetime}
                  setDateTime={this.setStartDateTime}
                  filterPassedDate={this.filterPassedStartDate}></DateTime>
              </div>
              <div className="col-md-4">
                <label>End Date Time</label>
                <DateTime
                  className="form-control"
                  dateTime={this.state.end_datetime}
                  setDateTime={this.setEndDateTime}
                  filterPassedDate={this.filterPassedEndDate}></DateTime>
              </div>
              <div className="col-md-3 d-flex" style={{ paddingBottom: "1px" }}>
                <Button
                  className="iconBtn"
                  onClick={() => this.onClickShowGraphData()}>
                  <MdRemoveRedEye></MdRemoveRedEye>
                </Button>
                <Button
                  className="iconBtn ml-3"
                  onClick={() => this.resetTime()}>
                  <MdRefresh></MdRefresh>
                </Button>
              </div>
            </div>
          </div>
        </div>

        {/* End */}

        <div className="charts-screen-tabs">
          <Button
            onClick={() => this.setActiveTab(1)}
            color={this.state.activeTab === 1 ? "primary" : ""}>
            General
          </Button>
          <Button
            onClick={() => this.setActiveTab(2)}
            color={this.state.activeTab === 2 ? "primary" : ""}>
            Apache
          </Button>
          <Button
            onClick={() => this.setActiveTab(5)}
            color={this.state.activeTab === 5 ? "primary" : ""}>
            Nginx
          </Button>
          <Button
            onClick={() => this.setActiveTab(3)}
            color={this.state.activeTab === 3 ? "primary" : ""}>
            MySQL
          </Button>
          <Button
            onClick={() => this.setActiveTab(4)}
            color={this.state.activeTab === 4 ? "primary" : ""}>
            Domain
          </Button>

          <Button
            onClick={() => this.setActiveTab(6)}
            color={this.state.activeTab === 6 ? "primary" : ""}>
            Additional Services
          </Button>
        </div>
        <div className="charts-list">
          <ul className="listed">
            {this.state.graphList.map((d, i) => {
              return d.activeTab !== this.state.activeTab ||
                this.state.activeTab === 2 ||
                this.state.activeTab === 5 ? (
                d.activeTab ? (
                  (this.state.activeTab === 2 || this.state.activeTab === 5) &&
                  d.activeTab === this.state.activeTab && (
                    <li
                      onClick={() => this.addOrRemoveChartsInList(d)}
                      key={i}
                      className={this.isAcitiveSubLink(d.title)}>
                      <a href="#">{d.title}</a>
                    </li>
                  )
                ) : (
                  (this.state.activeTab === 2 ||
                    this.state.activeTab === 5) && (
                    <li
                      key={i}
                      className={this.isAcitiveSubLink(d.title)}
                      onClick={() => this.addOrRemoveChartsInList(d)}>
                      <a href="#">{d.title}</a>
                    </li>
                  )
                )
              ) : (
                <li
                  key={i}
                  className={this.isAcitiveSubLink(d.title)}
                  onClick={() => {
                    this.addOrRemoveChartsInList(d);
                  }}>
                  <a href="#">{d.title}</a>
                </li>
              );
            })}
          </ul>
        </div>

        {Array.isArray(this.state.graphArray) &&
          this.state.graphArray.map((data, index) => {
            return (
              this.state.graphShowObj &&
              this.state.graphShowObj.hasOwnProperty(data.id) &&
              this.state.graphShowObj[data.id] && (
                <div key={data.id} className={"row"}>
                  <div className="col-md-12">
                    <div className="card overflow-visible sqlTable">
                      <a
                        href="#"
                        className="cross-charts"
                        onClick={() => this.addOrRemoveChartsInList(data)}>
                        X
                      </a>
                      <div className="card-header"></div>
                      <div className="largeChartInnerDiv">
                        {data.table && (
                          <Fragment>
                            {data.id === "top_process" && (
                              <TopMemoryProcress
                                uuid={this.state.uuid}
                                start_datetime={
                                  this.state.start_datetimeForChart
                                }
                                end_datetime={this.state.end_datetimeForChart}
                                mytimezone={this.state.mytimezone}
                                dateTime={this.state.md_cpu_usage_date}
                                unique_id={this.state.unique_id}
                              />
                            )}

                            {data.id === "apache_log" && (
                              <ApacheLogTable
                                dateTime={this.state.apache_log_date}
                                response_code_with_request_name={
                                  this.state.response_code_with_request_name
                                }
                                tableData={this.state.tableData}
                                title="Apache Traffic Details"
                              />
                            )}

                            {data.id === "apache_log_nginx" && (
                              <ApacheLogTable
                                dateTime={this.state.apache_log_date}
                                response_code_with_request_name={
                                  this.state
                                    .response_code_with_request_name_ngnix
                                }
                                tableData={this.state.tableData_ngnix}
                                title="Ngnix Traffic Details"
                              />
                            )}

                            {data.id === "disk_io_process" && (
                              <DiskIOAndWaitProcress
                                uuid={this.state.uuid}
                                start_datetime={
                                  this.state.start_datetimeForChart
                                }
                                end_datetime={this.state.end_datetimeForChart}
                                mytimezone={this.state.mytimezone}
                                dateTime={this.state.md_disk_io_process_date}
                                unique_id={this.state.unique_id}
                              />
                            )}
                            {data.id === "network_io_process" && (
                              <NetworkIOAndWaitProcess
                                uuid={this.state.uuid}
                                start_datetime={
                                  this.state.start_datetimeForChart
                                }
                                end_datetime={this.state.end_datetimeForChart}
                                mytimezone={this.state.mytimezone}
                                dateTime={this.state.network_io_process_date}
                                unique_id={this.state.unique_id}
                              />
                            )}

                            {data.id === "mysql_current_query" && (
                              <MySqlCurrentQuery
                                uuid={this.state.uuid}
                                start_datetime={
                                  this.state.start_datetimeForChart
                                }
                                end_datetime={this.state.end_datetimeForChart}
                                mytimezone={this.state.mytimezone}
                                dateTime={this.state.md_mysql_current_query}
                                unique_id={this.state.unique_id}
                              />
                            )}
                          </Fragment>
                        )}
                        {data.chart === 1 && (
                          <UsageChart
                            title={data.title}
                            tooltip={true}
                            toolbox={true}
                            restore={true}
                            uuid={this.state.uuid}
                            user_element={data.user_element}
                            SeriesType={graphsKeys}
                            start_datetime={this.state.start_datetimeForChart}
                            end_datetime={this.state.end_datetimeForChart}
                            mytimezone={this.state.mytimezone}
                            isLegend={true}
                            dataZoom={true}
                            apiCallUpdate={() => this.apiCallUpdate(index)}
                            apiCall={data.apiCall}
                            getEchartRef={(ref) =>
                              this.setEchartRef(ref, data.id)
                            }
                            getRestoreChart={this.getRestoreChart}
                            tooltipFormatterValueName={data.dateTimeName}
                            unique_id={this.state.unique_id}
                            onRefresh={this.state.onRefresh}
                          />
                        )}
                        {data.chart === 8 && (
                          <LoggedChartOperation
                            title={"Logged in Users"}
                            tooltip={true}
                            toolbox={true}
                            restore={true}
                            uuid={this.state.uuid}
                            user_element={data.user_element}
                            SeriesType={graphsKeys}
                            start_datetime={this.state.start_datetimeForChart}
                            end_datetime={this.state.end_datetimeForChart}
                            mytimezone={this.state.mytimezone}
                            dataZoom={true}
                            echartsRef={index + 1}
                            getEchartRef={(ref) =>
                              this.setEchartRef(ref, data.id)
                            }
                            getRestoreChart={this.getRestoreChart}
                            unique_id={this.state.unique_id}
                            onRefresh={this.state.onRefresh}
                          />
                        )}

                        {data.chart === 2 && (
                          <BackupChartOperation
                            title={data.title}
                            tooltip={true}
                            toolbox={true}
                            restore={true}
                            uuid={this.state.uuid}
                            user_element={data.user_element}
                            SeriesType={graphsKeys}
                            start_datetime={this.state.start_datetimeForChart}
                            end_datetime={this.state.end_datetimeForChart}
                            mytimezone={this.state.mytimezone}
                            dataZoom={true}
                            echartsRef={index + 1}
                            getEchartRef={(ref) =>
                              this.setEchartRef(ref, data.id)
                            }
                            getRestoreChart={this.getRestoreChart}
                            unique_id={this.state.unique_id}
                            onRefresh={this.state.onRefresh}
                          />
                        )}

                        {data.chart === 3 && (
                          <OpenPortChartOperation
                            title={"Ports used by processes"}
                            tooltip={true}
                            toolbox={true}
                            restore={true}
                            uuid={this.state.uuid}
                            user_element={data.user_element}
                            SeriesType={graphsKeys}
                            start_datetime={this.state.start_datetimeForChart}
                            end_datetime={this.state.end_datetimeForChart}
                            mytimezone={this.state.mytimezone}
                            dataZoom={true}
                            echartsRef={index + 1}
                            getEchartRef={(ref) =>
                              this.setEchartRef(ref, data.id)
                            }
                            getRestoreChart={this.getRestoreChart}
                            unique_id={this.state.unique_id}
                            onRefresh={this.state.onRefresh}
                          />
                        )}

                        {data.chart === 7 && (
                          <OpenPortChartOperationPublic
                            title={"Ports open to world"}
                            tooltip={true}
                            toolbox={true}
                            restore={true}
                            uuid={this.state.uuid}
                            user_element={data.user_element}
                            SeriesType={graphsKeys}
                            start_datetime={this.state.start_datetimeForChart}
                            end_datetime={this.state.end_datetimeForChart}
                            mytimezone={this.state.mytimezone}
                            dataZoom={true}
                            echartsRef={index + 1}
                            getEchartRef={(ref) =>
                              this.setEchartRef(ref, data.id)
                            }
                            getRestoreChart={this.getRestoreChart}
                            unique_id={this.state.unique_id}
                            onRefresh={this.state.onRefresh}
                          />
                        )}

                        {data.chart === 4 && (
                          <ChartMySqlOperation
                            title={data.title}
                            tooltip={true}
                            toolbox={true}
                            restore={true}
                            uuid={this.state.uuid}
                            user_element={data.user_element}
                            SeriesType={graphsKeys}
                            start_datetime={this.state.start_datetimeForChart}
                            end_datetime={this.state.end_datetimeForChart}
                            mytimezone={this.state.mytimezone}
                            isLegend={true}
                            dataZoom={true}
                            type={data.type}
                            getEchartRef={(ref) =>
                              this.setEchartRef(ref, data.id)
                            }
                            getRestoreChart={this.getRestoreChart}
                            tooltipFormatterValueName={data.dateTimeName}
                            unique_id={this.state.unique_id}
                            onRefresh={this.state.onRefresh}
                          />
                        )}

                        {data.chart === 6 && (
                          <MoniterUrlDetailsChart
                            urlId={data.urlId}
                            title={data.title}
                            grid={{
                              top: "25%",
                              left: "3%",
                              right: "4%",
                              bottom: "3%",
                              containLabel: true,
                            }}
                            style={{}}
                            getEchartRef={(ref) =>
                              this.setEchartRef(ref, data.id)
                            }
                            getRestoreChart={this.getRestoreChart}
                            start_datetime={this.state.start_datetimeForChart}
                            end_datetime={this.state.end_datetimeForChart}
                            dataZoom={true}
                            unique_id={this.state.unique_id}
                            onRefresh={
                              this.state.onRefresh
                            }></MoniterUrlDetailsChart>
                        )}

                        {data.chart === 5 ? (
                          data.dynamic === true ? (
                            // dynamic domain send chart data and timestemp
                            <>
                              <div className="chart-loader">
                                <Loader
                                  loader={this.state.loadingData}></Loader>
                              </div>
                              {/* ChartOperationsApachhe */}
                              <ChartOperationsApachheCommonLegend
                                title={
                                  data.title === "unknown"
                                    ? "Default"
                                    : data.title
                                }
                                tooltip={true}
                                toolbox={true}
                                restore={true}
                                uuid={this.state.uuid}
                                isLegend={true}
                                dataZoom={true}
                                chartData={data.chartData}
                                timestemp={data.timestamp}
                                outer={true}
                                isRefesh={data.isRefesh}
                                getEchartRef={(ref) =>
                                  this.setEchartRef(ref, data.id)
                                }
                                getRestoreChart={this.getRestoreChart}
                                tooltipFormatterValueName={data.dateTimeName}
                                unique_id={this.state.unique_id}
                                onRefresh={this.state.onRefresh}
                                isLoading={this.state.loadingData}
                              />
                            </>
                          ) : (
                            <ChartOperationsApachheCommonLegend
                              title={data.title}
                              tooltip={true}
                              toolbox={true}
                              restore={true}
                              uuid={this.state.uuid}
                              user_element={data.user_element}
                              SeriesType={graphsKeys}
                              start_datetime={this.state.start_datetimeForChart}
                              end_datetime={this.state.end_datetimeForChart}
                              mytimezone={this.state.mytimezone}
                              isLegend={true}
                              dataZoom={true}
                              type={data.type}
                              InnerDataType={data.type}
                              getEchartRef={(ref) =>
                                this.setEchartRef(ref, data.id)
                              }
                              getRestoreChart={this.getRestoreChart}
                              // tooltipFormatterValueName={data.dateTimeName}
                              unique_id={this.state.unique_id}
                              onRefresh={this.state.onRefresh}
                            />
                          )
                        ) : (
                          ""
                        )}

                        {data.chart === 9 && (
                          <>
                            {this.state.performanceChartData &&
                              Object.keys(this.state.performanceChartData).map(
                                (key) => {
                                  if (data.cKey === key)
                                    return (
                                      <>
                                        {this.state.performanceChartData && (
                                          <PerformanceChartsOperation
                                            title={data.title}
                                            name={key}
                                            data={
                                              this.state.performanceChartData
                                            }
                                            details
                                            iKey={data.iKey}
                                            getEchartRef={(ref) =>
                                              this.setEchartRef(ref, data.id)
                                            }
                                            getRestoreChart={
                                              this.getRestoreChart
                                            }
                                            onRefresh={this.state.onRefresh}
                                            tooltip={true}
                                            toolbox={true}
                                            restore={true}
                                            isLegend={true}
                                            dataZoom={true}
                                          />
                                        )}
                                      </>
                                    );
                                }
                              )}
                          </>
                        )}

                        {data.chart === 10 && (
                          <DiskPartitionOperation
                            uuid={this.state.uuid}
                            user_element="currently_logged_in"
                            SeriesType={graphsKeys}
                            active={this.state.active}
                            isRefresh={this.state.isRefresh}
                            mytimezone={this.state.mytimezone}
                            unique_id={this.state.unique_id}
                            onRefresh={this.state.onRefresh}
                            title="Partition"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )
            );
          })}
      </div>
    );
  }
}

export default MonitorSettingIndividualChart;
