export const MemoryType = ["ram_usage", "swap_usage", "memory_usage", 'db_backup', 'folder_backup', 'network-stats']

export const allData = {
    timestamp: ['2:23', '2:40', '3:50', '4:20', '5:23', '5:40', '6:6', '6:20'],
    data: [
        {
            Port:6000,
            "Netid": "udp",
            "State": "UNCONN",
            "Recv-Q": "0",
            " Send-Q": "0",
            "Local Address:Port": "0.0.0.0:631",
            "Peer Address:Port": "0.0.0.0:*",
            data: [6000,  6000 , 6000, 6000, 6000,null , 6000, 6000],
        },
        {
            Port:4000,
            "Netid": "udp",
            "State": "UNCONN",
            "Recv-Q": "0",
            " Send-Q": "0",
            "Local Address:Port": "224.0.0.251:5353",
            "Peer Address:Port": "0.0.0.0:*",
            data: [,  4000 , 4000, 4000, 4000, 4000, 4000, 4000],
        },
        {
            Port:3000,
            "Netid": "udp",
            "State": "UNCONN",
            "Recv-Q": "0",
            " Send-Q": "0",
            "Local Address:Port": "224.0.0.251:5353",
            "Peer Address:Port": "0.0.0.0:*",
            data: [3000,  3000 , 3000, 3000, 3000, 3000, 3000, 3000],
        },
        {
            Port:808011,
            "Netid": "tcp",
            "State": "LISTEN",
            "Recv-Q": "0",
            " Send-Q": "224",
            "Local Address:Port": "127.0.0.1:5432",
            "Peer Address:Port": "0.0.0.0:*",
            data: [808011,  808011 , 808011, 808011,null , 808011, 808011, 808011],
        },
        {
            Port:80801,
            "Netid": "tcp",
            "State": "LISTEN",
            "Recv-Q": "0",
            " Send-Q": "244",
            "Local Address:Port": "127.0.0.1:5433",
            "Peer Address:Port": "0.0.0.0:*",
            data: [80801,  null , 80801, 80801, 80801, 80801, 80801, ],
        },
        {
            Port:8080,
            "Netid": "tcp",
            "State": "LISTEN",
            "Recv-Q": "0",
            " Send-Q": "50",
            "Local Address:Port": "0.0.0.0:7005",
            "Peer Address:Port": "0.0.0.0:*",
            data: [8080,  8080 , 8080, 8080, 8080, 8080,null , 8080],
        },
        {
            Port:50000,
            "Netid": "tcp",
            "State": "LISTEN",
            "Recv-Q": "0",
            " Send-Q": "4096",
            "Local Address:Port": "127.0.0.1:6942",
            "Peer Address:Port": "0.0.0.0:*",
            data: [50000,  50000 ,null , 50000, 50000, 50000, 50000, 50000],
        },
        {
            Port:5000,
            "Netid": "tcp",
            "State": "LISTEN",
            "Recv-Q": "0",
            " Send-Q": "128",
            "Local Address:Port": "127.0.0.1:27017",
            "Peer Address:Port": "0.0.0.0:*",
            data: [5000,  5000 , 5000, 5000, 5000,null, 5000, 5000],
        },
        {
            Port:500,
            "Netid": "tcp",
            "State": "LISTEN",
            "Recv-Q": "0",
            " Send-Q": "80",
            "Local Address:Port": "127.0.0.1:3306",
            "Peer Address:Port": "0.0.0.0:*",
            data: [null,  500 , 500, 500, 500, 500, 500, 500],
        },
        {
            Port:10001,
            "Netid": "tcp",
            "State": "LISTEN",
            "Recv-Q": "0",
            " Send-Q": "4096",
            "Local Address:Port": "127.0.0.1:63342",
            "Peer Address:Port": "0.0.0.0:*",
            data: [null, 10001, 10001, 10001, 10001, 10001, 10001, 10001],
        },
        {
            Port:1000,
            "Netid": "tcp",
            "State": "LISTEN",
            "Recv-Q": "0",
            " Send-Q": "511",
            "Local Address:Port": "*:80",
            "Peer Address:Port": "*:*",
            data: [1000, 1000, , 1000, 1000, 1000, 1000, 1000],
        },
        {
            Port:200,
            "Netid": "tcp",
            "State": "LISTEN",
            "Recv-Q": "0",
            " Send-Q": "128",
            "Local Address:Port": "[::]:22",
            "Peer Address:Port": "[::]:*",
            data: ['200', '200', '200', null, '200', '200', , '200'],
        },
        {
            Port:80,
            "Netid": "tcp",
            "State": "LISTEN",
            "Recv-Q": "0",
            " Send-Q": "5",
            "Local Address:Port": "[::1]:631",
            "Peer Address:Port": "[::]:*",
            data: ['80', '80', '80', null, '80', '80', '80', '80'],
        }
    ]
}