import { DateTime } from '../index'
import { Button } from 'reactstrap'
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { MdRefresh } from "react-icons/md";
import moment from 'moment'
const DateTimeFilter = (props) => {

   const filterPassedStartDate = time => {
        const currentDate = new Date();
        const selectedDate = new Date(time);
        const end_datetime= new Date(moment(props.end_datetime));
        return currentDate > selectedDate && end_datetime>= selectedDate ;
    }

   const filterPassedEndDate = time => {
        const currentDate = new Date();
        const start_datetime= new Date(moment(props.start_datetime));
        const selectedDate = new Date(time);
        return currentDate > selectedDate && start_datetime <= selectedDate
    }

    return <div className="row align-items-end">
            <div className="col-md-4">
                <label>Start Date Time</label>
                <DateTime
                    dateTime={props.start_datetime}
                    setDateTime={props.setStartDateTime}
                    className="date-filter-class form-control"
                    filterPassedDate={filterPassedStartDate}
                ></DateTime></div>
            <div className="col-md-4">
                <label>End Date Time</label>
                <DateTime dateTime={props.end_datetime}
                    setDateTime={props.setEndDateTime}
                    className="date-filter-class form-control"
                    filterPassedDate={filterPassedEndDate}
                ></DateTime></div>
            <div className="col-md-3 d-flex" style={{paddingBottom: '1px'}}>  
             <Button className="addEditBtn primary-btn" onClick={() => props.onClickShowGraphData()}>Show</Button>
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>Reset</Tooltip>}
                    >
                    <Button className="iconBtn addIconBtn ml-3" onClick={() => props.onClickGetFreshUserdetails()}><MdRefresh /></Button>  
                </OverlayTrigger> 
            </div>
          </div>
}
export default DateTimeFilter