import React, { useState, useEffect } from 'react';
import { Button, Input } from 'reactstrap';
import { AiOutlineInfoCircle } from "react-icons/ai";
import toastr from 'toastr'
import { monitorServersServices } from '../../../_services/servicesWithSecondOrigin/index'
import { Validation } from '../../../_helpers/validation/Validation'
import { Error, TooltipPro } from '../../../_components/CommonComponents/index'

const SecretKeyPopup = (props) => {
    const [modal, setModal] = useState(false);
    const toggle = () => {
        if (!modal) { getKey() }
        setModal(!modal);
    }
    const [key, setKey] = useState("")
    const [public_key, setPublicKey] = useState("")
    const [private_key, setPrivateKey] = useState("")
    const [error, setError] = useState("")
    const copyText = () => {
        if (key) {
            navigator.clipboard.writeText(key)
            toastr.success("Copied!")
        }
    }

    useEffect(() => {
        if (props.MSId)
            getKey()
    }, [props.MSId])

    const getKey = async () => {
        let response = await monitorServersServices.getPrivateKey(props.MSId)
        if (response && response.data) {
            // setKey(response.data.secret_key)
            setPrivateKey(response.data)
            setPublicKey(response.publickey)
        } else {
            setPrivateKey("")
            setPublicKey("")
        }
    }

    const generate = async () => {
        let response = await monitorServersServices.generateKey()
        if (response && response.data) {
            setKey(response.data)
            toastr.success(response.message)
        } else {
            setKey("")
            toastr.error("Error")
        }
    }

    const onChangeFileUpload = (e) => {
        if (e.target.files && e.target.files[0]) {
            var file = e.target.files[0]
            let reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => {
                var strImage = reader.result.split('base64,')[1];
                setPublicKey(strImage)
            };
        }
    }

    const onSavePublicPrivateKey = async () => {
        let data = {
            "private_key_path": private_key,
            "monitor_server_id": props.MSId
        }
        let flag = 0
        let errobj = Validation(data, rules);
        setError(errobj)
        Object.keys(errobj).map(index => {
            if (errobj[index] !== "") {
                flag = 1;
            }
        });

        if (flag === 0) {
            let response = await monitorServersServices.AddPrivateKey(data)
            if (response && response.message) {
                toastr.success(response.message)
            }
        }
    }

    return (
        <div className="serverSettingData">
            <div className="row">
                <div className="col-md-12 pb-3">
                    <p>You provide a lot of sensitive information to G8keeper, and we ensure that all that information is saved securely. We secure it to the extent that we also do not have access to that information. All the information that is stored on our servers is encrypted using state of the art encryption techniques. We request you to upload a public key, which is stored with us and all the information you provide us is encrypted and stored that way. There is no way for us to decrypte the same. You also provide private key path, so when the server agent (running inside your server) needs to access the encrypted information (to collect performance stats or create backups) it selectively decrypts that information. The decryption happens only within your server and for a limited time. </p>
                    <h6 className='pt-2'>Here is how you can generate your private and public key pair</h6>
                    {/* 
                    <div className="row mt-1">
                        <div className="col-md-3">
                            <label>Secret Key</label>
                        </div>
                        <div className="col-md-6">
                            <Input value={key} onClick={() => copyText()}></Input>
                        </div>
                        <div className="col-md-3">
                            <Button color="primary" onClick={generate}>Refresh</Button>{' '}
                        </div>
                    </div> */}

                    <div className="publicPrivateKey">
                        <div className="row">
                            <div className="col-md-12">
                                <label> Public Key</label>
                                <span className="ml-2">
                                    <TooltipPro
                                        className="bottom tsize-400"
                                        tooltip={
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <p>Upload the public key. <a href="https://g8keeperapp.com/how-it-works/private-and-public-key-creation/" target="_blank">Here</a> is how to generate private / public key pair.</p>
                                                </div>
                                            </div>
                                        }
                                    >
                                        <AiOutlineInfoCircle className="infoIcon" />
                                    </TooltipPro>
                                </span>
                            </div>
                            {/* <div className="col-md-1">
                            <IoIosInformationCircleOutline size="35" color="red" />
                        </div> */}
                            <div className="col-md-12">
                                <input disabled type="file" className="form-control" id="uploadFile" onChange={(e) => onChangeFileUpload(e)}></input>
                                {public_key && <p>Public key is Exist</p>}
                                {error && error.public_key && <Error error={error.public_key}></Error>}
                            </div>
                        </div>
                        <div className="row pt-4">
                            <div className="col-md-12">
                                <label> Private Key</label>
                                <span className="ml-2">
                                    <TooltipPro
                                        className="bottom tsize-400"
                                        tooltip={
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <p>Provide absolute path of the private key, which should be stored on your server. <a href="https://g8keeperapp.com/how-it-works/private-and-public-key-creation/" target="_blank">Here</a> is how to generate private / public key pair.</p>
                                                </div>
                                            </div>
                                        }
                                    >
                                        <AiOutlineInfoCircle className="infoIcon" />
                                    </TooltipPro>
                                </span>
                            </div>
                            <div className="col-md-12">
                                <Input type="text" value={private_key} onChange={(e) => setPrivateKey(e.target.value)}></Input>
                                {error && error.private_key && <Error error={error.private_key}></Error>}
                            </div>
                        </div>
                        <div className="text-center mt-4">
                            <Button disabled={props.shared} color="primary" className="bluebutton  btn btn btn-secondary" onClick={() => onSavePublicPrivateKey()}>Update</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SecretKeyPopup;


const rules = [
    {
        field_name: 'private_key',
        label: 'Private Key',
        type: "string",
        isRequired: true
    },
    // {
    //     field_name: 'public_key',
    //     label: 'Public Key',
    //     type: "string",
    //     isRequired: true
    // }
]