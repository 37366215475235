import React, { useCallback, useEffect, useState } from "react";
import { IoIosArrowRoundBack } from "react-icons/io";
import { useLocation } from "react-router-dom";

import "./postman-style.css";

import Header from "../../_components/CommonComponents/Header/Header";
import KeyValuePair from "./common/KeyValuePair";
import { Validation } from "../../_helpers/validation/Validation";
import { ApiMonitorService } from "../../_services/servicesWithSecondOrigin";

const initialState = {
  ///--dynamic --//
  api_name: "",
  api_url: "",
  api_type: "",
  frequency: "",
  expected_http_status_code: "",
  api_header: {},
  api_body: "",
};
function ApiMonitorForm(props) {
  const { history } = props;
  const location = useLocation();
  const [paramValues, setParamValues] = useState([]);
  const [headerValues, setHeaderValues] = useState([]);
  const [headerSelected, setHeaderSelected] = useState(0);
  // const [isLoading, setLoader] = useState(false);
  const [error, setError] = useState({});
  const [bodyRaw, setBodyRaw] = useState("");
  const [keyValueErrParam, setKeyValueErrParam] = useState([]);
  const [keyValueErrHeader, setKeyValueErrHeader] = useState([]);
  const [apiFields, setApiFields] = useState({ ...initialState });

  const onChange = (e) => {
    const { name, value } = e.target;
    setApiFields((prev) => ({ ...prev, [name]: value }));
    if (name === "api_url") {
      const result = getQueryParamsURL(value);

      setParamValues(result);
    }
    if (name === "api_type" && value !== "GET") {
      paramValues.length === 0 &&
        setParamValues([...paramValues, { objKey: "", objValue: "" }]);
      headerValues.length === 0 &&
        setHeaderValues([...headerValues, { objKey: "", objValue: "" }]);
    }
  };

  ///---set active tab ----0,1,2----///
  const handleSelected = (value) => {
    setHeaderSelected(value);
  };

  ////---get the object key-value ---///
  function getObj_KeyValue(data) {
    let result = data?.reduce((accumulator, currentValue) => {
      return currentValue.objValue && currentValue.objKey
        ? { ...accumulator, [currentValue.objKey]: currentValue.objValue }
        : accumulator;
    }, {});
    return result;
  }

  ///--save the data and Api monitor--///
  function onSubmit() {
    let errorFlag = 0;
    // setLoader(true);
    const field = [...rules];

    if (apiFields.api_type !== "GET")
      field.push(
        ruleJSONFormat(bodyRaw === "JSON" || bodyRaw === "" ? "json" : "string")
      );

    const errorObj = Validation(apiFields, field);

    setError(errorObj);

    Object.keys(errorObj).forEach((key) => {
      if (errorObj[key]) {
        errorFlag = 1;
        if (key === "api_body" && apiFields.api_type !== "GET")
          setHeaderSelected(2);
      }
    });

    setKeyValueErrParam(
      paramValues.map((obj, index) => {
        const errorObj = Validation(obj, Key_Value_Rules);

        if (
          paramValues.find(
            (o, idx) =>
              idx < index &&
              o.objKey &&
              obj.objKey &&
              o.objKey.toLowerCase() === obj.objKey.toLowerCase()
          )
        ) {
          errorObj.objKey = "Only Unique Key is allowed";
        }

        for (const key in errorObj)
          if (errorObj[key]) {
            errorFlag = 1;
            setHeaderSelected(0);
          }

        return errorObj;
      })
    );

    setKeyValueErrHeader(
      headerValues.map((obj, index) => {
        const errorObj = Validation(obj, Key_Value_Rules);

        if (
          headerValues.find(
            (o, idx) =>
              idx < index &&
              o.objKey &&
              obj.objKey &&
              o.objKey.toLowerCase() === obj.objKey.toLowerCase()
          )
        ) {
          errorObj.objKey = "Only Unique Key is allowed";
        }

        for (const key in errorObj)
          if (errorObj[key]) {
            errorFlag = 1;
            setHeaderSelected(1);
          }

        return errorObj;
      })
    );

    if (errorFlag !== 0) return;

    const data = {
      ...apiFields,
      api_header: getObj_KeyValue(headerValues),
      // api_url: apiFields?.api_url?.split("?")[0] && apiFields?.api_url ? apiFields?.api_url?.split("?")[0]:apiFields.api_url,
      // api_body:
      //   bodyRaw === "JSON" && apiFields.api_body
      //     ? JSON.parse(apiFields.api_body)
      //     : apiFields.api_body,
      // getObj_KeyValue(paramValues),
    };

    if (apiFields.api_type === "GET") {
      data.api_body = "";
    }

    if (location?.state?.updatedApiData?.id) {
      ApiMonitorService.updateApiMonitoring(
        data,
        location?.state?.updatedApiData?.id
      ).then((res) => {
        if (res?.status === 200) {
          history.push("/monitor-urls-list", "apimonitor");
        }
      });
    } else {
      ApiMonitorService?.createApiForMonitor(data)?.then((res) => {
        if (res?.status === 200) {
          history.push("/monitor-urls-list", "apimonitor");
        }
      });
    }
  }

  const updateFormUrl = useCallback((params) => {
    const queryParams = params
      .filter((obj) => !!obj.objValue)
      .map((obj) => `${obj.objKey}=${obj.objValue}`)
      .join("&");

    setApiFields((prev) => ({
      ...prev,
      api_url:
        prev.api_url.split("?")[0] + (queryParams ? "?" + queryParams : ""),
    }));
  }, []);

  ///--set the key value pair----///
  function handleChange(event, index) {
    const { name, value } = event.target;
    const callback = (obj, idx) => {
      if (idx !== index) return obj;

      const newObj = {
        ...obj,
        [name]: name === "objKey" ? value.trim() : value,
      };

      return newObj;
    };

    if (headerSelected === 0) {
      setParamValues((prev) => {
        const newParams = prev.map(callback);

        updateFormUrl(newParams);

        return newParams;
      });
    } else if (headerSelected === 1) {
      setHeaderValues((prev) => prev.map(callback));
    }
  }

  ///---add the key-value pair----///
  const addHeaderKeyValue = () => {
    if (headerSelected === 0) {
      setParamValues((prev) => [...prev, { objKey: "", objValue: "" }]);
    } else if (headerSelected === 1) {
      setHeaderValues((prev) => [...prev, { objKey: "", objValue: "" }]);
    }
  };

  ///---remove the key-value pair----///
  const removeFormFields = (i) => {
    const callback = (_obj, idx) => idx !== i;

    if (headerSelected === 0) {
      setParamValues((prev) => {
        const params = prev.filter(callback);

        updateFormUrl(params);

        return params;
      });
    } else if (headerSelected === 1) {
      setHeaderValues((prev) => prev.filter(callback));
    }
  };

  ///---get query params from url----////
  const getQueryParamsURL = (url) => {
    const urlSearchParams = new URLSearchParams(url?.split("?")[1]);
    const params = Object.fromEntries(urlSearchParams.entries());

    let queryParamsArray = Object.keys(params)?.map((key) => {
      return { objKey: key, objValue: params[key] };
    });

    return queryParamsArray;
  };

  useEffect(() => {
    if (location?.state?.updatedApiData) {
      const {
        api_name,
        api_url,
        api_type,
        frequency,
        expected_http_status_code,
        api_header,
        api_body,
      } = location?.state?.updatedApiData;
      setApiFields((prev) => ({
        ...prev,
        api_name,
        api_url,
        api_type,
        frequency,
        expected_http_status_code,
        api_body: api_body && JSON.parse(api_body),
      }));
      api_header &&
        Object.entries(JSON.parse(api_header))?.forEach(([key, value]) =>
          setHeaderValues([
            {
              objKey: key,
              objValue: value,
            },
          ])
        );

      let querySign = "?";
      let result = api_url.includes(querySign)
        ? getQueryParamsURL(api_url)
        : "";
      if (result !== "") setParamValues(result);

      // api_body &&
      //   Object.entries(JSON.parse(api_body))?.forEach(([key, value]) =>
      //     setParamValues([
      //       {
      //         objKey: key,
      //         objValue: value,
      //       },
      //     ])
      //   );
    }
  }, [location?.state?.updatedApiData]);

  useEffect(() => {
    if (apiFields.api_type === "GET")
      setHeaderSelected((prev) => (prev === 2 ? 0 : prev));
  }, [apiFields.api_type]);

  let options = [];
  for (let i = 1; i <= 15; i++) {
    options.push({
      label: i,
      value: i * 60,
    });
  }

  return (
    <div>
      <Header history={props.history} {...props}></Header>
      <div className="card mx-0 mx-md-5 mt-4">
        <div className="card-header dash-header">
          <h3>
            <IoIosArrowRoundBack
              className="back-arrow-icon"
              onClick={() => {
                history.push("/monitor-urls-list", "apimonitor");
              }}
            />
            Api monitor
          </h3>
        </div>
        <div className="card-body mx-0 mx-md-5 mt-3">
          <div className="row">
            <div className="col-md-6">
              <label>API Name</label>
              <input
                onChange={onChange}
                value={apiFields.api_name}
                name="api_name"
                className="form-control mb-2"
                type="text"
                placeholder="API Name"
              />
              {error && error.api_name !== "" && (
                <p className="color-red">{error.api_name}</p>
              )}
            </div>
            <div className="col-md-6">
              <label>Frequency (in minutes)</label>
              <select
                onChange={onChange}
                value={apiFields.frequency}
                name="frequency"
                className="form-control mb-2"
                placeholder="Frequency">
                <option disabled value="">
                  Select...
                </option>
                {Array.isArray(options) &&
                  options.map((obj) => {
                    return (
                      <option value={obj.value} key={obj.value}>
                        {obj.label}
                      </option>
                    );
                  })}
              </select>

              {error && error.frequency !== "" && (
                <p className="color-red">{error.frequency}</p>
              )}
            </div>

            <div className="col-md-4">
              <label>Method</label>
              <select
                onChange={onChange}
                value={apiFields.api_type}
                name="api_type"
                className="form-control mb-2"
                data-method>
                <option>Select Method</option>
                <option value="GET">GET</option>
                <option value="POST">POST</option>
                <option value="PUT">PUT</option>
                <option value="PATCH">PATCH</option>
              </select>
              {error && error.api_type !== "" && (
                <p className="color-red">{error.api_type}</p>
              )}
            </div>

            <div className="col-md-4">
              <label>API</label>
              <input
                onChange={onChange}
                value={apiFields.api_url}
                name="api_url"
                data-url
                required
                className="form-control"
                type="url"
                placeholder="Enter request API"
              />
              {error && error.api_url !== "" && (
                <p className="color-red">{error.api_url}</p>
              )}
            </div>
            <div className="col-md-4">
              <label>Expected Http Status Code</label>
              <input
                onChange={onChange}
                value={apiFields.expected_http_status_code}
                name="expected_http_status_code"
                className="form-control"
                type="text"
                placeholder="Enter Status Code"
              />
              {error && error.expected_http_status_code !== "" && (
                <p className="color-red">{error.expected_http_status_code}</p>
              )}
            </div>
          </div>
          <ul className="nav nav-tabs" role="tablist">
            <li className="nav-item" role="presentation">
              <button
                onClick={() => handleSelected(0)}
                className={`nav-link ${headerSelected === 0 ? "active" : ""} `}
                id="query-params-tab"
                data-bs-toggle="tab"
                data-bs-target="#query-params"
                type="button"
                role="tab"
                aria-controls="query-params"
                aria-selected="true">
                Params
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                onClick={() => handleSelected(1)}
                style={{ margin: "0 1px" }}
                className={`nav-link ${headerSelected === 1 ? "active" : ""}`}
                id="request-headers-tab"
                data-bs-toggle="tab"
                data-bs-target="#request-headers"
                type="button"
                role="tab"
                aria-controls="request-headers"
                aria-selected="false">
                Headers
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                onClick={
                  apiFields.api_type === "GET"
                    ? undefined
                    : () => handleSelected(2)
                }
                className={`nav-link${headerSelected === 2 ? " active" : ""}${
                  apiFields.api_type === "GET" ? " disabled-tab" : ""
                }`}
                id="json-tab"
                data-bs-toggle="tab"
                data-bs-target="#json"
                type="button"
                role="tab"
                aria-controls="json">
                Body
              </button>
            </li>
          </ul>
          <div className="tab-content p-3 border-top-0 border">
            <div
              className="tab-pane fade show active"
              id="query-params"
              role="tabpanel"
              aria-labelledby="query-params-tab">
              {headerSelected === 0 ? (
                <KeyValuePair
                  paramsHeader={paramValues}
                  handleChange={handleChange}
                  removeFormFields={removeFormFields}
                  keyValueErr={keyValueErrParam}
                  apiMethod={apiFields?.api_type}
                  headerSelected={headerSelected}
                />
              ) : headerSelected === 1 ? (
                <KeyValuePair
                  paramsHeader={headerValues}
                  handleChange={handleChange}
                  removeFormFields={removeFormFields}
                  keyValueErr={keyValueErrHeader}
                  apiMethod={apiFields?.api_type}
                  headerSelected={headerSelected}
                />
              ) : (
                <div id="json" role="tabpanel" aria-labelledby="json-tab">
                  <select
                    onChange={(e) => setBodyRaw(e.target.value)}
                    className="dropdown-raw">
                    {/* <option value="JSON">JSON</option> */}
                    <option value="JSON">JSON</option>
                    <option value="Text">Text</option>
                  </select>
                  <div data-json-request-body className="">
                    <textarea
                      name="api_body"
                      onChange={onChange}
                      value={apiFields.api_body}
                      className="bodybox border-radius-4 border-color-fff width-100"
                      rows="12"
                      col="30"
                      style={{ resize: "none" }}
                      placeholder="Enter Body data"></textarea>
                  </div>
                  {error && error.api_body && error !== "" && (
                    <p className="color-red">{error.api_body}</p>
                  )}
                </div>
              )}
              {headerSelected !== 2 && (
                <button
                  data-add-query-param-btn
                  className="mt-2 btn btn-outline-success"
                  type="button"
                  onClick={() => addHeaderKeyValue()}>
                  Add
                </button>
              )}
            </div>
          </div>
          <div className="d-flex align-center justify-center mx-2 mt-1">
            <button
              style={{ width: "6rem" }}
              type="submit"
              className="btn btn-primary mt-5"
              onClick={onSubmit}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ApiMonitorForm;

const rules = [
  {
    field_name: "api_name",
    label: "API Name",
    type: "string",
    isRequired: true,
  },
  {
    field_name: "api_url",
    label: "API URL",
    type: "simple-url",
    isRequired: true,
  },
  {
    field_name: "frequency",
    label: "Frequency",
    type: "number",
    isRequired: true,
  },
  {
    field_name: "expected_http_status_code",
    label: "Expected Http Status Code",
    type: "number",
    isRequired: true,
  },
  {
    field_name: "api_type",
    label: "API Type",
    type: "string",
    isRequired: true,
  },
];

function ruleJSONFormat(type) {
  return {
    field_name: "api_body",
    label: "Body data",
    type: type,
    isRequired: true,
  };
}

///--rules for key-value --////
const Key_Value_Rules = [
  {
    field_name: "objKey",
    label: "key",
    type: "string",
    isRequired: true,
  },
  {
    field_name: "objValue",
    label: "value",
    type: "string",
    isRequired: true,
  },
];
