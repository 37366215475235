import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const FbModel = (props) => {
    const [dataArray, setData] = useState([])
    const {
        className
    } = props;

    const onChangeData = (obj) => {
        let array = dataArray.slice()
        if (dataArray.some(objj => objj.id === obj.id)) {
            array=array.filter((objj)=>{return objj.id !== obj.id})
        } else {
            array.push(obj)
        }
        setData(array)
    }
    
   const onSubmit=()=>{
        props.addArrayInMediaArray(dataArray)
        props.toggle();
    }
    return (
        <div>
            <Modal isOpen={props.modal} toggle={props.toggle} className={className}>
                <ModalHeader toggle={props.toggle}>Facebook Page</ModalHeader>
                <ModalBody>
                    {Array.isArray(props.fbPageData) && props.fbPageData.map((data) => {
                        let ischecked = dataArray.some(obj => obj.id === data.id)
                        return <p><input type="checkbox" checked={ischecked} onClick={() => onChangeData(data)} />{data.name}</p>
                    })}
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={()=>onSubmit()}>Checkout</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default FbModel;
