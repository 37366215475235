import React, { useState, useEffect } from "react";
import { TooltipPro } from "../../_components/CommonComponents";
import { notificationService } from "../../_services/servicesWithSecondOrigin/index";
import { FaThumbsUp } from "react-icons/fa";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { ImCross } from "react-icons/im";
import toastr from "toastr";
const OpenNotificationsPopup = (props) => {
  const [notifyArray, setnotifyArray] = useState([]);
  const [notifyArray1, setnotifyArray1] = useState([]);
  const [notifyArray2, setnotifyArray2] = useState([]);
  const [notifyArray3, setnotifyArray3] = useState([]);

  useEffect(() => {
    if (props.id) {
      getOpenNotification();
    }
  }, [props.id, props.isRefresh]);

  const getOpenNotification = async () => {
    var d = new Date();
    var n = d.getTimezoneOffset();
    let data = {
      resource_id: props.id,
      resource_type: props.isDomainPage ? props.isDomainPage : "monitor_server",
      timezone: props.mytimezone,
      timediff: n,
    };
    let response = "";
    if (props.isDomainPage) {
      response = await notificationService.allNotification(data);
    } else {
      response = await notificationService.getOpenNotification(data);
    }

    if (props.isDomainPage) {
      if (response && response.data && response.data.domain) {
        setnotifyArray1(response.data.domain);
        resetdata(1);
      }
      if (response && response.data && response.data.monitor_server) {
        setnotifyArray2(response.data.monitor_server);
        resetdata(2);
      }
      if (response && response.data && response.data.url_monitor) {
        setnotifyArray3(response.data.url_monitor);
        resetdata(3);
      }
    } else if (response && Array.isArray(response.data)) {
      setnotifyArray(response.data);
      resetdata(0);
    } else {
      resetdata();
    }
  };

  const resetdata = (type) => {
    type !== 0 && setnotifyArray([]);
    type !== 1 && setnotifyArray1([]);
    type !== 2 && setnotifyArray2([]);
    type !== 3 && setnotifyArray3([]);
  };

  const remove = (id) => {
    notificationService.removeNotification(id).then((res) => {
      res?.message && toastr.info(res?.message);

      if (props.id) {
        getOpenNotification();
      }
    });
  };
  let isNotify = 0;
  return (
    <div className="notificationdiv">
      {props.serverListing || props.isdomainDashboard ? (
        ""
      ) : (
        <>
          <label>Notifications:</label>
          <TooltipPro
            className="bottom tsize-300"
            tooltip={
              <div className="row">
                <div className="col-md-12">
                  <p>
                    This shows all the currently open notifications for this
                    server. Notifications generally are alert messages which
                    indicate that there is some issue on the server.
                  </p>
                </div>
              </div>
            }
          >
            <AiOutlineInfoCircle className="infoIcon" />
          </TooltipPro>
        </>
      )}
      <div>
        {props.isDomainPage ? (
          <>
            {Array.isArray(notifyArray1) && notifyArray1.length > 0
              ? notifyArray1.map((data, index) => {
                  if (
                    data &&
                    Array.isArray(data.eventNotification) &&
                    data.eventNotification.length > 0
                  ) {
                    isNotify += 1;
                    return (
                      <>
                        {/* <strong key={index}>
                          {data.eventNotification[0].message +
                            " (" +
                            data.eventNotification[0].created +
                            ")"}
                          <ImCross
                            size={15}
                            color="red"
                            onClick={() => remove(data.id)}
                          />
                        </strong> */}
                        <Message
                          key={data.id.toString()}
                          data={data}
                          remove={remove}
                        />
                      </>
                    );
                  }
                })
              : null}

            {Array.isArray(notifyArray2) && notifyArray2.length > 0
              ? notifyArray2.map((data, index) => {
                  if (
                    data &&
                    Array.isArray(data.eventNotification) &&
                    data.eventNotification.length > 0
                  ) {
                    isNotify += 1;
                    return (
                      <>
                        {/* <strong key={index}>
                          {data.eventNotification[0].message +
                            " (" +
                            data.eventNotification[0].created +
                            ")"}
                          <ImCross
                            size={15}
                            color="red"
                            onClick={() => remove(data.id)}
                          />
                        </strong> */}
                        <Message
                          key={data.id.toString()}
                          data={data}
                          remove={remove}
                        />
                      </>
                    );
                  }
                })
              : null}

            {Array.isArray(notifyArray3) && notifyArray3.length > 0
              ? notifyArray3.map((data, index) => {
                  if (
                    data &&
                    Array.isArray(data.eventNotification) &&
                    data.eventNotification.length > 0
                  ) {
                    isNotify += 1;
                    return (
                      <>
                        {/* <strong key={index}>
                          {data.eventNotification[0].message +
                            " (" +
                            data.eventNotification[0].created +
                            ")"}
                          <ImCross
                            size={15}
                            color="red"
                            onClick={() => remove(data.id)}
                          />
                        </strong> */}
                        <Message
                          key={data.id.toString()}
                          data={data}
                          remove={remove}
                        />
                      </>
                    );
                  }
                })
              : null}

            {(isNotify === 0 ||
              (notifyArray1.length === 0 &&
                notifyArray2.length === 0 &&
                notifyArray3.length === 0)) && (
              <div className="no-notification">
                <FaThumbsUp /> No Notification Available
              </div>
            )}
          </>
        ) : (
          <>
            {" "}
            {Array.isArray(notifyArray) && notifyArray.length > 0 ? (
              notifyArray.map((data, index) => {
                if (
                  data &&
                  Array.isArray(data.eventNotification) &&
                  data.eventNotification.length > 0
                ) {
                  return (
                    <>
                      <Message
                        key={data.id.toString()}
                        data={data}
                        remove={remove}
                      />
                    </>
                    //  <strong key={index}>
                    //       {data.eventNotification[0].message +
                    //         " (" +
                    //         data.eventNotification[0].created +
                    //         ")"}
                    //     </strong>
                  );
                }
              })
            ) : (
              <div className="no-notification servernotifi">
                {" "}
                <FaThumbsUp /> No Notification Available
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default OpenNotificationsPopup;

const Message = ({ data, remove }) => {
  return (
    <strong className="d-block">
      {data.eventNotification[0].message +
        " (" +
        data.eventNotification[0].created +
        ")"}
      <ImCross
        className="cursor-pointer ml-1"
        size={10}
        color="#000000"
        onClick={() => remove(data.eventNotification[0].eventNotificationId)}
      />
    </strong>
  );
};
