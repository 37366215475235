import React, { useRef, useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";
import refreshImg from "./../../assets/img/refresh_new.png";
import "echarts/lib/component/tooltip";

const LineStackChart = ({ ...props }) => {
  const [setUnSet, setUnSetUI] = useState(true);
  const myref = useRef(null);
  useEffect(() => {
    if (props.onRefresh) {
      setUnSetUI(false);
      setTimeout(() => {
        setUnSetUI(true);
      }, 1);
    }
  }, [props.onRefresh]);
  let options = {
    group: "group1",
    grid: {
      top: "25%",
      left: "3%",
      right: "4%",
      bottom: props.dataZoom ? "18%" : "3%",
      containLabel: true,
    },
    xAxis: {
      type: "category",
      data: ["03:00", "04:00"],
    },
    yAxis: {
      type: "value",
      axisLabel: {
        formatter: `{value} ${props.dataSizeType ? props.dataSizeType : ""}`,
      },
    },
    series: [
      {
        data: [0],
        type: "line",
        smooth: true,
      },
    ],
    legend: {
      data: [],
    },
  };

  if (props.title) {
    let style = {};
    var element = document.body;
    if (element.classList.contains("dark")) {
      style = {
        textStyle: {
          color: "#b6c2e4",
        },
      };
    }

    options.title = {
      text: props.title,
      ...style,
    };
  }
  if (props.legend) {
    if (props.legend) {
      if (props.legendObj) {
        options.legend = props.legendObj;
      } else {
        options.legend = {
          data: props.legend,
          type: "scroll",
        };
      }
    }
  }

  if (props.toolbox) {
    options.toolbox = {
      feature: {
        saveAsImage: {},
      },
      right: "06%",
    };

    // for restore custom toolbox feature
    if (props.restore) {
      options.toolbox.feature.myTool1 = {
        show: true,
        title: "Refresh",
        icon: "image://" + refreshImg,
        onclick: function () {
          if (
            myref &&
            myref.current &&
            myref.current.getEchartsInstance() &&
            myref.current.props
          ) {
            if (props.getRestoreChart) {
              props.getRestoreChart();
            } else {
              myref.current
                .getEchartsInstance()
                .setOption(myref.current.props.option, true);
            }
          }
        },
      };
    }
  }

  if (props.tooltip) {
    options.tooltip = {
      trigger: "axis",

      formatter: function (e) {
        if (props.tooltipFormatterCallBack) {
          localStorage.setItem("topProcess", e[0].name);
        }

        if (props.tooltipFormatterValueName) {
          localStorage.setItem(props.tooltipFormatterValueName, e[0].name);
        }

        let rowData = e[0].name;

        Array.isArray(e) &&
          e.forEach((data) => {
            rowData =
              rowData +
              `<br/> <span style="font-weight:bold">${data.marker} ${
                props.notShow ? "" : data.seriesName + " : "
              }</span>${
                props.tofixed ? parseFloat(data.data).toFixed(2) : data.data
              } ${props.dataSizeType ? props.dataSizeType : ""} ${
                !data.data && props.ShowOnBlank ? props.ShowOnBlank : ""
              }`;
          });
        return rowData;
      },
    };
    // Dark Mode custom color update on chart
    var element = document.body;
    if (element.classList.contains("dark")) {
      // code to update tooltip color on theme change
      let textColor = "#b6c2e4";
      let BgColor = "#30384c";
      options.tooltip = {
        ...options.tooltip,
        backgroundColor: BgColor,
        textStyle: {
          color: textColor,
        },
      };
      // code to update legend color on theme change
      options.legend = {
        ...options.legend,
        textStyle: {
          color: textColor,
        },
      };
    }
  }

  if (props.dataZoom) {
    options.dataZoom = [{}];
  }
  if (props.grid) {
    options.grid = props.grid;
  }
  if (props.xAxis) {
    options.xAxis = props.xAxis;
  }
  if (props.yAxis) {
    options.yAxis = props.yAxis;
  }
  if (props.series) {
    options.series = props.series;
  }

  const _onEvents = {
    click: () => {},
    //'dataZoom': this.onDataZoom,
  };

  useEffect(() => {
    if (props.getEchartRef && myref) {
      props.getEchartRef(myref);
    }
  }, []);

  return (
    <>
      {setUnSet && (
        <ReactECharts
          //theme="dark"
          ref={myref}
          option={options ? options : {}}
          onClick={_onEvents}
          opts={props.opts}
        />
      )}
    </>
  );
};
export default LineStackChart;
