import Draggable from "react-draggable";
import ModalDialog from "react-bootstrap/ModalDialog";

import "./ModalDraggable.styles.css"

// This Component can be passed in `dialogAs` props of `Modal` Component from `react-bootstrap` Library, NOT from `reactstrap` Library.
// Example:- <Modal dialogAs={ModalDraggable} >...</Modal>

export default function ModalDraggable(bootStrapModalProps) {
  return (
    <Draggable handle=".modal-header">
      <ModalDialog {...bootStrapModalProps} className={"draggable"} />
    </Draggable>
  );
}
