import React from "react";
import { FaFacebookF } from "react-icons/fa";
import { Row, Col } from 'reactstrap';
import FacebookAuth from '../../_components/FacebookAuth/FacebookAuth'
import TwitterAuth from '../TwitterAuth/TwitterAuth'
// import InstagramAuth from '../InstagramAuth/InstagramAuth'
// import { GrAddCircle } from "react-icons/gr";
// import { Constants } from './Constants'
import FormItem from './FormItem'
// import FbPage from './FBPageSelectModel'
const SocialMediaFrom = ({ ...props }) => {


    // const onClick = () => {
    //     // http://instagram.com/developer/endpoints/

    //     authenticateInstagram(811418753091786, 'https://www.a1websitetools.com/g8keeper/update-social-media/', callbackFun)
    // }

    // var authenticateInstagram = function (instagramClientId, instagramRedirectUri, callback) {
    //     // Pop-up window size, change if you want
    //     var accessToken = null;
    //     var popupWidth = 700,
    //         popupHeight = 500,
    //         popupLeft = (window.screen.width - popupWidth) / 2,
    //         popupTop = (window.screen.height - popupHeight) / 2;
    //     // Url needs to point to instagram_auth.php
    //     var popup = window.open('instagram_auth.php', '', 'width=' + popupWidth + ',height=' + popupHeight + ',left=' + popupLeft + ',top=' + popupTop + '');
    //     popup.onload = function () {
    //         // Open authorize url in pop-up
    //         if (window.location.hash.length == 0) {
    //             popup.open('https://instagram.com/oauth/authorize/?client_id=' + instagramClientId + '&redirect_uri=' + instagramRedirectUri + '&response_type=code' + '&scope=user_profile,user_media', '_self');
    //         }
    //         // An interval runs to get the access token from the pop-up
    //         var interval = setInterval(function () {
    //             try {
    //                 // Check if hash exists
    //                 if (popup.location.hash.length) {
    //                     // Hash found, that includes the access token
    //                     clearInterval(interval);
    //                     accessToken = popup.location.hash.slice(14); //slice #access_token= from string
    //                     console.log()
    //                     popup.close("accessToken", accessToken);
    //                     if (callback != undefined && typeof callback == 'function') {
    //                         callback();
    //                     }
    //                 }
    //             }
    //             catch (evt) {
    //                 // Permission denied
    //             }
    //         }, 100);
    //     };
    // }


    // const callbackFun = (a, b) => {
    // }

    return <div className="seo-monitoring-form">
        <div className="">
            {/* <div className="card-header">
                { <h3 className="mb-0">Track Social Media</h3> }
                {!props.popup && <h3>Domain: {props.domain}</h3>}
               
            </div> */}
            <div  className="p-4">
                <div>

                    <div className="row align-items-center mb-3">
                        <div className="col-md-4 col-sm-12 fbBtns">
                            <FacebookAuth onCallback={(e) => props.onCallback(e)} ><FaFacebookF /></FacebookAuth>
                            
                        </div>
                        <div className="col-md-4 col-sm-12 text-center">
                            <TwitterAuth twitterData={props.twitterData}></TwitterAuth>
                        </div>
                        <div className="col-md-4 col-sm-12 instaBtns">
                            <FacebookAuth isInsta={true} label="Login with Insta" onCallback={(e) => props.ongetInstraCallBack(e)}></FacebookAuth>
                            {/* <InstagramAuth ongetInstraCallBack={(e)=>props.ongetInstraCallBack(e)}></InstagramAuth> */}
                        </div>
                        {/* <div className="col-md-3">
                        <button onClick={() => onClick()}>insta</button>
                    </div> */}
                    </div>
                    <Row>
                        <Col>
                            <label>URLs - Facebook, Twitter, Instagram, Linkedin</label>
                        </Col>
                        <Col>
                            <label>Type</label>
                        </Col>
                    </Row>
                </div>
                <FormItem
                    socialMediaArray={props.socialMediaArray}
                    {...props}
                ></FormItem>
            </div>

        </div>
    </div>
}

export default SocialMediaFrom