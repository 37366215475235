import React, { useEffect, useState } from "react";
import LineStackChart from "../Charts/LineStackChart";
import { Loader } from "../../_components/CommonComponents/index";
import { useHistory } from "react-router-dom";
import { objectToQuery } from "../../_helpers/Common/Common";

const alternateChartTitles = {
  MemoryCurrent: "Memory Used",
  CPUUsageNSec: "CPU Used",
  TasksCurrent: "Current Tasks",
};

const chartDataTypes = {
  MemoryCurrent: "MB",
  CPUUsageNSec: "%",
};

const PerformanceChartsOperation = (props) => {
  let [data, setData] = useState({});
  let history = useHistory();
  const { name } = props;
  useEffect(() => {
    if (props.data) {
      setData(props.data);
    }
  }, [props.data]);

  return (
    <>
      {data[name] &&
        Object.keys(data[name]).map((key) => {
          if (key === "timestamp" || !data?.[name]?.[key]?.length) return null;
          if (props.iKey && props.iKey !== key) return null;

          let tId = name + "-" + key;
          let title = name + "-" + alternateChartTitles[key];

          if (props.details)
            return (
              <PerformanceChartOperation
                value={data[name][key].map((num) =>
                  typeof num === "number" ? +num.toFixed(3) : num
                )}
                key1={alternateChartTitles[key] || key}
                timestamp={data[name]["timestamp"]}
                dataSizeType={chartDataTypes[key]}
                {...props}
              />
            );

          return (
            <div className="col-md-6 mt-4" key={key}>
              <div className="card overflow-visible">
                <div className="card-header">
                  <h3>{alternateChartTitles[key] || key}</h3>
                </div>
                <div
                  className="chartInnerDiv"
                  onClick={() => {
                    const object = {
                      user_element: tId,
                      title: title,
                      update: true,
                      chart:
                        name === "Apache"
                          ? 2
                          : name === "Nginx"
                          ? 5
                          : name === "MySQL"
                          ? 3
                          : 9,
                      type: tId,
                      cKey: name,
                      iKey: key,
                      ...props.properties,
                    };
                    history.push(
                      "/monitor-server/chart" + objectToQuery(object),
                      object
                    );
                  }}>
                  <PerformanceChartOperation
                    value={data[name][key].map((num) =>
                      typeof num === "number" ? +num.toFixed(3) : num
                    )}
                    key1={alternateChartTitles[key] || key}
                    timestamp={data[name]["timestamp"]}
                    dataSizeType={chartDataTypes[key]}
                  />
                </div>
              </div>
            </div>
          );
        })}
    </>
  );

  // if (data && data[name] && name) {
  //     Object.keys(data[name]).map((key) => {
  //         return <PerformanceChartOperation value={data[name][key]} key={key} timestamp={data[name]['timestamp']} />
  //     })
  // }else{
  //     return <div></div>
  // }
};

export default PerformanceChartsOperation;

const PerformanceChartOperation = ({ dataSizeType, ...props }) => {
  const [xAxis, setXAxis] = useState(false);
  const [yAxis, setyAxis] = useState(false);
  const [series, setSeries] = useState(false);
  const [setUnSet, setUnSetUI] = useState(true);
  const [loader, setLoader] = useState(false);
  const [legend, setLegend] = useState([]);

  useEffect(() => {
    if (props.value) getGraphDataFromServer();
  }, [props.value, props.key1]);

  const getGraphDataFromServer = async () => {
    setSeries([
      {
        name: props.key1,
        data: [0],
        type: "line",
        smooth: true,
      },
    ]);
    // setXAxis({
    //     type: "category",
    //     triggerEvent: true,
    //     data: [],
    // });

    setXAxis({
      type: "category",
      data: props.timestamp,
    });

    setSeries([
      {
        name: props.key1,
        data: props.value,
        type: "line",
        smooth: true,
      },
    ]);
  };

  return (
    <>
      <div className="chart-loader">
        {" "}
        <Loader loader={loader}></Loader>
      </div>
      {setUnSet && (
        <LineStackChart
          xAxis={xAxis}
          yAxis={yAxis}
          series={series}
          dataSizeType={dataSizeType}
          legend={legend}
          tooltip={true}
          toolbox={true}
          {...props}></LineStackChart>
      )}
    </>
  );
};
