import React, { useState, useEffect } from "react";
import { notificationService } from "../../_services/servicesWithSecondOrigin/index";
import Header from "../../_components/CommonComponents/Header/Header";
import { Row, Col } from "reactstrap";
import { notificationChannelServices } from "../../_services/servicesWithSecondOrigin/index";
import { Error } from "../../_components/CommonComponents/index";
import CollapseData from "./CollapseData";
import PageLoader from "../../_components/CommonComponents/PageLoader/Loader";
import { MdRefresh } from "react-icons/md";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const NotificationSetting = (props) => {
  const [dataDomain, setDataDomain] = useState([]);
  const [dataServerReladed, setDataServerReladed] = useState([]);
  const [dataUrlMonitor, setDataUrlMonitor] = useState([]);
  const [allNotification, setAllNotification] = useState([]);
  const [chanelD, setChannel] = useState("");
  const [isLoading, setLOading] = useState(false);
  const [isRefresh, setRefresh] = useState("");
  const [error, setError] = useState(false);
  useEffect(() => {
    getAllNotification();
  }, []);

  const refresh = async () => {
    await getAllNotification();
    await setRefresh(Math.random());
  };

  const getDataDomain = async () => {
    setLOading(true);
    let dataObj = {
      resource_type: "domain",
    };
    let response = await notificationService.allResourceSetting(dataObj);
    if (response && response.data) {
      setLOading(false);
      setDataDomain(response.data);
    } else {
      setLOading(false);
    }
  };

  const getDataServerRelated = async () => {
    setLOading(true);
    let dataObj = {
      resource_type: "monitor_server",
    };
    let response = await notificationService.allResourceSetting(dataObj);
    if (response && response.data) {
      setLOading(false);
      setDataServerReladed(response.data);
    } else {
      setLOading(false);
    }
  };

  const getDataUrlMoniter = async () => {
    setLOading(true);
    let dataObj = {
      resource_type: "url_monitor",
    };
    let response = await notificationService.allResourceSetting(dataObj);
    if (response && response.data) {
      setLOading(false);
      setDataUrlMonitor(response.data);
    } else {
      setLOading(false);
    }
  };

  const getAllNotification = async () => {
    setLOading(true);
    let allNotifications =
      await notificationChannelServices.getAllNotificationChannel();
    if (
      allNotifications &&
      Array.isArray(allNotifications.data) &&
      allNotifications.data[0]
    ) {
      setAllNotification(allNotifications.data);
    } else {
      setAllNotification([]);
    }
    setLOading(false);
  };

  const onSetChangeChannel = (e) => {
    setLOading(true);
    setChannel(e.target.value);
    setTimeout(() => {
      setLOading(false);
    }, 500);
  };

  const onErrorCallBack = () => {
    setError(true);
  };

  const updateDomain = (obj) => {
    update(obj, dataDomain, setDataDomain, "domain");
  };
  const updateServer = (obj) => {
    update(obj, dataServerReladed, setDataServerReladed, "monitor_server");
  };
  const updateUrl = (obj) => {
    update(obj, dataUrlMonitor, setDataUrlMonitor, "url_monitor");
  };

  const update = (obj, data, setter, key) => {
    let data_copy = { ...data };
    for (const property in data[key]) {
      console.log(data_copy[key][property]?.setting);
      Array.isArray(data_copy[key][property]?.setting) &&
        data_copy[key][property]?.setting?.map((setting, index) => {
          if (setting?.id === obj?.resource_id) {
            Array.isArray(data_copy[key][property].setting[index].channels) &&
              data_copy[key][property].setting[index].channels.map(
                (channels, cIndex) => {
                  if (channels?.id === obj?.notification_channel_id) {
                    data_copy[key][property].setting[index].channels[
                      cIndex
                    ].status = obj.status;
                  }
                }
              );
          }
        });
    }
    setter(data_copy);
  };

  return (
    <div className="container">
      <PageLoader isLoading={isLoading}></PageLoader>
      <Header history={props.history} {...props}></Header>
      <div>
        <div className="serverDetailsSec">
          <div className="w-100">
            <Row className="align-items-center notificationPerPageFilter">
              <Col md="4">
                <div
                  className={!chanelD && error ? "error-color-red" : ""}
                  style={
                    !chanelD && error
                      ? {
                          border: "1px solid red",
                          borderRadius: "5px",
                          background: "red",
                        }
                      : {}
                  }
                >
                  <select
                    value={chanelD}
                    className={
                      !chanelD && error
                        ? "form-control error-color-red"
                        : "form-control"
                    }
                    onChange={(e) => onSetChangeChannel(e)}
                  >
                    <option value="">Select notification Channel</option>
                    {allNotification &&
                      allNotification.map((channel) => {
                        return (
                          <option value={channel.id}>
                            {channel.custom_channel_name}
                          </option>
                        );
                      })}
                  </select>
                </div>
                <div className="pl-3 error-string" style={{ fontSize: "13px" }}>
                  {!chanelD && error && (
                    <Error error="Please Choose Notification Channel"></Error>
                  )}
                </div>
              </Col>
              <Col md="7">
                <a
                  href="#"
                  style={!chanelD && error ? { marginBottom: "20px" } : {}}
                  className="btn btn-primary"
                  onClick={() => props.history.push("/notification-channel")}
                >
                  Manage Notification Channel
                </a>
              </Col>
              <Col md="1">
                <OverlayTrigger
                  key={"left"}
                  placement={"left"}
                  overlay={<Tooltip>Refresh</Tooltip>}
                >
                  <MdRefresh
                    size="20"
                    className="iconBtn"
                    onClick={() => refresh()}
                  ></MdRefresh>
                </OverlayTrigger>
              </Col>
            </Row>
          </div>
        </div>
        <div className="card">
          <div className="card-body p-0">
            <CollapseData
              urlServerName="Domain Name"
              data={dataDomain.domain}
              tab={"Domain Related Notifications"}
              innerKey={"server_name"}
              allNotification={allNotification}
              chanelD={chanelD}
              resource_type="domain"
              callBackGetData={(obj) => getDataDomain(obj)}
              isRefresh={isRefresh}
              onErrorCallBack={onErrorCallBack}
              switchAll={(obj) => updateDomain(obj)}
            ></CollapseData>
            <CollapseData
              urlServerName="Server Name"
              data={dataServerReladed.monitor_server}
              tab={"Server Related Notifications"}
              innerKey={"server_name"}
              allNotification={allNotification}
              chanelD={chanelD}
              resource_type="monitor_server"
              callBackGetData={() => getDataServerRelated()}
              isRefresh={isRefresh}
              onErrorCallBack={onErrorCallBack}
              switchAll={(obj) => updateServer(obj)}
            ></CollapseData>
            <CollapseData
              urlServerName="URLs"
              data={dataUrlMonitor.url_monitor}
              tab={"Monitor URL related notifications"}
              innerKey={"url"}
              allNotification={allNotification}
              chanelD={chanelD}
              resource_type="url_monitor"
              callBackGetData={(obj) => getDataUrlMoniter(obj)}
              isRefresh={isRefresh}
              onErrorCallBack={onErrorCallBack}
              switchAll={(obj) => updateUrl(obj)}
            ></CollapseData>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationSetting;
