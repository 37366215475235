import React, {
    useEffect,
    useState
} from 'react'
import LineStackChart from '../Charts/LineStackChart'
import { Loader } from '../../_components/CommonComponents/index'
const ChartCPUUptimeOperation = ({ ...props }) => {
    const [xAxis, setXAxis] = useState(false)
    const [yAxis, setyAxis] = useState(false)
    const [series, setSeries] = useState(false)
    const [setUnSet, setUnSetUI] = useState(true)
    const [dataSizeType, setDataSizeType] = useState("")
    const [loader, setLoader] = useState(true)
    useEffect(() => {
        if(props.chartData === null){
            setLoader(true)
        }else{
            setLoader(false)
        }
        if (props.chartData)
           {
             getGraphDataFromServer()
             
            
            }
    }, [props.chartData])

    const getGraphDataFromServer = async () => {
        setyAxis({
            type: 'value',
            axisLabel: {
                formatter: ``
            },
        })
      
        let response = props.chartData
        setLoader(false)
        if (response && response) {
            setUnSetUI(false)
            if (Array.isArray(response.timestamp)) {
                setXAxis({
                    type: 'category',
                    data: response.timestamp,
                    axisLabel: {
                        formatter: ``
                    },
                })
            } else {
                setXAxis({
                    type: 'category',
                    triggerEvent: true,
                    data: [],
                    axisLabel: {
                        formatter: ``
                    },
                })
            }
            const Series = [{
                name: 'count',
                data: response.count,
                type: 'line',
                smooth: true,
            }]
            setDataSizeType("")
            setUnSetUI(true)
            if (Series.length === 0) {
                setSeries([{
                    name: "",
                    data: [0],
                    type: 'line',
                    smooth: true,
                }])
            } else {
                setSeries(Series)
            }
            setLoader(false)
        } else {
            setLoader(false)
        }
    }

    return <div className='position-relative'>
        <div className="chart-loader " style={{top:'8%'}}> <Loader loader={loader}></Loader></div>
        {setUnSet && <LineStackChart
            xAxis={xAxis}
            yAxis={yAxis}
            series={series}
            dataSizeType={dataSizeType}
            tooltip={true}
            // toolbox={true}
            grid={
                {
                    left: '3%',
                    right: '4%',
                    bottom:  '80%',
                    top:'0%',
                    containLabel: true
                }
            }
            {...props}
        ></LineStackChart>}</div>
}
export default ChartCPUUptimeOperation