import React, { useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import { monitorServersServices } from "../../../_services/servicesWithSecondOrigin/index";
import online from "../../../assets/img/online_user.png";
import offline_user from "../../../assets/img/offline_user.png";
import { domainService } from "../../../_services/DomainServices/domainServices";
const CurrentLoggedInModel = (props) => {
  const { className } = props;

  const [modal, setModal] = useState(false);
  const [currentLoggedIn, setCurrentLoggedIn] = useState("");

  const toggle = () => setModal(!modal);

  useEffect(() => {
    if (props.uuid && props.mytimezone) serverCurrentLoggedIn();
    const interval = setInterval(() => {
      if (props.uuid && props.mytimezone) serverCurrentLoggedIn();
    }, 120000);
    return () => {
      clearInterval(interval);
    };
  }, [props.uuid, props.mytimezone]);

  const serverCurrentLoggedIn = async () => {
    setCurrentLoggedIn("");

    const data = {
      uuid: props.uuid,
      user_element: "currently_logged_in",
      timezone: props.mytimezone,
    };

    const response = await monitorServersServices.currentLoggedInServerDetails(
      data
    );
    if (!response?.data) return;
    if (Array.isArray(response.data.currently_logged_in))
      for (const row of response.data.currently_logged_in) {
        if (row[2]) {
          row[2] = row[2].replace(/([*+?^$|(){}\[\]])/g, "");
          const resp = await domainService.getLocationByIps({
            ip_address_list: [row[2]],
          });
          if (resp?.[row[2]]?.city_name)
            row[2] = `${row[2]} (${resp[row[2]].city_name}, ${
              resp[row[2]].country_name
            })`;
        }
      }

    setCurrentLoggedIn(response.data);
  };

  return (
    <div>
      {currentLoggedIn ? (
        <div onClick={toggle}>
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip>
                Users Logged into Server using SSH. Click to view details.
              </Tooltip>
            }
          >
            <img src={online} width="29px" style={{ cursor: "pointer" }}></img>
          </OverlayTrigger>
        </div>
      ) : (
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip>
              No one is Logged into the Server using SSH currently
            </Tooltip>
          }
        >
          <img src={offline_user} width="29px" className=""></img>
        </OverlayTrigger>
      )}
      <Modal size="lg" isOpen={modal} toggle={toggle} className={className}>
        <ModalHeader toggle={toggle}>
          Current Logged In{" "}
          {currentLoggedIn && currentLoggedIn.timestamp
            ? ": " + currentLoggedIn.timestamp
            : ""}
        </ModalHeader>
        <ModalBody>
          <div className="card mb-0">
            <table className="table mb-0">
              <thead>
                <tr>
                  <th>User</th>
                  <th>Login Time</th>
                  <th>Login IP</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {currentLoggedIn &&
                  Array.isArray(currentLoggedIn.currently_logged_in) &&
                  currentLoggedIn.currently_logged_in.map((obj, index) => {
                    return (
                      <tr key={index.toString()}>
                        <td>{`${obj[0]}`}</td>

                        <td>{`${obj[1]}`}</td>
                        <td>{`${obj[2]} `}</td>
                        <td>{`${obj[3]}`}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default CurrentLoggedInModel;
