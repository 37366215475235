import React from 'react';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import fbicon from '../../assets/img/facebook.png'
import insta from '../../assets/img/insta.png'

const FacebookAuth = (props) => {
  const responseFacebook = (response) => {
    window.FB.api(
      `/${response.userID}/accounts?fields=name,access_token&
            access_token": "{long-lived-user-access-token}`,

      (respo) => {
        if (respo && props.onCallback) {
          props.onCallback(respo)
        }
      })
  }

  return <FacebookLogin
    appId="805741630356591"
    cookie={true}                    // Enable cookies to allow the server to access the session.
    xfbml={true}
    autoLoad={false}
    fields="name,email,id"
    callback={responseFacebook}
    cssClass="my-facebook-button-class"
    icon="fa-facebook"
    scope="public_profile,email,pages_show_list,read_insights"
    render={renderProps => (
      <button  className ="fbBtns" onClick={renderProps.onClick}><img src={props.isInsta?insta :fbicon} alt=""/> {props.label?props.label:"Login with FaceBook"}</button>
    )}
  />
}


export default FacebookAuth


