import React, { useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";

const BarStackChart = ({ onEvents, ...props }) => {
  const [setUnSet, setUnSetUI] = useState(true);
  useEffect(() => {
    if (props.onRefresh) {
      setUnSetUI(false);
      setTimeout(() => {
        setUnSetUI(true);
      }, 1);
    }
  }, [props.onRefresh]);
  const options = {
    legend: {
      data: ["Direct", "Mail Ad", "Affiliate Ad", "Video Ad", "Search Engine"],
    },
    xAxis: {
      type: "value",
      axisLabel: {
        formatter: `{value} ${props.dataSizeType ? props.dataSizeType : ""}`,
      },
    },
    yAxis: {
      type: "category",
      data: ["y"],
    },
    series: [
      {
        name: "Direct",
        type: "bar",
        stack: "total",
        // label: {
        //     show: true
        // },
        emphasis: {
          focus: "series",
        },
        data: [""],
      },
    ],

    grid: {
      left: "3%",
      right: "4%",
      bottom: props.dataZoom ? "5%" : "3%",
      containLabel: true,
    },
  };

  if (props.title) {
    let style = {};
    var element = document.body;
    if (element.classList.contains("dark")) {
      style = {
        textStyle: {
          color: "#b6c2e4",
        },
      };
    }
    options.title = {
      text: props.title,
      ...style,
    };
  }
  if (props.legend) {
    options.legend = {
      data: props.legend,
    };
  }

  if (props.toolbox) {
    options.toolbox = {
      feature: {
        saveAsImage: {},
      },
    };
  }
  if (props.tooltip) {
    options.tooltip = {
      trigger: "axis",
      formatter: function (e) {
        if (props.tooltipFormatterCallBack) {
          localStorage.setItem("topProcess", e[0].name);
          props.tooltipFormatterCallBack(e[0].name);
        }
        let rowData = e[0].name;
        Array.isArray(e) &&
          e.forEach((data) => {
            rowData =
              rowData +
              `<br/> ${data.marker}   ${
                props.notShow ? "" : data.seriesName
              }   ${data.data} ${props.dataSizeType ? props.dataSizeType : ""}`;
              
          });
        return rowData;
      },
    };
    if (localStorage.getItem("themes") === "dark") {
      options.tooltip = {
        ...options.tooltip,
        backgroundColor: "#30384c",
        textStyle: {
          color: "#b6c2e4",
        },
      };
    }
  }
  if (props.dataZoom) {
    options.dataZoom = [{}];
  }
  if (props.grid) {
    options.grid = props.grid;
  }
  if (props.xAxis) {
    options.xAxis = props.xAxis;
  }
  if (props.yAxis) {
    options.yAxis = props.yAxis;
  }
  if (props.series) {
    options.series = props.series;
  }

  return <>{setUnSet && <ReactECharts option={options ? options : {}} onEvents={onEvents} />}</>;
};
export default BarStackChart;
