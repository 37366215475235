import { useState, useMemo } from "react";
import { Button } from "reactstrap";
import { AiOutlineInfoCircle } from "react-icons/ai";

import { CpuMemoMadal } from "./CPUMemoModal";
import { TooltipPro } from "../../_components/CommonComponents/index";
import { DiskIOWaitActualTable } from "../../_components/DiskIOWaitActualTabs/DiskIOWaitActualTabs";

export default function TopProcess({
  processType,
  date,
  topProcessData,
  getTopProcess,
  diskIOProcessServerDetails,
  uuid,
}) {
  const [search, setSearch] = useState("");

  const tableList = useMemo(
    () =>
      !search
        ? topProcessData
        : topProcessData.filter(
            (row) =>
              row.PID?.toString().includes(search) ||
              row.COMMAND?.toLowerCase().includes(search.toLowerCase())
          ),
    [topProcessData, search]
  );

  const topProcessKeyList = useMemo(
    () => [
      {
        dataField: "sid",
        text: "#",
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: "04px" };
        },
        formatter: (_cellContent, _cellData, index) => <div>{index + 1}</div>
      },
      {
        dataField: "PID",
        text: "PID",
        sort: true,
        headerStyle: (column, colIndex) => {
          return { width: "07px" };
        },
        formatter: (cellContent, row) => {
          return (
            <CpuMemoMadal
              COMMAND={row.COMMAND}
              cellContent={cellContent}
              uuid={uuid}
            />
          );
        },
      },
      {
        dataField: "%CPU",
        text: "CPU",
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: "05px" };
        },
      },
      {
        dataField: "%MEM",
        text: "MEM",
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: "05px" };
        },
      },
      {
        dataField: "COMMAND",
        text: "COMMAND",
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: "18px" };
        },
      },
      {
        dataField: "TIME+",
        text: "TIME",
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: "05px" };
        },
      },
    ],
    [uuid]
  );

  const otherProcessKeyList = useMemo(
    () => [
      {
        dataField: "sid",
        text: "#",
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: "03px" };
        },
      },
      {
        dataField: "PID",
        text: "PID",
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: "07px" };
        },
        formatter: (cellContent, row) => {
          return (
            <CpuMemoMadal
              COMMAND={row.COMMAND}
              cellContent={cellContent}
              uuid={uuid}
            />
          );
        },
      },
      {
        dataField: "USER",
        text: "USER",
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: "07px" };
        },
      },
      {
        dataField: "%MEM",
        text: "MEM",
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: "06px" };
        },
      },
      {
        dataField: "COMMAND",
        text: "COMMAND",
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: "15px" };
        },
      },
      {
        dataField: "PROCESS_TYPE",
        text: "PROCESS_TYPE",
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: "15px" };
        },
      },
    ],
    [uuid]
  );
  return (
    <div className="col-md-6">
      <div className="card fixedChartTable">
        <div className="px-3 py-2">
          <div className="row align-items-center">
            <div className="col-3 pr-0">
              <Button
                className="float-left"
                onClick={() => getTopProcess()}
                color={processType === "top_process" ? "primary" : "secondary"}
              >
                Top Process
              </Button>
              <TooltipPro
                className="bottom tsize-400 ml-3 mt-1 d-inline-block"
                style={{ zIndex: 10 }}
                tooltip={
                  <div className="row">
                    <div className="col-md-12">
                      <p>
                        This shows the currently running processes on the server
                        that are consuming CPU. The list is sorted based on CPU
                        usage by process. This list shows Process ID (PID),
                        Percent CPU usage by process, Percent RAM usage by
                        process, Name of the command that is running and
                        cummulative CPU time consumed by the process.
                      </p>
                    </div>
                  </div>
                }
              >
                <AiOutlineInfoCircle className="infoIcon" />
              </TooltipPro>
            </div>
            <div className="col-5 px-0">
              <div className="row mx-0">
                <div className="col-6 px-0 pt-2">
                  <strong style={{ fontSize: "12px" }}>{date}</strong>
                </div>
                <div className="col-6 px-0">
                  <input
                    type="search"
                    className="form-control"
                    placeholder="Search"
                    onChange={(e) => setSearch(e.target.value)}
                    style={{
                        fontSize: 12,
                        height: 32
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="col-4 d-flex justify-content-end">
              <Button
                className="float-right"
                onClick={() => diskIOProcessServerDetails()}
                color={
                  processType === "disk_io_process" ? "primary" : "secondary"
                }
              >
                Memory Proceses
              </Button>
              <TooltipPro
                className="bottom tsize-400 ml-3 mt-1 d-inline-block"
                style={{ zIndex: 10 }}
                tooltip={
                  <div className="row" style={{ zIndex: 10 }}>
                    <div className="col-md-12">
                      <p>
                        This shows the currently running processes on the server
                        that are consuming CPU. The list is sorted based on CPU
                        usage by process. This list shows Process ID (PID),
                        Percent CPU usage by process, Percent RAM usage by
                        process, Name of the command that is running and
                        cummulative CPU time consumed by the process.
                      </p>
                    </div>
                  </div>
                }
              >
                <AiOutlineInfoCircle className="infoIcon" />
              </TooltipPro>
            </div>
          </div>
        </div>
        {/* <div className="card-header"><h3>{processType === 'top_process' ? 'Top Process' : 'Disk IO Process'} {date && ": " + date}</h3></div> */}
        {/* <div className="table-responsive">
                <Table className="table table-bordered topProcessTbl">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>PID</th>
                            {processType !== 'top_process' && <th> USER</th>}
                            {processType === 'top_process' && <th>CPU</th>}
                            <th>MEM</th>
                            <th>COMMAND</th>
                            {processType === 'top_process' && <th> TIME</th>}
                            {processType !== 'top_process' && <th>PROCESS_TYPE</th>}
                        </tr>
                    </thead>
                    <tbody>
                        {Array.isArray(Object.entries(topProcessData)) && Object.entries(topProcessData).map((obj, index) => {
                            if (index < 9 || state.topProcessDataCollapse)
                                return <tr>
                                    <th scope="row">{index + 1}</th>
                                    <td>{obj[1] && obj[1]['PID']}</td>
                                    {processType !== 'top_process' && <td>{obj[1] && obj[1]['USER']}</td>}
                                    {processType === 'top_process' && <td>{obj[1] && obj[1]['%CPU']}</td>}
                                    <td>{obj[1] && obj[1]['%MEM']}</td>
                                    <td style={{ maxWidth: '150px' }}>{obj[1] && obj[1]['COMMAND']}</td>
                                    {processType === 'top_process' && <td>{obj[1] && obj[1]['TIME+']}</td>}
                                    {processType !== 'top_process' && <td>{obj[1] && obj[1]['PROCESS_TYPE']}</td>}
                                </tr>
                        })}
                    </tbody>
                </Table>
            </div>
            {Array.isArray(Object.entries(topProcessData)) && Object.entries(topProcessData).length > 9 && < div >
                <a style={{ float: 'right', cursor: 'pointer' }} onClick={() => setState({ topProcessDataCollapse: !state.topProcessDataCollapse })}>{state.topProcessDataCollapse ? <>Show Less <IoIosArrowUp size={20} ></IoIosArrowUp></> : <>Show More <IoIosArrowDown size={20} ></IoIosArrowDown></>}</a>
            </div>} */}

        <DiskIOWaitActualTable
          // dateTime={dateTime}
          tableList={tableList}
          style={{
            float: "right",
            cursor: "pointer",
            height: "20px",
          }}
          keyList={
            processType === "top_process"
              ? topProcessKeyList
              : otherProcessKeyList
          }
        />
      </div>
    </div>
  );
}
