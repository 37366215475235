import React, { useCallback, useEffect, useState } from "react";
import { CardTitle } from "reactstrap";
import DisallowedFilesFormListing from "./DisallowedFormListing";
import { monitorServersServices } from "../../../_services/servicesWithSecondOrigin/index";
import { Loader } from "../../CommonComponents/index";

const DisallowedFilesForm = (props) => {
    const [responseData, setData] = useState([]);
    const [loader, setLoader] = useState(true);
    const [message, setMessage] = useState("");
    // let [count, setCount] = useState(0);
    const getData = useCallback(async () => {
        setData([]);
        setLoader(true);
        let data = {
            uuid: props.uuid,
            user_element: "disallowed-file-type-monitoring",
        };
        let response = await monitorServersServices.getDisallowedFiles(data);
        if (response && response.data) {
            setData(response.data);
        }
        if (response && response.message) {
            setMessage(response.message);
        }
        setLoader(false);
    }, [props.uuid]);

    useEffect(() => {
        if (props.uuid) getData();
    }, [props.uuid, getData]);
    let count = 0
    return (
        <div>
            <div className="chart-loader">
                <Loader loader={loader}></Loader>
            </div>
            <div className="card-header">
                <h3>{`${props.serverDisallowedFilesShow ? "Disallowed Files" : "Disallowed Files"}`}</h3>
            </div>
            <div className="card-body">
                <form>
                    {Array.isArray(responseData) &&
                        responseData.map((data, index) => {
                            // setCount(++count)
                          
                            if (props.serverDisallowedFilesShow && count < 12) {
                                count = ++count
                                return (
                                    <div>
                                        <CardTitle style={{ display: "flex" }}> {data.folder_name}</CardTitle>
                                        <div className="pl-3">
                                            {props.showFolderFiles && data["disallowed-files"]?.[data.folder_name]?.map((str, index) => {
                                                // setCount(++count)

                                                if (count < 12 && index < 10) {
                                                    count = ++count
                                                    return <p style={{ fontSize: 12 }} className="mb-1">{str}</p>
                                                }
                                            }
                                            )}
                                        </div>
                                    </div>
                                );
                            } else {
                                return <DisallowedFilesFormListing data={data}></DisallowedFilesFormListing>;
                            }
                        })}
                    {!loader && Array.isArray(responseData) && responseData.length === 0 ? (
                        message === "Disallowed files not configured" ? (
                            <div className="no-data-found">{message}</div>
                        ) : (
                            <div className="no-data-found">No Data Found!</div>
                        )
                    ) : null}
                </form>
            </div>
        </div>
    );
};

export default DisallowedFilesForm;
