import React from 'react'
import MonitorUrlChartOperation from '../../_components/MonitorUrlsChartOperation/MonitorUrlChartOperation'
import { UrlStatusServices } from '../../_services/servicesWithSecondOrigin/index'
import moment from 'moment'

class MonitorUrlsList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            urlDetails: "",
            loader: false,
            myTimezone: "",
            start_datetime: moment(new Date()).add(-0.5 || -24, 'hours').format("YYYY-MM-DD HH:mm:ss"),
            hours: 24,
            minute: 0,
            end_datetime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
            id: "",
            refresh: "",
            urlId: ""
        }
    }

    componentDidMount() {
        let Timezone = localStorage.getItem("Timezone")
        this.setState({
            myTimezone: Timezone
        }, () => {
            if (this.props.urlId) {
                this.getUrlDetails(this.props.urlId)
            }
        })
    }

    componentDidUpdate(prev) {
        if ((this.props.start_datetime !== prev.start_datetime || this.props.end_datetime !== prev.end_datetime) && this.props.dataZoom) {
            let Timezone = localStorage.getItem("Timezone")
            this.setState({
                myTimezone: Timezone
            }, () => {
                if (this.props.urlId) {
                    this.getUrlDetails(this.props.urlId)
                }
            })
        }
    }

    getUrlDetails = async (id) => {
        var d = new Date();
        var n = d.getTimezoneOffset();
        this.setState({ loader: true, id: id, urlDetails: [], refresh: Math.random() })
        let data = {
            "timezone": this.state.myTimezone,
            timediff: n,
            "start_datetime": this.props.start_datetime ? this.props.start_datetime : this.state.start_datetime,
            "end_datetime": this.props.end_datetime ? this.props.end_datetime : this.state.end_datetime,
        }
        let response = await UrlStatusServices.UrlDetails(id, data)
        if (response && response.data) {
            this.setState({
                urlDetails: response.data,
                loader: false,
            })
        } else {
            this.setState({
                urlDetails: "",
                loader: false,
            })
        }
    }

    render() {
        const { urlDetails } = this.state
        return (
            <div className="monitorChartHeight" style={this.props.style ? this.props.style : { maxHeight: "100px", overflow: "hidden" }}>
                <MonitorUrlChartOperation
                    urlDetails={urlDetails.response_time ? urlDetails.response_time : []}
                    title={this.props.title ? this.props.title : ""}
                    tooltip={true}
                    //  toolbox={true}
                    loader={this.state.loader}
                    refresh={this.state.refresh}
                    grid={this.props.grid ? this.props.grid : {
                        top: '0%',
                        left: '10%',
                        right: '0%',
                        bottom: '70%',
                        containLabel: true
                    }}
                    {...this.props}
                />
            </div>
        )
    }
}

export default MonitorUrlsList