import React, {
    useEffect,
    useState
} from 'react'
import LineStackChart from '../Charts/LineStackChart'
import { Loader } from '../../_components/CommonComponents/index'
const MonitorUrlChartOperation = ({ ...props }) => {
    const [xAxis, setXAxis] = useState(false)
    const [yAxis, setyAxis] = useState(false)
    const [series, setSeries] = useState(false)
    const [dataSizeType, setDataSizeType] = useState("ms")
    const [loader, setLoader] = useState(false)
    const [allobj, setAllObj] = useState(true)
    useEffect(() => {

        if (props.urlDetails)
            getGraphDataFromServer()
    }, [props.urlDetails ,props.refresh])

    const getGraphDataFromServer = async () => {
        setXAxis({
            type: 'category',
            data: ["3:00", "4:00"]
        })
        setyAxis({
            type: 'value',
        })
        setSeries([{
            data: [1],
            type: 'line'
        }])

        setLoader(true)
        setTimeout(() => {
            setXAxis({
                type: 'category',
                data: Object.keys(props.urlDetails)
            })

            setyAxis({
                type: 'value',
                // data: Object.values(props.urlDetails)
            })

            setSeries([{
                // name: port.toString(),
                data: Object.values(props.urlDetails),
                type: 'line'
                // smooth: true,
            }])
            setLoader(false)
          
        }, 100)
    }

    return <>
        <div className="chart-loader"> <Loader loader={loader || props.loader}></Loader></div>
        {<LineStackChart
            xAxis={xAxis}
            yAxis={yAxis}
            series={series}
            dataSizeType={dataSizeType}
            allobj={allobj}
            {...props}
            notShow={true}
        ></LineStackChart>}</>
}
export default MonitorUrlChartOperation