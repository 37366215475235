import { RestMethodSecondOrigin } from '../../../_helpers/ApiConfigTwo/RestMethod'
import FilterResponse from '../../../_helpers/FilterResponse'
import { isAuthorized } from '../../../_helpers/Common/Common'

export const domainServices = {
    addDomainData,
    viewDomain,
    updateDomain,
    checkExistDomain,
    seoChartData,
    socialMediaChartData,
    LightHouseChartGetData
}

async function addDomainData(data) {
    try {
        const response = await RestMethodSecondOrigin.POST('/domain/add-domain', data);
        const api_response = FilterResponse(response);
        return api_response;
    }
    catch (error) {
        isAuthorized(error)
        console.log("error detected while fetching data from api");
        return null;
    }
}

async function viewDomain(id) {
    try {
        const response = await RestMethodSecondOrigin.GET('/domain/view-domain?id=' + id);
        const api_response = FilterResponse(response);
        return api_response;
    }
    catch (error) {
        isAuthorized(error)
        console.log("error detected while fetching data from api");
        return null;
    }
}

async function updateDomain(id, data) {
    try {
        const response = await RestMethodSecondOrigin.PUT('/domain/update-domain?id=' + id, data);
        const api_response = FilterResponse(response);
        return api_response;
    }
    catch (error) {
        isAuthorized(error)
        console.log("error detected while fetching data from api");
        return null;
    }
}


async function checkExistDomain(data) {
    try {
        const response = await RestMethodSecondOrigin.POST('/domain/check-domain', data);
        const api_response = FilterResponse(response);
        return api_response;
    }
    catch (error) {
        isAuthorized(error)
        console.log("error detected while fetching data from api");
        return null;
    }
}

async function seoChartData(data) {
    try {
        const response = await RestMethodSecondOrigin.POST('/seo-monitor/seo-chart', data);
        const api_response = FilterResponse(response);
        return api_response;
    }
    catch (error) {
        isAuthorized(error)
        console.log("error detected while fetching data from api");
        return null;
    }
}

async function socialMediaChartData(data, key) {
  
    let Url = ""
    if (key === "social-follower-chart") {
        Url = '/social-media-monitor/social-follower-chart'
    } else if (key === "social-post-chart") {
        Url = "/social-media-monitor/social-post-chart"
    } 
    try {
        const response = await RestMethodSecondOrigin.POST(Url, data);
        const api_response = FilterResponse(response);
        return api_response;
    }
    catch (error) {
        isAuthorized(error)
        console.log("error detected while fetching data from api");
        return null;
    }
}


async function LightHouseChartGetData(data, key) {
    try {
        const response = await RestMethodSecondOrigin.POST('/lighthouse/lighthouse-chart', data);
        const api_response = FilterResponse(response);
        return api_response;
    }
    catch (error) {
        isAuthorized(error)
        console.log("error detected while fetching data from api");
        return null;
    }
}