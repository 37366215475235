import { RestMethodSecondOrigin } from '../../../_helpers/ApiConfigTwo/RestMethod'
import FilterResponse from '../../../_helpers/FilterResponse'
import {isAuthorized} from '../../../_helpers/Common/Common'

export const seoMonitoringServices = {
    addSeoMonitoringData,
    updateSeoMonitoring,
    getAllSeoMonitoring
}

async function addSeoMonitoringData(data) {
    try {
        const response = await RestMethodSecondOrigin.POST('/domain-seo/add-domain-seo',data);
        const api_response = FilterResponse(response);  
        return api_response;
    }
    catch (error) {
        isAuthorized(error)
        return null;
    }
}

async function getAllSeoMonitoring(data) {
    try {
        const response = await RestMethodSecondOrigin.POST('/domain-seo/all-domain-seo',data);
        const api_response = FilterResponse(response);  
        return api_response;
    }
    catch (error) {
        isAuthorized(error)
        return null;
    }
}

async function updateSeoMonitoring(data) {
    try {
        const response = await RestMethodSecondOrigin.POST('/domain-seo/update-domain-seo',data);
        const api_response = FilterResponse(response);  
        return api_response;
    }
    catch (error) {
        isAuthorized(error)
        return null;
    }
}