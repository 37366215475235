import React, {
    useEffect,
    useState
} from 'react'
import moment from 'moment'
import LineStackChart from '../Charts/LineStackChart'
import { graphService } from '../../_services/servicesWithSecondOrigin/index'
import { Loader } from '../../_components/CommonComponents/index'
const ChartOperationsNginx = ({ ...props }) => {
    const [xAxis, setXAxis] = useState(false)
    const [yAxis, setyAxis] = useState(false)
    const [series, setSeries] = useState(false)
    const [setUnSet, setUnSetUI] = useState(true)
    const [dataSizeType, setDataSizeType] = useState("")
    const [loader, setLoader] = useState(true)
    const [legend, setLegend] = useState([])
    const [LegendObj, setLegendObj] = useState(false)
    useEffect(() => {
        if (props.outer) {
            getGraphDataFromServerOuterChart()
        } else
            if (props.uuid && !props.chartData) { getGraphDataFromServer() }
    }, [props.uuid, props.active, props.start_datetime, props.end_datetime, props.isRefresh, props.mytimezone, props.chartData,props.unique_id])

    const getGraphDataFromServerOuterChart = async () => {
        setLoader(true)

        setXAxis({
            type: 'category',
            triggerEvent: true,
            data: []
        })

        setSeries([{
            name: "",
            data: [0],
            type: 'line',
            smooth: true,
        }])


        setUnSetUI(false)
        if (Array.isArray(props.timestemp)) {
            setXAxis({
                type: 'category',
                data: props.timestemp
            })
        } else {
            setXAxis({
                type: 'category',
                triggerEvent: true,
                data: []
            })
        }
        const Series = []
        let inDataSize = ""
        if (props.chartData) {
            let innerData = Object.keys(props.chartData)
            let allinnerData = props.chartData
            Array.isArray(innerData) && innerData.map((object) => {
                if (Array.isArray(allinnerData[object])) {
                    setDataSizeType("")
                    allinnerData[object].map((check, index) => {
                        allinnerData[object][index] = check !== "" ? (parseFloat(check)).toFixed(2) : ""
                    })
                }

                if (props.isLegend) {
                    setLegend(innerData)
                    setLegendObj({
                        data: props.legend,
                        type: "scroll",
                        horizontalAlign: 'left',
                        right: 400,
                        left: 100,
                        padding: 50
                    })
                }

                if (allinnerData && Array.isArray(allinnerData[object]) && allinnerData[object].length > 0) {
                    Series.push({
                        name: object,
                        data: allinnerData[object],
                        type: 'line',
                        smooth: true,
                    })
                }
            })
        }

        setUnSetUI(true)
        if (Series.length === 0) {
            setSeries([{
                name: "",
                data: [0],
                type: 'line',
                smooth: true,
            }])
        } else {
            setSeries(Series)
        }
        setLoader(false)
    }


    const getGraphDataFromServer = async () => {
        var d = new Date();
        var n = d.getTimezoneOffset();
        setLoader(true)
        let data = {
            "uuid": props.uuid,
            "user_element": "apache_log_data",
            "start_datetime": props.start_datetime ? props.start_datetime : moment(new Date()).add(-props.active || -4, 'hours').format("YYYY-MM-DD HH:mm:ss"),
            "end_datetime": props.end_datetime ? props.end_datetime : moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
            "timezone": props.mytimezone,
            timediff: n,
            //"unique_id": "00000000-0000-0000-0000-0641a6ca2d4c"
        }

        if(props.unique_id){
            data.unique_id= props.unique_id
        }

        setXAxis({
            type: 'category',
            triggerEvent: true,
            data: []
        })

        setSeries([{
            name: "",
            data: [0],
            type: 'line',
            smooth: true,
        }])

        let response = await graphService.getNgnixGraphData(data)
        if (response && response.data) {
            let responseData = response.data;

            const Series = [];
            let inDataSize = "";

            let innerData = [];
            let allinnerData = {};


            if (props.InnerDataType) {
                if (Array.isArray(props.InnerDataType)) {
                    // let data = {};

                    for (const element of props.InnerDataType) {
                        if (responseData.hasOwnProperty(element)) {
                            let data = responseData[element];
                            if (data) {
                                innerData = innerData.concat(Object.keys(data));

                                allinnerData = {
                                    ...allinnerData,
                                    ...data
                                }
                            }
                        }
                    }
                } else {
                    innerData = Object.keys(response.data[props.InnerDataType])
                    allinnerData = response.data[props.InnerDataType]
                }
            }

            setUnSetUI(false)
            if (Array.isArray(response.data.timestamp)) {
                setXAxis({
                    type: 'category',
                    data: response.data.timestamp
                })
            } else {
                setXAxis({
                    type: 'category',
                    triggerEvent: true,
                    data: []
                })
            }


            Array.isArray(innerData) && innerData.map((object) => {
                if (Array.isArray(allinnerData[object])) {
                    setDataSizeType("")
                    allinnerData[object].map((check, index) => {
                        allinnerData[object][index] = check !== "" ? (parseFloat(check)).toFixed(2) : ""
                    })
                }

                if (props.isLegend) {
                    setLegend(innerData)
                    setLegendObj({
                        data: props.legend,
                        type: "scroll",
                        horizontalAlign: 'left',
                        right: 400,
                        left: 100,
                        padding: 50
                    })
                }


                if (allinnerData && Array.isArray(allinnerData[object]) && allinnerData[object].length > 0) {
                    Series.push({
                        name: object,
                        data: allinnerData[object],
                        type: 'line',
                        smooth: true,
                    })
                }
            })

            setUnSetUI(true)
            if (Series.length === 0) {
                setSeries([{
                    name: "",
                    data: [0],
                    type: 'line',
                    smooth: true,
                }])
            } else {
                setSeries(Series)
            }
            setLoader(false)
        } else {
            setSeries([{
                name: "",
                data: [0],
                type: 'line',
                smooth: true,
            }])
            setLoader(false)
        }
    }

    return <>
        <div className="chart-loader"> <Loader loader={loader}></Loader></div>
        {setUnSet && <LineStackChart
            xAxis={xAxis}
            yAxis={yAxis}
            series={series}
            dataSizeType={dataSizeType}
            legend={legend}
            legendObj={LegendObj}
            {...props}
        ></LineStackChart>}</>
}
export default ChartOperationsNginx