import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import moment from 'moment'
import "react-datepicker/dist/react-datepicker.css";

const DateTime = ({ ...props }) => {
    const [startDate, setstartDate] = useState(null)

    useEffect(() => {
        if (props.dateTime) {
            setstartDate(new Date(moment(props.dateTime, 'YYYY-MM-DD HH:mm:ss')))
        }
        if (props.dateTime === null) {
            setstartDate(null)
        }
    }, [props.dateTime])


    const filterPassedDate = time => {

        const currentDate = new Date();
        const selectedDate = new Date(time);
        return currentDate > selectedDate && selectedDate >= currentDate.setMonth(currentDate.getMonth() - 1);
    }

    return (
        <DatePicker
            selected={startDate}
            onChange={date => (props.setDateTime ? props.setDateTime(date) : null)}
            timeInputLabel="Time:"
            dateFormat="MM/dd/yyyy h:mm aa"
            showTimeInput
            filterDate={props.filterPassedDate ? props.filterPassedDate : filterPassedDate}
            className={props.className ? props.className : ""}
            // shouldCloseOnSelect={false}
            placeholderText="Date"
        />
    );
};

export default DateTime