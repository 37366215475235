import moment from "moment";
import React, { useEffect, useState, useMemo } from "react";
import { ModalBody, Modal } from "react-bootstrap";
import { AiOutlineLineChart } from "react-icons/ai";
import { MdRefresh, MdRemoveRedEye } from "react-icons/md";
import { Button } from "reactstrap";

import LineStackChart from "../../_components/Charts/LineStackChart";
import ModalDraggable from "../../_components/ModalDraggable/ModalDraggable";
import { DateTime, Loader } from "../../_components/CommonComponents";
import { graphService } from "../../_services/servicesWithSecondOrigin";

let activeButtons = {
  Cpu: true,
  memory: false,
  diskIO: false,
};

export const CpuMemoMadal = (props) => {
  const [show, setShow] = useState(false);
  const showModal = () => setShow(true);
  const onHide = () => setShow(false);
  const [setUnSet, setUnSetUI] = useState(true);
  const [loader, setLoader] = useState(false);
  const [cup, setCPU] = useState([]);
  const [mem, setMem] = useState([]);
  const [start_datetime, setstartDate] = useState(
    moment(new Date()).add(-4, "hours").format("YYYY-MM-DD HH:mm:ss")
  );
  const [end_datetime, setEndDate] = useState(
    moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
  );

  const [refresh, setRefresh] = useState("");
  const [active, setActive] = useState(activeButtons);

  const [cellContent, setCellContent] = useState("");

  const [time, setTime] = useState("");
  const [timestamp, setTimestemp] = useState("");

  useEffect(() => {
    if (show) {
      setCellContent(props.cellContent);
      topProcessData();
      getPidProcess();
    }
  }, [show]);

  const onClickRefresh = async () => {
    setstartDate(
      moment(new Date()).add(-4, "hours").format("YYYY-MM-DD HH:mm:ss")
    );
    setEndDate(moment(new Date()).format("YYYY-MM-DD HH:mm:ss"));
    setRefresh(Math.random());
  };

  const getPidProcess = () => {
    graphService
      .getPidDetails({
        uuid: props.uuid,
        user_element: "top_process",
        start_datetime: start_datetime,
        end_datetime: end_datetime,
        process: cellContent ? cellContent : props.cellContent,
        timezone: localStorage.getItem("Timezone"),
      })
      .then((res) => {
        setTime(res?.data);
      });
  };

  const topProcessData = () => {
    setLoader(true);
    graphService
      .getProcessChartsData({
        uuid: props.uuid,
        user_element: "top_process",
        start_datetime: start_datetime,
        end_datetime: end_datetime,
        process: cellContent ? cellContent : props.cellContent,
        timezone: localStorage.getItem("Timezone"),
      })
      .then((res) => {
        setUnSetUI(false);
        if (res?.data) {
          setTimestemp(res.data.timestamp);
          // setXAxis({
          //     type: "category",
          //     data: res.data.timestamp,
          // })
          setCPU(res.data.CPU);
          setMem(res.data.MEM);
        }
        setUnSetUI(true);
        setLoader(false);
      });
  };

  useEffect(() => {
    if (refresh) {
      getPidProcess();
      topProcessData();
    }
  }, [refresh]);

  const onClickGetNewData = () => {
    // getPidProcess();
    // topProcessData();
    setRefresh(Math.random());
  };

  const setStartDateTime = (date) => {
    setstartDate(moment(date).format("YYYY-MM-DD HH:mm:ss"));
    // this.setState(
    //     {
    //         start_datetime: moment(date).format("YYYY-MM-DD HH:mm:ss"),
    //     },
    //     () => {
    //         var a = moment(this.state.end_datetime, "YYYY-MM-DD HH:mm:ss");
    //         var b = moment(this.state.start_datetime, "YYYY-MM-DD HH:mm:ss");
    //         let c = a.diff(b, "days");
    //         if (c > 31) {
    //             this.setState({
    //                 end_datetime: b.add(1, "M").add(-1, "D").format("YYYY-MM-DD HH:mm:ss"),
    //             });
    //         }
    //     }
    // );
  };

  const setEndDateTime = (date) => {
    setEndDate(moment(date).format("YYYY-MM-DD HH:mm:ss"));
    // this.setState(
    //     {
    //         end_datetime: moment(date).format("YYYY-MM-DD HH:mm:ss"),
    //     },
    //     () => {
    //         var a = moment(this.state.end_datetime, "YYYY-MM-DD HH:mm:ss");
    //         var b = moment(this.state.start_datetime, "YYYY-MM-DD HH:mm:ss");
    //         let c = a.diff(b, "days");
    //         if (c > 31) {
    //             this.setState({
    //                 start_datetime: a.add(-1, "M").format("YYYY-MM-DD HH:mm:ss"),
    //             });
    //         }
    //     }
    // );
  };

  const filterPassedStartDate = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);
    const end_datetime_ = new Date(moment(end_datetime));
    return currentDate > selectedDate && end_datetime_ >= selectedDate;
  };

  const filterPassedEndDate = (time) => {
    const currentDate = new Date();
    const _start_datetime = new Date(moment(start_datetime));
    const selectedDate = new Date(time);
    return currentDate > selectedDate && _start_datetime <= selectedDate;
  };

  const onChangeCellContent = (e) => setCellContent(e.target.value);

  const startdate_endDate = useMemo(() => {
    if (time) {
      return (
        <span className="d-flex justify-content-center">
          {!time.startdatetime && !time?.enddatetime ? (
            <p>
              Process "{props.COMMAND}" was started earlier than 24 hours and
              still in running.{" "}
            </p>
          ) : time.startdatetime && !time?.enddatetime ? (
            <p>
              Process "{props.COMMAND}" started earlier than{" "}
              {time.startdatetime} and still running{" "}
            </p>
          ) : !time.startdatetime && time?.enddatetime ? (
            <p>
              Process "{props.COMMAND}" was started earlier than 24 hours and
              end at {time?.enddatetime}
            </p>
          ) : time.startdatetime && time?.enddatetime ? (
            <p>
              Process "{props.COMMAND}" started at {time.startdatetime} and end
              at {time?.enddatetime}
            </p>
          ) : null}
        </span>
      );
    }

    return null;
  }, [time]);

  return (
    <>
      <span className="d-flex">
        {props.cellContent}
        <AiOutlineLineChart
          size="13"
          color="blue"
          onClick={showModal}
          className="cursor-pointer"
        />
      </span>
      <Modal dialogAs={ModalDraggable} size="lg" show={show} onHide={onHide}>
        <Modal.Header onHide={onHide} closeButton>
          <span className="d-flex">
            {/* <span>Process Details</span> */}
            {startdate_endDate}
          </span>
        </Modal.Header>
        <ModalBody>
          <div className="container">
            <div className="row ">
              <div className="col-md-10">
                <div className="select_startdatetime">
                  <div className="row align-items-end">
                    <div className="col-md-4">
                      <label>PID</label>
                      <div>
                        <input
                          value={cellContent}
                          onChange={onChangeCellContent}
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-md-4 cpu-modal-date">
                      <label>Start Date Time</label>
                      <DateTime
                        className="form-control"
                        dateTime={start_datetime}
                        setDateTime={setStartDateTime}
                        filterPassedDate={filterPassedStartDate}></DateTime>
                    </div>
                    <div className="col-md-4 cpu-modal-date">
                      <label>End Date Time</label>
                      <DateTime
                        className="form-control"
                        dateTime={end_datetime}
                        setDateTime={setEndDateTime}
                        filterPassedDate={filterPassedEndDate}></DateTime>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-2">
                <div className="mt-3 d-flex" style={{ paddingBottom: "1px" }}>
                  <Button
                    className="iconBtn btn btn-secondary p-0 mr-1"
                    onClick={() => onClickGetNewData()}>
                    <MdRemoveRedEye></MdRemoveRedEye>
                  </Button>
                  <Button
                    className="iconBtn btn btn-secondary p-0 ml-1"
                    onClick={() => onClickRefresh()}>
                    <MdRefresh></MdRefresh>
                  </Button>
                </div>
              </div>
            </div>
            <div className="charts-screen-tabs">
              <Button
                color={active.Cpu ? "primary" : ""}
                onClick={() =>
                  setActive((prev) => ({ ...prev, Cpu: !prev.Cpu }))
                }>
                CPU
              </Button>
              <Button
                color={active.memory ? "primary" : ""}
                onClick={() =>
                  setActive((prev) => ({ ...prev, memory: !prev.memory }))
                }>
                Memory
              </Button>

              <Button
                color={active.diskIO ? "primary" : ""}
                onClick={() =>
                  setActive((prev) => ({ ...prev, diskIO: !prev.diskIO }))
                }>
                Disk IO
              </Button>
            </div>
            {active.Cpu && (
              <div>
                <div className="card overflow-visible">
                  <div className="card-header justify-content-start">
                    <h3 className="mr-3">CPU</h3>
                  </div>
                  {setUnSet && (
                    <ChartsOpratoins
                      series={[
                        {
                          name: "CPU",
                          data: cup,
                          type: "line",
                          smooth: true,
                        },
                      ]}
                      xAxis={{
                        type: "category",
                        data: timestamp,
                      }}
                      loader={loader}
                      dataSizeType="%"
                      {...props}></ChartsOpratoins>
                  )}
                </div>
              </div>
            )}

            {active.memory && (
              <div>
                <div className="card overflow-visible">
                  <div className="card-header justify-content-start">
                    <h3 className="mr-3">Memory</h3>
                  </div>
                  {setUnSet && (
                    <ChartsOpratoins
                      series={[
                        {
                          name: "Memory",
                          data: mem,
                          type: "line",
                          smooth: true,
                        },
                      ]}
                      xAxis={{
                        type: "category",
                        data: timestamp,
                      }}
                      {...props}
                      loader={loader}
                      dataSizeType="%"></ChartsOpratoins>
                  )}
                </div>
              </div>
            )}

            {active.diskIO && (
              <div>
                <div className="card overflow-visible">
                  <div className="card-header justify-content-start">
                    <h3 className="mr-3">DISK IO PROCESS</h3>
                  </div>
                  <ChartsOpratoinsDiskIO
                    {...props}
                    start_datetime={start_datetime}
                    end_datetime={end_datetime}
                    refresh={refresh}
                    cellContent={cellContent}
                    dataSizeType="%"></ChartsOpratoinsDiskIO>
                </div>
              </div>
            )}
            {!active.diskIO && !active.memory && !active.Cpu && (
              <div>
                <div className="card overflow-visible">
                  <div className="card-header justify-content-start">
                    <h3 className="mr-3">No Charts Found </h3>
                  </div>
                </div>
              </div>
            )}
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

const ChartsOpratoinsDiskIO = (props) => {
  const [xAxis, setXAxis] = useState(false);
  const [series, setSeries] = useState(false);
  const [setUnSet, setUnSetUI] = useState(true);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    diskIOData();
  }, [props.refresh]);

  const diskIOData = () => {
    setLoader(true);
    graphService
      .getDiskIOProcessChartsData({
        uuid: props.uuid,
        user_element: "actual_disk_io_process",
        start_datetime: props.start_datetime,
        end_datetime: props.end_datetime,
        process: props.cellContent,
        timezone: localStorage.getItem("Timezone"),
      })
      .then((res) => {
        setUnSetUI(false);

        if (res?.data) {
          setXAxis({
            type: "category",
            data: res.data.timestamp,
          });
          let keysArr = Object.keys(res.data);
          let seriess = [];
          keysArr.map((obj) => {
            if (obj !== "timestamp")
              seriess.push({
                name: obj,
                data: res?.data[obj],
                type: "line",
                smooth: true,
              });
          });

          setSeries(seriess);
          setUnSetUI(true);
          setLoader(false);
        } else {
          setUnSetUI(true);
          setLoader(false);
        }
      });
  };

  return (
    <div className="chartInnerDiv">
      <div className="chart-loader">
        <Loader loader={loader}></Loader>
      </div>
      {setUnSet && (
        <LineStackChart
          xAxis={xAxis}
          yAxis={false}
          series={series}
          dataSizeType={"KB/s"}
          tooltip></LineStackChart>
      )}
    </div>
  );
};

const ChartsOpratoins = (props) => {
  const [xAxis, setXAxis] = useState(false);
  const [series, setSeries] = useState(false);

  useEffect(() => {
    setXAxis(props.xAxis);
    setSeries(props.series);
  }, [props]);

  return (
    <div className="chartInnerDiv">
      <div className="chart-loader">
        <Loader loader={props.loader}></Loader>
      </div>
      <LineStackChart
        xAxis={xAxis}
        yAxis={false}
        series={series}
        tooltip
        dataSizeType={props.dataSizeType}></LineStackChart>
    </div>
  );
};
