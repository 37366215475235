import React, { useState } from 'react'
import { domainService } from '../../_services/DomainServices/domainServices'
import { Button, Form, FormGroup, Label, Input, FormText, Row, Col } from 'reactstrap';
import SpinnerForTable from '../../_components/CommonComponents/Loader/SpinnerForTable'
import { CheckBox } from '../CommonComponents/index'
const DnsInformation = ({ dnsInformation, cdn_loader, ...props }) => {
    const dns_response = dnsInformation
    return <Row>
        <Col className="col-lg-12 col-md-12">
            {cdn_loader ? <div className="domain-loader loader_div_width" style={props.style}>
                <SpinnerForTable loader={cdn_loader} style={props.style} />
            </div> : <div className="loader_div_width"></div>}
        </Col>
        <Col>
            {props.title && <label className="dns_table">{dns_response && <CheckBox value={props.dnsAllChecked} onChange={props.onClickAllDnsChecked} />} DNS Information</label>}
           <div className="table-responsive">
            <table className="table dns_table">
                <thead>
                    <th scope="col">#</th>
                    <th scope="col">Type</th>
                    <th scope="col">Name</th>
                    <th scope="col">Content</th>
                </thead>
                <tbody>
                    {dns_response && Array.isArray(dns_response) && dns_response.map((obj, index) => {
                        return <tr>
                            <td>
                                {obj.name && <CheckBox value={obj.monitor} onChange={e => props.onClickSingleDnsChecked(e, index)} />}
                            </td>
                            <td>
                                {obj.type}
                            </td>
                            <td>
                                {obj.name}
                            </td>
                            <td>
                                {obj.content}
                            </td>
                        </tr>
                    })}
                </tbody>
            </table>
            </div>
        </Col>
    </Row>
}

export default DnsInformation


















// import React, { useState } from 'react'
// import { domainService } from '../../_services/DomainServices/domainServices'
// import { Button, Form, FormGroup, Label, Input, FormText, Row, Col } from 'reactstrap';

// const DnsInformation = ({ dnsInformation }) => {
//     const dns_response = dnsInformation
//     return <>
//         <div className="domain_information_table">
//             <label>DNS Information</label>
//             <table className="table">
//                 <thead>
//                     <tr>
//                         {dns_response && Object.keys(dns_response).map((data) => {
//                             return <th scope="col">{data}</th>
//                         })}
//                     </tr>
//                 </thead>
//                 <tbody>
//                     <tr>
//                         {dns_response && Object.keys(dns_response).map((KEY) => {
//                             return <td>{Array.isArray(dns_response[KEY]) ? dns_response[KEY].map((data) => {
//                                 return <Row>
//                                     <Col>
//                                         {data}
//                                     </Col>
//                                 </Row>
//                             }
//                             ) : dns_response[KEY] && dns_response[KEY]
//                             }</td>
//                         })}
//                     </tr>
//                 </tbody>
//             </table>
//         </div></>
// }

// export default DnsInformation