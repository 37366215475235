import React, { Fragment, useState, useEffect } from "react";
import { Form, Input } from "reactstrap";
import { notificationChannelServices } from "../../_services/servicesWithSecondOrigin/index";
import { Constants } from "./Constants";
import { Validation } from "../../_helpers/validation/Validation";
import toastr from "toastr";
import { Error, MButton, Select } from "../CommonComponents/index";
import Switch from "../CommonComponents/SwitchBotton/SwitchBotton";
const NotificationChannelForm = ({ ...props }) => {
  const [masterChannel, setMasterChannel] = useState([]);
  const [master_channel_id, setChannelMasterId] = useState("");
  const [custom_channel_name, setChannelName] = useState("");
  const [allData, setAllData] = useState("");
  const [in_default, setAutoNotification] = useState("0");
  const [error, setError] = useState({});
  const [email, setEmail] = useState("");
  const [Slack, setSlack] = useState("");
  const [SlackState, setSlackState] = useState("");
  const [isEmail, setIsEmail] = useState("");
  const [emailError, setErrorEmail] = useState({});
  useEffect(() => {
    if (props.update) {
      getDataFromServer(props.id);
    }
  }, [props.update]);

  useEffect(() => {
    getMasterChannelData();
  }, []);

  const getMasterChannelData = async () => {
    let response =
      await notificationChannelServices.getAllMasterNotificationChannel();
    if (response && response.data) {
      let data = [];
      Array.isArray(response.data) &&
        response.data.map((obj) => {
          data.push({
            label: obj.channel_name,
            value: obj.id,
          });
        });
      setMasterChannel(data);
    }
  };

  const getDataFromServer = async (id) => {
    let response = await notificationChannelServices.getNotificationChannel(id);
    if (response && response.data) {
      if (response.data.id) {
        if (response.data.master_channel_id) {
          setChannelMasterId(response.data.master_channel_id.toString());
        }
        setChannelName(response.data.custom_channel_name);
        if (response.data.in_default) {
          setAutoNotification(response.data.in_default.toString());
        }
        let TypeKey = Object.keys(response.data.channel_variables);
        if (TypeKey.includes("email") && response.data.channel_variables) {
          setIsEmail("Email");
          response.data.channel_variables.email &&
            setEmail(response.data.channel_variables.email);
        }
        if (TypeKey.includes("slack") && response.data.channel_variables) {
          setSlack("Slack");
          response.data.channel_variables.slack &&
            setSlackState(response.data.channel_variables.slack);
        }
        setAllData(response.data);
      }
    }
  };

  const onSubmit = async (e) => {
    let emailObj = { email: email };
    let slackObj = { slack: SlackState };
    e.preventDefault();
    let data = {
      master_channel_id: master_channel_id,
      custom_channel_name: custom_channel_name,
      channel_variables: isEmail
        ? emailObj
        : Slack
        ? slackObj
        : allData && allData.channel_variables
        ? allData.channel_variables
        : {},
      status:
        allData && allData.status !== undefined
          ? allData.status
          : isEmail
          ? 1
          : 2,
      in_default: in_default,
    };

    let flag = 0;
    let errobj = Validation(data, Constants().rules);
    Object.keys(errobj).map((index) => {
      if (errobj[index] !== "") {
        flag = 1;
      }
    });

    if (isEmail) {
      let errobjEmail = Validation({ email: email }, Constants().emailRule);
      Object.keys(errobjEmail).map((index) => {
        if (errobjEmail[index] !== "") {
          flag = 1;
        }
      });
      setErrorEmail(errobjEmail);
    }
    if (Slack) {
      let errobjEmail = Validation(
        { slack: SlackState },
        Constants().slackRule
      );
      Object.keys(errobjEmail).map((index) => {
        if (errobjEmail[index] !== "") {
          flag = 1;
        }
      });
      setErrorEmail(errobjEmail);
    }

    setError(errobj);
    if (flag === 0) {
      let response = "";
      if (props.update) {
        response = await notificationChannelServices.updateNotificationChannel(
          props.id,
          data
        );
      } else {
        response = await notificationChannelServices.addNotificationChannel(
          data
        );
      }
      if (response) {
        if (response && response.message) {
          toastr.success(response.message);
        }
        if (response && response.status === 200) {
          if (props.callback) {
            props.callback();
          } else {
            props.history.push("/notification-channel");
          }
        }
      }
    }
  };

  const onChangeEmail = () => {
    let allDataobj = { ...allData };
    allDataobj.channel_variables = {};
    setAllData(allDataobj);
  };

  return (
    <Fragment>
      <div className="card-header">
        <h3> {props.update ? "Update" : "Add"} Notification Channel</h3>
        {props.popup ? (
          ""
        ) : (
          <MButton
            className=" addEditBtn"
            onClick={() => props.history.push("/notification-channel")}
            label="Back"
          ></MButton>
        )}
      </div>
      <div className="card-body">
        <Form onSubmit={(e) => onSubmit(e)}>
          <div className="mt-3">
            <label>Master Channel</label>
            <Select
              options={masterChannel}
              setSelectedOption={(e) => {
                setChannelMasterId(e.value);
                setIsEmail(e.label === "Email" ? e.label : "");
                setSlack(e.label === "Slack" ? e.label : "");
                onChangeEmail();
              }}
              selectedOption={master_channel_id}
            ></Select>
            {error &&
              error.master_channel_id &&
              error.master_channel_id !== "" && (
                <Error error={error.master_channel_id}></Error>
              )}
          </div>
          <div className="mt-3">
            <label>Channel Name</label>
            <Input
              placeholder="Channel Name"
              value={custom_channel_name}
              onChange={(e) => setChannelName(e.target.value)}
            ></Input>
            {error &&
              error.custom_channel_name &&
              error.custom_channel_name !== "" && (
                <Error error={error.custom_channel_name}></Error>
              )}
          </div>
          {isEmail === "Email" && (
            <div className="mt-3">
              <label>Email</label>
              <Input
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              ></Input>
              {emailError && emailError.email && (
                <Error error={emailError.email}></Error>
              )}
            </div>
          )}
          {Slack === "Slack" && (
            <div className="mt-3">
              <label>Slack Webhook</label>
              <Input
                placeholder="Slack Webhook"
                value={SlackState}
                onChange={(e) => setSlackState(e.target.value)}
              ></Input>
              {emailError && emailError.slack && (
                <Error error={emailError.slack}></Error>
              )}
            </div>
          )}
          <div className="mt-3">
            <label>Auto Notification Setting</label>
            <div>
              <Switch
                onClick={(e) => setAutoNotification(e ? "1" : "0")}
                checked={in_default === "1" ? true : false}
              ></Switch>
            </div>
          </div>
          <MButton
            label={props.update ? "Update" : "Save"}
            className="mt-3 bluebutton "
            onClick={(e) => onSubmit(e)}
          ></MButton>
        </Form>
      </div>
    </Fragment>
  );
};

export default NotificationChannelForm;
