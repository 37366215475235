import React from "react";
import "./Loader.css";
import { Spinner } from "reactstrap";
export default class Loader extends React.Component {
  render() {
    return (
      <>
        {this.props.isLoading ? (
          <div className="loader-box">
            <div className="formwrapper">
              <p className="d-block text-center">
                {/* <img src={logo} alt="logo" className="img-fluid-image" /> */}
                <Spinner animation="border" variant="secoundary" color="#fff" />
              </p>
              <p style={{ color: "#fff" }}>Loading ...</p>
            </div>
          </div>
        ) : null}
      </>
    );
  }
}
