import React, { Fragment, useState, useEffect } from "react";
import { Notes, AppInstallSteps } from "../../CommonComponents/index";
import { monitorServersServices } from "../../../_services/servicesWithSecondOrigin/index";
import { BiLineChart } from "react-icons/bi";
import { RiSettings2Line, RiDeleteBinLine } from "react-icons/ri";
import { MdEdit } from "react-icons/md";
import { ServerShare } from "../index";
import { Validation } from "../../../_helpers/validation/Validation";
import toastr from "toastr";
import { confirmAlert } from "react-confirm-alert";
import PageLoader from "../../CommonComponents/PageLoader/Loader";
import SecretKeyPopup from "../../SecretKeyPopup/SecretKeyPopup";
import { UsageChart } from "../../../_components/MonitorServerChart/index";
import OpenNotificationsPopup from "../../../Pages/NotificationChannel/NotificationsPopup";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import nginx_img from "../../../assets/img/ServerIcon/nginx.png";
import apache_img from "../../../assets/img/ServerIcon/apache.jpg";
import aws_img from "../../../assets/img/ServerIcon/aws.jpg";
import backup_img from "../../../assets/img/ServerIcon/database.png";
import folder_img from "../../../assets/img/ServerIcon/folder.jpg";
import googleFiles_img from "../../../assets/img/ServerIcon/not-allowed-folder.png";
import greenLock_img from "../../../assets/img/ServerIcon/monitor.png";
import mysql_img from "../../../assets/img/ServerIcon/mysql.jpg";

import server_on from "../../../assets/img/serveron.png";
import server_off from "../../../assets/img/serveroff.png";
import { Tab, Nav } from "react-bootstrap";
import CurrentLoggedIn from "../../../_components/CommonComponents/ServerCurrentLoggedIn/CurrentLoggedInModel";
import AddMonitorServerPopup from "./AddNewMonitorServer";
import defaultMonitorServers from "../../../assets/img/blank-monitor-servers.png";
import topArrow from "../../../assets/img/arrow-2.png";
import { FiCopy } from "react-icons/fi";
import { MdRefresh } from "react-icons/md";
import { AiFillAccountBook } from "react-icons/ai";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import AddServer from "../../../Pages/MonitorServers/AddServer";
import { useShareMoniterServer } from "../../../_hooks";
import { objectToQuery } from "../../../_helpers/Common/Common";

const MonitorServerListing = ({ ...props }) => {
  const [data, setData] = useState([]);
  const [id, setId] = useState(null);
  const [Shareddata, setShareddata] = useState([]);
  const [isLoading, setLoader] = useState(true);
  const [mytimezone, setMytimezone] = useState("");
  const [isInactive, setInactive] = useState(false);
  const [modal, setModal] = useState(false);
  const [secretkey, setKey] = useState("");
  const share = useShareMoniterServer();
  const toggle = () =>
    setModal((prev) => {
      if (prev) setId(null);

      return !prev;
    });

  useEffect(() => {
    let Timezone = localStorage.getItem("Timezone");
    setMytimezone(Timezone);
    getData();
    getKey();
  }, []);

  const getKey = async () => {
    let response = await monitorServersServices.getKey();
    if (response && response.data) {
      setKey(response.data.secret_key);
    }
  };

  const getData = async (inactive) => {
    setLoader(true);
    setData([]);
    let response = "";
    if (inactive) {
      setInactive(true);
      response = await monitorServersServices.getAllInactiveMonitorServer();
    } else {
      setInactive(false);
      response = await monitorServersServices.getAllMonitorServer();
    }

    if (response && response.data && Array.isArray(response.data)) {
      setData(
        response.data.sort((a, b) => {
          if (a.server_name.toLowerCase() < b.server_name.toLowerCase()) {
            return -1;
          }
          if (a.server_name.toLowerCase() > b.server_name.toLowerCase()) {
            return 1;
          }
          return 0;
        })
      );
    }
    if (response && response.Shareddata && Array.isArray(response.Shareddata)) {
      setShareddata(
        response.Shareddata.sort((a, b) => {
          if (a.server_name.toLowerCase() < b.server_name.toLowerCase()) {
            return -1;
          }
          if (a.server_name.toLowerCase() > b.server_name.toLowerCase()) {
            return 1;
          }
          return 0;
        })
      );
    }
    setLoader(false);
  };

  const refresh = () => {
    getData();
    getKey();
  };

  //   const share = async (id, email, callBack) => {
  //     let rule = [
  //       {
  //         field_name: "email",
  //         label: "Email",
  //         type: "email",
  //         isRequired: true,
  //       },
  //     ];
  //     let errobj = Validation({ email: email }, rule);
  //     if (errobj && errobj.email === "") {
  //       let data = {
  //         email: email,
  //       };
  //       let response = await monitorServersServices.shareMonitorServers(id, data);
  //       if (response && response.message) {
  //         toastr.warning(response.message);
  //       }
  //       if (callBack && response && response.status === 200) {
  //         callBack();
  //       }
  //     } else {
  //       toastr.warning(errobj.email);
  //     }
  //   };

  const deleteServer = (id) => {
    confirmAlert({
      title: "Confirm to Delete",
      message: <p>Are you sure to do this.</p>,
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            let response = await monitorServersServices.deleteMonitorServers(
              id
            );
            if (response && response.message) {
              toastr.info(response.message);
            }
            if (response && response.status === 200) {
              getData();
            }
          },
        },
        {
          label: "No",
          onClick: () => console.log("No"),
        },
      ],
    });
  };

  const monitorServerSharedByOtherRemove = async (uuid) => {
    confirmAlert({
      title: "Confirm to Delete",
      message: <p>Are you sure to do this.</p>,
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            let data = {
              target_id: uuid,
            };
            let response =
              await monitorServersServices.monitorServerSharedByOtherRemove(
                data
              );
            if (response && response.message) {
              toastr.success(response.message);
              getData();
            }
          },
        },
        {
          label: "No",
          onClick: () => console.log("No"),
        },
      ],
    });
  };

  const copyText = (key) => {
    if (key) {
      navigator.clipboard.writeText(key);
      toastr.success("Copied!");
    }
  };

  const onClickGotoCharts = (obj, key, setting) => {
    localStorage.setItem("moniterServerScroll", key);
    let object = {
      id: obj.id,
      uuid: obj.uuid,
      update: true,
      server_name: obj.server_name,
    };
    props.history.push(
      "/monitor-server/charts" + objectToQuery(object),
      object
    );
  };

  const onClickGotoSetting = (obj, key, setting) => {
    localStorage.setItem("moniterServerScroll", key);
    props.history.push("/monitor-server/server-setting", {
      id: obj.id,
      uuid: obj.uuid,
      server_name: obj.server_name,
      update: true,
    });
  };

  return (
    <Fragment>
      <Tab.Container id="left-tabs-example" defaultActiveKey="1">
        <Nav variant="pills" className="serverInfoTabs">
          <Nav.Item>
            <Nav.Link eventKey="1" onClick={() => getData()}>
              Active Server
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="2" onClick={() => getData(true)}>
              Inactive Server
            </Nav.Link>
          </Nav.Item>
        </Nav>
        {/* <Tab.Content className="p-0">
            <Tab.Pane eventKey="1">
            </Tab.Pane>
            <Tab.Pane eventKey="2">
            </Tab.Pane>
        </Tab.Content> */}
      </Tab.Container>
      <div className="card p-0">
        <div className="card-header">
          <h3>Monitor Servers</h3>
          {!isInactive && (
            <div className="card-header-buttons">
              <OverlayTrigger
                key={"left"}
                placement={"left"}
                overlay={<Tooltip>Refresh</Tooltip>}>
                <MdRefresh
                  size="20"
                  className="iconBtn"
                  style={{ marginRight: "10px" }}
                  onClick={() => refresh()}></MdRefresh>
              </OverlayTrigger>
              <SecretKeyPopup />
              <AddMonitorServerPopup
                secretkey={secretkey}
                getData={() => getData()}></AddMonitorServerPopup>
            </div>
          )}
        </div>
        <PageLoader isLoading={isLoading} />
        <div className="table-responsive">
          <table className="table table-bordered mb-0">
            {Array.isArray(data) && data.length > 0 && (
              <thead>
                <tr>
                  <th scope="col">S.No.</th>
                  {/* <th scope="col">Uuid</th> */}
                  <th scope="col" colSpan="2">
                    Server
                  </th>
                  <th scope="col">Notifications</th>
                  <th scope="col" style={{ width: "200px" }}>
                    Action
                  </th>
                </tr>
              </thead>
            )}
            <tbody>
              {Array.isArray(data) && data.length > 0
                ? data.map((obj, index) => {
                    let object = {
                      id: obj.id,
                      uuid: obj.uuid,
                      update: true,
                      server_name: obj.server_name,
                    };
                    return (
                      <>
                        <tr key={obj.id}>
                          <td rowSpan="2">{index + 1}</td>
                          <td>
                            <div className="row">
                              <div className="col-12">
                                <div className="serversNameInfo">
                                  <div>
                                    <div>{obj.server_name}</div>
                                    <div>({obj.uuid})</div>
                                  </div>
                                  <div
                                    className="servericon"
                                    style={{ display: "flex" }}>
                                    {obj.server_details &&
                                    obj.server_details.server_status &&
                                    obj.server_details.server_status.status ===
                                      "UP" ? (
                                      <OverlayTrigger
                                        placement="top"
                                        overlay={
                                          <Tooltip>Server is UP</Tooltip>
                                        }>
                                        <img
                                          src={server_on}
                                          width="30px"
                                          className="mr-3"
                                          alt="server_on"
                                        />
                                      </OverlayTrigger>
                                    ) : (
                                      <OverlayTrigger
                                        placement="top"
                                        overlay={
                                          <Tooltip>Server is DOWN</Tooltip>
                                        }>
                                        <img
                                          src={server_off}
                                          width="30px"
                                          className="mr-3"
                                          alt="server_off"></img>
                                      </OverlayTrigger>
                                    )}
                                    {/* {obj.currently_logged_in ? <img src={online} width="32px" className="ml-3 mt-1" alt="online"></img> : <img src={offline_user} width="32px" className="ml-3 mt-1" alt="offline_user"></img>} */}
                                    <CurrentLoggedIn
                                      uuid={obj.uuid}
                                      mytimezone={mytimezone}></CurrentLoggedIn>
                                  </div>
                                </div>
                              </div>
                              <div className="col-12 mt-2">
                                <p className="m-0">
                                  IP: {obj.server_details?.Public_ip || "NA"} |
                                  Agent:{" "}
                                  {obj.server_details?.App_version || "NA"}
                                </p>
                              </div>
                            </div>
                          </td>
                          {obj.server_details.new_server ? (
                            <td rowSpan="2">
                              <p>
                                <h6 className="command-title">
                                  Run this command on your server to setup
                                  g8keeper agent. To view what all you can do
                                  with the agent on server{" "}
                                  <a
                                    href="https://g8keeperapp.com/how-it-works/manage-g8keeper-agent-on-server/"
                                    target="_blank">
                                    click here{" "}
                                  </a>
                                </h6>
                                <div className="p-relative">
                                  <p className="command-box">
                                    {" "}
                                    {`sudo bash -c "$(curl -sL http://static.g8keeperapp.com/install-g8keeper.sh)" -i -key=${obj.uuid} -secret=${secretkey}`}
                                    <FiCopy
                                      style={{ cursor: "pointer" }}
                                      className="copy-text-button"
                                      onClick={() =>
                                        copyText(
                                          `sudo bash -c "$(curl -sL http://static.g8keeperapp.com/install-g8keeper.sh)" -i -key=${obj.uuid} -secret=${secretkey}`
                                        )
                                      }></FiCopy>
                                  </p>
                                </div>
                              </p>
                            </td>
                          ) : (
                            <td>
                              <div className="serversNameInfo">
                                {/* <div className="d-flex">
                                                            {obj.aws_icon === 1 ? (
                                                                <OverlayTrigger placement="top" overlay={<Tooltip>Amazon Web Services</Tooltip>}>
                                                                    <img src={aws_img} alt="aws_img" className="moniterImges" />
                                                                </OverlayTrigger>
                                                            ) : (
                                                                <OverlayTrigger placement="top" overlay={<Tooltip>Amazon Web Services disabled</Tooltip>}>
                                                                    <div></div>
                                                                </OverlayTrigger>
                                                            )}

                                                            {obj.apche_icon === 1 ? (
                                                                <OverlayTrigger placement="top" overlay={<Tooltip>Apache details are configured for monitoring</Tooltip>}>
                                                                    <img src={apache_img} alt="apache_img" className="moniterImges" />
                                                                </OverlayTrigger>
                                                            ) : (
                                                                <OverlayTrigger placement="top" overlay={<Tooltip>Apache not being monitored on this server</Tooltip>}>
                                                                    <img src={apache_img} alt="apache_img" className="moniterImges moniterImgesDisabled" />
                                                                </OverlayTrigger>
                                                            )}

                                                            {obj.mysql_icon === 1 ? (
                                                                <OverlayTrigger placement="top" overlay={<Tooltip>MySQL details are configured for monitoring</Tooltip>}>
                                                                    <img src={mysql_img} alt="mysql_img" className="moniterImges" />
                                                                </OverlayTrigger>
                                                            ) : (
                                                                <OverlayTrigger placement="top" overlay={<Tooltip>MySQL not being monitored on this server</Tooltip>}>
                                                                    <img src={mysql_img} alt="mysql_img" className="moniterImges moniterImgesDisabled" />
                                                                </OverlayTrigger>
                                                            )}

                                                            {obj.disallowed_icon === 1 ? (
                                                                <OverlayTrigger placement="top" overlay={<Tooltip> Not Allowed Files monitoring configured</Tooltip>}>
                                                                    <img src={googleFiles_img} alt="googleFiles_img" className="moniterImges" />
                                                                </OverlayTrigger>
                                                            ) : (
                                                                <OverlayTrigger placement="top" overlay={<Tooltip>Not Allowed Files monitoring not configured</Tooltip>}>
                                                                    <img src={googleFiles_img} alt="googleFiles_img" className="moniterImges moniterImgesDisabled" />
                                                                </OverlayTrigger>
                                                            )}

                                                            {obj.folder_change_icon === 1 ? (
                                                                <OverlayTrigger placement="top" overlay={<Tooltip>File change monitoring configured</Tooltip>}>
                                                                    <img src={greenLock_img} alt="greenLock_img" className="moniterImges" />
                                                                </OverlayTrigger>
                                                            ) : (
                                                                <OverlayTrigger placement="top" overlay={<Tooltip>File change monitoring not configured</Tooltip>}>
                                                                    <img src={greenLock_img} alt="greenLock_img" className="moniterImges moniterImgesDisabled" />
                                                                </OverlayTrigger>
                                                            )}

                                                            {obj.db_backup_icon === 1 ? (
                                                                <OverlayTrigger placement="top" overlay={<Tooltip>DB Backup monitoring configured</Tooltip>}>
                                                                    <img src={backup_img} alt="backup_img" className="moniterImges" />
                                                                </OverlayTrigger>
                                                            ) : (
                                                                <OverlayTrigger placement="top" overlay={<Tooltip>DB Backup monitoring not configured</Tooltip>}>
                                                                    <img src={backup_img} alt="backup_img" className="moniterImges moniterImgesDisabled" />
                                                                </OverlayTrigger>
                                                            )}

                                                            {obj.folder_backup_icon === 1 ? (
                                                                <OverlayTrigger placement="top" overlay={<Tooltip>Folder Backup monitoring configured</Tooltip>}>
                                                                    <img src={folder_img} alt="folder_img" className="moniterImges" />
                                                                </OverlayTrigger>
                                                            ) : (
                                                                <OverlayTrigger placement="top" overlay={<Tooltip>Folder Backup monitoring not configured</Tooltip>}>
                                                                    <img src={folder_img} alt="folder_img" className="moniterImges moniterImgesDisabled" />
                                                                </OverlayTrigger>
                                                            )}
                                                        </div> */}

                                <div className="d-flex">
                                  {obj.server_type === "Aws" ? (
                                    <>
                                      {obj.aws_icon === 1 ? (
                                        <OverlayTrigger
                                          placement="top"
                                          overlay={
                                            <Tooltip>
                                              Amazon Web Services click to view
                                              details.
                                            </Tooltip>
                                          }>
                                          <img
                                            style={{ cursor: "pointer" }}
                                            src={aws_img}
                                            alt="aws_img"
                                            className="moniterImges"
                                            onClick={() =>
                                              onClickGotoCharts(obj, "awsView")
                                            }
                                          />
                                        </OverlayTrigger>
                                      ) : (
                                        <OverlayTrigger
                                          placement="top"
                                          overlay={
                                            <Tooltip>
                                              Amazon Web Services disabled click
                                              to configure.
                                            </Tooltip>
                                          }>
                                          <div></div>
                                        </OverlayTrigger>
                                      )}
                                    </>
                                  ) : obj.server_type === "DigitalOcean" ? (
                                    <>
                                      {obj.aws_icon === 1 ? (
                                        <OverlayTrigger
                                          placement="top"
                                          overlay={
                                            <Tooltip>
                                              Digital Ocean click to view
                                              details.
                                            </Tooltip>
                                          }>
                                          <AiFillAccountBook
                                            size="25"
                                            style={{ cursor: "pointer" }}
                                            src={aws_img}
                                            alt="aws_img"
                                            className="moniterImges"
                                            onClick={() =>
                                              onClickGotoCharts(obj, "awsView")
                                            }
                                          />
                                        </OverlayTrigger>
                                      ) : (
                                        <OverlayTrigger
                                          placement="top"
                                          overlay={
                                            <Tooltip>
                                              Digital Ocean disabled click to
                                              configure.
                                            </Tooltip>
                                          }>
                                          <div></div>
                                        </OverlayTrigger>
                                      )}
                                    </>
                                  ) : (
                                    ""
                                  )}

                                  {obj.apche_icon === 1 ? (
                                    <OverlayTrigger
                                      placement="top"
                                      overlay={
                                        <Tooltip>
                                          Apache details are configured for
                                          monitoring click to view details.
                                        </Tooltip>
                                      }>
                                      <img
                                        src={apache_img}
                                        style={{ cursor: "pointer" }}
                                        alt="apache_img"
                                        className="moniterImges"
                                        onClick={() =>
                                          onClickGotoCharts(obj, "performance")
                                        }
                                      />
                                    </OverlayTrigger>
                                  ) : (
                                    <OverlayTrigger
                                      placement="top"
                                      overlay={
                                        <Tooltip>
                                          Apache not being monitored on this
                                          server click to configure.
                                        </Tooltip>
                                      }>
                                      <img
                                        src={apache_img}
                                        style={{ cursor: "pointer" }}
                                        alt="apache_img"
                                        className="moniterImges moniterImgesDisabled"
                                        onClick={() =>
                                          onClickGotoSetting(
                                            obj,
                                            "performance",
                                            "setting"
                                          )
                                        }
                                      />
                                    </OverlayTrigger>
                                  )}
                                  {obj.nginx_icon === 1 ? (
                                    <OverlayTrigger
                                      placement="top"
                                      overlay={
                                        <Tooltip>
                                          Nginx details are configured for
                                          monitoring click to view details.
                                        </Tooltip>
                                      }>
                                      <img
                                        src={nginx_img}
                                        style={{ cursor: "pointer" }}
                                        alt="nginx_img"
                                        className="moniterImges"
                                        onClick={() =>
                                          onClickGotoCharts(obj, "performance")
                                        }
                                      />
                                    </OverlayTrigger>
                                  ) : (
                                    <OverlayTrigger
                                      placement="top"
                                      overlay={
                                        <Tooltip>
                                          Nginx not being monitored on this
                                          server click to configure.
                                        </Tooltip>
                                      }>
                                      <img
                                        src={nginx_img}
                                        style={{ cursor: "pointer" }}
                                        alt="nginx_img"
                                        className="moniterImges moniterImgesDisabled"
                                        onClick={() =>
                                          onClickGotoSetting(
                                            obj,
                                            "performance",
                                            "setting"
                                          )
                                        }
                                      />
                                    </OverlayTrigger>
                                  )}

                                  {obj.mysql_icon === 1 ? (
                                    <OverlayTrigger
                                      placement="top"
                                      overlay={
                                        <Tooltip>
                                          MySQL details are configured for
                                          monitoring click to view details.
                                        </Tooltip>
                                      }>
                                      <img
                                        src={mysql_img}
                                        style={{ cursor: "pointer" }}
                                        alt="mysql_img"
                                        className="moniterImges"
                                        onClick={() =>
                                          onClickGotoCharts(obj, "performance")
                                        }
                                      />
                                    </OverlayTrigger>
                                  ) : (
                                    <OverlayTrigger
                                      placement="top"
                                      overlay={
                                        <Tooltip>
                                          MySQL not being monitored on this
                                          server click to configure.
                                        </Tooltip>
                                      }>
                                      <img
                                        src={mysql_img}
                                        style={{ cursor: "pointer" }}
                                        alt="mysql_img"
                                        className="moniterImges moniterImgesDisabled"
                                        onClick={() =>
                                          onClickGotoSetting(
                                            obj,
                                            "performance",
                                            "setting"
                                          )
                                        }
                                      />
                                    </OverlayTrigger>
                                  )}

                                  {obj.disallowed_icon === 1 ? (
                                    <OverlayTrigger
                                      placement="top"
                                      overlay={
                                        <Tooltip>
                                          {" "}
                                          Not Allowed Files monitoring
                                          configured click to view details.
                                        </Tooltip>
                                      }>
                                      <img
                                        src={googleFiles_img}
                                        style={{ cursor: "pointer" }}
                                        alt="googleFiles_img"
                                        className="moniterImges"
                                        onClick={() =>
                                          onClickGotoCharts(
                                            obj,
                                            "notAllowedFile"
                                          )
                                        }
                                      />
                                    </OverlayTrigger>
                                  ) : (
                                    <OverlayTrigger
                                      placement="top"
                                      overlay={
                                        <Tooltip>
                                          Not Allowed Files monitoring not
                                          configured click to configure.
                                        </Tooltip>
                                      }>
                                      <img
                                        src={googleFiles_img}
                                        style={{ cursor: "pointer" }}
                                        alt="googleFiles_img"
                                        className="moniterImges moniterImgesDisabled"
                                        onClick={() =>
                                          onClickGotoSetting(
                                            obj,
                                            "notAllowedFile",
                                            "setting"
                                          )
                                        }
                                      />
                                    </OverlayTrigger>
                                  )}

                                  {obj.folder_change_icon === 1 ? (
                                    <OverlayTrigger
                                      placement="top"
                                      overlay={
                                        <Tooltip>
                                          File change monitoring configured
                                          click to view details.
                                        </Tooltip>
                                      }>
                                      <img
                                        src={greenLock_img}
                                        style={{ cursor: "pointer" }}
                                        alt="greenLock_img"
                                        className="moniterImges"
                                        onClick={() =>
                                          onClickGotoCharts(obj, "folderChange")
                                        }
                                      />
                                    </OverlayTrigger>
                                  ) : (
                                    <OverlayTrigger
                                      placement="top"
                                      overlay={
                                        <Tooltip>
                                          File change monitoring not configured
                                          click to configure.
                                        </Tooltip>
                                      }>
                                      <img
                                        src={greenLock_img}
                                        style={{ cursor: "pointer" }}
                                        alt="greenLock_img"
                                        className="moniterImges moniterImgesDisabled"
                                        onClick={() =>
                                          onClickGotoSetting(
                                            obj,
                                            "folderChange",
                                            "setting"
                                          )
                                        }
                                      />
                                    </OverlayTrigger>
                                  )}

                                  {obj.db_backup_icon === 1 ? (
                                    <OverlayTrigger
                                      placement="top"
                                      overlay={
                                        <Tooltip>
                                          DB Backup monitoring configured click
                                          to view details.
                                        </Tooltip>
                                      }>
                                      <img
                                        src={backup_img}
                                        style={{ cursor: "pointer" }}
                                        alt="backup_img"
                                        className="moniterImges"
                                        onClick={() =>
                                          onClickGotoCharts(obj, "backup")
                                        }
                                      />
                                    </OverlayTrigger>
                                  ) : (
                                    <OverlayTrigger
                                      placement="top"
                                      overlay={
                                        <Tooltip>
                                          DB Backup monitoring not configured
                                          click to configure.
                                        </Tooltip>
                                      }>
                                      <img
                                        src={backup_img}
                                        style={{ cursor: "pointer" }}
                                        alt="backup_img"
                                        className="moniterImges moniterImgesDisabled"
                                        onClick={() =>
                                          onClickGotoSetting(
                                            obj,
                                            "backup",
                                            "setting"
                                          )
                                        }
                                      />
                                    </OverlayTrigger>
                                  )}

                                  {obj.folder_backup_icon === 1 ? (
                                    <OverlayTrigger
                                      placement="top"
                                      overlay={
                                        <Tooltip>
                                          Folder Backup monitoring configured
                                          click to view details.
                                        </Tooltip>
                                      }>
                                      <img
                                        src={folder_img}
                                        style={{ cursor: "pointer" }}
                                        alt="folder_img"
                                        className="moniterImges"
                                        onClick={() =>
                                          onClickGotoCharts(obj, "backup")
                                        }
                                      />
                                    </OverlayTrigger>
                                  ) : (
                                    <OverlayTrigger
                                      placement="top"
                                      overlay={
                                        <Tooltip>
                                          Folder Backup monitoring not
                                          configured click to configure.
                                        </Tooltip>
                                      }>
                                      <img
                                        src={folder_img}
                                        style={{ cursor: "pointer" }}
                                        alt="folder_img"
                                        className="moniterImges moniterImgesDisabled"
                                        onClick={() =>
                                          onClickGotoSetting(
                                            obj,
                                            "backup",
                                            "setting"
                                          )
                                        }
                                      />
                                    </OverlayTrigger>
                                  )}
                                </div>
                              </div>
                            </td>
                          )}
                          <td rowSpan="2">
                            <OpenNotificationsPopup
                              key={obj.id}
                              id={obj.id}
                              mytimezone={mytimezone}
                              serverListing={true}></OpenNotificationsPopup>
                          </td>
                          <td rowSpan="2">
                            <div className="tableActionBtns">
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip>View Server Details</Tooltip>
                                }>
                                <BiLineChart
                                  className="actionIcon"
                                  onClick={() => {
                                    localStorage.setItem(
                                      "chartsRedireactTo",
                                      1
                                    );
                                    props.history.push(
                                      "/monitor-server/charts" +
                                        objectToQuery(object),
                                      object
                                    );
                                  }}
                                />
                              </OverlayTrigger>
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip>Edit Server Settings</Tooltip>
                                }>
                                <RiSettings2Line
                                  className="actionIcon"
                                  onClick={() =>
                                    props.history.push(
                                      "/monitor-server/server-setting",
                                      {
                                        id: obj.id,
                                        uuid: obj.uuid,
                                        server_name: obj.server_name,
                                        update: true,
                                      }
                                    )
                                  }
                                />
                              </OverlayTrigger>
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip>
                                    Edit Server Name and Description
                                  </Tooltip>
                                }>
                                <MdEdit
                                  className="actionIcon"
                                  onClick={() => {
                                    setId(obj.id);
                                    setModal(true);
                                  }}
                                />
                              </OverlayTrigger>
                              <ServerShare
                                uuid={obj.uuid}
                                className="actionIcon"
                                id={obj.id}
                                share={share}
                                server_name={obj.server_name}></ServerShare>
                              <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip>Remove</Tooltip>}>
                                <RiDeleteBinLine
                                  className="actionIcon deleteIcon"
                                  onClick={() => deleteServer(obj.id)}
                                />
                              </OverlayTrigger>
                              <Notes id={obj.id} type="monitor_server"></Notes>
                              <AppInstallSteps
                                secretkey={obj.secret_key}
                                uuid={obj.uuid}></AppInstallSteps>
                            </div>
                          </td>
                        </tr>
                        <tr key={index}>
                          <td>{obj.server_description}</td>
                          {!obj.server_details.new_server && (
                            <td>
                              {obj.server_details.new_server ? (
                                // <p>
                                //     Run this command on your server to setup g8keeper agent

                                //     <p> {`sudo bash -c "$(curl -sL http://static.g8keeperapp.com/install-g8keeper.sh)" -i -key=${obj.uuid} -secret=${obj.secret_key}`}</p>
                                <p>
                                  <h6 className="command-title">
                                    Run this command on your server to setup
                                    g8keeper agent. To view what all you can do
                                    with the agent on server{" "}
                                    <a
                                      href="https://g8keeperapp.com/how-it-works/manage-g8keeper-agent-on-server/"
                                      target="_blank">
                                      click here{" "}
                                    </a>
                                  </h6>
                                  <div className="p-relative">
                                    <p className="command-box">
                                      {" "}
                                      {`sudo bash -c "$(curl -sL http://static.g8keeperapp.com/install-g8keeper.sh)" -i -key=${obj.uuid} -secret=${obj.secret_key}`}
                                      <FiCopy
                                        style={{ cursor: "pointer" }}
                                        className="copy-text-button"
                                        onClick={() =>
                                          copyText(
                                            `sudo bash -c "$(curl -sL http://static.g8keeperapp.com/install-g8keeper.sh)" -i -key=${obj.uuid} -secret=${obj.secret_key}`
                                          )
                                        }></FiCopy>
                                    </p>
                                  </div>
                                </p>
                              ) : (
                                // {/* </p> */}
                                <div
                                  style={{
                                    width: "350px",
                                    height: "100px",
                                    overflow: "hidden",
                                  }}>
                                  <UsageChart
                                    // title="CPU Usage"AiFillAccountBook
                                    // toolbox={true}
                                    uuid={obj.uuid}
                                    user_element="cpu_usage"
                                    SeriesType={["total", "system", "user"]}
                                    active={0.5}
                                    // tooltipFormatterCallBack={this.getTopProcessDate}
                                    // isRefresh={this.state.isRefresh}
                                    mytimezone={mytimezone}
                                    grid={{
                                      top: "2%",
                                      left: "10%",
                                      right: "0%",
                                      bottom: "70%",
                                      containLabel: true,
                                    }}
                                    onRefresh={props.onRefresh}
                                  />
                                </div>
                              )}
                            </td>
                          )}
                        </tr>
                      </>
                    );
                  })
                : Shareddata.length < 1 && (
                    <div className="blankPageUI">
                      <div className="row">
                        <div className="row">
                          <div className="notActiveTxt col-md-6">
                            <p className="notactivedata">
                              <strong>Okay!</strong>
                              {isInactive
                                ? "it seems you have no inactive server, which is a good thing!"
                                : "it seems you have not added a server yet."}
                            </p>

                            {isInactive ? (
                              <>
                                <p>
                                  {" "}
                                  Inactive Server tab displays the servers that
                                  you have added, however we have not seen the
                                  server UP for at least last 72 hours.
                                </p>
                                <p>
                                  The moment we see the server is up again,
                                  g8keeper moves the server to active tab
                                  automatically{" "}
                                </p>
                              </>
                            ) : (
                              <>
                                <p>
                                  If you are using linux based cloud server you
                                  can add it here in 2 simple steps and start
                                  monitoring server details in realtime.
                                </p>
                                <p>
                                  The setup will require SSH access to server
                                  and will only take less than 5 mins.
                                </p>
                                <ol>
                                  <li>
                                    To add the server{" "}
                                    <AddMonitorServerPopup
                                      secretkey={secretkey}
                                      fromClickHere={true}
                                      getData={() =>
                                        getData()
                                      }></AddMonitorServerPopup>
                                  </li>
                                  <li>Setup the agent on server</li>
                                </ol>
                              </>
                            )}
                          </div>
                          {
                            <div className="text-center col-md-6">
                              <div className="defaultImg">
                                {!isInactive && (
                                  <img src={topArrow} className="topArrow2" />
                                )}
                                <img
                                  src={defaultMonitorServers}
                                  alt="defaultMonitorServers"
                                  className="img-fluid"
                                />
                              </div>
                            </div>
                          }
                        </div>
                      </div>
                    </div>
                  )}
            </tbody>
          </table>
        </div>
      </div>
      {Array.isArray(Shareddata) && Shareddata.length > 0 && (
        <div className="card p-0">
          <div className="card-header">
            <h3>Shared Monitor Servers</h3>
          </div>
          <div className="table-responsive">
            <table className="table table-bordered mb-0">
              {Shareddata.length > 0 && (
                <thead>
                  <tr>
                    <th scope="col">S.No.</th>
                    <th scope="col" colSpan="2">
                      Server Name
                    </th>
                    <th scope="col">Shared by</th>
                    <th scope="col">NOTIFICATIONS</th>
                    <th scope="col" style={{ maxWidth: "200px" }}>
                      Action
                    </th>
                  </tr>
                </thead>
              )}
              <tbody>
                {Shareddata.map((obj, index) => {
                  let object = {
                    id: obj.id,
                    uuid: obj.uuid,
                    update: true,
                    server_name: obj.server_name,
                  };
                  return (
                    <>
                      <tr key={obj.id}>
                        <td rowSpan="2">{index + 1}</td>
                        <td>
                          <div className="row">
                            <div className="col-12">
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}>
                                <div>
                                  <div>{obj.server_name}</div>
                                  <div>({obj.uuid})</div>
                                </div>
                                <div
                                  className="servericon"
                                  style={{ display: "flex" }}>
                                  {obj.server_details &&
                                  obj.server_details.server_status &&
                                  obj.server_details.server_status.status ===
                                    "UP" ? (
                                    <OverlayTrigger
                                      placement="top"
                                      overlay={<Tooltip>Server is UP</Tooltip>}>
                                      <img
                                        src={server_on}
                                        width="30px"
                                        className="mr-3"
                                        alt="server_on"
                                      />
                                    </OverlayTrigger>
                                  ) : (
                                    <OverlayTrigger
                                      placement="top"
                                      overlay={
                                        <Tooltip>Server is DOWN</Tooltip>
                                      }>
                                      <img
                                        src={server_off}
                                        width="30px"
                                        className="mr-3"
                                        alt="server_off"></img>
                                    </OverlayTrigger>
                                  )}
                                  {/* {obj.currently_logged_in ? <img src={online} width="32px" className="ml-3 mt-1" alt="online"></img> : <img src={offline_user} width="32px" className="ml-3 mt-1" alt="offline_user"></img>} */}
                                  <CurrentLoggedIn
                                    uuid={obj.uuid}
                                    mytimezone={mytimezone}></CurrentLoggedIn>
                                </div>
                              </div>
                            </div>
                            <div className="col-12 mt-2">
                              <p className="m-0">
                                IP: {obj.server_details?.Public_ip || "NA"} |
                                Agent: {obj.server_details?.App_version || "NA"}
                              </p>
                            </div>
                          </div>
                        </td>

                        {obj.server_details.new_server ? (
                          <td rowSpan="2">
                            {/* <p>
                                                        Run this command on your server to setup g8keeper agent
                                                        <p> {`sudo bash -c "$(curl -sL http://static.g8keeperapp.com/install-g8keeper.sh)" -i -key=${obj.uuid} -secret=${obj.secret_key}`}</p>
                                                    </p> */}
                            <p>
                              <h6 className="command-title">
                                Run this command on your server to setup
                                g8keeper agent. To view what all you can do with
                                the agent on server{" "}
                                <a
                                  href="https://g8keeperapp.com/how-it-works/manage-g8keeper-agent-on-server/"
                                  target="_blank">
                                  click here{" "}
                                </a>
                              </h6>
                              <div className="p-relative">
                                <p className="command-box">
                                  {" "}
                                  {`sudo bash -c "$(curl -sL http://static.g8keeperapp.com/install-g8keeper.sh)" -i -key=${obj.uuid} -secret=${obj.secret_key}`}
                                  <FiCopy
                                    style={{ cursor: "pointer" }}
                                    className="copy-text-button"
                                    onClick={() =>
                                      copyText(
                                        `sudo bash -c "$(curl -sL http://static.g8keeperapp.com/install-g8keeper.sh)" -i -key=${obj.uuid} -secret=${obj.secret_key}`
                                      )
                                    }></FiCopy>
                                </p>
                              </div>
                            </p>
                          </td>
                        ) : (
                          <td>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-around",
                              }}>
                              <div className="d-flex">
                                {obj.server_type === "Aws" ? (
                                  <>
                                    {obj.aws_icon === 1 ? (
                                      <OverlayTrigger
                                        placement="top"
                                        overlay={
                                          <Tooltip>
                                            Amazon Web Services click to view
                                            details.
                                          </Tooltip>
                                        }>
                                        <img
                                          style={{ cursor: "pointer" }}
                                          src={aws_img}
                                          alt="aws_img"
                                          className="moniterImges"
                                          onClick={() =>
                                            onClickGotoCharts(obj, "awsView")
                                          }
                                        />
                                      </OverlayTrigger>
                                    ) : (
                                      <OverlayTrigger
                                        placement="top"
                                        overlay={
                                          <Tooltip>
                                            Amazon Web Services disabled click
                                            to configure.
                                          </Tooltip>
                                        }>
                                        <div></div>
                                      </OverlayTrigger>
                                    )}
                                  </>
                                ) : obj.server_type === "DigitalOcean" ? (
                                  <>
                                    {obj.aws_icon === 1 ? (
                                      <OverlayTrigger
                                        placement="top"
                                        overlay={
                                          <Tooltip>
                                            Digital Ocean click to view details.
                                          </Tooltip>
                                        }>
                                        <AiFillAccountBook
                                          size="25"
                                          style={{ cursor: "pointer" }}
                                          alt="aws_img"
                                          className="moniterImges"
                                          onClick={() =>
                                            onClickGotoCharts(obj, "awsView")
                                          }
                                        />
                                      </OverlayTrigger>
                                    ) : (
                                      <OverlayTrigger
                                        placement="top"
                                        overlay={
                                          <Tooltip>
                                            Digital Ocean disabled click to
                                            configure.
                                          </Tooltip>
                                        }>
                                        <div></div>
                                      </OverlayTrigger>
                                    )}
                                  </>
                                ) : (
                                  ""
                                )}

                                {obj.apche_icon === 1 ? (
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={
                                      <Tooltip>
                                        Apache details are configured for
                                        monitoring click to view details.
                                      </Tooltip>
                                    }>
                                    <img
                                      src={apache_img}
                                      style={{ cursor: "pointer" }}
                                      alt="apache_img"
                                      className="moniterImges"
                                      onClick={() =>
                                        onClickGotoCharts(obj, "performance")
                                      }
                                    />
                                  </OverlayTrigger>
                                ) : (
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={
                                      <Tooltip>
                                        Apache not being monitored on this
                                        server click to configure.
                                      </Tooltip>
                                    }>
                                    <img
                                      src={apache_img}
                                      style={{ cursor: "pointer" }}
                                      alt="apache_img"
                                      className="moniterImges moniterImgesDisabled"
                                      onClick={() =>
                                        onClickGotoSetting(
                                          obj,
                                          "performance",
                                          "setting"
                                        )
                                      }
                                    />
                                  </OverlayTrigger>
                                )}
                                {obj.nginx_icon === 1 ? (
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={
                                      <Tooltip>
                                        Nginx details are configured for
                                        monitoring click to view details.
                                      </Tooltip>
                                    }>
                                    <img
                                      src={nginx_img}
                                      style={{ cursor: "pointer" }}
                                      alt="nginx_img"
                                      className="moniterImges"
                                      onClick={() =>
                                        onClickGotoCharts(obj, "performance")
                                      }
                                    />
                                  </OverlayTrigger>
                                ) : (
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={
                                      <Tooltip>
                                        Nginx not being monitored on this server
                                        click to configure.
                                      </Tooltip>
                                    }>
                                    <img
                                      src={nginx_img}
                                      style={{ cursor: "pointer" }}
                                      alt="nginx_img"
                                      className="moniterImges moniterImgesDisabled"
                                      onClick={() =>
                                        onClickGotoSetting(
                                          obj,
                                          "performance",
                                          "setting"
                                        )
                                      }
                                    />
                                  </OverlayTrigger>
                                )}

                                {obj.mysql_icon === 1 ? (
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={
                                      <Tooltip>
                                        MySQL details are configured for
                                        monitoring click to view details.
                                      </Tooltip>
                                    }>
                                    <img
                                      src={mysql_img}
                                      style={{ cursor: "pointer" }}
                                      alt="mysql_img"
                                      className="moniterImges"
                                      onClick={() =>
                                        onClickGotoCharts(obj, "performance")
                                      }
                                    />
                                  </OverlayTrigger>
                                ) : (
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={
                                      <Tooltip>
                                        MySQL not being monitored on this server
                                        click to configure.
                                      </Tooltip>
                                    }>
                                    <img
                                      src={mysql_img}
                                      style={{ cursor: "pointer" }}
                                      alt="mysql_img"
                                      className="moniterImges moniterImgesDisabled"
                                      onClick={() =>
                                        onClickGotoSetting(
                                          obj,
                                          "performance",
                                          "setting"
                                        )
                                      }
                                    />
                                  </OverlayTrigger>
                                )}

                                {obj.disallowed_icon === 1 ? (
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={
                                      <Tooltip>
                                        {" "}
                                        Not Allowed Files monitoring configured
                                        click to view details.
                                      </Tooltip>
                                    }>
                                    <img
                                      src={googleFiles_img}
                                      style={{ cursor: "pointer" }}
                                      alt="googleFiles_img"
                                      className="moniterImges"
                                      onClick={() =>
                                        onClickGotoCharts(obj, "notAllowedFile")
                                      }
                                    />
                                  </OverlayTrigger>
                                ) : (
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={
                                      <Tooltip>
                                        Not Allowed Files monitoring not
                                        configured click to configure.
                                      </Tooltip>
                                    }>
                                    <img
                                      src={googleFiles_img}
                                      style={{ cursor: "pointer" }}
                                      alt="googleFiles_img"
                                      className="moniterImges moniterImgesDisabled"
                                      onClick={() =>
                                        onClickGotoSetting(
                                          obj,
                                          "notAllowedFile",
                                          "setting"
                                        )
                                      }
                                    />
                                  </OverlayTrigger>
                                )}

                                {obj.folder_change_icon === 1 ? (
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={
                                      <Tooltip>
                                        File change monitoring configured click
                                        to view details.
                                      </Tooltip>
                                    }>
                                    <img
                                      src={greenLock_img}
                                      style={{ cursor: "pointer" }}
                                      alt="greenLock_img"
                                      className="moniterImges"
                                      onClick={() =>
                                        onClickGotoCharts(obj, "folderChange")
                                      }
                                    />
                                  </OverlayTrigger>
                                ) : (
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={
                                      <Tooltip>
                                        File change monitoring not configured
                                        click to configure.
                                      </Tooltip>
                                    }>
                                    <img
                                      src={greenLock_img}
                                      style={{ cursor: "pointer" }}
                                      alt="greenLock_img"
                                      className="moniterImges moniterImgesDisabled"
                                      onClick={() =>
                                        onClickGotoSetting(
                                          obj,
                                          "folderChange",
                                          "setting"
                                        )
                                      }
                                    />
                                  </OverlayTrigger>
                                )}

                                {obj.db_backup_icon === 1 ? (
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={
                                      <Tooltip>
                                        DB Backup monitoring configured click to
                                        view details.
                                      </Tooltip>
                                    }>
                                    <img
                                      src={backup_img}
                                      style={{ cursor: "pointer" }}
                                      alt="backup_img"
                                      className="moniterImges"
                                      onClick={() =>
                                        onClickGotoCharts(obj, "backup")
                                      }
                                    />
                                  </OverlayTrigger>
                                ) : (
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={
                                      <Tooltip>
                                        DB Backup monitoring not configured
                                        click to configure.
                                      </Tooltip>
                                    }>
                                    <img
                                      src={backup_img}
                                      style={{ cursor: "pointer" }}
                                      alt="backup_img"
                                      className="moniterImges moniterImgesDisabled"
                                      onClick={() =>
                                        onClickGotoSetting(
                                          obj,
                                          "backup",
                                          "setting"
                                        )
                                      }
                                    />
                                  </OverlayTrigger>
                                )}

                                {obj.folder_backup_icon === 1 ? (
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={
                                      <Tooltip>
                                        Folder Backup monitoring configured
                                        click to view details.
                                      </Tooltip>
                                    }>
                                    <img
                                      src={folder_img}
                                      style={{ cursor: "pointer" }}
                                      alt="folder_img"
                                      className="moniterImges"
                                      onClick={() =>
                                        onClickGotoCharts(obj, "backup")
                                      }
                                    />
                                  </OverlayTrigger>
                                ) : (
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={
                                      <Tooltip>
                                        Folder Backup monitoring not configured
                                        click to configure.
                                      </Tooltip>
                                    }>
                                    <img
                                      src={folder_img}
                                      style={{ cursor: "pointer" }}
                                      alt="folder_img"
                                      className="moniterImges moniterImgesDisabled"
                                      onClick={() =>
                                        onClickGotoSetting(
                                          obj,
                                          "backup",
                                          "setting"
                                        )
                                      }
                                    />
                                  </OverlayTrigger>
                                )}
                              </div>
                            </div>
                          </td>
                        )}

                        <td rowSpan="2">{obj.email}</td>
                        <td rowSpan="2">
                          <OpenNotificationsPopup
                            key={obj.id}
                            id={obj.id}
                            mytimezone={mytimezone}
                            serverListing={true}></OpenNotificationsPopup>
                        </td>

                        <td rowSpan="2">
                          <div className="tableActionBtns">
                            <OverlayTrigger
                              placement="top"
                              overlay={<Tooltip>View Server Details</Tooltip>}>
                              <BiLineChart
                                className="actionIcon"
                                onClick={() => {
                                  localStorage.setItem("chartsRedireactTo", 1);
                                  props.history.push(
                                    "/monitor-server/charts" +
                                      objectToQuery(object),
                                    object
                                  );
                                }}></BiLineChart>
                            </OverlayTrigger>
                            <OverlayTrigger
                              placement="top"
                              overlay={<Tooltip>Edit Server Settings</Tooltip>}>
                              <RiSettings2Line
                                className="actionIcon"
                                onClick={() =>
                                  props.history.push(
                                    "/monitor-server/server-setting",
                                    {
                                      id: obj.id,
                                      uuid: obj.uuid,
                                      server_name: obj.server_name,
                                      update: true,
                                    }
                                  )
                                }
                              />
                            </OverlayTrigger>

                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip>
                                  Edit Server Name and Description
                                </Tooltip>
                              }>
                              <MdEdit
                                className="actionIcon"
                                onClick={() => {
                                  setId(obj.id);
                                  setModal(true);
                                }}
                              />
                            </OverlayTrigger>
                            <ServerShare
                              uuid={obj.uuid}
                              className="actionIcon"
                              id={obj.id}
                              share={share}
                              server_name={obj.server_name}></ServerShare>
                            <OverlayTrigger
                              placement="top"
                              overlay={<Tooltip>Remove</Tooltip>}>
                              <RiDeleteBinLine
                                onClick={() =>
                                  monitorServerSharedByOtherRemove(obj.uuid)
                                }
                                className="actionIcon deleteIcon"
                              />
                            </OverlayTrigger>
                            <Notes id={obj.id} type="monitor_server"></Notes>
                            <AppInstallSteps
                              secretkey={obj.secret_key}
                              uuid={obj.uuid}></AppInstallSteps>
                          </div>
                        </td>
                      </tr>

                      <tr key={index}>
                        <td>{obj.server_description}</td>
                        {!obj.server_details.new_server && (
                          <td>
                            {obj.server_details.new_server ? (
                              // <p>
                              //     Run this command on your server to setup g8keeper agent.
                              //     <p> {`sudo bash -c "$(curl -sL http://static.g8keeperapp.com/install-g8keeper.sh)" -i -key=${obj.uuid} -secret=${obj.secret_key}`}</p>
                              // </p>
                              <p>
                                <h6 className="command-title">
                                  Run this command on your server to setup
                                  g8keeper agent. To view what all you can do
                                  with the agent on server{" "}
                                  <a
                                    href="https://g8keeperapp.com/how-it-works/manage-g8keeper-agent-on-server/"
                                    target="_blank">
                                    click here{" "}
                                  </a>
                                </h6>
                                <div className="p-relative">
                                  <p className="command-box">
                                    {" "}
                                    {`sudo bash -c "$(curl -sL http://static.g8keeperapp.com/install-g8keeper.sh)" -i -key=${obj.uuid} -secret=${obj.secret_key}`}
                                    <FiCopy
                                      style={{ cursor: "pointer" }}
                                      className="copy-text-button"
                                      onClick={() =>
                                        copyText(
                                          `sudo bash -c "$(curl -sL http://static.g8keeperapp.com/install-g8keeper.sh)" -i -key=${obj.uuid} -secret=${obj.secret_key}`
                                        )
                                      }></FiCopy>
                                  </p>
                                </div>
                              </p>
                            ) : (
                              <div
                                style={{
                                  width: "350px",
                                  height: "100px",
                                  overflow: "hidden",
                                }}>
                                <UsageChart
                                  tooltip={true}
                                  uuid={obj.uuid}
                                  user_element="cpu_usage"
                                  SeriesType={["total", "system", "user"]}
                                  active={0.5}
                                  mytimezone={mytimezone}
                                  grid={{
                                    top: "2%",
                                    left: "10%",
                                    right: "0%",
                                    bottom: "70%",
                                    containLabel: true,
                                  }}
                                  // {...props}
                                />
                              </div>
                            )}
                          </td>
                        )}
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Edit Server</ModalHeader>
        <ModalBody>
          <AddServer
            id={id}
            update={!!id}
            toggle={toggle}
            popup
            responseAfterAdd={(
              _response,
              { server_name, server_description, id }
            ) => {
              setData((prev) =>
                prev.map((d) =>
                  d.id !== id ? d : { ...d, server_name, server_description }
                )
              );
              setShareddata((prev) =>
                prev.map((d) =>
                  d.id !== id ? d : { ...d, server_name, server_description }
                )
              );
            }}
          />
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default MonitorServerListing;
