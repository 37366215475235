import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input } from 'reactstrap';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { AiFillAppstore } from "react-icons/ai";
import toastr from 'toastr'
import { monitorServersServices } from '../../_services/servicesWithSecondOrigin/index'
import { Validation } from '../../_helpers/validation/Validation'
import { Error } from '../../_components/CommonComponents/index'
import { confirmAlert } from 'react-confirm-alert'
import { AiOutlineInfoCircle } from 'react-icons/ai'

const SecretKeyPopup = (props) => {
    const [modal, setModal] = useState(false);
    const toggle = () => {
        if (!modal) { getKey() }
        setModal(!modal);
    }
    const [key, setKey] = useState("")
    const [public_key, setPublicKey] = useState("")
    const [showPublic, setShowublic] = useState("")
    const [private_key, setPrivateKey] = useState("")
    const [error, setError] = useState("")
    const copyText = () => {
        if (key) {
            navigator.clipboard.writeText(key)
            toastr.success("Copied!")
        }
    }

    const getKey = async () => {
        let response = await monitorServersServices.getKey()
        if (response && response.data) {
            setKey(response.data.secret_key)
            setPrivateKey(response.data.private_key)
            setShowublic(response.data.public_key)
        }
    }

    const generate = async () => {
        confirmAlert({
            title: "Confirm to Refresh",
            message: <p>Are you sure to do this.</p>,
            buttons: [
                {
                    label: "Yes",
                    onClick: async () => {
                        let response = await monitorServersServices.generateKey()
                        if (response && response.data) {
                            setKey(response.data)
                            toastr.success(response.message)
                        } else {
                            setKey("")
                            toastr.error("Error")
                        }
                    },
                },
                {
                    label: "No",
                    onClick: () => console.log("No"),
                },
            ],
        });
    }

    const onChangeFileUpload = (e) => {
        if (e.target.files && e.target.files[0]) {
            var file = e.target.files[0]
            let reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => {
                var strImage = reader.result.split('base64,')[1];
                setPublicKey(strImage)
            };
        }
    }

    const onSavePublicPrivateKey = async () => {
        let data = {
            "public_key": public_key,
            "private_key": private_key
        }
        let flag = 0
        let errobj = Validation(data, rules);
        setError(errobj)
        Object.keys(errobj).map(index => {
            if (errobj[index] !== "") {
                flag = 1;
            }
        });

        if (flag === 0) {
            let response = await monitorServersServices.savePublicPrivateKey(data)
            if (response && response.message) {
                toastr.success(response.message)
            }
        }
    }

    return (
        <div>
            <OverlayTrigger placement="left" overlay={<Tooltip>Secret key for your accout</Tooltip>}>
                <AiFillAppstore className="headerIcon" onClick={toggle} style={{ marginRight: '10px' }} />
            </OverlayTrigger>
            <Modal isOpen={modal} toggle={toggle} backdrop="static">
                <ModalHeader toggle={toggle}>Secret Key</ModalHeader>
                <ModalBody>
                    <div className="row mt-1">
                        <div className="col-md-3">
                            <label>Secret Key</label>
                            <span className="bottom tsize-400 tooltip ml-1">
                                <AiOutlineInfoCircle className="infoIcon" />
                                <span className="tooltiptext tooltip-top">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <p> Secret key is used to encrypt all the commnunication.</p>
                                        </div>
                                    </div>
                                </span>
                            </span>
                        </div>
                        <div className="col-md-6 pb-3">
                            <Input value={key} onClick={() => copyText()}></Input>
                        </div>
                        <div className="col-md-3">
                            <Button color="primary" onClick={generate}>Refresh</Button>{' '}
                        </div>
                    </div>

                    <div className="row mt-2">
                        <div className="col-md-3">
                            <label> Public Key </label>
                            <span className="bottom tsize-400 tooltip ml-1">
                                <AiOutlineInfoCircle className="infoIcon" />
                                <span className="tooltiptext tooltip-top">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <p>Upload the public key. <a href="https://g8keeperapp.com/how-it-works/private-and-public-key-creation/" target="_blank">Click here</a> for steps on how to generate private / public key pair.</p>
                                        </div>
                                    </div>
                                </span>
                            </span>
                        </div>

                        <div className="col-md-9">
                            <input type="file" onChange={(e) => onChangeFileUpload(e)}></input>
                            {showPublic ? <p class="text-success pt-2">Public key Already is there</p> : ""}
                            {error && error.public_key && <Error error={error.public_key}></Error>}
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-md-3">
                            <label> Private Key</label>
                            <span className="bottom tsize-400 tooltip ml-1">
                                <AiOutlineInfoCircle className="infoIcon" />
                                <span className="tooltiptext tooltip-top">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <p>Provide absolute path of the private key, which should be stored on your server. <a href="https://g8keeperapp.com/how-it-works/private-and-public-key-creation/" target="_blank">Here</a> is how to generate private / public key pair.</p>
                                        </div>
                                    </div>
                                </span>
                            </span>
                        </div>
                        <div className="col-md-9">
                            <Input type="text" value={private_key} onChange={(e) => setPrivateKey(e.target.value)}></Input>
                            {error && error.private_key && <Error error={error.private_key}></Error>}
                        </div>
                    </div>
                    <div className='text-center pt-4'>
                      <Button color="primary" onClick={() => onSavePublicPrivateKey()}>Save</Button>
                    </div>                    
                </ModalBody>                
            </Modal>
        </div>
    );
}

export default SecretKeyPopup;


const rules = [
    {
        field_name: 'private_key',
        label: 'Private Key',
        type: "string",
        isRequired: true
    },
    {
        field_name: 'public_key',
        label: 'Public Key',
        type: "string",
        isRequired: true
    }
]