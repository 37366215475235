import React, {useEffect, useState} from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

const TablePagination = (props) => {
  const [currentPage, setCurrentPage]= useState(0)
  const [pageCount, setPageCount]= useState(0)

  useEffect(()=>{
    setCurrentPage(props.currentPage)
    setPageCount(props.pageCount)
  },[props.currentPage,props.pageCount])

  return (
    <Pagination size="sm" aria-label="Page navigation example">
      <PaginationItem>
        <PaginationLink first href="javascript:void(0)" onClick={props.onClickPage && currentPage !== 1 ? () => props.onClickPage(1) : null} />
      </PaginationItem>
      <PaginationItem>
        <PaginationLink previous href="javascript:void(0)" onClick={props.onClickPage && currentPage !== 0 && currentPage !== 1 ? () => props.onClickPage(currentPage - 1) : null} />
      </PaginationItem>

      {pageCount !== 0 && Array.from(Array(pageCount), (e, i) => {
        
        if (pageCount <= 5 ||(currentPage <= 3 && i < 5)||(currentPage >= pageCount-2 && i >= pageCount-5 ) ||(currentPage-4 < i && i < currentPage+2 )) {
          return <PaginationItem active={currentPage === i + 1} key={i}>
            <PaginationLink href="javascript:void(0)" onClick={props.onClickPage ? () => props.onClickPage(i + 1) : null}>
              {i + 1}
            </PaginationLink>
          </PaginationItem>
        }
        //  else {
        //   if ((currentPage <= 3 && i < 5)||(currentPage >= pageCount-2 && i >= pageCount-5 ) ||(currentPage-4 < i && i < currentPage+2 ) ) {
        //     return <PaginationItem active={currentPage === i + 1}>
        //       <PaginationLink href="javascript:void(0)" onClick={props.onClickPage ? () => props.onClickPage(i + 1) : null}>
        //         {i + 1}
        //       </PaginationLink>
        //     </PaginationItem>
        //   }
        // }
      })}

      {/* <PaginationItem active={currentPage}>
        <PaginationLink href="javascript:void(0)">
          {currentPage}
        </PaginationLink>
      </PaginationItem> */}


      <PaginationItem>
        <PaginationLink next href="javascript:void(0)" onClick={props.onClickPage && currentPage !== pageCount ? () => props.onClickPage(currentPage + 1) : null} />
      </PaginationItem>
      <PaginationItem>
        <PaginationLink last href="javascript:void(0)" onClick={props.onClickPage && pageCount !== currentPage ? () => props.onClickPage(pageCount ? pageCount : 0) : null} />
      </PaginationItem>
    </Pagination>
  );
}

export default TablePagination;