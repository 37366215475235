import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input } from 'reactstrap';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Select } from '../CommonComponents/index'
import { monitorUrlsServices } from '../../_services/servicesWithSecondOrigin/index'
import toastr from 'toastr'
import Switch from '../CommonComponents/SwitchBotton/SwitchBotton'
import Notification from './Notification'
import { MdModeEdit, MdAdd } from "react-icons/md";
const AddEditUrlMonitorPopup = (props) => {
    const {
        className
    } = props;
    const [modal, setModal] = useState(false);
    const [frequency, setFrequency] = useState(1);
    const [url, setURL] = useState("");
    const [domain_name, setDomain] = useState("")
    const [status, setStatus] = useState(false);
    const toggle = () => setModal(!modal);

    useEffect(() => {
        if (props.allData) {
            setFrequency(parseInt(props.allData.Frequency))
            setURL(props.allData.URL)
            setDomain(props.allData.domain_name)
            setStatus(parseInt(props.allData.Status) === 1 ? true : false)
        }
    }, [props.allData])
    const onClickSave = async () => {
        let data = {
            "domain_name": props.edit ? domain_name : url,
            "urls": props.edit ?
                [
                    {
                        "id": props.allData.id,
                        "url": url,
                        "status": status ? "1" : '0',
                        "frequency": frequency
                    }

                ]
                : [
                    {
                        "url": url,
                        "frequency": frequency
                    }]
        }

        let response = ""
        if (props.edit) {
            response = await monitorUrlsServices.updateMonitorURLs(data)
        } else {
            response = await monitorUrlsServices.addMonitorURLs(data)
        }

        if (response && response.message) {
            toastr.info(response.message)
            toggle();
            if (props.callback) {
                props.callback()
            }
        }
    }

    const onClickSwitch = (value) => {
        setStatus(value)
    }

    let options = []
    for (let i = 1; i <= 15; i++) {
        options.push({
            label: i,
            value: i * 60
        })
    }


    return (
        <div>
            {props.domainDetails ? <OverlayTrigger placement="bottom" overlay={<Tooltip>Add URLs</Tooltip>}>
                <Button className="iconBtn addIconBtn" onClick={toggle}><MdAdd /></Button>
            </OverlayTrigger> : props.edit ? <OverlayTrigger placement="bottom" overlay={<Tooltip>Edit</Tooltip>}>
                <Button className="iconBtn" onClick={toggle}> <MdModeEdit /></Button>
            </OverlayTrigger> :

                <OverlayTrigger placement="bottom" overlay={<Tooltip>Add URLs</Tooltip>}>
                    <Button className="iconBtn addIconBtn" onClick={toggle} label={props.edit ? "" : ""}><MdAdd /></Button>
                </OverlayTrigger>
            }



            <Modal isOpen={modal} toggle={toggle} className={className}>
                <ModalHeader toggle={toggle}>{props.edit ? "Edit" : "Add"} URL</ModalHeader>
                <ModalBody>
                    <label>URL</label>
                    <Input type="text" onChange={e => setURL(e.target.value)} value={url}></Input>
                    <label className="pt-3">Frequency (in minutes)</label>
                    <Select options={options} setSelectedOption={
                        (e) => setFrequency(e.value)
                    }
                        selectedOption={frequency}
                    ></Select>
                    {props.edit && <div>
                        <label className="pt-3">Status</label>
                        <div> <Switch checked={status} onClick={(e) => onClickSwitch(e)}></Switch></div>
                    </div>}


                </ModalBody>
                <ModalFooter>
                    <div className="text-right mb-3">
                        <Button className="mr-2" style={{ minWidth: '150px' }} color="primary" onClick={() => onClickSave()}>{props.edit ? "Update" : "Save"}</Button>{' '}
                        <Button className="ml-2" style={{ minWidth: '150px' }} color="secondary" onClick={toggle}>Cancel</Button>
                    </div>
                </ModalFooter>
                {props.edit && <ModalBody>
                    <Notification MSId={props.allData.id} callback={toggle}></Notification>
                </ModalBody>}
            </Modal>
        </div>
    );
}

export default AddEditUrlMonitorPopup;