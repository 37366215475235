import React from "react";
// import TwitterLogin from "react-twitter-auth";
import TwitterLogin from "react-twitter-login";
import twitter_icon from '../../assets/img/twitter_icon.png'
// export default (props) => {
//   const onFailed = (err, data) => {
//     console.log("ddddddddddd",err, data);
//   };

//   const onSuccess = (err, data) => {
//     console.log("ddddddddddd",err, data);
//   };

//   return (
//     <TwitterLogin
//       consumerKey="VtlCtqH2tWV7e5B8qq8EQbayh"
//       consumerSecret="4csmDlRXvj5em3ktsPtNN4V1CVQHSmrl83HVzssCM92djuzaLs"
//       loginUrl="http://localhost:4000/api/v1/auth/twitter"
//       onFailure={onFailed}
//       onSuccess={onSuccess}
//       requestTokenUrl="http://localhost:4000/api/v1/auth/twitter/reverse"
//     />
//   );
// };

const TwitterAuth =(props)=>{
  const authHandler = (err, data) => {
    if(data){
      props.twitterData(data)
    }
  };
  
  return <TwitterLogin
  authCallback={authHandler}
  consumerKey="AeDGy7uR6LKWLM0vdemr1juv9"
  consumerSecret="xXUorXDqRaA8h82WfGmpUvS7mAQKoZDd90HL7fqnRlK65k4Tqn"
  onFailure={console.log("twitter fail")}
  children={<div><button className="twitterBtn"><img src={twitter_icon} /></button><label>Login with Twitter</label></div>}
/>
}
// http://localhost:3000/g8keeper/view-domain-details

export default TwitterAuth




