import React, { useEffect, useState } from "react";
import LineStackChart from "../Charts/LineStackChart";
import { domainServices } from "../../_services/servicesWithSecondOrigin/index";
import { Loader } from "../../_components/CommonComponents/index";

const SeoMonitorChartOperation = ({ setShow, ...props }) => {
    const [xAxis, setXAxis] = useState(false);
    const [yAxis, setyAxis] = useState(false);
    const [series, setSeries] = useState(false);
    const [dataSizeType, setDataSizeType] = useState("");
    const [loader, setLoader] = useState(false);
    const [reload, setReload] = useState(true);
    const [message, setMessage] = useState("");

    useEffect(() => {
        if (props.domain_name) getGraphDataFromServer();
    }, [props.refresh, props.domain_name, props.myTimezone]);

    const getGraphDataFromServer = async () => {
        setMessage("");
        setSeries([
            {
                data: [
                    {
                        name: "",
                        data: [],
                        type: "line",
                    },
                ],
                type: "line",
            },
        ]);

        setXAxis({
            type: "category",
            data: ["3:00"],
        });
        setLoader(true);
        let data = {
            domain_name: props.domain_name,
            start_datetime: props.start_datetime,
            end_datetime: props.end_datetime,
            timezone: props.myTimezone,
        };

        let response = await domainServices.seoChartData(data);
        if (response && response.data) {
            let series = [];
            setReload(false);
            if (response.data && response.data.final_timeStamp) {
                setXAxis({
                    type: "category",
                    data: response.data.final_timeStamp,
                });
            }
            if (response.data.data) {
                Object.keys(response.data.data).forEach((rowName) => {
                    series.push({
                        name: rowName.toString(),
                        data: response.data.data[rowName],
                        type: "line",
                        //  smooth: true,
                    });
                });
                setSeries(series);
                setReload(true);
            } else {
                setReload(true);
            }
        } else {
            if (response && response.message) {
                setMessage(response.message);
            }
        }

        setyAxis({
            type: "value",
        });
        setLoader(false);
    };

    return (
        <>
            <div className="chart-loader">
                {" "}
                <Loader loader={loader || props.loader}></Loader>
            </div>
            {message === "Waiting for data!" ? (
                <div className="text-center" >
                    <h3 className="chart-configure chartInfo">
                        SEO monitoring is configured, you can  <span style={{ textDecoration: "underline", cursor: "pointer" }} onClick={() => setShow(true)}>
                            Click Here
                        </span>{" "} to view or modify your keywords.
                        It may take us upto 24 hours to update the data.
                    </h3>
                </div> 
            ) : message ? (
                <div className="text-center">
                    <h3 className="chart-configure">
                        {message}.{" "}
                        <span style={{ textDecoration: "underline", cursor: "pointer" }} onClick={() => setShow(true)}>
                            Click Here
                        </span>{" "}
                        to configure now.
                    </h3>
                </div>
            ) : (
                <>
                    {reload && (
                        <LineStackChart
                            xAxis={xAxis}
                            yAxis={yAxis}
                            series={series}
                            dataSizeType={dataSizeType}
                            {...props}
                        // notShow={true}
                        ></LineStackChart>
                    )}
                </>
            )}
        </>
    );
};
export default SeoMonitorChartOperation;
