import React, { Fragment, useState, useEffect } from 'react'
import { Tooltip } from '../CommonComponents/index'
import { Button } from 'reactstrap';
import { notificationChannelServices } from '../../_services/servicesWithSecondOrigin/index'
import { MdDelete } from "react-icons/md";
import { IoIosArrowRoundBack } from "react-icons/io";
import toastr from 'toastr'
import { confirmAlert } from 'react-confirm-alert'

import ActivateNotificationChannel from './ActivateNotificationChannel'
import PageLoader from '../CommonComponents/PageLoader/Loader'
import { AiOutlineColumnHeight } from "react-icons/ai";
import AddNotificationPopup from '../../Pages/NotificationChannel/addNotificationPopup'

const NotificationChannelList = ({ ...props }) => {
    const [data, setData] = useState([])
    const [masterChannel, setMasterChannelData] = useState([])
    const [isLoading, setLoading] = useState(true)
    useEffect(async () => {
        getData()
        getMasterChannelData()
    }, [])

    const getMasterChannelData = async () => {
        let response = await notificationChannelServices.getAllMasterNotificationChannel()
        if (response && response.data) {
            setMasterChannelData(response.data)
        }
    }

    const getData = async () => {
        setLoading(true)
        let response = await notificationChannelServices.getAllNotificationChannel()
        if (response && response.data && Array.isArray(response.data)) {
            setData(response.data)
        }
        setLoading(false)
    }

    const deleteServer = (id) => {
        confirmAlert({
            title: 'Confirm to Delete',
            message: <p>Are you sure to do this.</p>,
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        let response = await notificationChannelServices.deleteNotificationChannel(id)
                        if (response && response.message) {
                            toastr.info(response.message)
                        }
                        if (response && response.status === 200) {
                            getData()
                        }
                    }
                },
                {
                    label: 'No',
                    onClick: () => console.log("No")
                }
            ]
        });
    }

    const autoNotificationSetting = (id, in_default) => {
        confirmAlert({
            title: 'Confirm to ' + (in_default === "0" ? "Activate" : in_default === "1" ? "De-activate" : "") + 'Auto Notification Setting',
            message: <p>Are you sure to do this.</p>,
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        let data = {
                            "in_default": in_default === "1" ? "0" : "1"
                        }
                        let response = await notificationChannelServices.autoNotificationSettingActivate(data, id)
                        if (response && response.message) {
                            toastr.info(response.message)
                        }
                        if (response && response.status === 200) {
                            getData()
                        }
                    }
                },
                {
                    label: 'No',
                    onClick: () => console.log("No")
                }
            ]
        });
    }

    return <Fragment>
        <PageLoader isLoading={isLoading}> </PageLoader>
        <div className="card">
            <div className="card-header justify-content-start">
                <Button className="backBtn mr-3" onClick={() => props.history.push('/view-notification-permission')}><IoIosArrowRoundBack></IoIosArrowRoundBack></Button>
                <h3>Notification Channel</h3>
                <AddNotificationPopup callback={() => {
                    getData()
                    getMasterChannelData()
                }} addpop={true} />

            </div>

            <div className="card-header-buttons">

            </div>
            <div className="table-responsive">
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th scope="col">Channel</th>
                            <th scope="col">Channel Name</th>
                            <th scope="col">Status</th>
                            <th scope="col">Auto Notification Setting </th>
                            <th scope="col">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Array.isArray(data) && data.map((obj, index) => {
                            let myChannel = "";
                            Array.isArray(masterChannel) && masterChannel.map((c_obj) => {
                                if (parseInt(c_obj.id) === parseInt(obj.master_channel_id)) {
                                    myChannel = c_obj.channel_name
                                }
                            })
                            let isEmail = Object.keys(obj.channel_variables).includes("email")
                            return <tr key={index}>
                                <td>{myChannel}</td>
                                <td>{obj.custom_channel_name}{isEmail&& obj.channel_variables && obj.channel_variables.email ? ` (${obj.channel_variables && obj.channel_variables.email})` : ""}</td>
                                <td><ActivateNotificationChannel
                                    myChannel={myChannel}
                                    obj={obj}
                                    getData={getData}
                                /></td>
                                <td>{(obj.in_default === "1" || obj.in_default === 1) ? "Active" : (obj.in_default === "0" || obj.in_default === 0) ? "In-active" : ""}</td>
                                <td>
                                    <div className="tableActionBtns">
                                        {/* <Tooltip UI={<GrView className="actionIcon" onClick={() => props.history.push('/view-notification-channel', { id: obj.id })}></GrView>} tooltip="View domain details" /> */}
                                        <Tooltip UI={
                                            // <MdEdit className="actionIcon" onClick={() => { props.history.push('/update-notification-channel', { id: obj.id, update: true }) }}></MdEdit>

                                            <AddNotificationPopup callback={() => {
                                                getData()
                                                getMasterChannelData()
                                            }} editpop={true}
                                                id={obj.id}
                                            />

                                        } tooltip="Edit domain details" />
                                        <Tooltip UI={<MdDelete className="actionIcon deleteIcon" onClick={() => deleteServer(obj.id)}></MdDelete>} tooltip="Delete domain" />
                                        <Tooltip UI={<AiOutlineColumnHeight className="actionIcon" onClick={() => autoNotificationSetting(obj.id, obj.in_default)}></AiOutlineColumnHeight>} tooltip="Auto Notification Setting" />

                                    </div>
                                </td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    </Fragment>
}

export default NotificationChannelList