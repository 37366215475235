import React, { useEffect, useState } from "react";
import moment from "moment";
import LineStackChart from "../Charts/LineStackChart";
import { graphService } from "../../_services/servicesWithSecondOrigin/index";
import { MemoryType } from "./Constants";
import { Loader } from "../../_components/CommonComponents/index";
const BackupChartOperation = ({ ...props }) => {
  const [xAxis, setXAxis] = useState(false);
  const [yAxis, setyAxis] = useState(false);
  const [series, setSeries] = useState(false);
  const [setUnSet, setUnSetUI] = useState(true);
  const [dataSizeType, setDataSizeType] = useState("");
  const [loader, setLoader] = useState(true);
  useEffect(() => {
    if (props.uuid) getGraphDataFromServer();
  }, [
    props.uuid,
    props.active,
    props.start_datetime,
    props.end_datetime,
    props.mytimezone,
    props.unique_id,
  ]);

  const getGraphDataFromServer = async () => {
    setSeries([
      {
        name: "",
        data: [0],
        type: "line",
        smooth: true,
      },
    ]);
    setXAxis({
      type: "category",
      data: ["3:00"],
    });
    setLoader(true);
    var d = new Date();
    var n = d.getTimezoneOffset();
    let data = {
      uuid: props.uuid,
      user_element: props.user_element,
      start_datetime: props.start_datetime
        ? props.start_datetime
        : moment(new Date())
            .subtract(1, "months")
            .format("YYYY-MM-DD HH:mm:ss"),
      end_datetime: props.end_datetime
        ? props.end_datetime
        : moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      timezone: props.mytimezone,
      timediff: n,
    };

    if (props.unique_id) {
      data.unique_id = props.unique_id;
    }

    let response = {};
    if (
      props.user_element !== "db_backup" &&
      props.user_element !== "folder_backup"
    ) {
      response = await graphService.getGraphData(data);
      console.log("ssssssssssssssssssss", response);
    } else {
      response = await graphService.getGraphData2(data);
    }
    setUnSetUI(false);
    if (response && response.data) {
      if (Array.isArray(response.data.final_timeStamp)) {
        setXAxis({
          type: "category",
          data: response.data.final_timeStamp,
        });
      } else {
        setXAxis({
          type: "category",
          data: [],
        });
      }
      const Series = [];
      let inDataSize = "";
      response.data.data &&
        Array.isArray(Object.keys(response.data.data)) &&
        Object.keys(response.data.data).map((object) => {
          if (
            Array.isArray(response.data.data[object]) &&
            MemoryType.includes(props.user_element)
          ) {
            response.data.data[object].map((check, index) => {
              // if (parseInt(check) / 1024 ** 3 > 1) {
              //     inDataSize = "GB"
              //     response.data.data[object][index] = (parseInt(check) / 1024 ** 3).toFixed(2)
              // } else if (parseInt(check) / 1024 ** 2 > 1) {
              //     inDataSize = "MB"
              //     response.data.data[object][index] = (parseInt(check) / 1024 ** 2).toFixed(2)
              // } else if (parseInt(check) / 1024 ** 1 > 1) {
              //     inDataSize = "KB"
              //     response.data.data[object][index] = (parseInt(check) / 1024 ** 1).toFixed(2)
              // }else{
              //     response.data.data[object][index] = (parseInt(check)).toFixed(2)
              // }

              inDataSize = "MB";
              setDataSizeType(inDataSize);
              response.data.data[object][index] = (
                parseInt(check) /
                1024 ** 2
              ).toFixed(2);
            });
          } else {
          }

          Array.isArray(response.data.data[object]) &&
            Series.push({
              name: object,
              data: response.data.data[object],
              type: "line",
              smooth: true,
            });
        });
      setUnSetUI(true);
      if (Series.length === 0) {
        setSeries([
          {
            name: "",
            data: [0],
            type: "line",
            smooth: true,
          },
        ]);
      } else {
        setSeries(Series);
      }
      setLoader(false);
    } else {
      setUnSetUI(true);
      setLoader(false);
      setSeries([
        {
          name: "",
          data: [0],
          type: "line",
          smooth: true,
        },
      ]);
      setXAxis({
        type: "category",
        data: [],
      });
    }
  };

  return (
    <>
      <div className="chart-loader">
        {" "}
        <Loader loader={loader}></Loader>
      </div>
      {setUnSet && (
        <LineStackChart
          xAxis={xAxis}
          yAxis={yAxis}
          series={series}
          dataSizeType={dataSizeType}
          {...props}
        ></LineStackChart>
      )}
    </>
  );
};
export default BackupChartOperation;
