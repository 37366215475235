import React, { useRef, useEffect, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import refreshImg from './../../assets/img/refresh.png'

const LineStackChart = ({ ...props }) => {
    let myref = useRef()
    const [setUnSet, setUnSetUI] = useState(true)
    useEffect(() => {
        if (props.onRefresh) {
            setUnSetUI(false)
            setTimeout(() => {
                setUnSetUI(true)
            }, 1)
        }
    }, [props.onRefresh])
    const options = {
        grid: {
            left: '3%',
            right: '4%',
            bottom: props.dataZoom ? '18%' : '3%',
            containLabel: true
        },
        xAxis: {
            type: 'category',
            data: ['03:00', '04:00'],
        },
        yAxis: {
            type: 'value',
            // axisLabel: {
            //     formatter: (data, val) => {
            //         return props.allData.length > 8 ? (props.allData[2*val] ?props.allData[2*val].Port:0):(props.allData[data] ?props.allData[data].Port:0)
            //     }
            // },
        },
        series: [
            {
                data: [0],
                type: 'line',
                smooth: true,
            },

        ],

    };
    if (props.title) {
        options.title = {
            text: props.title
        }
    }
    if (props.toolbox) {
        options.toolbox = {
            feature: {
                saveAsImage: {}
            },
            right: "06%"
        }

        // for restore custom toolbox feature
        if (props.restore) {
            options.toolbox.feature.myTool1 = {
                show: true,
                title: 'Refresh',
                icon: 'image://' + refreshImg,
                onclick: function () {
                    if (myref && myref.current && myref.current.getEchartsInstance() && myref.current.props) {
                        if (props.getRestoreChart) {
                            props.getRestoreChart();
                        } else {
                            myref.current.getEchartsInstance().setOption(myref.current.props.option, true);
                        }
                    }
                }
            };
        }
    }

    if (props.tooltip) {
        options.tooltip = {
            trigger: 'axis',
            formatter: function (e) {
                // let rowData = e.name
                // rowData = rowData + `<br/> ${e.marker}   ${e.seriesName ? e.seriesName : ""}
                // <br/> Address:Port:  ${props.allobj && props.allobj[e.seriesName] ? props.allobj[e.seriesName]['Address:Port'] : ""}
                // <br/> Netid:  ${props.allobj && props.allobj[e.seriesName] ? props.allobj[e.seriesName]['Netid'] : ""}
                // <br/> State:  ${props.allobj && props.allobj[e.seriesName] ? props.allobj[e.seriesName]['State'] : ""}
                // <br/> Recv-Q:  ${props.allobj && props.allobj[e.seriesName] ? props.allobj[e.seriesName]['Recv-Q'] : ""}
                // `
                // return rowData

                let rowData = e[0].name
                if (props.publicPort) {
                    Array.isArray(e) && e.map((data) => {
                        // Add option in tooltip only if value is present
                        let isValueEmpty = data.value !== "";
                        if(isValueEmpty){
                            rowData = rowData + `<br/>  ${data.marker}   ${data.seriesName} `
                        }                        
                    })
                } else {
                    Array.isArray(e) && e.map((data) => {
                        let isValueEmpty = data.value !== "";
                        if (props.allobj && props.allobj[data.seriesName] && props.allobj[data.seriesName]['Peer'] && props.allobj[data.seriesName]['Peer'].match(/\".+?\"/i) && (props.allobj[data.seriesName]['Peer'].match(/\".+?\"/i)).toString().replace(/"/g, '')) {
                        // Add option in tooltip only if value is present
                            if(isValueEmpty){                            
                            rowData = rowData + `<br/> ${data.marker}   ${data.seriesName} 
                                    ${props.allobj && props.allobj[data.seriesName] ? props.allobj[data.seriesName]['Address:Port'] : ""}
                                    (${props.allobj && props.allobj[data.seriesName] ? props.allobj[data.seriesName]['Netid'] : ""})
                                    ${props.allobj && props.allobj[data.seriesName] && props.allobj[data.seriesName]['Peer'] && (props.allobj[data.seriesName]['Peer'].match(/\".+?\"/i)).toString().replace(/"/g, '')}
                                    `}
                            } else if (props.allobj && props.allobj[data.seriesName] && props.allobj[data.seriesName]['Address:Port']) {
                                // Add option in tooltip only if value is present                        
                                if(isValueEmpty){
                                    rowData = rowData + `<br/> ${data.marker}   ${data.seriesName} 
                                    ${props.allobj && props.allobj[data.seriesName] ? props.allobj[data.seriesName]['Address:Port'] : ""}
                                    (${props.allobj && props.allobj[data.seriesName] ? props.allobj[data.seriesName]['Netid'] : ""})
                                `}
                            }
                    })
                }
                return rowData
            },
        }
        if (localStorage.getItem('themes') === 'dark') {
            options.tooltip = {
                ...options.tooltip, backgroundColor: '#30384c',
                textStyle: {
                    color: '#b6c2e4'
                },
            }
        }
    }
    if (props.grid) {
        options.grid = props.grid
    }
    if (props.xAxis) {
        options.xAxis = props.xAxis
    }
    if (props.yAxis) {
        options.yAxis = props.yAxis
    }
    if (props.series) {
        options.series = props.series
    }

    if (props.dataZoom) {
        options.dataZoom = [{}]
    }

    useEffect(() => {
        if (props.getEchartRef && myref) {
            props.getEchartRef(myref)
        }
    }, [])


    const onEvents = (e) => {
        let eventObj = e;
        // console.log("{chk ==> ", eventObj)
    }
    return (<>{setUnSet && <ReactECharts ref={myref} option={options ? options : {}} formatFunction={onEvents} />}</>)
}
export default LineStackChart
