let rules = [
  {
    field_name: "server_name",
    label: "Server Name",
    type: "string",
    isRequired: true,
  },
  // {
  //     field_name: 'server_description',
  //     label: 'Server Description',
  //     type: "string",
  //     isRequired: true
  // },
  {
    field_name: "plan_id",
    label: "Plan",
    type: "string",
    isRequired: true,
  },
];

let fileRules = [
  {
    field_name: "friendly_folder_name",
    label: "Friendly Folder Name",
    type: "string",
    isRequired: true,
  },
  {
    field_name: "folder",
    label: "Folder(add full folder path)",
    type: "string",
    isRequired: true,
  },
  // {
  //     field_name: 'not_allowed_file_types',
  //     label: 'Not Allowed File Types (Comma separated list)',
  //     type: "string",
  //     isRequired: true
  // },
  // {
  //     field_name: 'exclude_sub_folders',
  //     label: 'Exclude Sub Folders (one sub-folder per line)',
  //     type: "string",
  //     isRequired: true
  // },
  // {
  //     field_name: 'allowed_file_types',
  //     label: 'Allowed File Types (Comma separated list)',
  //     type: "string",
  //     isRequired: true
  // }
];

const frequencyCheck = [
  {
    field_name: "frequencyCheck",
    label: "Frequency For Checks(seconds)",
    type: "string",
    isRequired: true,
  },
];

const hour = () => {
  let array = [];
  for (let i = 0; i < 24; i++) {
    array.push({ label: i, value: i });
  }
  return array;
};

const day = () => {
  let array = [{ label: "Every Day", value: "*" }];
  for (let i = 0; i < 31; i++) {
    array.push({ label: i + 1, value: i + 1 });
  }
  return array;
};

const month = [
  { value: "*", label: "Every Month" },
  { value: "1", label: "January" },
  { value: "2", label: "February" },
  { value: "3", label: "March" },
  { value: "4", label: "April" },
  { value: "5", label: "May" },
  { value: "6", label: "June" },
  { value: "7", label: "July" },
  { value: "8", label: "August" },
  { value: "9", label: "September" },
  { value: "10", label: "October" },
  { value: "11", label: "November" },
  { value: "12", label: "December" },
];

const month31 = [
  { value: "*", label: "Every Month" },
  { value: "1", label: "January" },
  { value: "3", label: "March" },
  { value: "5", label: "May" },
  { value: "7", label: "July" },
  { value: "8", label: "August" },
  { value: "10", label: "October" },
  { value: "12", label: "December" },
];

const month31check = [1, 3, 5, 7, 8, 10, 12];
const month30 = [
  { value: "*", label: "Every Month" },
  { value: "1", label: "January" },
  { value: "3", label: "March" },
  { value: "4", label: "April" },
  { value: "5", label: "May" },
  { value: "6", label: "June" },
  { value: "7", label: "July" },
  { value: "8", label: "August" },
  { value: "9", label: "September" },
  { value: "10", label: "October" },
  { value: "11", label: "November" },
  { value: "12", label: "December" },
];

const month30check = [1, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

const weekDay = [
  { value: "*", label: "All Day" },
  { value: "1", label: "Monday" },
  { value: "2", label: "Wednessday" },
  { value: "3", label: "Thursday" },
  { value: "4", label: "Friday" },
  { value: "5", label: "Saturday" },
  { value: "6", label: "Sunday" },
];

let DBBackupRule = [
  {
    field_name: "friendly_db_name",
    label: "Friendly DB Name",
    type: "string",
    isRequired: true,
  },
  {
    field_name: "db_username",
    label: "DB Username",
    type: "string",
    isRequired: true,
  },
  {
    field_name: "db_password",
    label: "DB Password",
    type: "string",
    isRequired: true,
  },
  {
    field_name: "host",
    label: "Host",
    type: "string",
    isRequired: true,
  },
  {
    field_name: "db_name",
    label: "DB Name",
    type: "string",
    isRequired: true,
  },
];

const uploadPath = [
  {
    field_name: "bucket_name",
    label: "Bucket Name",
    type: "string",
    isRequired: true,
  },
  {
    field_name: "access_key",
    label: "Access Key",
    type: "string",
    isRequired: true,
  },
  {
    field_name: "secret_code",
    label: "Secret Code",
    type: "string",
    isRequired: true,
  },
  // {
  //     field_name: 'step_to_create_s3_bucket',
  //     label: 'Steps To Create S3 bucket',
  //     type: "string",
  //     isRequired: true
  // },
  // {
  //     field_name: 'log_level',
  //     label: 'Log Level',
  //     type: "string",
  //     isRequired: true
  // },
  // {
  //     field_name: 'cpu_time',
  //     label: 'CPU Time(In Seconds)',
  //     type: "string",
  //     isRequired: true
  // },
  {
    field_name: "region_name",
    label: "Region Name",
    type: "string",
    isRequired: true,
  },
];

const uploadPath_dropbox = [
  {
    field_name: "dropbox_token",
    label: "Dropbox Token ",
    type: "string",
    isRequired: true,
  },
  {
    field_name: "dropbox_path",
    label: "Dropbox Path",
    type: "string",
    isRequired: true,
  },
  // {
  //     field_name: 'log_level',
  //     label: 'Log Level',
  //     type: "string",
  //     isRequired: true
  // },
  // {
  //     field_name: 'cpu_time',
  //     label: 'CPU Time(In Seconds)',
  //     type: "string",
  //     isRequired: true
  // },
];

const uploadPath_google = [
  {
    field_name: "google_folder_name",
    label: "Google Folder Name",
    type: "string",
    isRequired: true,
  },
  // {
  //     field_name: 'google_json',
  //     label: 'Google JSON',
  //     type: "string",
  //     isRequired: true
  // },

  // {
  //     field_name: 'google_access_token',
  //     label: 'Google Access Token',
  //     type: "string",
  //     isRequired: true
  // },
  // {
  //     field_name: 'google_client_id',
  //     label: 'Google Client Id',
  //     type: "string",
  //     isRequired: true
  // },
  // {
  //     field_name: 'google_client_secret',
  //     label: 'Google Client Secret',
  //     type: "string",
  //     isRequired: true
  // },
  // {
  //     field_name: 'google_refresh_token',
  //     label: 'Google Refresh Token',
  //     type: "string",
  //     isRequired: true
  // },
  // {
  //     field_name: 'log_level',
  //     label: 'Log Level',
  //     type: "string",
  //     isRequired: true
  // },
  // {
  //     field_name: 'cpu_time',
  //     label: 'CPU Time(In Seconds)',
  //     type: "string",
  //     isRequired: true
  // },
];

let customFieldsRules = [
  {
    field_name: "log_path",
    label: "Path",
    type: "string",
    isRequired: true,
  },
  {
    field_name: "field_separator",
    label: "Separator",
    type: "string",
    isRequired: true,
  },
];

let customFieldsSubRules = [
  {
    field_name: "field",
    label: "Field",
    type: "string",
    isRequired: true,
  },
  {
    field_name: "header",
    label: "Header",
    type: "string",
    isRequired: true,
  },
];

export function Constants() {
  return {
    rules,
    fileRules,
    frequencyCheck,
    hour,
    day,
    month,
    month31,
    month30,
    weekDay,
    DBBackupRule,
    uploadPath,
    uploadPath_dropbox,
    uploadPath_google,
    month31check,
    month30check,
    customFieldsRules,
    customFieldsSubRules,
  };
}
