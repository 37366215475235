import React, { useEffect, useMemo, useState } from "react";
import BarStackChart from "../Charts/BarStackChart";
import { graphService } from "../../_services/servicesWithSecondOrigin/index";
import { Loader } from "../../_components/CommonComponents/index";
import { throttle } from "../../_helpers/Common/Common";
import BarStackChartSingleLine from "../Charts/BarStackChartSingleLine";
import moment from "moment";

const DiskPartitionOperation = ({ height, ...props }) => {
  const [xAxis, setXAxis] = useState(false);
  const [yAxis, setyAxis] = useState(false);
  const [series, setSeries] = useState(false);
  const [seriesFinal, setSeriesFinal] = useState(false);
  const [setUnSet, setUnSetUI] = useState(true);
  const [dataSizeType, setDataSizeType] = useState(" %");
  const [loader, setLoader] = useState(true);
  const [legend, setLegend] = useState([]);
  const [flagg, setFlagg] = useState(0);
  const [finalObjs, setFinalObj] = useState({});
  useEffect(() => {
    if (props.uuid) getDataFromServer();
  }, [props.uuid]);

  var color = [
    "Blue ",
    "Green",
    "Red",
    "Orange",
    "Violet",
    "Indigo",
    "Yellow",
    "Blue ",
    "Green",
    "Red",
    "Orange",
    "Violet",
    "Indigo",
    "Yellow",
  ];
  const getDataFromServer = () => {
    graphService
      .getPartitionData({
        uuid: props.uuid,
        start_datetime: props.start_datetime
          ? props.start_datetime
          : moment(new Date())
              .add(-props.active || -4, "hours")
              .format("YYYY-MM-DD HH:mm:ss"),
        end_datetime: props.end_datetime
          ? props.end_datetime
          : moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
        timezone: props.mytimezone,
        timediff: new Date().getTimezoneOffset(),
      })
      .then((res) => {
        const finalObj =
          Array.isArray(res.data) &&
          res.data.reduce(
            (previousValue, currentValue, currentIndex) => {
              previousValue["partition_name"] = [
                ...previousValue.partition_name,
                currentValue.partition_name,
              ];
              previousValue["mounted_on"] = [
                ...previousValue.mounted_on,
                currentValue.mounted_on,
              ];
              previousValue["partition_availble"] = [
                ...previousValue.partition_availble,
                currentValue?.partition_availble,
              ];
              previousValue["partition_percentage"] = [
                ...previousValue.partition_percentage,
                {
                  value: parseInt(currentValue?.partition_percentage),
                  itemStyle: {
                    color: color[currentIndex],
                  },
                },
              ];
              previousValue["partition_size"] = [
                ...previousValue.partition_size,
                {
                  value: currentValue?.partition_size,
                  itemStyle: {
                    color: color[currentIndex],
                  },
                },
              ];
              previousValue["partition_used"] = [
                ...previousValue.partition_used,
                currentValue?.partition_used,
              ];
              return previousValue;
            },
            {
              partition_name: [],
              mounted_on: [],
              partition_availble: [],
              partition_percentage: [],
              partition_size: [],
              partition_used: [],
            }
          );

        setFinalObj(finalObj);
        setXAxis({
          type: "value",
          boundaryGap: [0, 0.01],
          max: 100,
        });

        let seriesArr = [
          {
            showBackground: true,
            name: "2011",
            type: "bar",
            data: finalObj.partition_percentage,
            label: {
              show: true,
              position: "right",
              valueAnimation: true,
              formatter: function (d) {
                return (
                  finalObj.partition_percentage[d.dataIndex].value + "% Used"
                );
              },
            },
          },
        ];
        setSeries(seriesArr);
        setSeriesFinal(seriesArr);
        setyAxis({
          type: "category",
          data: finalObj.partition_name,
          triggerEvent: true,
        });
      });
  };

  const onEvents = useMemo(
    () => ({
      click: ({ componentType, targetType, value }) => {
        if (componentType !== "yAxis" || targetType !== "axisLabel") return;
        const index = yAxis.data.findIndex((label) => label === value);
        if (index === -1) return;

        setSeriesFinal((prev) =>
          prev.map((s, idx) => ({
            ...s,
            data: s.data.map((n, i) =>
              i !== index ? n : n === 0 ? series[idx].data[i] : 0
            ),
          }))
        );
      },
    }),
    [series, yAxis.data]
  );

  return (
    <>
      <div className="chart-loader">
        {" "}
        {/* <Loader loader={loader}></Loader> */}
      </div>
      {setUnSet && (
        <BarStackChartSingleLine
          xAxis={xAxis}
          yAxis={yAxis}
          series={seriesFinal}
          dataSizeType={dataSizeType}
          legend={legend}
          onEvents={onEvents}
          {...props}
          tooltip={true}
          allData={finalObjs}
          height={height}
        ></BarStackChartSingleLine>
      )}
    </>
  );
};
export default DiskPartitionOperation;
