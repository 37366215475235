import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import AddNotificationChannel from './AddNotificationChannel';
import { MdAdd } from "react-icons/md";
import { MdEdit } from "react-icons/md";
const AddNotificationPopup = (props) => {
    const {
        className
    } = props;

    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);

    return (
        <div className="model-width">
            {props.editpop ? <MdEdit className="actionIcon" onClick={toggle}></MdEdit> : <MdAdd className="headerIcon" onClick={toggle} />}
            {/* <Button color="danger">{buttonLabel}</Button> */}
            <Modal isOpen={modal} toggle={toggle} className={className} >
                <ModalHeader toggle={toggle}>Notification</ModalHeader>
                <ModalBody>
                    <AddNotificationChannel popup={true} callback={() => {
                        toggle()
                        props.callback()
                    }}
                        editpop={props.editpop}
                        id={props.id}
                    ></AddNotificationChannel>
                </ModalBody>
            </Modal>
        </div>
    );
}

export default AddNotificationPopup;