import React, { useState, useEffect } from "react";
import { Select } from "../index";
import { monitorServersServices } from "../../../_services/servicesWithSecondOrigin/index";
import CurrentLoggedIn from "./CurrentLoggedInModel";

const CurentServerLoggedIn = ({ ...props }) => {
  const [data, setData] = useState([]);
  const [unique_id, setUniqueId] = useState([]);
  const [sharedServer, setSharedServer] = useState([]);
  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    calUniqueIdArray();
  }, [props.uuid, data]);

  // useEffect(async () => {
  //     if (props.uuid) {
  //         let data = {
  //             "uuid": props.uuid
  //         }
  //         let res = await monitorServersServices.selectedId(data)
  //         if (res && res.data && res.data && res.data.unique_id) {
  //             let obj = {
  //                 label: res.data.unique_id,
  //                 value: res.data.unique_id
  //             }
  //             onSelectUniqueId(obj)
  //         }
  //     }
  // }, [props.uuid])

  const calUniqueIdArray = () => {
    let nArr = [
      {
        label: "All",
        value: "all",
      },
    ];
    Array.isArray(data) &&
      data.length > 0 &&
      data.map((D) => {
        if (D.value === props.uuid) {
          props.onSetAppVersion && props.onSetAppVersion(D.Appversion);
          Array.isArray(D.unique_id) &&
            D.unique_id.map((obj) => {
              nArr.push({
                label: obj.unique_id,
                value: obj.unique_id,
              });
            });
        }
      });
    setUniqueId(nArr);
  };

  const getData = async () => {
    let response = await monitorServersServices.getAllMonitorServerForChart();
    if (response && response.data && Array.isArray(response.data)) {
      let array_temp = [];
      response.data.map((obj) => {
        array_temp.push({
          label: obj.server_name,
          value: obj.uuid,
          id: obj.id,
          unique_id: obj.unique_id,
          shared: obj.shared,
          Appversion: obj.Appversion,
        });
      });
      setSharedServer(response.Shareddata);
      Array.isArray(response.Shareddata) &&
        response.Shareddata.map((obj) => {
          if (obj.id === props.id) {
            props.onClickChange();
          }
          array_temp.push({
            label: obj.server_name,
            value: obj.uuid,
            id: obj.id,
            unique_id: obj.unique_id,
            shared: obj.shared,
            Appversion: obj.Appversion,
          });
        });
      array_temp = array_temp.sort((a, b) => {
        if (a.label.toLowerCase() < b.label.toLowerCase()) {
          return -1;
        }
        if (a.label.toLowerCase() > b.label.toLowerCase()) {
          return 1;
        }
        return 0;
      });
      setData(array_temp);
    }
  };

  useEffect(() => {
    if (props.id) {
      Array.isArray(sharedServer) &&
        sharedServer.map((obj) => {
          if (obj.id === props.id) {
            props.onClickChange();
          }
        });
    }
  }, [props.id, data]);

  const onSelectServer = async (e) => {
    if (e.value) {
      let data = {
        uuid: e.value,
      };
      let res = await monitorServersServices.selectedId(data);
      if (res && res.data && res.data && res.data.unique_id) {
        let obj = {
          label: res.data.unique_id,
          value: res.data.unique_id,
        };
        onSelectUniqueId(obj);
        if (res.data.unique_id) {
          e.unique = res.data.unique_id;
        }
        props.onSelectServer?.(e);
      } else {
        props.onSelectServer?.(e);
      }
    }

    if (e.unique_id) {
      let nArr = [
        {
          label: "All",
          value: "all",
        },
      ];
      Array.isArray(e.unique_id) &&
        e.unique_id.map((obj) => {
          nArr.push({
            label: obj.unique_id,
            value: obj.unique_id,
          });
        });
      setUniqueId(nArr);
    }
  };

  const onSelectUniqueId = (e) => {
    // if (props.onSelectUniqueId) { props.onSelectUniqueId(e) }
  };

  return (
    <>
      <div className="serverIcons">
        {props.noRender ? (
          ""
        ) : (
          <div className="mr-2 mr-lg-3">
            <CurrentLoggedIn
              uuid={props.uuid}
              mytimezone={props.mytimezone}
            ></CurrentLoggedIn>
          </div>
        )}
        {props.noUIRender ? (
          ""
        ) : (
          <div className="serverDetailsSecSelect">
            <div className="serverListServices">
              <div className="selectServer">
                <Select
                  selectedOption={props.uuid}
                  setSelectedOption={onSelectServer}
                  options={data}
                ></Select>
              </div>
              {/* {props.noRender ? "" : <div className="selectServer">
                        <Select selectedOption={props.unique_id === null ?
                            "all"
                            : props.unique_id} setSelectedOption={(e) => onSelectUniqueId(e)} options={unique_id}></Select>
                    </div>} */}
              <span className="serverUUID">
                {props.uuid ? `(${props.uuid})` : ""}
              </span>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default CurentServerLoggedIn;
