import React, {
    useEffect,
    useState
} from 'react'
import moment from 'moment'
import { graphService } from '../../_services/servicesWithSecondOrigin/index'
import { ChartOperationsApachhe } from './index'
const ChartOperationsApachheOuter = ({ ...props }) => {
    const [timestemp, setTimestemp] = useState([])
    const [keys, setKeys] = useState([])
    const [data, setData] = useState("")

    useEffect(() => {
        if (props.uuid)
            getGraphDataFromServer()
    }, [props.uuid, props.active, props.start_datetime, props.end_datetime, props.isRefresh, props.mytimezone])

    const getGraphDataFromServer = async () => {
        var d = new Date();
        var n = d.getTimezoneOffset();
        let data = {
            "uuid": props.uuid,
            "user_element": "apache_log_data",
            "start_datetime": props.start_datetime ? props.start_datetime : moment(new Date()).add(-props.active || -4, 'hours').format("YYYY-MM-DD HH:mm:ss"),
            "end_datetime": props.end_datetime ? props.end_datetime : moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
            "timezone": props.mytimezone,
            timediff: n,
            //"unique_id": "00000000-0000-0000-0000-0641a6ca2d4c"
        }


        let response = await graphService.getApachheGraphData(data)
        if (response && response.data && response.data.OuterData) {
            if (response.data && response.data.timestamp) { setTimestemp(response.data.timestamp) }
            setKeys(Object.keys(response.data.OuterData))
            setData(response.data.OuterData)
        } else {
            setKeys([])
            setData([])
        }
    }

    return <>
        {Array.isArray(keys) && keys.map((key) => {
            return <div className="row" >
                <div className="col-md-12">
                    <div className="card">
                        {/* <a href="#" className="cross-charts" onClick={() => this.addOrRemoveChartsInList(data)}>X</a> */}

                        <div className="card-header"></div>
                        <div className="largeChartInnerDiv">
                            <ChartOperationsApachhe
                                title={key === "unknown" ? "Default" : key}
                                tooltip={true}
                                toolbox={true}
                                uuid={props.uuid}
                                isLegend={true}
                                dataZoom={true}
                                chartData={data[key]}
                                timestemp={timestemp}
                                outer={true}
                            />
                        </div>
                    </div>
                </div>
            </div>
        })}
    </>
}
export default ChartOperationsApachheOuter