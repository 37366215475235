import toastr from 'toastr'
function FilterResponse(response) {
    try {
        if ((response.status >= 200 &&  response.status<=298)) {
            return response.data;
        }
        else {
            console.log("response.data.message", response.data.message)
          //  toastr.error(response.data.message)
            return false
        }
    }
    catch (error) {
        toastr.error("Something Went Wrong")
       console.log(error);
    }
}
export default FilterResponse;