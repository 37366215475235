import React from "react";
import { Table } from "reactstrap";
import { Pagination, TooltipPro } from "../CommonComponents/index";
import { backUpServices } from "../../_services/servicesWithSecondOrigin/index";
import { Loader } from "../../_components/CommonComponents/index";
import { AiOutlineInfoCircle } from "react-icons/ai";

class DbBackupList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            folderBackupData: "",
            currentPage: 0,
            pageCount: 0,
            perPage: 0,
            totalCount: 0,
            loader: true,
            message: "",
        };
    }

    componentDidMount() {
        if (this.props.uuid)
            this.setState(
                {
                    uuid: this.props.uuid,
                    // start_datetime: this.props.start_datetime,
                    // end_datetime: this.props.end_datetime
                },
                () => {
                    this.getDataFromServer();
                }
            );
    }

    componentDidUpdate(prev) {
        if (prev.uuid !== this.props.uuid || prev.isRefresh !== this.props.isRefresh || prev.unique_id !== this.props.unique_id)
            this.setState(
                {
                    uuid: this.props.uuid,
                    // start_datetime: this.props.start_datetime,
                    // end_datetime: this.props.end_datetime,
                    folderBackupData: "",
                    currentPage: 0,
                    pageCount: 0,
                    perPage: 0,
                    totalCount: 0,
                    loader: true,
                },
                () => {
                    this.getDataFromServer();
                }
            );
    }

    getDataFromServer = async (page) => {
        this.setState({ loader: true });
        let data = {
            uuid: this.state.uuid,
            // "start_datetime": this.state.start_datetime,
            // "end_datetime": this.state.end_datetime
        };

        if (this.props.unique_id) {
            data.unique_id = this.props.unique_id
        }

        let response = await backUpServices.getDBBackUpData(data, page);
        if (response && response.data) {
            this.setState({
                folderBackupData: response.data,
                loader: false,
            });
            if (response._meta) {
                this.setState(response._meta);
            }
            this.props.setMessage("");
        } else {
            if (response && response.message) {
                this.setState(
                    {
                        message: response.message,
                    },
                    () => {
                        this.props.setMessage(this.state.message);
                    }
                );
            }
            this.setState({ loader: false });
        }
    };

    byteIntoGbMbKb = (byte) => {
        let inDataSize = "";
        if (parseInt(byte) / 1024 ** 3 > 1) {
            inDataSize = " GB";
            return (parseInt(byte) / 1024 ** 3).toFixed(2) + inDataSize;
        } else if (parseInt(byte) / 1024 ** 2 > 1) {
            inDataSize = " MB";
            return (parseInt(byte) / 1024 ** 2).toFixed(2) + inDataSize;
        } else if (parseInt(byte) / 1024 ** 1 > 1) {
            inDataSize = " KB";
            return (parseInt(byte) / 1024 ** 1).toFixed(2) + inDataSize;
        } else {
            inDataSize = " Byte";
            return parseInt(byte) + inDataSize;
        }
    };

    render() {
        const { tooltip } = this.props;

        return (
            <div>
                <div className={`card-header${tooltip ? " justify-content-start" : ""}`}>
                    <h3 className="mr-3">DB Backup</h3>
                    {tooltip && (
                        <TooltipPro
                            className="bottom tsize-400"
                            style={{ zIndex: 10 }}
                            tooltip={
                                <div className="row">
                                    <div className="col-md-12">
                                        <p>{tooltip}</p>
                                    </div>
                                </div>
                            }
                        >
                            <AiOutlineInfoCircle className="infoIcon" />
                        </TooltipPro>
                    )}
                </div>
                <div className="chart-loader" style={{ top: "100%" }}>
                    {" "}
                    <Loader loader={this.state.loader}></Loader>
                </div>
                <div className="table-responsive">
                    <Table className="table table-bordered">
                        <thead>
                            <tr>
                                <th>S.No.</th>
                                <th>DB Name</th>
                                <th>File Path</th>
                                <th>Timestamp</th>
                                <th>Message</th>
                                <th>Backup Size</th>
                            </tr>
                        </thead>
                        {Array.isArray(this.state.folderBackupData) && this.state.folderBackupData.length !== 0 ? (
                            <tbody>
                                {Array.isArray(this.state.folderBackupData) &&
                                    this.state.folderBackupData.map((data, index) => {
                                        return (
                                            <tr key={index} >
                                                <th scope="row">{index + 1}</th>
                                                <td>
                                                    {data && data.friendly_name ? (
                                                        <div>
                                                            {data.friendly_name} ({data.db_name})
                                                        </div>
                                                    ) : (
                                                        data.db_name
                                                    )}
                                                </td>
                                                <td>
                                                    <a href={data.aws_file_path} target="_blank" rel="noreferrer">
                                                        Backup Link
                                                    </a>
                                                </td>
                                                <td>{data.timestamp}</td>
                                                <td style={data.status === 0 ?{color: 'rgb(234 84 85)'}:{}}>{data.message}</td>
                                                <td>{this.byteIntoGbMbKb(data.backup_size)}</td>
                                            </tr>
                                        );
                                    })}
                            </tbody>
                        ) : (
                            !this.state.loader && <div className="no-data-found">{this.state.message ? this.state.message : "No data Found"}</div>
                        )}
                    </Table>
                </div>
                {Array.isArray(this.state.folderBackupData) && this.state.folderBackupData.length !== 0 && (
                    <div className="paginationSec">
                        <Pagination
                            currentPage={this.state.currentPage}
                            pageCount={this.state.pageCount}
                            perPage={this.state.perPage}
                            totalCount={this.state.totalCount}
                            onClickPage={(page) => this.getDataFromServer(page)}
                        ></Pagination>
                    </div>
                )}
            </div>
        );
    }
}

export default DbBackupList;
