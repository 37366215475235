import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, Nav, NavItem, NavLink } from 'reactstrap';
import { Constants } from '../NotificationChannelForm/Constants'
import { telegramBotService } from '../../_services/telegramBotService/telegramBotApiService'
import { notificationChannelServices } from '../../_services/servicesWithSecondOrigin/index'
import toastr from 'toastr'
import { Validation } from '../../_helpers/validation/Validation'
import { Error } from '../CommonComponents/index'
const ActivateNotificationChannel = (props) => {
    const {
        className
    } = props;

    const [modal, setModal] = useState(false);
    const [email, setEmail] = useState("");
    const [emailErr, setEmailSeErr] = useState({});
    const toggle = () => setModal(!modal);

    const getDataFromTelegramBot = async () => {
        let response = await telegramBotService.getDataFromTelegram()
        if (response && response.result) {
            let id = ""
            Array.isArray(response.result) && response.result.map((obj) => {
                if (obj && obj.message && obj.message.text === `/start ${props.myChannel + props.obj.id + '122' + props.obj.created_by}`) {
                    id = obj && obj.message && obj.message.chat && obj.message.chat.id ? obj.message.chat.id : ""
                }
            })
            if (id) {
                let data = {
                    "master_channel_id": props.obj.master_channel_id,
                    "custom_channel_name": props.obj.custom_channel_name,
                    "channel_variables": { "chat_id": id },
                    "status": '1'
                }

                let response = await notificationChannelServices.updateNotificationChannel(props.obj.id, data)
                if (response) {
                    if (response && response.message) {
                        toastr.success(response.message)
                    }
                    if (response && response.status === 200) {
                        props.getData()
                        toggle()
                    }
                }

            } else {
                toastr.warning(`Cannot find your message on our bot`)
            }
        }
    }

    const saveEmail = async () => {
        let SLACK =Constants().channels.SLACK === props.myChannel
        let data = {
            "master_channel_id": props.obj.master_channel_id,
            "custom_channel_name": props.obj.custom_channel_name,
            "channel_variables":SLACK ? { "slack": email }: { "email": email },
            "status": '1'
        }

        let flag = 0
        let errobj = Validation({ email: email }, [{
            field_name: 'email',
            label: SLACK?'Slack':'Email',
            type: SLACK?'string': "email",
            isRequired: true
        }]);
        setEmailSeErr(errobj)
        Object.keys(errobj).map(index => {
            if (errobj[index] !== "") {
                flag = 1;
            }
        });
        if (flag === 0) {
            let response = await notificationChannelServices.updateNotificationChannel(props.obj.id, data)
            if (response) {
                if (response && response.message) {
                    toastr.success(response.message)
                }
                if (response && response.status === 200) {
                    props.getData()
                    toggle()
                }
            }
        }
    }

    const deActivateChannel= async()=>{
        let data={
            "status":'2'
        }
        let response = await notificationChannelServices.changeStatusOfChannel(props.obj.id, data)
         if(response && response.message){
             toastr.info(response.message)
             props.getData()
         }
    }
    
    return (
        <div>
            <div> {parseInt(props.obj.status) === 2 ? "In-active" : parseInt(props.obj.status) === 1 ? "Active" : ""} ({parseInt(props.obj.status) === 1 ? <a href="#" onClick={toggle}>In-active</a> : parseInt(props.obj.status) === 2 ? <a onClick={toggle} href="#">Active</a> : ""})</div>
            <Modal isOpen={modal} toggle={toggle} className={className}>
                <ModalHeader toggle={toggle}>Activate Channel</ModalHeader>
                <ModalBody>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <label>Channel</label>
                                <Input value={props.myChannel} readOnly={true}></Input>
                            </div>
                            <div className="col-md-12 mt-2">
                                <label>Channel Custom Name</label>
                                <Input value={props.obj && props.obj.custom_channel_name} readOnly={true}></Input>
                            </div>
                            {(props.obj.status === '2' || props.obj.status === 2) ? <>
                                {Constants().channels.TELEGRAM === props.myChannel && <div className="col-md-12 mt-2">
                                    <Nav>
                                        <NavItem>
                                            <NavLink href={`https://t.me/G8keeper_bot?start=` + props.myChannel + props.obj.id + '122' + props.obj.created_by} target="_blank">Activate Telegram</NavLink>
                                        </NavItem>
                                    </Nav>
                                </div>}

                                {Constants().channels.EMAIL === props.myChannel && <div className="col-md-12 mt-2">
                                    <label>Email Id*</label>
                                    <Input placeholder="Enter Email Id" value={email} onChange={e => setEmail(e.target.value)}></Input>
                                    {emailErr && emailErr.email !== "" && <Error error={emailErr.email} />}
                                </div>}
                                {Constants().channels.SLACK === props.myChannel && <div className="col-md-12 mt-2">
                                    <label>Slack Webhook*</label>
                                    <Input placeholder="Slack Webhook" value={email} onChange={e => setEmail(e.target.value)}></Input>
                                    {emailErr && emailErr.email !== "" && <Error error={emailErr.email} />}
                                </div>}
                            </> : <>
                                    <div className="col-md-12 mt-2">
                                        <Nav>
                                            <NavItem>
                                                <NavLink href="#javascript:void(0)" onClick={()=>deActivateChannel()}>Deactivate Channel</NavLink>
                                            </NavItem>
                                        </Nav>
                                    </div>
                                </>}

                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    {Constants().channels.TELEGRAM === props.myChannel && <Button color="success" onClick={getDataFromTelegramBot}>save</Button>}
                    {(Constants().channels.EMAIL === props.myChannel || Constants().channels.SLACK === props.myChannel) && <Button color="success" onClick={saveEmail}>save</Button>}
                    <Button color="secondary" className="primary-btn" onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

const saveEmail = () => {

}

export default ActivateNotificationChannel;