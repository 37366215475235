import React from "react";
import { Row, Col, Tab, Nav } from "react-bootstrap";
import { generatePath } from "react-router";
import Header from "../../_components/CommonComponents/Header/Header";
import { AiFillSetting, AiOutlineLineChart, AiFillAppstore } from "react-icons/ai";
import { Button } from "reactstrap";
import { UploadPath, ServerBackup, ModifiedFiles, DisallowedFiles, DBBackup, Notification, GeneralSetting, GeneralKeys } from "../../_components/MonitorServersComponents/index";
import { ServerCurrentLoggedIn } from "../../_components/CommonComponents/index";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { objectToQuery } from "../../_helpers/Common/Common";
import MonitorCustomLogs from "../../_components/MonitorServersComponents/MonitorServerSetting/MonitorCustomLogs";

class MonitorServerSetting extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      uuid: "",
      server_name: "",
      active: props.location?.state?.activeTab || 8,
      shared: "",
      defaultActiveKey: "8",
    };
  }

  async componentWillMount() {
    this.onScrollById();
  }
  async componentDidMount() {
    this.onScrollById();
    if (this.props.location && this.props.location.state && this.props.location.state.id) {
      this.setState({
        id: this.props.location.state.id,
        uuid: this.props.location.state.uuid,
        server_name: this.props.location.state.server_name,
      });
    } else {
      this.props.history.push("/monitor-servers");
    }
  }

  onScrollById = () => {
    let id = localStorage.getItem("moniterServerScroll");
    if (id && id !== "null") {
      this.setState(
        {
          defaultActiveKey: id === "performance" ? "7" : id === "backup" ? "3" : id === "notAllowedFile" ? "1" : id === "folderChange" ? "2" : "8",
          active: id === "performance" ? 7 : id === "backup" ? 3 : id === "notAllowedFile" ? 1 : id === "folderChange" ? 2 : 8,
        },
        () => {
          localStorage.setItem("moniterServerScroll", null);
        }
      );
    }
  };

  onSelectServer = (e) => {
    this.setState({
      id: e.id,
      uuid: e.value,
      server_name: e.label,
      shared: e.shared,
    });
  };

  onClickChange = () => {
    this.setState({
      shared: true,
    });
  };

  render() {
    return (
      <div className="container">
        <Header history={this.props.history} {...this.props}></Header>
        <div className="serverDetailsSec">
          <ServerCurrentLoggedIn
            server_name={this.state.server_name}
            uuid={this.state.uuid}
            onSelectServer={this.onSelectServer}
            noRender={true}
            onClickChange={this.onClickChange}
            id={this.state.id}
          />
          <div className="serverSettingUUID">
            <span className="pl-5">
              UUID: <strong className="textRed">{this.state.uuid}</strong>
            </span>
            <OverlayTrigger placement="bottom" overlay={<Tooltip>Click to view server data</Tooltip>}>
              <Button
                className="iconBtn ml-2"
                onClick={() => {
                  const object = {
                    id: this.state.id,
                    uuid: this.state.uuid,
                    update: true,
                    server_name: this.state.server_name,
                  };
                  this.props.history.push("/monitor-server/charts" + objectToQuery(object), object);
                }}
              >
                {" "}
                <AiOutlineLineChart></AiOutlineLineChart>
              </Button>
            </OverlayTrigger>
          </div>
        </div>
        <div className="card overflow-inherit">
          {/* <div className="card-header">
                        <div className="d-flex">
                            <h3>Server Name: {this.state.server_name}</h3>
                            <span className="pl-5">UUID: <strong className="textRed">{this.state.uuid}</strong></span>
                        </div>
                    </div> */}

          <Row>
            <Col className="col-md-12">
              <div id="captureData">
                <Tab.Container id="left-tabs-example" defaultActiveKey={this.state.defaultActiveKey ? this.state.defaultActiveKey : "8"}>
                  <Nav variant="pills" className="setting-tabs-view serverInfoTabs customsetting">
                    <Nav.Item>
                      <Nav.Link eventKey="8" onClick={() => this.setState({ active: 8 })}>
                        General
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="1" onClick={() => this.setState({ active: 1 })}>
                        Disallowed Files
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="2" onClick={() => this.setState({ active: 2 })}>
                        File Integrity Monitoring
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="3" onClick={() => this.setState({ active: 3 })}>
                        Backup
                      </Nav.Link>
                    </Nav.Item>
                    {/* <Nav.Item>
                                            <Nav.Link eventKey="4" onClick={() => this.setState({ active: 4 })}>DB Backup</Nav.Link>
                                        </Nav.Item> */}
                    {/* <Nav.Item>
                                            <Nav.Link eventKey="5" onClick={() => this.setState({ active: 5 })}>Upload Path</Nav.Link>
                                        </Nav.Item> */}
                    <Nav.Item>
                      <Nav.Link eventKey="6" onClick={() => this.setState({ active: 6 })}>
                        Notifications
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="7" onClick={() => this.setState({ active: 7 })}>
                        Performance
                      </Nav.Link>
                    </Nav.Item>

                    <Nav.Item>
                      <Nav.Link eventKey="9" onClick={() => this.setState({ active: 9 })}>
                        Custom Logs
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content className="p-0">
                    <Tab.Pane eventKey="8">
                      <GeneralKeys shared={this.state.shared} MSId={this.state.id} history={this.props.history} active={this.state.active} />
                    </Tab.Pane>
                    <Tab.Pane eventKey="1">
                      <DisallowedFiles MSId={this.state.id} history={this.props.history} active={this.state.active} />
                    </Tab.Pane>
                    <Tab.Pane eventKey="2">
                      <ModifiedFiles MSId={this.state.id} history={this.props.history} active={this.state.active} />
                    </Tab.Pane>
                    <Tab.Pane eventKey="3">
                      <ServerBackup uuid={this.state.uuid} MSId={this.state.id} history={this.props.history} active={this.state.active}></ServerBackup>
                    </Tab.Pane>
                    {/* <Tab.Pane eventKey="4">
                                            <DBBackup MSId={this.state.id} history={this.props.history} active={this.state.active} />
                                        </Tab.Pane> */}
                    {/* <Tab.Pane eventKey="5">
                                            <UploadPath MSId={this.state.id} history={this.props.history} active={this.state.active} />
                                        </Tab.Pane> */}
                    <Tab.Pane eventKey="6">
                      <Notification MSId={this.state.id} history={this.props.history} active={this.state.active} />
                    </Tab.Pane>
                    <Tab.Pane eventKey="7">
                      <GeneralSetting MSId={this.state.id} history={this.props.history} uuid={this.state.uuid} active={this.state.active} />
                    </Tab.Pane>

                    <Tab.Pane eventKey="9">
                      <MonitorCustomLogs uuid={this.state.uuid} MSId={this.state.id} history={this.props.history} active={this.state.active}></MonitorCustomLogs>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default MonitorServerSetting;
