import React from 'react'
import { IoIosArrowRoundBack } from "react-icons/io";
import exclamation from "../../assets/img/exclamation.svg"
import { Link } from 'react-router-dom';
class WrongUrl extends React.Component{
    constructor(props){
        super(props);
        this.state={

        }
    }

    render(){
        return(<div className='wrongUrl'>
                <div className='txtSec'>
                    <h1>404 </h1>
                    <img src={ exclamation } alt=""/>
                    <div className="px-1">
                        <h4>SORRY!</h4>
                        <p>The Page You're Looking For Was Not Found</p>
                        <Link className="BackBtn" to="/dashboard"><IoIosArrowRoundBack></IoIosArrowRoundBack>Go Back</Link>
                    </div>
                </div>
        </div>)
    }
}
export default WrongUrl