import { RestMethodSecondOrigin } from '../../../_helpers/ApiConfigTwo/RestMethod'
import FilterResponse from '../../../_helpers/FilterResponse'
import {isAuthorized} from '../../../_helpers/Common/Common'
export const socialMediaServices = {
    addSocialMediaData,
    getSocialMediaData,
    updateSocialMediaData,
    geInstagramData
}

async function addSocialMediaData(data) {
    try {
        const response = await RestMethodSecondOrigin.POST('/domain-social-media/add-domain-social',data);
        const api_response = FilterResponse(response);  
        return api_response;
    }
    catch (error) {
        isAuthorized(error)
        return null;
    }
}

async function getSocialMediaData(data) {
    try {
        const response = await RestMethodSecondOrigin.POST('/domain-social-media/all-domain-social',data);
        const api_response = FilterResponse(response);  
        return api_response;
    }
    catch (error) {
        isAuthorized(error)
        return null;
    }
}

async function updateSocialMediaData(data) {
    try {
        const response = await RestMethodSecondOrigin.POST('/domain-social-media/update-domain-social',data);
        const api_response = FilterResponse(response);  
        return api_response;
    }
    catch (error) {
        isAuthorized(error)
        return null;
    }
}

async function geInstagramData(data) {
    try {
        const response = await RestMethodSecondOrigin.POST('/instagram/insta-token',data);
        const api_response = FilterResponse(response);  
        return api_response;
    }
    catch (error) {
        isAuthorized(error)
        return null;
    }
}