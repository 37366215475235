import { RestMethodSecondOrigin } from '../../../_helpers/ApiConfigTwo/RestMethod'
import FilterResponse from '../../../_helpers/FilterResponse'
import {isAuthorized} from '../../../_helpers/Common/Common'

export const mysqlRuntimeServices = {
    getMysqlRuntime
}

async function getMysqlRuntime(data) {
    try {
        const response = await RestMethodSecondOrigin.POST('/mysql/mysql-runtime',data);
        const api_response = FilterResponse(response);  
        return api_response;
    }
    catch (error) {
        isAuthorized(error)
        return null;
    }
}