import React, { useState, useEffect } from 'react'
import { Form } from 'reactstrap';
import { MButton, Select, Loader, TooltipPro } from '../../CommonComponents/index'
import DBBackupForm from './DBBackupForm/DBBackupForm'
import { Constants } from '../Constants'
import { monitorServersSettingServices } from '../../../_services/servicesWithSecondOrigin/index'
import toastr from 'toastr'
import { Validation } from '../../../_helpers/validation/Validation'
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { confirmAlert } from 'react-confirm-alert'
const fileObj = {
    "friendly_db_name": "",
    "db_username": "",
    "db_password": "",
    "host": "",
    "db_name": "",
    "update": false,


    "db_username_update": false,
    "db_password_update": false,
    "host_update": false,
    "db_name_update": false,
}

const DBBackup = ({ ...props }) => {
    const [dataObj, setDataObj] = useState({
        "hour": 0,
        "day": "*",
        "month": "*",
        "weekday": "*",
    })
    const [inputFields, setInputFields] = useState([fileObj])
    const [errorArray, setErrorArray] = useState([])
    const [loader, setLoader] = useState(false)
    const [public_key_path, setKeyPath] = useState("")

    useEffect(() => {
        if (props.active === 4)
            getDataFromServer()
    }, [props.MSId, props.active]);

    useEffect(() => {
        setKeyPath(props.public_key_path)
    }, [props.public_key_path])

    const getDataFromServer = async () => {
        setLoader(true)
        setDataObj({
            "hour": 0,
            "day": "*",
            "month": "*",
            "weekday": "*",
        })
        setInputFields([fileObj])
        setKeyPath("")
        if (props.MSId) {
            let response = await monitorServersSettingServices.getMonitorDBBackup(props.MSId)
            if (response && response.data && response.data.length !== 0) {
                Array.isArray(response.data) && response.data.map((allData, i) => {
                    allData.db_username = allData.db_username_to_show ? allData.db_username_to_show : allData.db_username
                    allData.db_password = allData.db_password_to_show ? allData.db_password_to_show : allData.db_password
                    allData.host = allData.host_to_show ? allData.host_to_show : allData.host
                    allData.db_name = allData.db_name_to_show ? allData.db_name_to_show : allData.db_name
                })
                setInputFields(response.data)
                if (response.data[0] && response.data[0].hour !== "" && response.data[0] && response.data[0].hour !== undefined) {
                    setDataObj({
                        "hour": parseInt(response.data[0].hour),
                        "day": response.data[0].day === "*" ? "*" : parseInt(response.data[0].day),
                        "month": response.data[0].month,
                        "weekday": response.data[0].weekday,
                    })
                    props.setPublicKey(response.data[0].public_key_path)
                }
            }
        }
        setLoader(false)
    }

    const addFiles = () => {
        let cloneArray = inputFields.concat(fileObj)
        setInputFields(cloneArray)
    }

    const remove = (index) => {
        confirmAlert({
            title: 'Confirm to Delete',
            message: <p>Are you sure to do this.</p>,
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        let cloneArray = inputFields.slice()
                        if (cloneArray[index].id) {
                            deletePerticularSettingData(cloneArray[index].id, cloneArray, index)
                        } else {
                            if (cloneArray.length === 1) {
                                allreset()
                            } else {
                                cloneArray.splice(index, 1)
                                setInputFields(cloneArray)
                            }
                        }
                    }
                },
                {
                    label: 'No',
                    onClick: () => console.log("No")
                }
            ]
        });
    }

    const deletePerticularSettingData = async (id, cloneArray, index) => {
        let data = {
            "type": "db_bkp",
            "id": id
        }
        let response = await monitorServersSettingServices.deleteSettingData(data);
        if (response && response.status === 200) {
            if (response.message)
                toastr.success(response.message)
            if (cloneArray.length === 1) {
                allreset()
            } else {
                cloneArray.splice(index, 1)
                setInputFields(cloneArray)
            }
        }
    }

    const allreset = () => {
        setInputFields([fileObj])
        setDataObj({
            "hour": 0,
            "day": "*",
            "month": "*",
            "weekday": "*",
        })
    }

    const onChangeFolders = (e, index) => {
        let cloneArray = JSON.parse(JSON.stringify(inputFields))
        cloneArray[index][e.target.name] = e.target.value

        cloneArray[index].update = true
        switch (e.target.name) {
            case "db_username":
                cloneArray[index].db_username_update = true;
                break;
            case "db_password":
                cloneArray[index].db_password_update = true;
                break;
            case "host":
                cloneArray[index].host_update = true;
                break;
            case "db_name":
                cloneArray[index].db_name_update = true;
                break;
            default:
                console.log("default")
        }

        setInputFields(cloneArray)
    }

    const onSubmit = async (e) => {
        let cloneArray = inputFields.slice()
        let flag = 0
        let errorArray = []

        let ArrayHostData = []
        const field = Constants().DBBackupRule;
        Array.isArray(cloneArray) && cloneArray.map((obj) => {
            let errobj = Validation(obj, field);
            Object.keys(errobj).map(index => {
                if (errobj[index] !== "") {
                    flag = 1;
                }
            });
            errorArray = errorArray.concat(errobj)
            let objData = { friendly_db_name: obj.friendly_db_name }
            if (obj.update) {
                if (obj.db_username_update) {
                    objData.db_username = obj.db_username
                }
                if (obj.db_password_update) {
                    objData.db_password = obj.db_password
                }
                if (obj.host_update) {
                    objData.host = obj.host
                }
                if (obj.db_name_update) {
                    objData.db_name = obj.db_name
                }

            }
            if (obj.id) {
                objData.id = obj.id
            }
            ArrayHostData.push(objData)
        })

        setErrorArray(errorArray)
        if (flag === 0) {
            let data = {
                ...dataObj,
                "data": ArrayHostData,
                //  public_key_path: public_key_path
            }

            let response = await monitorServersSettingServices.updateMonitorDBBackup(data, props.MSId)
            if (response) {
                await monitorServersSettingServices.updatePublicKey(props.MSId, { public_key_path: public_key_path })
                if (response && response.message) {
                    toastr.success(response.message)
                    getDataFromServer()
                }
            }
        }
    }

    const onChange = (e, name) => {
        let obj = { ...dataObj }
        obj[name] = e.value;
        setDataObj(obj)
    }

    const onChangeDay = (e) => {
        let check = 0
        if (parseInt(e.value) === 31 && Constants().month31check.includes(parseInt(dataObj.month))) {
            check = 1
        } else if (parseInt(e.value) === 30 && Constants().month30check.includes(parseInt(dataObj.month))) {
            check = 1
        }
        if ((parseInt(e.value) !== 31 && parseInt(e.value) !== 30) || e.value === "*" || check === 1) { onChange(e, "day") } else {
            let obj = {
                value: "*"
            }
            onChange(e, "day")
            onChange(obj, "month")
        }
    }

    return <div className="serverSettingData">
        <div className="setLoaderPosition">
            <Loader loader={loader}></Loader>
        </div>
        <div className="serverInfoTabsTitle">
            {/* <Button className="backBtn mr-4" onClick={(e) => props.history.push('/monitor-servers')}><IoIosArrowRoundBack></IoIosArrowRoundBack></Button> */}
            <h3>Database Backup
                <span className="ml-2">
                    <TooltipPro
                        className="bottom tsize-600"
                        tooltip={
                            <div className="row">
                                <div className="col-md-12">
                                    <p>In this section you can configure the databases that you want to backup. A zip file is created, which is then encryped (provided you have setup a public key) and then uploaded to the location you have configured. You should setup database user with only database backup privileges (read only access) (For "how to setup db user"<a target="_blank" href="https://g8keeperapp.com/how-it-works/create-mysql-db-user-to-take-backup-of-the-database/"> click here)</a></p>
                                    <p>You can configure multiple databases for backup.</p>
                                    <p><strong>Note: </strong>Backup is only created if there is atleast 10% disk space remaining on the server.</p>
                                </div>
                            </div>
                        }
                    >
                        <AiOutlineInfoCircle className="infoIcon" />
                    </TooltipPro>
                </span>
            </h3>
        </div>

        <div>

            <Form onSubmit={(e) => onSubmit(e)}>


                <div className="row mt-4 mb-4">
                    <div className="col-md-3">
                        <label>Hour {props.machine_timezone ? props.machine_timezone : ""}</label><span className="ml-2">
                            <TooltipPro
                                className="bottom tsize-400"
                                tooltip={
                                    <div className="row">
                                        <div className="col-md-12">
                                            <p>Setup hour of the day when you want the backup to be done. This time is as per the server timezone. You should select the time when there will be minimal activity on the server. Backup is generally quick but performance intensive process. Also please ensure that folder backup and DB backup are not schuduled at the same time.</p>
                                        </div>
                                    </div>
                                }
                            >
                                <AiOutlineInfoCircle className="infoIcon" />
                            </TooltipPro>
                        </span>
                        <Select options={Constants().hour()} setSelectedOption={(e) => onChange(e, "hour")} selectedOption={dataObj.hour}></Select>
                    </div>
                    <div className="col-md-3">
                        <label>Day</label><span className="ml-2">
                            <TooltipPro
                                className="bottom tsize-400"
                                tooltip={
                                    <div className="row">
                                        <div className="col-md-12">
                                            <p>If the folder does not change frequently you may not want to do backup everyday, in that case you can select the date of the month when you want to backup the folder.</p>
                                        </div>
                                    </div>
                                }
                            >
                                <AiOutlineInfoCircle className="infoIcon" />
                            </TooltipPro>
                        </span>
                        <Select options={Constants().day()} selectedOption={dataObj.day} setSelectedOption={(e) => onChangeDay(e, "day")}></Select>
                    </div>
                    <div className="col-md-3">
                        <label>Month</label><span className="ml-2">
                            <TooltipPro
                                className="bottom tsize-400"
                                tooltip={
                                    <div className="row">
                                        <div className="col-md-12">
                                            <p>You can select a Month if you only want to do backup in a particular month. </p>
                                        </div>
                                    </div>
                                }
                            >
                                <AiOutlineInfoCircle className="infoIcon" />
                            </TooltipPro>
                        </span>
                        <Select options={parseInt(dataObj.day) === 31 ? Constants().month31 : parseInt(dataObj.day) === 30 ? Constants().month30 : Constants().month} selectedOption={dataObj.month} selectedOption={dataObj.month} setSelectedOption={(e) => onChange(e, "month")}></Select>
                    </div>
                    <div className="col-md-3">
                        <label>Weekday</label><span className="ml-2">
                            <TooltipPro
                                className="bottom tsize-400"
                                tooltip={
                                    <div className="row">
                                        <div className="col-md-12">
                                            <p>For a weekly backup you can select a day, then the backup will only be done on that day at the specified time.</p>
                                        </div>
                                    </div>
                                }
                            >
                                <AiOutlineInfoCircle className="infoIcon" />
                            </TooltipPro>
                        </span>
                        <Select options={Constants().weekDay} selectedOption={dataObj.weekday} selectedOption={dataObj.weekday} setSelectedOption={(e) => onChange(e, "weekday")}></Select>
                    </div>
                </div>
                {/* <div className="row mt-4">
                    <div className="col-md-3">
                        <label>Encryption Public Key Path</label>
                        <Input value={public_key_path} onChange={e => setKeyPath(e.target.value)}></Input>
                    </div>
                </div> */}
                {Array.isArray(inputFields) && inputFields.map((data, index) => {
                    return <DBBackupForm hideRemove={inputFields.length !== 1} errorArray={errorArray} remove={() => remove(index)} data={data} onChangeFolders={onChangeFolders} index={index}></DBBackupForm>
                })}

                <div className="mt-4 disallowedbuttons justify-content-center">
                    {/* <Button className="bluesum btn" onClick={() => addFiles()} ><MdAdd></MdAdd></Button> */}
                    <MButton label="Add More" onClick={() => addFiles()} className="bluebutton mx-1"></MButton>
                    <MButton label="Schedule" onClick={(e) => onSubmit(e)} className="bluebutton mx-1"></MButton>
                </div>
            </Form>
        </div>
    </div >
}

export default DBBackup