import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, Input } from "reactstrap";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { CgNotes } from "react-icons/cg";
import { MdAdd } from "react-icons/md";
import { notesServices } from "../../../_services/servicesWithSecondOrigin/index";
import toastr from "toastr";
import { BiLeftArrowAlt } from "react-icons/bi";
import { BsCheckCircle } from "react-icons/bs";
import { MdEdit, MdDelete } from "react-icons/md";
import { Error, Loader } from "../../CommonComponents/index";
import { ImCross } from "react-icons/im";
const Notes = (props) => {
  const [formNo, setFormNo] = useState(1);
  const [header, setHeader] = useState("");
  const [text, setText] = useState("");
  const [allURLData, setAllURLData] = useState([]);
  const [allDomainData, setAllDomainData] = useState([]);
  const [allApiData, setAllApiData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [id, setId] = useState("");
  const [type, setType] = useState("");
  const [dataObj, setDataObj] = useState("");
  const [error, setError] = useState("");
  const [modal, setModal] = useState(false);
  const [isLoading, setLoader] = useState(false);
  const toggle = () => {
    setModal(!modal);
    if (!modal) {
      getAllNotes();
      onAddResetValue();
      setAllURLData([]);
      setAllDomainData([]);
      setAllData([]);
      setAllApiData([]);
    }
  };

  const getAllNotes = async () => {
    onAddResetValue();
    setAllURLData([]);
    setAllDomainData([]);
    setAllData([]);
    setAllApiData([]);
    setLoader(true);
    var d = new Date();
    var n = d.getTimezoneOffset();
    let data = {
      resource_type: props.type,
      resource_id: props.id,
      timediff: n,
    };
    let res = await notesServices.getNotes(data);
    if (res && res.data && res.data.monitor_server) {
      setAllData(
        res.data.monitor_server.sort((a, b) =>
          a.created < b.created ? 1 : a.created > b.created ? -1 : 0
        )
      );
    }
    if (res && res.data && res.data.url_monitor) {
      setAllURLData(
        res.data.url_monitor.sort((a, b) =>
          a.created < b.created ? 1 : a.created > b.created ? -1 : 0
        )
      );
    }
    if (res && res.data && res.data.domain && Array.isArray(res.data.domain)) {
      setAllDomainData(
        res.data.domain.sort((a, b) =>
          a.created < b.created ? 1 : a.created > b.created ? -1 : 0
        )
      );
    }

    if (Array.isArray(res?.data?.api_monitor) && res?.data?.api_monitor) {
      setAllApiData(
        res.data.api_monitor.sort((a, b) =>
          a.created < b.created ? 1 : a.created > b.created ? -1 : 0
        )
      );
    }

    setLoader(false);
  };

  const addNotes = async () => {
    setLoader(true);
    let data = {};
    if (id) {
      data = {
        resource_id: dataObj.resource_id,
        resource_type: type,
        heading: header,
        text: text,
      };
    } else {
      data = {
        resource_id: props.id,
        resource_type: props.type,
        heading: header,
        text: text,
      };
    }

    if (Object.keys(validation(data)).length === 0) {
      let res = "";
      if (id) {
        res = await notesServices.updateNote(data, id);
      } else {
        res = await notesServices.addNotes(data);
      }

      if (res && res.message) {
        toastr.success(res.message);
        reset();
        setFormNo(1);
        getAllNotes();
        if (id) {
          dataObj.text = text;
          dataObj.heading = header;
          setDataObj(dataObj);
        }
      }
      setLoader(false);
    } else {
      setError(validation(data));
      setLoader(false);
    }
  };

  const validation = (obj) => {
    let errorObj = {};
    if (obj.text === "") {
      errorObj.text = "Text is Required!";
    } else if (obj.text.length < 5) {
      errorObj.text = "Min Five Characters are Required!";
    }
    // if (obj.heading === "") {
    //     errorObj.heading = "Heading is Required!"
    // } else if (obj.heading.length < 5) {
    //     errorObj.heading = "Min Five Characters are Required!"
    // }
    return errorObj;
  };

  const onAddResetValue = () => {
    reset();
    setId("");
    setType("");
    setDataObj("");
  };

  const reset = () => {
    setText("");
    setHeader("");
    setError("");
  };

  const setNotesData = (obj) => {
    setDataObj(obj);
  };

  const deleteNote = async (obj) => {
    let res = await notesServices.deleteNotes(obj.id);
    if (res.status === 200 && res.message) {
      toastr.success(res.message);
      setDataObj("");
      getAllNotes();
    } else if (res.message) {
      toastr.success(res.message);
    }
  };

  const onClickForEditNotes = async (obj) => {
    setFormNo(2);
    setHeader(obj.heading);
    setText(obj.text);
    setId(obj.id);
    setError("");
  };

  const closeNoteDetails = () => {
    onAddResetValue();
    setFormNo(1);
  };

  return (
    <div>
      <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip>View Notes</Tooltip>}>
        <Button className="iconBtn mr-1" onClick={() => toggle()}>
          <CgNotes />
        </Button>
      </OverlayTrigger>
      <Modal size="md" isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle} className="notesHeader">
          <div>Notes</div>

          <Button
            className="noteBtn ml-2"
            style={{ fontSize: "18px" }}
            onClick={() => {
              setFormNo(formNo === 1 ? 2 : 1);
              onAddResetValue();
            }}>
            {formNo === 1 ? <MdAdd /> : <BiLeftArrowAlt></BiLeftArrowAlt>}
          </Button>
        </ModalHeader>

        {formNo === 1 ? (
          /*<ModalBody style={{ minHeight: '500px' }}>*/ <ModalBody>
            <div className="chart-loader">
              {" "}
              <Loader loader={isLoading}></Loader>
            </div>

            <div className="row">
              {allData.length === 0 &&
              allURLData.length === 0 &&
              allDomainData.length === 0 &&
              allApiData.length === 0 ? (
                <div>No Data Found</div>
              ) : (
                <div className={dataObj === "" ? "col-md-12" : "col-md-5"}>
                  <div className="card" style={{ height: "100%" }}>
                    {Array.isArray(allDomainData) &&
                      allDomainData.map((obj, index) => {
                        return (
                          <>
                            {index === 0 && notesHeader()}
                            <span
                              className="card-header"
                              style={
                                obj.id === dataObj.id
                                  ? { background: "#e9e3ef" }
                                  : {}
                              }
                              onClick={() => {
                                setNotesData(obj);
                                setType(obj.resource_type);
                              }}>
                              <div
                                style={{
                                  display: "flex",
                                  fontSize: "12px",
                                  width: "60%",
                                }}>
                                <BsCheckCircle
                                  size="15"
                                  className="b-ok"></BsCheckCircle>
                                <p className="cut-text">
                                  {obj.heading ? obj.heading : obj.text}
                                </p>
                              </div>
                              <div>
                                <p style={{ fontSize: "12px" }}>
                                  {obj.resource_type === "url_monitor"
                                    ? "URL Monitor"
                                    : obj.resource_type === "monitor_server"
                                    ? "Monitor Server"
                                    : "Domain"}
                                </p>
                              </div>
                              <div>
                                <p style={{ fontSize: "12px" }}>
                                  {obj.created}
                                </p>
                              </div>
                            </span>
                          </>
                        );
                      })}
                    {Array.isArray(allURLData) &&
                      allURLData.map((obj, index) => {
                        return (
                          <>
                            {index === 0 && notesHeader()}
                            <span
                              className="card-header modalNote"
                              style={
                                obj.id === dataObj.id
                                  ? { background: "#e9e3ef" }
                                  : {}
                              }
                              onClick={() => {
                                setNotesData(obj);
                                setType("url_monitor");
                              }}>
                              <div
                                style={{
                                  display: "flex",
                                  fontSize: "12px",
                                  width: "60%",
                                }}>
                                <BsCheckCircle
                                  size="15"
                                  className="b-ok"></BsCheckCircle>
                                <p className="cut-text">
                                  {obj.heading ? obj.heading : obj.text}
                                </p>
                              </div>
                              <div>
                                <p style={{ fontSize: "12px" }}>URL Monitor</p>
                              </div>

                              <div>
                                <p style={{ fontSize: "12px" }}>
                                  {obj.created}
                                </p>
                              </div>
                            </span>
                          </>
                        );
                      })}

                    {Array.isArray(allApiData) &&
                      allApiData.map((obj, index) => {
                        return (
                          <>
                            {index === 0 && notesHeader()}
                            <span
                              className="card-header modalNote"
                              style={
                                obj.id === dataObj.id
                                  ? { background: "#e9e3ef", display: "flex" }
                                  : { display: "flex" }
                              }
                              onClick={() => {
                                setNotesData(obj);
                                setType("api_monitor");
                              }}>
                              <div
                                style={{
                                  display: "flex",
                                  fontSize: "12px",
                                  width: "60%",
                                }}>
                                <BsCheckCircle
                                  size="15"
                                  className="b-ok"></BsCheckCircle>
                                <p className="cut-text">
                                  {obj.heading ? obj.heading : obj.text}
                                </p>
                              </div>
                              <div>
                                <p style={{ fontSize: "12px" }}>Api Monitor</p>
                              </div>

                              <div>
                                <p style={{ fontSize: "12px" }}>
                                  {obj.created}
                                </p>
                              </div>
                            </span>
                          </>
                        );
                      })}
                    {Array.isArray(allData) &&
                      allData.map((obj, index) => {
                        return (
                          <>
                            {index === 0 && notesHeader()}
                            <span
                              className="card-header"
                              style={
                                obj.id === dataObj.id
                                  ? { background: "#e9e3ef" }
                                  : {}
                              }
                              onClick={() => {
                                setNotesData(obj);
                                setType("monitor_server");
                              }}>
                              <div
                                style={{
                                  display: "flex",
                                  fontSize: "12px",
                                  width: "60%",
                                }}>
                                <BsCheckCircle
                                  size="15"
                                  className="b-ok"></BsCheckCircle>
                                <p className="cut-text">
                                  {obj.heading ? obj.heading : obj.text}
                                </p>
                              </div>
                              <div>
                                <p style={{ fontSize: "12px" }}>
                                  Server Monitor
                                </p>
                              </div>
                              <div className="">
                                <p style={{ fontSize: "12px" }}>
                                  {obj.created}
                                </p>
                              </div>
                            </span>
                          </>
                        );
                      })}
                  </div>
                </div>
              )}

              {dataObj && (
                <div className="col-md-7">
                  <div className="card" style={{ height: "100%" }}>
                    <div className="card-header">
                      <p>{dataObj && dataObj.created}</p>
                      <p>
                        {dataObj.resource_type === "url_monitor"
                          ? "URL Monitor"
                          : dataObj.resource_type === "monitor_server"
                          ? "Monitor Server"
                          : dataObj.resource_type === "domain"
                          ? "Domain"
                          : props.resourceType}
                      </p>
                      <div className="d-flex">
                        <Button
                          style={{ fontSize: "18px" }}
                          className="iconBtn p-0"
                          onClick={() => onClickForEditNotes(dataObj)}>
                          <MdEdit />
                        </Button>

                        <Button
                          style={{ fontSize: "18px" }}
                          className="iconBtn p-0"
                          onClick={() => deleteNote(dataObj)}>
                          <MdDelete />
                        </Button>
                        <Button
                          style={{ fontSize: "18px" }}
                          className="iconBtn p-0"
                          onClick={() => closeNoteDetails(dataObj)}>
                          <ImCross size="12px" />
                        </Button>
                      </div>
                    </div>
                    <div className="serverDetails">
                      {/* <div className="mt-3"> */}
                      <div className="">
                        <h5>{dataObj && dataObj.heading}</h5>
                        <p>{dataObj && dataObj.text}</p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </ModalBody>
        ) : (
          <ModalBody>
            <div className="mt-1">
              <label>Heading</label>
              <Input
                value={header}
                onChange={(e) => {
                  setHeader(e.target.value);
                  setError("");
                }}
                placeholder="Heading"></Input>
              {error && error.heading && <Error error={error.heading}></Error>}
            </div>
            <div className="mt-3" style={{ width: "100%", height: "30%" }}>
              <label>Text</label>
              <textarea
                className="form-control"
                value={text}
                onChange={(e) => {
                  setText(e.target.value.length < 200 ? e.target.value : text);
                  setError("");
                }}
                style={{ width: "100%", height: "100%" }}
                placeholder="Write your text here"></textarea>
              {error && error.text && <Error error={error.text}></Error>}
            </div>
            <div className="pt-3 text-center">
              <Button
                className="btn addEditBtn  btn-primary"
                onClick={() => addNotes()}>
                {id ? "Update" : "Add"}
              </Button>
            </div>
          </ModalBody>
        )}
      </Modal>
    </div>
  );
};

export default Notes;

const notesHeader = () => {
  return (
    <span className="card-header" onClick={() => {}}>
      <div style={{ display: "flex", width: "55%" }}>
        <h6>Notes</h6>
      </div>
      <div>
        <h6 style={{ paddingRight: "0px" }}>Level</h6>
      </div>

      <div>
        <h6 style={{}}>Date</h6>
      </div>
    </span>
  );
};
