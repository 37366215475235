import React from 'react'
import Header from '../../_components/CommonComponents/Header/Header'
import NotificationChannelForm from '../../_components/NotificationChannelForm/NotificationChannelForm'
class AddNotificationChannel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: "",
            update: false,
        }
    }

    componentDidMount() {
        if (this.props.location && this.props.location.state && this.props.location.state.update) {
            this.setState({
                id: this.props.location.state.id,
                update: this.props.location.state.update
            })
        }
        if (this.props.id) {
            this.setState({
                id: this.props.id,
                update: this.props.editpop
            })
        }
    }

    render() {
        return (<div className="container">
            {this.props.popup ? "" : <Header history={this.props.history} {...this.props}></Header>}
            <div className="card">
                <div className="row">
                    {/* <div className="col-md-2">
                        <h3>Notification Channel</h3>
                    </div> */}
                    <div className="col-md-12">
                        <NotificationChannelForm {...this.props}
                            id={this.state.id}
                            update={this.state.update}
                        />
                    </div>
                </div>
            </div>
        </div>)
    }
}

export default AddNotificationChannel