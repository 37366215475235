import React from 'react'
import { Button } from 'reactstrap';
import Header from '../../_components/CommonComponents/Header/Header'
import MonitorUrlsForm from '../../_components/MonitorUrlsForm/MonitorUrlsForm'
import { monitorUrlsServices } from '../../_services/servicesWithSecondOrigin/index'
import toastr from 'toastr'

class MonitorUrls extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            monitorUrlsArray: [],
            domain: "Domain Not Found",
            id: "",
            update: ""
        }
    }

    componentDidMount() {
        if (this.props.location && this.props.location.state && this.props.location.state.domain) {
            this.setState({
                domain: this.props.location.state.domain
            }, () => {
                this.getAllURLsForMonitor()
            })
        }

        if (this.props.location && this.props.location.state && this.props.location.state.update) {
            this.setState({
                update: this.props.location.state.update,
                id: this.props.location.state.id,
                domain: this.props.location.state.domain,
            })
        }
    }

    addUrl = (url) => {
        let monitorUrlsArray = [...this.state.monitorUrlsArray]
        monitorUrlsArray.unshift({
            "url": url,
            "status": "0"
        })
        this.setState({ monitorUrlsArray: monitorUrlsArray })
    }

    getAllURLsForMonitor = async () => {
        let data = {
            "domain_name": this.state.domain
        }
        let response = await monitorUrlsServices.getMonitorURLs(data)
        if (response && response.data) {
            let newArray = []
            Array.isArray(response.data) && response.data.map((obj) => {
                let NewObj = {
                    "id": obj.id,
                    "url": obj.URL,
                    "status": obj.Status
                }
                newArray.push(NewObj)
            })
            this.setState({
                monitorUrlsArray: newArray
            })
        }
    }

    onClickSwitch = (value, index) => {
        let monitorUrlsArray = [...this.state.monitorUrlsArray]
        monitorUrlsArray[index].status = value ? '1' : '0'
        this.setState({
            monitorUrlsArray: monitorUrlsArray
        })
    }

    onSubmit = async () => {
        let data = {
            "domain_name": this.state.domain,
            "urls": Array.isArray(this.state.monitorUrlsArray) ? this.state.monitorUrlsArray : []
        }
        let response = await monitorUrlsServices.updateMonitorURLs(data)
        if (response && response.status === 200) {
            if (response.messages) {
                toastr.success(response.messages)
            }
            this.props.history.push('/dashboard', { domain: this.state.domain })
        } else {
            if (response && response.messages) {
                toastr.success(response.messages)
            }
        }
    }

    goToPrevious = () => {
        if (this.state.update) {
            this.props.history.push('/update-social-media', { domain: this.state.domain, id: this.state.id, update: true })
        } else {
            this.props.history.push('/social-media', { domain: this.state.domain })
        }
    }

    render() {
        return (<div>
            <Header history={this.props.history} {...this.props}></Header>
            <div className="container">
            <div className="seo-monitoring">
                

                <MonitorUrlsForm
                    domain={this.state.domain}
                    monitorUrlsArray={this.state.monitorUrlsArray}
                    addUrl={this.addUrl}
                    onClickSwitch={this.onClickSwitch}
                ></MonitorUrlsForm>
            </div>
            <div className="belowBtns">
                <Button className="PreviousBotton" onClick={() => this.goToPrevious()}>Previous</Button>
                <Button className="NextButton" onClick={() => this.onSubmit()}>Finish</Button>
            </div>
            </div>
        </div>)
    }
}

export default MonitorUrls