import React, { Fragment, useEffect, useState } from "react";
import { Tab, Nav } from "react-bootstrap";
import Header from "../../_components/CommonComponents/Header/Header";
import { ApiMonitorListing } from "../../Pages/Postman/ApiMonitorListing";
import MonitorUrlsList from "../MonitorULRs/MonitorUrlsList";
import { useLocation } from "react-router-dom";

const MonitorServerListing = (props) => {
  const location = useLocation();
  const [isActive, setIsActive] = useState(false);
  const showActiveTabs = (value) => {
    setIsActive(value);
  };
  useEffect(() => {
    if (location?.state) {
      showActiveTabs(true);
    }
  }, [location?.state]);
  return (
    <div>
      <Header history={props.history} {...props}></Header>
      <div className="container api-url-monitoring-container">
        <div className="row">
          <div className="col-md-12">
            <Fragment>
              <Tab.Container id="left-tabs-example" defaultActiveKey={`${location.state ? 2 :1}`}>
                <Nav variant="pills" className="serverInfoTabs">
                  <Nav.Item>
                    <Nav.Link
                      eventKey="1"
                      onClick={() => showActiveTabs(false)}>
                      Monitor URLs
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="2" onClick={() => showActiveTabs(true)}>
                      Monitor APIs
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Tab.Container>
            </Fragment>
          </div>
        </div>
      </div>
      {isActive ? (
        <ApiMonitorListing {...props} />
      ) : (
        <MonitorUrlsList {...props} />
      )}
    </div>
  );
};

export default MonitorServerListing;
