import React from 'react'
import { Error, TooltipPro, Tagsinput } from '../../../CommonComponents/index'
import { Input, Button } from 'reactstrap';
import { MdClear } from "react-icons/md";
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
const ModifiedFilesForm = ({ ...props }) => {
    return <>
        {props.data && <div className="fileSection">
            <div className="row mt-4">
                <div className="col-md-6">
                    <label>Friendly Folder Name</label><span className="ml-2">
                        <TooltipPro
                            className="bottom tsize-600"

                            tooltip={
                                <div className="row">
                                    <div className="col-md-12">
                                        <p>This name will be used in alerts and all reporting. Use a name that helps you quickly understand which folder this refers to</p>
                                    </div>
                                </div>
                            }
                        >
                            <AiOutlineInfoCircle className="infoIcon" />
                        </TooltipPro>
                    </span>
                    <Input value={props.data.friendly_folder_name} name="friendly_folder_name" placeholder="Friendly Folder Name" onChange={e => props.onChangeFile(e, props.index)}></Input>
                    {props.errorArray && props.errorArray[props.index] && <Error error={props.errorArray[props.index].friendly_folder_name}></Error>}
                </div>
                <div className="col-md-6">
                    <label>Folder(add full folder path)</label><span className="ml-2">
                        <TooltipPro
                            className="bottom tsize-400"

                            tooltip={
                                <div className="row">
                                    <div className="col-md-12">
                                        <p>Please add absolute folder path. For example if your webroot folder is /var/www/html then use the same instead of just using /var or /var/www, otherwise it will unnecessarily add to performance hit</p>
                                    </div>
                                </div>
                            }
                        >
                            <AiOutlineInfoCircle className="infoIcon" />
                        </TooltipPro>
                    </span>
                    <Input placeholder="Folder(add full folder path)" value={props.data.folder} name="folder" onChange={e => props.onChangeFile(e, props.index)}></Input>
                    {props.errorArray && props.errorArray[props.index] && <Error error={props.errorArray[props.index].folder}></Error>}
                </div>
            </div>
            <div className="row mt-4">
                <div className="col-md-12 tagsCss">
                    <label>Allowed File Types (Comma separated list)</label><span className="ml-2">
                        <TooltipPro
                            className="bottom tsize-600"

                            tooltip={
                                <div className="row">
                                    <div className="col-md-12">
                                        <p>Add the filetypes that you want to scan. Provide a comma seperated list of file extensions that need to be scanned like csv, sql, xls, xlsx, zip, tar, gz</p>
                                    </div>
                                </div>
                            }
                        >
                            <AiOutlineInfoCircle className="infoIcon" />
                        </TooltipPro>
                    </span>

                    {/* <Input  placeholder="Allowed File Types (Comma separated list)" value={props.data.allowed_file_types} name="allowed_file_types" onChange={e => props.onChangeFile(e, props.index)}></Input> */}
                    <Tagsinput value={props.data.allowed_file_types} name="allowed_file_types" onChange={e => props.onChangeFileTag(e, props.index, "allowed_file_types")} />
                    {props.errorArray && props.errorArray[props.index] && <Error error={props.errorArray[props.index].allowed_file_types}></Error>}
                </div>
            </div>
            <div className="row mt-4">
                <div className="col-md-12">
                    <label>Exclude Sub Folders (one sub-folder per line)</label><span className="ml-2">
                        <TooltipPro
                            className="bottom tsize-600"

                            tooltip={
                                <div className="row">
                                    <div className="col-md-12">
                                        <p>Provide the absolute path for the folders or files that should not be included in the reported list. For example if your monitored folder is /var/www/html then you need to add excluded folder as /var/www/html/clientcsv in case you want to exclude clientcsv folder from scanning.</p>
                                    </div>
                                </div>
                            }
                        >
                            <AiOutlineInfoCircle className="infoIcon" />
                        </TooltipPro>
                    </span>
                    <Input type="textarea" placeholder="Exclude Sub Folders (one sub-folder per line)" value={props.data.exclude_sub_folders} name="exclude_sub_folders" onChange={e => props.onChangeFile(e, props.index)}></Input>
                    {props.errorArray && props.errorArray[props.index] && <Error error={props.errorArray[props.index].exclude_sub_folders}></Error>}
                </div>
            </div>
            {(props.hideRemove || props.data.id) && <div>
                <OverlayTrigger placement="top" overlay={<Tooltip>Delete this setting</Tooltip>}>
                    <Button className="btn-danger removeSecIcon" onClick={props.remove}><MdClear /></Button>
                </OverlayTrigger>
            </div>}
        </div>}
    </>
}
export default ModifiedFilesForm