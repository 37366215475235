import { RestMethodSecondOrigin } from "../../../../_helpers/ApiConfigTwo/RestMethod";
import FilterResponse from "../../../../_helpers/FilterResponse";
import { isAuthorized } from "../../../../_helpers/Common/Common";
export const graphService = {
  getGraphData,
  getGraphData2,
  getOpenPortGraphData,
  getMysqlChartGraphData,
  getMysqlDetails,
  getMysqlStatisticsLatencyDetails,
  getMysqlDBTable,
  getApachheGraphData,
  getApachheGraphData2,
  getDomainDataChart,
  getOpenPortGraphDataPublic,

  getProcessChartsData,
  getDiskIOProcessChartsData,
  getPidDetails,
  getNgnixGraphData,
  getPartitionData,
};

async function getGraphData(data) {
  try {
    const response = await RestMethodSecondOrigin.POST(
      "/chart/graph-chart",
      data
    );
    const api_response = FilterResponse(response);
    return api_response;
  } catch (error) {
    isAuthorized(error);
    return null;
  }
}

async function getGraphData2(data) {
  try {
    const response = await RestMethodSecondOrigin.POST(
      "/chart/db-backup-chart",
      data
    );
    const api_response = FilterResponse(response);
    return api_response;
  } catch (error) {
    isAuthorized(error);
    return null;
  }
}

async function getOpenPortGraphData(data) {
  try {
    const response = await RestMethodSecondOrigin.POST(
      "/chart/open-port",
      data
    );
    const api_response = FilterResponse(response);
    return api_response;
  } catch (error) {
    isAuthorized(error);
    return null;
  }
}

async function getOpenPortGraphDataPublic(data) {
  let url = "/public-port/port";
  try {
    const response = await RestMethodSecondOrigin.POST(url, data);
    const api_response = FilterResponse(response);
    return api_response;
  } catch (error) {
    isAuthorized(error);
    return null;
  }
}

async function getMysqlChartGraphData(data, type) {
  let url = "";
  if (type === "mysql") {
    url = "/mysql/mysql-chart";
  } else if (type === "mysql-current-query") {
    url = "/mysql/mysql-query";
  } else if (type === "mysql-read-write-count") {
    url = "/mysql/mysql-read-write-count";
  } else if (type === "mysql-statement") {
    url = "/mysql/mysql-statement";
  } else if (type === "mysql-read-write-data") {
    url = "/mysql/mysql-read-write-data";
  } else if (type === "mysql-latency") {
    url = "/mysql/mysql-latency";
  } else if (type === "total-latency") {
    url = "/mysql/total-latency";
  } else if (type === "actual-disk-io-process" || type === "actual-network-io-process") {
    url = "/chart/actual-disk-io-process";
  } else if (type === "disk-io-wait-process") {
    url = "/chart/disk-io-wait-process";
  }
  try {
    const response = await RestMethodSecondOrigin.POST(url, data);
    const api_response = FilterResponse(response);
    return api_response;
  } catch (error) {
    isAuthorized(error);
    return null;
  }
}

async function getMysqlDetails(data) {
  let url = "/mysql/mysql-details";
  try {
    const response = await RestMethodSecondOrigin.POST(url, data);
    const api_response = FilterResponse(response);
    return api_response;
  } catch (error) {
    isAuthorized(error);
    return null;
  }
}

async function getMysqlStatisticsLatencyDetails(data) {
  let url = "/mysql/statistics-latency";
  try {
    const response = await RestMethodSecondOrigin.POST(url, data);
    const api_response = FilterResponse(response);
    return api_response;
  } catch (error) {
    isAuthorized(error);
    return null;
  }
}

async function getMysqlDBTable(data) {
  let url = "/mysql/db-and-table";
  try {
    const response = await RestMethodSecondOrigin.POST(url, data);
    const api_response = FilterResponse(response);
    return api_response;
  } catch (error) {
    isAuthorized(error);
    return null;
  }
}

async function getApachheGraphData(data) {
  try {
    const response = await RestMethodSecondOrigin.POST(
      "/apache/apache-data-chart",
      data
    );
    const api_response = FilterResponse(response);
    return api_response;
  } catch (error) {
    isAuthorized(error);
    return null;
  }
}

async function getApachheGraphData2(data) {
  try {
    const response = await RestMethodSecondOrigin.POST(
      "/apache/apache-data-log-chart",
      data
    );
    const api_response = FilterResponse(response);
    return api_response;
  } catch (error) {
    isAuthorized(error);
    return null;
  }
}

async function getNgnixGraphData(data) {
  try {
    const response = await RestMethodSecondOrigin.POST(
      "/ngnix/ngnix-data-chart",
      data
    );
    const api_response = FilterResponse(response);
    return api_response;
  } catch (error) {
    isAuthorized(error);
    return null;
  }
}

async function getDomainDataChart(data) {
  try {
    const response = await RestMethodSecondOrigin.POST(
      "/url-chart/urls-chart",
      data
    );
    const api_response = FilterResponse(response);
    return api_response;
  } catch (error) {
    isAuthorized(error);
    return null;
  }
}

async function getProcessChartsData(data) {
  try {
    const response = await RestMethodSecondOrigin.POST(
      "/process/process-chart",
      data
    );
    const api_response = FilterResponse(response);
    return api_response;
  } catch (error) {
    isAuthorized(error);
    return null;
  }
}

async function getDiskIOProcessChartsData(data) {
  try {
    const response = await RestMethodSecondOrigin.POST(
      "/process/disk-io-process-chart",
      data
    );
    const api_response = FilterResponse(response);
    return api_response;
  } catch (error) {
    isAuthorized(error);
    return null;
  }
}

async function getPidDetails(data) {
  try {
    const response = await RestMethodSecondOrigin.POST(
      "/process/pid-details",
      data
    );
    const api_response = FilterResponse(response);
    return api_response;
  } catch (error) {
    isAuthorized(error);
    return null;
  }
}

async function getPartitionData(data) {
  try {
    const response = await RestMethodSecondOrigin.POST(
      "/partition/partition-chart",
      data
    );
    const api_response = FilterResponse(response);
    return api_response;
  } catch (error) {
    isAuthorized(error);
    return null;
  }
}
