const SocialMediaRule = [
    // {
    //     field_name: 'social_media_type',
    //     label: 'Keyword',
    //     type: "string",
    //     isRequired: true
    // },
    // {
    //     field_name: 'url',
    //     label: 'URL',
    //     type: "url",
    //     isRequired: true
    // }
]

export function Constant() {
    return { SocialMediaRule }
}