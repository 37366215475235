import React from 'react'
import { Error, TooltipPro } from '../../../CommonComponents/index'
import { Input, Button } from 'reactstrap';
import { MdClear } from "react-icons/md";
import { AiOutlineInfoCircle } from 'react-icons/ai';

const DBBackupForm = ({ ...props }) => {
    return <>
        {props.data && <div className='fileSection'>
            <div className="row mt-4">
                <div className="col-md-6">
                    <label>Friendly DB Name</label><span className="ml-2">
                            <TooltipPro
                                className="bottom tsize-400"
                                tooltip={
                                    <div className ="row">
                                        <div className ="col-md-12">
                                            <p>This name will be used in alerts and all reporting. Use a name that helps you quickly understand which database this refers to.</p>
                                        </div>
                                    </div>
                                }
                            >
                               <AiOutlineInfoCircle className="infoIcon" />
                            </TooltipPro>
                        </span>
                    <Input value={props.data.friendly_db_name} name="friendly_db_name" placeholder="Friendly DB Name" onChange={e => props.onChangeFolders(e, props.index)}></Input>
                    {props.errorArray && props.errorArray[props.index] && <Error error={props.errorArray[props.index].friendly_db_name}></Error>}
                </div>
                <div className="col-md-6">
                    <label>DB Username</label><span className="ml-2">
                            <TooltipPro
                                className="bottom tsize-400"
                                tooltip={
                                    <div className ="row">
                                        <div className ="col-md-12">
                                            <p>You need to provide the username that has access to the DB. On setting the user with required access <a href="https://g8keeperapp.com/how-it-works/create-mysql-db-user-to-take-backup-of-the-database/" target="_blank">click here</a></p>
                                        </div>
                                    </div>
                                }
                            >
                               <AiOutlineInfoCircle className="infoIcon" />
                            </TooltipPro>
                        </span>
                    <Input placeholder="DB Username" value={props.data.db_username} name="db_username" onChange={e => props.onChangeFolders(e, props.index)}></Input>
                    {props.errorArray && props.errorArray[props.index] && <Error error={props.errorArray[props.index].db_username}></Error>}
                </div>
            </div>
            <div className="row mt-4">    
                <div className="col-md-6">
                    <label>DB Password</label><span className="ml-2">
                            <TooltipPro
                                className="bottom tsize-400"
                                tooltip={
                                    <div className ="row">
                                        <div className ="col-md-12">
                                            <p>Provide the password for the database user. </p>
                                        </div>
                                    </div>
                                }
                            >
                               <AiOutlineInfoCircle className="infoIcon" />
                            </TooltipPro>
                        </span>
                    <Input type="password" placeholder="DB Password" value={props.data.db_password} name="db_password" onChange={e => props.onChangeFolders(e, props.index)}></Input>
                    {props.errorArray && props.errorArray[props.index] && <Error error={props.errorArray[props.index].db_password}></Error>}
                </div>
                <div className="col-md-6">
                    <label>Host</label><span className="ml-2">
                            <TooltipPro
                                className="bottom tsize-400"
                                tooltip={
                                    <div className="row">
                                        <div className="col-md-12">
                                            <p>Provide the path of the host. If DB is on the same server where G8Keeper is running then simply specify "localhost" else provide fully qualified host url.</p>
                                        </div>
                                    </div>
                                }
                            >
                               <AiOutlineInfoCircle className="infoIcon" />
                            </TooltipPro>
                        </span>
                    <Input placeholder="Host" value={props.data.host} name="host" onChange={e => props.onChangeFolders(e, props.index)}></Input>
                    {props.errorArray && props.errorArray[props.index] && <Error error={props.errorArray[props.index].host}></Error>}
                </div>
            </div>
            <div className="row mt-4">    
                <div className="col-md-12">
                    <label>DB Name</label><span className="ml-2">
                            <TooltipPro
                                className="bottom tsize-400"
                                tooltip={
                                    <div className="row">
                                        <div className="col-md-12">
                                            <p>Provide the name of the database that needs to be backed up.</p>
                                        </div>
                                    </div>
                                }
                            >
                               <AiOutlineInfoCircle className="infoIcon" />
                            </TooltipPro>
                        </span>
                    <Input placeholder="DB Name" value={props.data.db_name} name="db_name" onChange={e => props.onChangeFolders(e, props.index)}></Input>
                    {props.errorArray && props.errorArray[props.index] && <Error error={props.errorArray[props.index].db_name}></Error>}
                </div>
            </div>
            {(props.hideRemove || props.data.id) && <div>
                <Button className="btn-danger removeSecIcon" onClick={props.remove}><MdClear /></Button>
            </div>}
        </div>}
    </>
}
export default DBBackupForm
