import React from "react";
import { Form, Input, Row, Col } from 'reactstrap';
import Select from '../../_components/CommonComponents/DropDown/Select'
import { Constants } from './Constants'
import { MdAdd, MdRemoveCircleOutline } from "react-icons/md";
import { Error } from '../CommonComponents/index'
const FormItem = ({ ...props }) => {
    return <div>
        <Form>
            {Array.isArray(props.socialMediaArray) && props.socialMediaArray.map((obj, index) => {
                return <Row className="social-media-form-listing">
                    <Col className="col-md-5">
                        <Input placeholder="URL" onChange={(e) => props.onChange(e.target.value, index, "url")} value={obj.url}></Input>
                        {props.errorArray && props.errorArray[index] && props.errorArray[index].url !== "" && <Error error={props.errorArray[index].url} />}
                    </Col>
                    <Col className="col-md-5">
                        <Select
                            options={Constants.socialMedia}
                            setSelectedOption={(e) => props.onChange(e.value, index, "social_media_type")}
                            selectedOption={obj.social_media_type}
                            isDisabled={true}
                      ></Select>
                        {props.errorArray && props.errorArray[index] && props.errorArray[index].social_media_type !== "" && <Error error={props.errorArray[index].social_media_type} />}
                    </Col>
                    <Col className="text-right"><MdRemoveCircleOutline className="deleteIcon actionIcon " size={0} onClick={() => props.removeSocialMediaURL(index)}></MdRemoveCircleOutline></Col>
                </Row>
            })}

            <Row className="m-2">
            <MdAdd className="socialIcon bluesum " size={30} onClick={() => props.addSocailMedia()}></MdAdd>
            </Row>
        </Form>
    </div>
}
export default FormItem