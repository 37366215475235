import React, { useState } from "react";
import { Input } from "reactstrap";
import { Modal, ModalTitle } from "react-bootstrap";
import { MdShare } from "react-icons/md";
import { domainDetailsServices } from "../../../_services/servicesWithSecondOrigin/index";
import { IoIosRemoveCircleOutline } from "react-icons/io";
import toastr from "toastr";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
const DomainShare = ({ ...props }) => {
  const [email, setEmail] = useState("");
  const [show, setShow] = useState(false);
  const [sharedWithArray, setSharedWithArray] = useState([]);

  const setShowPopup = () => {
    sharedWithFunc();
    setShow(true);
  };
  const onsubmit = async () => {
    let data = {
      email: email,
    };
    let response = await domainDetailsServices.ShareDomain(
      data,
      props.allData.id
    );
    if (response && response.status === 200) {
      callBack();
    }
    toastr.success(response.message);
  };

  const sharedWithFunc = async () => {
    let data = {
      domain_name: props.allData.domain_name,
    };
    let response = await domainDetailsServices.domainSharedWithList(data);
    if (response && Array.isArray(response.data)) {
      setSharedWithArray(response.data);
    }
    setShow(true);
  };

  const domainServerSharedWithRemoveFromOther = async (obj) => {
    let data = {
      domain_name: props.allData.domain_name,
      receiver_company_id: obj.company_id,
    };
    let response =
      await domainDetailsServices.domainServerSharedWithRemoveFromOther(data);
    if (response && response.message) {
      toastr.success(response.message);
      sharedWithFunc();
    }
  };

  const callBack = () => {
    setEmail("");
    setShow(false);
  };

  return (
    <>
      {parseInt(props.allData.shared) === 0 && (
        <OverlayTrigger
          placement={props.placement || "top"}
          overlay={<Tooltip>View and share domain with other users</Tooltip>}
        >
          {
            <MdShare
              className={props.className}
              onClick={() => setShowPopup(true)}
            />
          }
        </OverlayTrigger>
      )}
      {/* {parseInt(props.allData.shared) === 0 &&<OverlayTrigger placement="top" overlay={<Tooltip>View and share domain with other users</Tooltip>}><MdShare className={props.className} onClick={() => setShowPopup(true)} />
        </OverlayTrigger>}
         */}

      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <ModalTitle>Share ({props.allData.domain_name})</ModalTitle>
        </Modal.Header>
        <Modal.Body>
          <p>
            You can share the server data access with others. They will be able
            to view and edit details of the server on g8keeper. Only share with
            people you know. It will only work if they already have account on
            g8keeper. You can revoke access anytime you choose to do so.
          </p>
          <Input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter Email"
          ></Input>
        </Modal.Body>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => onsubmit()}
          >
            Share
          </button>
        </div>
        {Array.isArray(sharedWithArray) && sharedWithArray.length > 0 && (
          <div className="modal-body" style={{ borderTop: "1px solid #ddd" }}>
            {Array.isArray(sharedWithArray) && <h6>Domain Shared With</h6>}
            {Array.isArray(sharedWithArray) &&
              sharedWithArray.map((data) => {
                return (
                  <div className="row mt-1">
                    <div className="col-md-9">{data.email}</div>
                    <div className="col-md-3 text-right">
                      <IoIosRemoveCircleOutline
                        color="red"
                        size={20}
                        className="actionIcon deleteIcon"
                        onClick={() =>
                          domainServerSharedWithRemoveFromOther(data)
                        }
                      ></IoIosRemoveCircleOutline>
                    </div>
                  </div>
                );
              })}
          </div>
        )}
      </Modal>
    </>
  );
};

export default DomainShare;
