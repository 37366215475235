import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import ServerHistoryTable from "./ServerHistoryTable";
import historyIcon from "../../assets/img/history.png";

const ServerHistoryModal = (props) => {
    const { className } = props;

    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);

    return (
        <>
            {/* <TooltipPro tooltip="history" className={props.className}><img src={historyIcon} className="historyBlueIcon" onClick={toggle} /></TooltipPro> */}

            <OverlayTrigger placement="top" overlay={<Tooltip>{props.tooltip || "History"}</Tooltip>}>
                <img src={historyIcon} className="historyBlueIcon" alt="history" onClick={toggle} />
            </OverlayTrigger>

            <Modal isOpen={modal} toggle={toggle} className={className}>
                <ModalHeader toggle={toggle}>{props.tableName}</ModalHeader>
                <ModalBody>
                    <ServerHistoryTable
                        // tableName="Private IP"
                        user_element={props.user_element}
                        uuid={props.uuid}
                        myTimezone={props.myTimezone}
                        firstCol={props.firstCol}
                    ></ServerHistoryTable>
                </ModalBody>
            </Modal>
        </>
    );
};

export default ServerHistoryModal;
