import React from "react";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import { MdAdd } from "react-icons/md";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import SeoMonitoring from "./SeoMonitoring";

const SeoMonitoringPopup = ({ show,domain, setShow, ...props }) => {
    const { className } = props;

    const toggle = () => setShow(!show);

    return (
        <div>
            <OverlayTrigger placement="bottom" overlay={<Tooltip>Add SEO Keywords</Tooltip>}>
                <Button className="iconBtn addIconBtn" onClick={toggle}>
                    <MdAdd />
                </Button>
            </OverlayTrigger>
            <Modal size="lg" isOpen={show} toggle={toggle} className={className}>
                <ModalHeader toggle={toggle}>SEO Monitoring</ModalHeader>
                <ModalBody>
                    <SeoMonitoring popup={true} {...props} domain={domain} callback={toggle} />
                </ModalBody>
            </Modal>
        </div>
    );
};

export default SeoMonitoringPopup;
