import React, { useState, useEffect } from 'react'
import { Form } from 'reactstrap';
import { MButton, Loader } from '../../CommonComponents/index'
import { notificationChannelServices } from '../../../_services/servicesWithSecondOrigin/index'
import toastr from 'toastr'


const ModifiedFiles = ({ ...props }) => {
    const [data, setData] = useState([])
    const [loader, setLoader] = useState(false)

    useEffect(async () => {
        if (props.MSId && props.active === 6)
            getData()
    }, [props.MSId, props.active])

    const getData = async () => {
        setLoader(true)
        setData([])
        let objData = {
            "resource_type": "monitor_server",
            "resource_id": props.MSId,
        }
        let allNotifications = await notificationChannelServices.getAllNotificationChannel()

        if (allNotifications && Array.isArray(allNotifications.data)) {

            let AllResource = await notificationChannelServices.activateResourceNotification(objData)
            if (AllResource && AllResource.data && Array.isArray(AllResource.data)) {


                allNotifications.data.map((obj, index) => {
                    allNotifications.data[index].status = 0
                    AllResource.data.map((s_obj) => {
                        if (parseInt(obj.id) === parseInt(s_obj.notification_channel_id)) {
                            allNotifications.data[index].status = s_obj.status
                        }
                    })
                })
                setData(allNotifications.data)
            }
        }
        setLoader(false)
    }

    const onSubmit = async (e) => {
        let notifyingData = [...data]
        let objData = []
        Array.isArray(notifyingData) && notifyingData.map((obj) => {
            objData.push({
                "resource_id": props.MSId,
                "notification_channel_id": obj.id,
                "status": parseInt(obj.status) === 1 ? 1 : 0
            })
        })

        let objdata = {
            "resource_type": "monitor_server",
            "data": objData
        }

        let response = await notificationChannelServices.masterActivateNotification(objdata)
        if (response && response.message) {
            toastr.info(response.message)
        }
    }

    const onClick = (e, index) => {
        let notifyingData = [...data]
        notifyingData[index].status = (parseInt(notifyingData[index].status) === 1) ? 0 : 1
        setData(notifyingData)
    }

    return <div className="serverSettingData">
        <div className="setLoaderPosition">
            <Loader loader={loader}></Loader>
        </div>

        <div className="serverInfoTabsTitle flex-column align-items-start">
            <h3>Notification Setting</h3>
            <p className='serverInfoDetails'>If you want to enable all the notifications for the server on a notification channel. You just need to select and enable the channel. If you want a finer level control on which notifications you want to receive, configure the same in Notificaitons section.</p>
        </div>

        <div>
            <Form onSubmit={(e) => onSubmit(e)}>
                <div className="row mt-3">
                    {Array.isArray(data) && data.map((obj, index) => {
                        return <div className="col-md-6 col-lg-3">
                                    <div class="customCheckBox">
                                        <input type="checkbox" checked={parseInt(obj.status) === 1} onChange={(e) => onClick(e, index)} id={index} />
                                        <label for={index}>{obj.custom_channel_name}</label>
                                    </div>
                                </div>
                    })}
                </div>
                <div className="mt-4">
                    <MButton label="Save" onClick={(e) => onSubmit(e)} className="bluebutton"></MButton>
                </div>
            </Form>
        </div>
    </div>
}

export default ModifiedFiles