import React, { useState } from 'react'
import { Button, Form, Input } from 'reactstrap';
import { Validation } from '../../_helpers/validation/Validation'
import { Constants } from './Constants'
import { loginService } from '../../_services/servicesWithSecondOrigin/Login/login'
import toastr from 'toastr'
import { decode as base64_decode, encode as base64_encode } from 'base-64';
import PageLoader from '../../_components/CommonComponents/PageLoader/Loader'

const LoginForm = ({ ...props }) => {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [error, setError] = useState({})
    const [isLoading, setLoader] = useState(false)
    const login = async (e) => {
        e.preventDefault()
        setLoader(true)
        let flag = 0
        const field = Constants().loginRule;
        let errobj = Validation({ email: email, password: password }, field);
        setError(errobj)
        Object.keys(errobj).map(index => {
            if (errobj[index] !== "") {
                flag = 1;
            }
        });
        if (flag === 0) {
            let data = {
                "email": email,
                "password": password
            }

            let response = await loginService.login(data)
            if (response && response.data) {
                localStorage.setItem("basicAuthToken", base64_encode(response.data.token + ':'))
                localStorage.setItem("basicAuthData", JSON.stringify(response.data))
                toastr.info("Login Successful!")
                props.history.push('/dashboard')
            } else if (response && response.message) {
                toastr.warning(response.message)
            }
            setLoader(false)
        } else {
            setLoader(false)
        }
    }

    return <div>
        <PageLoader isLoading={isLoading}></PageLoader>
        <Form onSubmit={login}>
            <div className="form-group">
                <label>Email address</label>
                <Input placeholder="Email" value={email} onChange={e => { setEmail(e.target.value, setError({})) }}></Input>
                {error && error.email !== "" && <p>{error.email}</p>}
            </div>

            <div className="form-group">
                <label>Password</label>
                <Input type="password" placeholder="Password" value={password} onChange={e => { setPassword(e.target.value, setError({})) }}></Input>
                {error && error.password !== "" && <p>{error.password}</p>}
            </div>
            <div className="mt-4">
                <div className="user-button-login">
                    <Button className="btn-primary btn-block">Sign In</Button>
                </div>
            </div>
            {/* <div style={{ textAlign: 'center', margin: '5px' }}><a onClick={() => props.history.push('/signup')} href="#" >Sign Up</a></div> */}
        </Form>
    </div>
}
export default LoginForm