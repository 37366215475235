const keywordRule = [
    // {
    //     field_name: 'keyword',
    //     label: 'Keyword',
    //     type: "string",
    //     isRequired: true
    // },
    // {
    //     field_name: 'url',
    //     label: 'URL',
    //     type: "url",
    //     isRequired: true
    // }
]

export function Constants() {
    return { keywordRule }
}