const loginRule = [
  {
    field_name: "email",
    label: "Email",
    type: "email",
    isRequired: true,
  },
  {
    field_name: "first_name",
    label: "Name",
    type: "string",
    isRequired: true,
  },
  {
    field_name: "company",
    label: "Company",
    type: "string",
    isRequired: true,
  },
  {
    field_name: "password",
    label: "Password",
    type: "password",
    isRequired: true,
  },
  {
    field_name: "data_center",
    label: "Datacenter",
    type: "string",
    isRequired: true,
  },
];

export function Constants() {
  return { loginRule };
}
