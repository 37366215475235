import toastr from 'toastr'

export const telegramBotService = {
    getDataFromTelegram,
}

async function getDataFromTelegram(data) {
    try {
        const response = await fetch('https://api.telegram.org/bot1798761625:AAEtbIgSz944CiJDZ9XPd4tW6GLuFbMhP_c/getUpdates');
        return response.json();
    }
    catch (error) {
        console.log("error",error)
    }
}
