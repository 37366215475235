import { Redirect } from "react-router-dom";
import { URL_CONFIG } from "../../_constants/Config/URL_CONFIG";
import toastr from "toastr";
export const isLogin = () => {
  const token = localStorage.getItem("basicAuthToken");
  if (token) {
    return true;
  } else {
    return false;
  }
};

export const isAuthorized = (error) => {
  console.log("error detected while fetching data from api");
  if (error && error.response && error.response.status && parseInt(error.response.status) === 401) {
    localStorage.clear();
    if (window.location.host !== "13.232.26.204") {
      window.location.assign(URL_CONFIG.BASE_URL + "/login");
    }
  } else {
    toastr.warning("Error");
  }
};

export const debounce = (func, delay = 100) => {
  let debounceTimer;
  return function () {
    const context = this;
    const args = arguments;
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => func.apply(context, args), delay);
  };
};

export const throttle = (func, delay = 100) => {
  // Previously called time of the function
  let prev = 0;
  return (...args) => {
    let now = new Date().getTime();

    if (now - prev > delay) {
      prev = now;

      return func(...args);
    }
  };
};

export const CheckArray = (data) => {
  if (Array.isArray(data)) {
    if (data.length !== 0) {
      return data[0];
    } else {
      return "";
    }
  } else {
    return data;
  }
};

export const updateDom = (render) => {
  setInterval(() => {
    return render;
  }, 3000);
};

export const objectToQuery = (obj) => {
  let q = "?";
  Object.entries(obj).forEach((arr, i) => {
    q += (i === 0 ? "" : "&") + arr[0] + "=" + arr[1];
  });
  return q;
};

export const getQueryObject = (search) => {
  try {
    let obj = JSON.parse('{"' + decodeURI(search.substring(1)).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}');

    if (search) return obj;
  } catch (error) {}
};

export function getQueryVariable(variable) {
  var query = window.location.search.substring(1);
  console.log("Query", query);
  var vars = query.split("&");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    if (decodeURIComponent(pair[0]) == variable) {
      return decodeURIComponent(pair[1]);
    }
  }
  console.log("Query variable %s not found", variable);
}
