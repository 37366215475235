import React,
{ useState, useEffect } from 'react'
import { MdMarkunread } from "react-icons/md";
import { BsEnvelopeOpen } from "react-icons/bs";
import { ImCross } from "react-icons/im";
import Header from '../../_components/CommonComponents/Header/Header'
import { emailService } from '../../_services/servicesWithSecondOrigin/index'
import { Pagination, Select, Loader } from '../../_components/CommonComponents/index'
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { IoIosArrowRoundBack } from 'react-icons/io';
import { Button } from 'reactstrap';
import { Modal, ModalBody } from 'reactstrap';
import parse from 'html-react-parser';

function EmailComponent(props) {

    const [emailListing_backup, setEmailListingBackup] = useState([])
    const [dataObj, setDataObj] = useState("")

    // const [parPage, setParPage] = useState("")

    const [loader, setLoader] = useState(false)
    const [searchKey, setSearchKey] = useState("")
    const [selectAll, setSelectAll] = useState(false)
    const [selectAnyOne, setSelectAnyOne] = useState(false)

    const [emailListing, setEmailListing] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [pageCount, setPageCount] = useState(0)
    const [selectedOption, setSelectedOptionData] = useState(10)
    const [markAsRead, setMarkAsRead] = useState(0)
    useEffect(() => {
        if (emailListing && Array.isArray(emailListing)) {
            let length = emailListing.length
            let pageCount = parseInt(length / selectedOption)
            if (length % selectedOption !== 0) {
                pageCount = pageCount + 1
            }
            setPageCount(pageCount)
        }
    }, [emailListing, selectedOption])



    const getData = async (page) => {
        setLoader(true)
        if (props.location && props.location.state && props.location.state.email) {
            let data = {
                "email": props.location.state.email
            }
            let response = await emailService.getAllEmail(page, data)
            if (response && response.data) {
                setEmailListing(JSON.parse(JSON.stringify(response.data)))
                setEmailListingBackup(JSON.parse(JSON.stringify(response.data)))
            } else {
                setEmailListing([])
                setPageCount(0)
                setCurrentPage(1)
                // setParPage("")
                setEmailListingBackup([])
            }
        }


        setLoader(false)
    }

    useEffect(getData, [])

    const onClickEmail = async (data, index) => {
        setDataObj(data)
        if (parseInt(data.is_read) === 0) {
            let response = await emailService.readEmail(data.id, { is_read: 1 })
            if (response && response.status === 200) {
                let arr = [...emailListing]
                data.is_read = 1
                arr[index] = data
                setEmailListing(arr)
            }
        }
    }

    const setPageNumber = (page) => {
        setCurrentPage(page)
        refreshonPagination()
    }

    const setSelectedOption = (obj) => {
        setSelectedOptionData(obj.value)
        setCurrentPage(1)
        refreshonPagination()
    }


    const onChangeSearch = (e) => {
        setSearchKey(e.target.value)
    }


    const onsubmitSearch = (e) => {
        e.preventDefault()
        const value = searchKey
        let arr = JSON.parse(JSON.stringify(emailListing_backup))
        let newArray = []
        newArray = arr.filter((obj) => {
            if (obj && obj.email_subject.toLowerCase().includes(value.toLowerCase())) {
                return true
            } else {
                return false
            }
        })
        if (value) { setEmailListing(newArray) } else { setEmailListing(arr) }
    }

    const selectBox = (index) => {
        let arr = JSON.parse(JSON.stringify(emailListing))
        let isallSelect = true;
        let selectedAny = false
        arr[index].selected = arr[index].selected === 1 ? 0 : 1;

        let count = 0;
        let uread = 0
        arr.map((obj) => {
            if (obj.selected !== 1) {
                isallSelect = false
            }
            if (obj.selected === 1) {
                selectedAny = true
                if (parseInt(obj.is_read) === 0) {
                    uread += 1
                }
                count += 1
            }
        })
        setMarkAsRead(uread === count ? 1 : 0)
        setSelectAnyOne(selectedAny)
        setSelectAll(isallSelect)
        setEmailListing(arr)
    }

    const selectAllBox = () => {
        let arr = JSON.parse(JSON.stringify(emailListing))
        arr.map((obj) => {
            if (selectAll) {
                obj.selected = 0
            } else {
                obj.selected = 1
            }
        })
        setSelectAnyOne(!selectAll)
        setSelectAll(!selectAll)
        setEmailListing(arr)
    }

    const onClickUnread = async () => {
        let arr = JSON.parse(JSON.stringify(emailListing))
        let newArray = []
        let count = 0;
        let read = 0;
        arr.map((obj) => {
            if (obj.selected === 1) {
                if (parseInt(obj.is_read) === 1) {
                    read += 1
                }
                count += 1
            }
        })

        arr.map((obj) => {
            if (obj.selected === 1) {
                obj.is_read = (count === read) ? 0 : 1;
                newArray.push(obj.id)
            }
        })
        let data = {
            "id": newArray,
            "is_read": count === read ? 0 : 1
        }
        if (newArray.length > 0) {
            let response = await emailService.readUnread(data)
            if (response && response.status) {
                setMarkAsRead(markAsRead === 1 ? 0 : 1)
                setEmailListing(arr)

            }
        }
    }

    const refreshonPagination = () => {
        let arr = JSON.parse(JSON.stringify(emailListing))
        arr.map((obj) => {
            obj.selected = 0
        })
        setSelectAnyOne(false)
        setSelectAll(false)
        setEmailListing(arr)
    }

    const singleReadUnread = async (data, index, read) => {
        let response = await emailService.readEmail(data.id, { is_read: data.is_read === 0 ? 1 : 0 })
        if (response && response.status === 200) {
            let arr = [...emailListing]
            data.is_read = data.is_read === 0 ? 1 : 0
            arr[index] = data
            setEmailListing(arr)
        }
    }

    return <div className="container">
        <Header history={props.history} {...props}></Header>
        <div className="loaderCenter"> <Loader loader={loader}></Loader></div>
        <div className="seo-monitoring">

            <div className="serverDetailsSec emailPageTopFilter" >
                <Button className="backBtn hiddenMob mr-4" onClick={() => props.history.push('/email-inboxes')}>
                    <IoIosArrowRoundBack></IoIosArrowRoundBack>
                </Button>
                <div className="d-flex align-items-center flexDisableMob">
                    <h6 className="card-title mb-0 mr-2">Emails</h6>
                    <form onSubmit={onsubmitSearch} className="d-flex mr-2 align-items-center"> <input placeholder="Search" className="form-control" onChange={onChangeSearch}></input><button className="ml-2 addEditBtn btn btn-primary">Search</button></form>
                    <h6 className="ml-2 m-0">{props.location.state.email && props.location.state.email}</h6>
                </div>
            </div>
            <div >
                <div className="card">
                    <div className="card-body mp-0" style={{ position: 'relative' }}>
                      <div className="table-responsive">
                        <table className="table table-bordered">
                            <tbody className={dataObj ? "co" : "co2"}>
                                {Array.isArray(emailListing) && emailListing.length > 0 && <tr><td>
                                    <input className="actionIcon ml-2" onClick={() => selectAllBox()} type="checkbox" style={{
                                        width: '14px',
                                        height: '14px'
                                    }}
                                        checked={selectAll}
                                    ></input>
                                    {selectAnyOne && <OverlayTrigger overlay={<Tooltip>Mark as {markAsRead === 0 ? "unread" : "read"}</Tooltip>}>
                                        <span >
                                            {markAsRead === 0 ?
                                                <MdMarkunread className="actionIcon" onClick={() => onClickUnread()}></MdMarkunread>
                                                : <BsEnvelopeOpen className="actionIcon" onClick={() => onClickUnread()}></BsEnvelopeOpen>
                                            }
                                        </span>
                                    </OverlayTrigger>}
                                </td>
                                </tr>}
                                {Array.isArray(emailListing) && emailListing.length > 0 ? emailListing.map((data, index) => {
                                    if (currentPage * selectedOption > index && currentPage * selectedOption < index + 1 + selectedOption) {
                                        // if (dataObj) {
                                        //     return <tr style={data.selected === 1 ? { background: "#6a7d4b" } : { background: dataObj === data ? "#b5afaf" : "" }} onClick={() => onClickEmail(data)} className={data.is_read === 0 ? "p-2 unread-email" : "p-2 read-email"} >
                                        //         <td className=""><input onClick={() => selectBox(index)} type="checkbox" style={{
                                        //             width: '14px',
                                        //             height: '14px'
                                        //         }}
                                        //             checked={data.selected === 1}
                                        //         ></input></td>
                                        //         <td><h6 className="">{data.from_email.replace(/\<(.*?)\>/, "")}</h6></td>
                                        //         <td ><p className="garage-content">{data.email_subject} </p></td>
                                        //     </tr>
                                        // } else {
                                            return <tr className={data.is_read === 0 ? "p-2 unread-email" : "p-2 read-email"} style={data.selected === 1 ? { background: "#6a7d4b" } : {}}>
                                                <td className=""><input onClick={() => selectBox(index)} type="checkbox" style={{
                                                    width: '14px',
                                                    height: '14px'
                                                }}
                                                    checked={data.selected === 1}
                                                ></input></td>
                                                <td><h6 onClick={() => onClickEmail(data, index)} className="">{data.from_email.replace(/\<(.*?)\>/, "")}</h6></td>
                                                <td ><p onClick={() => onClickEmail(data, index)} className="">{data.email_subject} 
                                                {/* - {(data.message)}  */}
                                                </p></td>
                                                <td><p className="">{data.service_provider} </p></td>
                                                <td><p className="">{data.created_at} </p></td>

                                                <td><div className="" onClick={() => singleReadUnread(data, index)}><OverlayTrigger overlay={<Tooltip>Mark as {data.is_read === 0 ? "read" : "unread"}</Tooltip>}>
                                                    <div> {data.is_read === 1 ? <MdMarkunread className="actionIcon" onClick={() => onClickUnread()}></MdMarkunread> : <BsEnvelopeOpen size='12' className="actionIcon" onClick={() => onClickUnread()}></BsEnvelopeOpen>}</div>
                                                </OverlayTrigger></div></td>

                                            </tr>
                                        // }
                                    }
                                }) : <div>No Record Found</div>}
                            </tbody>
                        </table>
                        </div>
                        <Modal size="lg" isOpen={dataObj} toggle={() => setDataObj("")} centered>

                            {dataObj && <div>
                                <div style={{ height: '100%' }}>
                                    <ModalBody>
                                        <ImCross style={{ float: 'right' }} className="close" size="10" onClick={() => setDataObj("")} />
                                        <h6> {dataObj.email_subject}</h6>
                                        <p >{dataObj.from_email}</p>
                                        <p >To: {dataObj.to_email}</p>
                                        <p >Message: </p> <div className='email-div'>{parse(dataObj.message)}</div>
                                        {/* {dataObj.attachments && <p>Attachments :{dataObj.attachments}</p>} */}
                                    </ModalBody>
                                </div>
                            </div>}

                        </Modal>
                        {Array.isArray(emailListing) && emailListing.length !== 0 && <div className="paginationSec col-md-12" >
                            <div className="totalPage">
                                <Select selectedOption={selectedOption} setSelectedOption={setSelectedOption} options={[{ label: 10, value: 10 }, { label: 30, value: 30 }, { label: 50, value: 50 }, { label: 70, value: 70 }, { label: 100, value: 100 }]}></Select>
                            </div>
                            <Pagination
                                currentPage={currentPage}
                                pageCount={pageCount}
                                onClickPage={(page) => setPageNumber(page)}
                            ></Pagination>
                        </div>}

                    </div>


                </div>
            </div>
        </div>
    </div>
}

export default EmailComponent;

