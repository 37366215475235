import React from "react";
import { Button } from "reactstrap";
import Header from "../../_components/CommonComponents/Header/Header";
import {
  DateTime,
  ServerCurrentLoggedIn,
  TooltipPro,
} from "../../_components/CommonComponents/index";
import BackupFoldrChangeListing from "../../_components/BackUpFolderListing/BackUpFolderListing";
import { IoIosArrowRoundBack } from "react-icons/io";
import moment from "moment";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { objectToQuery } from "../../_helpers/Common/Common";
class BackupFolderChange extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      uuid: "",
      myTimezone: "",
      server_name: "",
      unique_id: null,
      start_timestamp: "",
      end_timestamp: "",
      modified_date: "",
      filepath: "",
      filetype: "",
      onSubmitSearch: "",
      type: "",
    };
  }

  componentDidMount() {
    let Timezone = localStorage.getItem("Timezone");
    this.setState(
      {
        myTimezone: Timezone,
      },
      () => {
        if (this.props.location.state.uuid) {
          this.setState({
            uuid: this.props.location.state.uuid,
          });
        }
        if (
          this.props.location &&
          this.props.location.state &&
          this.props.location.state.id
        ) {
          this.setState(
            {
              id: this.props.location.state.id,
              uuid: this.props.location.state.uuid,
              server_name: this.props.location.state.server_name,
            },
            async () => {}
          );
        } else {
          this.props.history.push("/monitor-servers");
        }
      }
    );
  }

  onSelectServer = (e) => {
    this.setState({
      id: e.id,
      uuid: e.value,
      server_name: e.label,
    });
  };

  onSelectUniqueId = (e) => {
    this.setState({
      unique_id: e.value,
    });
  };

  filterPassedStartDate = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);
    const end_timestamp = new Date(moment(this.state.end_timestamp));
    if (this.state.start_timestamp) {
      return currentDate > selectedDate && end_timestamp >= selectedDate;
    } else {
      return true;
    }
  };

  filterPassedEndDate = (time) => {
    const currentDate = new Date();
    const start_timestamp = new Date(moment(this.state.start_timestamp));
    const selectedDate = new Date(time);
    return currentDate > selectedDate && start_timestamp <= selectedDate;
  };

  setStartDateTime = (date) => {
    this.setState({
      start_timestamp: moment(date).format("YYYY-MM-DD HH:mm:ss"),
    });
  };

  setEndDateTime = (date) => {
    this.setState({
      end_timestamp: moment(date).format("YYYY-MM-DD HH:mm:ss"),
    });
  };

  dateFilter = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);
    return currentDate > selectedDate;
  };

  onChangeModifiedDate = (date) => {
    this.setState({
      modified_date: moment(date).format("YYYY-MM-DD HH:mm:ss"),
    });
  };

  reset = () => {
    this.setState(
      {
        start_timestamp: "",
        end_timestamp: "",
        modified_date: "",
        filepath: "",
        filetype: "",
        type: "",
      },
      () => {
        this.onSubmit();
      }
    );
  };

  onSubmit = () => {
    let onSubmitSearch = "";
    if (this.state.start_timestamp) {
      onSubmitSearch =
        onSubmitSearch + "&start_timestamp=" + this.state.start_timestamp;
    }
    if (this.state.end_timestamp) {
      onSubmitSearch =
        onSubmitSearch + "&end_timestamp=" + this.state.end_timestamp;
    }
    if (this.state.modified_date) {
      onSubmitSearch =
        onSubmitSearch + "&modified_date=" + this.state.modified_date;
    }
    if (this.state.filepath) {
      onSubmitSearch = onSubmitSearch + "&filepath=" + this.state.filepath;
    }
    if (this.state.filetype) {
      onSubmitSearch = onSubmitSearch + "&filetype=" + this.state.filetype;
    }
    if (this.state.type) {
      onSubmitSearch = onSubmitSearch + "&type=" + this.state.type;
    }
    this.setState({
      onSubmitSearch: onSubmitSearch,
    });
  };

  render() {
    const object = {
      id: this.state.id,
      uuid: this.state.uuid,
      update: true,
      server_name: this.state.server_name,
    };
    return (
      <div className="container">
        <Header history={this.props.history} {...this.props}></Header>

        <div className="serverDetailsSec">
          <Button
            className="backBtn mr-4"
            onClick={() =>
              this.props.history.push(
                "/monitor-server/charts" + objectToQuery(object),
                object
              )
            }>
            <IoIosArrowRoundBack></IoIosArrowRoundBack>
          </Button>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <ServerCurrentLoggedIn
              server_name={this.state.server_name}
              uuid={this.state.uuid}
              onSelectServer={this.onSelectServer}
              mytimezone={this.state.mytimezone}
              unique_id={this.state.unique_id}
              onSelectUniqueId={this.onSelectUniqueId}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card overflow-inherit">
              <div className="card-body">
                <div className="row bckupfolderChange">
                  <div className="col-md-4 mt-2">
                    <label>File Path</label>
                    <TooltipPro
                      className="bottom tsize-300 ml-1"
                      tooltip={
                        <div className="row">
                          <div className="col-md-12">
                            <p>
                              <strong> For help </strong>
                              Text will reflect soon
                            </p>
                          </div>
                        </div>
                      }>
                      <AiOutlineInfoCircle className="infoIcon" />
                    </TooltipPro>
                    <input
                      className="form-control"
                      type="text"
                      value={this.state.filepath}
                      onChange={(e) =>
                        this.setState({
                          filepath: e.target.value,
                        })
                      }></input>
                  </div>
                  <div className="col-md-4 mt-2">
                    <label>File Type</label>
                    <TooltipPro
                      className="bottom tsize-300 ml-1"
                      tooltip={
                        <div className="row">
                          <div className="col-md-12">
                            <p>
                              <strong> For help </strong>
                              Text will reflect soon
                            </p>
                          </div>
                        </div>
                      }>
                      <AiOutlineInfoCircle className="infoIcon" />
                    </TooltipPro>
                    <input
                      className="form-control"
                      type="text"
                      value={this.state.filetype}
                      onChange={(e) =>
                        this.setState({
                          filetype: e.target.value,
                        })
                      }></input>
                  </div>

                  <div className="col-md-4 mt-2">
                    <label>Type</label>
                    <TooltipPro
                      className="bottom tsize-300 ml-1"
                      tooltip={
                        <div className="row">
                          <div className="col-md-12">
                            <p>
                              <strong> For help </strong>
                              Text will reflect soon
                            </p>
                          </div>
                        </div>
                      }>
                      <AiOutlineInfoCircle className="infoIcon" />
                    </TooltipPro>
                    <select
                      value={this.state.type}
                      className="date-filter-class form-control"
                      onChange={(e) => this.setState({ type: e.target.value })}>
                      <option value="">Select</option>
                      <option value="New">New</option>
                      <option value="Deleted">Deleted</option>
                      <option value="Modified / old">Modified / Old</option>
                    </select>
                  </div>
                  <div className="col-md-4 mt-2">
                    <label>Start Date</label>
                    <TooltipPro
                      className="bottom tsize-300 ml-1"
                      tooltip={
                        <div className="row">
                          <div className="col-md-12">
                            <p>
                              <strong> For help </strong>
                              Text will reflect soon
                            </p>
                          </div>
                        </div>
                      }>
                      <AiOutlineInfoCircle className="infoIcon" />
                    </TooltipPro>
                    <br />
                    <DateTime
                      dateTime={this.state.start_timestamp}
                      setDateTime={this.setStartDateTime}
                      className="date-filter-class form-control"
                      filterPassedDate={this.filterPassedStartDate}></DateTime>
                  </div>
                  <div className="col-md-4 mt-2">
                    <label>End Date</label>
                    <TooltipPro
                      className="bottom tsize-300 ml-1"
                      tooltip={
                        <div className="row">
                          <div className="col-md-12">
                            <p>
                              <strong> For help </strong>
                              Text will reflect soon
                            </p>
                          </div>
                        </div>
                      }>
                      <AiOutlineInfoCircle className="infoIcon" />
                    </TooltipPro>
                    <br />
                    <DateTime
                      dateTime={this.state.end_timestamp}
                      setDateTime={this.setEndDateTime}
                      className="date-filter-class form-control"
                      filterPassedDate={this.filterPassedEndDate}></DateTime>
                  </div>
                  <div className="col-md-4 mt-2">
                    <label>File Modified Date</label>
                    <TooltipPro
                      className="bottom tsize-300 ml-1"
                      tooltip={
                        <div className="row">
                          <div className="col-md-12">
                            <p>
                              <strong> For help </strong>
                              Text will reflect soon
                            </p>
                          </div>
                        </div>
                      }>
                      <AiOutlineInfoCircle className="infoIcon" />
                    </TooltipPro>
                    <br />
                    <DateTime
                      dateTime={this.state.modified_date}
                      setDateTime={this.onChangeModifiedDate}
                      className="date-filter-class form-control"
                      placeholder="Date"
                      filterPassedDate={this.dateFilter}></DateTime>
                  </div>
                </div>
                <div className="mt-3 text-center">
                  <Button
                    className="btn-primary mr-2"
                    onClick={() => this.onSubmit()}>
                    Search
                  </Button>
                  <Button onClick={() => this.reset()}>Reset</Button>
                </div>
              </div>
            </div>
            <div className="card overflow-inherit">
              <BackupFoldrChangeListing
                {...this.props}
                uuid={this.state.uuid}
                onSubmitSearch={
                  this.state.onSubmitSearch
                }></BackupFoldrChangeListing>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BackupFolderChange;
