import { RestMethodSecondOrigin } from "../../../_helpers/ApiConfigTwo/RestMethod";
import FilterResponse from "../../../_helpers/FilterResponse";
import { isAuthorized } from "../../../_helpers/Common/Common";

export const monitorServersServices = {
  addMonitorServer,
  getMonitorServer,
  updateMonitorServers,
  deleteMonitorServers,
  shareMonitorServers,
  getAllMonitorServer,
  getAllMonitorServerForChart,
  monitorServerChartDetails,
  topProcessServerDetails,
  diskIOProcessServerDetails,
  currentLoggedInServerDetails,
  monitorServerChartDetailsLog,
  MonitorServerBackUpFolderChange,
  getDisallowedFiles,
  updateDisallowedFiles,
  monitorServerHistory,
  lastRebootTime,
  monitorServerSharedWith,
  monitorServerSharedWithRemoveFromOther,
  monitorServerSharedByOtherRemove,
  awsMetaData,
  getDatabaseSchema,
  generateKey,
  getKey,
  savePublicPrivateKey,
  selectedId,
  getAllInactiveMonitorServer,
  getPrivateKey,
  AddPrivateKey,
  getMonitorServerCronList,
  getMonitorServerCronLogs,
  updateMonitorServerCronJob,
  getMonitorServerCronLogChartData,
  customLogTableDetails,
};

async function getMonitorServerCronList(data) {
  try {
    const response = await RestMethodSecondOrigin.POST("/cron/cronjob-list", data);
    return response.data;
  } catch (error) {
    return { data: [] };
  }
}

async function updateMonitorServerCronJob(data) {
  try {
    const response = await RestMethodSecondOrigin.PUT("/cron/update-cronjob?id=" + data.id, data);
    return response.data;
  } catch (error) {
    return null;
  }
}

async function getMonitorServerCronLogs(data) {
  try {
    const response = await RestMethodSecondOrigin.POST("/cron/cronjob-log?page=" + data.pageNumber, data);
    return response.data;
  } catch (error) {
    return { data: [] };
  }
}

async function getMonitorServerCronLogChartData(data) {
  try {
    const response = await RestMethodSecondOrigin.POST("/cron/cron-chart", data);
    return response.data;
  } catch (error) {
    return { data: [] };
  }
}

async function addMonitorServer(data) {
  try {
    const response = await RestMethodSecondOrigin.POST("/monitor-server/add-monitor-server", data);
    // const api_response = FilterResponse(response);
    return response;
  } catch (error) {
    isAuthorized(error);
    return null;
  }
}

async function getMonitorServer(id) {
  try {
    const response = await RestMethodSecondOrigin.GET("/monitor-server/view-monitor-server?id=" + id);
    const api_response = FilterResponse(response);
    return api_response;
  } catch (error) {
    isAuthorized(error);
    return null;
  }
}

async function getAllMonitorServer(id) {
  try {
    const response = await RestMethodSecondOrigin.GET("/monitor-server/all-server");
    const api_response = FilterResponse(response);
    return api_response;
  } catch (error) {
    isAuthorized(error);
    return null;
  }
}

async function getAllInactiveMonitorServer(id) {
  try {
    const response = await RestMethodSecondOrigin.GET("/monitor-server/all-inactive-server");
    const api_response = FilterResponse(response);
    return api_response;
  } catch (error) {
    isAuthorized(error);
    return null;
  }
}

async function getAllMonitorServerForChart(id) {
  try {
    const response = await RestMethodSecondOrigin.GET("/monitor-server/server-for-chart");
    const api_response = FilterResponse(response);
    return api_response;
  } catch (error) {
    isAuthorized(error);
    return null;
  }
}

async function updateMonitorServers(id, data) {
  try {
    const response = await RestMethodSecondOrigin.PUT("/monitor-server/update-monitor-server?id=" + id, data);
    const api_response = FilterResponse(response);
    return api_response;
  } catch (error) {
    isAuthorized(error);
    return null;
  }
}

async function deleteMonitorServers(id) {
  try {
    const response = await RestMethodSecondOrigin.GET("/monitor-server/delete-monitor-server?id=" + id);
    const api_response = FilterResponse(response);
    return api_response;
  } catch (error) {
    isAuthorized(error);
    return null;
  }
}

async function shareMonitorServers(id, data) {
  try {
    const response = await RestMethodSecondOrigin.PUT("/monitor-server/share-monitor-server?id=" + id, data);
    const api_response = FilterResponse(response);
    return api_response;
  } catch (error) {
    isAuthorized(error);
    return null;
  }
}

async function monitorServerChartDetails(data) {
  try {
    const response = await RestMethodSecondOrigin.POST("/chart/server-details", data);
    const api_response = FilterResponse(response);
    return api_response;
  } catch (error) {
    isAuthorized(error);
    return null;
  }
}

async function monitorServerChartDetailsLog(data) {
  try {
    const response = await RestMethodSecondOrigin.POST("/server-status/server-log", data);
    const api_response = FilterResponse(response);
    return api_response;
  } catch (error) {
    isAuthorized(error);
    return null;
  }
}

async function topProcessServerDetails(data) {
  try {
    const response = await RestMethodSecondOrigin.POST("/chart/top-process", data);
    const api_response = FilterResponse(response);
    return api_response;
  } catch (error) {
    isAuthorized(error);
    return null;
  }
}

async function diskIOProcessServerDetails(data) {
  try {
    const response = await RestMethodSecondOrigin.POST("/chart/disk-io-process", data);
    const api_response = FilterResponse(response);
    return api_response;
  } catch (error) {
    isAuthorized(error);
    return null;
  }
}

async function currentLoggedInServerDetails(data) {
  try {
    const response = await RestMethodSecondOrigin.POST("/chart/current-logged-in", data);
    const api_response = FilterResponse(response);
    return api_response;
  } catch (error) {
    isAuthorized(error);
    return null;
  }
}

async function MonitorServerBackUpFolderChange(id, page, pageSize, selectPageItem, onSubmitSearch, timediff) {
  let URL = "/backup/folder-change?id=" + id;
  if (page) {
    URL = URL + "&page=" + page;
  }
  if (pageSize || selectPageItem) {
    URL = URL + "&pageSize=" + (pageSize || selectPageItem);
  }

  //   if (selectPageItem) {
  //     URL = URL + "&pageSize=" + selectPageItem;
  //   }
  if (onSubmitSearch) {
    URL = URL + onSubmitSearch;
  }
  if (timediff) {
    URL = URL + "&timediff=" + timediff;
  }
  try {
    const response = await RestMethodSecondOrigin.GET(URL);
    const api_response = FilterResponse(response);
    return api_response;
  } catch (error) {
    isAuthorized(error);
    return null;
  }
}

async function getDisallowedFiles(data) {
  try {
    const response = await RestMethodSecondOrigin.POST("/disallowed-file/all-files", data);
    const api_response = FilterResponse(response);
    return api_response;
  } catch (error) {
    isAuthorized(error);
    return null;
  }
}

async function updateDisallowedFiles(data) {
  try {
    const response = await RestMethodSecondOrigin.POST("/disallowed-file/update-disallowed", data);
    const api_response = FilterResponse(response);
    return api_response;
  } catch (error) {
    isAuthorized(error);
    return null;
  }
}

async function monitorServerHistory(data) {
  try {
    const response = await RestMethodSecondOrigin.POST("/history/history-data", data);
    const api_response = FilterResponse(response);
    return api_response;
  } catch (error) {
    isAuthorized(error);
    return null;
  }
}

async function lastRebootTime(data) {
  try {
    const response = await RestMethodSecondOrigin.POST("/chart/last-reboot", data);
    const api_response = FilterResponse(response);
    return api_response;
  } catch (error) {
    isAuthorized(error);
    return null;
  }
}

async function monitorServerSharedWith(uuid) {
  try {
    const response = await RestMethodSecondOrigin.GET("/monitor-server/server-shared-with?uuid=" + uuid);
    const api_response = FilterResponse(response);
    return api_response;
  } catch (error) {
    isAuthorized(error);
    return null;
  }
}

async function monitorServerSharedWithRemoveFromOther(data) {
  try {
    const response = await RestMethodSecondOrigin.POST("/monitor-server/remove-shared-server", data);
    const api_response = FilterResponse(response);
    return api_response;
  } catch (error) {
    isAuthorized(error);
    return null;
  }
}

async function monitorServerSharedByOtherRemove(data) {
  try {
    const response = await RestMethodSecondOrigin.POST("/monitor-server/remove-shared", data);
    const api_response = FilterResponse(response);
    return api_response;
  } catch (error) {
    isAuthorized(error);
    return null;
  }
}

async function awsMetaData(data) {
  try {
    const response = await RestMethodSecondOrigin.POST("/chart/aws-meta-data", data);
    const api_response = FilterResponse(response);
    return api_response;
  } catch (error) {
    isAuthorized(error);
    return null;
  }
}

async function getDatabaseSchema(data) {
  try {
    const response = await RestMethodSecondOrigin.POST("/database-schemas/database-schema-chart", data);
    const api_response = FilterResponse(response);
    return api_response;
  } catch (error) {
    isAuthorized(error);
    return null;
  }
}

async function getKey() {
  try {
    const response = await RestMethodSecondOrigin.GET("/secret-key/get-key");
    const api_response = FilterResponse(response);
    return api_response;
  } catch (error) {
    isAuthorized(error);
    return null;
  }
}

async function generateKey() {
  try {
    const response = await RestMethodSecondOrigin.GET("/secret-key/generate");
    const api_response = FilterResponse(response);
    return api_response;
  } catch (error) {
    isAuthorized(error);
    return null;
  }
}

async function savePublicPrivateKey(data) {
  try {
    const response = await RestMethodSecondOrigin.POST("/secret-key/private-public-key", data);
    const api_response = FilterResponse(response);
    return api_response;
  } catch (error) {
    isAuthorized(error);
    return null;
  }
}

async function selectedId(data) {
  try {
    const response = await RestMethodSecondOrigin.POST("/monitor-server/selected-id", data);
    const api_response = FilterResponse(response);
    return api_response;
  } catch (error) {
    isAuthorized(error);
    return null;
  }
}

async function getPrivateKey(id) {
  try {
    const response = await RestMethodSecondOrigin.GET("/private-key/get-private-key?id=" + id);
    const api_response = FilterResponse(response);
    return api_response;
  } catch (error) {
    isAuthorized(error);
    return null;
  }
}

async function AddPrivateKey(data) {
  try {
    const response = await RestMethodSecondOrigin.POST("/private-key/add-key", data);
    const api_response = FilterResponse(response);
    return api_response;
  } catch (error) {
    isAuthorized(error);
    return null;
  }
}

async function customLogTableDetails(data) {
  try {
    const response = await RestMethodSecondOrigin.POST("chart/custom-log-list", data);
    const api_response = FilterResponse(response);
    return api_response;
  } catch (error) {
    isAuthorized(error);
    return null;
  }
}
