import React from 'react'
import { ImCross } from 'react-icons/im'
class TagInput extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            value: "",
            allVal: []
        }
    }

    componentDidMount() {
        if (this.props.value) {
            this.setState({
                allVal: this.props.value.split(',')
            })
        }
    }

    componentDidUpdate(prev) {
        if (this.props.value && this.props.value !== prev.value) {
            this.setState({
                allVal: this.props.value.split(',')
            })
        }

    }

    onChange = (e) => {
        const allVal = [...this.state.allVal]
        let { value } = e.target
        value = value.replace(',,', ',')
        if (value.length > 1 && value.includes(',')) {
            this.setState({
                allVal: value.trim() ? [value.replace(",", ""), ...allVal] : allVal,
                value: "",
            }, () => {
                this.callBackValue()
            })
        } else {
            this.setState({
                value: value,
            })
        }
    }

    callBackValue = () => {
        const allVal = [...this.state.allVal]
        if (this.props.onChange) {
            this.props.onChange(allVal.join())
        }
    }

    remove = (i) => {
        const allVal = [...this.state.allVal]
        allVal.splice(i, 1)
        this.setState({
            allVal: allVal
        }, () => {
            this.callBackValue()
        })
    }

    render() {
        return <div className="tagSec">
            <div className="mb-2">{Array.isArray(this.state.allVal) && this.state.allVal.map((data, i) => {
                return <span className="tagClass">{data} <ImCross style={{ cursor: 'pointer' }} onClick={() => this.remove(i)}></ImCross></span>
            })} </div><input className="form-control" onChange={this.onChange} value={this.state.value}></input>
        </div>
    }
}
export default TagInput