import React, { Fragment, useState, useEffect } from 'react'
import { monitorServersServices } from '../../_services/servicesWithSecondOrigin/index'
import { Pagination } from '../../_components/CommonComponents/index'
import { Loader } from '../../_components/CommonComponents/index'
const ServerHistoryTable = (props) => {
    const [data, setData] = useState([])
    const [pageCount, setPageCount] = useState(0)
    const [currentPage, setcurrentPage] = useState(1)
    const [loader, setLoader] = useState(true)
    useEffect(async () => {
        if (props.uuid && props.myTimezone) {
            getData()
            setPageCount(0)
            setcurrentPage(1)
        }
    }, [props.uuid, props.myTimezone])

    const getData = async (id) => {
        setLoader(true)
        let dataObj = {
            "uuid": props.uuid,
            "user_element": props.user_element,
            "timezone": props.myTimezone,
        }
        let response = await monitorServersServices.monitorServerHistory(dataObj)
        if (response && response.data && Array.isArray(response.data)) {
            setData(response.data)
            if (response.data && Array.isArray(response.data)) {
                let length = response.data.length
                let pageCount = parseInt(length / 5)
                if (length % 5 !== 0) {
                    pageCount = pageCount + 1
                }
                setPageCount(pageCount)
            }
        } else {
            setData([])
        }
        setLoader(false)
    }

    const onClick = () => {
        if (props.onClick) {
            props.onClick()
        }
    }

    const setPageNumber = (page) => {
        setcurrentPage(page)
    }

    return <Fragment>
        <div className="chart-loader"> <Loader loader={loader}></Loader></div>
        <div>
            {props.tableName && <div className="card-header"><h3>{props.tableName}</h3></div>}
            <div onClick={() => onClick()}>
                {props.user_element === "currently_logged_in" ? <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th scope="col">S.No.</th>
                            <th scope="col">{props.firstCol ? props.firstCol : "IP"}</th>
                            <th scope="col">Server</th>
                            <th scope="col">Start</th>
                            <th scope="col">End</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Array.isArray(data) && data.map((obj, index) => {
                            if (currentPage * 5 > index && currentPage * 5 < index + 1 + 5)
                                return <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{obj.ip}</td>
                                    <td>{obj.server}</td>
                                    <td>{obj.start}</td>
                                    <td>{obj.end}</td>
                                </tr>
                        })}
                    </tbody>
                </table> : <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th scope="col">S.No.</th>
                                <th scope="col">{props.firstCol ? props.firstCol : "IP"}</th>
                                <th scope="col">Timestamp</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Array.isArray(data) && data.map((obj, index) => {
                                if (currentPage * 5 > index && currentPage * 5 < index + 1 + 5)
                                    return <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{obj.value}</td>
                                        <td>{obj.timestamp}</td>
                                    </tr>
                            })}
                        </tbody>
                    </table>}
            </div>

            <div className="paginationSec">
                <Pagination
                    onClickPage={setPageNumber}
                    pageCount={pageCount}
                    currentPage={currentPage}
                ></Pagination>
            </div>
        </div>
    </Fragment>
}

export default ServerHistoryTable