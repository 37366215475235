import React, { useEffect, useState } from 'react'
import DnsInformation from '../DomainInformation/DnsInformation'
import { CardBody } from 'reactstrap';
import { domainService } from '../../_services/DomainServices/domainServices'

const AccordionBody = ({ ...props }) => {
  const [cdn_loader, setCdn_loader] = useState(false)
  const [dnsInformation, setDnsInformation] = useState("")
  const [dnsAllChecked, setDnsAllChecked] = useState(false)
  useEffect(() => {
    getDnsInformation()
  }, [props.my_domain_name])

  const getDnsInformation = async () => {
    setCdn_loader(true)
    setDnsInformation("")
    let data = {
      "token": props.my_token_copy,
      "domain_id": props.DOMAIN_ID
    }

    let response = await domainService.getDomainDNSInformationByTokenAndDomainId(data)
    if (response) {
      let newArray = []
      Array.isArray(response) && response.map((obj) => {
        newArray.push({
          "type": obj.TYPE,
          "name": obj.NAME,
          "content": obj.CONTENT,
          "status": 1,
          "cloudflare_proxy": 1,
          "server_hosted_by": "abhishek",
          "server_hostname": "Singh",
          "server_isp": "Singh",
          "server_organisation": "codefire",
          "server_continent": "asia",
          "server_country": "india",
          "server_region": "north",
          "server_city": "Noida",
          "monitor": true,
          "auto_updated": 1
        })
      })
      setDnsInformation(newArray)
      setCdn_loader(false)
    } else {
      setCdn_loader(false)
    }
  }

  const onClickAllDnsChecked = (e) => {

  }

  const onClickSingleDnsChecked = () => {

  }

  return <CardBody>
    {<DnsInformation
      cdn_loader={cdn_loader}
      dnsInformation={dnsInformation}
      title={true}
      style={{ marginLeft: '-14px' }}
      dnsAllChecked={dnsAllChecked}
      onClickAllDnsChecked={onClickAllDnsChecked}
      onClickSingleDnsChecked={onClickSingleDnsChecked}
    ></DnsInformation>}
  </CardBody>
}

export default AccordionBody