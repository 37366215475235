import axios from 'axios';
import { URL_CONFIG } from '../../_constants/Config/URL_CONFIG'
const API_AUTH = axios.create({
  baseURL: URL_CONFIG.LOCAL_URL
});

API_AUTH.interceptors.request.use(function (request) {
  const token = localStorage.getItem('basicAuthToken');
  request.headers.Authorization = token ? `Basic ${token}` : '';
  return request;
});

API_AUTH.interceptors.response.use((response, error) => {
  if(response){
    return response
  } else{
      return error
  }
})

export default API_AUTH