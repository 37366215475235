import React from 'react'
import { Button } from 'reactstrap';
import Header from '../../_components/CommonComponents/Header/Header'
import g8keeperLogo from '../../assets/img/logo.svg';
import { loginService } from '../../_services/servicesWithSecondOrigin/Login/login'
class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: "",
            token: "",
            verify: ""
        }
    }


    async  componentDidMount() {
        if (this.props.match.params.id && this.props.match.params.token) {
            let response = await loginService.verifyEmail(this.props.match.params.id, this.props.match.params.token)
            if (response && response.message)
                this.setState({
                    verify: response.message
                })
        }
    }

    render() {
        return (<div>
            <Header history={this.props.history} {...this.props}></Header>
            <div className="loginForm">
                <div className="card">
                    <div className="loginLogo"><img src={g8keeperLogo} alt="Logo" /></div>
                    <h4 className="card-title"> Welcome to g8keeper! 👋 </h4>
                    <div className="seo-monitoring-form">

                        <div className="mt-4">
                            <h4 className="loginLogo" style={{ color: '#94053a' }}>{this.state.verify}</h4>
                            <div className="user-button-login">
                                <Button className="btn-primary btn-block" onClick={() => this.props.history.push('/login')}>Sign In</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>)
    }
}

export default Login