import { useEffect } from "react";
import { useState } from "react";
import { creditsServices } from "../../_services/servicesWithSecondOrigin";

export const useCreditDetails = () => {
  const [data, setData] = useState("");
  const [open, setOpen] = useState(0);

  const toggle = (id) => {
    if (open === id) {
      setOpen(0);
    } else {
      setOpen(id);
    }
  };

  const getData = () => {
    creditsServices.getUserCredits().then((res) => {
      console.log(res);
      if (res) {
        setData(res);
      }
    });
  };
  useEffect(() => {
    getData();
  }, []);
  return [data, open, toggle];
};

export const useCreditDetailsLog = () => {
  const [data, setData] = useState("");
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState("");
  const getData = (type, page, pageSize) => {
    creditsServices.getUserCreditsList(type, page, pageSize).then((res) => {
      if (Array.isArray(res.login)) {
        setData(res.login);
        setPage(res._meta.currentPage);
        setTotalPage(res._meta.totalCount);
      } else {
        setData("");
      }
    });
  };
  return [data, getData, page, totalPage];
};
