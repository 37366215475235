import React from "react";
import { NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./profile.styles.css";
export function ProfilePopup(props) {
  return (
    <>
      <NavDropdown title={props.children} id="basic-nav-dropdown">
        <NavDropdown.Item>
          <Link to="/credit-details">Credit Details </Link>
        </NavDropdown.Item>
        <NavDropdown.Item>{props.logout}</NavDropdown.Item>
      </NavDropdown>
    </>
  );
}
