import React,
{ useState, useEffect } from 'react'
import toastr from 'toastr'
import { confirmAlert } from "react-confirm-alert";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { MdRemoveRedEye } from "react-icons/md";
import { IoIosRemoveCircleOutline } from "react-icons/io";
import Header from '../../_components/CommonComponents/Header/Header'
import { Loader } from '../../_components/CommonComponents/index'
import ServerShare from "../../_components/EmailShare/EmailSharePopup";
import { emailService } from '../../_services/servicesWithSecondOrigin/index'
import { Validation } from "../../_helpers/validation/Validation";
import { MdRefresh } from "react-icons/md";

function EmailInboxs(props) {
    const [sharedEmailInboxesListing, setSharedEmailInboxesListing] = useState([])
    const [mailInboxesListing, setEmailInboxesListing] = useState([])
    // const [dataObj, setDataObj] = useState("")
    const [currentPage, setCurrentPage] = useState(1)
    const [selectedOption, setSelectedOptionData] = useState(10)
    const [loader, setLoader] = useState(false)

    const refresh = () => {
        getData()
    }

    const getData = async (page) => {
        setLoader(true)
        setEmailInboxesListing([])
        setSharedEmailInboxesListing([])
        let response = await emailService.getAllEmailInboxes()
        if (response && response.Shareddata) {
            setSharedEmailInboxesListing(response.Shareddata)
        }
        if (response && response.data) {
            setEmailInboxesListing(response.data)
        }
        setLoader(false)
    }

    useEffect(getData, [])

    const onClickEmail = async (data, index) => {
        // setDataObj(data)
        if (parseInt(data.is_read) === 0) {
            let response = await emailService.readEmail(data.id, { is_read: 1 })
            if (response && response.status === 200) {
                let arr = [...sharedEmailInboxesListing]
                data.is_read = 1
                arr[index] = data
                setSharedEmailInboxesListing(arr)
            }
        }
    }

    const setPageNumber = (page) => {
        if (parseInt(page) !== parseInt(currentPage)) {

            getData("?page=" + page + '&per-page=' + selectedOption)
        }
    }

    const setSelectedOption = (obj) => {
        setSelectedOptionData(obj.value)
        getData("?page=" + 1 + '&per-page=' + obj.value)
    }

    const share = async (id, email, callBack) => {
        let rule = [
            {
                field_name: "email",
                label: "Email",
                type: "email",
                isRequired: true,
            },
        ];
        let errobj = Validation({ email: email }, rule);
        if (errobj && errobj.email === "") {
            let data = {
                "id": "",
                email: email,
            };
            let response = await emailService.shareEmailInbox(data);
            if (response && response.message) {
                toastr.success(response.message);
            }
            if (callBack && response && response.status === 200) {
                callBack();
            }
        } else {
            toastr.warning(errobj.email);
        }
    };

    const removeDomain = (dataObj) => {
        confirmAlert({
            title: "Confirm to Delete",
            message: <p>Are you sure to do this.</p>,
            buttons: [
                {
                    label: "Yes",
                    onClick: async () => {
                        let data = {
                            "receiver_company_id": dataObj.company_id,
                            "target_id": dataObj.g8keeper_email
                        }
                        let response = await emailService.shareEmailInboxByOtherRemove(data);
                        if (response && response.message) {
                            toastr.info(response.message);
                        }
                        if (response && response.status === 200) {
                            getData();
                        }
                    },
                },
                {
                    label: "No",
                    onClick: () => console.log("No"),
                },
            ],
        });
    };

    return <div className="container">
        <Header history={props.history} {...props}></Header>
        <div className="loaderCenter"> <Loader loader={loader}></Loader></div>
        <div className="seo-monitoring">
                    <div className="card">
                        <div className="card-header">
                            <h3>Inboxes</h3>
                            <OverlayTrigger
                                key={'left'}
                                placement={'left'}
                                overlay={<Tooltip>Refresh</Tooltip>}>
                                <MdRefresh size="20" className="iconBtn" onClick={() => refresh()}></MdRefresh>
                            </OverlayTrigger>
                        </div>
                        <div className="react-bootstrap-table">
                            <table className="table table-bordered mb-0">
                                <thead>
                                    <tr>
                                        {Array.isArray(mailInboxesListing) && mailInboxesListing.length > 0 && <>
                                            <th>Name</th>
                                            <th>Email </th>
                                            <th style={{width: '100px'}} align="right">Actions</th>
                                        </>}
                                    </tr>
                                </thead>
                                {Array.isArray(mailInboxesListing) && mailInboxesListing.length > 0 ? <tbody>
                                    {mailInboxesListing.map((data, index) => {
                                        return <tr className={"read-email"} key={data.id}>
                                            <td className="">{data.name}</td>
                                            <td className="">{data.g8keeper_email} </td>

                                            <td  align="right">
                                                <div className="d-flex" >
                                                <OverlayTrigger placement="top" overlay={<Tooltip>View Inbox</Tooltip>}><MdRemoveRedEye className="mr-1 actionIcon" size={27} onClick={() => {
                                                    props.history.push('/email-listing', { email: data.g8keeper_email })
                                                }}></MdRemoveRedEye></OverlayTrigger>
                                                <ServerShare className="actionIcon" id={data.id} share={share} email={data.g8keeper_email} server_name={data.name}></ServerShare>
                                                </div>

                                            </td>
                                        </tr>
                                    })}
                                </tbody> : <div>No Record Found</div>}
                            </table>
                        </div>
                    </div>

 
                    {Array.isArray(sharedEmailInboxesListing) && sharedEmailInboxesListing.length > 0 && <div className="card">
                        <div className="card-header">
                            <h3>Shared Inboxes</h3>
                        </div>
                        <div className="card-body p-0">
                            <div className="react-bootstrap-table">
                            <table className="table table-bordered mb-0">
                                <thead>
                                    <tr>
                                        {Array.isArray(sharedEmailInboxesListing) && sharedEmailInboxesListing.length > 0 && <>
                                            <th className="">Name</th>
                                            <th className="">Email </th>
                                            <th style={{width: '100px'}}>Actions</th>
                                        </>}
                                    </tr>
                                </thead>
                            {Array.isArray(sharedEmailInboxesListing) && sharedEmailInboxesListing.length > 0 ? <tbody>
                                {sharedEmailInboxesListing.map((data, index) => {
                                    return <tr className={" read-email"} >
                                        <td>{data.name}</td>
                                        <td><p className="mb-0">{data.g8keeper_email} </p></td>
                                        <td className="">
                                            <OverlayTrigger placement="top" overlay={<Tooltip>View Inbox</Tooltip>}><MdRemoveRedEye className="mr-1 actionIcon" size={27} onClick={() => {
                                                props.history.push('/email-listing', { email: data.g8keeper_email })
                                            }}></MdRemoveRedEye></OverlayTrigger>
                                            <OverlayTrigger placement="top" overlay={<Tooltip>Delete Inbox </Tooltip>}><IoIosRemoveCircleOutline className="mr-1 actionIcon deleteIcon" size={25} onClick={() => removeDomain(data)}></IoIosRemoveCircleOutline></OverlayTrigger>
                                        </td>
                                    </tr>
                                })}
                            </tbody> : <div>No Record Found</div>}
                            </table>
                            </div>
                        </div>
                    </div>}
        </div>
    </div>
}

export default EmailInboxs;

