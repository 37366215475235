import React from 'react'
import { useHistory } from "react-router-dom";
import { MdRemoveRedEye, MdDelete } from "react-icons/md";
import { AiOutlineEdit } from "react-icons/ai";
import { DomainNotificationPoppup } from '../DomainDetails/index'
import { ShareDomain } from '../DomainDetails/index'
import { IoIosRemoveCircleOutline } from "react-icons/io";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Notifications from '../../Pages/NotificationChannel/NotificationsPopup'

import apache_img from '../../assets/img/ServerIcon/no-domain-malware.png'
import minitor_url_image from '../../assets/img/ServerIcon/monitor-url-up.svg'
import aws_down_img from '../../assets/img/ServerIcon/monitor-url-down.png'
import backup_img from '../../assets/img/ServerIcon/database.png'
import folder_img from '../../assets/img/ServerIcon/folder.jpg'
import googleFiles_img from '../../assets/img/ServerIcon/not-allowed-folder.png'
import greenLock_img from '../../assets/img/ServerIcon/server-monitoring.svg'
import mysql_img from '../../assets/img/ServerIcon/email-blacklist.png'
import seo_configured from '../../assets/img/ServerIcon/seo-up.svg'
import seo_notconfigured from '../../assets/img/ServerIcon/seo-down.svg'
import socialmediacon from '../../assets/img/ServerIcon/social-media-con.png'
import socialmedianotcon from '../../assets/img/ServerIcon/social-media-notcon.png'




import server_on from '../../assets/img/serveron.png'
import server_off from '../../assets/img/serveroff.png'
import { Notes } from '../CommonComponents/index';
import { MdLink } from "react-icons/md";
import { RiDeleteBinLine } from 'react-icons/ri';


const Dashboard = ({ object, key, ...props }) => {
    let history = useHistory();
    const gotoView = (object, id) => {
        localStorage.setItem("cID", id)
        localStorage.setItem("domainID", object.id)
        history.push('/view-domain-details', { id: object.id })
    }

    return <tr key={props.key1 + 1}>
        <td >{props.key1 + 1}

        </td>
        <td><div style={{ display: "flex", justifyContent: 'space-between' }}>
            <div>
                <div>
                    <span onClick={() => {
                        localStorage.setItem("domainID", object.id)
                        // props.history.push('/view-domain-details', { id: object.id })
                    }}>{object && object.domain_name}

                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>View URL</Tooltip>}><a href={"http://" + object.domain_name} target="_blank"><MdLink className="linkIcon" /></a></OverlayTrigger>
                    </span>
                </div>
            </div>
            {/* <div style={{ display: 'flex' }}>
      
            </div> */}
        </div>
        </td>
        <td>
            <div style={{ display: 'flex' }}>

                {object.monitor_url === 1 ? <OverlayTrigger placement="top" overlay={<Tooltip>Monitor URL is configured</Tooltip>}><img style={{cursor:'pointer'}} src={minitor_url_image} onClick={() => gotoView(object, "monitor_url")} alt="minitor_url_image" className="moniterImges" /></OverlayTrigger> : <OverlayTrigger placement="top" overlay={<Tooltip>Monitor URL is not configured</Tooltip>}><div><img style={{cursor:'pointer'}} onClick={() => gotoView(object, "monitor_url")} src={aws_down_img} alt="minitor_url_image" className="moniterImges" /></div></OverlayTrigger>}

                {object.monitor_server === 1 ? <OverlayTrigger placement="top" overlay={<Tooltip>Server monitoring for this domain is configured</Tooltip>}><img style={{cursor:'pointer'}} src={greenLock_img} onClick={() => gotoView(object, "monitor_server")}  alt="monitor-server" className="moniterImges" /></OverlayTrigger> : <OverlayTrigger placement="top" overlay={<Tooltip>Servers monitoring for this domain is not configured</Tooltip>}><img style={{cursor:'pointer'}} src={greenLock_img} alt="monitor-server" onClick={() => gotoView(object, "monitor_server")} className="moniterImges moniterImgesDisabled" /></OverlayTrigger>}

                {object.seo === 1 ? <OverlayTrigger placement="top" overlay={<Tooltip>SEO monitoring for this domain is configured </Tooltip>}><img style={{cursor:'pointer'}} src={seo_configured} onClick={() => gotoView(object, "monitor_seo")} alt="seo_configured" className="moniterImges" /></OverlayTrigger> : <OverlayTrigger placement="top" overlay={<Tooltip>SEO monitoring for this domain is not configured</Tooltip>}><img style={{cursor:'pointer'}} src={seo_notconfigured} onClick={() => gotoView(object, "monitor_seo")} alt="seo_notconfigured" className="moniterImges moniterImgesDisabled" /></OverlayTrigger>}


                {object.social_media === 1 ? <OverlayTrigger placement="top" overlay={<Tooltip>Social Media monitoring for this domain is configured</Tooltip>}><img style={{cursor:'pointer'}} src={socialmediacon} onClick={() => gotoView(object, "monitor_social_media")} alt="socialmediacon" className="moniterImges" /></OverlayTrigger> : <OverlayTrigger placement="top" overlay={<Tooltip>Social Media monitoring for this domain is not configured</Tooltip>}><img style={{cursor:'pointer'}} src={socialmedianotcon} onClick={() => gotoView(object, "monitor_social_media")} alt="socialmedianotcon" className="moniterImges moniterImgesDisabled" /></OverlayTrigger>}



                {object.domain_malware === 1 ? <OverlayTrigger placement="top" overlay={<Tooltip> ⛔ Malware found on the domain</Tooltip>}><img style={{cursor:'pointer'}} src={apache_img} onClick={() => gotoView(object, "malware")} alt="no-domain-malware" className="moniterImges" /></OverlayTrigger> : <OverlayTrigger placement="top" overlay={<Tooltip>No malware has been found on the domain</Tooltip>}><img style={{cursor:'pointer'}} src={apache_img} onClick={() => gotoView(object, "malware")} alt="apache_img" className="moniterImges moniterImgesDisabled" /></OverlayTrigger>}

                {object.email_blacklist === 1 ? <OverlayTrigger placement="top" overlay={<Tooltip>  ⛔ Email is blacklisted for this domain</Tooltip>}><img style={{cursor:'pointer'}} src={mysql_img} onClick={() => gotoView(object, "email_back_track")} alt="mysql_img" className="moniterImges" /></OverlayTrigger> : <OverlayTrigger placement="top" overlay={<Tooltip>Email is not blacklisted for this domain</Tooltip>}><img style={{cursor:'pointer'}} src={mysql_img} onClick={() => gotoView(object, "email_back_track")} alt="mysql_img" className="moniterImges moniterImgesDisabled" /></OverlayTrigger>}


            </div>
        </td>
        {props.sharedby && <td>{object.shared_by}</td>}
        <td>
            <Notifications id={object.id} mytimezone={props.myTimezone} isDomainPage="domain" isdomainDashboard={true}></Notifications>
        </td>
        <td>
            <div className="d-flex">
                <OverlayTrigger placement="top" overlay={<Tooltip>View domain details</Tooltip>}><MdRemoveRedEye className="mr-1 actionIcon" size={27} onClick={() => {
                    localStorage.setItem("domainID", object.id)
                    props.history.push('/view-domain-details', { id: object.id })
                }}></MdRemoveRedEye></OverlayTrigger>

                {/* <OverlayTrigger placement="top" overlay={<Tooltip>Edit domain details</Tooltip>}><AiOutlineEdit className="mr-1 actionIcon" size={27} onClick={() => props.history.push('/update-domain', { id: object.id })}>
                
                </AiOutlineEdit></OverlayTrigger> */}
                <Notes id={object.id} type="domain"></Notes>
                <ShareDomain className="mr-1 actionIcon" allData={object}></ShareDomain>
                {parseInt(object.shared) === 0 ? <OverlayTrigger placement="top" overlay={<Tooltip>Delete domain </Tooltip>}><RiDeleteBinLine className="mr-1 actionIcon deleteIcon" size={25} onClick={() => props.deleteDomain(object.id)}></RiDeleteBinLine></OverlayTrigger> :
                    <OverlayTrigger placement="top" overlay={<Tooltip>Delete domain </Tooltip>}><IoIosRemoveCircleOutline className="mr-1 actionIcon deleteIcon" size={25} onClick={() => props.removeDomain(object.domain_name)}></IoIosRemoveCircleOutline></OverlayTrigger>}

                <DomainNotificationPoppup allData={object}></DomainNotificationPoppup>

            </div>
        </td>
    </tr>
}
export default Dashboard



{/* <span><MdDelete style={{zIndex:"9999999"}} onClick={()=>props.deleteDomain(object.id)}></MdDelete></span> */ }