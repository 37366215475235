import { RestMethodSecondOrigin } from '../../../_helpers/ApiConfigTwo/RestMethod'
import FilterResponse from '../../../_helpers/FilterResponse'
import { isAuthorized } from '../../../_helpers/Common/Common'
export const notesServices = {
    addNotes,
    getNotes,
    deleteNotes,
    updateNote
}

async function addNotes(data) {
    try {
        const response = await RestMethodSecondOrigin.POST('/note/add-note', data);
        const api_response = FilterResponse(response);
        return api_response;
    }
    catch (error) {
        isAuthorized(error)
        return null;
    }
}

async function getNotes(data) {
    try {
        const response = await RestMethodSecondOrigin.POST('/note/get-note', data);
        const api_response = FilterResponse(response);
        return api_response;
    }
    catch (error) {
        isAuthorized(error)
        return null;
    }
}

async function deleteNotes(id) {
    try {
        const response = await RestMethodSecondOrigin.GET('/note/delete-note?id='+id);
        const api_response = FilterResponse(response);
        return api_response;
    }
    catch (error) {
        isAuthorized(error)
        return null;
    }
}

async function updateNote(data,id) {
    try {
        const response = await RestMethodSecondOrigin.PUT('/note/update-note?id='+id, data);
        const api_response = FilterResponse(response);
        return api_response;
    }
    catch (error) {
        isAuthorized(error)
        return null;
    }
}