import React, { useState, useEffect } from 'react'
import { dashBoardServices } from '../../_services/servicesWithSecondOrigin/index'
import { Select } from '../../_components/CommonComponents/index'

const DomianListing = (props) => {
    const [allDomain, setDomainListing] = useState([])
    useEffect(() => {
        getAllDomain()
    }, [])
    const getAllDomain = async () => {
        let response = await dashBoardServices.getAllDomain()
        if (response && response.domain && Array.isArray(response.domain)) {
            response.domain.map((data) => {
                data.label = data.domain_name;
                data.value = data.id
            })
            setDomainListing(response.domain)
        } else {
            setDomainListing([])
        }
    }

    const onSelectDomain = (e) => {
        props.onSelectDomain(e)
    }

    return <div className="serverListServices d-flex">
        <span className="selectServer">
            <Select className="dropdownOption" selectedOption={props.id} setSelectedOption={(e) => onSelectDomain(e)} options={allDomain.sort((a, b) => a.label.toLowerCase() > b.label.toLowerCase() ? 1 : a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 0)}></Select>
        </span>
        {/* <span className="selectServer" style={{ minWidth: '5px', marginRight: '0px' }}>
            <Button className="mr-3 addEditBtn primary-btn btn btn-secondary" onClick={() => props.history.push('/update-domain', { id: props.id })}>Edit</Button>
        </span> */}

    </div>
}
export default DomianListing
