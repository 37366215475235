import { Button } from "reactstrap";
export const MButton = ({ ...props }) => {
  const onClick = (e) => {
    if (props.onClick) {
      props.onClick(e);
    } else {
      console.log("clicked");
    }
  };
  return (
    <Button disabled={props.disabled} color={props.color ? props.color : "secondary"} className={props.className ? props.className : ""} onClick={(e) => onClick(e)}>
      {props.label ? props.label : ""}
    </Button>
  );
};
