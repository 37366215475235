export function Rules(field, ruleObj) {
  var errorMsg = "";
  if (field === "" || field === undefined || field === null) {
    errorMsg = ruleObj.label + " is Required.";
  } else {
    if (ruleObj.type === "password") {
      var password = /^(?=.*[a-zA-Z0-9])(?=.*[!@#$%^&*])(?=.{8,})/;
      var specialCharacter = /^(?=.*[!@#$%^&*])/;
      var Maxlen = /^(?=.{,16})/;
      var len = /^(?=.{8,})/;
      var capital = /^(?=.*[A-Z])/;
      var number = /^(?=.*[0-9])/;
      if (password.test(field) === false) {
        errorMsg = "Please enter valid password";
        if (len.test(field) === false) {
          errorMsg = "Min length 8 is required";
        }
        if (specialCharacter.test(field) === false) {
          errorMsg = "Please enter at least one special character";
        }
        if (number.test(field) === false) {
          errorMsg = "Please enter at least one number";
        }
      } else {
        if (len.test(field) === false) {
          errorMsg = "Min length 8 is required";
        }
        if (specialCharacter.test(field) === false) {
          errorMsg = "Please enter at least one special character";
        }
        if (number.test(field) === false) {
          errorMsg = "Please enter at least one number";
        }
      }
    } else if (ruleObj.type === "number") {
      if (
        ruleObj["max"] !== undefined &&
        field.toString().length > ruleObj.max
      ) {
        errorMsg = "Max " + ruleObj.max + " digit required";
      }
      if (
        ruleObj["min"] !== undefined &&
        field.toString().length < ruleObj.min
      ) {
        errorMsg = "Min " + ruleObj.min + " digit required";
      }

      if (
        ruleObj["isNonNegative"] !== undefined &&
        !isNaN(field) &&
        parseFloat(field) < 0
      ) {
        errorMsg = "Negative Value is not Allowed";
      }
      if (ruleObj["toFixed"] !== undefined && !isNaN(field)) {
        let temp = field.toString().split(".");
        let toFixedVal = ruleObj["toFixedVal"] ? ruleObj["toFixedVal"] : 2;
        if (temp.length > 1 && temp[temp.length - 1].length > toFixedVal)
          errorMsg = "Only " + toFixedVal + " Decimal Values are Allowed!";
      }

      if (isNaN(field)) {
        errorMsg = "Please enter a valid number";
      }
      if (ruleObj.zero) {
        if (field == 0) {
          errorMsg = "Value must be greater than 0";
        }
      }
    } else if (ruleObj.type === "phone") {
      if (
        ruleObj["max"] !== undefined &&
        field.toString().length > ruleObj.max
      ) {
        errorMsg = "Please enter Valid Mobile Number";
      }
      if (
        ruleObj["min"] !== undefined &&
        field.toString().length < ruleObj.min
      ) {
        errorMsg = "Please enter Valid Mobile Number";
      }
      if (isNaN(field)) {
        errorMsg = "Please enter Valid Mobile Number";
      }
      if (ruleObj.zero) {
        if (field == 0) {
          errorMsg = "Value must be greater than 0";
        }
      }
    } else if (ruleObj.type === "email") {
      var regEmail =
        /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
      if (regEmail.test(field) === false) {
        errorMsg = ruleObj.label + " is invalid email";
      }
    } else if (ruleObj.type === "date") {
      var pattern =
        /(0[1-9]|[12][0-9]|3[01])[- -.](0[1-9]|1[012])[- -.](19|20)\d\d/;
      if (pattern.test(field) === false) {
        errorMsg = "Date pattern should be dd-mm-yyyy";
      }
    } else if (ruleObj.type === "string") {
      errorMsg = "";
    } else if (ruleObj.type === "gstin") {
      var gst_number = field;

      if (gst_number.toString().length < 15) {
        return (errorMsg = "Minimum 15 digit required");
      }
      if (gst_number.toString().length > 15) {
        return (errorMsg = "Maximun 15 digit required");
      }

      if (gst_number.charAt(13) !== "Z") {
        return (errorMsg = "Provide a valid GST Number.");
      }

      var third_letter = gst_number.charAt(2);
      var fourth_letter = gst_number.charAt(3);
      var fifth_letter = gst_number.charAt(4);
      var thirteen_letter = gst_number.charAt(12);

      if (
        /\d/.test(parseInt(gst_number.charAt(0))) === false ||
        /\d/.test(parseInt(gst_number.charAt(1))) === false
      ) {
        return (errorMsg = "Provide a valid GSTIN Number.");
      }

      if (/\d/.test(parseInt(thirteen_letter)) === false) {
        return (errorMsg = "Provide a valid GSTIN Number.");
      }

      var regGST = /^[a-zA-Z]{1}$/i;

      if (
        !regGST.test(third_letter) ||
        !regGST.test(fourth_letter) ||
        !regGST.test(fifth_letter)
      ) {
        return (errorMsg = "Provide a valid GSTIN Number.");
      }

      var first_two = parseInt(gst_number.substring(0, 2));

      if (first_two > 37) {
        return (errorMsg = "Provide a valid GSTIN Number.");
      }
    } else if (ruleObj.type === "pan") {
      var panVal = field;
      var regpan = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;

      if (!regpan.test(panVal)) {
        errorMsg = "Provide a valid PAN Number";
      }
    } else if (ruleObj.type === "volume") {
      if (
        ruleObj["max"] !== undefined &&
        field.toString().length > ruleObj.max
      ) {
        errorMsg = "Max " + ruleObj.max + " digit required";
      }
      if (
        ruleObj["min"] !== undefined &&
        field.toString().length < ruleObj.min
      ) {
        errorMsg = "Min " + ruleObj.min + " digit required";
      }
      if (field == 0) {
        errorMsg = "Volume must be greater than 0";
      }

      if (isNaN(field)) {
        errorMsg = "Please enter a valid number";
      }
    } else if (ruleObj.type === "url") {
      var url = field;
      //var regpan = /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
      // var regpan =   /(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/|\/\/)(([a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?))(\/.*)?/

      var regpan =
        /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi;
      if (!regpan.test(url)) {
        errorMsg = "Please Enter Valid URL";
      }
    } else if (ruleObj.type === "simple-url") {
      var url = field;
      var simpleregpan =
        /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
      if (!simpleregpan.test(url)) {
        errorMsg = "Please Enter Valid URL";
      }
    } else if (ruleObj.type === "json") {
      const isJson = (str) => {
        try {
          JSON.parse(str);
        } catch (e) {
          //JSON is not okay
          return false;
        }
        return true;
      };
      let jsondata = field;
      errorMsg = isJson(jsondata)
        ? ""
        : "JSON data is not in valid format";
    }
     else if (ruleObj.type === "name") {
      const alphanumeric = /^([a-zA-Z0-9 _]+)$/;
      if (alphanumeric.test(field) === false) {
        errorMsg = "Only Alphabets and Numbers are allowed.";
      }
    }
  }
  return errorMsg;
}
